import * as Sentry from '@sentry/browser'

export function httpError($state, $window, ErrorsData) {
  'ngInject'

  const service = {
    handle
  }

  return service

  function handle(e, response) {
    // These are reported to the API, therefore don't need reported in the web client
    const ignoreServerErrors = [500, 504]

    // Errors handled in app that don't need to be sent to Sentry (via Sentry)
    const sentryMutedErrors = [
      'invalid_credentials',
      'login_attempts_exceeded',
      'invalid_permission',
      'account_deactivated',
      'account_not_found',
      'record_not_found',
      'folder_not_found',
      'product_not_found',
      'state_not_found',
      'user_does_not_belong_to_account',
      'user_does_not_have_permission_for_folder',
      'user_is_not_an_owner',
      'user_does_not_belong_to_subscription'
    ]

    if (ignoreServerErrors.indexOf(response.status) !== -1) {
      return false
    }

    if (response.status === 400) {
      //Validation error
      Sentry.captureMessage(
        `App error due to HTTP status ${response.status} (${response.data && response.data.error})`,
        { extra: response.data }
      )
      return false
    }

    if (response.status === 403) {
      //Permissions error should be handled in app
      return false
    }

    // Auth error, already handled via authService (see app.js)
    if (response.status === 401) {
      return false
    }

    if (response.status === 503) {
      $state.go('maintenance', {}, { location: 'replace' })
      return false
    }

    if (response.data) {
      if (sentryMutedErrors.indexOf(response.data.error) !== -1) {
        return false
      }

      if (response.config.ignoreError) {
        return false
      }

      ErrorsData.currentError(response.data.error || null)
    }

    Sentry.captureMessage(`App error due to HTTP status ${response.status} (${response.data && response.data.error})`)

    $state.go('error', {}, { location: 'replace' })

    return true
  }
}
