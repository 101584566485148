import { Controller } from './controller'
import view from './view.html'

export const ptChecklistsSectionContent = {
  bindings: {
    record: '<',
    onValueChange: '&',
    checklistName: '@',
    section: '<',
    field: '<',
    checklistOptions: '<'
  },
  controller: Controller,
  template: view
}
