import _each from 'lodash/each'
import _map from 'lodash/map'

export function RecordModel(recordsBucket, restmod, timezoneUtil) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      $extend: {
        Record: {
          inBucket: function() {
            return recordsBucket.recordInBucket(this.id);
          },
          addToBucket: function() {
            return recordsBucket.add(this.id);
          },
          removeFromBucket: function() {
            return recordsBucket.remove(this.id);
          },
        }
      }
    })
    .mix({
      notify_at: {
        encode: function (value) {
          return timezoneUtil.localToAccountTz(value).format('YYYY-MM-DD')
        }
      },
      next_check_at: {
        encode: function (value) {
          return timezoneUtil.localToAccountTz(value).format('YYYY-MM-DD')
        }
      }
    })
    .mix({
      account: { hasOne: 'AccountModel', map: 'account.data' },
      user: { hasOne: 'UserModel', map: 'user.data' },
      folder: { hasOne: 'FolderModel', map: 'folder.data' },
      state: { hasOne: 'StateModel', map: 'state.data' },
      initial_state: { hasOne: 'StateModel', map: 'initial_state.data' },
      logs: { hasMany: 'LogsModel' },
      notes: { hasMany: 'NotesModel' },
      fields: { hasMany: 'FieldsModel', map: 'fields.data' },
      uploads: { hasMany: 'UploadModel', map: 'uploads.data' },
      log: { hasOne: 'LogsModel', map: 'log.data' },
      note: { hasOne: 'NotesModel', map: 'note.data' },
      frequency: { hasOne: 'FrequenciesModel', map: 'frequency.data' },
      tags: {
        decode: function (_value) {
          let tags = []
          _each(_value, function (value) {
            tags.push({ text: value })
          })
          return tags
        },
        encode: function (_value) {
          if (_value[0] && _value[0].text) {
            return _map(_value, 'text')
          }

          return _value
        }
      },
      checklists: { map: 'checklists.data' }
    })

  return model
}
