export function collections(pagination) {
  'ngInject'

  let service = {
    getAll: getAll
  }

  let limit = 100

  return service

  ////////////////

  function getAll(collection) {
    return getNext(collection)
      .then(function (collectionData) {
        if (pagination.hasMorePages(collectionData)) {
          return getAll(collectionData)
        }

        return collectionData
      })
      .catch(function () {})
  }

  function getNext(collection) {
    let params = {
      limit: limit,
      page: pagination.nextPage(collection)
    }

    return collection.$fetch(params).$asPromise()
  }
}
