import _assign from 'lodash/assign'

export const ImportsData = (
  $q,
  ImportsModel,
  BASE_URL,
  API_VERSION,
  session,
  $http,
  collections,
  pagination,
  EventsData
) => {
  'ngInject'

  let importing = false

  const completeImport = (importId, folderId, blueprintId, records) => {
    return $http({
      method: 'POST',
      url: `${BASE_URL}/imports/${importId}/actions/map`,
      headers: {
        Accept: API_VERSION
      },
      data: {
        folder_id: folderId,
        blueprint_id: blueprintId,
        records: records
      }
    }).then((response) => {
      response.data.data.forEach((record) =>
        EventsData.pushEvent('record_was_imported', {
          recordId: record.id,
          folderId: folderId
        })
      )

      EventsData.broadcast('product_import_complete')

      return response.data.data
    })
  }

  const createImport = (productIds) =>
    ImportsModel.$build({
      type: 'products',
      product_ids: productIds.split(',')
    })
      .$save()
      .$asPromise()
      .then((response) => {
        EventsData.broadcast('product_import_created')

        return response
      })

  const fetchImports = (imports, additionalParams = {}) =>
    imports
      .$fetch(
        _assign(
          {
            page: pagination.nextPage(imports)
          },
          additionalParams
        )
      )
      .$asPromise()

  const getLimitedImports = (limit = 20, additionalParams = {}) =>
    ImportsModel.$collection(
      _assign(
        {
          limit
        },
        additionalParams
      )
    )

  const getImport = (importId) => ImportsModel.$find(importId).$asPromise()

  const isImporting = (value = null) => {
    if (value !== null) {
      importing = value
    }

    return importing
  }

  return {
    fetchImports,
    getLimitedImports,
    getImport,
    isImporting,
    completeImport,
    createImport
  }
}
