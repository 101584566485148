export function Controller($state, session, $stateParams, usersData, gettextCatalog, profileData) {
  'ngInject'

  let vm = this

  vm.code = $stateParams.code
  vm.crumbs = [
    {
      /// Bread crumb
      name: gettextCatalog.getString('Accounts'),
      link: 'accounts'
    },
    {
      /// Bread crumb
      name: gettextCatalog.getString('Profile'),
      link: 'profile.basic'
    }
  ]
  vm.self = gettextCatalog.getString('Validate email address')

  vm.currentUser = session.user
  vm.validating = true
  vm.validated = null

  ////////////////

  vm.$onInit = function () {
    validate()
  }

  function validate() {
    // Check session.user as that will be updated
    if (session.user.confirmedAt) {
      vm.validating = false
      vm.validated = true
    } else {
      vm.validating = true

      usersData
        .validate(vm.code)
        .then(function () {
          profileData.getUser().then(function () {
            validate()
          })
        })
        .catch(function (rejection) {
          if (rejection.data.error === 'user_already_confirmed') {
            vm.validated = true
          } else {
            vm.validated = false
          }

          vm.validating = false
        })
    }
  }
}
