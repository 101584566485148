import * as React from 'react'

import { Activity } from '..'
import { Text } from '../../components'
import { View, createStyledComponent } from '../../components/styled'
import { ActivityTitle } from './activity-title'
import { ActivityContent } from './activity-content'

interface Props {
  activity: Activity
  viewType: string
  onPressActivity: (activity: Activity) => void
}

const Wrapper = createStyledComponent(View, {
  overflow: 'visible'
})

export const ActivityTaskWasOverdue = ({ activity, viewType, onPressActivity }: Props) => {
  switch (viewType) {
    case 'simple':
      return (
        <Wrapper>
          <ActivityTitle activity={activity} onPressActivity={onPressActivity}>
            Task was overdue
          </ActivityTitle>
          <ActivityContent>
            <Text>Title: {activity.task.title}</Text>
          </ActivityContent>
        </Wrapper>
      )
  }
}
