import _find from 'lodash/find'

export function Controller(BASE_URL, uploadFileActions, uploadsData) {
  'ngInject'

  this.$onInit = () => {
    this.defaultUpload = _find(this.record?.uploads, { default: true })

    if (this.defaultUpload) {
      this.uploadType = uploadsData.getUploadType(this.defaultUpload)
    }
  }

  this.$onChanges = () => {}

  this.fileUrl = () => {
    if (this.defaultUpload && this.defaultUpload.view) {
      return BASE_URL + this.defaultUpload.view + '&width=100&height=100'
    }

    return ''
  }

  this.toggleUploadList = () => {
    this.uploadListOpen = !this.uploadListOpen
  }

  this.viewUpload = (upload) => {
    uploadFileActions.viewFile(upload)
  }
}
