export function editableAutoTel(editableDirectiveFactory) {
  'ngInject'

  return editableDirectiveFactory({
    directiveName: 'editableAutoTel',
    inputTpl: `<input type="tel">`,
    autosubmit: function () {
      this.scope.$on('autoSubmit', () => {
        this.scope.$form.$submit()
      })
    }
  })
}
