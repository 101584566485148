export function ptProfileProcessesRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('profile.processes', {
    url: '/processes',
    views: {
      'content@layoutFull': {
        component: 'ptProfileProcesses'
      }
    },
    resolve: {
      processes: resolveProcesses
    },
    onEnter: onProfileProcessesEnter
  })

  function resolveProcesses(ProcessesData) {
    'ngInject'

    return ProcessesData.getAll()
  }

  function onProfileProcessesEnter(page, gettextCatalog) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Your processes'))
  }
}
