import angular from 'angular'

import { ptButtonModule } from './components/pt-button'
import { ptIconModule } from './components/pt-icon'
import { ptItemPickerModule } from './components/pt-item-picker'
import { ptLayoutBaseModule } from './components/pt-layout-base'
import { ptLayoutFullModule } from './components/pt-layout-full'
import { ptLayoutLoginModule } from './components/pt-layout-login'
import { ptLayoutSplashModule } from './components/pt-layout-splash'
import { ptListUpdatesModule } from './components/pt-list-updates'
import { ptModalHeaderModule } from './components/pt-modal-header'
import { ptPersonDisplayModule } from './components/pt-person-display'
import { ptSortPanelListItemModule } from './components/pt-sort-panel-list-item'
import { ptStateIndicatorModule } from './components/pt-state-indicator'
import { ptTextEllipsisModule } from './components/pt-text-ellipsis'
import { ptTooltipUploadModule } from './components/pt-tooltip-upload'
import { ptUploadIconModule } from './components/pt-upload-icon'
import { ptValidationErrorModule } from './components/pt-validation-error'

import { AngularDebugConfig } from './config/angular-debug'
import { ApiCheckConfig } from './config/api-check'
import { ApiConfig } from './config/api'
import { BindingsInit } from './config/bindings-init'
import { FormlyConfig } from './config/formly'
import { HttpConfig } from './config/http'
import { LocalStorageConfig } from './config/local-storage'
import { LocationConfig } from './config/location'
import { SpinnerConfig } from './config/spinner'
import { StateConfig } from './config/state'
import { TooltipConfig } from './config/tooltip'
import { TrackingConfig } from './config/tracking'

import { ptBreadcrumbsModule } from './directives/pt-breadcrumbs'
import { ptBtnLoadingModule } from './directives/pt-btn-loading'
import { ptCancelDragDefaultsModule } from './directives/pt-cancel-drag-defaults'
import { ptCheckboxIndeterminateModule } from './directives/pt-checkbox-indeterminate'
import { ptCompileModule } from './directives/pt-compile'
import { ptContactModule } from './directives/pt-contact'
import { ptDateModule } from './directives/pt-date'
import { ptDragOverClassModule } from './directives/pt-drag-over-class'
import { ptFocusModule } from './directives/pt-focus'
import { ptItemPickerClickModule } from './directives/pt-item-picker-click'
import { ptOfflineModule } from './directives/pt-offline'
import { ptResolvingIndicatorModule } from './directives/pt-resolving-indicator'
import { ptRovingGroupModule } from './directives/pt-roving-group'
import { ptRovingItemModule } from './directives/pt-roving-item'
import { ptScrollRightModule } from './directives/pt-scroll-right'
import { ptTextcompleteModule } from './directives/pt-textcomplete'
import { ptTimeAgoModule } from './directives/pt-time-ago'
import { ptUserIconModule } from './directives/pt-user-icon'
import { xeditableModule } from './directives/xeditable'
import { ptPasswordValidationModule } from './validation/pt-password-validation'

import { CommonFiltersModule } from './filters'

import { formlyModule } from './formly'

import { BootstrapDatepickerModule } from './run/bootstrap-datepicker'

import { ApiBaseModel } from './services/api-base-model'
import { ApiBaseUrl } from './services/api-base-url'
import { ApiHeaders } from './services/api-headers'
import { ApiModelActions } from './services/api-model-actions'
import { collections } from './services/collections'
import { connectionBuffer } from './services/connection-buffer'
import { connection } from './services/connection'
import { context } from './services/context'
import { DateFormat } from './services/date-format'
import { dateTimeFormat } from './services/date-time-format'
import { fileHandler } from './services/file-handler'
import { fileSize } from './services/file-size'
import { guidGenerator } from './services/guid-generator'
import { isIe } from './services/is-ie'
import { page } from './services/page'
import { pagination } from './services/pagination'
import { ptApi } from './services/pt-api'
import { recursionHelper } from './services/recursion-helper'
import { requestRetry } from './services/request-retry'
import { richText } from './services/rich-text'
import { responseErrorInterceptor } from './services/response-error-interceptor'
import { session } from './services/session'
import { stateMonitor } from './services/state-monitor'
import { TemplatesData } from './services/templates'
import { timezoneUtil } from './services/timezone-util'
import { validator } from './services/validator'
import { wootricInit } from './services/wootric-init'

import { GetText } from './run/gettext'
import { Moment } from './run/moment'
import { xEditable } from './run/xeditable'
import { emojiService } from './services/emojiService'
import { ptBucketButtonModule } from './directives/pt-bucket-button'

export const CommonModule = angular
  .module('common', [
    formlyModule,
    BootstrapDatepickerModule,

    ptButtonModule,
    ptIconModule,
    ptItemPickerModule,
    ptLayoutBaseModule,
    ptLayoutFullModule,
    ptLayoutLoginModule,
    ptLayoutSplashModule,
    ptListUpdatesModule,
    ptModalHeaderModule,
    ptPersonDisplayModule,
    ptSortPanelListItemModule,
    ptStateIndicatorModule,
    ptTextEllipsisModule,
    ptTooltipUploadModule,
    ptUploadIconModule,
    ptValidationErrorModule,

    ptBreadcrumbsModule,
    ptBtnLoadingModule,
    ptCancelDragDefaultsModule,
    ptCheckboxIndeterminateModule,
    ptCompileModule,
    ptContactModule,
    ptDateModule,
    ptDragOverClassModule,
    ptFocusModule,
    ptItemPickerClickModule,
    ptOfflineModule,
    ptResolvingIndicatorModule,
    ptRovingGroupModule,
    ptRovingItemModule,
    ptScrollRightModule,
    ptTextcompleteModule,
    ptTimeAgoModule,
    ptUserIconModule,
    xeditableModule,
    ptPasswordValidationModule,
    CommonFiltersModule,
    ptBucketButtonModule
  ])
  .config(AngularDebugConfig)
  .config(ApiCheckConfig)
  .config(ApiConfig)
  .config(BindingsInit)
  .config(FormlyConfig)
  .config(HttpConfig)
  .config(LocalStorageConfig)
  .config(LocationConfig)
  .config(SpinnerConfig)
  .config(StateConfig)
  .config(TooltipConfig)
  .config(TrackingConfig)
  .factory('ApiBaseModel', ApiBaseModel)
  .factory('ApiBaseUrl', ApiBaseUrl)
  .factory('ApiHeaders', ApiHeaders)
  .factory('ApiModelActions', ApiModelActions)
  .factory('collections', collections)
  .factory('connectionBuffer', connectionBuffer)
  .factory('connection', connection)
  .factory('context', context)
  .factory('DateFormat', DateFormat)
  .factory('dateTimeFormat', dateTimeFormat)
  .factory('emojiService', emojiService)
  .factory('fileHandler', fileHandler)
  .factory('fileSize', fileSize)
  .factory('guidGenerator', guidGenerator)
  .factory('isIe', isIe)
  .factory('page', page)
  .factory('pagination', pagination)
  .factory('ptApi', ptApi)
  .factory('recursionHelper', recursionHelper)
  .factory('requestRetry', requestRetry)
  .factory('responseErrorInterceptor', responseErrorInterceptor)
  .factory('richText', richText)
  .factory('session', session)
  .factory('stateMonitor', stateMonitor)
  .factory('TemplatesData', TemplatesData)
  .factory('timezoneUtil', timezoneUtil)
  .factory('validator', validator)
  .factory('wootricInit', wootricInit)
  .run(GetText)
  .run(Moment)
  //TODO this is still initialised when you first download.
  .run([
    'emojiService',
    function (emojiService) {
      emojiService.init()
    }
  ])
  .run(xEditable).name
