import angular from 'angular'

import _uniq from 'lodash/uniq'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _filter from 'lodash/filter'
import _findLastKey from 'lodash/findLastKey'

export function permissionsConverter(gettextCatalog) {
  'ngInject'

  let service = {
    convert: convert,
    reverse: reverse
  }
  let s = {
    /// A permission level, used like - 'Record - View' to indicate the user has permission to view records
    view: gettextCatalog.getString('View'),
    /// A permission level, used like - 'Record - Add' to indicate the user has permission to add records
    add: gettextCatalog.getString('Add'),
    /// A permission level, used like - 'Record - Edit' to indicate the user has permission to edit records
    edit: gettextCatalog.getString('Edit')
  }
  return service

  ////////////////

  function convert(permissions, type) {
    let converted = {}
    let levels = [
      { key: 'view', name: s.view },
      { key: 'add', name: s.add },
      { key: 'edit', name: s.edit }
    ]
    let resources = _uniq(_map(permissions, 'resource'))

    if (!angular.isArray(permissions)) {
      return permissions
    }

    _forEach(resources, function (name) {
      let filteredPermissions = _filter(permissions as any, { resource: name, access: true })

      converted[name] = levels[filteredPermissions.length - 1][type]
    })

    return converted
  }

  function reverse(permissions) {
    _forEach(permissions, function (permission, name) {
      permissions[name] = _findLastKey(permission)
    })

    return permissions
  }
}
