import view from './view.html'

export function ptActivitiesTableRow() {
  var directive = {
    restrict: 'A',
    template: view,
    controller: function () {
      var vm = this

      vm.activityOptions = {
        view: 'td',
        compact: true
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      activity: '='
    }
  }

  return directive
}
