import _clone from 'lodash/clone'

export function Controller(context, TagsData, gettextCatalog, $q) {
  'ngInject'

  const $ctrl = this
  $ctrl.tags = []
  $ctrl.initialTags = _clone($ctrl.tags)

  $ctrl.storeSelectedTags = function (data) {
    $ctrl.tags = data
  }

  $ctrl.addTagsToFilter = function () {
    this.close({ $value: $ctrl.tags })
  }

  $ctrl.pickerOptions = {
    searchPlaceholder: gettextCatalog.getString('Find Tags...'),
    contextLabel: gettextCatalog.getString('Tags'),
    canCreate: false
  }

  $ctrl.getAvailableItems = async function () {
    let tags = await TagsData.findTags(context.get('account'))
    tags = tags.filter((tag) => {
      return $ctrl.resolve.existingTags.find((t) => t === tag.name) === undefined
    })
    return $q.when(tags)
  }

  $ctrl.popoverClose = function () {
    $ctrl.popover = {
      open: false
    }
  }

  $ctrl.popoverToggle = function () {
    let newValue = !$ctrl.popover.open

    $ctrl.popover = {
      open: newValue
    }
  }

  $ctrl.popover = {
    open: true
  }
}
