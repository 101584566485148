import lazy from 'lazy.js'

export const permissionsCheck = ($q, context, session, ErrorsModal) => {
  'ngInject'

  const service = {
    can,
    canPromise,
    cant,
    is,
    isnt,
    enforce
  }

  const isChecks = {
    admin: isAdmin,
    viewOnly: isViewOnly
  }

  return service

  function can(action, resource, account, instance?) {
    
    const permissions = account && account.me && account.me.permissions
    
    const permission = lazy(permissions).find(function (perm) {
      return perm && perm.action === action && perm.resource === resource
    })

    if (!permission) {
      throw new NotFoundPermissionException(action, resource)
    }

    if (permission.access) {
      return true
    }

    return instanceCheck(action, resource, account, instance)
  }

  function canPromise(action, resource, account, instance) {
    const defer = $q.defer()
    const permission = can(action, resource, account, instance)

    if (permission) {
      defer.resolve(permission)
    } else {
      defer.reject(permission)
    }

    return defer.promise
  }

  function cant(action, resource, account, instance) {
    return !can(action, resource, account, instance)
  }

  function is(type, account) {
    return isChecks[type](account)
  }

  function isnt(type, account) {
    return !is(type, account)
  }

  function enforce(action, resource, account, instance) {
    if (can(action, resource, account, instance)) {
      return true
    }

    ErrorsModal.openModal('invalid_permission')
    return false
  }

  function NotFoundPermissionException(action, resource) {
    this.message = 'Cannot find permission for action:' + action + ', resource:' + resource
    this.name = 'NotFoundPermissionException'
  }

  function isAdmin(account) {
    const member = account.me
    return !!member.admin
  }

  function isViewOnly(account) {
    const member = account.me
    return !!member.view_only
  }

  function instanceCheck(action, resource, account, instance) {
    if (instance && instance.user && session && session.user && action === 'edit') {
      if (resource === 'folders') {
        return folderEditInstanceCheck(action, resource, account, instance)
      }

      if (resource === 'records') {
        return recordEditInstanceCheck(action, resource, account, instance)
      }
    }

    return false
  }

  function folderEditInstanceCheck(action, resource, account, instance) {
    if (can('add', resource, account) && instance.user.id === session.user.id) {
      if (instance && instance.records && !instance.records.length) {
        return true
      }
    }

    return false
  }

  function recordEditInstanceCheck(action, resource, account, instance) {
    if (can('add', resource, account) && instance.user.id === session.user.id) {
      if (!instance.log.id && !instance.note.id) {
        return true
      }
    }

    return false
  }
}
