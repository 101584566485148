export function Controller($timeout, gettextCatalog) {
  'ngInject'

  this.$onInit = () => {
    this.recordIds = this.resolve.recordIds || []
    this.type = this.resolve.type
    this.batch = this.resolve.batch
    this.certificates = this.resolve.certificates
    this.userSignatures = this.resolve.userSignatures
    this.params = this.resolve.$transitions.params()
    
    if (this.recordIds.length > 1) {
      this.modalTitle = `Print/Export ${this.recordIds.length} records`
    } else if (this.recordIds.length === 1) {
      this.modalTitle = `Print/Export 1 record`
    } else {
      this.modalTitle = `Print/Export`
    }
  }

  this.closeModal = (timeout) => {
    if (timeout) {
      $timeout(this.closeModal, 500)
    } else {
      this.dismiss({ $value: 'cancel' })
    }
  }
}
