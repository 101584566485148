import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'

export function ActivitiesDeactivated() {
  'ngInject'

  let service = {
    flag: flag
  }

  return service

  function flag(activities) {
    let deactivatedData = {}

    return _forEach(activities, function (activity, key) {
      if (_includes(['log_was_deactivated', 'note_was_deactivated'], activity.type)) {
        deactivatedData[(activity.log && activity.log.id) || activity.note.id] = activity
      }

      if (_includes(['log_was_created', 'note_was_created'], activity.type)) {
        activities[key].$deactivated = deactivatedData[(activity.log && activity.log.id) || activity.note.id] || null
      }
    })
  }
}
