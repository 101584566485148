import * as React from 'react'
import { View, createStyledComponent } from '../../components/styled'
import { colors } from '../../styles'

const StateIcon = createStyledComponent(View, {
  width: 8,
  height: 8,
  borderRadius: 4,
  marginRight: 5
})

export interface TaskStateBadgeProps {
  state: string
  name: string
}

export default function TaskStateBadge(props: TaskStateBadgeProps) {
  const { state, name } = props

  const getColor = () => {
    switch (state) {
      case 'open': {
        return colors.positive
      }

      case 'pending': {
        return colors.warning
      }

      case 'closed': {
        return colors.negative
      }
    }
  }

  const getBadgeName = () => {
    if (name) {
      return name
    }

    const stateName = state;
    return stateName.charAt(0).toUpperCase() + stateName.slice(1)
  }

  const color = getColor()

  const badgeName = getBadgeName()

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View>
        <StateIcon style={{ backgroundColor: color }} />
      </View>
      <View style={{ flexGrow: 1 }} >{badgeName}</View>
    </View>
  )
}
