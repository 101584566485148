import _pick from 'lodash/pick'
import _omitBy from 'lodash/omitBy'
import _isNull from 'lodash/isNull'
import _keys from 'lodash/keys'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'

export function SubscriptionsData(
  $http,
  $q,
  SubscriptionModel,
  accountsPoller,
  session,
  collections,
  pagination,
  EventsData,
  validator,
  BASE_URL,
  API_VERSION,
  ptApi
) {
  'ngInject'

  const service = {
    calculateUsedPercent,
    checkoutPlan,
    create,
    estimatePlan,
    renewalEstimate,
    fetchBilling,
    fetchDiscounts,
    fetchSubscription,
    getAll,
    getBillingAddress,
    getHostedPageData,
    getLimitWarning,
    getNext,
    getPlanData,
    getPlanPrice,
    init,
    pollForAccount,
    setUpBillingData,
    updateDetails,
    updateBilling,
    updatePayment,
    updatePlan
  }

  const cache = {
    fetchBilling: {}
  }

  // If there are <= the amount below, show the limit warning / upgrade info
  const limitWarnings = {
    members: 2,
    records: 5
  }

  return service

  function calculateUsedPercent(used, total) {
    if (total > 0) {
      return (used / total) * 100
    }

    // unlimited, so used 0%
    return 0
  }

  function checkoutPlan(subscriptionId, data) {
    return ptApi
      .request({
        method: 'POST',
        url: `/subscriptions/${subscriptionId}/actions/checkout`,
        data: data
      })
      .then((response) => response.data)
  }

  function create(model) {
    const data: any = {
      name: model.name,
      company_name: model.company_name
    }

    if (model.reseller_id) {
      data.reseller_id = model.reseller_id
    }

    if (model.industry_id) {
      data.industry_id = model.industry_id
    }

    if (model.country_code) {
      data.country_code = model.country_code
    }

    if (model.affiliate_id) {
      data.affiliate_id = model.affiliate_id
    }

    return SubscriptionModel.$build(data)
      .$save()
      .$asPromise()
      .then(function (subscription) {
        EventsData.pushEvent('subscription_was_created', {
          subscriptionId: subscription.id
        })
        return subscription
      })
  }

  function estimatePlan(subscriptionId, data) {
    return ptApi
      .request({
        method: 'POST',
        url: `/subscriptions/${subscriptionId}/actions/update-estimate`,
        data: data
      })
      .then((response) => response.data)
  }

  function renewalEstimate(subscriptionId) {
    return ptApi
      .request({
        method: 'POST',
        url: `/subscriptions/${subscriptionId}/actions/renewal-estimate`,
        data: {}
      })
      .then((response) => response.data)
  }

  function fetchBilling(subscription, fromApi = false) {
    if (!fromApi && _get(cache.fetchBilling, subscription.id)) {
      return $q.resolve(cache.fetchBilling[subscription.id])
    }

    return (
      subscription.billing
        .$fetch()
        .$asPromise()
        // Cache the data against the subscription ID to prevent unneeded api calls
        .then((response) => {
          return setUpBillingData(subscription.id, response)
        })
    )
  }

  function fetchDiscounts(subscriptionId) {
    return ptApi
      .request({ method: 'GET', url: `/subscriptions/${subscriptionId}/discounts` })
      .then((response) => response.data)
  }

  function fetchSubscription(id, params = {}) {
    return SubscriptionModel.$find(id, params).$asPromise()
  }

  function getAll(subscription) {
    const collection = subscription.$collection()

    return collections.getAll(collection)
  }

  // Only get the not-null values of the address
  function getBillingAddress(billing) {
    return _omitBy(
      _pick(billing, [
        'first_name',
        'last_name',
        'company',
        'phone',
        'address1',
        'address2',
        'city',
        'state',
        'zip',
        'country'
      ]),
      _isNull
    )
  }

  function getHostedPageData(id) {
    return ptApi.request({
      method: 'GET',
      url: `/hosted-pages/${id}`
    })
  }

  function getLimitWarning(type) {
    return limitWarnings[type]
  }

  function getNext(results, limit, include) {
    const params = {
      limit: limit || 10,
      page: pagination.nextPage(results),
      include: include
    }

    return results.$fetch(params).$asPromise()
  }

  function getPlanData() {
    return ptApi.request({ method: 'GET', url: '/plans' }).then((response) => response.data)
  }

  function getPlanPrice(planId, values) {
    return ptApi
      .request({
        method: 'POST',
        url: `/plans/${planId}/price`,
        data: {
          users: values.users,
          records: values.records
        }
      })
      .then((response) => response.data)
  }

  function init(filter) {
    const params: any = {
      sort: 'name'
    }

    if (filter) {
      params.filter = 'name~=' + filter
    }

    return SubscriptionModel.$collection(params)
  }

  function patch(subscription, propertyNames) {
    return subscription
      .$save(propertyNames)
      .$asPromise()
      .then(
        function (response) {
          return response
        },
        function (rejection) {
          return validator.rejection(rejection.$response)
        }
      )
  }

  function pollForAccount(subscriptionId) {
    // Get accounts for subscription
    return fetchSubscription(subscriptionId, { include: 'accounts' }).then(function (subscription) {
      return accountsPoller.pollForCompletion(subscription.accounts[0]).then(function (account) {
        return account
      })
    })
  }

  /**
   * Structure the billing data better before returning to the controller
   */
  function setUpBillingData(subscriptionId, billingData) {
    const billing = {
      data: billingData,
      address: null,
      paymentSource: _get(billingData.billing, 'payment_source'),
      paymentDetails: null,
      hasUpcomingChanges: _isNull(billingData.upcoming) ? false : true
    }

    const address = getBillingAddress(billingData.billing)

    if (billing.paymentSource) {
      billing.paymentDetails = _omitBy(billingData.billing[billing.paymentSource], _isNull)
    }

    if (_keys(address).length) {
      billing.address = address
    }

    cache.fetchBilling[subscriptionId] = _cloneDeep(billing)

    return billing
  }

  function updateDetails(subscription) {
    const data: any = {
      name: subscription.name,
      company_name: subscription.company_name
    }

    if (subscription.industry) {
      data.industry_id = subscription.industry.id
    }

    if (subscription.country_code) {
      data.country_code = subscription.country_code
    }

    return ptApi
      .request({
        method: 'PATCH',
        url: `/subscriptions/${subscription.id}`,
        data: data
      })
      .then((response) => response.data)
  }

  function updateBilling(subscriptionId, data) {
    return ptApi
      .request({
        method: 'PATCH',
        url: `/subscriptions/${subscriptionId}/billing`,
        data: data
      })
      .then((response) => response.data)
  }

  function updatePayment(subscriptionId, data) {
    return ptApi
      .request({
        method: 'POST',
        url: `/subscriptions/${subscriptionId}/actions/update_payment`,
        data: data
      })
      .then((response) => response.data)
  }

  function updatePlan(subscriptionId, data) {
    return ptApi
      .request({
        method: 'PATCH',
        url: `/subscriptions/${subscriptionId}/actions/plan`,
        data: data
      })
      .then((response) => {
        EventsData.broadcast('subscription:changed')

        return response.data
      })
  }
}
