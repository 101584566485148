import * as React from 'react'
import { Text } from 'reactxp'
import { PTDate } from '../../components'
import { View, createStyledComponent } from '../../components/styled'
import { UserAvatar } from '../../user/components'
import { spacing } from '../../styles'
import { Activity } from '../models'

interface Props {
  activity: Activity
}

const Wrapper = createStyledComponent(View, {
  flexDirection: 'row',
  alignItems: 'center'
})

const Avatar = createStyledComponent(View, {
  marginRight: spacing.half
})

const Username = createStyledComponent(View, {
  flexGrow: 1
})

export const ActivityUser = ({ activity }: Props) => {
  return (
    <Wrapper>
      <Avatar>
        <UserAvatar user={activity.user}></UserAvatar>
      </Avatar>
      <Username>
        <Text style={{ fontWeight: '500' }}>
          {activity.user.firstName} {activity.user.lastName}
        </Text>
        <Text>
          <PTDate user={activity.user} dateTime={activity.createdAt} />
        </Text>
      </Username>
    </Wrapper>
  )
}
