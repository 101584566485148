import { Controller } from './controller'
import view from './view.html'

export const ptIauditorAuditItem = {
  bindings: {
    audit: '<',
    customFields: '<'
  },
  controller: Controller,
  template: view
}
