import * as React from 'react'
import * as moment from 'moment-timezone'
import { Record } from '../../record/index'
import { Log } from '../../logs/index'
import { Text, View } from 'reactxp'
import { Button } from '../../components'
import { createStyledView, createStyledComponent, ScrollView } from '../../components/styled'
import { spacing, colors, fontSizes } from '../../styles'
import { createTaskAction } from '../api/index'
import { Task } from '../models/index'

interface TaskLogsListProps {
  task: Task
  record: Record
  logs: Log[]
  dateFormat?: string
  onAssigned?: (log?: Log) => void
  isViewOnly?: boolean
}

const Wrapper = createStyledView({
  paddingLeft: 20,
  paddingRight: 20
})

const InspectionWrapper = createStyledComponent(ScrollView, {
  maxHeight: 230,
  paddingLeft: 20,
  paddingRight: 20
})

const InspectionRow = createStyledView({
  flexDirection: 'row',
  alignItems: 'center',
  paddingVertical: spacing.base,
  borderBottomWidth: 1,
  borderColor: colors.neutralTintSix
})

export const TaskLogsList = ({
  task,
  record,
  logs,
  onAssigned,
  dateFormat = 'Do MMM YYYY H:mm a',
  isViewOnly
}: TaskLogsListProps) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const assignToLog = async (log: Log) => {
    setIsLoading(true)

    await createTaskAction({
      action: 'assign-to-log',
      taskId: task.id,
      payload: { log_id: log.id }
    })

    onAssigned(log)
    setIsLoading(false)
  }

  const removeFromLog = async (log: Log) => {
    setIsLoading(true)

    await createTaskAction({
      action: 'unassign-from-log',
      taskId: task.id
    })

    onAssigned()
    setIsLoading(false)
  }

  return (
    <>
      <Wrapper>
        <Text style={{ fontSize: fontSizes.medium, fontWeight: '500' }}>{record.name}</Text>
        <View style={{ height: spacing.base }} />
        {!logs || (logs && !logs.length && <Text>There are no logs available to select.</Text>)}
      </Wrapper>
      {logs && !!logs.length && (
        <InspectionWrapper>
          {logs &&
            !!logs.length &&
            logs.map((log, index) => {
              const logIsSelected = task.log && task.log.id === log.id

              return (
                <InspectionRow
                  key={log.id}
                  style={{
                    ...(index === 0 && { borderTopWidth: 1 })
                  }}
                >
                  <View style={{ flexShrink: 1, marginRight: spacing.half }}>
                    <Text style={{ color: colors.neutralTintTwo }}>INSPECTION:</Text>
                  </View>
                  <View style={{ flexGrow: 1 }}>
                    <Text style={{ color: `rgb(${log.state.colour})` }}>
                      {log.state.name} {moment(log.createdAt).format(dateFormat)}
                    </Text>
                  </View>
                  <View style={{ flexShrink: 1, marginLeft: spacing.base }}>
                    {!isViewOnly && (
                      <Button
                        label={logIsSelected ? 'Unassign' : 'Select'}
                        theme={{
                          labelColor: 'neutral',
                          iconColor: 'neutral',
                          backgroundColor: 'white',
                          borderColor: 'neutralTintFive'
                        }}
                        onPress={() => (logIsSelected ? removeFromLog(log) : assignToLog(log))}
                        locked={isLoading}
                      />
                    )}
                  </View>
                </InspectionRow>
              )
            })}
        </InspectionWrapper>
      )}
    </>
  )
}

export { TaskLogsList as default, TaskLogsListProps }
