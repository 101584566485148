import { Controller } from './controller'
import view from './view.html'

export const ptComplianceWidgetFilter = {
  bindings: {
    folders: '<',
    onApplyFilter: '&'
  },
  controller: Controller,
  template: view
}
