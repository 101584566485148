export function Controller(gettextCatalog, ChecklistsData, recordsData, context) {
  'ngInject'

  const s = {
    /// Checkbox label
    replaceExistingChecklists: gettextCatalog.getString('Replace existing checklists?')
  }

  const saveSuccess = () => {
    this.dismiss({ $value: 'saved' })
  }

  this.$onChanges = () => {}

  this.$onInit = () => {
    this.records = this.resolve.records

    this.checklist = {
      checklists: [],
      replace: false
    }
    this.checklistFields = [
      {
        key: 'replace',
        type: 'checkbox',
        templateOptions: {
          label: s.replaceExistingChecklists
        }
      }
    ]
    this.error = null
    this.modalTitle = gettextCatalog.getPlural(
      this.records.length,
      'Add checklists to 1 record',
      'Add checklists to {{$count}} records',
      {}
    )
    this.popover = {
      add: {
        open: false
      },
      remove: {
        open: false
      }
    }
    this.saving = false
  }

  this.pickerOptions = {
    searchPlaceholder: gettextCatalog.getString('Find Checklists...'),
    contextLabel: gettextCatalog.getString('Checklists'),
    labelProperty: 'name',
    canCreate: false
  }

  this.getAvailableChecklists = () => {
    return ChecklistsData.getAccountChecklists(context.get('account'))
  }

  this.save = (options) => {
    this.saving = true

    return recordsData
      .saveChecklists(context.get('account'), this.records, this.checklist.checklists, {
        remove: options.remove,
        replace: this.checklist.replace
      })
      .then(saveSuccess)
  }

  this.popoverClose = (name) => {
    this.popover[name] = {
      open: false
    }
  }

  this.popoverToggle = (name) => {
    const newValue = !this.popover[name].open

    this.popover[name] = {
      open: newValue
    }
  }

  this.saveChecklists = (checklists) => {
    this.checklist.checklists = checklists
  }
}
