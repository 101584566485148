import * as React from 'react'
import { View } from 'reactxp'
import { cloneDeep, isArray } from 'lodash'
import { spacing, colors } from '../../styles'
import { AccountUpload, UploadThumbnail, CreateUploads } from '../../upload'
import { Icon } from '../../components'
import { createStyledView } from '../../components/styled'

interface Props {
  accountId: string
  uploads?: AccountUpload[]
  onChangeFiles?: (files: AccountUpload[]) => void
  itemsPerRow?: number
  onDeleteUpload: (id: string) => void
  onPressUpload?: (upload: AccountUpload) => void
  editable: boolean
}

const ThumbnailContainer = createStyledView({
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: spacing.half,
  overflow: 'visible'
})

export const UploadThumbnails = ({
  accountId,
  uploads,
  onChangeFiles,
  itemsPerRow = 3,
  onDeleteUpload,
  onPressUpload,
  editable
}: Props) => {
  const [files, setFiles] = React.useState([])
  const [thumbnailContainerValues, setThumbnailContainerValues] = React.useState(null)
  const fileUpload = React.useRef(null)
  const fileForm = React.useRef(null)

  React.useEffect(() => {
    if (isArray(uploads)) {
      setFiles(
        uploads.map((upload) => ({
          ...upload,
          uploaded: true
        }))
      )
    }
  }, [uploads])

  const onFileChanges = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // persist the event so we can clear the value later
    event.persist()

    const filesArray = Array.from(event.target.files).map((file, index) => {
      return {
        // need to give a unique id for iterating in the view
        id: `${index}${new Date().getTime()}`,
        view: URL.createObjectURL(file),
        uploaded: false,
        error: false,
        mime: file.type,
        progress: 9999,
        uploading: false,
        file
      }
    })

    await Promise.all(
      filesArray.map(async (file) => {
        const getProgress = (progressEvent) => {
          file.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)

          setFiles(cloneDeep([...files, ...filesArray]))
        }

        file.uploading = true

        try {
          const upload = await CreateUploads({
            accountId,
            uploadProgressCallback: getProgress,
            payload: { file: file.file }
          })

          URL.revokeObjectURL(file.view)

          file.id = upload.id
          file.uploaded = true
          file.view = upload.view

          setFiles([...files, ...filesArray])
        } catch (e) {
          file.error = true

          setFiles([...files, ...filesArray])
        } finally {
          file.uploading = false

          setFiles([...files, ...filesArray])
        }

        return file
      })
    )

    setFiles([...files, ...filesArray])
    onChangeFiles([...files, ...filesArray])

    event.target.value = ''
  }

  const onPressUploadNew = () => {
    fileUpload.current.click()
  }

  return (
    <View onLayout={setThumbnailContainerValues}>
      <form style={{ display: 'none', position: 'absolute' }} ref={fileForm}>
        <input ref={fileUpload} type="file" onChange={onFileChanges} multiple />
      </form>
      <ThumbnailContainer>
        {files.map((file: AccountUpload) => (
          <UploadThumbnail
            key={file.id}
            file={file}
            containerWidth={thumbnailContainerValues && thumbnailContainerValues.width}
            itemsPerRow={itemsPerRow}
            onDeleteUpload={onDeleteUpload}
            onPressUpload={onPressUpload}
            editable={editable}
          />
        ))}
        {editable && (
          <View
            onPress={onPressUploadNew}
            style={{
              width:
                (thumbnailContainerValues && thumbnailContainerValues.width - itemsPerRow * spacing.half) / itemsPerRow,
              margin: spacing.quarter,
              padding: spacing.half,
              borderColor: colors.neutralTintFive,
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              height: 100
            }}>
            <Icon name="plus" size="huge"></Icon>
          </View>
        )}
      </ThumbnailContainer>
    </View>
  )
}
