import _find from 'lodash/find'
import _cloneDeep from 'lodash/cloneDeep'
import _map from 'lodash/map'

export function Controller(
  $scope,
  $timeout,
  $state,
  $stateParams,
  recordsData,
  iAuditorData,
  recordsPagination,
  BASE_URL,
  EventsData,
  context,
  permissionsCheck
) {
  'ngInject'

  let allRecordsCollection = null
  let attempts = 0
  let recordsCache = []

  /**
   * Get all records belonging to a folder
   */
  const getRecords = (folder) => {
    let records = _find(recordsCache, { folderId: folder.id })

    allRecordsCollection = recordsData.init(context.get('account'), folder.id, recordsPagination.getPerPage(), {
      include: 'folder,uploads,fields',
      sort: 'name,identifier,serial'
    })

    this.loading = {
      value: true
    }

    // If we already have the records cached for the selected folder
    if (records) {
      this.records = _cloneDeep(records.data)

      this.loading = {
        value: false
      }

      highlightSelectedRecords()
    } else {
      recordsData
        .getAllRecords(allRecordsCollection)
        .then((folderRecords) => {
          // An error in the app was occuring without this 'if' statement, the user would quickly change folders
          // while the request was loading, resulting in 'folderRecords' being undefined and not an empty array.
          if (folderRecords) {
            recordsCache.push({
              folderId: folder.id,
              data: _cloneDeep(folderRecords)
            })

            this.records = folderRecords

            highlightSelectedRecords()
          }
        })
        .finally(() => {
          this.loading = {
            value: false
          }

          allRecordsCollection = null
        })
    }
  }

  /**
   * Helper function which checks the audit data expiry time (seconds) against current time (ms > seconds)
   */
  const hasAuditExpired = () => {
    if (this.audit.expires <= parseInt(String(Date.now() / 1000), 10)) {
      return true
    }
    return false
  }

  const highlightSelectedRecords = () => {
    this.records.forEach((record) => {
      if (_find(this.recordsSelected, { id: record.id })) {
        record.$selected = true
      }
    })
  }

  this.$onInit = () => {
    this.permissions = {
      logs: permissionsCheck.can('add', 'logs', context.get('account'))
    }
    this.audit = this.resolve.audit
    this.auditWasAttached = false
    this.downloadError = false
    this.auditRecords = this.audit.records.data
    this.folder = {}
    this.records = []
    this.recordsSelected = []

    this.loading = {
      value: false
    }

    $scope.$on('iauditor_audit_was_used', (event, data) => {
      if (data.data.id === $stateParams.auditId) {
        this.auditWasAttached = true

        this.deselectRecords()
      }
    })

    $scope.$on('modal.closing', () => {
      this.folder.$highlight = false
    })
  }

  this.clearSearch = () => {
    this.records = []
    this.folder = {}
  }

  this.deselectRecords = () => {
    this.recordsSelected = []

    this.records.forEach((item) => {
      if (item.$selected) {
        item.$selected = false
      }
    })
  }

  this.getCustomFieldValue = (fieldKey) => {
    return iAuditorData.getCustomFieldValue(this.audit.custom_fields, fieldKey)
  }

  /**
   * Checks if an audit's PDF can be downloaded. If not, then attempt to re-fetch the audit.
   * If that fails, show an error in the view.
   */
  this.downloadFile = () => {
    if (!hasAuditExpired()) {
      iAuditorData
        .downloadAudit(this.audit)
        .then(() => {
          attempts = 0
        })
        .catch(() => {})
    } else if (attempts <= 2) {
      attempts = attempts + 1

      iAuditorData.getAuditById(this.audit.id).then((refreshedAudit) => {
        this.audit = refreshedAudit

        this.downloadFile()
      })
    } else {
      this.downloadError = true
      attempts = 0
    }
  }

  this.folderChange = (folder) => {
    this.auditWasAttached = false

    // folder was de-selected while records were being fetched
    if (allRecordsCollection && allRecordsCollection.$hasPendingActions()) {
      allRecordsCollection.$cancel()
      this.clearSearch()
    } else if (!folder) {
      // folder was de-selected after records were fetched
      this.clearSearch()
    } else if (!this.folder.id || this.folder.id !== (folder && folder.id)) {
      this.folder = folder
      getRecords(folder)
    }
  }

  this.gotoState = (event) => {
    if (!this.recordsSelected.length) {
      event.event.preventDefault()
    } else {
      this.auditWasAttached = false
      $state.go(event.state, {
        recordIds: _map(this.recordsSelected, 'id').join(',')
      })
    }
  }

  this.gotoRecord = (id) => {
    this.dismiss()

    $timeout(() => {
      $state.go('^.records', { recordId: id })
    })
  }

  this.previewUrl = () => {
    return BASE_URL + this.audit.view
  }

  this.recordSearch = (query) => {
    this.loading = {
      value: true
    }

    EventsData.broadcast('folder_picker_clear_selection')

    recordsData
      .search({
        limit: 1000,
        filter: 'name~=' + query,
        include: 'folder,fields,uploads'
      })
      .then((response) => {
        this.records = response
        highlightSelectedRecords()
      })
      .finally(() => {
        this.loading = {
          value: false
        }
      })
  }

  this.selectRecord = (event) => {
    this.auditWasAttached = false

    if (event.record && event.record.$selected) {
      event.record.$selected = false
      this.recordsSelected = this.recordsSelected.filter((record) => {
        return record.id !== event.record.id
      })
    } else if (event.record && !_find(this.recordsSelected, { id: event.record.id })) {
      event.record.$selected = true
      this.recordsSelected = this.recordsSelected.concat([event.record])
    }
  }

  this.viewSelection = () => {
    if (this.recordsSelected.length) {
      EventsData.broadcast('folder_picker_clear_selection')

      this.records = this.recordsSelected
    }
  }
}
