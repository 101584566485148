export function ptSummariesUnsubscribeRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('unsubscribe', {
    parent: 'layoutSplash',
    url: '/unsubscribe/{summaryId}',
    data: { secure: false },
    views: {
      'content@layoutSplash': {
        component: 'ptSummariesUnsubscribe'
      }
    }
  })
}
