import angular from 'angular'

export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    valueChange
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.value) {
      $ctrl.value = angular.copy($ctrl.value)
    }
  }

  function valueChange() {
    $ctrl.onValueChange({ value: $ctrl.value })
  }
}
