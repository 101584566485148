import angular from 'angular'
import { react2angular } from 'react2angular'

import { RecordsBatchCloneRoute } from './route'
import { ptRecordsBatchClone } from './component'
import RecordsBatchCloneForm from 'AppComponents/records/RecordsBatchCloneForm'

export const ptRecordsBatchCloneModule = angular
  .module('records.ptRecordsBatchClone', [])
  .config(RecordsBatchCloneRoute)
  .component('ptRecordsBatchCloneForm', react2angular(RecordsBatchCloneForm, ['records', 'formUpdated']))
  .component('ptRecordsBatchClone', ptRecordsBatchClone).name
