import angular from 'angular'
import { ProcessesData } from './services/data'
import { ProcessesModel } from './services/models'
import { ptProcessesTable } from './components/pt-processes-table/component'
import { ptProcessesTableRow } from './components/pt-processes-table-row/component'

export const ProcessesModule = angular
  .module('processes', [])
  .factory('ProcessesData', ProcessesData)
  .factory('ProcessesModel', ProcessesModel)
  .component('ptProcessesTable', ptProcessesTable)
  .component('ptProcessesTableRow', ptProcessesTableRow).name
