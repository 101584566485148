export function page($rootScope) {
  'ngInject'

  let service = {
    setTitle: setTitle,
    resetTitle: resetTitle,
    leftMenuOpen: leftMenuOpen,
    setAccountName: setAccountName,
    removeAccountName: removeAccountName,
    setAccountLogo: setAccountLogo,
    removeAccountLogo: removeAccountLogo
  }
  return service

  ////////////////

  function setTitle(title) {
    if (!$rootScope.prevTitles) {
      $rootScope.prevTitles = []
    }
    $rootScope.prevTitles.push(document.title)
    document.title = 'Papertrail | ' + title
  }

  /* use when unload a modal */
  function resetTitle() {
    if ($rootScope.prevTitles.length > 0) {
      const oldTitle = $rootScope.prevTitles.pop()
      document.title = oldTitle
    } else {
      document.title = 'Papertrail'
    }
  }

  function leftMenuOpen(open) {
    $rootScope.leftMenuOpen = open
  }

  function setAccountName(name) {
    $rootScope.accountName = name
  }

  function removeAccountName() {
    $rootScope.accountName = null
  }

  function setAccountLogo(logo) {
    $rootScope.accountLogo = logo
  }

  function removeAccountLogo() {
    $rootScope.accountName = null
  }
}
