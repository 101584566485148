import _find from 'lodash/find'
import _filter from 'lodash/filter'

export function Controller($scope, session, membersData, context) {
  'ngInject'

  let vm = this

  vm.members = null
  vm.displayableMembers = null

  ////////////////

  vm.$onInit = function () {
    getMembers()

    listen()
    watch()
  }

  function getMembers() {
    return membersData.getAll(context.get('account').id, vm.type).then(function (members) {
      let currentUser: any = _find(members, { user: { id: session.user.id } })

      if (currentUser) {
        currentUser.$currentUser = true
      }

      vm.members = members
      vm.displayableMembers = vm.members
    })
  }

  function listen() {
    $scope.$on('member_was_created', updateMemberEvent)
    $scope.$on('member_was_updated', updateMemberEvent)
  }

  function watch() {
    $scope.$watch('vm.filter', function (newVaue, oldValue) {
      if (newVaue !== oldValue) {
        if (!vm.filter) {
          vm.displayableMembers = vm.members
        }

        vm.displayableMembers = _filter(vm.members, function (member) {
          if (
            member.user.first_name.search(new RegExp(vm.filter, 'i')) > -1 ||
            member.user.last_name.search(new RegExp(vm.filter, 'i')) > -1
          ) {
            return member
          }
        })
      }
    })
  }

  function updateMemberEvent() {
    getMembers()
  }
}
