import { Controller } from './controller'
import view from './view.html'

export const ptNotificationPartnershipCreated = {
  bindings: {
    onRead: '&',
    onOpenRecord: '&',
    notification: '<',
    view: '<'
  },
  controller: Controller,
  template: view
}
