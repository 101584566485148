import _filter from 'lodash/filter'

export function Controller(recordsData, recordsSelection) {
  'ngInject'

  const sortDeletable = () => {
    this.deletable = _filter(this.records, (record) => {
      if (record.log.id || record.note.id) {
        return false
      }
      return true
    })

    this.undeletable = _filter(this.records, (record) => {
      if (record.log.id || record.note.id) {
        return true
      }
      return false
    })
  }

  this.$onInit = () => {
    this.deleting = false
    this.records = this.resolve.records
    this.deletable = []
    this.undeletable = []
    this.error = null
    this.folder = {}

    sortDeletable()
  }

  this.deleteRecords = () => {
    let result

    if (this.deletable.length) {
      this.deleting = true

      result = recordsData
        .batchDelete(this.deletable)
        .then(() => {
          this.dismiss({ $value: 'saved' })
          recordsSelection.remove(this.deletable)
          recordsSelection.setAll(false)
        })
        .catch((rejection) => {
          this.deleting = false
          this.error = rejection.$response.data.error
        })
    }

    return result
  }
}
