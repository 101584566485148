import lazy from 'lazy.js'

export function Controller($scope, BASE_URL, uploadsData) {
  'ngInject'

  let vm = this
  let uploadId = null

  vm.type = 'default'
  vm.url = null
  vm.mime = null

  vm.$onInit = function () {
    setupCover()

    watch()
  }

  function setupCover() {
    let upload = getDefaultUpload()

    if (upload) {
      vm.type = uploadsData.getUploadType(upload)
      setCoverUpload(upload)
    }
  }

  function watch() {
    $scope.$watch(getDefaultUpload, function (newValue, oldValue) {
      if (oldValue !== newValue) {
        if (!newValue) {
          vm.type = 'default'
          setCoverUpload(null)
        } else if (newValue.id !== uploadId) {
          vm.type = uploadsData.getUploadType(newValue)
          setCoverUpload(newValue)
        }
      }
    })
  }

  function getDefaultUpload() {
    return lazy(vm.record.uploads).find(function (upload) {
      return upload && upload.default
    })
  }

  function setCoverUpload(upload) {
    if (upload) {
      vm.url = fileUrl(upload.view)
      vm.mime = upload.mime
      uploadId = upload.id
    } else {
      vm.url = null
      vm.mime = null
      uploadId = null
    }
  }

  function fileUrl(url) {
    return BASE_URL + url
  }
}
