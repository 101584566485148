import * as React from 'react'
import { View } from 'reactxp'
import { cloneDeep } from 'lodash'
import { DiscardCriteria, DiscardCriteriaQuestion } from '../index'
import { ChecklistField, ChecklistSection } from '../../checklist'
import { ChecklistSectionComponent } from '../../checklist/components'

export type DiscardCriteriaQuestionWithAnswer = DiscardCriteriaQuestion & { value?: boolean }

interface Props {
  criteria: DiscardCriteria & { questions: DiscardCriteriaQuestionWithAnswer[] }
  onChange?: (section: DiscardCriteria, field: ChecklistField) => void
  open?: boolean
}

export const DiscardCriteriaGroup = (props: Props) => {
  const { criteria, onChange = () => undefined, open = true } = props
  const { id, name, description, questions } = criteria

  const section: ChecklistSection = {
    id: id as any,
    order: 1,
    label: name,
    description,
    fields: questions.map((item: DiscardCriteriaQuestionWithAnswer) => {
      const { id: questionId, title, description: questionDescription, position, value } = item
      return {
        id: questionId as any,
        label: title,
        description: questionDescription,
        type: '',
        value,
        order: position
      }
    })
  }

  return (
    <View>
      <ChecklistSectionComponent
        section={section}
        collapsible={true}
        open={open}
        showSectionIcon={false}
        onChange={(newSection, newField) => {
          const newCriteria = cloneDeep(criteria)
          newCriteria.questions.forEach((question: DiscardCriteriaQuestionWithAnswer, index) => {
            question.value = newSection.fields[index].value as boolean
          })
          onChange(newCriteria, newField)
        }}
      />
    </View>
  )
}
