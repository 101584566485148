import angular from 'angular'
import _remove from 'lodash/remove'

export function Controller(uploadFileActions, BASE_URL, context, $uibModal, gettextCatalog, $scope,) {
  'ngInject'

  let vm = this

  vm.control = {
    signatureUrl: signatureUrl,
    view: view,
  }

  vm.previewUrl = null
  vm.uploading = false

  this.model = []
  this.signatureData = {};
 
    this.$onInit = () => {
      if(this.field && this.field.responseUploads && this.field.responseUploads.data && this.field.responseUploads.data.length > 0){
          this.fileData = this.field.responseUploads.data[0]
          this.fileResponse =  this.field.responseUploads.data[0]
          this.fileUploaded = true;
      }
    $scope.$on('signatureSaved', (event, data) => {
      if(this.field.id === data.field.id) {
        this.signatures = data.signatures;
        this.fieldData = data.field; 
        this.model = data.model;
        this.signatureId = this.model[0].value
        const fieldId = this.field.id; 
        if (!this.signatureData[fieldId]) {
            this.signatureData[fieldId] = {};
        }
        this.signatureData[fieldId][this.signatureId] = data.signatures;
        this.signatureSaved = true;
        this.signaturePreviewUrls = this.getSignaturePreviewUrls(this.signatureData);
      } 
       
    });

    $scope.$on('fileUploaded', (event, data) => { 
    
      if(data.fileData.id === this.field.id) {
        this.fileData = data.fileData;
        this.fileResponse = data.response;
        this.fileUploaded = true;
        if (!this.signatureSaved) {
          this.filePreviewUrl = this.getFilePreviewUrl(this.fileData);
      }
  
    }
  });
    this.files = this.field.files || []

    this.accountId = context.get('account').id
    this.modalTitle = gettextCatalog.getString('Add Signature')
   
  }

  this.openSignatureModal = () => {

    $uibModal
      .open({
        component: 'ptChecklistItemSignatureModal',
        size: 'l',
        backdrop: 'static',
        resolve: {  signatureFile: () => this.signatureFile,
                    signatureSaved: () => this.signatureSaved,
                    fileUploaded: () => this.fileUploaded,
                    field: () => this.field,
                    signatures: () => this.signatures,
        }
        
      })
      .result.then((signatureFile) => {
        this.signatureFile = signatureFile;
      })
      .catch(() => {});
      }

      this.closeModal = (signatureFile) => {
        $uibModal.close(signatureFile);
      };

      this.base64ToBlob = (base64, mimeType) => {
        const bytes = atob(base64.split(',')[1]);
        const arr = new Uint8Array(bytes.length);

        for (let i = 0; i < bytes.length; i++) {
            arr[i] = bytes.charCodeAt(i);
        }

        return new Blob([arr], { type: mimeType });
    }
     
      // Generate signature preview URL
      this.signaturePreviewUrls = [];
      this.getSignaturePreviewUrls = (signatureData) => {
        const signaturePreviews = {};

        Object.keys(signatureData).forEach(fieldId => {
          const fieldSignatures = signatureData[fieldId];
            signaturePreviews[fieldId] = {};

            Object.keys(fieldSignatures).forEach(signatureId => {
                const signature = fieldSignatures[signatureId];
                const previewUrls = signature.map(signatureData => {
                    const blob = this.base64ToBlob(signatureData, 'image/png');
                    return URL.createObjectURL(blob);
                });

                signaturePreviews[fieldId][signatureId] = previewUrls;
            });
          });
          return signaturePreviews;
      };
      // Generate uploaded file signature preview URL
    this.getFilePreviewUrl = () => {
      if (this.fileUploaded && this.fileResponse) {
          return uploadFileActions.getFileUrl(this.fileResponse.view);
      }
      return null;
  };
  

  this.hasFiles = () => {
    if(this.fieldData && this.fieldData.uploads){
        return this.fieldData.uploads;
    }
}


  this.setFiles = (event) => {
    this.field.uploads = event.model
    this.field.files = event.files
  }

  this.setUploading = (event) => {
    this.uploading = event.uploading
  }

  this.onModelChange = (event) => {
    this.model = event.model;
    this.files = event.files;
  };

  this.$onChanges = function (changes) {
    if (changes.model) {
      this.model = angular.copy(this.model)
    }

    if (changes.uploading) {
      this.uploading = angular.copy(this.uploading)
    }
  }

    this.setModel = () => {
      this.onModelChange({
        $event: {
          model: this.model,
          files: this.files,
        },
      });
    };

  this.removeSignature = (uploadId) => {
   
      _remove(this.files, (item: any) => {
        return item.value === uploadId
      })
  
      _remove(this.model, (item: any) => {
        return item.value === uploadId
      })
      this.setModel();
      this.signatureSaved = false
      this.fileUploaded = false

     
      const fieldId = this.field.id;
      if (this.signatureData[fieldId] && this.signatureData[uploadId]) {
        this.signatureData = [];
      }
      this.setModel();
  
      this.fileData = null;
      this.fileResponse = null;
    }

  function view(upload) {
    uploadFileActions.viewFile(upload, undefined)
  }

  function signatureUrl(url) {
    return BASE_URL + url
  }
}
