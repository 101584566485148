export function Controller(TagsData, recordsData, gettextCatalog, context) {
  'ngInject'

  const s = {
    /// Checkbox label
    replaceExistingTags: gettextCatalog.getString('Replace existing tags?')
  }

  this.$onInit = () => {
    this.pickerOptions = {
      searchPlaceholder: gettextCatalog.getString('Find/Create Tags...'),
      contextLabel: gettextCatalog.getString('Tags')
    }

    this.saving = false
    this.records = this.resolve.records
    this.error = null
    this.tag = {
      tags: [],
      replace: false
    }
    this.popover = {
      add: { open: false },
      remove: { open: false }
    }
    this.tagFields = [
      {
        key: 'replace',
        type: 'checkbox',
        templateOptions: {
          label: s.replaceExistingTags
        }
      }
    ]
  }

  this.getAvailableItems = () => {
    return TagsData.findTags(context.get('account'))
  }

  this.save = (options) => {
    this.saving = true

    return recordsData.saveTags(this.records, this.tag.tags, this.tag.replace, options.remove).then(() => {
      this.dismiss({ $value: 'saved' })
    })
  }

  this.popoverClose = (name) => {
    this.popover[name] = {
      open: false
    }
  }

  this.popoverToggle = (name) => {
    let newValue = !this.popover[name].open

    this.popover[name] = {
      open: newValue
    }
  }

  this.updateTags = (tags) => {
    this.tag.tags = tags
  }
}
