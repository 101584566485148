export function Controller(
  $window,
  $state,
  Authenticate,
  AuthenticateRedirect,
  invitationsForUser,
  gettextCatalog,
  SSOProvidersData,
  session
) {
  'ngInject'

  const $ctrl = this

  $ctrl.$onInit = () => {
    $ctrl.email = $ctrl.$transition$.params().email
    $ctrl.first_name = $ctrl.$transition$.params().first_name
    $ctrl.last_name = $ctrl.$transition$.params().last_name

    $ctrl.loggedInUser = session.user

    $ctrl.unacceptedInvites = []
    $ctrl.self = gettextCatalog.getString('Invitations')

    // New method where invite email has the invitation_id
    if ($ctrl.$transition$.params().invitation_id) {
      setupInvite()
    } else {
      // Handle old invite emails without the invitations_id
      setupInvites()
    }

    if ($ctrl.loggedInUser) {
      $ctrl.loggedInAsSelf = $ctrl.loggedInUser.email === $ctrl.$transition$.params().email
    }

    $ctrl.showUnconfirmedMessage = checkUnconfirmed()

    // If we know the invite and the user is already logged in then continue to accepting
    if ($ctrl.invitation && $ctrl.loggedInAsSelf && !$ctrl.showUnconfirmedMessage) {
      $ctrl.accept()
    }
  }

  $ctrl.accept = () => {
    $ctrl.accepting = true

    invitationsForUser
      .accept($ctrl.invitation)
      .then(() => {
        $state.go('accounts')
      })
      .catch((response) => {
        if (response && response.data && response.data.error === 'user_not_confirmed') {
          $ctrl.showUnconfirmedMessage = true
        }

        $ctrl.accepting = false
      })
  }

  $ctrl.goToInvite = (invite) => {
    $state.go('invitations', {
      email: $ctrl.$transition$.params().email,
      code: $ctrl.$transition$.params().code,
      invitation_id: invite.id
    })
  }

  /**
   * @param {Object} user - object containing username, password and rememberme values
   */
  $ctrl.register = (user) => {
    Authenticate.attemptLogin(user)
      .then($ctrl.accept)
      .catch(() => {})
  }

  function checkUnconfirmed() {
    return $ctrl.invitation && $ctrl.invitation.has_account && $ctrl.loggedInAsSelf && !$ctrl.loggedInUser.confirmedAt
  }

  function setupInvite() {
    $ctrl.invitations.forEach((invite) => {
      if (!invite.accepted_at && invite.id === $ctrl.$transition$.params().invitation_id) {
        $ctrl.invitation = invite

        if ($ctrl.invitation.type === 'owner') {
          $ctrl.inviteLabel = $ctrl.invitation.subscription.name
        } else {
          $ctrl.inviteLabel = $ctrl.invitation.account.name
        }
      }
    })
  }

  function setupInvites() {
    $ctrl.unacceptedInvites = $ctrl.invitations.filter((invite) => {
      return !invite.accepted_at
    })
  }
}
