export function Controller(context) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    clearSelection,
    selectAll,
    updateFrequency
  }

  $ctrl.filters = {
    frequencies: {
      data: context.get('account').frequencies
    }
  }

  $ctrl.$onInit = function () {
    const frequencies = getSelection()

    if (!frequencies.length) {
      $ctrl.control.selectAll('frequencies')
    }
  }

  function getSelection() {
    const frequencies = []

    $ctrl.filters.frequencies.data.forEach((item) => {
      if (item.$selected) {
        frequencies.push(item.id)
      }
    })

    return frequencies
  }

  function selectAll(type) {
    toggleSelection(type, true)
  }

  function clearSelection(type) {
    toggleSelection(type, false)
  }

  function toggleSelection(type, value) {
    $ctrl.filters[type].data.forEach((item) => {
      item.$selected = value
    })
  }

  function updateFrequency() {
    const frequencies = []

    $ctrl.filters.frequencies.data.forEach((item) => {
      if (item.$selected) {
        frequencies.push(item.id)
      }
    })

    // If all are selected, remove param from filter as the api does this by default
    if (frequencies.length === $ctrl.filters.frequencies.data.length) {
      frequencies.length = 0
      $ctrl.onUpdateFrequency({
        frequencies: null
      })
    } else if (frequencies.length > 0) {
      //don't update graph if non selected as it doesn't work
      $ctrl.onUpdateFrequency({
        frequencies: frequencies.join(',')
      })
    }
  }
}
