export const ErrorsConstants = {
  default: {
    title: '<span translate>This is embarrassing...<span>',
    body: "<p translate>Something has gone wrong, and we're currently unable to display the page you requested. We have been informed and will fix this soon.</p>",
    nextSteps: ['back', 'accounts', 'support']
  },
  // API http 404 error
  account_not_found: {
    title: "<span translate>We've got a problem...</span>",
    body: '<p translate>The account you are trying to view does not exist!</p>',
    nextSteps: ['back', 'accounts', 'support']
  },
  // API http 403 error
  account_deactivated: {
    title: '<span translate>This account is deactivated</span>',
    body: '<p translate>This account has been locked either by an account owner, or by its Papertrail subscription being terminated.</p>',
    nextSteps: ['accounts', 'support']
  },
  // thrown in-app
  subscription_deactivated: {
    title: '<span translate>This subscription is deactivated</span>',
    body: '<p translate>This subscription has been deactivated. Please <a pt-contact>contact us</a> to reactivate it.</p>',
    nextSteps: ['accounts', 'support']
  },
  // thrown in-app
  trial_expired: {
    title: '<span translate>This trial has expired</span>',
    body: '<p translate>This trial subscription has expired.<br/>Please <a pt-contact>contact us</a> to extend your trial or subscribe.</p>',
    nextSteps: ['accounts', 'support']
  },
  // API http 403 error
  user_does_not_belong_to_account: {
    title: "<span translate>We've got a problem...</span>",
    body: "<p translate>You don't have access to this account. Either you have not been invited, or your access has been suspended.</p>",
    nextSteps: ['admin', 'accounts', 'support']
  },
  // API http 404 error
  folder_not_found: {
    title: '<span translate>Folder not found</span>',
    body: '<p translate>The folder may have been deleted or is not available for you to view.</p>',
    nextSteps: ['back', 'accounts', 'support']
  },
  // API http 400 error
  folder_exists: {
    title: '<span translate>Folder Already Exists</span>',
    body: '<p translate>The folder Name already exists.</p>'
  },
  // API http 400 error
  folder_does_not_belong_to_account: {
    title: "<span translate>We've got a problem</span>",
    body: '<p translate>The folder you are trying to view is not available in this account.</p>',
    nextSteps: ['back', 'accounts', 'support']
  },
  invalid_folder_move: {
    title: '<span translate>Invalid folder move</span>',
    body: '<p translate>You cannot move a folder into one of<br/> its own sub-folders.</p>'
  },
  // API http 403 error
  user_does_not_have_permission_for_folder: {
    title: "<span translate>We've got a problem</span>",
    body: '<p translate>You do not have access to view this folder.</p>',
    nextSteps: ['admin', 'back', 'accounts', 'support']
  },
  // thrown in-app
  user_is_not_an_owner: {
    title: "<span translate>We've got a problem</span>",
    body: '<p translate>You are not an owner of this subscription.</p>',
    nextSteps: ['admin', 'back', 'accounts', 'support']
  },
  // thrown in-app
  view_only_forbidden: {
    title: '<span translate>Access denied</span>',
    body: '<p translate>View only members cannot access this feature. Please ask the account owner if you require access.</p>'
  },
  // thrown in-app
  authorise_admin: {
    title: '<span translate>Admin permissions required</span>',
    body: '<p translate>You do not have admin permissions for this account.</p>',
    nextSteps: ['admin', 'accounts', 'support']
  },
  // API http 404 error
  product_not_found: {
    title: "<span translate>We've got a problem</span>",
    body: '<p translate>The product you are trying to import does not exist!</p>',
    nextSteps: ['back', 'support']
  },

  // thrown in-app
  modal_default: {
    title: '<span translate>Sorry, something went wrong</span>',
    body: '<p translate>Please try doing that again, or <a pt-contact>get in touch</a> with us.</p>',
    articleId: 'en/articles/356374-sorry-something-went-wrong'
  },
  // API http 403 error
  invalid_permission: {
    title: "<span translate>Sorry, you don't have permission to do this</span>",
    body: '<p translate>You have not been granted the required permissions to perform this action.</p>',
    articleId: 'en/articles/356368-sorry-you-don-t-have-permission-to-do-this'
  },
  // thrown in-app
  no_subscriptions_for_account: {
    title: '<span translate>Sorry, you need access to a subscription</span>',
    body: '<p>To create a new account, you must be an owner of a subscription. If you would like a new subscription, please <a pt-contact>get in touch.</a></p>',
    articleId: null
  },
  // API http 400 error
  folder_has_children: {
    title: "<span translate>This folder can't be deleted</span>",
    body: '<p translate>This folder has subfolders, so can not be deleted right now. You will first need to move or delete the subfolders.</p>',
    articleId: 'en/articles/356373-this-folder-can-t-be-deleted'
  },
  // API http 400 error
  folder_has_records: {
    title: "<span translate>This folder can't be deleted</span>",
    body: '<p translate>This folder contains active or archived records, so can not be deleted right now. You will first need to move the records to another folder.</p>',
    articleId: 'en/articles/356373-this-folder-can-t-be-deleted'
  },
  select_folder_for_record: {
    title: '<span translate>Select a folder first</span>',
    body: '<p translate>To add a new record, please select the folder you wish to add it to first.</p>',
    articleId: 'en/articles/356372-select-the-folder-first'
  },
  // thrown in-app
  select_records_for_log: {
    title: '<span translate>Select records first</span>',
    body: '<p translate>Please select the records you would like to add an inspection for first.</p><p translate>Note: You cannot add inspections to archived records.</p>',
    articleId: 'en/articles/356371-select-records-first'
  },
  // thrown in-app
  select_records_for_note: {
    title: '<span translate>Select records first</span>',
    body: '<p translate>Please select the records you would like to add a note to first.</p><p translate>Note: You cannot add inspections to archived records.</p>',
    articleId: 'en/articles/356320-select-record-first'
  },
  // thrown in-app
  select_records: {
    title: '<span translate>Select records first</span>',
    body: '<p translate>Please select the records you would like to perform this action on first.</p><p translate>Note: You cannot perform this action on archived records.</p>',
    articleId: null
  },
  // API http 404 error
  record_not_found: {
    title: '<span translate>Record not found</span>',
    body: '<p translate>The record may have been deleted, moved to another account, or is not available for you to view.</p>',
    articleId: null
  },
  // API http 404 error
  invitation_not_found: {
    title: '<span translate>Invitation not found</span>',
    body: '<p translate>The invitation may have been deleted, or is not available for you to view.</p>',
    articleId: null
  },
  // thrown in-app
  select_records_including_archived: {
    title: '<span translate>Select records first</span>',
    body: '<p translate>Please select the records you would like to perform this action on first.</p>',
    articleId: null
  },
  // API http 400 error
  record_has_logs: {
    title: "<span translate>Sorry, you can't delete this record</span>",
    body: '<p translate>As there are already inspections recorded, you will need to archive the record instead.</p>',
    articleId: 'en/articles/356319-sorry-you-can-t-delete-this-record'
  },
  // API http 400 error
  record_has_notes: {
    title: "<span translate>Sorry, you can't delete this record</span>",
    body: '<p translate>As there are already notes recorded, you will need to archive the record instead.</p>',
    articleId: 'en/articles/356319-sorry-you-can-t-delete-this-record'
  },
  // API http 400 error
  record_has_tasks: {
    title: "<span translate>Sorry, you can't delete this record</span>",
    body: '<p translate>As there are already tasks created, you will need to archive the record instead.</p>',
    articleId: null
  },
  // API http 400 error
  record_limit_exceeded: {
    title: "<span translate>Sorry, you can't add this record</span>",
    body: '<p translate>This Papertrail subscription has hit its record limit. If you are a subscription owner, you should upgrade now.</p>',
    articleId: 'en/articles/356317-sorry-you-can-t-add-this-record'
  },
  // API http 400 error
  member_limit_exceeded: {
    title: "<span translate>Sorry, you can't invite this member</span>",
    body: '<p translate>This Papertrail subscription has hit its member limit. If you are a subscription owner, you should upgrade now.</p>',
    articleId: 'en/articles/356315-sorry-you-can-t-add-this-user'
  },
  view_only_member_limit_exceeded: {
    title: "<span translate>Sorry, you can't invite this member</span>",
    body: '<p translate>This Papertrail subscription has hit its view only member limit. If you are a subscription owner, you should upgrade now.</p>',
    articleId: 'en/articles/356315-sorry-you-can-t-add-this-user'
  },
  // thrown in-app
  demo_expiry_info: {
    title: '<span translate>About demo expiry</span>',
    body: '<p translate>Demo accounts expire automatically after 30 days to prevent abuse. Please <a pt-contact>contact us</a> if you wish to extend or reactivate a demo account.</p>',
    articleId: null
  },
  // thrown in-app
  account_deactivated_demo: {
    title: '<span translate>Expired demo account</span>',
    body: '<p translate>This demo account has automatically expired. Please <a pt-contact>get in touch</a> to request reactivation of the demo.</p>',
    articleId: null
  },
  popup_blocked: {
    title: '<span translate>Blocked by popup blocker</span>',
    body: '<p translate>Please allow popups from papertrail.io then try again.</p>',
    articleId: null
  },
  // API http 400 error
  record_has_not_been_deleted: {
    title: '<span translate>Record not deleted</span>',
    body: '<p translate>You can not revert this action, as the record is not currently deleted.</p>',
    articleId: null
  },
  // thrown in-app
  payment_update_failed: {
    title: '<span translate>Payment updating failed</span>',
    body: `
      <p translate>
        Sorry, there was an error retrieving the update payment page.
      </p>
      <p translate>
        Our team have been made aware.
      </p>
      <p translate>
        Please try again later, or <a pt-contact>get in touch</a> if this persists.
      </p>
    `,
    articleId: null
  },
  // thrown in-app
  activities_pdf_count: {
    title: '<span translate>Too many activities</span>',
    body: '<p translate>Please amend the filter to return less than 1000 activities before exporting</p>'
  },

  invitation_resend_throttle: {
    title: '<span translate>Too many requests</span>',
    body: '<p translate>Please wait a minute before trying to resend the invitation.</p>'
  }
}
