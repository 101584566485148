export function ptSubscriptionsNewRoute($stateProvider) {
  'ngInject'

  let newSubscriptionModal

  $stateProvider.state('new-subscription', {
    parent: 'accounts',
    url: '/new-subscription?affiliate_token',
    views: {
      'utility@layoutBase': {
        template: ' '
      }
    },
    resolve: {
      // accountResolves: resolveAccounts,
      resellerResolves: resolveResellers,
      industryResolves: resolveIndustries,
      countryResolves: resolveCountries,
      templateResolves: resolveTemplates
    },
    onEnter: onNewSubscriptionEnter,
    onExit: onNewSubscriptionExit
  })

  function resolveResellers(resellersData) {
    'ngInject'

    return resellersData.getAll()
  }

  function resolveCountries(CountriesData) {
    'ngInject'

    return CountriesData.get()
  }

  function resolveIndustries(industriesData) {
    'ngInject'

    return industriesData.get()
  }

  function resolveTemplates(TemplatesData) {
    'ngInject'

    return TemplatesData.get()
  }

  function onNewSubscriptionEnter(
    $uibModal,
    $timeout,
    $state,
    resellerResolves,
    industryResolves,
    countryResolves,
    templateResolves,
    page
  ) {
    'ngInject'

    page.setTitle('New Subscription')

    newSubscriptionModal = $uibModal.open({
      component: 'ptSubscriptionsNew',
      resolve: {
        resellers: function () {
          return resellerResolves
        },
        industries: function () {
          return industryResolves
        },
        countries: function () {
          return countryResolves
        },
        templates: function () {
          return templateResolves
        }
      }
    })

    newSubscriptionModal.result.catch(function () {
      //timeout is required otherwise MFE is unmounted before modal elements removed from dom
      //which causes issues with multiple modals if you click the button again
      $timeout(() => {
        newSubscriptionModal.close()
        $timeout(() => {
          $state.go('^')
        }, 500)
      }, 50)
    })
  }

  function onNewSubscriptionExit(page) {
    'ngInject'
    page.resetTitle()
    newSubscriptionModal.close()
  }
}
