import { Controller } from './controller'
import view from './view.html'

export const ptTutorialsNewInspection = {
  bindings: {
    showExtended: '<'
  },
  controller: Controller,
  template: view
}
