import view from './view.html'

export function label(formlyConfigProvider) {
  'ngInject'

  formlyConfigProvider.setWrapper([
    {
      name: 'bootstrapLabel',
      template: view,
      overwriteOk: true
    }
  ])
}
