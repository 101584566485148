export function Controller($state, permissionsCheck, context) {
  'ngInject'

  let vm = this

  vm.control = {
    newFolder: newFolder
  }

  vm.account = context.get('account')

  vm.permissionsCheck = permissionsCheck

  vm.$onInit = function () {}

  function newFolder() {
    if ($state.get('.new-folder')) {
      $state.go('.new-folder')
    } else {
      $state.go('account.new-folder')
    }
  }
}
