export function Controller($state, flagsCheck, context, tracking) {
  'ngInject'

  let vm = this

  vm.control = {
    openModal: openModal
  }

  vm.showScan = null

  //////////

  vm.$onInit = function () {
    vm.showScan = true
  }

  function openModal() {
    tracking.trackEvent({ action: 'openScanModal', category: 'scan' })

    if ($state.get('.scan')) {
      $state.go('.scan')
    }
  }
}
