import { Controller } from './controller'
import view from './view.html'

export const ptBillingDetails = {
  bindings: {
    subscription: '<',
    billing: '<',
    billingDetailsAvailable: '<'
  },
  template: view,
  controller: Controller
}
