import { headers } from './headers'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../keys'
import { getAxiosInstance, getConfig } from './index'

export const requestToken = async (credentials?: { username: string; password: string; remember: boolean }) => {
  const axios = getAxiosInstance()
  const { storage, auth } = getConfig()

  const { data: token } = await axios({
    url: `auth/access_token`,
    method: 'POST',
    data: {
      grant_type: (credentials as any).username ? 'password' : 'refresh_token',
      client_id: !!auth ? auth.clientId : undefined,
      client_secret: !!auth ? auth.clientSecret : undefined,
      ...credentials
    },
    headers: headers
  })

  const { refresh_token: refreshToken, access_token: accessToken } = token

  return accessToken as string
}

export const ssoProviders = async () => {
  const axios = getAxiosInstance()

  const { data: providers } = await axios({
    url: `oauth/url`,
    method: 'GET',
    headers: headers
  })

  return providers as string[]
}

export const login = async (username: string, password: string, remember: boolean) =>
  requestToken({ username, password, remember })

export const logout = async () => {
  const axios = getAxiosInstance()
  const { storage } = getConfig()
  const accessToken = await storage.getItem(ACCESS_TOKEN_KEY)
  await storage.removeItem(ACCESS_TOKEN_KEY)
  await storage.removeItem(REFRESH_TOKEN_KEY)

  if (accessToken) {
    try {
      await axios({
        url: `auth/access_token`,
        method: 'DELETE',
        headers: headers
      })
    } catch (error) {
      // ignore 401, token is already revoked
      if ((error as any).response.status !== 401) {
        throw error
      }
    }
  }
}
