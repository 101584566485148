import _find from 'lodash/find'
import _forEach from 'lodash/forEach'

export function Controller($scope, session, invitationsTypes, invitationsForUser, gettextCatalog) {
  'ngInject'

  let vm = this

  vm.control = {
    accept: accept,
    acceptAll: acceptAll
  }
  vm.pendingInvitations = []
  vm.acceptedInvitations = []
  vm.buttonContent = {
    html: '<i class="icon-ok"></i>&nbsp;<span translate>ACCEPT</span>'
  }
  vm.currentUser = session.user
  vm.validated = null
  vm.crumbs = [
    {
      /// Bread crumb
      name: gettextCatalog.getString('Accounts'),
      link: 'accounts'
    },
    {
      /// Bread crumb
      name: gettextCatalog.getString('Profile'),
      link: 'profile.basic'
    }
  ]
  vm.self = gettextCatalog.getString('Invitations')

  ////////////////

  vm.$onInit = function () {
    checkValidationStatus()

    splitInvitationTypes()

    listen()
  }

  function checkValidationStatus() {
    if (vm.currentUser) {
      vm.validated = !!vm.currentUser.confirmedAt
    } else {
      vm.validated = true
    }
  }

  function listen() {
    $scope.$on('invitation_was_updated', updateInvitationEvent)
    $scope.$on('invitation_was_deleted', deleteInvitationEvent)
  }

  function splitInvitationTypes() {
    vm.pendingInvitations = invitationsTypes.pending(vm.invitations)
    vm.acceptedInvitations = invitationsTypes.accepted(vm.invitations)
  }

  function updateInvitationEvent(event, invitationDto) {
    let invitation = _find(vm.invitations, { id: invitationDto.invitationId })

    if (invitation) {
      invitationsForUser.getUpdated(invitation).then(function () {
        splitInvitationTypes()
      })
    }
  }

  function deleteInvitationEvent(event, invitationDto) {
    let invitation = _find(vm.invitations, { id: invitationDto.invitationId })

    if (invitation) {
      invitationsForUser.remove(invitation)
      splitInvitationTypes()
    }
  }

  function accept(invitationId) {
    let invitation = _find(vm.invitations, { id: invitationId })

    invitationsForUser.accept(invitation)
  }

  function acceptAll() {
    _forEach(vm.pendingInvitations, function (invitation) {
      accept(invitation.id)
    })
  }
}
