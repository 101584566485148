import { Controller } from './controller'
import view from './view.html'

export const ptActivityTaskUploadWasDeleted = {
  bindings: {
    data: '<',
    options: '<ptActivityOptions'
  },
  controller: Controller,
  template: view
}
