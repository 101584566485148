export function ptImportsAccountsRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('imports.import.accounts', {
    url: `/accounts`,
    views: {
      'crumbs@imports': {
        component: 'ptImportsCrumbs'
      },
      'main@imports': {
        component: 'ptImportsAccounts'
      }
    },
    resolve: {
      crumbs: resolveCrumbs,
      self: resolveSelf
    }
  })

  function resolveCrumbs(gettextCatalog) {
    'ngInject'

    return [
      {
        name: gettextCatalog.getString('Imports'),
        link: 'imports'
      },
      {
        name: gettextCatalog.getString('Import Details'),
        link: '^'
      }
    ]
  }

  function resolveSelf(gettextCatalog) {
    'ngInject'

    return gettextCatalog.getString('Accounts')
  }
}
