export class recordViewOptions {
  // In the provider function, you cannot inject any
  // service or factory. This can only be done at the
  // "$get" method.
  $get = function () {
    return {
      params: this.params
    }
  }

  params = function () {
    return ['view']
  }
}
