export function SpinnerConfig(usSpinnerConfigProvider) {
  'ngInject'

  usSpinnerConfigProvider.setDefaults({
    // The number of lines to draw
    lines: 9,
    // The length of each line
    length: 8,
    // The line thickness
    width: 5,
    // The radius of the inner circle
    radius: 5,
    // Corner roundness (0..1)
    corners: 0.8,
    // The rotation offset
    rotate: 0,
    // 1: clockwise, -1: counterclockwise
    direction: 1,
    // #rgb or #rrggbb or array of colors
    color: '#000',
    // Rounds per second
    speed: 1,
    // Afterglow percentage
    trail: 54,
    zIndex: 'initial'
  })
}
