import { Controller } from './controller'
import view from './view.html'

export function selectSuggest(formlyConfig) {
  'ngInject'

  formlyConfig.setType({
    name: 'selectSuggest',
    extends: 'select',
    template: view,
    controller: Controller
  })
}
