import _xor from 'lodash/xor'
import _cloneDeep from 'lodash/cloneDeep'

export function Controller($timeout, $element, recordsData, ChecklistsData, filteringContext, gettextCatalog, context) {
  'ngInject'

  const mapText = (checklists) => {
    return checklists.map((checklist) => checklist.name)
  }

  this.$onInit = () => {
    this.pickerOptions = {
      searchPlaceholder: gettextCatalog.getString('Find Checklists...'),
      contextLabel: gettextCatalog.getString('Checklists'),
      canCreate: false,
      labelProperty: 'name'
    }

    this.field = {
      required: false,
      label: gettextCatalog.getString('Checklists')
    }

    this.focusInput = false
    this.popover = {
      open: false
    }
  }

  this.$onChanges = (changes) => {
    if (changes.record) {
      // Set the initial checklist values to compare against later on when saving.
      this.initialChecklists = _cloneDeep(this.record.checklists)
    }
  }

  this.getAvailableChecklists = () => {
    return ChecklistsData.getAccountChecklists(context.get('account'))
  }

  this.saveChecklists = (checklists) => {
    // If there has been a change in the checklists data
    if (_xor(mapText(checklists), mapText(this.initialChecklists)).length) {
      this.initialChecklists = _cloneDeep(checklists)

      recordsData.saveChecklists(context.get('account'), [this.record], checklists, { replace: true })
    }
  }

  this.popoverClose = () => {
    this.popover = {
      open: false
    }
  }

  this.popoverToggle = () => {
    let newValue = !this.popover.open

    this.popover = {
      open: newValue
    }
  }

  this.checklistClicked = (value) => {
    filteringContext.applyFilter({ checklist: value }, 'folders')
  }

  this.toggleFocus = ($event) => {
    if ($event.originalEvent && $event.originalEvent.type === 'click') {
      this.focusInput = !this.focusInput

      if (this.focusInput) {
        $timeout(() => {
          $element.find('input').trigger('click')
        })
      }
    }
  }
}
