import angular from 'angular'
import { ptProductPreviewModule } from './components/pt-product-preview'
import { ptProductsSearchModule } from './components/pt-products-search'
import { ProductsData } from './services/data'
import { ProductsList } from './services/list'

export const ProductsModule = angular
  .module('products', [ptProductPreviewModule, ptProductsSearchModule])
  .factory('ProductsData', ProductsData)
  .factory('ProductsList', ProductsList).name
