import { Controller } from './controller'
import view from './view.html'

export const ptRecordsTaskItem = {
  bindings: {
    task: '<'
  },
  controller: Controller,
  template: view
}
