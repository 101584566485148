export function Controller(foldersNavigation) {
  'ngInject'

  let vm = this

  vm.control = {
    go: go
  }

  /////////

  vm.$onInit = function () {}

  function go(crumb) {
    foldersNavigation.go(crumb.id)
  }
}
