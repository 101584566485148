export function Controller(page, context, filteringContext) {
  'ngInject'

  let $ctrl = this

  $ctrl.account = context.get('account').me

  $ctrl.$onInit = function () {
    page.setTitle('Account Dashboard')

    filteringContext.setDefaultFilter()
  }
}
