import angular from 'angular'
import { EventsCache } from './services/cache'
import { EventsData } from './services/data'
import { EventsPusher } from './services/pusher'
import { EventsTranslator } from './services/translator'

export const EventsModule = angular
  .module('events', [])
  .factory('EventsCache', EventsCache)
  .factory('EventsData', EventsData)
  .factory('EventsPusher', EventsPusher)
  .factory('EventsTranslator', EventsTranslator).name
