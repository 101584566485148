import _forEach from 'lodash/forEach'

export function timezonesData(TimezoneModel) {
  'ngInject'

  let timezonesCache = null

  let service = {
    get: get
  }
  return service

  ////////////////

  function get() {
    if (timezonesCache) {
      return timezonesCache
    }

    return TimezoneModel.$collection()
      .$fetch()
      .$asPromise()
      .then(function (timezones) {
        timezonesCache = formatTimezones(timezones)
        return timezonesCache
      })
  }

  function formatTimezones(timezones) {
    return _forEach(timezones, function (timezone) {
      timezone.formattedName = '(' + timezone.offset_formatted + ') ' + timezone.city
    })
  }
}
