import _sortBy from 'lodash/sortBy'
import { PapertrailSession } from '@papertrail/web3-session'

export function AccountRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('account', {
    url: '/accounts/{accountId:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
    parent: 'layoutFull',
    views: {
      'content@layoutFull': 'ptAccount'
    },
    resolve: {
      account: resolveAccount,
      member: resolveMember,
      filters: resolveFilters,
      // Resolve checklists which is used as a pre-check on whether to show checklist fields
      checklists: resolveChecklists
    },
    onEnter: onAccountsViewEnter,
    onExit: onAccountsViewExit
  })

  function onAccountsViewEnter(EventsPusher, page, account, member, wootricInit) {
    'ngInject'
    if (account && member.role === 'member') {
      PapertrailSession.intercom.setAccountId(account.id)
    }
    wootricInit.boot()
  }

  function onAccountsViewExit(page, EventsPusher, foldersTree, context, DefinitionsData) {
    'ngInject'

    DefinitionsData.clearAllColumns()
    foldersTree.clear()
    page.leftMenuOpen(false)
    page.removeAccountName()
    page.removeAccountLogo()
    PapertrailSession.intercom.setAccountId(undefined)
  }

  function resolveAccount(
    context,
    accountsData,
    ErrorsDisplay,
    $stateParams,
    DefinitionsData,
    BlueprintsData,
    localStorageService,
    session
  ) {
    'ngInject'

    return accountsData
      .fetchAccount($stateParams.accountId)
      .then(function (account) {
        context.set('account', account)
        const storedDefinitions = localStorageService.get(`definitions-${account.id}-${session.user.id}`)

        let fields = DefinitionsData.getCoreProperties().concat(BlueprintsData.getFieldsAsArray())

        let fieldsSorted = _sortBy(fields, 'label')

        DefinitionsData.clearAllColumns()
        DefinitionsData.setAllColumns(fieldsSorted)

        if (storedDefinitions) {
          DefinitionsData.setVisibleColumns({ definitions: storedDefinitions.columns })
        } else {
          DefinitionsData.setVisibleColumns({ definitions: account.folder.definitions })
        }

        return account
      })
      .catch(function (rejection) {
        return ErrorsDisplay.catch(rejection.$response.data.error)
      })
  }

  function resolveChecklists(ChecklistsData, account) {
    'ngInject'

    return ChecklistsData.getAccountChecklists(account, true).then((response) => response)
  }

  function resolveFilters(account, filtersData) {
    'ngInject'

    return filtersData.fetch(account)
  }

  function resolveMember(context, account, membersData) {
    'ngInject'

    return membersData.fetch(account).then(function (member) {
      context.set('member', member)
      return member
    })
  }
}
