import _keys from 'lodash/keys'

export function Controller(ActivitiesRecord, richText, dateformatFilter, dateTimeFormat) {
  'ngInject'

  let vm = this

  vm.control = {
    openRecord: openRecord
  }

  vm.richText = richText
  vm.dateformatFilter = dateformatFilter
  vm.dateTimeFormat = dateTimeFormat

  vm.changes = {
    sentance: '',
    data: []
  }
  vm.singleChangeFrom = null
  vm.singleChangeTo = null
  vm.tagChanges = {}

  vm.buildChecklistNamesString = (checklists) => checklists.map((item) => item.name).join(', ')

  vm.$onInit = function () {
    let data = ActivitiesRecord.changes(vm.data)
    let change

    for (change in data) {
      if (data.hasOwnProperty(change)) {
        vm.changes.data.push(data[change])
      }
    }

    vm.tagChanges = ActivitiesRecord.tagChanges(vm.changes.data)
    vm.changes.sentance = ActivitiesRecord.propsToSentance(_keys(data))

    if (vm.changes.data.length === 1) {
      vm.singleChangeFrom = vm.changes.data[0].from
      vm.singleChangeTo = vm.changes.data[0].to
    }
  }

  function openRecord() {
    vm.openRecord()
  }
}
