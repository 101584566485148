import _uniqBy from 'lodash/uniqBy'

export function lookupsData(context, collections) {
  'ngInject'

  let service = {
    fetch: fetch
  }
  return service

  ////////////////

  function fetch(source, value, options) {
    let params = {
      type: source,
      q: value,
      limit: (options && options.limit) || 10
    }

    let collection = context.get('account').lookup.$collection(params)

    let resultsCollection

    if (options && options.all) {
      resultsCollection = collections.getAll(collection)
    } else {
      resultsCollection = collection.$fetch().$asPromise()
    }

    return resultsCollection.then(function (results) {
      return _uniqBy(results, 'value')
    })
  }
}
