export function Controller($scope, recordsData, gettextCatalog) {
  'ngInject'

  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const vm = this

  vm.control = {
    saveState: saveState,
  }

  vm.field = {
    required: true,
    label: gettextCatalog.getString('Record state')
  }
  /////////////

  vm.$onInit = function () {
    // no op
  }

  function saveState(value) {
    return recordsData.saveProperty(vm.record, 'state', value).catch(() => {
      // no op
    })
  }
}
