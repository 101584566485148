import view from './view.html'

export const textareaExpanding = (formlyConfig) => {
  'ngInject'

  formlyConfig.setType({
    name: 'textareaExpanding',
    extends: 'textarea',
    defaultOptions: {
      templateOptions: {
        rows: 2
      }
    },
    template: view,
    wrapper: ['copyDown', 'bootstrapLabel', 'bootstrapHasError']
  })
}
