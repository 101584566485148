import angular from 'angular'

import _forEach from 'lodash/forEach'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _map from 'lodash/map'

export function Controller($state, $stateParams) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    toggleFilterPanel,
    setAll,
    toggleSection,
    applyFilters
  }

  $ctrl.filters = {
    account: {
      options: [],
      $open: false
    },
    read: $stateParams.read === 'true' || false
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.accounts) {
      $ctrl.accounts = angular.copy($ctrl.accounts)
    }
  }

  $ctrl.$onInit = function () {
    setupAccountFilter()
  }

  function setupAccountFilter() {
    let accountIds

    $ctrl.filters.account.options = $ctrl.accounts

    // If accounts param is set, get account ids and mark them as selected in the filter panel.
    // Else, show all accounts as being included in filter.
    if ($stateParams.accounts) {
      accountIds = $stateParams.accounts.split(',')

      _forEach(accountIds, (accountId) => {
        let account: any = _find($ctrl.filters.account.options, { id: accountId })

        if (account) {
          account.$selected = true
        }
      })
    } else {
      _forEach($ctrl.filters.account.options, (account) => {
        account.$selected = true
      })
    }
  }

  function toggleSection(section) {
    $ctrl.filters[section].$open = !$ctrl.filters[section].$open
  }

  function setAll(section, bool) {
    _forEach($ctrl[section], (option) => {
      option.$selected = bool
    })
  }

  function toggleFilterPanel() {
    $ctrl.onToggleFilterPanel()
  }

  function applyFilters() {
    const params = {
      accounts: accountsFilterCheck(),
      read: readFilterCheck()
    }

    $state.go($state.current.name, params)
  }

  function accountsFilterCheck() {
    let accountsSelected = _filter($ctrl.filters.account.options, '$selected')
    let accountIds

    if (accountsSelected.length && accountsSelected.length !== $ctrl.filters.account.options.length) {
      // clear the url param by returning undefined, as the default is to show all accounts.
      accountIds = _map(accountsSelected, 'id').join(',')
    }

    return accountIds
  }

  function readFilterCheck() {
    let includeRead

    if ($ctrl.filters.read) {
      includeRead = true
    }

    return includeRead
  }
}
