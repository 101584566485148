import _kebabCase from 'lodash/kebabCase'

export function Controller() {
  'ngInject'

  this.$onInit = () => {}

  this.$onChanges = (changes) => {
    if (changes.recordOrigin) {
      this.manufacturer = _kebabCase(this.recordOrigin.value.manufacturer)
    }
  }
}
