import { Controller } from './controller'
import view from './view.html'

export const ptNotificationsList = {
  bindings: {
    notification: '<',
    onRead: '&',
    onOpenRecord: '&'
  },
  controller: Controller,
  template: view
}
