export function userSwitchModal($uibModal) {
  'ngInject'

  let modal

  let service = {
    openModal: openModal
  }

  return service

  function openModal() {
    modal = $uibModal.open({
      component: 'ptUsersSwitch',
      keyboard: false,
      backdrop: 'static'
    })

    modal.result.catch(function () {})
  }
}
