import * as React from 'react'
import { View } from 'reactxp'
import { Button } from '../../components'
import { createStyledView } from '../../components/styled'
import { spacing } from '../../styles'
import { DiscardCriteria } from '../index'
import { ChecklistField } from '../../checklist/index'
import { DiscardCriteriaGroup } from './discard-criteria-group'

const ButtonContainer = createStyledView({
  flexDirection: 'row',
  marginTop: spacing.base
})

interface Props {
  criteria: DiscardCriteria
  onChange?: (criteria: DiscardCriteria, field: ChecklistField) => void
  onSubmit?: (criteria: DiscardCriteria) => void
  open?: boolean
}

export const DiscardCriteriaComponent = (props: Props) => {
  const { criteria, onChange = () => undefined, onSubmit = () => undefined, open = true } = props

  const showButton =
    criteria.questions.map((item) => ((item as any).value || false) as boolean).filter((val) => !!val).length > 0

  return (
    <View>
      <DiscardCriteriaGroup
        criteria={criteria}
        open={open}
        onChange={(newCriteria, newField) => {
          onChange(newCriteria, newField)
        }}
      />

      {showButton && (
        <ButtonContainer>
          <Button label="View Results" onPress={() => onSubmit(criteria)} />
        </ButtonContainer>
      )}
    </View>
  )
}
