import * as Sentry from '@sentry/browser'
import _assign from 'lodash/assign'

export function ReportsData(context, collections, $http, BASE_URL, API_VERSION, session, $q) {
  'ngInject'

  const service = {
    generateReportExport,
    getReportData,
    getProjectedChecks,
    getReports
  }

  return service

  /////////////////////////////////

  function generateReportExport(accountId, report, params) {
    const defer = $q.defer()

    $http({
      method: 'POST',
      url: `${BASE_URL}/accounts/${accountId}/reports/${report}/actions/generate`,
      headers: {
        Accept: API_VERSION
      },
      data: params
    })
      .then((response) => {
        defer.resolve(response.data.data)
      })
      .catch((response) => {
        defer.reject(response)
      })

    return defer.promise
  }

  function getReports(additionalParams) {
    const params = {}

    if (typeof additionalParams === 'object') {
      _assign(params, additionalParams)
    }

    const collection = context.get('account').reports.$collection(params)

    return collections.getAll(collection)
  }

  function getProjectedChecks(additionalParams) {
    const params = {}

    if (typeof additionalParams === 'object') {
      _assign(params, additionalParams)
    }

    const collection = context.get('account').reportsProjectedChecks.$collection(params)

    return collections.getAll(collection)
  }

  function getReportData(accountId, report, params = {}) {
    const defer = $q.defer()

    $http({
      method: 'GET',
      url: `${BASE_URL}/accounts/${accountId}/reports/${report}`,
      headers: {
        Accept: API_VERSION
      },
      params: params
    })
      .then((response) => {
        defer.resolve(response.data.data)
      })
      .catch((response) => {
        defer.reject(response)
      })

    return defer.promise
  }
}
