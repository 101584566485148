import _cloneDeep from 'lodash/cloneDeep'
import { fetchRecord } from 'AppCore/record/api'
import { Member } from 'AppCore/member/models'
import { Folder } from 'AppCore/folder/models'
import { Record } from 'AppCore/record/models'

export function Controller(foldersData, context, $q, ErrorsModal, $scope, permissionsCheck) {
  'ngInject'

  const setupMembers = () => {
    this.usersLoading = true

    const getRecordParams = {
      accountId: context.get('account').id,
      recordId: this.recordId,
      query: { include: 'folder' }
    }

    $q.resolve(fetchRecord(getRecordParams))
      .then((record: Record) => {
        return foldersData.getFolder(context.get('account'), record.folder.id).catch((response) => {
          ErrorsModal.openModal(response.error)
        })
      })
      .then((folder: Folder) => {
        return foldersData.getFolderMembers(context.get('account'), folder.id, 'user').catch((response) => {
          ErrorsModal.openModal(response.error)
        })
      })
      .then((folderMembers: Member[]) => {
        const selectedUserIds: string[] = []

        // Get the user data from the member
        this.users = folderMembers.reduce((users, member) => {
          users.push(member.user)

          return users
        }, [])
      })
      .catch(() => {})
      .finally(() => {
        this.usersLoading = false
      })
  }

  this.$onInit = () => {
    const account = context.get('account')
    const isViewOnly = permissionsCheck.is('viewOnly', account)
    const canEditTasks = permissionsCheck.can('edit', 'tasks', account)
    this.editable = !isViewOnly && canEditTasks
    setupMembers()
  }

  this.$onChanges = (changes) => {
    if (changes.resolve) {
      this.selectedUsers = _cloneDeep(this.resolve.selectedUsers)
      this.recordId = _cloneDeep(this.resolve.recordId)
    }
  }

  this.confirmAssignees = () => {
    this.dismiss({ $value: { assignees: this.selectedUsers } })
  }

  this.closeModal = (reason) => {
    this.dismiss({ $value: reason || 'cancel' })
  }

  this.usersUpdated = (selectedUsers) => {
    this.selectedUsers = selectedUsers
    // Because this is called from React
    $scope.$apply()
  }
}
