export function Controller(membersData) {
  'ngInject'

  let vm = this

  vm.member = vm.resolve.member

  vm.control = {
    suspend: suspend
  }

  vm.$onInit = function () {}

  function suspend() {
    membersData.suspend(vm.member)
    vm.dismiss()
  }
}
