export function recordsMove($q, $http, context, EventsData, session, validator, BASE_URL, API_VERSION) {
  'ngInject'

  let service = {
    move: move,
    batchMove: batchMove
  }

  return service

  function move(record, folderId) {
    let accountId = context.get('account').id
    let req = {
      method: 'POST',
      url: BASE_URL + '/accounts/' + accountId + '/records/' + record.id + '/actions/move',
      headers: {
        Accept: API_VERSION
      },
      data: {
        folder_id: folderId
      }
    }

    return $http(req).then(
      function (updatedRecord) {
        EventsData.pushEvent('record_was_moved', {
          recordId: updatedRecord.data.data.id,
          folderId: folderId
        })

        return updatedRecord
      },
      function (rejection) {
        return validator.rejection(rejection.$response)
      }
    )
  }

  function batchMove(records, folderId) {
    let promises = []

    records.forEach(function (record) {
      promises.push(move(record, folderId))
    })

    return $q.all(promises)
  }
}
