import { createResource } from '../../api/resource'
import { createRequestTransformer, createResponseArrayTransformer } from '../../api/transformer'
import { CreateTaskUploadPayload } from '..'
import { AccountUpload } from '../../upload'

const transformRequest = createRequestTransformer<CreateTaskUploadPayload>()

interface Params {
  taskId: UUID
  payload: CreateTaskUploadPayload
}

export const createTaskUpload = createResource({
  request: ({ taskId, payload }: Params) => ({
    url: `tasks/${taskId}/uploads`,
    method: 'POST',
    data: transformRequest(payload)
  }),
  transform: createResponseArrayTransformer<AccountUpload>('account')
})
