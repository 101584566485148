export function recordsPagination() {
  'ngInject'

  let currentPerPage = 50
  let currentPage = 1

  let service = {
    setInitialParams: setInitialParams,
    resetPage: resetPage,
    setPerPage: setPerPage,
    setPage: setPage,
    getPerPage: getPerPage,
    getPage: getPage,
    filters: filters
  }

  return service

  function setInitialParams($stateParams) {
    setPerPage($stateParams.recordsPerPage)
    setPage($stateParams.recordsPage)
  }

  function resetPage() {
    currentPage = 1
  }

  function setPerPage(perPage) {
    if (perPage !== undefined && perPage !== null) {
      currentPerPage = perPage
    }
  }

  function setPage(page) {
    page = Math.max(1, page)

    if (page) {
      currentPage = page
    }
  }

  function getPerPage() {
    return currentPerPage
  }

  function getPage() {
    return currentPage
  }

  function filters() {
    return {
      recordsPerPage: getPerPage(),
      recordsPage: getPage()
    }
  }
}
