export function Controller(richText) {
  'ngInject'

  let vm = this
  let memorizeCache = {
    richText: {
      raw: null,
      parsed: null
    }
  }

  vm.richText = memorizeRichText

  vm.$onInit = function () {}

  function memorizeRichText(text) {
    if (memorizeCache.richText.raw !== text) {
      memorizeCache.richText.raw = text
      memorizeCache.richText.parsed = richText(text)
    }

    return memorizeCache.richText.parsed
  }
}
