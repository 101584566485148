export function Link(scope, element, attrs) {
  let override = false

  init()

  function init() {
    if (typeof attrs.override !== 'undefined') {
      attrs.$observe('override', function () {
        if (attrs.override) {
          override = attrs.override
        } else {
          override = false
        }
        setupLogo()
      })
    }

    scope.$watch('vm.logo', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        setupLogo()
      }
    })

    setupLogo()
  }

  function setupLogo() {
    let logo = override || scope.vm.logo || (scope.vm.account && scope.vm.account.logo)

    if (logo) {
      // css
      element.css('background-image', 'url(' + logo + ')')

      // class
      element.removeClass('default-logo')
    } else {
      // class
      element.addClass('default-logo')
    }
  }
}
