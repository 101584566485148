import _find from 'lodash/find'
import _omit from 'lodash/omit'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import _difference from 'lodash/difference'

export function BlueprintsData(foldersData, context, gettextCatalog) {
  'ngInject'

  const service = {
    getByKey,
    getIdentifiers,
    copyFieldsToRecord,
    getSections,
    getFieldsAsArray
  }

  return service

  ////////////////

  function getBlueprint() {
    return context.get('account').blueprint
  }

  function getByKey(key) {
    const blueprint = getBlueprint()
    let found = null

    blueprint.sections.data.forEach((section) => {
      section.fields.data.forEach((field) => {
        if (field.key === key) {
          found = field
        }
      })
    })

    return found
  }

  function getIdentifiers() {
    const blueprint = getBlueprint()
    let found = []

    blueprint.sections.data.forEach((section) => {
      section.fields.data.forEach((field) => {
        if (field.type === 'identifier' || field.type === 'rfid' || field.type === 'barcode') {
          found.push(field)
        }
      })
    })

    return found
  }

  function copyFieldsToRecord(record) {
    const blueprint = getBlueprint()

    blueprint.sections.data.forEach((section) => {
      section.fields.data.forEach((field) => {
        const existingField = _find(record.fields, { key: field.key })
        const copyField = _omit(field, ['id'])

        if (!existingField) {
          // Copy field from blueprint to record
          record.fields.$build(copyField).$reveal()
        }
      })
    })
  }

  function getSections(record) {
    const blueprint = getBlueprint()
    const matchedFieldKeys = []
    const recordFieldKeys = _map(record.fields, 'key')
    const extraSection = {
      title: gettextCatalog.getString('Other Fields'),
      fields: []
    }
    const sections = []
    let unmatchedFieldKeys = []

    copyFieldsToRecord(record)

    blueprint.sections.data.forEach((section) => {
      const copySection = _omit(section, ['fields'])

      copySection.fields = []

      section.fields.data = _sortBy(section.fields.data, 'position')

      section.fields.data.forEach((field) => {
        copySection.fields.push(field.key)
        matchedFieldKeys.push(field.key)
      })

      sections.push(copySection)
    })

    unmatchedFieldKeys = _difference(recordFieldKeys, matchedFieldKeys)

    if (unmatchedFieldKeys.length) {
      unmatchedFieldKeys.forEach((fieldKey) => {
        extraSection.fields.push(fieldKey)
      })

      sections.push(extraSection)
    }

    return sections
  }

  function getFieldsAsArray() {
    const blueprint = getBlueprint()
    const array = []

    blueprint.sections.data.forEach((section) => {
      section.fields.data.forEach((field) => {
        array.push(field)
      })
    })

    return array
  }
}
