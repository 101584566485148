export function SSOProvidersData(SSOModel, SSOModelAuthenticate, collections) {
  'ngInject'

  return {
    getAll: getAll,
    getAllForAuthenticate: getAllForAuthenticate,
  }

  function getAll() {
    const collection = SSOModel.$collection()
    return collections.getAll(collection)
  }

  function getAllForAuthenticate() {
    const collection = SSOModelAuthenticate.$collection()
    return collections.getAll(collection)
  }
}
