import _assign from 'lodash/assign'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _pickBy from 'lodash/pickBy'
import _capitalize from 'lodash/capitalize'
import * as tasks from 'AppCore/task/api'
import { TaskAction } from 'AppCore/task/models'

export function TasksData($q, $http, BASE_URL, API_VERSION, moment) {
  'ngInject'

  const service = {
    action,
    addComment,
    amendAssignees,
    amendTask,
    createTask,
    getSortArray,
    getSortColumns,
    getSortString,
    getTaskById,
    getTaskSubscribers,
    fetchAssignedCount,
    fetchTasks,
    fetchTasksCount,
    flag,
    noAuthUnsubscribe,
    noAuthSubscribe,
    setSortColumns,
    updateTaskState,
    getAllAvailableStates,
    fetchActivity
  }

  let sortColumns = [
    {
      key: 'title',
      label: 'Title',
      direction: 'ASC',
      order: 0
    },
    {
      key: 'created_at',
      label: 'Created',
      direction: 'DESC',
      order: 1
    },
    {
      key: 'deadline_at',
      label: 'Deadline',
      direction: 'ASC',
      order: 0
    }
  ]

  return service

  function action(taskId: string, taskAction: TaskAction, data?: any) {
    const defer = $q.defer()

    tasks
      .createTaskAction({ action: taskAction, taskId, ...(data && { payload: data }) })
      .then((response) => {
        defer.resolve(response)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function updateTaskState(taskId: string, taskState: string) {
    const defer = $q.defer()

    tasks
      .updateTaskState({ taskId, taskState })
      .then((response) => {
        defer.resolve(response)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function addComment(taskId, data) {
    const defer = $q.defer()

    tasks
      .createTaskNote({ taskId, payload: data })
      .then((response) => {
        defer.resolve(response)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function amendAssignees(taskId: string, assignees: string[]) {
    const defer = $q.defer()

    tasks
      .createTaskAssignee({ taskId, payload: { assignees } })
      .then((response) => {
        defer.resolve(response)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function amendTask(taskId, taskData = {}) {
    const defer = $q.defer()

    tasks
      .updateTask({ taskId, payload: taskData })
      .then((response) => {
        defer.resolve(response)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function createTask(task) {
    const defer = $q.defer()

    tasks
      .createTask({
        payload: {
          title: task.title,
          body: task.body,
          deadlineAt: moment(task.deadlineAt).format('YYYY-MM-DD HH:mm:ss'),
          recordId: task.recordId,
          assignees: task.assignees
        }
      })
      .then((response) => defer.resolve(response))
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function fetchTasks(additionalParams = {}) {
    const defer = $q.defer()

    const params = _assign(
      {
        sort: getSortString(),
        limit: 20
      },
      additionalParams
    )

    tasks
      .fetchTasks({ query: params })
      .then((response) => defer.resolve(response))
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function fetchTasksCount(additionalParams = {}) {
    const defer = $q.defer()

    const params = _assign(
      {
        page: 1,
        limit: 1
      },
      _pickBy(additionalParams, (val, key) => {
        return key !== 'cursor' && key !== 'limit'
      })
    )

    tasks
      .fetchTasks({ query: params })
      .then((response) => defer.resolve(response.cursor.page.total))
      .catch((rejection) => defer.reject(rejection.response))

    return defer.promise
  }

  function fetchAssignedCount(user) {
    const defer = $q.defer()
    const params = {
      page: 1,
      limit: 1,
      state: 'open,pending',
      ...(user && { filter: `assignee_id=${user.id}` })
    }

    tasks
      .fetchTasks({
        query: params
      })
      .then((response) => defer.resolve(response.cursor.page.total))
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function flag(account) {
    let status = 'disabled'
    let flagData

    if (account && account.flags) {
      flagData = _find(account.flags, { key: 'tasks' })
    }

    if (flagData && flagData.active) {
      status = 'active'
    } else if (flagData) {
      status = 'inactive'
    }

    return status
  }

  function getTaskSubscribers(taskId) {
    return tasks.fetchTaskSubscribers({
      taskId
    })
  }

  function getSortArray() {
    const currentSort = []
    const sortedColumns = _filter(sortColumns, (column) => {
      return column.order > 0
    })

    sortedColumns.forEach((column) => {
      if (column.direction === 'ASC') {
        currentSort.push(column.key)
      } else if (column.direction === 'DESC') {
        currentSort.push('-' + column.key)
      }
    })

    return currentSort
  }

  function getSortString() {
    return getSortArray().join(',')
  }

  function getSortColumns() {
    return sortColumns
  }

  function getTaskById(id: string) {
    const defer = $q.defer()

    tasks
      .fetchTask({
        taskId: id,
        query: {
          include: 'record,user,assignees,account,log,uploads,task_state'
        }
      })
      .then((response) => defer.resolve(response))
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function noAuthUnsubscribe(taskId, subscriberId) {
    return $http({
      method: 'DELETE',
      url: `${BASE_URL}/tasks/${taskId}/subscribers/${subscriberId}/unsubscribe`,
      headers: {
        Accept: API_VERSION
      }
    })
  }

  function noAuthSubscribe(taskId, subscriberId) {
    return $http({
      method: 'POST',
      url: `${BASE_URL}/tasks/${taskId}/subscribers/${subscriberId}/resubscribe`,
      headers: {
        Accept: API_VERSION
      }
    })
  }

  function setSortColumns(updatedColumns) {
    sortColumns = updatedColumns

    return sortColumns
  }

  function getAllAvailableStates() {
    const states = [
      {
        id: 'state1',
        key: 'open',
        name: 'Open'
      },
      {
        id: 'state2',
        key: 'pending',
        name: 'Pending Close'
      },
      {
        id: 'state3',
        key: 'closed',
        name: 'Closed'
      }
    ]

    return states
  }

  function fetchActivity(logId, accountId) {
    return $http({
      method: 'GET',
      url: `${BASE_URL}/accounts/${accountId}/activities`,
      params: {
        log_id: logId,
        type: 'log_was_created'
      }
    })
  }
}
