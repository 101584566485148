export class filterParams {
  // In the provider function, you cannot inject any
  // service or factory. This can only be done at the
  // "$get" method.
  $get = function () {
    return {
      params: this.params
    }
  }

  params = function () {
    return ['filter_id', 'include', 'inspection', 'filter', 'field-filter', 'state', 'tag', 'name', 'search', 'starred', 'task_active']
  }
}
