import * as React from 'react'
import { Icon } from '../../components'
import { Text, View, createStyledComponent } from '../../components/styled'

import { Activity } from '../models'
import { Upload } from '../../upload'
import { User } from '../../user'
import { ActivityTitle } from './activity-title'
import { ActivityContent } from './activity-content'
import { ActivityUploads } from './activity-uploads'
import Hyperlink from '../../components/hyperlink'

interface Props {
  activity: Activity
  viewType: string
  onPressActivity: (activity: Activity) => void
  onPressUpload: (upload: Upload, user: User) => void
}

const Wrapper = createStyledComponent(View, {
  overflow: 'visible'
})

export const ActivityTaskNoteWasCreated = ({ activity, viewType, onPressActivity, onPressUpload }: Props) => {
  switch (viewType) {
    case 'simple':
      return (
        <Wrapper>
          <ActivityTitle activity={activity} onPressActivity={onPressActivity}>
            Note was created
          </ActivityTitle>
          <ActivityContent>
            {activity.to.comment && <Hyperlink text={`Comments: ${activity.to.comment}`} textComponent={<Text />} />}
            {activity.to.uploads && !!activity.to.uploads.length && (
              <ActivityUploads activity={activity} onPressUpload={onPressUpload} />
            )}
          </ActivityContent>
        </Wrapper>
      )

    case 'table':
      return (
        <Wrapper>
          <Text>ActivityTaskNoteWasCreated</Text>
        </Wrapper>
      )

    default:
      return (
        <Wrapper>
          <Text>ActivityTaskNoteWasCreated</Text>
        </Wrapper>
      )
  }
}
