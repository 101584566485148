const uuid = '(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})'

export function ptImportsDataMapRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('imports.import.accounts.folders.map', {
    url: `/{folderId:${uuid}}`,
    views: {
      'crumbs@imports': {
        component: 'ptImportsCrumbs'
      },
      'main@imports': {
        component: 'ptImportsDataMap'
      }
    },
    resolve: {
      folder: resolveFolder,
      crumbs: resolveCrumbs,
      self: resolveSelf
    },
    onEnter: onEnter,
    onExit: onExit
  })

  function resolveFolder(foldersData, $transition$, account, ErrorsModal) {
    'ngInject'

    return foldersData.getFolder(account, $transition$.params().folderId).catch((response) => {
      ErrorsModal.openModal(response.error)
    })
  }

  function resolveCrumbs(gettextCatalog) {
    'ngInject'

    return [
      {
        name: gettextCatalog.getString('Imports'),
        link: 'imports'
      },
      {
        name: gettextCatalog.getString('Import Details'),
        link: '^.^.^'
      },
      {
        name: gettextCatalog.getString('Accounts'),
        link: '^.^'
      },
      {
        name: gettextCatalog.getString('Folders'),
        link: '^'
      }
    ]
  }

  function resolveSelf(gettextCatalog) {
    'ngInject'

    return gettextCatalog.getString('Import')
  }

  function onEnter(EventsPusher) {
    'ngInject'
  }

  function onExit($window, EventsPusher, ImportsData, gettextCatalog) {
    'ngInject'

    let result

    if (ImportsData.isImporting()) {
      result = $window.confirm(gettextCatalog.getString('Imports are still completing, are you sure you want to exit?'))
    }

    return result
  }
}
