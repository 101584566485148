import angular from 'angular'
import { ptUsersLoginModule } from './components/pt-users-login'
import { ptUsersLoginFormModule } from './components/pt-users-login-form'
import { ptUsersLogoutModule } from './components/pt-users-logout'
import { ptUsersRegisterModule } from './components/pt-users-register'
import { ptUsersRegisterFormModule } from './components/pt-users-register-form'

import { ptUserValidationButtonModule } from './directives/pt-user-validation-button'

import { usersData } from './services/data'
import { UserModel } from './services/models'
import { userSwitchModal } from './services/switch-modal'

export const UsersModule = angular
  .module('users', [
    // components
    ptUsersLoginModule,
    ptUsersLoginFormModule,
    ptUsersLogoutModule,
    ptUsersRegisterModule,
    ptUsersRegisterFormModule,
    // directives
    ptUserValidationButtonModule
  ])
  .factory('usersData', usersData)
  .factory('UserModel', UserModel)
  .factory('userSwitchModal', userSwitchModal).name
