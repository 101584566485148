export function ActivitiesChecklist() {
  'ngInject'

  const service = {
    calculateChecklistSelected
  }

  return service

  function calculateChecklistSelected(checklist) {
    const sections = checklist.sections.data || checklist.sections

    const results = sections.map((section) => {
      const fields = section.fields.data || section.fields

      return fields.reduce(
        (result, field) => ({
          selected: result.selected + (field.value ? 1 : 0),
          total: result.total + 1
        }),
        { selected: 0, total: 0 }
      )
    })

    return results.reduce(
      (result, item) => ({
        selected: result.selected + item.selected,
        total: result.total + item.total
      }),
      { selected: 0, total: 0 }
    )
  }
}
