export function InvoicesData(collections, pagination) {
  'ngInject'

  let service = {
    init: init,
    fetch: fetch,
    getInvoice: getInvoice,
    getAll: getAll,
    getDownloadUrl: getDownloadUrl
  }
  return service

  function init(subscription, params) {
    return subscription.invoices.$collection(params)
  }

  function fetch(collection) {
    let params = {
      page: pagination.nextPage(collection)
    }

    return collection.$fetch(params).$asPromise()
  }

  function getInvoice(subscription, invoiceNumber) {
    return subscription.invoices.$find(invoiceNumber).$asPromise()
  }

  function getAll(subscription) {
    let collection = subscription.invoices.$collection()

    return collections.getAll(collection)
  }

  function getDownloadUrl(subscription, invoice) {
    return getInvoice(subscription, invoice.invoice_number).then(function (response) {
      return response.download
    })
  }
}
