export const newLogRoutes = [
  'account.records.new-log',
  'iauditor.audit.new-log',
  'activities.records.new-log',
  'bucket.records.new-log',
  'folder.records.new-log',
  'folders.records.new-log',
  'folder.new-log',
  'bucket.new-log',
  'folders.new-log',
  'folder.scan.new-log',
  'folders.scan.new-log',
  'account.scan.new-log'
]

export const ptLogsNewRoute = ($stateProvider) => {
  'ngInject'

  let newLogModal

  const newLogState = (state) => {
    $stateProvider.state(state, {
      url: '/logs/new?{recordIds}&{sort}&{taskId}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: addLogPermission,
        resolvedRecords: resolveRecords,
        batch: isBatch
      },
      onEnter: onNewLogEnter,
      onExit: onNewLogExit
    })
  }

  const isBatch = ($stateParams) => {
    if ($stateParams.recordIds) {
      return true
    } else {
      return false
    }
  }

  const resolveRecords = (account, $stateParams, recordsResolves, ErrorsDisplay) => {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    let selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves
      .records(selection, 'state,log,fields,folder,frequency,checklists,discard_criteria,uploads', $stateParams.sort)
      .catch(() => ErrorsDisplay.catch('select_records_for_log'))
  }

  const addLogPermission = (account, member, permissionsCheck, ErrorsResolve) => {
    'ngInject'

    return permissionsCheck.canPromise('add', 'logs', account).catch(ErrorsResolve.caught)
  }

  const onNewLogEnter = ($uibModal, $state, resolvedRecords, batch, page) => {
    'ngInject'

    page.setTitle('Add Inspection')

    newLogModal = $uibModal.open({
      component: 'ptLogsNew',
      backdrop: 'static',
      windowClass: 'test_new-log',
      size: 'xl',
      resolve: {
        records: () => resolvedRecords,
        isBatch: () => batch
      }
    })

    newLogModal.result.catch((reason) => {
      //when inspections are completed, navigation to new state is handled in controller remove method
      //so we can go to the 'next action' dialog if required

      if (reason !== 'inspections_completed') {
        $state.go('^')
      }
    })
  }

  const onNewLogExit = (page) => {
    'ngInject'
    page.resetTitle()
    newLogModal.close()
  }

  newLogRoutes.forEach((route) => {
    newLogState(route)
  })
}
