export function Controller($element) {
  'ngInject'

  let $ctrl = this
  let elemNode
  let dummyNode

  $ctrl.tooltipOpen = false

  $ctrl.control = {
    displayTooltip: displayTooltip,
    closeTooltip: closeTooltip
  }

  $ctrl.$postLink = function () {
    elemNode = $element[0].children[0]
    dummyNode = $element[0].children[1]
  }

  function displayTooltip() {
    if (clientRectWidth(dummyNode) > clientRectWidth(elemNode)) {
      $ctrl.tooltipOpen = true
    }
  }

  function closeTooltip() {
    $ctrl.tooltipOpen = false
  }

  function clientRectWidth(el) {
    return el.getBoundingClientRect().width
  }
}
