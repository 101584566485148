import { Controller } from './controller'
import view from './view.html'

export function ptUploadSingle() {
  'ngInject'

  let directive = {
    template: view,
    link: (scope, element, attrs) => {
      scope.vm.nolink = 'nolink' in attrs
    },
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'E',
    scope: {
      upload: '<',
      user: '<'
    }
  }

  return directive
}
