/**
 * Additional custom validation for a password input field
 * To use:
 * <input name="password" minlength="8" required ng-model="$ctrl.formModel.password" pt-password-validation>
 */

import PasswordValidator from '../../services/password-validator'

export function ptPasswordValidation() {
  'ngInject'

  let directive = {
    require: 'ngModel',
    link: link,
    restrict: 'A'
  }

  return directive

  function link(scope, element, attrs, ctrl) {
    ctrl.$validators.passwordValidation = function (modelValue, viewValue) {
      if (ctrl.$isEmpty(modelValue)) {
        //no point checking further
        return true
      }

      return PasswordValidator(viewValue)
    }
  }
}
