export function Controller(richText, moment, ActivitiesChecklist) {
  'ngInject'

  let vm = this

  // 1 minute
  let dateDiffThreshold = 60000

  vm.control = {
    openRecord: openRecord
  }

  vm.richText = richText
  vm.getChecklistStatusString = getChecklistStatusString
  vm.loggedAtDiffers = dateDiffInMilliseconds(vm.data.to.logged_at, vm.data.to.created_at) > dateDiffThreshold

  vm.stateColor = {
    'background-color': 'rgb(' + vm.data.to.state.colour + ')'
  }

  ////////////

  vm.$onInit = function () {
    vm.checklistOptions = {
      canExpand: false,
      isExpanded: true,
      readOnly: true,
      editField: true
    }

    vm.checklistOptionsSimple = {
      canExpand: true,
      isExpanded: false,
      readOnly: true,
      editField: true
    }
  }

  function getChecklistStatusString(checklist) {
    const result = ActivitiesChecklist.calculateChecklistSelected(checklist)
    return `${result.selected} / ${result.total}`
  }

  function dateDiffInMilliseconds(date1, date2) {
    // Return the difference as a positive number for comparing
    return Math.abs(moment(date1).diff(moment(date2)))
  }

  function openRecord() {
    vm.openRecord()
  }
}
