import _filter from 'lodash/filter'

export function TemplatesData(ptApi) {
  'ngInject'

  const defaultFirst = (templates) => {
    // ensure default template is at the beginning of selection
    return _filter(templates, { key: 'default' }).concat(
      _filter(templates, function (template) {
        return template.key !== 'default'
      })
    )
  }

  const get = (type) => {
    return ptApi
      .request({
        method: 'GET',
        url: '/templates'
      })
      .then(function (response) {
        const sortedTemplates = defaultFirst(response.data)

        if (type) {
          return _filter(sortedTemplates, { [type]: true })
        }

        return sortedTemplates
      })
  }

  const getForSubscription = (subscriptionId) => {
    return ptApi
      .request({
        method: 'GET',
        url: `/templates/${subscriptionId}`
      })
      .then(function (response) {
        const sortedTemplates = defaultFirst(response.data)
        return sortedTemplates
      })
  }

  return {
    get,
    getForSubscription
  }
}
