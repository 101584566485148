export function recordsViewMode() {
  'ngInject'

  let validModes = ['record', 'folder']
  let lastMode = 'folder'

  let service = {
    setInitialParams: setInitialParams,
    set: set,
    get: get,
    filters: filters
  }

  return service

  function setInitialParams($stateParams) {
      set($stateParams.view)
    }

  function set(mode) {
    if (mode && validModes.indexOf(mode) > -1) {
      lastMode = mode
    }
  }

  function get() {
    return lastMode
  }

  function filters() {
    return {
      view: get()
    }
  }
}
