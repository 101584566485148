import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'

export function Controller($scope, $timeout, session, membersData, ivhTreeviewBfs, context) {
  'ngInject'

  let vm = this

  vm.control = {
    changeHandler: changeHandler,
    selectAll: selectAll,
    selectNone: selectNone
  }

  vm.members = {
    admin: null,
    nonAdmin: null
  }

  vm.options = {
    idAttribute: 'id',
    labelAttribute: 'name',
    selectedAttribute: '$selected',
    twistieCollapsedTpl: `<pt-icon name="'rightDir'" />`,
    twistieExpandedTpl: `<pt-icon name="'downDir'" />`,
    twistieLeafTpl: '<span class="icon-fw icon-none"></span>',
    validate: true,
    defaultSelectedState: false,
    nodeTpl: `
      <div>
        <span ng-if="::!node.admin" ivh-treeview-checkbox></span>
        <span class="ivh-treeview-node-label" ivh-treeview-toggle>
          {{::trvw.label(node)}}
        </span>
        <div ivh-treeview-children ng-if="trvw.isExpanded(node)"></div>
      </div>
    `
  }

  //////////

  vm.$onInit = function () {
    vm.loadingMembers = true

    getMembers()
      .then(function () {
        $timeout(function () {
          // We need to wait for a digest cycle to have happened to set the flags on each folder

          vm.picked = getSelectedIds(vm.members.nonAdmin)
          vm.ready = true
        })
      })
      .finally(function () {
        vm.loadingMembers = false
      })
  }

  function getMembers() {
    vm.ready = false

    return membersData.getAll(context.get('account').id).then(function (members) {
      let membersExcludingSelf = removeSelf(members)

      let processedMembers = processMembersData(membersExcludingSelf)

      vm.members.admin = filterAdmin(processedMembers, true)
      vm.members.nonAdmin = filterAdmin(processedMembers, false)

      setSelectedIds(vm.members.nonAdmin, vm.picked)
    })
  }

  function processMembersData(members) {
    return _sortBy(
      _map(members, function (member) {
        member.name = member.user.first_name + ' ' + member.user.last_name

        return member
      }),
      function (member) {
        return member.name.toLowerCase()
      }
    )
  }

  function removeSelf(members) {
    let currentUserId = session.user.id

    return _filter(members, function (member) {
      return member.admin.id !== currentUserId
    })
  }

  function filterAdmin(members, state) {
    return _filter(members, { admin: state })
  }

  function changeHandler(node, isSelected, tree) {
    vm.picked = getSelectedIds(tree)
  }

  function selectAll(state) {
    if (state === undefined) {
      state = true
    }

    ivhTreeviewBfs(vm.members.nonAdmin, function (node) {
      node.$selected = state
    })

    vm.picked = getSelectedIds(vm.members.nonAdmin)
  }

  function selectNone() {
    selectAll(false)
  }

  function setSelectedIds(tree, selected) {
    ivhTreeviewBfs(tree, function (node) {
      if (_includes(selected, node.id)) {
        node.$selected = true
      }
    })
  }

  function getSelectedIds(tree) {
    let ids = []

    ivhTreeviewBfs(tree, function (node) {
      if (node.$selected) {
        ids.push(node.id)
      }
    })

    return ids
  }
}
