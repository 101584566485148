import angular from 'angular'
import { LogsData } from './services/data'
import { LogsModel } from './services/models'
import { ptLogsDiscardCriteriaModule } from './components/pt-logs-discard-criteria'
import { ptLogsDiscardCriteriaResultsModule } from './components/pt-logs-discard-criteria-results'
import { ptLogsNewModule } from './components/pt-logs-new'
import { ptLogsEditModule } from './components/pt-logs-edit'
import { ptLogsFormModule } from './components/pt-logs-form'

export const LogsModule = angular
  .module('logs', [
    ptLogsDiscardCriteriaModule,
    ptLogsDiscardCriteriaResultsModule,
    ptLogsNewModule,
    ptLogsEditModule,
    ptLogsFormModule
  ])
  .factory('LogsModel', LogsModel)
  .factory('LogsData', LogsData).name
