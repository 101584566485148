import { Controller } from './controller'
import view from './view.html'

export const ptUsersRegister = {
  bindings: {
    ssoProviders: '<'
  },
  controller: Controller,
  template: view
}
