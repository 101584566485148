import { Controller } from './controller'
import view from './view.html'

export const ptIauditorFilterBool = {
  bindings: {
    filterKey: '@',
    onUpdateFilterValue: '&'
  },
  controller: Controller,
  template: view
}
