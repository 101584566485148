import { map as _map } from 'lodash'

export function FieldsModel(restmod, timezoneUtil, moment, context) {
  'ngInject'

  const model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      value: {
        encode: function (value) {
          if (this.type === 'date') {
            return moment(value).format('YYYY-MM-DD')
          } else if (this.type === 'array') {
            return _map(value, 'text')
          }

          return value
        },
        decode: function (value) {
          var tmp = null

          if (this.type === 'date') {
            return timezoneUtil.convertUtcToAccountTz(value)
          }

          if (this.type === 'array' && value) {
            tmp = []

            value.split(', ').forEach(function (splitValue) {
              tmp.push({ text: splitValue })
            })
            return tmp
          }

          return value
        }
      }
    })

  return model
}
