export function ErrorsRoutes($stateProvider) {
  'ngInject'

  $stateProvider.state('error', {
    url: '/error',
    parent: 'layoutSplash',
    views: {
      'content@layoutSplash': {
        component: 'ptErrorsError'
      }
    },
    onEnter: onErrorEnter
  })

  $stateProvider.state('maintenance', {
    url: '/maintenance',
    parent: 'layoutSplash',
    views: {
      'content@layoutSplash': {
        component: 'ptErrorsMaintenance'
      }
    },
    onEnter: onMaintanceEnter
  })

  function onErrorEnter(gettextCatalog, $uibModalStack, EventsData, page) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Error'))
    prepErrorPage($uibModalStack, EventsData)
  }

  function onMaintanceEnter(gettextCatalog, $uibModalStack, EventsData, page) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Under maintenance'))
    prepErrorPage($uibModalStack, EventsData)
  }

  function prepErrorPage($uibModalStack, EventsData) {
    'ngInject'

    $uibModalStack.dismissAll('error')
    EventsData.broadcast('routeResolver:resolved')
  }
}
