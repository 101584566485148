export function editableMoney($timeout, editableDirectiveFactory) {
  'ngInject'

  return editableDirectiveFactory({
    directiveName: 'editableMoney',
    inputTpl: '<pt-record-field-moneypicker></pt-record-field-moneypicker>',
    autosubmit: function () {
      let self = this

      self.scope.$on('autoSubmit', function () {
        self.scope.$form.$submit()
      })

      $timeout(function () {
        let input = self.editorEl.find('input')

        input.bind('blur', function () {
          $timeout(function () {
            // Dont submit if the select is now in focus
            if (!self.editorEl.find('select').is(':focus')) {
              self.scope.$form.$submit()
            }
          })
        })
      })
    }
  })
}
