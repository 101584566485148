import { filter as _filter } from 'lodash'

export function FieldsIdentifiers() {
  'ngInject'

  const service = {
    get
  }

  return service

  function get(fields) {
    return _filter(fields, { type: 'identifier' })
  }
}
