import { createResource } from '../../api/resource'
import { transformCamelToSnake, createResponsePaginationTransformer } from '../../api/transformer'
import { Task } from '../index'

interface FetchTasksQuery {
  cursor?: number
  include?: string
  sort?: string
  limit?: number
  filter?: string
  page?: number
  state?: string
  taskId?: string
  folderId?: string
  accountId?: string
  recordId?: string
}

interface Params {
  query?: FetchTasksQuery
}

export const fetchTasks = createResource({
  request: ({ query }: Params) => ({
    url: `tasks`,
    method: 'GET',
    params: query && transformCamelToSnake(query)
  }),
  transform: createResponsePaginationTransformer<Task>(
    'record',
    'user',
    'source',
    'assignees.user',
    'uploads',
    'account',
    'log',
    'task_state'
  )
})
