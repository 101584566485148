import { Controller } from './controller'
import view from './view.html'

export const ptTags = {
  template: view,
  controller: Controller,
  bindings: {
    tags: '<',
    limit: '@'
  }
}
