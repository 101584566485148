import _find from 'lodash/find'
import _forEach from 'lodash/forEach'

export function Controller(
  $scope,
  $state,
  $timeout,
  EventsData,
  recordsBucket,
  recordsData,
  recordsResolves,
  BlueprintsData,
  permissionsCheck,
  ErrorsModal,
  TasksData,
  context,
  FieldsData,
  ChecklistsCache
) {
  'ngInject'

  const getAllTaskStates = (account) => {
    return account.task_states.data.map((t) => t.key).join(',')
  }

  const getNonClosedTaskStates = (account) => {
    return account.task_states.data
      .map((t) => t.key)
      .filter((t) => t !== 'closed')
      .join(',')
  }

  const fieldsForSection = (section) => {
    const fields = []

    _forEach(section.fields, (fieldKey) => {
      const field: any = _find(this.record.fields, { key: fieldKey })
      if (field) {
        fields.push(field)
      }
    })

    return fields
  }

  const getRecord = (recordId) => {
    return recordsResolves.expandedRecord(recordId).then((expandedRecord) => {
      this.record = expandedRecord
      setupRecord(this.record)
    })
  }

  const hasFields = (section) => {
    return !!_find(fieldsForSection(section), 'value')
  }

  const listen = () => {
    const eventsWhitelist = [
      'record_was_updated',
      'record_was_moved',
      'record_was_deleted',
      'record_upload_was_added',
      'record_upload_was_updated',
      'log_was_created',
      'log_was_updated',
      'log_was_deactivated',
      'log_was_activated',
      'field_was_created',
      'field_was_updated',
      'field_was_deleted',
      'note_was_created',
      'note_was_updated',
      'note_was_deleted',
      'service_log_was_created',
      'service_certificate_was_created'
    ]

    $scope.$on('task_was_created', (event, data) => {
      const recordShouldUpdate = data.data.record && data.data.record.id === this.record.id
      if (recordShouldUpdate) {
        setupTasks()
      }
    })

    $scope.$on('task_was_updated', (event, data) => {
      const recordShouldUpdate = data.data.record && data.data.record.id === this.record.id
      if (recordShouldUpdate) {
        setupTasks()
      }
    })

    $scope.$on('task_state_was_updated', (event, data) => {
      const recordShouldUpdate = data.data.record && data.data.record.data.id === this.record.id
      if (recordShouldUpdate) {
        setupTasks()
      }
    })

    $scope.$on('modal.closing', modalClosing)
    $scope.$on('record_was_deleted', recordWasDeleted)

    _forEach(eventsWhitelist, (event) => {
      $scope.$on(event, updateRecord)
    })
  }

  const modalClosing = (e, reason) => {
    // Allow the modal to close if the user has been unauthorised
    if (reason === 'login-cancelled') {
      this.dismiss({ $value: 'cancel' })
    } else if (this.uploading) {
      e.preventDefault()

      $scope.$watch('this.uploading', (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.dismiss()
        }
      })
    }

    // prevent default and close after a timeout to allow forms in edit
    // more to catch the blur event and start saving
    else if (reason !== 'timeout') {
      EventsData.broadcast('autoSubmit')
    }
  }

  const recordWasDeleted = (e, recordDto) => {
    if (recordDto.recordId === this.record.id) {
      this.dismiss()
    }
  }

  const setupRecord = (recordToSetup) => {
    if (!this.record.archived_at && permissionsCheck.can('edit', 'records', this.account, this.record)) {
      recordToSetup.$editable = true
    } else {
      recordToSetup.$editable = false
    }

    if (
      !this.record.log.id &&
      !this.record.note.id &&
      permissionsCheck.can('edit', 'records', this.account, this.record)
    ) {
      recordToSetup.$deletable = true
    } else {
      recordToSetup.$deletable = false
    }

    this.record.frequency_id = this.record.frequency.id

    this.sections = BlueprintsData.getSections(recordToSetup)
  }

  const setupTasks = () => {
    this.tasksLoading = true

    TasksData.fetchTasks({
      include: 'assignees,task_state',
      recordId: this.record.id,
      sort: '-created_at',
      accountId: this.account.id,
      state: getNonClosedTaskStates(this.account)
    })
      .then((response) => {
        this.tasks = response.items
      })
      .finally(() => {
        this.tasksLoading = false
      })
  }

  const updateRecord = (e, dto) => {
    if (dto.recordId === this.record.id) {
      getRecord(this.record.id)
    }
  }
  const removeFromBucket = (recordId) => {
    recordsBucket.removeMany([recordId]);
  }

  this.$onInit = () => {
    this.account = context.get('account')

    this.record = this.resolve.record
    this.sections = {}
    this.allFields = false
    this.hasFields = hasFields
    this.fieldsForSection = fieldsForSection
    this.permissionsCheck = permissionsCheck
    this.uploading = false
    this.tasks = []
    this.tasksLoading = true
    this.showTasks = TasksData.flag(this.account) === 'active'
    this.isViewOnly = permissionsCheck.is('viewOnly', this.account)

    this.recordsBucket = recordsBucket

    try {
      this.canAddTasks = permissionsCheck.can('add', 'tasks', this.account)
    } catch {
      this.canAddTasks = false
    }

    this.allFields = this.resolve.$transition.params().openfields
    this.accountChecklists = ChecklistsCache.get(this.account)
    this.allTaskStates = getAllTaskStates(this.account)

    setupRecord(this.record)
    setupTasks()

    listen()
  }

  this.closeModal = () => {
    this.dismiss({ $value: 'cancel' })
  }

  this.toggleFields = () => {
    this.allFields = !this.allFields
  }

  this.cloneRecord = () => {
    if (permissionsCheck.enforce('add', 'records', this.account)) {
      $state.go('folder.clone-records', { folderId: this.record.folder.id, recordIds: this.record.id })
    }
  }

  
  this.deleteRecord = () => {
    recordsData.delete(this.record).then(
      () => {
        // Ensure the record is removed from the bucket when deleted
        removeFromBucket(this.record.id)
        this.dismiss({ $value: 'deleted' })
      },
      (rejection) => {
        ErrorsModal.openModal(rejection.$response.data.error)
      }
    )
  }

  this.moveRecord = () => {
    if (permissionsCheck.enforce('edit', 'records', this.account)) {
      this.dismiss({ $value: 'move' })

      $timeout(() => {
        $state.go('folder.move-records', { folderId: this.record.folder.id, recordIds: this.record.id })
      }, 100)
    }
  }

  this.saveField = (field, value) => {
    return FieldsData.saveField(this.record, field, value).catch(() => {})
  }
}
