export function RecordsBatchEditRoute($stateProvider) {
  'ngInject'

  let batchEditRecordModal

  $stateProvider.state('bucket.edit-records', editRecordsStates())
  $stateProvider.state('folders.edit-records', editRecordsStates())
  $stateProvider.state('folder.edit-records', editRecordsStates())
  $stateProvider.state('folder.scan.edit-records', editRecordsStates())
  $stateProvider.state('folders.scan.edit-records', editRecordsStates())
  $stateProvider.state('account.scan.edit-records', editRecordsStates())

  function editRecordsStates() {
    return {
      url: '/records/edit?{recordIds}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: editRecordPermission,
        resolvedRecords: resolveEditRecords
      },
      onEnter: onBatchEditRecordEnter,
      onExit: onBatchEditRecordExit
    }
  }

  function editRecordPermission(account, member, permissionsCheck, ErrorsResolve) {
    'ngInject'

    return permissionsCheck.canPromise('edit', 'records', account).catch(ErrorsResolve.caught)
  }

  function onBatchEditRecordEnter($uibModal, $state, resolvedRecords) {
    'ngInject'

    batchEditRecordModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptRecordsBatchEdit',
      resolve: {
        records: () => resolvedRecords
      }
    })

    batchEditRecordModal.result.catch(() => {
      $state.go('^')
    })
  }

  function onBatchEditRecordExit() {
    'ngInject'

    batchEditRecordModal.close()
  }

  function resolveEditRecords(account, $stateParams, recordsResolves, ErrorsDisplay) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    let selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves.records(selection, 'log,state,fields,folder').catch(() => {
      return ErrorsDisplay.catch('select_records')
    })
  }
}
