import view from './view.html'

export function ptRecordsTableFieldCreatedAt() {
  'ngInject'

  let directive = {
    template: view,
    controller: function () {},
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      record: '<'
    }
  }

  return directive
}
