//import './help-popover.html'

import { chartOptions, chartOptionsPrint } from './chart-options'

export function Controller(context, ReportsData, $uibModal, filteringContext) {
  'ngInject'

  const $ctrl = this
  let exportModal

  $ctrl.$onInit = () => {
    $ctrl.account = context.get('account')
    $ctrl.chartOptions = chartOptions
    $ctrl.chartOptionsPrint = chartOptionsPrint
    $ctrl.chartData = []
    $ctrl.chartDatasetOverride = {}
    $ctrl.chartColors = []
    $ctrl.chartLabels = []
    $ctrl.chartFilters = []

    $ctrl.helpPopover = 'components/pt-record-state-widget/help-popover.html'
    $ctrl.loading = false

    getReportData()
  }

  const getReportData = () => {
    $ctrl.loading = true

    ReportsData.getReportData($ctrl.account.id, 'record_state_compliance', {})
      .then(buildDataSet)
      .catch(() => {})
      .finally(() => {
        $ctrl.loading = false
      })
  }

  const buildDataSet = (entries) => {
    const chartColors = []
    const chartData = []
    const chartLabels = []

    const stateIncluded = (name) => $ctrl.widget.meta.segments.some((segment: any) => segment.name === name)

    const totalRecords = entries
      .filter((it: any) => stateIncluded(it.name))
      .map((it: any) => it.count)
      .reduce((prev, current) => prev + current, 0)

    const percent = (count) => {
      return Math.round((1000 * count) / totalRecords) / 10
    }

    const addSegment = (name, count, color) => {
      chartLabels.push(name + ': ' + count + ' (' + percent(count) + '%)')
      chartData.push(count)
      chartColors.push(color)
    }

    $ctrl.widget.meta.segments.forEach((segment, index) => {
      const segData = entries.find((it: any) => it.name === segment.name)
      if (segData) {
        addSegment(segment.name, segData.count, segment.color)
        if (segment.name === 'Overdue') {
          $ctrl.chartFilters.push({ inspection: 'overdue' })
        } else {
          const state = $ctrl.account.states.find((it) => it.name === segment.name)
          $ctrl.chartFilters.push({ inspection: 'not_overdue', state: state.id })
        }
      }
    })

    $ctrl.chartData = chartData
    $ctrl.chartLabels = chartLabels
    $ctrl.chartColors = chartColors
    $ctrl.chartDatasetOverride.hoverBackgroundColor = chartColors
  }

  $ctrl.chartClick = (data) => {
    const filter = $ctrl.chartFilters[data[0]._index]
    filteringContext.applyFilter(filter, 'folders')
  }

  $ctrl.openExportModal = () => {
    exportModal = $uibModal.open({
      component: 'ptComplianceWidgetExport',
      resolve: {
        params: () => {
          return {}
        },
        report: () => 'record_state_compliance'
      }
    })

    exportModal.result.catch(() => {
      // user dismissed
    })
  }
}
