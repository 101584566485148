import { Controller } from './controller'
import { link } from './link'

export function ptScan() {
  'ngInject'

  let directive = {
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'A',
    link: link,
    scope: {
      ptScanSubmit: '&'
    },
    require: ''
  }

  return directive
}
