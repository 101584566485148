export function Controller($state, TasksData, $stateParams) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    openTask,
    subscribe,
    unsubscribe
  }

  $ctrl.hasError = false
  $ctrl.isLoading = false
  $ctrl.isSubscribed = true
  $ctrl.reSubscribed = false

  $ctrl.$onInit = function () {
    if ($stateParams.subscribeAction === 'resubscribe') {
      subscribe()
    } else {
      unsubscribe()
    }
  }

  function openTask() {
    $state.go('account.records.task', {
      accountId: $stateParams.accountId,
      recordId: $stateParams.recordId,
      taskId: $stateParams.taskId
    })
  }

  function subscribe() {
    $ctrl.hasError = false
    $ctrl.isLoading = true

    if ($stateParams.taskId && $stateParams.subscriberId) {
      TasksData.noAuthSubscribe($stateParams.taskId, $stateParams.subscriberId)
        .then(() => {
          $ctrl.isSubscribed = true
          $ctrl.reSubscribed = true
        })
        .catch(() => {
          $ctrl.hasError = true
        })
        .finally(() => {
          $ctrl.isLoading = false
        })
    }
  }

  function unsubscribe() {
    $ctrl.hasError = false
    $ctrl.isLoading = true
    $ctrl.reSubscribed = false

    if ($stateParams.taskId && $stateParams.subscriberId) {
      TasksData.noAuthUnsubscribe($stateParams.taskId, $stateParams.subscriberId)
        .then(() => {
          $ctrl.isSubscribed = false
        })
        .catch(() => {
          $ctrl.hasError = true
        })
        .finally(() => {
          $ctrl.isLoading = false
        })
    }
  }
}
