/**
 * This exists after upgrading to Angular 1.6 which introduced that all bindings
 * (or scope) on a directive or component could not be used until $onInit was called.
 *
 * This allows them to be used outside of $onInit again while upgrading
 */
export function BindingsInit($compileProvider) {
  'ngInject'

  $compileProvider.preAssignBindingsEnabled(true)
}
