import angular from 'angular'

export function Controller() {
  'ngInject'



  this.$onInit = function () {}

  this.openUniSearch = () => {
    const universalSearch = new CustomEvent("universalSearch", {
      detail: {
        show: true,
      },
    });
    window.dispatchEvent(universalSearch)
  }
}
