import angular from 'angular'

import _forEach from 'lodash/forEach'

export function Controller($filter) {
  'ngInject'

  let $ctrl = this

  $ctrl.filters = {
    members: {
      open: true,
      data: null,
      filteredData: null
    }
  }

  $ctrl.control = {
    membersUpdate: membersUpdate,
    toggleFilterVisible: toggleFilterVisible,
    selectAll: selectAll,
    clearSelection: clearSelection,
    filterMembers: filterMembers
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.members) {
      $ctrl.members = angular.copy($ctrl.members)
      $ctrl.control.filterMembers()
    }
  }

  function membersUpdate() {
    $ctrl.onUpdateMembers({
      $event: {
        members: $ctrl.filters.members.data
      }
    })
  }

  function toggleFilterVisible(type) {
    $ctrl.filters[type].open = !$ctrl.filters[type].open
  }

  function filterMembers() {
    let wordContainsValue = function (word, term) {
      return word.toLowerCase().indexOf(term.toLowerCase()) >= 0
    }

    $ctrl.filters.members.data = $filter('filter')($ctrl.members, function (item) {
      // No search term, show all.
      if (!$ctrl.membersFilter) {
        return true
      }

      // Check to see if the search term exists in either the first or last name. Case insensitive.
      return wordContainsValue($filter('fullname')(item.user), $ctrl.membersFilter)
    })
  }

  function selectAll(type) {
    toggleSelection(type, true)
  }

  function clearSelection(type) {
    toggleSelection(type, false)
  }

  function toggleSelection(type, value) {
    _forEach($ctrl.filters[type].data, function (item) {
      item.selected = value
    })
  }
}
