export function Controller() {
  'ngInject'

  let vm = this

  vm.toggleSort = null

  vm.$onInit = function () {
    vm.toggleSort = false
  }
}
