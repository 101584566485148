export function AccountsRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('accounts', {
    url: '/accounts?search&state&id',
    params: {
      search: {
        dynamic: true
      },
      state: {
        dynamic: true
      },
      id: {
        dynamic: true
      }
    },
    parent: 'layoutFull',
    views: {
      'content@layoutFull': 'ptAccounts'
    },
    onEnter: onAccountsListEnter,
    onExit: onAccountsListExit
  })

  function onAccountsListEnter(gettextCatalog, page) {
    'ngInject'
    /// Page title
    page.setTitle(gettextCatalog.getString('Home'))
  }

  function onAccountsListExit() {
    'ngInject'
  }
}
