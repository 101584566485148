import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _assign from 'lodash/assign'
import _remove from 'lodash/remove'
import { RRule, RRuleSet } from 'rrule'
import { RetirementStatus } from '../../services/types'
import { createTaskAction } from 'AppCore/task/api'

export function Controller(
  LogsData,
  recordsSelection,
  permissionsCheck,
  FieldsIdentifiers,
  moment,
  $state,
  $stateParams,
  context,
  statesData,
  session,
  DateFormat,
  $uibModal,
  $scope,
  $timeout,
  TutorialsFlags,
  $q,
  TasksData,
  timezoneUtil,
  ChecklistsSentiment
) {
  'ngInject'

  /**
   * After a selection occurs, check if there are any left to select.
   * If not, we won't show the select checkbox and 'add' button
   */
  const checklistsAreAvailable = () => {
    return this.checklists.some((checklist) => {
      return checklist.$selected ? false : true
    })
  }

  const prepareDiscardCriteriaForApi = () => {
    const discardCriteriaData = {
      id: this.discardCriteria.id,
      version: this.discardCriteria.version,
      answers: []
    }

    this.discardCriteria.questions.forEach((question) => {
      discardCriteriaData.answers.push({
        id: question.id,
        value: !!question.value
      })
    })

    return discardCriteriaData
  }

  const checkUploadsComplete = () => {
    if (!this.uploading) {
      return true
    }

    this.error = 'wait_for_uploads'

    return false
  }

  const getNextCheckFromToday = (record) => {
    const frequencyRule = _get(record, 'frequency.rule') || null
    if (frequencyRule !== null) {
      const ruleOptions = RRule.parseString(frequencyRule)
      ruleOptions.count = 2
      const rRuleSet = new RRuleSet()
      rRuleSet.rrule(new RRule(ruleOptions))

      const dates = rRuleSet.all()

      // If the first date is today, return the 2nd.
      // It won't always be today, e.g. if frequency is "1st Monday of Month"
      return moment(dates[0]).startOf('day') > moment().startOf('day') ? dates[0] : dates[1]
    }
  }

  const getInitialState = () => {
    const currentStateId = _get(this.record, 'log.state.id') || _get(this.record, 'state.id')

    const currentStateExists = this.states.some((state) => state.id === currentStateId)

    if (!currentStateExists) {
      const defaultState = this.states.find((state) => state.default)

      if (!defaultState) {
        return this.states[0].id
      }

      return defaultState.id
    }

    return currentStateId
  }

  const setRetirementStatus = () => {
    if (this.recordDateForRetirement) {
      if (moment().isAfter(this.recordDateForRetirement)) {
        this.retirementStatus = RetirementStatus.Overdue
      } else if (
        !this.showDatePickerField &&
        this.nextCheckFromToday &&
        moment(this.nextCheckFromToday).isAfter(this.recordDateForRetirement)
      ) {
        this.retirementStatus = RetirementStatus.Due
      } else if (
        this.showDatePickerField &&
        this.log.next_check_at &&
        moment(this.log.next_check_at).isAfter(this.recordDateForRetirement)
      ) {
        this.retirementStatus = RetirementStatus.Due
      } else {
        this.retirementStatus = RetirementStatus.None
      }
    }
  }

  this.$onInit = () => {
    this.tasks = []
    this.account = context.get('account')
    this.accountId = context.get('account').id
    this.showTasks = TasksData.flag(this.account) === 'active'

    this.account = context.get('account')

    this.showTasks = TasksData.flag(this.account) === 'active' && this.mode !== 'edit'

    this.states = statesData.get()

    this.states.forEach(function (state) {
      if (state.should_archive) {
        state.name = state.name + ' (Will archive)'
      }
    })

    const isViewOnly = permissionsCheck.is('viewOnly', this.account)
    const canAddTasks = permissionsCheck.can('add', 'tasks', this.account)

    this.canCreateTasks = !isViewOnly && canAddTasks

    this.retirementStatusTypes = RetirementStatus
    this.retirementStatus = RetirementStatus.None

    this.recordDateForRetirement = this.record.fields.find((field) => field.key === 'date_for_retirement')
    if (this.recordDateForRetirement) {
      this.recordDateForRetirement = this.recordDateForRetirement.value
    }

    this.nextCheckFromToday = getNextCheckFromToday(this.record)

    setRetirementStatus()

    if (this.mode === 'edit') {
      this.log = {
        ...this.record.log,
        next_check_at: new Date(this.record.log.next_check_at),
        state_id: getInitialState(),
        quantity: parseInt(this.record.log.quantity, 10) || 1,

        // removing unecessary fields
        lat: undefined,
        lng: undefined,
        logged_at: undefined
      }
    } else {
      this.log = {
        state_id: getInitialState(),
        comments: null,
        quantity: parseInt(this.record.log.quantity, 10) || 1,
        next_check_at: null
      }
    }

    if (this.record.discard_criteria) {
      this.discardCriteria = this.record.discard_criteria.data
      this.discardCriteria.questions = this.discardCriteria.questions.data

      this.log.discard_criteria = prepareDiscardCriteriaForApi()
    }

    this.checklists = _cloneDeep(this.record.checklists)

    if (_get(this.record, 'origin.value.slug') === 'teufelberger') {
      this.checklistOptions = {
        checklistVisible: false
      }
    }

    this.selectedChecklists = []

    if (this.checklists.length === 1) {
      this.selectChecklist(this.checklists[0].id)
    }

    this.accountId = this.account.id
    this.dateFormat = DateFormat.toUib(session.user.dateFormat)

    this.datepickerOptions = {
      showWeeks: false,
      startingDay: 1,
      showButtons: true,
      maxDate: null,
      minDate: moment()
    }

    this.canEditNextCheck = permissionsCheck.can('edit', 'logs', this.account)

    this.showDatePickerField = false
    this.files = []
    this.uploading = false
    this.saving = false
    this.error = null

    this.checklistsAvailable = checklistsAreAvailable()

    $scope.$watch('$ctrl.log.next_check_at', () => {
      if (this.log.next_check_at) {
        this.showDatePickerField = true
      }
      setRetirementStatus()
    })

    let audit

    if (this.mode !== 'edit') {
      if ($stateParams.auditId) {
        audit = {
          type: 'iauditor',
          value: $stateParams.auditId
        }

        this.files = this.files.concat([audit])

        this.log.files = [audit]
      }
    }

    this.onRegister({ scope: this })

    this.identifiers = FieldsIdentifiers.get(this.record.fields)

    this.showUploads = this.mode !== 'edit'

    this.checklistOptions = {
      canDelete: this.mode !== 'edit'
    }
    this.identifiers = FieldsIdentifiers.get(this.record.fields)
  }

  this.openCreateTaskModal = () => {
    $uibModal
      .open({
        component: 'ptTaskCreate',
        size: 'l',
        backdrop: 'static',
        resolve: {
          recordId: () => this.record.id,
          isLogTask: () => true
        }
      })
      .result.catch((task) => {
        if (task && task.id) {
          TasksData.getTaskById(task.id).then((newTask) => {
            this.tasks = [...this.tasks, ...[newTask]]
          })
        }
      })
  }

  this.isValid = () => {
    if (this.record.$scope.$scope.$scope.requires_upload && this.form.file.$viewValue.length === 0) {
      this.error = {
        message: 'Uploads are mandatory.'
      }
      return false
    }
    let fieldsData
    if (this.record.checklists && this.record.checklists[0] && this.record.checklists[0].sections) {
      fieldsData =
        this.record.checklists[0].sections.data && this.record.checklists[0].sections.data.length > 0
          ? this.record.checklists[0].sections.data[0].fields.data
          : this.record.checklists[0].sections[0].fields.data
    }

    if (fieldsData && Array.isArray(fieldsData)) {
      // Find the imgfile type in the data array
      const imgField = fieldsData.find((field) => field.type === 'imgfile' && field.response_required === true)
      //image file validation
      if (imgField) {
        if (typeof this.form.file === 'object' && this.form.file.$viewValue.length === 0) {
          this.error = {
            message: 'Required image upload is mandatory.'
          }
          return false
        }
      }
    }

    //textarea validation
    const longDescriptionField = this.form.longDescription

    if (longDescriptionField?.$error.required) {
      this.error = {
        message: 'Long description is required.'
      }
      return false
    }
    //date validation
    const dateField = this.form.date

    if (dateField?.$error.date) {
      this.error = {
        message: 'The date should be written as XX/XX/XXXX.'
      }
      return false
    }

    return this.form.$valid && !this.uploading
  }

  this.confirmDiscardCriteriaAdvice = () => {
    TutorialsFlags.setUserFlag('read_discard_criteria_warning')
  }

  this.copyValue = (key, currentValue) => {
    const fieldKey = key
    let value = _cloneDeep(currentValue)

    if (fieldKey === 'checklists') {
      value = _cloneDeep(this.selectedChecklists)
    }

    if (fieldKey === 'discard_critera') {
      value = _cloneDeep(this.discardCriteria)
    }

    this.onCopyDown({
      recordId: this.record.id,
      fieldKey,
      value
    })
  }

  this.discardCriteriaUpdated = (criteria) => {
    // TODO: R2A - remove angular timeout
    $timeout(() => {
      this.discardCriteria = criteria

      this.onDiscardCriteriaUpdated({ recordId: this.record.id, criteria: criteria })
      this.log.discard_criteria = prepareDiscardCriteriaForApi()
    })
  }

  function formatChecklistValue(field) {
    if (field.type === 'boolean') {
      return !!field.value
    } else if (field.type === 'date') {
      return field.value ? timezoneUtil.localToAccountTz(field.value).format('YYYY-MM-DD') : null
    } else {
      return field.value
    }
  }

  this.getChecklistValues = (checklists) => {
    const obj = {}
    const checklistIdentifier = this.mode === 'edit' ? 'checklistUuid' : 'id'

    checklists.forEach((checklist) => {
      obj[checklist[checklistIdentifier]] = {
        version: checklist.version
      }

      const checklistSections =
        checklist.sections.data && checklist.sections.data.length > 0 ? checklist.sections.data : checklist.sections

      checklistSections.forEach((section) => {
        section.fields.data.forEach((field) => {
          obj[checklist[checklistIdentifier]][field.id] = {
            value: formatChecklistValue(field),
            ...(field.comments && { comments: field.comments }),
            ...(field.uploads &&
              (field.type === 'sigfile' || field.type === 'imgfile' || field.type === 'genfile') && {
                response_uploads: field.uploads
              })
          }
          if (
            field.type === 'date' ||
            field.type === 'textarea' ||
            field.type === 'sigfile' ||
            field.type === 'imgfile' ||
            field.type === 'genfile'
          ) {
            obj[checklist[checklistIdentifier]][field.id].sentiment = ChecklistsSentiment.getSentiment(field)
          }
          if (field.options && field.options.length > 0) {
            // API expects options to contain just the option corresponding to the selected value
            obj[checklist[checklistIdentifier]][field.id].options = [
              field.options.find((option) => option.value === field.value)
            ]
          }
          if (field.uploads && (field.type === 'sigfile' || field.type === 'imgfile' || field.type === 'genfile')) {
            obj[checklist[checklistIdentifier]][field.id].uploads = []
          } else {
            obj[checklist[checklistIdentifier]][field.id].uploads = field.uploads
          }
        })
      })
    })
    return obj
  }

  this.remove = (reason) => {
    this.onRemove({ reason, recordId: this.record.id })
    this.onDeregister({ scope: this })
  }

  this.save = () => {
    const saveDefer = $q.defer()

    if (_get(this.selectedChecklists, 'length') > 0) {
      this.log.checklists = this.getChecklistValues(this.selectedChecklists)
    }

    if (this.form.$valid && checkUploadsComplete()) {
      this.saving = true
      this.error = null

      if (typeof this.log.checklists !== 'object') {
        this.log.checklists = {}
      }

      LogsData.create(this.record, this.log)

        .then((log) => {
          this.remove('success')
          recordsSelection.unselect(this.record)

          saveDefer.resolve(log)

          return log
        })
        .then((log) => {
          const defer = $q.defer()

          if (this.tasks.length) {
            this.tasks.forEach((task) => {
              return createTaskAction({
                action: 'assign-to-log',
                taskId: task.id,
                payload: { log_id: log.id }
              })
                .then(() => {
                  defer.resolve()
                })
                .catch(() => {
                  defer.reject()
                })
            })
          } else {
            defer.resolve()
          }

          return defer.promise
        })
        .catch((response) => {
          const responseData = response.$response.data

          this.saving = false
          this.error = responseData.error

          saveDefer.reject(responseData)
        })
    }

    return saveDefer.promise
  }

  this.update = () => {
    const updateDefer = $q.defer()

    if (_get(this.selectedChecklists, 'length') > 0) {
      this.log.checklists = this.getChecklistValues(this.selectedChecklists)
    }

    if (this.form.$valid) {
      this.saving = true
      this.error = null

      LogsData.update(this.accountId, this.record, this.log.id, this.log)
        .then((log) => {
          updateDefer.resolve(log)
          $state.go('^')

          return log
        })
        .catch((response) => {
          const responseData = response.$response.data
          this.saving = false
          this.error = responseData.error

          updateDefer.reject(responseData)
        })
    }

    return updateDefer.promise
  }

  this.setField = (fieldKey, value) => {
    const checklists = []

    if (fieldKey === 'checklists' && value.length) {
      // Only copy the checklists that this record has available to it
      value.forEach((checklist) => {
        const list = _find(this.checklists, { id: checklist.id })

        if (list) {
          checklists.push(_cloneDeep(checklist))
        }
      })

      if (checklists.length) {
        this.selectedChecklists = checklists
      }
    } else if (fieldKey === 'checklists' && !value.length) {
      this.selectedChecklists = _cloneDeep(value)
    } else if (
      fieldKey === 'discard_criteria' &&
      this.record.discard_criteria &&
      value.id === this.discardCriteria.id
    ) {
      this.discardCriteriaUpdated(_cloneDeep(value))
    } else {
      this.log[fieldKey] = _cloneDeep(value)
    }
  }

  this.setFiles = (event) => {
    this.log.files = event.model
  }

  this.setUploading = (event) => {
    this.uploading = event.uploading
  }

  this.selectChecklist = (checklistId: number) => {
    const checklist: any = _find(this.checklists, { id: checklistId })

    if (checklist) {
      checklist.$selected = true

      this.selectedChecklists.push(checklist)

      this.checklistsAvailable = checklistsAreAvailable()
    }
  }

  this.toggleDatePickerField = () => {
    this.showDatePickerField = !this.showDatePickerField
  }

  this.removeChecklist = (checklistId) => {
    const checklist: any = _find(this.checklists, { id: checklistId })
    let modal

    if (checklist) {
      modal = $uibModal.open({
        component: 'ptChecklistRemoveModal',
        resolve: {
          checklistName: () => checklist.name
        }
      })

      modal.result.catch(($value) => {
        if ($value) {
          checklist.$selected = false

          checklist.sections.data.forEach((section) => {
            section.fields.data.forEach((field) => {
              field.value = null
            })
          })

          _remove(this.selectedChecklists, (item: any) => {
            return item.id === checklistId
          })

          this.checklistsAvailable = checklistsAreAvailable()
        }
      })
    }
  }

  this.updateChecklist = (updatedChecklist) => {
    const checklist: any = _find(this.selectedChecklists, { id: updatedChecklist.id })
    if (checklist) {
      checklist.sections.data = _assign(
        [],
        updatedChecklist.sections.data && updatedChecklist.sections.data.length > 0
          ? updatedChecklist.sections.data
          : updatedChecklist.sections
      )
    }
  }

  this.submit = () => {
    this.submitted = true

    if (this.form.$valid) {
      this.save()
    }
  }
}
