import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isObject from 'lodash/isObject'
import _find from 'lodash/find'
import _omit from 'lodash/omit'
import _uniqBy from 'lodash/uniqBy'
import _trim from 'lodash/trim'
import _clone from 'lodash/clone'

export function FieldsData($q, validator, EventsData, BlueprintsData) {
  'ngInject'

  const service = {
    saveField,
    batchSaveField
  }

  return service

  ////////////////

  function saveField(record, field, value) {
    let defer = $q.defer()
    let validation

    if ((!value || (_isArray(value) && _isEmpty(value))) && value !== 0) {
      if (field.id) {
        return deleteField(record, field)
      }

      defer.resolve()
      return defer.promise
    }

    if (field.value !== value || _isObject(value)) {
      validation = validator.validate('field', field.type, value)
      if (!validation.valid) {
        defer.reject(validation.message)
        return defer.promise
      }

      field.value = value

      return field
        .$save(['value'])
        .$asPromise()
        .then(
          function (response) {
            let eventName

            if (response.$response.config.method === 'POST') {
              eventName = 'field_was_created'
            } else {
              eventName = 'field_was_updated'
            }

            EventsData.pushEvent(eventName, {
              fieldId: response.$response.data.data.id,
              recordId: record.id,
              folderId: record.folder.id
            })

            return response
          },
          function (rejection) {
            return validator.rejection(rejection.$response)
          }
        )
    }

    defer.resolve()
    return defer.promise
  }

  function batchSaveField(records, blueprintField, value, replace) {
    let promises = []
    let valueToSave

    records.forEach(function (record) {
      let field: any = _find(record.fields, { key: blueprintField.key })

      if (!field) {
        field = record.fields.$build(_omit(blueprintField, ['id'])).$reveal()
      }

      if (field.type === 'array') {
        valueToSave = getArrayToSave(record, field, value, replace)
      } else {
        valueToSave = value
      }

      promises.push(saveField(record, field, valueToSave))
    })

    return $q.all(promises)
  }

  function getArrayToSave(record, field, values, replace) {
    if (!replace && field.value) {
      if (!values) {
        return field.value
      }

      return _uniqBy(field.value.concat(values), function (value: any) {
        return _trim(value.text)
      })
    }

    return values
  }

  function deleteField(record, field) {
    let originalField = _clone(field)
    let blueprint = BlueprintsData.getByKey(field.key)

    if (blueprint) {
      return field
        .$deleteAndReplace(blueprint)
        .$asPromise()
        .then(function () {
          field.value = null

          EventsData.pushEvent('field_was_deleted', {
            fieldId: originalField.id,
            recordId: record.id,
            folderId: record.folder.id
          })
        })
    }

    return field.$destroy().$asPromise()
  }
}
