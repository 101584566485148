import { Controller } from './controller'
import view from './view.html'

export const ptNotificationAccountRecordsRetirementOverdue = {
  bindings: {
    onRead: '&',
    notification: '<',
    view: '<'
  },
  controller: Controller,
  template: view
}
