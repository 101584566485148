export function industriesData(IndustryModel) {
  'ngInject'

  let industriesCache = null

  let service = {
    get: get
  }
  return service

  ////////////////

  function get() {
    if (industriesCache) {
      return industriesCache
    }

    return IndustryModel.$collection()
      .$fetch()
      .$asPromise()
      .then(function (industries) {
        industriesCache = industries
        return industriesCache
      })
  }
}
