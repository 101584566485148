import angular from 'angular'

export function ptCancelDragDefaults($window) {
  'ngInject'

  let directive = {
    link: link
  }

  return directive

  function link() {
    angular.element($window).on('dragstart', preventDefault)
    angular.element($window).on('dragover', preventDefault)
    angular.element($window).on('drop', preventDefault)
  }

  function preventDefault(e) {
    if (!angular.element(e.target).hasClass('ui-draggable')) {
      e.preventDefault()
    }
  }
}
