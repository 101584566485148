export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {}

  $ctrl.$onInit = () => {
    $ctrl.newUser = $ctrl.$transition$.params().newUser
    $ctrl.subscriptionId = $ctrl.subscriptions[0].id
  }

  $ctrl.$onChanges = function () {}
}
