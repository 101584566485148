export function AccountExportRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('settings.export', {
    url: '/export',
    views: {
      'content@layoutFull': 'ptAccountExport'
    },
    onEnter: onAccountExport
  })

  function onAccountExport(gettextCatalog, page) {
    'ngInject'

    /// Page title
    page.setTitle(gettextCatalog.getString('Full account export'))
  }
}
