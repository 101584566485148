import { Controller } from './controller'
import view from './view.html'

export function ptRecordsTableRow() {
  'ngInject'

  let directive = {
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    restrict: 'A',
    scope: {
      cols: '<',
      subRows: '<',
      record: '<',
      $selection: '<selection',
      outerControl: '<control'
    }
  }

  return directive
}
