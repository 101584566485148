export function ptImportsCompleteRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('imports.import.accounts.folders.map.complete', {
    url: '/complete?newUser',
    params: {
      newUser: {
        type: 'bool'
      }
    },
    views: {
      'crumbs@imports': {
        component: 'ptImportsCrumbs'
      },
      'main@imports': {
        component: 'ptImportsComplete'
      }
    },
    resolve: {
      crumbs: resolveCrumbs,
      self: resolveSelf
    }
  })

  function resolveCrumbs(gettextCatalog) {
    'ngInject'

    return [
      {
        name: gettextCatalog.getString('Imports'),
        link: 'imports'
      }
    ]
  }

  function resolveSelf(gettextCatalog) {
    'ngInject'

    return gettextCatalog.getString('Import Complete')
  }
}
