import angular from 'angular'
import { ptProfileBasicModule } from './components/pt-profile-basic'
import { ptProfileEmailsModule } from './components/pt-profile-emails'
import { ptProfileEmailsAccountModule } from './components/pt-profile-emails-account'
import { ptProfileInvitationsModule } from './components/pt-profile-invitations'
import { ptProfilePasswordModule } from './components/pt-profile-password'
import { ptProfileProcessesModule } from './components/pt-profile-processes'
import { ptProfileValidateModule } from './components/pt-profile-validate'

import { profileData } from './services/data'
import { ProfileModel } from './services/models'

export const ProfileModule = angular
  .module('profile', [
    ptProfileBasicModule,
    ptProfileEmailsModule,
    ptProfileEmailsAccountModule,
    ptProfileInvitationsModule,
    ptProfilePasswordModule,
    ptProfileProcessesModule,
    ptProfileValidateModule
  ])
  .factory('profileData', profileData)
  .factory('ProfileModel', ProfileModel).name
