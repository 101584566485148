import lazy from 'lazy.js'
import _remove from 'lodash/remove'
import { Record } from 'AppCore/record'

export function Controller() {
  'ngInject'

  let forms = {}

  this.$onInit = () => {
    this.saving = false
    this.records = this.resolve.recordsData
    this.multiple = this.records.length > 1
  }

  this.register = (scope) => {
    forms[scope.record.id] = scope
  }

  this.deregister = (scope) => {
    delete forms[scope.record.id]
  }

  this.save = () => {
    this.saving = true

    lazy(forms).each((form) => {
      form.save()
    })
  }

  this.remove = (reason, recordId) => {
    _remove(this.records, (record: Record) => {
      return record.id === recordId
    })

    if (this.records.length === 0) {
      this.dismiss({ $value: 'no records' })
    }
  }

  this.allValid = () => {
    let valid = true

    lazy(forms).each((form) => {
      if (!form.isValid()) {
        valid = false
      }
    })

    return valid
  }

  this.copyDown = (recordId, fieldKey, value) => {
    let idMatched = false

    lazy(this.records).each((record) => {
      if (idMatched) {
        forms[record.id].setField(fieldKey, value)
      } else if (record.id === recordId) {
        idMatched = true
      }
    })
  }
}
