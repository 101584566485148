export function Controller(ActivitiesRecord) {
  'ngInject'

  let vm = this

  vm.control = {
    openRecord: openRecord
  }

  vm.$onInit = function () {
    vm.changes = ActivitiesRecord.changes(vm.data)
    vm.stateColor = {
      'background-color': 'rgb(' + vm.data.to.state.colour + ')'
    }
  }

  function openRecord() {
    vm.openRecord()
  }
}
