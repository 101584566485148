import { Controller } from './controller'
import view from './view.html'

export function ptFoldersTableRow() {
  let directive = {
    restrict: 'A',
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      folder: '=',
      folderMeta: '=',
      index: '=',
      last: '=',
      nested: '='
    }
  }

  return directive
}
