export function Controller(ivhTreeviewBfs, ivhTreeviewMgr) {
  'ngInject'

  const $ctrl = this

  $ctrl.options = {
    idAttribute: 'id',
    labelAttribute: 'name',
    validate: false,
    twistieCollapsedTpl: `<pt-icon name="'rightDir'" />`,
    twistieExpandedTpl: `<pt-icon name="'downDir'" />`,
    twistieLeafTpl: '<span class="icon-fw icon-none"></span>',
    disableCheckboxSelectionPropagation: true
  }

  $ctrl.$onInit = () => {}

  $ctrl.applyFilter = () => {
    $ctrl.onApplyFilter()
  }

  $ctrl.folderToggle = (ivhNode) => {
    ivhTreeviewBfs(ivhNode, (node) => {
      node.selected = ivhNode.selected
    })
  }

  $ctrl.selectAll = () => {
    ivhTreeviewMgr.selectAll($ctrl.folders)
  }

  $ctrl.selectNone = () => {
    ivhTreeviewMgr.deselectAll($ctrl.folders)
  }
}
