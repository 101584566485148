export function responseErrorInterceptor(EventsData, $q, requestRetry, connection, CONNECTION_TEST_URL) {
  'ngInject'

  const responseError = (rejection) => {
    const defer = $q.defer()
    const accountSearchParam = rejection.config.params && (rejection.config.params.q || rejection.config.params.search)
    const noneSearch504 = rejection.status === 504 && !accountSearchParam

    if (rejection.status <= 0) {
      if (rejection.config.url === CONNECTION_TEST_URL) {
        defer.reject(rejection)
      } else {
        connection.failed(rejection, defer)
      }
    }
    // If a 504 occurs from an account search, we don't want to retry it and potentially cause a mongo overload
    else if (
      (rejection.status === 419 || noneSearch504) &&
      (typeof rejection.config.retries === 'undefined' || rejection.config.retries < 5)
    ) {
      // Just retry the request
      requestRetry.retry(rejection, defer)
    } else {
      EventsData.broadcast('event:http-error', rejection)
      defer.reject(rejection)
    }

    return defer.promise
  }

  return {
    responseError: responseError
  }
}
