export function ExportsData(context, collections) {
  'ngInject'

  const cachedCertificates = []

  const getCertificates = () => {
    const params = {
      limit: 100
    }

    const account = context.get('account')

    if (!cachedCertificates[account.id]) {
      cachedCertificates[account.id] = collections.getAll(account.certificates.$collection(params))
    }

    return cachedCertificates[account.id]
  }

  const getExportCertificate = (data) => {
    return context.get('account').certificates.$build(data).$save().$asPromise()
  }

  const getExportGeneral = (data) => {
    return context.get('account').exports.$build(data).$save().$asPromise()
  }

  const setChecklistInclude = (model) => {
    if (model.advanced.checklists) {
      model.include = model.include + ',checklists'
    }
  }

  const setArchivedParams = (model) => {
    switch (model.advanced.archived) {
      case 'include':
        if (model.include.indexOf('archived') < 0) {
          model.include = model.include + ',archived'
        }

        // Make sure there is no archived_at filter in the filter string
        if (typeof model.filter === 'string') {
          model.filter = model.filter.replace(/archived_at(.*?,|.*\b)/g, '')
        }

        break
      case 'only':
        if (model.include.indexOf('archived') < 0) {
          model.include = model.include + ',archived'
        }

        if (typeof model.filter !== 'string') {
          model.filter = ''
        }

        if (model.filter.indexOf('archived_at') < 0) {
          model.filter = model.filter + 'archived_at<P1M'
        }

        break
      case 'exclude':
        if (model.include.indexOf('archived') >= 0) {
          model.include = model.include.replace(/archived(.*?,|.*\b)/g, '')
        }

        if (model.filter && model.filter.indexOf('archived_at') >= 0) {
          model.filter = model.filter.replace(/archived_at(.*?,|.*\b)/g, '')
        }

        break
      default:
      //
    }

    if (model.filter === '') {
      delete model.filter
    }
  }

  return {
    getCertificates,
    getExportCertificate,
    getExportGeneral,
    setArchivedParams,
    setChecklistInclude
  }
}
