import view from './view.html'

export function copyDown(formlyConfigProvider) {
  'ngInject'

  formlyConfigProvider.setWrapper([
    {
      name: 'copyDown',
      template: view
    }
  ])
}
