export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {}

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function () {}
}
