import { Controller } from './controller'
import view from './view.html'

export const ptChecklistsSectionHeading = {
  bindings: {
    section: '<checklistSection',
    checklistOptions: '<'
  },
  controller: Controller,
  template: view
}
