export function viewFileModal($uibModal) {
  'ngInject'

  let modal

  const openModal = (upload, user, download) => {
    let size = 'md'

    if (upload.is_image || upload.mime === 'application/pdf') {
      size = 'xl'
    }

    modal = $uibModal.open({
      component: 'ptUploadsFileView',
      size: size,
      resolve: {
        upload: () => upload,
        user: () => user,
        download: () => download
      }
    })

    modal.result.catch(function () {})
  }

  let service = {
    openModal: openModal
  }

  return service
}
