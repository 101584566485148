import angular from 'angular'

export function ptActivitiesRoute($stateProvider, moment) {
  'ngInject'

  $stateProvider.state('activities', {
    parent: 'account',
    url: '/activities?user&type&from&to&folder_id&log_state_id',
    params: {
      from: moment().subtract(2, 'months').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD')
    },
    views: {
      'content@layoutFull': 'ptActivities'
    },
    onEnter: onActivitiesEnter
  })

  function onActivitiesEnter(gettextCatalog, page, $state, $transition$) {
    'ngInject'

    var params = angular.copy($transition$.params())
    var moments = {
      to: params.to && moment(params.to),
      from: params.from && moment(params.from)
    }
    var now = moment()
    var hasChanges = false
    var result

    /// Page Title
    page.setTitle(gettextCatalog.getString('Activities'))

    // Validate the dates, and use defaults if that fail validation
    if (moments.to && moments.from && moments.from.isAfter(moments.to)) {
      moments.from = moment(moments.to).subtract(2, 'months')
      hasChanges = true
    }

    if (moments.from && moments.from.isAfter(now)) {
      moments.from = moment().subtract(2, 'months')
      hasChanges = true
    }

    if (moments.to && moments.to.isAfter(now)) {
      moments.to = moment()
      hasChanges = true
    }

    params.from = moments.from.format('YYYY-MM-DD')
    params.to = moments.to.format('YYYY-MM-DD')

    if (hasChanges) {
      result = $state.target($transition$.to(), params, $transition$.options())
    }

    return result
  }
}
