import _cloneDeep from 'lodash/cloneDeep'

export function Controller(richText, BASE_URL, ChecklistsData, tracking) {
  'ngInject'
  this.$onInit = () => {
    this.checklistOptions = {
      canExpand: true,
      isExpanded: false,
      readOnly: true,
      editField: true
    }

    this.requiresUpload = this.requires_upload
    this.richText = richText
    this.showDetails = false
    this.fileUrl = BASE_URL
  }

  this.$onChanges = (changes) => {
    if (changes.record) {
      this.record = _cloneDeep(this.record)

      if (this.record.log.checklist_completions.length) {
        this.fields = ChecklistsData.getChecklistCounts(this.record.log.checklist_completions)
      }
    }

    if (changes.subRows) {
      this.subRows = _cloneDeep(this.subRows)
    }
  }

  this.toggleExtraDetails = (event) => {
    tracking.trackEvent({ action: 'toggleExtraDetails', category: 'recordList' })
    event.stopPropagation()

    this.showDetails = !this.showDetails
  }
}
