import { Controller } from './controller'
import { Link } from './link'
import view from './view.html'

export function ptAccountLogo() {
  let directive = {
    restrict: 'A',
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      logo: '=?',
      account: '=?'
    },
    link: Link
  }

  return directive
}
