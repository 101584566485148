export function GetText($window, gettextCatalog) {
  'ngInject'

  gettextCatalog.setCurrentLanguage(getLanguage())
  gettextCatalog.debug = false
  gettextCatalog.showTranslatedMarkers = false

  function getLanguage() {
    let languages = window.navigator.languages || [window.navigator.language || (<any>window).navigator.userLanguage]
    let language = languages[0] || 'en-GB'

    return language.replace('-', '_')
  }
}
