import * as React from 'react'

import { Text } from '../../components'
import { createStyledComponent, View } from '../../components/styled'
import { spacing, colors } from '../../styles'
import { TextProps } from 'reactxp/src/common/Types'
import { Activity } from '../models'

interface Props extends TextProps {
  children: React.ReactNode
  activity: Activity
  onPressActivity: (activity) => void
}

const Wrapper = createStyledComponent(Text, {
  fontWeight: '500',
  marginBottom: spacing.half,
  overflow: 'visible'
})

const BulletPoint = createStyledComponent(View, {
  backgroundColor: colors.neutralTintFive,
  width: 8,
  height: 8,
  position: 'absolute',
  top: 5,
  left: -35,
  borderRadius: 5
})

export const ActivityTitle = ({ children, onPressActivity, activity }: Props) => {
  return (
    <Wrapper onPress={() => onPressActivity(activity)}>
      <BulletPoint />
      {children}
    </Wrapper>
  )
}
