export function Controller($interval, connection) {
  'ngInject'

  let vm = this
  let backoffFactor = 1.5
  let initialBackoff = 1
  let lastBackoff = 0
  let maxBackoff = 30

  vm.control = {
    retry: retry
  }

  vm.seconds = null
  vm.retrying = false

  vm.$onInit = function () {
    vm.seconds = initialBackoff
    initiate()
  }

  function retry(manual?) {
    if (manual) {
      vm.retrying = true
    }

    connection.test().catch(function () {
      vm.retrying = false

      if (manual) {
        vm.seconds = lastBackoff
      } else {
        vm.seconds = Math.round(Math.min(lastBackoff * backoffFactor, maxBackoff))
        lastBackoff = vm.seconds
      }

      startTimer()
    })
  }

  function initiate() {
    vm.seconds = initialBackoff
    lastBackoff = vm.seconds

    startTimer()
  }

  function startTimer() {
    $interval.cancel(vm.timer)

    vm.timer = $interval(function () {
      vm.seconds = vm.seconds - 1

      if (vm.seconds < 1) {
        $interval.cancel(vm.timer)
        retry()
      }
    }, 1000)
  }
}
