import view from './view.html'
import { Controller } from './controller'

/**
 * Readonly view of a imgfile or genfile field
 */
export const ptChecklistItemFiles = {
  bindings: {
    record: '<',
    onValueChange: '&',
    field: '<',
    readOnly: '<'
  },
  controller: Controller,
  template: view
}
