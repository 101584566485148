import angular from 'angular'

import _assign from 'lodash/assign'
import _find from 'lodash/find'
import _sortBy from 'lodash/sortBy'
import _isEqual from 'lodash/isEqual'

import { chartOptions } from './chart-options'

import './help-popover.html'

export function Controller(context, ReportsData, $uibModal, moment, foldersTree, ivhTreeviewBfs) {
  'ngInject'

  const $ctrl = this

  const filterParams = {
    interval: 'P1W',
    segments: 4,
    folders: String(context.get('account').folder.id),
    include: 'descendants'
  }

  let exportModal
  let foldersFlattened = []
  let previousFolderFilter = [context.get('account').folder.id]

  $ctrl.$onInit = () => {
    $ctrl.account = context.get('account')

    $ctrl.chartOptions = chartOptions

    $ctrl.filterPanel = {
      isOpen: false
    }

    $ctrl.presets = [
      {
        tag: 'days7',
        name: '7 Days'
      },
      {
        tag: 'weeks4',
        name: '4 Weeks'
      },
      {
        tag: 'months6',
        name: '6 Months'
      }
    ]

    $ctrl.helpPopover = 'components/pt-compliance-widget/help-popover.html'
    $ctrl.activePreset = 'weeks4'
    $ctrl.percentageColumnData = []
    $ctrl.chartData = []
    $ctrl.xAxisColLabels = []
    $ctrl.seriesData = ['Compliance']
    $ctrl.loading = false

    $ctrl.chartDatasetOverride = [
      {
        label: 'Compliance',
        borderWidth: 2,
        backgroundColor: 'rgba(24,166,120,0.6)'
      },
      {
        label: 'Due',
        borderWidth: 2,
        hidden: true
      },
      {
        label: 'Inspected',
        borderWidth: 2,
        hidden: true
      }
    ]

    getFolders()
    getComplianceChecks()
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.widget) {
      $ctrl.widget = angular.copy($ctrl.widget)
    }
  }

  $ctrl.applyFilter = () => {
    const folderIds = []

    ivhTreeviewBfs($ctrl.folders, (folder) => {
      // Search the flattened array of folders to see if this folder has a parent
      let parentFolder = _find(foldersFlattened, { id: folder.parentId })

      /**
       * Below, we check if the folder is selected and its parent is not before adding to the filter
       * If the parent is selected, then the descendants are included by default.
       * If this folder is selected and its parent (root folder) is not defined, we know this is a top level folder.
       */
      if (folder.selected && ((parentFolder && !parentFolder.selected) || !parentFolder)) {
        folderIds.push(folder.id)
      }
    })

    // No folders selected is the same as selecting the root folder
    if (!folderIds.length) {
      folderIds.push($ctrl.account.folder.id)
    }

    if (!_isEqual(_sortBy(folderIds), _sortBy(previousFolderFilter))) {
      previousFolderFilter = folderIds
      $ctrl.filterPanel.isOpen = false

      getComplianceChecks(_assign(filterParams, { folders: folderIds.join(',') }))
    }
  }

  $ctrl.openExportModal = () => {
    exportModal = $uibModal.open({
      component: 'ptComplianceWidgetExport',
      resolve: {
        params: () => {
          return filterParams
        },
        report: () => 'compliance'
      }
    })

    exportModal.result.catch(() => {
      // user dismissed
    })
  }

  $ctrl.setPreset = (preset) => {
    switch (preset) {
      case 'days7':
        filterParams.interval = 'P1D'
        filterParams.segments = 7
        $ctrl.activePreset = 'days7'
        break
      case 'weeks4':
        filterParams.interval = 'P1W'
        filterParams.segments = 4
        $ctrl.activePreset = 'weeks4'
        break
      case 'months6':
        filterParams.interval = 'P1M'
        filterParams.segments = 6
        $ctrl.activePreset = 'months6'
        break
      default:
        filterParams.interval = 'P1W'
        filterParams.segments = 4
        $ctrl.activePreset = 'weeks4'
    }

    getComplianceChecks()
  }

  $ctrl.toggleFilterPanel = () => {
    $ctrl.filterPanel.isOpen = !$ctrl.filterPanel.isOpen
  }

  const buildDataSet = (data) => {
    const xAxisColLabels = []
    const percentageColumnData = []
    const dueColumnData = []
    const inspectedColumnData = []

    data.columns.forEach((set) => {
      // If displaying a daily graph, use one date. Otherwise display a date range.
      let dateString = () => {
        if (parseDate(set.header.start) === parseDate(set.header.end)) {
          return parseDate(set.header.start)
        }

        return `${parseDate(set.header.start)} - ${parseDate(set.header.end)}`
      }

      xAxisColLabels.push(dateString())

      percentageColumnData.push(set.total.percentage)
      dueColumnData.push(set.total.calculated)
      inspectedColumnData.push(set.total.actual)
    })

    $ctrl.xAxisColLabels = xAxisColLabels
    $ctrl.percentageColumnData = percentageColumnData
    $ctrl.dueColumnData = dueColumnData
    $ctrl.inspectedColumnData = inspectedColumnData

    updateChartData()
  }

  const getComplianceChecks = (params = filterParams) => {
    $ctrl.loading = true

    ReportsData.getReportData($ctrl.account.id, 'compliance', params)
      .then(buildDataSet)
      .catch(() => {})
      .finally(() => {
        $ctrl.loading = false
      })
  }

  const getFolders = (refresh?) => {
    foldersTree
      .getTree($ctrl.account, refresh)
      .then((folders) => {
        $ctrl.folders = folders
        foldersFlattened = []

        // Create a flattened array of all folders (to use in the filtering)
        ivhTreeviewBfs($ctrl.folders, (folder) => {
          foldersFlattened.push(folder)
        })
      })
      .catch(() => {})
  }

  const parseDate = (date) => {
    return moment(date).format('D MMM YY')
  }

  const updateChartData = () => {
    $ctrl.chartData = [$ctrl.percentageColumnData, $ctrl.dueColumnData, $ctrl.inspectedColumnData]
  }
}
