export function ptInvitationsPublicOAuthRedirectRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('invitations-oauth-redirect', {
    parent: 'layoutFull',
    url: '/invitations/oauth-redirect?code&state',
    params: {
      skipAuth: null
    },
    views: {
      content: {
        component: 'ptInvitationsPublicOAuthRedirect'
      }
    },
    onEnter: onInvitationsEnter,
    resolve: {
      ssoProviders: resolveSSOProviders
    }
  })

  function resolveSSOProviders(SSOProvidersData) {
    'ngInject'

    return SSOProvidersData.getAllForAuthenticate()
  }

  function onInvitationsEnter(gettextCatalog, page) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Your Invitations'))
  }
}


// http://localhost:9000/invitations/oauth-redirect
// code=xAr86ybUaHj2oND0ZVY
// state=1tn2da
// session_state=ab2ae44a-f4fe-4737-8434-afeb60e01128#
