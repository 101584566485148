import angular from 'angular'

export function Controller($state, $stateParams) {
  'ngInject'

  const checkCustomFieldsFilter = () => {
    this.customFields.forEach((field) => {
      switch (field.type) {
        case 'bool':
          if ($stateParams[field.key]) {
            this.customFilters[field.key] = $stateParams[field.key]
          }
          break
        case 'string':
          if ($stateParams[field.key]) {
            this.customFilters[field.key] = $stateParams[field.key]
          }
          break
        default:
        //
      }
    })
  }

  const checkUsedFilter = () => {
    if ($stateParams.used) {
      this.usedFilter = true
    }
  }

  const checkTemplatesFilter = () => {
    if ($stateParams.template_id) {
      this.templateFilter = $stateParams.template_id.split(',').length
    }
  }

  const checkAuthorFilter = () => {
    if ($stateParams.author) {
      this.author = $stateParams.author
    }
  }

  const checkScoreFilter = () => {
    if ($stateParams.score) {
      this.scoreFilter = $stateParams.score
        .replace('>=', 'More than or equal to ')
        .replace('<=', 'Less than or equal to ')
        .replace('=', 'Equal to ')
        .replace('>', 'Above ')
        .replace('<', 'Below ')
    }
  }

  this.$onInit = () => {
    this.stateParams = $stateParams
    this.author = null
    this.destination = null
    this.usedFilter = null
    this.templateFilter = null
    this.scoreFilter = null
    this.teamSourcedFilter = null
    this.customFilters = {}

    checkAuthorFilter()
    checkUsedFilter()
    checkTemplatesFilter()
    checkScoreFilter()
  }

  this.$onChanges = (changes) => {
    if (changes.customFields) {
      this.customFields = angular.copy(this.customFields)

      checkCustomFieldsFilter()
    }
  }

  this.resetAllFilters = () => {
    let filters = {
      from: null,
      to: null,
      template_id: null,
      used: 'false',
      author: null,
      score: null
    }

    this.customFields.forEach((field) => {
      filters[field.key] = null
    })

    $state.go($state.current.name, filters)
  }

  this.toggleFilterPanel = () => {
    this.onTogglePanel({ panel: 'filter' })
  }

  this.toggleSortPanel = () => {
    this.onTogglePanel({ panel: 'sort' })
  }
}
