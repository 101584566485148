export function Controller() {
  'ngInject'

  let vm = this

  vm.$onInit = function () {
    vm.stateStyle = {
      color: 'rgb(' + vm.record.state.colour + ')'
    }
  }

  vm.$onChanges = function (changes) {
    if (changes.record) {
      vm.stateStyle = {
        color: 'rgb(' + vm.record.state.colour + ')'
      }
    }
  }
}
