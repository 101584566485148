import { Controller } from './controller'
import view from './view.html'

export const ptRecordsBatchButton = {
  bindings: {
    selectionCount: '@',
    records: '<'
  },
  controller: Controller,
  template: view
}
