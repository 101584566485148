import { Controller } from './controller'
import view from './view.html'

export function ptBreadcrumbs() {
  'ngInject'

  let directive = {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    link: link,
    restrict: 'E',
    scope: {
      crumbs: '=?',
      folders: '=?',
      folder: '=?',
      folderMeta: '=?',
      self: '=?',
      bucket: '=?'
    }
  }

  return directive

  function link(scope, element, attrs) {
    scope.vm.dashboard = 'dashboard' in attrs
  }
}
