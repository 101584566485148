import { Controller } from './controller'
import view from './view.html'

export function ptActivitiesFilterPanel() {
  var directive = {
    restrict: 'E',
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      toggleFiltersPanel: '&'
    }
  }

  return directive
}
