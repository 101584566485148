export function RecordsBatchActionRoute($stateProvider) {
  'ngInject'

  let batchActionRecordModal

  $stateProvider.state('bucket.action-records', editRecordsStates())
  $stateProvider.state('folders.action-records', editRecordsStates())
  $stateProvider.state('folder.action-records', editRecordsStates())
  $stateProvider.state('folder.scan.action-records', editRecordsStates())
  $stateProvider.state('folders.scan.action-records', editRecordsStates())
  $stateProvider.state('account.scan.action-records', editRecordsStates())

  function editRecordsStates() {
    return {
      url: '/records/action/{action}?{recordIds}&{report}&{reportId}&{cert}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        resolvedRecords: resolveEditRecords
      },
      onEnter: onBatchActionRecordEnter,
      onExit: onBatchActionRecordExit
    }
  }

  function onBatchActionRecordEnter($uibModal, $state, resolvedRecords, context, $stateParams) {
    'ngInject'
    const member = context.get('member')
    const userId = member.user.$scope.$scope.id
    const noFollowUp = localStorage.getItem(`no-followup-inspections-for-${userId}`)

    if (!noFollowUp) {
      if ($stateParams.action === 'export') {
        batchActionRecordModal = $uibModal.open({
          backdrop: 'static',
          component: 'ptRecordsBatchAction',
          resolve: {
            records: () => resolvedRecords
          }
        })
      } else if ($stateParams.action !== 'export') {
        batchActionRecordModal = $uibModal.open({
          backdrop: 'static',
          component: 'ptRecordsBatchAction',
          resolve: {
            records: () => resolvedRecords
          }
        })
      }

      batchActionRecordModal.result.catch(() => {
        if ($state.$current.name.indexOf('.action-records') >= 0) {
          $state.go('^')
        }
      })
    }
  }

  function onBatchActionRecordExit() {
    'ngInject'
  }

  function resolveEditRecords(account, $stateParams, recordsResolves, context, $q, $state) {
    'ngInject'

    const member = context.get('member')
    const userId = member.user.$scope.$scope.id
    const noFollowUp = localStorage.getItem(`no-followup-inspections-for-${userId}`)

    if (noFollowUp) {
      return $q.reject('No Follow Up');
    }

    const selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves.records(selection, 'log,state,fields,folder,archived').catch(() => {
      //if no records found (not really possible as we are including archived)
      $state.go('^')
    })
  }
}
