import angular from 'angular'
import { react2angular } from 'react2angular'
import { TaskRoute } from './route'
import { ptTask } from './component'

import { TaskAssignees, TaskNoteComponent, TaskActivities } from 'AppCore/task/components'

export const Task = angular
  .module('tasks.pt-task', [])
  .config(TaskRoute)
  .component('ptTask', ptTask)
  .component(
    'ptTaskAssignees',
    react2angular(TaskAssignees, ['users', 'iconSize', 'onPressAssignees', 'onUserUpdated', 'editable'])
  )
  .component(
    'ptTaskNote',
    react2angular(TaskNoteComponent, ['task', 'onChangeNote', 'onChangeFiles', 'isAdmin', 'editable'])
  )
  .component('ptTaskActivities', react2angular(TaskActivities, ['task', 'onPressActivity', 'onPressUpload', 'taskStates'])).name
