import * as React from 'react'
import ReactMarkdown from 'react-markdown'

export interface DiscardCriteriaContentProps {
  source: string
}
export interface DiscardCriteriaContentState {}

class DiscardCriteriaContent extends React.Component<DiscardCriteriaContentProps, DiscardCriteriaContentState> {
  render() {
    return <ReactMarkdown source={this.props.source} />
  }
}

export default DiscardCriteriaContent
