import * as React from 'react'
import { createStyledText } from '../../components/styled'
import { fontSizes } from '../../styles'

interface Props {
  children: React.ReactNode
}

const TaskInfoSlotTitle = createStyledText({
  fontSize: fontSizes.small,
  marginTop: 0,
  marginBottom: 5
})

export const TaskSectionTitle = ({ children }: Props) => {
  return <TaskInfoSlotTitle>{children}</TaskInfoSlotTitle>
}
