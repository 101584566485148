export function ptFoldersEditRoute($stateProvider) {
  'ngInject'

  let editFolderModal

  $stateProvider.state('edit-folder', {
    parent: 'folder',
    url: '/edit',
    views: {
      'utility@layoutBase': {
        template: ' '
      }
    },
    resolve: {
      resolvedFolder: resolveFolder,
      permission: editFolderPermission
    },
    onEnter: onEditFolderEnter,
    onExit: onEditFolderExit
  })

  function resolveFolder(
    account,
    filters,
    foldersResolves,
    ErrorsDisplay,
    $stateParams,
    filteringContext,
    recordsViewMode,
    recordsPagination
  ) {
    'ngInject'

    // We require account and filters DI to ensure they are resolved before this route
    filteringContext.setInitialFilter($stateParams)
    recordsViewMode.setInitialParams($stateParams)
    recordsPagination.setInitialParams($stateParams)

    return foldersResolves.folder(account, $stateParams.folderId || account.folder.id).catch(function (rejection) {
      return ErrorsDisplay.catch(rejection.$response.data.error)
    })
  }

  function editFolderPermission(account, member, resolvedFolder, permissionsCheck, ErrorsResolve) {
    'ngInject'

    return permissionsCheck.canPromise('edit', 'folders', account, resolvedFolder).catch(ErrorsResolve.caught)
  }

  function onEditFolderEnter($uibModal, $state, resolvedFolder, page) {
    'ngInject'
    page.setTitle('Edit Folder')
    editFolderModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptFoldersEdit',
      resolve: {
        folder: () => resolvedFolder
      }
    })

    editFolderModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onEditFolderExit(page) {
    'ngInject'
    page.resetTitle()
    editFolderModal.close()
  }
}
