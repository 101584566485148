import { Controller } from './controller'
import view from './view.html'

export function ptRecordUploads() {
  return {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'A',
    scope: {
      record: '<',
      uploading: '<'
    }
  }
}
