import angular from 'angular'

export function Controller($state, TasksResolver) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    changedSentance,
    openActivity,
    singleKey,
    singleValue,
    parseKey
  }

  $ctrl.keys = Object.keys($ctrl.data.to)

  $ctrl.isProfileTasks = $state.current.name.indexOf('tasks') === 0

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.data) {
      $ctrl.data = angular.copy($ctrl.data)
    }
  }

  function singleKey() {
    return Object.keys($ctrl.data.to)[0]
  }

  function singleValue() {
    return $ctrl.data.to[singleKey()]
  }

  function changedSentance() {
    let sentance = ''

    if ($ctrl.keys.length === 1) {
      sentance = $ctrl.keys[0]
    } else if ($ctrl.keys.length === 2) {
      sentance = `${$ctrl.keys[0]} and ${$ctrl.keys[1]}`
    } else if ($ctrl.keys.length > 2) {
      for (let i = 1; i <= $ctrl.keys.length; i = i + 1) {
        if (i === 1) {
          sentance = `${parseKey($ctrl.keys[i - 1])}`
        } else if (i === $ctrl.keys.length) {
          sentance = `${sentance} and ${parseKey($ctrl.keys[i - 1])}`
        } else {
          sentance = `${sentance}, ${parseKey($ctrl.keys[i - 1])}`
        }
      }
    }

    return sentance
  }

  function parseKey(key) {
    let returnKey = key

    switch (key) {
      case 'deadline_at':
        returnKey = 'Deadline'
        break
      case 'body':
        returnKey = 'Description'
        break
      case 'title':
        returnKey = 'Title'
        break
      default:
      //
    }

    return returnKey
  }

  function openActivity() {
    $state.go($state.current.name + '.activity', { activityId: $ctrl.data.id })
  }

  this.goToTask = () => {
    TasksResolver.resolve({ taskId: this.data.task.id })
  }
}
