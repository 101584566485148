export function Controller(
  $scope,
  gettextCatalog,
  pagination,
  ActivitiesData,
  ActivitiesDeactivated,
  context,
  fileHandler,
  ptApi,
  ErrorsModal,
  $uibModal
) {
  'ngInject'

  const params: any = {
    limit: 25,
    include:
      'checklist_completions,checklist_completions.sections.fields.uploads,partial_completions,partial_completions.sections.fields.uploads,partial_completions.sections.fields.response_uploads'
  }

  this.$onInit = () => {
    this.loading = false
    this.activities = []
    this.totalActivities = false
    this.canFetchMore = false
    this.displayedActivities = []
    this.hasFilters = false
    this.self = gettextCatalog.getString('Activities')
    this.showFilterPanel = false
    this.pendingItems = []
    this.downloadPreparing = false

    setupParams()
    this.setupActivities()

    $scope.$on('activity_was_created', (event, data) => {
      this.pendingItems.push(data.data.id)
    })
  }

  this.getNext = () => {
    this.loading = true

    ActivitiesData.getNext(this.activities).then(success)
  }

  this.setupActivities = () => {
    let includeEventGroups = ['record', 'record_note', 'record_log', 'folder', 'task', 'task_note', 'service']
    let ignoreEvents = ['record_moved_from_folder']

    this.activities = ActivitiesData.init(context.get('account').id, includeEventGroups, ignoreEvents, params)

    this.getNext()
  }

  this.toggleFiltersPanel = () => {
    this.showFilterPanel = !this.showFilterPanel
  }

  this.openExportModal = () => {
    if (this.totalActivities > 1000) {
      ErrorsModal.openModal('activities_pdf_count')
      return
    }

    this.exportModal = $uibModal.open({
      component: 'ptActivitiesExport',
      resolve: {
        params: () => {
          return params
        }
      }
    })

    this.exportModal.result.catch(() => {
      // user dismissed
    })
  }

  const setupParams = () => {
    if (this.$transition$.params().user) {
      params.user_id = this.$transition$.params().user
      this.hasFilters = true
    }

    if (this.$transition$.params().log_state_id) {
      params.log_state_id = this.$transition$.params().log_state_id
      this.hasFilters = true
    }

    if (this.$transition$.params().type) {
      params.type = this.$transition$.params().type
      this.hasFilters = true
    }

    if (this.$transition$.params().from || this.$transition$.params().to) {
      params.filter =
        'created_at=' +
        (this.$transition$.params().from || this.$transition$.params().to) +
        '/' +
        (this.$transition$.params().to || this.$transition$.params().from)
      this.hasFilters = true
    }

    if (this.$transition$.params().folder_id) {
      params.folder_id = this.$transition$.params().folder_id
      this.hasFilters = true
    }
  }

  const success = (results) => {
    this.totalActivities = pagination.totalResults(results)
    this.canFetchMore = pagination.hasMorePages(results)
    this.displayedActivities = results

    flagDeactivated()
    flagHighlighted()

    this.pendingItems = []

    this.loading = false
  }

  const flagDeactivated = () => {
    this.displayedActivities = ActivitiesDeactivated.flag(this.displayedActivities)
  }

  const flagHighlighted = () => {
    this.displayedActivities.forEach((activity) => {
      if (this.pendingItems.indexOf(activity.id) >= 0) {
        activity.highlight = true
      }
    })
  }
}
