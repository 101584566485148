import _some from 'lodash/some'
import _remove from 'lodash/remove'
import _forEach from 'lodash/forEach'

export function uploadsData($q, BASE_URL, API_VERSION, session, context, Upload, EventsData, IntegrationsData) {
  'ngInject'

  let service = {
    batchSaveUploads: batchSaveUploads,
    fetch: fetch,
    getUploadType: getUploadType,
    remove: remove,
    removeFailedUploads: removeFailedUploads,
    save: save,
    saveCoverImageState: saveCoverImageState,
    saveSingle: saveSingle,
    upload: uploadValidate,
    uploadTypeCheck: uploadTypeCheck
  }

  return service

  ////////////////

  // As we add further integrations, checks can live here
  function uploadTypeCheck() {
    let defer = $q.defer()

    // If iAuditor uploads are available, show upload modal
    IntegrationsData.init()
      .then(function (data) {
        defer.resolve(_some(data, { provider: 'iauditor' }))
      })
      .catch(function () {
        defer.reject()
      })

    return defer.promise
  }

  function removeFailedUploads(files: any[]) {
    _remove(files, function (file) {
      return file.error
    })
  }

  function uploadValidate(file: any, accountId: string) {
    let defer = $q.defer()

    if (file.uploading) {
      defer.reject('inProgress')
      return defer.promise
    } else if (file.value) {
      defer.resolve(file.value)
      return defer.promise
    }

    file.uploading = true

    return uploadFile(file, accountId).then(
      function (response) {
        return {
          fileId: uploadFileSuccess(response, file)
        }
      },
      function (rejection) {
        return uploadError(rejection, file)
      },
      function (evt) {
        return uploadProgress(evt, file)
      }
    )
  }

  function fetch(upload) {
    let account = context.get('account')

    return account.uploads.$find(upload.id).$asPromise()
  }

  function uploadFile(file, accountId) {
    let url = BASE_URL + '/accounts/' + accountId + '/uploads'

    return Upload.upload({
      url: url,
      file: file,
      headers: {
        Accept: API_VERSION
      }
    })
  }

  function uploadFileSuccess(response, file) {
    file.uploading = false
    return response.data.data.id
  }

  function uploadError(rejection, file) {
    file.uploading = false
    file.error = true
  }

  function uploadProgress(evt, file) {
    // Math.min is to fix IE which reports 200% sometimes
    file.progress = Math.min(100, parseInt(String((100.0 * evt.loaded) / evt.total), 10))
  }

  function saveUploads(records, uploads) {
    return new Promise((resolve, reject) => {
      let promises = []

      // Save the first upload
      _forEach(records, function (record) {
        promises.push(saveSingle(record, uploads[0].value, uploads[0].type))
      })

      // Skip the first upload for batch processing after a timeout
      setTimeout(function () {
        for (let i = 1; i < uploads.length; i++) {
          const upload = uploads[i]
          _forEach(records, function (record) {
            promises.push(saveSingle(record, upload.value, upload.type))
          })
        }

        // Resolve the promise when all saves are complete
        Promise.all(promises)
          .then(() => {
            resolve('All uploads saved successfully')
          })
          .catch((error) => {
            reject(error) // Forwarding any errors from saveSingle function
          })
      }, 5000)
    })
  }

  function batchSaveUploads(records, uploads) {
    return new Promise((resolve, reject) => {
      saveUploads(records, uploads)
        .then(() => {
          resolve('Uploads saved successfully')
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  function save(parent, fileId) {
    return parent.uploads
      .$build({ files: [{ type: 'upload_id', value: fileId }] })
      .$save()
      .$asPromise()
  }

  function saveSingle(parent, fileId, type) {
    return parent.uploads
      .$build({ type: type || 'upload_id', value: fileId })
      .$save()
      .$asPromise()
  }

  function saveCoverImageState(upload) {
    return upload.$save(['default']).$asPromise()
  }

  function remove(upload) {
    upload.active = false
    return upload.$save(['active']).$asPromise()
  }

  function getUploadType(upload) {
    let type

    if (upload && upload.is_image) {
      type = 'image'
    } else if (upload && upload.mime === 'application/pdf' && upload.source === 'file') {
      type = 'pdf'
    } else if (upload && upload.mime === 'application/pdf' && upload.source === 'iauditor') {
      type = 'iauditor'
    }

    return type
  }
}
