import moment from 'moment-timezone'
import _cloneDeep from 'lodash/cloneDeep'

export function Controller($state, TasksResolver) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    openActivity
  }

  $ctrl.$onInit = function () {
    $ctrl.isProfileTasks = $state.current.name.indexOf('tasks') === 0
    $ctrl.inspectionText = `${$ctrl.data.to.state.name} ${moment($ctrl.data.to.createdAt).format('Do MMM YYYY H:mm a')}`
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.data) {
      $ctrl.data = _cloneDeep($ctrl.data)
    }
  }

  function openActivity() {
    $state.go($state.current.name + '.activity', { activityId: $ctrl.data.id })
  }

  this.goToTask = () => {
    TasksResolver.resolve({ taskId: this.data.task.id })
  }
}
