import _extend from 'lodash/extend'
import { fetchInvitations } from 'AppCore/invitation/api'

export function invitationsForUser($q, EventsData, ptApi) {
  'ngInject'

  let service = {
    getAll: getAll,
    getUpdated: getUpdated,
    remove: remove,
    accept: accept
  }

  return service

  function getAll(extraParams = {}) {
    const defer = $q.defer()
    const query = _extend({ include: 'account,subscription,user' }, extraParams)

    fetchInvitations({ query })
      .then((response) => {
        return defer.resolve(response)
      })
      .catch((rejection) => {
        return defer.reject(rejection.response.data)
      })

    return defer.promise
  }

  function getUpdated(invitation) {
    return invitation.$fetch({ include: 'account' }).$asPromise()
  }

  function remove(invitation) {
    invitation.$scope.$remove(invitation)
  }

  function accept(invitation) {
    let endpoint = '/members'

    if (invitation.type === 'owner') {
      endpoint = '/owners'
    }

    return ptApi
      .request({
        method: 'POST',
        url: endpoint,
        data: {
          invitation_id: invitation.id
        }
      })
      .then(function () {
        EventsData.pushEvent('invitation_was_updated', {
          invitationId: invitation.id
        })
      })
  }
}
