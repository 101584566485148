import * as Sentry from '@sentry/browser'
import _isNull from 'lodash/isNull'
import angular from 'angular'

export function Controller(
  context,
  ErrorsModal,
  invitationsForAccount,
  gettextCatalog,
  SubscriptionsData,
  TutorialsFlags,
  TasksData
) {
  'ngInject'
  const account = context.get('account')
  const showTasks = TasksData.flag(account) === 'active'

  let vm = this
  let s = {
    /// Field label
    emailAddress: gettextCatalog.getString('Email address to send invite to'),
    /// Field label
    adminMember: gettextCatalog.getString('Admin member'),
    /// Field help message
    adminHelp: gettextCatalog.getString('Admin members can access the account settings and manage other members.'),
    viewOnlyMember: gettextCatalog.getString('View only member'),
    viewOnlyHelp: gettextCatalog.getString(
      'View only members can view folders, records and tasks but cannot make any changes.\n If you would like to add view-only members to your account, please ensure that your subscription plan includes this feature.'
    ),
    /// Field label
    permissions: gettextCatalog.getString('Permissions'),
    /// Message
    adminPermissions: gettextCatalog.getString('Admin members automatically receive all permissions'),
    viewOnlyPermissions: gettextCatalog.getString('View only members automatically receive view permissions'),
    /// Field label
    folderPermissions: gettextCatalog.getString('Folder permissions'),
    /// Field help message
    folderPermissionsHelp: gettextCatalog.getString('Restrict what this member can do with folders.'),
    /// Field label
    recordPermissions: gettextCatalog.getString('Record permissions'),
    /// Field help message
    recordPermissionsHelp: gettextCatalog.getString('Restrict what this member can do with records.'),
    /// Field label
    inspectionPermissions: gettextCatalog.getString('Inspection permissions'),
    /// Field help message
    inspectionPermissionsHelp: gettextCatalog.getString('Restrict what this member can do with inspections.'),
    /// Select box option
    folderView: gettextCatalog.getString('Can only view folders'),
    /// Select box option
    folderAdd: gettextCatalog.getString('Can add new folders'),
    /// Select box option
    folderEdit: gettextCatalog.getString('Can edit folders'),
    /// Select box option
    recordView: gettextCatalog.getString('Can only view records'),
    /// Select box option
    recordAdd: gettextCatalog.getString('Can add new records'),
    /// Select box option
    recordEdit: gettextCatalog.getString('Can edit records'),
    /// Select box option
    inspectionView: gettextCatalog.getString('Can only view inspections'),
    /// Select box option
    inspectionAdd: gettextCatalog.getString('Can add new inspections'),
    /// Select box option
    inspectionEdit: gettextCatalog.getString('Can edit inspections'),
    /// Field label
    taskPermissions: gettextCatalog.getString('Task permissions'),
    /// Field help message
    taskPermissionsHelp: gettextCatalog.getString('Restrict what this member can do with tasks.'),
    /// Select box option
    taskAdd: gettextCatalog.getString('Can add tasks'),
    /// Select box option
    taskEdit: gettextCatalog.getString('Can edit tasks'),
    /// Select box option
    taskView: gettextCatalog.getString('Can only view tasks')
  }

  vm.control = {
    closeModal: closeModal,
    save: save,
    toggleFolderPickerReady,
    changeHandler
  }

  vm.tutorial = null

  vm.folderPickerReady = false
  vm.saving = false
  vm.memberError = null
  vm.model = {
    email: '',
    folders: [],
    admin: false,
    view_only: false,
    permissions: {
      folders: 'view',
      records: 'view',
      logs: 'view',
      tasks: 'view'
    }
  }

  vm.formFields = [
    {
      key: 'email',
      type: 'input',
      ngModelAttrs: {
        ptFocus: {
          attribute: 'pt-focus'
        }
      },
      templateOptions: {
        label: s.emailAddress,
        type: 'email',
        required: true,
        ptFocus: true
      }
    },
    {
      key: 'admin',
      type: 'checkbox',
      templateOptions: {
        label: s.adminMember,
        help: s.adminHelp
      },
      hideExpression: function () {
        return vm.model.view_only
      }
    },
    {
      key: 'view_only',
      type: 'checkbox',
      id: 'view-only-checkbox',
      templateOptions: {
        label: s.viewOnlyMember,
        help: s.viewOnlyHelp
      },
      hideExpression: function () {
        return vm.model.admin
      },
    },
    {
      template: '<label class="control-label">' + s.permissions + '</label><p>' + s.adminPermissions + '</p>',
      hideExpression: function () {
        return !vm.model.admin
      }
    },
    {
      template: '<label class="control-label">' + s.permissions + '</label><p>' + s.viewOnlyPermissions + '</p>',
      hideExpression: function () {
        return !vm.model.view_only
      }
    },
    {
      key: 'permissions',
      hideExpression: function () {
        return vm.model.admin || vm.model.view_only
      },
      fieldGroup: [
        {
          key: 'folders',
          type: 'select',
          templateOptions: {
            label: s.folderPermissions,
            help: s.folderPermissionsHelp,
            options: [
              { name: s.folderView, id: 'view' },
              { name: s.folderAdd, id: 'add' },
              { name: s.folderEdit, id: 'edit' }
            ],
            ngOptions: 'level.id as level.name for level in to.options'
          }
        },
        {
          key: 'records',
          type: 'select',
          templateOptions: {
            label: s.recordPermissions,
            help: s.recordPermissionsHelp,
            options: [
              { name: s.recordView, id: 'view' },
              { name: s.recordAdd, id: 'add' },
              { name: s.recordEdit, id: 'edit' }
            ],
            ngOptions: 'level.id as level.name for level in to.options'
          }
        },
        {
          key: 'logs',
          type: 'select',
          templateOptions: {
            label: s.inspectionPermissions,
            help: s.inspectionPermissionsHelp,
            options: [
              { name: s.inspectionView, id: 'view' },
              { name: s.inspectionAdd, id: 'add' },
              { name: s.inspectionEdit, id: 'edit' }
            ],
            ngOptions: 'level.id as level.name for level in to.options'
          }
        },
        {
          key: 'tasks',
          type: 'select',
          hideExpression: () => !showTasks,
          templateOptions: {
            label: s.taskPermissions,
            help: s.taskPermissionsHelp,
            options: [
              { name: s.taskView, id: 'view' },
              { name: s.taskAdd, id: 'add' },
              { name: s.taskEdit, id: 'edit' }
            ],
            ngOptions: 'level.id as level.name for level in to.options'
          }
        }
      ]
    }
  ]

  vm.error = null
  vm.validationErrors = null

  //////////

  vm.$onInit = function () {
    vm.isAdmin = context.get('member').admin

    SubscriptionsData.fetchSubscription(context.get('account').subscription.id, { include: 'stats' }).then(function (
      subscription
    ) {
      vm.subscription = subscription
      vm.memberUsedPercent = SubscriptionsData.calculateUsedPercent(
        vm.subscription.stats.members,
        vm.subscription.members
      )

      const checkboxElement = angular.element(document.querySelector('#view-only-checkbox'));

      // If the subscription has no view only members, then disable the checkbox
      const disableViewOnly: boolean = vm.subscription.view_only_members === 0

      checkboxElement.attr('disabled', disableViewOnly ? 'true' : null) // set disabled to true
    })

    TutorialsFlags.getUserFlag('created_account_invite')
      .then(function (flag) {
        vm.tutorial = flag
      })
      .catch(() => {})
  }

  function save() {
    if (vm.form.$valid) {
      vm.saving = true
      vm.error = null
      vm.validationErrors = null

      // If the member is admin, dont send any folders as the API assumes all folders for an admin
      if (vm.model.admin) {
        vm.model.folders = []
      }

      invitationsForAccount.create(vm.model).then(saveSuccess, saveError)
    }
  }

  function saveSuccess(response) {
    // If this is a fresh invite
    if (_isNull(response.accepted_at)) {
      vm.dismiss({ $value: 'saved' })
    } else {
      // If the user has already been invited
      vm.error = 'email_is_existing_member'
      vm.saving = false
    }
  }

  function saveError(rejection) {
    let response = rejection.$response.data

    if (response.error === 'member_limit_exceeded' || response.error === 'view_only_member_limit_exceeded') {
      ErrorsModal.openModal(response.error)
    } else if (response.error === 'email_is_existing_member') {
      vm.error = response.error
    } else if (response.error === 'validation_error') {
      vm.error = response.error
      vm.validationErrors = response.data
    } else {
      vm.error = 'unknown'

      Sentry.captureMessage('New User Invite: Unknown error message was shown')
    }

    vm.saving = false
  }

  function closeModal() {
    vm.dismiss({ $value: 'cancel' })
  }

  function changeHandler(picked) {
    vm.model.folders = picked
  }

  function toggleFolderPickerReady(state) {
    vm.folderPickerReady = state
  }
}
