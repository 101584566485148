import { Controller } from './controller'
import view from './view.html'

export const ptChecklistFieldEdit = {
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  },
  controller: Controller,
  template: view
}
