import PubSub from 'pubsub-js'
import { v4 as uuid } from 'uuid'

export function ProductsList($localForage) {
  'ngInject'

  const store = $localForage.createInstance({
    name: 'Papertrail',
    storeName: 'ProductsList'
  })

  const NOTIFY_CHANGES = 'ProductsList'

  const notifyChanges = () => {
    PubSub.publish(NOTIFY_CHANGES)
  }

  const get = (productId) => {
    let product = null;

    return store
      .iterate((value) => {
        if(value.id == productId) {
          product = value;
        }
      })
      .then(() => product)
  }
  
  const getUnique = (uniqueUuid) => store.getItem(uniqueUuid)

  const list = () => {
    const productList = []

    return store
      .iterate((value, key) => {
        if(value.uniqueUuid) {
          productList.push(value)
        } else {
          store.removeItem(key);
        }
      })
      .then(() => {
        return productList
      })
  }

  const remove = (product, quantity = null) => {
    if (!quantity) {
      return store.removeItem(product.uniqueUuid).then(notifyChanges)
    }

    return getUnique(product.uniqueUuid).then((currentProductData) => {
      const targetQuantity = currentProductData.quantity - quantity

      if (targetQuantity < 1) {
        return store.removeItem(product.uniqueUuid).then(notifyChanges)
      }

      return store
        .setItem(product.uniqueUuid, {
          ...product,
          uniqueUuid: product.uniqueUuid,
          quantity: targetQuantity
        })
        .then(notifyChanges)
    })
  }

  const set = (product, quantity) => {
    if (quantity <= 0) {
      return store.removeItem(product.uniqueUuid).then(notifyChanges)
    }

    if(!product.uniqueUuid) {
      const uniqueUuid = uuid();
      product.uniqueUuid = uniqueUuid;
      product.uniqueTimestamp = Date.now();
    }
    
    return store
      .setItem(product.uniqueUuid, {
        ...product,
        quantity: quantity
      })
      .then(notifyChanges)
  }

  const getQuantity = () =>
    list().then((productsList) => productsList.reduce((accumulator, product) => accumulator + product.quantity, 0))

  const clear = () => store.clear().then(notifyChanges)

  return {
    NOTIFY_CHANGES,
    notifyChanges,
    get,
    getUnique,
    list,
    remove,
    set,
    getQuantity,
    clear
  }
}
