import { createResource } from '../../api/resource'
import { createResponsePaginationTransformer } from '../../api/transformer'
import { Record } from '..'

interface Query {
  include?: string
  limit: number
  folder_id?: string
}

interface Params {
  accountId: string
  query?: Query
}

export const fetchRecords = createResource({
  request: ({ accountId, query }: Params) => ({
    url: `accounts/${accountId}/records`,
    method: 'GET',
    params: query
  }),
  transform: createResponsePaginationTransformer<Record>(
    'account',
    'folder',
    'user',
    'frequency',
    'field',
    'log',
    'logs',
    'note',
    'notes',
    'state',
    'initialState',
    'uploads',
    'checklists'
  )
})
