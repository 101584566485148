import lazy from 'lazy.js'

export function Controller($scope, recordsSelection, recordsViewMode, recordsPath, richText, EventsData) {
  'ngInject'

  let vm = this
  let memorizeCache = {
    logComments: {
      raw: null,
      parsed: null
    }
  }

  vm.control = {
    getFieldValue: getFieldValue,
    toggleSelection: toggleSelection,
    selectionChange: selectionChange
  }

  vm.richText = memorizeLogComments

  vm.showCrumbs = recordsViewMode.get() === 'record' ? true : false
  vm.crumbs = []

  /////////////

  vm.$onInit = function () {
    addRowToSelectionObject()

    if (vm.showCrumbs) {
      vm.crumbs = recordsPath.getRelativePath(vm.record)
    }

    watch()
  }

  function watch() {
    $scope.$watch('vm.cols', calculateDataTypes)
    $scope.$watch('vm.record.fields', calculateDataTypes, true)
  }

  function memorizeLogComments(text) {
    if (memorizeCache.logComments.raw !== text) {
      memorizeCache.logComments.raw = text
      memorizeCache.logComments.parsed = richText(text)
    }

    return memorizeCache.logComments.parsed
  }

  function addRowToSelectionObject() {
    recordsSelection.add(vm.record)
    selectionChange();

    $scope.$on('$destroy', function () {
      recordsSelection.remove(vm.record)
      selectionChange();
    })
  }

  function toggleSelection($event) {
    $event.stopPropagation()
    recordsSelection.toggle(vm.record)
    selectionChange();
  }

  function selectionChange() {
    EventsData.broadcast('records:reorder-selection');
  }

  function calculateDataTypes() {
    lazy(vm.cols).each(function (col) {
      if (!col.$dataType) {
        col.$dataType = getDataType(col)
      }
    })
  }

  function getDataType(column) {
    if (column.$coreColumn) {
      return column.key
    }
    return typeForColumn(column.key)
  }

  function getFieldValue(fieldKey) {
    let field = findField(fieldKey)

    if (field) {
      return field.value
    }

    return null
  }

  function typeForColumn(fieldKey) {
    let field = findField(fieldKey)

    if (field) {
      return field.type
    }
    return null
  }

  function findField(fieldKey) {
    return lazy(vm.record.fields).find(function (field) {
      return field && field.key === fieldKey
    })
  }
}
