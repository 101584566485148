import angular from 'angular'

export function Controller($timeout, NotificationsData, recordsModal, EventsData) {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {
    readOne: readOne
  }

  $ctrl.$onInit = () => {
    $ctrl.notification = angular.copy($ctrl.resolve.notification)
  }

  function readOne() {
    NotificationsData.readCollection([$ctrl.resolve.notification])
      .then(function readSuccess() {
        $ctrl.resolve.notification.read_at = true
        $ctrl.notification = angular.copy($ctrl.resolve.notification)
        EventsData.pushEvent('notification_was_read', {
          from: 'modal',
          id: [$ctrl.resolve.notification.id]
        })
      })
      .catch(() => {})
  }
}
