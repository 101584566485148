import { Controller } from './controller'
import view from './view.html'

export const ptChecklist = {
  bindings: {
    record: '<',
    checklist: '<',
    checklistOptions: '<',
    onRemoveChecklist: '&',
    onUpdateChecklist: '&'
  },
  controller: Controller,
  template: view
}
