import view from './view.html'
import { Controller } from './controller'

export const ptRecordFieldMoneypicker = {
  bindings: {
    fieldOptions: '<'
  },
  template: view,
  controller: Controller,
  require: {
    ngModelCtrl: '^ngModel'
  }
}
