import { createResource } from '../../api/resource'
import { createRequestTransformer, createResponseTransformer } from '../../api/transformer'
import { Folder } from '../index'

interface UpdateFolderRequest {
  name?: string
  description?: string
  position?: number
  parentId?: UUID
}

interface Params {
  accountId: UUID
  folderId: UUID
  payload: UpdateFolderRequest
}

const transformRequest = createRequestTransformer<UpdateFolderRequest>()

export const updateFolder = createResource({
  request: ({ accountId, folderId, payload }: Params) => ({
    url: `accounts/${accountId}/folders/${folderId}`,
    method: 'PATCH',
    data: transformRequest(payload)
  }),
  transform: createResponseTransformer<Folder>(
    'members',
    'children',
    'records',
    'ancestors',
    'descendants',
    'definitions',
    'user'
  )
})
