export function Controller($state, foldersNavigation, $scope) {
  'ngInject'

  let vm = this

  vm.control = {
    go: go
  }

  vm.crumbs = vm.crumbs || []

  vm.$onInit = function () {
    setupFolders()

    $scope.$watch('vm.folders', function () {
      vm.crumbs = []
      setupFolders()
    })
  }

  function setupFolders() {
    if (vm.folders && vm.folders.length) {
      const folders = vm.folders.slice(1)

      folders.forEach(function (folder) {
        vm.crumbs.push({
          name: folder.name,
          link: 'folder',
          id: folder.id
        })
      })
    }
  }

  function go(crumb) {
    if (crumb.link === 'folders') {
      foldersNavigation.go()
    } else if (crumb.link === 'folder') {
      foldersNavigation.go(crumb.id)
    } else {
      $state.go(crumb.link)
    }
  }
}
