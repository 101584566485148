import angular from 'angular'

export function ptIauditorAuditsRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('iauditor', {
    parent: 'account',
    url: '/iauditor?used&from&to&template_id&author&score&destination&team_sourced',
    views: {
      'content@layoutFull': {
        component: 'ptIauditorAudits'
      }
    },
    onEnter: onIAuditorEnter
  })

  function onIAuditorEnter(gettextCatalog, page, $state, $transition$, moment) {
    'ngInject'

    let params = angular.copy($transition$.params())
    let moments = {
      to: params.to && moment(params.to),
      from: params.from && moment(params.from)
    }
    let now = moment()
    let reload = false
    let result

    /// Page Title
    page.setTitle(gettextCatalog.getString('iAuditor'))

    // Default filter
    if (!params.used) {
      params.used = 'false'
      reload = true
    }

    // Validate the dates, and use defaults if that fail validation
    if (moments.to && moments.from && moments.from.isAfter(moments.to)) {
      reload = true
      moments.from = null
      moments.to = null
    }

    if (moments.from && moments.from.isAfter(now)) {
      reload = true
      moments.from = null
    }

    if (moments.to && moments.to.isAfter(now)) {
      reload = true
      moments.to = null
    }

    if (reload) {
      result = $state.target($transition$.to(), params, $transition$.options())
    }

    return result
  }
}
