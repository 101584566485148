import { Controller } from './controller'
import view from './view.html'

export const ptRecordPreviewHeader = {
  bindings: {
    record: '<',
    multiple: '<',
    identifiers: '<',
    onRemove: '&'
  },
  controller: Controller,
  template: view
}
