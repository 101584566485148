import { createResource } from '../../api/resource'
import { createResponseTransformer } from '../../api/transformer'

interface Params {
  taskId: UUID
  taskState: string
}

export const updateTaskState = createResource({
  request: ({ taskId, taskState }: Params) => ({
    url: `tasks/${taskId}/actions/setstate/${taskState}`,
    method: 'POST',    
    data: null
  }),
  transform: createResponseTransformer<any>()
})
