export function IntegrationsData($q, context) {
  'ngInject'

  let integrations: any = {}

  let service = {
    init: init
  }

  return service

  function init() {
    let defer = $q.defer()

    // If we already have the integrations data, return it from cache.
    if (integrationsDataExists()) {
      defer.resolve(integrations.data)

      return defer.promise
    }

    // Otherwise, get the data from the API
    return context.get('account').integrations.$fetch().$asPromise().then(storeIntegrationsData)
  }

  function storeIntegrationsData(data) {
    integrations.data = data
    integrations.accountId = context.get('account').id

    return data
  }

  /**
   * Check if the integrations data is cached and that the account the user is on matches that data
   */
  function integrationsDataExists() {
    return !!integrations.data && context.get('account').id === integrations.accountId
  }
}
