import view from './view.html'
import { Controller } from './controller'

export const ptRecordFieldDate = {
  bindings: {
    field: '<',
    onSave: '&',
    editable: '<',
    fieldOptions: '<'
  },
  template: view,
  controller: Controller
}
