import _pick from 'lodash/pick'

export function Controller(
  $scope,
  $rootScope,
  recordsBucket,
  recordsData,
  recordsEvents,
  recordsSelection,
  DefinitionsData,
  recordsModal,
  recordsViewMode,
  recordsPagination,
  foldersNavigation,
  filteringContext,
  AccessesData,
  context
) {
  'ngInject'

  let vm = this
  let eventControl = {
    getRecords: getRecords
  }



  vm.control = {
    openRecord: openRecord
  }

  vm.$onInit = function () {
    vm.account = context.get('account')
    setRecordColumns()
    addEventListener('record_tran_ok', () => {
      getRecords(false);
    } )
    

    vm.starredFilterApplied = filteringContext.getFilteringParams().starred

    AccessesData.folderAccess(vm.folder.id)
      .then(function (access) {
        vm.folderStarred = access && access.starred

        // No folders within folders view OR starred filter applied on non-starred folder
        if (
          (vm.folder.count === 0 && recordsViewMode.get() === 'folder') ||
          (vm.starredFilterApplied && !vm.folderStarred)
        ) {
          vm.records = []
          vm.$selection = recordsSelection.init()
        } else {
          getRecords(true)
        }
      })
      .catch(() => {})

    recordsEvents.handle($scope, vm, eventControl)

    listen()
  }

  function listen() {
    $scope.$on('definitions:changed', function () {
      vm.cols = DefinitionsData.getVisibleColumnsOnly()

      checkForSubRows(vm.records)
    })
    $scope.$on('records:reorder-selection', function () {
      resetSelectionOrder(vm.records)
    });
    let bucketChangedEvent = $rootScope.$on('bucket:changed', function(oldData, newData) {
      getRecords(false)
    });
    $scope.$on('$destroy', bucketChangedEvent);
  }

  function getRecords(resetSelection) {
    const extraParams: any = {};

    if(vm.bucket) {
      extraParams.record_id = recordsBucket.recordIds.join(',');
    }

    let recordsCollection = recordsData.init(vm.account, vm.folder.id, vm.bucket ? 1000 : recordsPagination.getPerPage(), extraParams)

    setSort()

    if (resetSelection) {
      vm.$selection = recordsSelection.init(vm.account)
    }

    vm.loading = true

    return recordsData.getPage(recordsCollection, recordsPagination.getPage()).then(function (records) {
      // If there are no records on this page, go to the last page with records
      if (!records.$metadata.pagination.count && records.$metadata.pagination.current_page !== 1) {
        recordsPagination.setPage(records.$metadata.pagination.total_pages)

        return foldersNavigation.go(vm.folder.id, { paginate: true })
      }

      vm.records = records
      vm.loading = false

      checkForSubRows(vm.records)
      resetSelectionOrder(vm.records)

      vm.folderMeta.$hasRecords = !!vm.records.length

      return records
    })
  }

  function resetSelectionOrder(records) {
    const selectedRecordIds = recordsSelection.selectedIds();

    for(let recordId in recordsSelection.current()) {
      recordsSelection.remove({
        id: recordId
      });
    }

    for(let record of records) {
      if(selectedRecordIds.includes(record.id)) {
        recordsSelection.add(record);
        recordsSelection.select(record);
      } else {
        recordsSelection.add(record);
        recordsSelection.unselect(record);
      }
    }
  }

  /**
   * Set which columns (aka definations) are to be displayed in the record list view
   */
  function setRecordColumns() {
    vm.definitions = DefinitionsData.getAllColumns()
    vm.cols = DefinitionsData.getVisibleColumnsOnly()

    checkForSubRows(vm.records)
  }

  function setSort() {
    recordsData.setSort(DefinitionsData.getVisibleColumnsAsSortKeys())
  }

  function openRecord(record) {
    recordsModal.goToModalRoute(record.id)
  }

  function checkForSubRows(records) {
    let subordinate = DefinitionsData.getSubordinate()

    if (records && records.length) {
      records.forEach(function (record) {
        record.$hasSubRow = false
        record.subRows = {}

        subordinate.forEach(function (row) {
          let properties = row.key.split('.')
          let column = _pick(record[properties[0]], properties[1])

          switch (row.key) {
            case 'log.comments':
              if (column && column[properties[1]]) {
                record.$hasSubRow = true
                record.subRows[properties[1]] = column[properties[1]]
              }
              break
            case 'log.uploads':
            case 'log.checklist_completions':
              if (column && column[properties[1]] && column[properties[1]].length) {
                record.$hasSubRow = true
                record.subRows[properties[1]] = column[properties[1]]
              }
              break
            default:
              break
          }
        })
      })
    }
  }
}
