import { Platform } from 'reactxp'

type PlatformType = ReturnType<typeof Platform.getType> | 'mobile'

export const getPlatformSize = (size: number, platforms: { [key in PlatformType]?: number } = {}) => {
  const type = Platform.getType()
  const platformValue = platforms[type]

  if (!!platformValue) {
    return platformValue
  } else if (type === 'android' || type === 'ios') {
    return platforms.mobile || size
  }

  return size
}

export const getPlatformStyle = <T extends object>(platforms: { [key in PlatformType]?: T } = {}) => {
  const type = Platform.getType()
  const platformValue = platforms[type]

  if (!!platformValue) {
    return platformValue
  } else if (type === 'android' || type === 'ios') {
    return platforms.mobile || {}
  }

  return {}
}
