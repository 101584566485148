import angular from 'angular'

export function Controller(iAuditorData) {
  'ngInject'

  this.$onInit = () => {}

  this.$onChanges = (changes) => {
    if (changes.audit) {
      this.audit = angular.copy(this.audit)
    }

    if (changes.customFields) {
      this.customFields = angular.copy(this.customFields)
    }
  }

  this.getCustomFieldValue = (fieldKey) => {
    return iAuditorData.getCustomFieldValue(this.audit.custom_fields, fieldKey)
  }
}
