export function ptFocus($timeout) {
  'ngInject'

  let directive = {
    link: link,
    restrict: 'A'
  }

  return directive

  function link(scope, element, attrs) {
    let unwatch = scope.$watch(attrs.ptFocus, function (newValue) {
      if (newValue) {
        $timeout(function () {
          element.focus()
        })

        if (!('ptFocusWatch' in attrs)) {
          // If there is not a pt-focus-watch attribute, then unwatch it, as its work has been done.
          unwatch()
        }
      }
    })
  }
}
