import * as React from 'react'
import { createStyledComponent, View } from '../../components/styled'
import { spacing } from '../../styles'

interface Props {
  children: React.ReactNode
}

const Wrapper = createStyledComponent(View, {
  marginLeft: spacing.base
})

export const ActivityContent = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>
}
