export function RecordRoute($stateProvider) {
  'ngInject'

  let viewRecordModal

  addRecordState('folder')
  addRecordState('folders')
  addRecordState('bucket')
  addRecordState('account')
  addRecordState('activities')

  function addRecordState(parent) {
    $stateProvider.state(parent + '.records', {
      url: '/records/{recordId:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}?openfields&postRecordAction',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        resolveRecords: resolveRecord
      },
      onEnter: onViewRecordEnter,
      onExit: onViewRecordExit
    })
  }

  function resolveRecord(account, recordsResolves, $stateParams, ErrorsDisplay) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    return recordsResolves.expandedRecord($stateParams.recordId).catch(function (rejection) {
      return ErrorsDisplay.catch(rejection.$response.data.error)
    })
  }

  function onViewRecordEnter($uibModal, $state, resolveRecords, $transition$, page) {
    'ngInject'
    page.setTitle('Record Details')
    viewRecordModal = $uibModal.open({
      component: 'ptRecord',
      windowClass: 'record',
      resolve: {
        record: () => resolveRecords,
        $transition: () => $transition$
      }
    })

    viewRecordModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onViewRecordExit(page, $state, $stateParams, $timeout) {
    'ngInject'
    page.resetTitle()
    viewRecordModal.close()    
    if ($stateParams.postRecordAction) {
      $timeout(() => {
        const recordIds = $stateParams.recordId
        $state.go('folder.action-records', { recordIds, action: 'create' })
      })
    } 
  }  
}
