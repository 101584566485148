import angular from 'angular'

import { ptExportCertificateModule } from './components/pt-export-certificate'
import { ptExportGeneralModule } from './components/pt-export-general'
import { ptExportsModule } from './components/pt-exports'

import { ExportsData } from './services/data'
import { ExportsModel, CertificatesModel } from './services/models'

export const ExportsModule = angular
  .module('exports', [ptExportCertificateModule, ptExportGeneralModule, ptExportsModule])
  .factory('ExportsData', ExportsData)
  .factory('CertificatesModel', CertificatesModel)
  .factory('ExportsModel', ExportsModel).name
