import angular from 'angular'
import { ptFolderColumns } from './directive'

export const ptFolderColumnsModule = angular
  .module('folders.pt-folder-columns', [])
  .directive('ptFolderColumns', ptFolderColumns)
  .run([
    '$templateCache',
    ($templateCache) => {
      $templateCache.put('directives/pt-folder-columns/popover.html', require('./popover.html'))
    }
  ]).name
