import * as React from 'react'
import { View, Text, Platform } from 'reactxp'
import { colors, spacing, fontSizes } from '../../styles'
import { InputDropdown, PluralText, IconTextInput } from '../../components'
import { User } from '../../user/index'
import { MemberSelector } from './member-selector'
import { MembersAvatarRow } from './members-avatar-row'
import { find } from 'lodash'

interface MemberSearchProps {
  users: User[]
  initialSelectedUsers: User[]
  editable: boolean
  onUsersUpdated: (users: User[]) => void
}

const ItemStyle = {
  paddingTop: spacing.base,
  paddingBottom: spacing.base,
  borderWidth: 0,
  borderColor: colors.neutralTintSix
}

export const MemberSearch = ({ users, initialSelectedUsers, onUsersUpdated, editable }: MemberSearchProps) => {
  const [search, setSearch] = React.useState('')
  const [showDropdown, setShowDropdown] = React.useState(false)
  const [filteredUsers, setFilteredUsers] = React.useState(initialSelectedUsers)
  const [selectedUsers, setSelectedUsers] = React.useState(initialSelectedUsers)
  const dropdown = React.useRef(null)

  const handlePress = (event) => {
    if (dropdown && dropdown.current && dropdown.current.contains(event.target)) {
      return
    }

    setShowDropdown(false)
  }

  React.useEffect(() => {
    setFilteredUsers(users)
  }, [])

  React.useEffect(() => {
    setSelectedUsers(initialSelectedUsers)
  }, [initialSelectedUsers])

  React.useEffect(() => {
    const platform = Platform.getType()
    const pressEvent = platform === 'web' ? 'click' : 'touchstart'

    if (showDropdown) {
      document.addEventListener(pressEvent, handlePress)
    } else {
      document.removeEventListener(pressEvent, handlePress)
    }

    return () => {
      document.removeEventListener(pressEvent, handlePress)
    }
  }, [showDropdown])

  const closeSearch = () => {
    setSearch('')
    setShowDropdown(false)
  }

  const onInputPress = () => {
    setShowDropdown(true)
  }

  const onInputKey = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeSearch()
    }
  }

  const searchChange = (value: string) => {
    setSearch(value)
    setFilteredUsers(
      users.filter((user) => {
        return `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.toLowerCase())
      })
    )

    // Need to double check the search is open after event.
    // It may have been closed due to pressing Esc key.

    // this is a functional  component not sure if it works 
    if (value.length && !showDropdown) {
      this.setState({
        showDropdown: true
      })
    }
  }

  const updateUsers = (user: User, shouldSetSelected: boolean) => {
    const isCurrentlySelected = find(selectedUsers, { id: user.id })

    // Remove user from selection
    if (!shouldSetSelected && isCurrentlySelected) {
      const selected = selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)

      // setSelectedUsers(selected)
      onUsersUpdated(selected)
    }
    // Add user to selection
    else if (shouldSetSelected && !isCurrentlySelected) {
      const selected = [...selectedUsers, user]

      // setSelectedUsers(selected)
      onUsersUpdated(selected)
    }
  }

  return (
    <View style={{ overflow: 'visible' }}>
      <IconTextInput
        placeholder="Search..."
        iconName="monitor"
        onInputPress={onInputPress}
        onChangeValue={searchChange}
        onKeyPressEvent={onInputKey}
        value={search}
      />
      {!selectedUsers.length && (
        <View style={{ padding: spacing.base }}>
          <Text style={{ fontSize: fontSizes.medium, marginBottom: spacing.half }}>
            There are no users assigned to this Task
          </Text>
          <Text style={{ color: colors.neutralTintTwo }}>
            Use the search bar above to find users to invite to this task.
          </Text>
        </View>
      )}
      {!!selectedUsers.length && (
        <View style={{ padding: spacing.base }}>
          <PluralText
            count={selectedUsers.length}
            plural={`Adding ${selectedUsers.length} users`}
            style={{
              fontSize: fontSizes.small,
              color: colors.neutralTintFour,
              marginBottom: spacing.half
            }}>
            Adding {selectedUsers.length} user
          </PluralText>
          <MembersAvatarRow users={selectedUsers} />
          {selectedUsers.map((user, index) => (
            <View
              key={index}
              style={[
                ItemStyle,
                {
                  ...(index + 1 < users.length ? { borderBottomWidth: 1 } : {})
                }
              ]}>
              <MemberSelector onUpdateUser={updateUsers} user={user} isSelected={true} editable={editable} />
            </View>
          ))}
        </View>
      )}
      {showDropdown && (
        <div ref={dropdown}>
          <InputDropdown closeDropdown={closeSearch} position={{ top: 50 }}>
            <View style={{ paddingLeft: spacing.base, paddingRight: spacing.base }}>
              {!!filteredUsers.length &&
                filteredUsers.map((user, index) => (
                  <View
                    key={index}
                    style={[
                      ItemStyle,
                      {
                        ...(index + 1 < filteredUsers.length ? { borderBottomWidth: 1 } : {})
                      }
                    ]}>
                    <MemberSelector
                      onUpdateUser={updateUsers}
                      user={user}
                      editable={editable}
                      isSelected={!!find(selectedUsers, { id: user.id })}
                    />
                  </View>
                ))}
              {!filteredUsers.length && search && (
                <Text
                  style={{
                    paddingTop: spacing.base,
                    paddingBottom: spacing.base
                  }}>
                  No results to display.
                </Text>
              )}
            </View>
          </InputDropdown>
        </div>
      )}
    </View>
  )
}
