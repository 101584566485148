
import SignaturePad from "signature_pad";

export function Controller(uploadFileActions, BASE_URL, context, ChecklistsData, gettextCatalog, $rootScope) {
  'ngInject'


  let $ctrl = this;

  $ctrl.control = {
    signatureUrl: signatureUrl,
    view: view,
  }

  $ctrl.image = null
  $ctrl.previewUrl = null
  $ctrl.uploading = false
 

  $ctrl.$onInit = () => {
    $ctrl.accountId = context.get('account').id
    $ctrl.modalTitle = gettextCatalog.getString('Add Signature')
  }

  const canvas = document.querySelector("canvas");

  canvas.style.border = "1px solid #c6ced6";
  canvas.style.margin = "20px auto";
  canvas.style.borderRadius = "3px";
  

  const signaturePad = new SignaturePad(canvas, {
    minWidth: 0.5,
    maxWidth: 2.5,
    penColor: "black",
    backgroundColor: 'rgb(255, 255, 255)'
  }); 
  

  $ctrl.signatureData = null;
  $ctrl.signatureSaved = false;
  $ctrl.signatures = [];

  $ctrl.accept = () => {
    if (signaturePad && !signaturePad.isEmpty() ) {
        const signatureData = signaturePad.toDataURL();

        // Convert Base64 string to Blob
        function base64ToBlob(base64, mimeType) {
            const bytes = atob(base64.split(',')[1]);
            const arr = new Uint8Array(bytes.length);

            for (let i = 0; i < bytes.length; i++) {
                arr[i] = bytes.charCodeAt(i);
            }

            return new Blob([arr], { type: mimeType });
        }
        $ctrl.signatures.push(signatureData);
        const blob = base64ToBlob(signatureData, 'image/png');

        // Create a File object with the Blob and the filename
        const extension = $ctrl.filename ? $ctrl.filename.split('.').pop() : 'png';
        const filename = $ctrl.filename ? ($ctrl.filename.endsWith('.png') ? $ctrl.filename : `${$ctrl.filename}.png`) : `signature.${extension}`;

        const signatureFile = new File([blob], filename, { type: 'image/png' });
       
        // Call the uploadSignature method from the ChecklistsData service
        ChecklistsData.uploadSignature({ id: $ctrl.accountId }, signatureFile)
            .then((response) => {
              $ctrl.uploading = true;
            
                const event = {
                    files: [signatureFile],
                    model: [
                        { value: response.id, type: 'upload_id' }
                    ]
                };

                $ctrl.dismiss({ $value: event });
                $ctrl.signatureSaved = true;
                $ctrl.setFiles(event);
                $ctrl.setUploading(event);
                $rootScope.$broadcast('signatureSaved', { 
                  signatures: $ctrl.signatures,
                  model: event.model,
                  field:  $ctrl.resolve.field

              });
             
            })
            .catch(() => {});
          } 
          else if (signaturePad && signaturePad.isEmpty() && $ctrl.resolve.field.files) {
            ChecklistsData.uploadSignature({ id: $ctrl.accountId }, $ctrl.resolve.field.files[0])
            .then((response) => {
              $ctrl.uploading = true;

            const event = {
              files: [$ctrl.resolve.field.files],
              model: [
                  { value: response.id, type: 'upload_id' }
              ]
          };
          $ctrl.setFiles(event);
          $ctrl.setUploading(event);
          
          $ctrl.dismiss({ $value: event });

          $rootScope.$broadcast('fileUploaded', { fileData:  $ctrl.resolve.field, event: event, response: response});
        })
        .catch(() => {});
}
         
  
};

$ctrl.clear = () => {
  if (!signaturePad.isEmpty() ) {
    signaturePad.clear();
  } else  {
    return;
  }
};

$ctrl.hasFiles = () => {
  if($ctrl.resolve.field.uploads && $ctrl.resolve.field.files){
    return $ctrl.resolve.field.uploads
  }
}

$ctrl.setFiles = (event) => {
  $ctrl.resolve.field.uploads = event.model
  $ctrl.resolve.field.files = event.files
}

$ctrl.setUploading = (event) => {
  $ctrl.uploading = event.uploading
}

function view(upload) {
    uploadFileActions.viewFile(upload, undefined)
  }

  function signatureUrl(url) {
    return BASE_URL + url
  }

}