export function Controller(session, widgetsData) {
  'ngInject'

  let $ctrl = this

  $ctrl.initialised = false
  $ctrl.widgetData = []
  $ctrl.isEmployee = session.user.isEmployee

  $ctrl.$onInit = function () {
    widgetsData.fetch().then(function widgetsDataFetched(data) {
      data.sort(function (a, b) {
        return a.order - b.order
      })
      $ctrl.initialised = true
      $ctrl.widgetData = data
    })
  }
}
