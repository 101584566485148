export function emoji(emojiService) {
  'ngInject'

  const emojifFilter = (value) => {
    let emojione = emojiService.get()
    if (!emojione) {
      return value
    } else {
      emojione.ascii = true
      return emojione.shortnameToUnicode(value)
    }
  }

  return emojifFilter
}
