import _find from 'lodash/find'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _omit from 'lodash/omit'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _keys from 'lodash/keys'
import { PapertrailSession } from '@papertrail/web3-session'

export function Controller(context, $state, accountsData, AddressesData, gettextCatalog) {
  'ngInject'

  let coreAccountFields = ['name', 'country_code', 'timezone']
  let $ctrl = this
  let s = {
    /// Field label
    accountName: gettextCatalog.getString('Account name'),
    /// Field label
    businessName: gettextCatalog.getString('Business/Organisation name'),
    /// Field label
    address1: gettextCatalog.getString('Address line 1'),
    /// Field label
    address2: gettextCatalog.getString('Address line 2'),
    /// Field label
    city: gettextCatalog.getString('City'),
    /// Field label
    county: gettextCatalog.getString('County/State'),
    /// Field label
    postcode: gettextCatalog.getString('Postcode/Zip'),
    /// Field label
    country: gettextCatalog.getString('Country'),
    /// Field label
    timezone: gettextCatalog.getString('Timezone')
  }

  $ctrl.control = {
    save: save
  }

  $ctrl.self = gettextCatalog.getString('Settings')

  $ctrl.account = context.get('account')
  $ctrl.defaultAddress = getDefaultAddress()
  $ctrl.saving = false

  $ctrl.model = {
    name: $ctrl.account.name,
    country_code: $ctrl.account.country_code,
    timezone: $ctrl.account.timezone,
    address_line_one: $ctrl.defaultAddress && $ctrl.defaultAddress.address_line_one,
    address_line_two: $ctrl.defaultAddress && $ctrl.defaultAddress.address_line_two,
    address_line_three: $ctrl.defaultAddress && $ctrl.defaultAddress.address_line_three,
    address_line_four: $ctrl.defaultAddress && $ctrl.defaultAddress.address_line_four,
    locality: $ctrl.defaultAddress && $ctrl.defaultAddress.locality,
    region: $ctrl.defaultAddress && $ctrl.defaultAddress.region,
    postcode: $ctrl.defaultAddress && $ctrl.defaultAddress.postcode,
    country: $ctrl.defaultAddress && $ctrl.defaultAddress.country
  }
  $ctrl.accountFields = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: s.accountName,
        required: true
      }
    },
    {
      key: 'address_line_one',
      type: 'input',
      templateOptions: {
        label: s.businessName
      }
    },
    {
      key: 'address_line_two',
      type: 'input',
      templateOptions: {
        label: s.address1
      }
    },
    {
      key: 'address_line_three',
      type: 'input',
      templateOptions: {
        label: s.address2
      }
    },
    {
      key: 'locality',
      type: 'input',
      templateOptions: {
        label: s.city
      }
    },
    {
      key: 'region',
      type: 'input',
      templateOptions: {
        label: s.county
      }
    },
    {
      key: 'postcode',
      type: 'input',
      templateOptions: {
        label: s.postcode
      }
    },
    {
      key: 'country_code',
      type: 'select',
      templateOptions: {
        label: s.country,
        required: true,
        options: $ctrl.countries,
        ngOptions: 'country.alpha_2 as country.name for country in to.options'
      }
    },
    {
      key: 'timezone',
      type: 'select',
      templateOptions: {
        label: s.timezone,
        required: true,
        options: $ctrl.timezones,
        ngOptions: 'timezone.name as timezone.formattedName for timezone in to.options'
      }
    }
  ]
  $ctrl.error = null
  $ctrl.success = false

  $ctrl.$onInit = function () {}

  function getDefaultAddress() {
    return _find($ctrl.account.addresses, 'default')
  }

  function save() {
    let prop
    let propsToUpdate = []
    let modelFields = _pick($ctrl.model, coreAccountFields)
    let accountFields = _pick($ctrl.account, coreAccountFields)

    if ($ctrl.form.$valid) {
      $ctrl.saving = true
      $ctrl.error = null
      $ctrl.success = false

      for (prop in modelFields) {
        if (modelFields[prop] !== accountFields[prop]) {
          propsToUpdate.push(prop)
        }
      }

      _merge($ctrl.account, _pick($ctrl.model, coreAccountFields))

      // We have something to update.
      if (propsToUpdate.length) {
        accountsData.update($ctrl.account, propsToUpdate).then(accountSaveSuccess, saveError)
      } else {
        // Nothing to update.
        accountSaveSuccess()
      }
    }
  }

  function accountSaveSuccess() {
    PapertrailSession.reloadAccount()
    let addressFields: any = _omit($ctrl.model, coreAccountFields.concat(['country']))
    let hasAddress = !!_filter(addressFields).length

    addressFields.country = _get(_find($ctrl.countries, { alpha_2: $ctrl.model.country_code }), 'name')

    if ($ctrl.defaultAddress) {
      if (hasAddress) {
        // patch address
        AddressesData.update(_merge($ctrl.defaultAddress, addressFields), _keys(addressFields)).then(
          addressSaveSuccess,
          saveError
        )
      } else {
        // delete address
        AddressesData.delete($ctrl.defaultAddress).then(addressSaveSuccess, saveError)
      }
    } else if (hasAddress) {
      // post address
      AddressesData.create($ctrl.account, addressFields).then(addressSaveSuccess, saveError)
    } else {
      addressSaveSuccess()
    }
  }

  function addressSaveSuccess() {
    $ctrl.success = true

    $state.forceReload().then(function () {
      $ctrl.saving = false
    })
  }

  function saveError(rejection) {
    $ctrl.saving = false
    $ctrl.error = rejection.$response.data.error
  }
}
