export function Controller(permissionsConverter, context, TasksData) {
  'ngInject'

  const account = context.get('account')
  const showTasks = TasksData.flag(account) === 'active'
  let vm = this
  vm.showTasks = showTasks

  ////////////////

  vm.$onInit = function () {
    calculatePermissionLevels()
  }

  function calculatePermissionLevels() {
    vm.permissionNames = permissionsConverter.convert(vm.permissions, 'name')
  }
}
