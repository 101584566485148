export function Controller(TasksData, context) {
  'ngInject'
  const account = context.get('account')
  const taskStates = account.task_states.data

  this.$onInit = () => {    
    this.options = taskStates
    this.state = this.resolve.task.taskStateKey    
  }

  this.confirm = () => {
    const state = taskStates.find((t) => t.key === this.state)
    this.dismiss({ $value: state.id })
  }
}
