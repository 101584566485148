type Params = {
  accountId?: string
}

const recordBucketKey = 'records-bucket';

export function recordsBucket($q, context, $rootScope) {
  'ngInject'

  let initialState = localStorage.getItem(recordBucketKey);

  let service = {
    add: add,
    remove: remove,
    addMany: addMany,
    removeMany: removeMany,
    clear: clear,
    accountRecordIds: initialState ? JSON.parse(initialState) : {}, // Object so we don't have to check if the key already exists
    get recordIds() {
      return getRecordIds()
    },
    recordInBucket: recordInBucket,
  }

  if (window.addEventListener) {
    window.addEventListener("storage", function(e) {
      if(e.key === recordBucketKey) {
        const newData = e.newValue ? JSON.parse(e.newValue) : {}
        const oldData = e.oldValue ? JSON.parse(e.oldValue) : {}
        updateBucket(oldData, newData);
      }
    }, false);
  }

  function updateBucket(oldData, newData) {
    service.accountRecordIds = newData;
    $rootScope.$emit('bucket:changed', { oldData, newData });
    $rootScope.$apply();
    return newData;
  }

  // Ensure Local Storage Syncs across Tabs
  $rootScope.$watch(function(){
    return localStorage.getItem(recordBucketKey);
  }, function(newValue, oldValue){
    if(newValue === oldValue) {
      return oldValue;
    }
    const newData = newValue ? JSON.parse(newValue) : {}
    const oldData = oldValue ? JSON.parse(oldValue) : {}
    return updateBucket(oldData, newData)
  }, true);

  return service

  function addMany(recordIds) {
    const accountId = getAccountId();
    // const oldData = JSON.parse(JSON.stringify(service.accountRecordIds));

    if(!service.accountRecordIds[accountId]) {
      service.accountRecordIds[accountId] = {}; // Object so we don't have to check if the key already exists
    }

    recordIds.forEach(recordId => {
      service.accountRecordIds[accountId][recordId] = recordId;
    });

    localStorage.setItem(recordBucketKey, JSON.stringify(service.accountRecordIds));

    //updateBucket(oldData, service.accountRecordIds);

    return service.accountRecordIds[accountId];
  }

  function add(recordId) {
    return addMany([recordId])
  }

  function removeMany(recordIds) {
    const accountId = getAccountId();
    // const oldData = JSON.parse(JSON.stringify(service.accountRecordIds));

    if(!service.accountRecordIds[accountId]) {
      return null;
    }

    recordIds.forEach(recordId => {
      if(!service.accountRecordIds[accountId][recordId]) {
        return;
      }

      delete service.accountRecordIds[accountId][recordId];

      if(Object.keys(service.accountRecordIds[accountId]).length === 0) {
        delete service.accountRecordIds[accountId];
      }
    });

    localStorage.setItem(recordBucketKey, JSON.stringify(service.accountRecordIds));
    
    //updateBucket(oldData, service.accountRecordIds);

    return service.accountRecordIds[accountId];
  }

  function remove(recordId) {
    return removeMany([recordId]);
  }

  function clear() {
    const accountId = getAccountId();
    // const oldData = JSON.parse(JSON.stringify(service.accountRecordIds));

    service.accountRecordIds[accountId] = {};

    localStorage.setItem(recordBucketKey, JSON.stringify(service.accountRecordIds));

    // updateBucket(oldData, service.accountRecordIds);
  }

  function getAccountId() {
    const accountId = context.get('account').id
    return accountId;
  }

  function getRecordIds() {
    const accountId = getAccountId();
    
    if(service.accountRecordIds[accountId]) {
      return Object.keys(service.accountRecordIds[getAccountId()]);
    } else {
      return [];
    }
  }

  function recordInBucket(recordId) {
    const accountId = getAccountId();
    
    if(service.accountRecordIds[accountId] && service.accountRecordIds[accountId][recordId]) {
      return true;
    } else {
      return false;
    }
  }
}
