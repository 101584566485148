export function ActivitiesRevert($q, context, ActivitiesData, recordsData, foldersData, permissionsCheck, ErrorsModal) {
  'ngInject'

  let service = {
    revertable: revertable,
    revert: revert
  }

  return service

  function revert(activityId) {
    return ActivitiesData.get(context.get('account').id, activityId).then(function () {
      return ActivitiesData.revert(context.get('account').id, activityId).catch(() => {})
    })
  }

  function revertable(activity) {
    switch (activity.type) {
      case 'log_was_created':
        return checkHistoryPermission(activity, 'log')

      case 'note_was_created':
        return checkHistoryPermission(activity, 'note')

      default:
      //
    }

    if (!activity.revertable) {
      return $q.when(false)
    }

    switch (activity.type) {
      case 'field_was_created':
      case 'field_was_updated':
      case 'field_was_deleted':
      case 'record_upload_was_added':
      case 'record_upload_was_updated':
      case 'record_was_updated':
      case 'record_moved_to_folder':
      case 'record_was_created':
      case 'record_was_imported':
      case 'record_was_cloned':
      case 'record_was_undeleted':
        return checkRecordEditPermission(activity.record.id)

      case 'folder_was_created':
      case 'folder_was_moved':
      case 'folder_was_updated':
        return checkFolderEditPermission(activity.folder.id)

      case 'record_was_deleted':
        return checkAddPermission('records')

      case 'folder_was_deleted':
        return checkAddPermission('folders')

      default:
        return $q.when(false)
    }
  }

  function checkRecordEditPermission(id) {
    return recordsData.getRecord(id, null, 'user').then(function (record) {
      return permissionsCheck.can('edit', 'records', context.get('account'), record)
    })
  }

  function checkFolderEditPermission(id) {
    return foldersData
      .getFolder(context.get('account'), id, null)
      .then(function (folder) {
        return permissionsCheck.can('edit', 'folders', context.get('account'), folder)
      })
      .catch((response) => {
        ErrorsModal.openModal(response.error)
      })
  }

  function checkAddPermission(item) {
    return $q.when(permissionsCheck.can('add', item, context.get('account')))
  }

  function checkHistoryPermission(activity, entity) {
    return recordsData.getRecord(activity.record.id, null, 'user,account,' + entity).then(function (record) {
      return (
        record[entity].active &&
        record[entity].id === activity.to.id &&
        record.account.id === activity.account.id &&
        permissionsCheck.can('edit', 'logs', context.get('account'), record)
      )
    })
  }
}
