import _each from 'lodash/each'

export function TagsData($q, context, collections, TagsCache) {
  'ngInject'

  const service = {
    findTags: findTags
  }

  return service

  function findTags(account, fromApi = false) {
    const params = {
      limit: 100
    }

    if (!fromApi && TagsCache.get(account)) {
      return $q.when(TagsCache.get(account))
    }

    return collections.getAll(context.get('account').tags.$collection(params)).then((tags) => {
      _each(tags, (value) => {
        value.text = value.name
      })

      TagsCache.store(account, tags)

      return tags
    })
  }
}
