import _assign from 'lodash/assign'
import _debounce from 'lodash/debounce'
import _find from 'lodash/find'
import _remove from 'lodash/remove'
import _cloneDeep from 'lodash/cloneDeep'

// Cache the template for use in the view
//import './popover.html'

export function Controller($scope, $filter, gettextCatalog) {
  'ngInject'

  const $ctrl = this
  let availableItems = []

  $ctrl.control = {
    updateItems,
    toggleItemSelection,
    createNewItem,
    allowCreate,
    itemClicked,
    itemSubmit,
    removeItem,
    popoverClicked
  }

  $ctrl.loading = false
  $ctrl.searchText = ''
  $ctrl.itemDataFiltered = []
  $ctrl.popoverTemplate = 'components/pt-item-picker/popover.html'

  const defaultOptions = {
    searchPlaceholder: gettextCatalog.getString('Search...'),
    contextLabel: gettextCatalog.getString('Labels'),
    labelProperty: 'text',
    canCreate: true
  }

  $ctrl.$onInit = function () {
    $ctrl.options = _assign(defaultOptions, $ctrl.pickerOptions)
    listen()
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.selectedItems) {
      $ctrl.selectedItems = _cloneDeep($ctrl.selectedItems)

      setSelectedItems()
    }

    if (changes.popover) {
      $ctrl.popover = _cloneDeep($ctrl.popover)

      if ($ctrl.popover.open) {
        getAvailableItems()
      }

      if (changes.popover.currentValue.open && !changes.popover.previousValue.open) {
        $ctrl.searchText = ''
        updateItems()
      } else if (!changes.popover.currentValue.open && changes.popover.previousValue.open) {
        saveItems()
      }
    }
  }

  function getAvailableItems() {
    $ctrl.loading = true

    $ctrl
      .getAvailableItems()
      .then((items) => {
        availableItems = items
        $ctrl.itemDataFiltered = items

        setSelectedItems()
      })
      .finally(() => {
        $ctrl.loading = false
      })
  }

  function listen() {
    $scope.$on('itemPickerInit', getAvailableItems)

    $scope.$on('autoSubmit', saveItems)
  }

  function itemClicked(event, value) {
    event.stopPropagation()
    $ctrl.onItemClick({ value: value })
  }

  function itemSubmit(event) {
    event.stopPropagation()

    const matchedItem: any = exactMatchExists()

    if ($ctrl.options.canCreate && $ctrl.control.allowCreate()) {
      createNewItem()
    } else if (matchedItem && !matchedItem.selected) {
      $ctrl.control.toggleItemSelection(event, matchedItem)
      $ctrl.searchText = ''
      updateItems()
    }
  }

  function updateItems() {
    $ctrl.itemDataFiltered = $filter('filter')(availableItems, $ctrl.searchText)

    orderBySelected()
  }

  function orderBySelected() {
    $ctrl.itemDataFiltered = $filter('orderBy')($ctrl.itemDataFiltered, 'selected')
  }

  function setSelectedItems() {
    $ctrl.itemDataFiltered.forEach((filteredItem) => {
      filteredItem.selected = null

      if (_find($ctrl.selectedItems, { [$ctrl.options.labelProperty]: filteredItem[$ctrl.options.labelProperty] })) {
        filteredItem.selected = true
      }
    })

    orderBySelected()
  }

  function updateSelection() {
    let newItems = []

    availableItems.forEach((filteredItem) => {
      if (filteredItem.selected) {
        newItems.push(filteredItem)
      }
    })

    $ctrl.selectedItems = newItems
    $ctrl.onItemChange({ data: $ctrl.selectedItems })
  }

  function saveItems() {
    // As the below is an optional binding, check it exists first.
    if (typeof $ctrl.onSave === 'function') {
      $ctrl.onSave({ data: $ctrl.selectedItems })
    }
  }

  function removeItem(event, itemText) {
    let itemAvailable = _find(availableItems, { [$ctrl.options.labelProperty]: itemText })
    let saveDebounced = _debounce(saveItems, 2500)

    event.stopPropagation()

    if (itemAvailable && itemAvailable.hasOwnProperty('selected')) {
      delete itemAvailable.selected
    }

    _remove($ctrl.selectedItems, (item) => {
      return item[$ctrl.options.labelProperty] === itemText
    })

    $ctrl.onItemChange({ data: $ctrl.selectedItems })

    saveDebounced()
  }

  function toggleItemSelection(event, item) {
    event.preventDefault()

    if (item && item.selected) {
      // Angular will put 'false' values before true values when sorting.
      // The property 'selected' does not exist initially, so we need to re-undefine it.
      delete item.selected
    } else {
      item.selected = true
    }

    updateSelection()
  }

  function createNewItem() {
    availableItems.push({
      [$ctrl.options.labelProperty]: $ctrl.searchText,
      selected: true
    })

    $ctrl.searchText = ''

    updateItems()
    updateSelection()
  }

  /**
   * A helper function which lets us know if a new item can be created
   */
  function allowCreate() {
    return (
      !$ctrl.loading &&
      $ctrl.searchText &&
      !_find($ctrl.itemDataFiltered, { [$ctrl.options.labelProperty]: $ctrl.searchText })
    )
  }

  /**
   * A helper function which lets us know if an exact matched item exists
   */
  function exactMatchExists() {
    return $ctrl.searchText && _find($ctrl.itemDataFiltered, { [$ctrl.options.labelProperty]: $ctrl.searchText })
  }

  /**
   * Stop propagation when clicking within the item-picker popover to prevent a document
   * click being dispatched. Which would cause the item-picker popover to close.
   */
  function popoverClicked(event) {
    event.stopPropagation()
  }
}
