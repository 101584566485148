export function StateConfig($provide, $urlMatcherFactoryProvider, $urlRouterProvider) {
  'ngInject'

  $provide.decorator('$state', function ($delegate, $stateParams) {
    'ngInject'

    $delegate.forceReload = function () {
      return $delegate.go($delegate.current, $stateParams, {
        reload: true,
        inherit: false,
        notify: true
      })
    }
    return $delegate
  })

  $urlMatcherFactoryProvider.strictMode(false)

  $urlRouterProvider.otherwise('/accounts')
}
