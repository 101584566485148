export const getUploadType = (upload) => {
  let type

  if (upload.isImage) {
    type = 'image'
  } else if (upload.mime === 'application/pdf' && upload.source === 'file') {
    type = 'pdf'
  } else if (upload.mime === 'application/pdf' && upload.source === 'iauditor') {
    type = 'iauditor'
  }

  return type
}
