import angular from 'angular'
import { react2angular } from 'react2angular'
import { ptTaskAmendAssignees } from './component'
import { MemberSearch } from 'AppCore/member/components'

export const TaskAmendAssignees = angular
  .module('tasks.ptTaskAmendAssignees', [])
  .component('ptTaskAmendAssignees', ptTaskAmendAssignees)
  .component(
    'ptMembersSearch',
    react2angular(MemberSearch, ['users', 'initialSelectedUsers', 'onUsersUpdated', 'editable'])
  ).name
