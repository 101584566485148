export function EventsData($rootScope, EventsTranslator, EventsCache) {
  'ngInject'

  let debounceTimer
  const debounceDelay = 500 // Adjust as needed

  const service = {
    broadcast: broadcast,
    on: on,
    translate: translate,
    pushEvent: pushEvent
  }

  const debouncedEvents = ['field_was_created', 'field_was_updated']
  const debouncedEventHandlers = {}

  debouncedEvents.forEach((event) => {
    debouncedEventHandlers[event] = debounceEvent((args) => {
      $rootScope.$broadcast(event, args)
    }, debounceDelay)
  })

  function debounceEvent(func, delay) {
    return function (args) {
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => func(args), delay)
    }
  }

  /**
   * Broadcast an event without caching it.
   * Use pushEvent to cache (for use with Pusher events)
   */
  function broadcast(event, args) {
    if (debouncedEvents.includes(event)) {
      debouncedEventHandlers[event](args)
    } else {
      $rootScope.$broadcast(event, args)
    }
  }

  function on(event, args) {
    $rootScope.$on(event, args)
  }

  /**
   * When an action occurs, e.g. A log was created. We fire an App event, as
   * well as a Pusher event.
   *
   * App events are a fallback if the Pusher is not working, or is slow.
   *
   * We cache App and Pusher events so we can check if we have already actioned them.
   */
  function pushEvent(eventName, data) {
    let logMessage = 'Ignore internal event'

    if (data && !EventsCache.stored(data)) {
      logMessage = 'Internal event'

      EventsCache.store(eventName, data)
      data.isRemote = false
      broadcast(eventName, data)
    }
  }

  /**
   * Pusher events come in via the translate method.
   * If the event exists in the EventsTranslator service, it is broadcast to the app
   * after being transformed.
   */
  function translate(eventName, data) {
    const translatedData = EventsTranslator.translate(eventName, data)

    if (translatedData && !EventsCache.stored(eventName, translatedData)) {
      EventsCache.store(eventName, translatedData)
      translatedData.isRemote = true
      broadcast(eventName, translatedData)
    }
  }

  return service
}
