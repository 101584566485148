export function Controller(tracking) {
  'ngInject'

  let vm = this

  vm.control = {
    typeChange: typeChange
  }

  vm.type = 'activity'

  vm.$onInit = function () {}

  function typeChange() {
    tracking.trackEvent({ action: 'changeView', category: 'recordHistory', label: vm.type })
  }
}
