import * as React from 'react'
import { Text, Link } from 'reactxp'
// import linkifyIt from "linkify-it";
import { ViewStyleRuleSet } from 'reactxp/dist/common/Types'
import { LinkProps } from 'reactxp/src/common/Types'

const LinkifyIt = require('linkify-it')()

type Props = {
  text: string
  linkStyle?: ViewStyleRuleSet
  shortern?: boolean
  linkProps?: LinkProps
  textComponent: React.ReactElement<Text>
}

const Hyperlink = ({ textComponent, text, linkStyle, shortern = true, linkProps, ...componentProps }: Props) => {
  const defaultText = React.cloneElement(textComponent, componentProps, text)

  try {
    if (!LinkifyIt.pretest(text) || !LinkifyIt.test(text)) {
      return defaultText
    }

    const elements = [] as (React.ReactElement<Link> | string)[]
    let _lastIndex = 0

    LinkifyIt.match(text).forEach(({ index, lastIndex, text: linkText, url }) => {
      const nonLinkedText = text.substring(_lastIndex, index)

      if (nonLinkedText) {
        elements.push(nonLinkedText)
      }

      _lastIndex = lastIndex

      elements.push(
        <Link url={url} key={url + index} style={linkStyle} {...linkProps}>
          {shortern && url.length > 33 ? url.substr(0, 30) + '...' : linkText}
        </Link>
      )
    })

    elements.push(text.substring(_lastIndex, text.length))

    return React.cloneElement(textComponent, componentProps, elements)
  } catch (error) {
    return defaultText
  }
}

export default Hyperlink

// export class HyperlinkFoo extends React.Component<Props> {
//   render = () => {
//     const { linkStyle, ...viewProps } = this.props;

//     return <View {...viewProps}>{this.parse(this).props.children}</View>;
//   };

//   isTextNested = component => {
//     if (!React.isValidElement(component)) throw new Error("Invalid component");
//     let { type: { displayName } = {} } = component;
//     if (displayName !== "Text") throw new Error("Not a Text component");
//     return typeof component.props.children !== "string";
//   };

//   linkify = component => {
//     if (
//       !linkifyIt.pretest(component.props.children) ||
//       !linkifyIt.test(component.props.children)
//     )
//       return component;

//     let elements = [];
//     let _lastIndex = 0;

//     const componentProps = {
//       ...component.props,
//       ref: undefined,
//       key: undefined
//     };

// try {
// linkifyIt
//   .match(component.props.children)
//   .forEach(({ index, lastIndex, text, url }) => {
//     let nonLinkedText = component.props.children.substring(_lastIndex, index);
//     nonLinkedText && elements.push(nonLinkedText);
//     _lastIndex = lastIndex;
//     if (this.props.linkText)
//       text =
//         typeof this.props.linkText === "function"
//           ? this.props.linkText(url)
//           : this.props.linkText;

//     // const clickHandlerProps = {};
//     // if (OS !== 'web') {
//     //   clickHandlerProps.onLongPress = this.props.onLongPress
//     //     ? () => this.props.onLongPress(url, text)
//     //     : undefined
//     // }
//     // clickHandlerProps.onPress = this.props.onPress
//     //   ? () => this.props.onPress(url, text)
//     //   : undefined;

//     elements.push(
//       <Link
//         {...componentProps}
//         // {...clickHandlerProps}
//         key={url + index}
//         style={[component.props.style, this.props.linkStyle]}
//       >
//         {text}
//       </Link>
//     );
//   });
// elements.push(
//   component.props.children.substring(
//     _lastIndex,
//     component.props.children.length
//   )
// );
// return React.cloneElement(component, componentProps, elements);
//     } catch (err) {
//       return component;
//     }
//   };

//   parse = component => {
//     let { props: { children } = {}, type: { displayName } = {} } =
//       component || {};
//     if (!children) return component;

//     const componentProps = {
//       ...component.props,
//       ref: undefined,
//       key: undefined
//     };

//     return React.cloneElement(
//       component,
//       componentProps,
//       React.Children.map(children, (child: React.ReactChild) => {
//         let { type: { displayName } = {} } = child || {};
//         if (typeof child === "string" && linkifyIt.pretest(child))
//           return this.linkify(
//             <Text {...componentProps} style={component.props.style}>
//               {child}
//             </Text>
//           );
//         if (displayName === "Text" && !this.isTextNested(child))
//           return this.linkify(child);
//         return this.parse(child);
//       })
//     );
//   };
// }
