export function ptProfilePasswordRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('profile.password', {
    url: '/password',
    views: {
      'content@layoutFull': {
        component: 'ptProfilePassword'
      }
    },
    onEnter: onProfilePasswordEnter
  })

  function onProfilePasswordEnter(page, gettextCatalog) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Change password'))
  }
}
