import angular from 'angular'

//import './help-popover.html'

export function Controller(moment, ReportsData, filteringContext) {
  'ngInject'

  const $ctrl = this

  const defaultParams = {
    interval: 'P1W',
    segments: 8,
    include: 'descendants',
    frequencies: null
  }

  $ctrl.control = {
    chartClick,
    getProjectedChecks,
    setPreset,
    toggleFilterPanel,
    updateFrequency
  }

  $ctrl.presets = [
    {
      tag: 'week',
      name: '7 Days'
    },
    {
      tag: '8week',
      name: '8 Weeks'
    },
    {
      tag: 'halfyear',
      name: '6 Months'
    },
    {
      tag: 'year',
      name: '4 Quarters'
    }
  ]

  $ctrl.activePreset = '8week'
  $ctrl.actualData = []
  $ctrl.chartData = []
  $ctrl.dataset = []
  $ctrl.inpectionHelpPopover = 'components/pt-future-inspections-widget/help-popover.html'
  $ctrl.labelsData = []
  $ctrl.projectedData = []
  $ctrl.reports = []
  $ctrl.seriesData = ['Actual', 'Projected']
  $ctrl.loading = false

  $ctrl.chartDatasetOverride = [
    {
      label: 'Actual',
      borderWidth: 2,
      backgroundColor: 'rgba(24,166,120,0.6)'
    },
    {
      label: 'Projected',
      borderWidth: 2,
      backgroundColor: 'rgba(198,206,214,0.6)'
    }
  ]

  $ctrl.filterPanel = {
    isOpen: false
  }

  $ctrl.$onInit = function () {
    $ctrl.chartOptions = {
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              fontFamily: `Avenir, sans-serif`
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              fontFamily: `Avenir, sans-serif`,
              /**
               *  A return of undefined = no line drawn on graph
               *  params (value, index, values)
               */
              callback: function (value) {
                let int

                // Make sure floating numbers do not get rendered on the chart
                if (value % 1 === 0) {
                  int = value
                }

                return int
              }
            }
          }
        ]
      },
      tooltips: {
        titleMarginBottom: 10,
        bodySpacing: 10,
        titleFontFamily: `Avenir, sans-serif`,
        bodyFontFamily: `Avenir, sans-serif`,
        footerFontFamily: `Avenir, sans-serif`
      },
      title: {
        display: false
      },
      legend: {
        display: true,
        labels: {
          fontFamily: `Avenir, sans-serif`,
          fontSize: 14
        }
      }
    }

    $ctrl.control.getProjectedChecks()
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.widget) {
      $ctrl.widget = angular.copy($ctrl.widget)
    }
  }

  //////////////////////////////

  function getProjectedChecks() {
    $ctrl.loading = true

    ReportsData.getProjectedChecks(defaultParams)
      .then(buildDataSet)
      .finally(() => {
        $ctrl.loading = false
      })
  }

  function buildDataSet(data) {
    const labelsData = []
    const actualData = []
    const projectedData = []

    $ctrl.dataset = data

    data.forEach((set) => {
      // If displaying a daily graph, use one date. Otherwise display a date range.
      let dateString = () => {
        if (parseDate(set.start) === parseDate(set.end)) {
          return parseDate(set.start)
        }

        return `${parseDate(set.start)} - ${parseDate(set.end)}`
      }

      labelsData.push(dateString())
      actualData.push(set.count)
      projectedData.push(set.projected)
    })

    $ctrl.labelsData = labelsData
    $ctrl.actualData = actualData
    $ctrl.projectedData = projectedData

    updateChartData()
  }

  function updateChartData() {
    $ctrl.chartData = [$ctrl.actualData, $ctrl.projectedData]
  }

  function parseDate(date) {
    return moment(date).format('D MMM YY')
  }

  function setPreset(preset) {
    switch (preset) {
      case 'week':
        defaultParams.interval = 'P1D'
        defaultParams.segments = 7
        $ctrl.activePreset = 'week'
        break
      case '8week':
        defaultParams.interval = 'P1W'
        defaultParams.segments = 8
        $ctrl.activePreset = '8week'
        break
      case 'halfyear':
        defaultParams.interval = 'P1M'
        defaultParams.segments = 6
        $ctrl.activePreset = 'halfyear'
        break
      case 'year':
        defaultParams.interval = 'P3M'
        defaultParams.segments = 4
        $ctrl.activePreset = 'year'
        break
      default:
        defaultParams.interval = 'P1W'
        defaultParams.segments = 8
        $ctrl.activePreset = '8week'
    }

    $ctrl.control.getProjectedChecks()
  }

  function toggleFilterPanel() {
    $ctrl.filterPanel.isOpen = !$ctrl.filterPanel.isOpen
  }

  function updateFrequency(frequencies) {
    defaultParams.frequencies = frequencies
    $ctrl.filterPanel.isOpen = false
    $ctrl.control.getProjectedChecks()
  }

  /**
   * If a column has been clicked that has 'actual' data within it. Show those records.
   * @param  {Object[]} points An empty array, unless clicking on a column.
   */
  function chartClick(points) {
    let column
    let dataset
    let startDate
    let endDate
    let nextCheckAt

    if (points.length) {
      column = points[0]._index
      dataset = $ctrl.dataset[column]
      startDate = moment(dataset.start).format('YYYY-MM-DD')
      endDate = moment(dataset.end).format('YYYY-MM-DD')
      nextCheckAt = `next_check_at=${startDate}/${endDate}`

      if ($ctrl.actualData[column]) {
        filteringContext.applyFilter({ filter: nextCheckAt }, 'folders')
      }
    }
  }
}
