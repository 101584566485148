import _assign from 'lodash/assign'
import _cloneDeep from 'lodash/cloneDeep'
import _find from 'lodash/find'

export function Controller() {
  'ngInject'

  const checklistOptions = {
    readOnly: false,
    isExpanded: true,
    checklistVisible: true,
    canExpand: true,
    canDelete: true,
    editField: true
  }

  this.getSections = () => this.checklist.sections.data || this.checklist.sections

  this.$onInit = () => {
    this.getSections().forEach((section) => {
      section.$show = true
    })
  }

  this.$onChanges = (changes) => {
    if (changes.checklist) {
      this.checklist = _cloneDeep(this.checklist)
    }

    if (changes.checklistOptions) {
      this.checklistOptions = _assign(checklistOptions, this.checklistOptions)
    }
  }

  this.removeChecklist = () => {
    this.onRemoveChecklist({ checklistId: this.checklist.id })
  }

  this.toggleChecklistVisible = () => {
    checklistOptions.checklistVisible = !checklistOptions.checklistVisible
  }

  this.toggleSection = (sectionId) => {
    if (!this.checklistOptions.canExpand) {
      return
    }
    const section: any = _find(this.getSections(), { id: sectionId })

    if (section) {
      section.$show = !section.$show
    }
  }

  this.updateChecklistSection = (updatedSection) => {
    const section: any = _find(this.getSections(), { id: updatedSection.id })

    if (section) {
      section.fields.data = _assign([], updatedSection.fields.data)

      this.onUpdateChecklist({ checklist: this.checklist })
    }
  }
}
