import * as React from 'react'
import { ActivityTitle } from './activity-title'
import { ActivityContent } from './activity-content'
import { Activity } from '../'
import { Upload } from '../../upload/'
import { User } from '../../user/'
import { View, createStyledComponent } from '../../components/styled'
import { ActivityUploads } from './activity-uploads'

interface Props {
  activity: Activity
  viewType: string
  onPressActivity: (activity: Activity) => void
  onPressUpload: (upload: Upload, user: User) => void
}

const Wrapper = createStyledComponent(View, {
  overflow: 'visible'
})

export const ActivityTaskUploadWasDeleted = ({ activity, viewType, onPressActivity, onPressUpload }: Props) => {
  switch (viewType) {
    case 'simple':
      return (
        <Wrapper>
          <ActivityTitle activity={activity} onPressActivity={onPressActivity}>
            Upload was deleted
          </ActivityTitle>
          <ActivityContent>
            <ActivityUploads action="from" activity={activity} onPressUpload={onPressUpload} />
          </ActivityContent>
        </Wrapper>
      )
  }
}
