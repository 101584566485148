export function SubscriptionRoute($stateProvider) {
  'ngInject'

  const uuid = '(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})'

  $stateProvider.state('subscription.**', {
    url: `/subscriptions/{subscriptionId:${uuid}}`,
    lazyLoad: ($transition$) => {
      const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')

      return import(/* webpackChunkName: "subscription" */ '../components/pt-subscription')
        .then((mod) => {
          return $ocLazyLoad.inject(mod.default)
        })
        .catch((err) => {
          throw new Error('LazyLoad Error: ' + err)
        })
    }
  })
}
