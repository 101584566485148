import _clone from 'lodash/clone'
import _cloneDeep from 'lodash/cloneDeep'
import _isMatch from 'lodash/isMatch'

export function Controller($scope) {
  'ngInject'

  const $ctrl = this
  let selectedCurrency = 'GBP'

  $ctrl.control = {
    currency,
    saveMoneyField
  }

  $ctrl.initialValue = null

  $ctrl.$onInit = function () {
    $ctrl.initialValue = _clone($ctrl.field.value)

    watch()
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.field) {
      $ctrl.field = _cloneDeep($ctrl.field)

      if ($ctrl.field.value) {
        $ctrl.initialValue = _clone($ctrl.field.value)
      }
    }

    if (changes.editable) {
      $ctrl.editable = _clone($ctrl.editable)
    }
  }

  function watch() {
    $scope.$watch('$ctrl.field.value', function () {
      if ($ctrl.field.value) {
        $ctrl.initialValue = _clone($ctrl.field.value)
      }
    })
  }

  function saveMoneyField(value) {
    let result

    // If this is previously saved, but now has no value, then its to be removed
    if ($ctrl.field.id && value && !value.value) {
      value = null
    }

    if (
      ($ctrl.initialValue !== value && ($ctrl.initialValue === null || value === null)) ||
      !_isMatch($ctrl.initialValue, { currency: value && value.currency, value: value && value.value })
    ) {
      result = $ctrl.onSave({ value: value })
    }

    return result
  }

  function currency(value) {
    if (value) {
      selectedCurrency = value
    }

    return selectedCurrency
  }
}
