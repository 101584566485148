import _find from 'lodash/find'
import { ProductSearchField } from 'AppCore/product'
import angular from 'angular'

export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    getProductIdentifiers,
    getProductName
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.products) {
      $ctrl.products = angular.copy($ctrl.products)
    }
  }

  function getProductIdentifiers(product) {
    let productIdentifiers = []

    product.fields.forEach((field) => {
      // Create a string of identifiers for the view, to help the user see what product this is
      if (field.type === 'identifier') {
        productIdentifiers.push(field.value)
      }
    })

    return productIdentifiers.join(', ')
  }

  function getProductName(product) {
    return _find(product.fields, <ProductSearchField>{ key: 'name' }).value
  }
}
