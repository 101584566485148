import { Controller } from './controller'
import view from './view.html'

export const ptIauditorUploadItem = {
  bindings: {
    audit: '<'
  },
  controller: Controller,
  template: view
}
