import _forEach from 'lodash/forEach'

export function Controller($scope, recordsSelection, DefinitionsData, tracking) {
  'ngInject'

  const setSortableColumns = () => {
    _forEach(this.cols, (col) => {
      if (!DefinitionsData.getSortExcludes().includes(col.key)) {
        col.sortable = true
      }
    })
  }

  const watchSelection = () => {
    $scope.$watchCollection(
      () => {
        return recordsSelection.current()
      },
      () => {
        this.allSelected = recordsSelection.allSelected()
      }
    )
  }

  this.$onInit = () => {
    this.allSelected = false

    watchSelection()
    setSortableColumns()

    $scope.$watch('vm.cols', setSortableColumns)
  }

  this.allSelectionChange = () => {
    recordsSelection.setAll(this.allSelected)
  }
  

  this.sortColumn = (column) => {
    tracking.trackEvent({ action: 'sortByColumn', category: 'sortRecords', label: column.key })

    const selectedColumn = { ...column }
    const allColumns = DefinitionsData.getAllColumns()
    const visibleColumns = DefinitionsData.getVisibleColumnsAsArray()
    const visibleColumn = visibleColumns.find((col) => col.name === selectedColumn.name)

    // Un-set the column order as a new sort is being applied
    visibleColumns.forEach((col) => {
      col.order = 0
    })

    allColumns.forEach((col) => {
      // Un-set all columns order as we just want to order to the column we clicked
      col.order = 0

      if (visibleColumn.name === col.label) {
        const order = 1
        const direction = selectedColumn.order !== 1 ? 'ASC' : visibleColumn.direction === 'ASC' ? 'DESC' : 'ASC'

        col.order = order
        col.direction = direction

        visibleColumn.order = order
        visibleColumn.direction = direction
      }
    })

    DefinitionsData.setVisibleColumns({ definitions: visibleColumns, setDefault: true })
  }
}
