import angular from 'angular'
import { ptSummariesUnsubscribeModule } from './components/pt-summaries-unsubscribe'

import { ptSummariesTableModule } from './directives/pt-summaries-table'
import { ptSummariesTableHeadModule } from './directives/pt-summaries-table-head'
import { ptSummariesTableRowModule } from './directives/pt-summaries-table-row'

import { summariesData } from './services/data'
import { SummaryModel } from './services/models'

export const SummariesModule = angular
  .module('summaries', [
    // components
    ptSummariesUnsubscribeModule,

    // directive
    ptSummariesTableModule,
    ptSummariesTableHeadModule,
    ptSummariesTableRowModule
  ])
  .factory('summariesData', summariesData)
  .factory('SummaryModel', SummaryModel).name
