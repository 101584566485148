export function dateformat(moment, session, angularMomentConfig) {
  'ngInject'

  const dateformatFilter = (value, format) => {
    let date = moment(value)
    let timezone = angularMomentConfig.timezone

    if (session && session.user) {
      timezone = session.user.timezone
    }

    if (!date.isValid() || typeof value === 'undefined') {
      return ''
    }

    return moment.tz(date, timezone).format(format)
  }

  return dateformatFilter
}
