export function Controller(permissionsCheck, context) {
  'ngInject'

  let vm = this

  vm.account = context.get('account')

  vm.permissionsCheck = permissionsCheck
  vm.shownForm = null
  vm.isViewOnly = permissionsCheck.is('viewOnly', vm.account)
  //////////

  vm.$onInit = function () {}
}
