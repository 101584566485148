import _find from 'lodash/find'
import { ProductSearchField } from 'AppCore/product'

export function Controller($scope, accountsData, $state) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    getProductName,
    nextStep
  }

  $ctrl.accounts = null
  $ctrl.selectedAccount = null

  $ctrl.$onInit = () => {
    $ctrl.loading = true
    getAccounts()

    $scope.$on('account_was_created', (event, data) => getAccounts(true, data.accountId))
  }

  $ctrl.$onChanges = () => {}

  function getAccounts(clearCache = false, accountId?) {
    accountsData
      .getAll(accountsData.init(), clearCache)
      .then((response) => {
        $ctrl.accounts = response

        if ($ctrl.accounts.length === 1) {
          $ctrl.selectedAccount = $ctrl.accounts[0]
        }

        // New account created, select it.
        if ($ctrl.accounts.length > 1 && accountId) {
          $ctrl.accounts.forEach((account) => {
            if (account.id === accountId) {
              $ctrl.selectedAccount = account
            }
          })
        }
      })
      .catch(() => {})
      .finally(() => {
        $ctrl.loading = false
      })
  }

  function getProductName(product) {
    return _find(product, <ProductSearchField>{ key: 'name' }).value
  }

  function nextStep() {
    if ($ctrl.selectedAccount) {
      $state.go('.folders', { accountId: $ctrl.selectedAccount.id })
    }
  }
}
