import angular from 'angular'
import { react2angular } from 'react2angular'
import { ptTaskSidebar } from './component'
import { TaskDetails } from 'AppCore/task/components'

export const TaskSidebar = angular
  .module('tasks.pt-task-sidebar', [])
  .component('ptTaskSidebar', ptTaskSidebar)
  .component(
    'ptTaskDetails',
    react2angular(TaskDetails, [
      'task',
      'isAdmin',
      'editable',
      'sessionUser',
      'onPressAccountName',
      'onPressInspection',
      'onPressRecordName',
      'onPressLogAssign',
      'onPressDeadline',
      'onPressAssignees',
      'onPressTitleBody',
      'onUserUpdated',
      'onPressUpload',
      'onAddTaskUploads',
      'onDeleteTaskUploads',
      'taskStates'
    ])
  ).name
