import angular from 'angular'
import { ImportsData } from './services/data'
import { ImportsModel } from './services/models'
import { ptImportsModule } from './components/pt-imports'
import { ptImportsAccountsModule } from './components/pt-imports-accounts'
import { ptImportsCompleteModule } from './components/pt-imports-complete'
import { ptImportsCrumbsModule } from './components/pt-imports-crumbs'
import { ptImportsCycleNavModule } from './components/pt-imports-cycle-nav'
import { ptImportsDataMapModule } from './components/pt-imports-data-map'
import { ptImportsFoldersModule } from './components/pt-imports-folders'
import { ptImportsImportModule } from './components/pt-imports-import'
import { ptImportsNewModule } from './components/pt-imports-new'
import { ptImportsProductListModule } from './components/pt-imports-product-list'
import { ptImportsRecordPreviewModule } from './components/pt-imports-record-preview'

export const ImportsModule = angular
  .module('imports', [
    ptImportsModule,
    ptImportsAccountsModule,
    ptImportsCompleteModule,
    ptImportsCrumbsModule,
    ptImportsCycleNavModule,
    ptImportsDataMapModule,
    ptImportsFoldersModule,
    ptImportsImportModule,
    ptImportsNewModule,
    ptImportsProductListModule,
    ptImportsRecordPreviewModule
  ])
  .factory('ImportsData', ImportsData)
  .factory('ImportsModel', ImportsModel).name
