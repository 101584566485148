import { Controller } from './controller'
import view from './view.html'

export function ptRootfolderDropdownCrumb() {
  let directive = {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'A',
    scope: {}
  }

  return directive
}
