export function ptActivityRoute($stateProvider) {
  'ngInject'

  let activityModal

  recordActivityViewState('account.records')
  recordActivityViewState('account')
  recordActivityViewState('folder.records')
  recordActivityViewState('folder')
  recordActivityViewState('folders.records')
  recordActivityViewState('folders')
  recordActivityViewState('activities.records')
  recordActivityViewState('activities')

  taskActivityViewState('account.tasks.task')
  taskActivityViewState('account.records.task')
  taskActivityViewState('folders.records.task')
  taskActivityViewState('folder.records.task')
  taskActivityViewState('tasks.task')

  function recordActivityViewState(parent) {
    $stateProvider.state(parent + '.activity', {
      url: '/activities/{activityId}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        activity: resolveRecordActivity
      },
      onEnter: onActivityEnter,
      onExit: onActivityExit
    })
  }

  function taskActivityViewState(parent) {
    $stateProvider.state(parent + '.activity', {
      url: '/activities/{activityId}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        activity: resolveTaskActivity
      },
      onEnter: onActivityEnter,
      onExit: onActivityExit
    })
  }

  function onActivityEnter($uibModal, $state, activity, page) {
    'ngInject'

    page.setTitle('Activity Details')

    activityModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptActivity',
      resolve: {
        activity: activity
      },
      size: 'activities',
    })

    activityModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onActivityExit(page) {
    'ngInject'
    page.resetTitle()
    activityModal.close()
  }

  function resolveRecordActivity(account, ActivitiesData, $stateParams) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    return ActivitiesData.get(account.id, $stateParams.activityId, {
      include:
        'checklist_completions,checklist_completions.sections.fields.uploads,checklist_completions.sections.fields.response_uploads,partial_completions,partial_completions.sections.fields.uploads,partial_completions.sections.fields.response_uploads'
    })
  }

  function resolveTaskActivity(task, ActivitiesData, $stateParams) {
    'ngInject'

    return ActivitiesData.get(task.account.id, $stateParams.activityId)
  }
}
