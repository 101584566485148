export function ptCurrency($filter) {
  'ngInject'

  const isInt = function (n) {
    return n % 1 === 0
  }

  const ptCurrencyFilter = (value) => {
    return $filter('currency')(value, '£', isInt(value) ? 0 : 2)
  }

  return ptCurrencyFilter
}
