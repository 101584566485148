export const DateFormat = () => {
  'ngInject'

  const service = {
    toUib
  }

  return service

  function toUib(dateFormat) {
    switch (dateFormat) {
      case 'Do MMM YYYY':
      case 'DD/MM/YYYY':
        return 'dd/MM/yyyy'
      case 'DD.MM.YYYY':
        return 'dd.MM.yyyy'
      case 'MM/DD/YYYY':
        return 'MM/dd/yyyy'
      default:
        return 'yyyy-MM-dd'
    }
  }
}
