import { Controller } from './controller'
import view from './view.html'

export const ptImportsProductList = {
  bindings: {
    products: '<'
  },
  controller: Controller,
  template: view
}
