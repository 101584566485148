import angular from 'angular'

export function Controller($state, context) {
  'ngInject'

  const $ctrl = this
  const account = context.get('account')
  const taskStates = account?.task_states?.data

  $ctrl.control = {
    markAsRead,
    openRecord,
    openTask,
    parseState
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.notification) {
      $ctrl.notification = angular.copy($ctrl.notification)
    }

    if (changes.view) {
      $ctrl.view = angular.copy($ctrl.view)
    }
  }

  function markAsRead(event) {
    // Send our own onRead event back up to our components
    $ctrl.onRead()
    // Prevent clicks from firing on parent components. e.g.:
    // The notification page has a ui-sref which would open a modal.
    event.stopPropagation()
  }

  function openRecord() {
    $state.go('account.records', {
      accountId: $ctrl.notification.account.data.id,
      recordId: $ctrl.notification.meta.record.id
    })
  }

  function openTask() {
    $state.go('account.records.task', {
      accountId: $ctrl.notification.account.data.id,
      recordId: $ctrl.notification.meta.record.id,
      taskId: $ctrl.notification.meta.task.id
    })
  }

  function parseState(state) {
    const systemState = taskStates?.find((t) => t.key === state)
    return systemState ? systemState.name : 'UNDEFINED'
  }
}
