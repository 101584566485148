import _debounce from 'lodash/debounce'
import _reject from 'lodash/reject'

export function Controller($scope, accountsData, filtersData, filteringContext, context) {
  'ngInject'

  let vm = this

  vm.control = {
    applyFilter: applyFilter
  }

  vm.filters = null

  ////////////

  vm.$onInit = function () {
    let debounce = _debounce(setup, 500)

    setup()

    $scope.$on('access_was_created', debounce)
    $scope.$on('access_was_updated', debounce)
    $scope.$on('access_was_deleted', debounce)
  }

  function setup() {
    let account = context.get('account')

    if (vm.account) {
      vm.filters = _reject(vm.account.filters, 'default')
    } else {
      fetchCounts(account.folder.id, account)
    }
  }

  function fetchCounts(folderId, account) {
    return filtersData.fetchCounts(folderId, account).then(function (filters) {
      vm.filters = _reject(filters, 'default')
      return filters
    })
  }

  function applyFilter(filter) {
    let rules = filtersData.extractRules(filter.rules.data)

    if (vm.account) {
      rules.accountId = vm.account.id
    }

    filteringContext.applyFilter(rules, 'folders')
  }
}
