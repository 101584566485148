// TODO Once more of the models have been created, improve typing here.

import * as moment from 'moment-timezone'
import { find } from 'lodash'
import { cloneDeep } from 'lodash'
import { includes } from 'lodash'
import { rrulestr } from 'rrule'
import { ProductSearch, ProductSearchMatch } from '../index'

const createCommentString = (fields: any[]) =>
  fields
    .reduce((comments, field) => {
      if (field.recordKey === 'comments') {
        comments.push(`${field.label}: ${field.value}`)
      }
      return comments
    }, [])
    .join(',\n')
    .trim()

export const createFakeRecords = (mappings: any[]) =>
  mappings.reduce((records, mapping, index) => {
    const record = cloneDeep(mapping)

    records.push({
      index,
      position: index + 1,
      $editable: false,
      name: record.name,
      frequency: record.frequency,
      next_check_at: new Date(),
      notify_at: new Date(),
      uploads: record.uploads,
      fields: record.productFields
        .filter((productField) => productField.recordKey !== 'ignore' && productField.recordKey !== 'comments')
        .map((productField) => {
          // Find the record field that matches the selection (productField.recordKey)
          // The recordField.id check is to make sure we always prefer an existing record field over a
          // newly created one.
          const findFn = (recordField) => {
            return recordField.key === productField.recordKey && !!recordField.id
          }

          const item = find(productField.recordFields, findFn)

          return {
            // Use the record fields label/type where possible, fallback to products own if needed
            label: (item && item.label) || productField.label,
            type: (item && item.type) || productField.type,
            value: productField.type === 'array' ? productField.value.map((text) => ({ text })) : productField.value
          }
        }),
      comments: createCommentString(record.productFields.filter((field) => field.recordKey !== 'ignore'))
    })

    return records
  }, [])

const autoMapFields = (mappings: any) =>
  mappings.map((mapping) => {
    mapping.productFields.forEach((productField) => {
      productField.recordFields.forEach((recordField) => {
        if (fieldsDoMatch(recordField, productField) && typesDoMatch(recordField.type, productField.type)) {
          productField.recordKey = recordField.key
        }
      })
    })

    return mapping
  })

const fieldsDoMatch = (recordField: any, productField: any) =>
  recordField.key === productField.key || recordField.label.toLowerCase() === productField.label.toLowerCase()

/**
 * This function looks at the type of each field and does a custom type match for importing
 * Some fields may not have a type of string, but really are strings, which this function checks against.
 */
const typesDoMatch = (recordFieldType: string, productFieldType = 'string') => {
  let recordType = recordFieldType
  let productType = productFieldType

  // e.g. 'Add to comments' and 'Do not add to record'
  if (recordFieldType === 'ignore') {
    return true
  }

  // All types in the below array can be mapped to each other
  const typesEqualToString = ['barcode', 'identifier', 'integer', 'rfid', 'string', 'textarea', null]

  // For comparison, change types that are displayed as strings in the view to a string type
  // This will allow the user to map the product field to the record field
  if (includes(typesEqualToString, productFieldType)) {
    productType = 'string'
  }

  if (includes(typesEqualToString, recordFieldType)) {
    recordType = 'string'
  }

  return recordType === productType
}

export const createMappings = (importData: any, frequencies: any, blueprint: any) =>
  autoMapFields(
    importData.records.data.reduce((mappings, product, index) => {
      let productName
      const productIdentifiers = []
      let productFields
      const uploads = createRecordUploads(product.fields)
      let freqRecommendation = {
        toText: () => 'no data provided',
        toString: () => (find(frequencies, { default: true }) as any).rule
      }
      let frequency = find(frequencies, {
        rule: freqRecommendation.toString() !== null ? freqRecommendation.toString().replace('RRULE:', '') : null
      })

      product.fields.forEach((productField) => {
        // Set the default mapping to itself
        productField.recordKey = productField.key

        // Automatically map the name field
        if (productField.key === 'name') {
          productName = productField.value
        }

        // Create a string of identifiers for the view, to help the user see what product this is
        if (productField.type === 'identifier') {
          productIdentifiers.push(productField.value)
        }

        if (productField.key === 'inspection_frequency') {
          if (productField.value) {
            freqRecommendation = rrulestr(productField.value)
            frequency = find(frequencies, { rule: freqRecommendation.toString().replace('RRULE:', '') })
          } else {
            freqRecommendation = {
              toText: () => 'None',
              toString: () => null
            }
            frequency = find(frequencies, { rule: null })
          }
        }

        if (productField.type === 'date') {
          productField.value = moment(productField.value).format('YYYY-MM-DD')
        }

        productField.recordFields = mergeRecordFieldSections(productField, blueprint.sections.data)
      })

      // Remove any fields that don't have a value as there is no need to map them
      productFields = product.fields.filter((field) => field.value)

      mappings.push({
        index: index + 1,
        name: productName,
        product_id: product.id,
        identifiers: productIdentifiers.join(', '),
        uploads,
        freqRecommendation: freqRecommendation.toText(),
        frequency,

        // Remove fields from the productFields that are not part of a record blueprint
        // We store them above
        productFields: productFields.filter(
          (field) => !includes(['images', 'uploads', 'inspection_frequency', 'name'], field.key)
        )
      })

      return mappings
    }, [])
  )

const createRecordUploads = (productFields: any) => {
  const results = []
  const images: any = find(productFields, { key: 'images' })
  const uploads: any = find(productFields, { key: 'uploads' })

  images.value.forEach((image) => {
    results.push({
      type: 'url',
      value: image,
      resized: getImageResizeUrl(image),
      mime: getMimeType(image),
      name: getFilename(image)
    })
  })

  uploads.value.forEach((upload) => {
    results.push({
      type: 'url',
      value: upload,
      mime: getMimeType(upload),
      name: getFilename(upload)
    })
  })

  return results
}

const getFilename = (url) => url.split('/').pop()

const getImageResizeUrl = (url) =>
  url.replace(
    's3-eu-west-1.amazonaws.com/pt-manufacturers-products',
    'manufacturers-products-images.papertrail.io/100x100'
  )

const getMimeType = (url) => {
  const ext = url.split('.').pop()

  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'webp':
      return `image/${ext}`
    case 'pdf':
    case 'zip':
      return `application/${ext}`
    case 'doc':
    case 'docx':
      return 'application/msword'
    case 'xls':
    case 'xlsx':
      return 'application/vnd.ms-excel'
    case 'ppt':
    case 'pptx':
      return 'application/vnd.ms-powerpoint'
    default:
      return 'default'
  }
}

// Create an array of record field keys that are already selected
const getSelectedFields = (mapping: any) =>
  mapping.productFields.reduce((results, productField) => {
    if (productField.recordKey && !includes(['ignore', 'comments'], productField.recordKey)) {
      results.push(productField.recordKey)
    }
    return results
  }, [])

export const mappingFieldChange = (mapping: any) => {
  const fieldsMapped = getSelectedFields(mapping)

  mapping.productFields.forEach((productField) => {
    // Disable the record keys that are selected and enable any others
    productField.recordFields.forEach((recordField) => {
      // We need to keep the current selected record value enabled for this product field,
      // or AngularJS will null value it.
      if (productField.recordKey === recordField.key) {
        recordField.disabled = false
      } else if (fieldsMapped.indexOf(recordField.key) >= 0 || !typesDoMatch(recordField.type, productField.type)) {
        recordField.disabled = true
      } else if (recordField.key !== 'divider') {
        recordField.disabled = false
      }
    })
  })
}

const mergeRecordFieldSections = (productField, blueprintSections: any[]) => {
  return cloneDeep(blueprintSections).reduce(
    (fields, section) => {
      if (section.fields && section.fields.data) {
        return fields.concat(section.fields.data)
      }
      return fields
    },
    [
      {
        key: productField.key,
        label: `${productField.label} *`,
        type: productField.type
      },
      {
        key: 'comments',
        label: 'Add to comments',
        type: 'ignore'
      },
      {
        key: 'ignore',
        label: 'Do not add to Record',
        type: 'ignore'
      },
      {
        key: 'divider',
        label: '--------------------',
        disabled: true,
        type: 'NothingCanEverMatchThisString'
      }
    ]
  )
}

const prepFieldsForAPI = (fields: any) =>
  fields
    .filter((field) => !includes(['ignore', 'comments'], field.recordKey))
    .map((field) => ({
      value: field.value,
      key: field.recordKey,
      type: field.type,
      label: field.label
    }))

// Prepare the data for import
export const prepRecordsForAPI = (mappings: any[], stateId?: string) =>
  mappings.reduce((records, mapping) => {
    // Create the data object that will be POSTed to the API
    records.push({
      name: mapping.name,
      // Remove all fields that are being mapped to comments or are ignored
      fields: prepFieldsForAPI(mapping.productFields),
      frequency: {
        name: mapping.frequency.name,
        rule: mapping.frequency.rule,
        notify: mapping.frequency.notify
      },
      state_id: stateId,
      // Create a string of all of the product fields that are being mapped to the record comments field.
      comments: createCommentString(mapping.productFields),
      uploads: mapping.uploads,
      product_id: mapping.product_id
    })

    return records
  }, [])

const ASTERISK_DELIMITER_PATTERN = /\*\*([^*]*)\*\*/g
const ASTERISK_PATTERN = /\*/g

export const transformMatchedField = (key: string, field: string, search: ProductSearch, transform: string) => {
  const matches: ProductSearchMatch[] = search.matches.filter((match) => match.key === key)
  if (matches.length === 0) {
    return field
  }

  return matches[0].highlight.replace(ASTERISK_DELIMITER_PATTERN, transform)
}

// TODO WEB_ONLY
/**
 * Generate a highlighted search term value to show in the view
 * @param {Object} result
 */
export const generateHighlightedValues = (result: any) => {
  const wrapWithStrongTag = '<strong class="matchTerm">$&</strong>'

  const parseHighlight = (highlight) =>
    highlight.replace(ASTERISK_DELIMITER_PATTERN, wrapWithStrongTag).replace(ASTERISK_PATTERN, '')

  result.matches.data.forEach((match) => {
    switch (match.key) {
      case 'name':
      case 'manufacturer':
        result[`${match.key}Html`] = parseHighlight(match.highlight)
        break

      default:
        match.html = parseHighlight(match.highlight)
        break
    }
  })
}
