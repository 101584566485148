import { createResource } from '../../api/resource'
import { transformCamelToSnake, createResponseTransformer } from '../../api/transformer'
import { Task } from '../index'

interface FetchTaskQuery {
  include?: string
}

interface Params {
  taskId: UUID
  query?: FetchTaskQuery
}

export const fetchTask = createResource({
  request: ({ taskId, query }: Params) => ({
    url: `tasks/${taskId}`,
    method: 'GET',
    params: transformCamelToSnake(query)
  }),
  transform: createResponseTransformer<Task>('record', 'user', 'source', 'assignees.user', 'uploads', 'account', 'log')
})
