import { Controller } from './controller'
import view from './view.html'

export const ptExportCertificate = {
  bindings: {
    onCloseModal: '&',
    type: '<',
    batch: '<',
    recordIds: '<',
    certificates: '<',
    userSignatures: '<',
    params: '<'
  },
  template: view,
  controller: Controller
}
