import * as React from 'react'
import { View } from 'reactxp'
import { Task } from '../models'
import { createTaskUpload, deleteTaskUpload } from '../api'
import { UploadThumbnails } from '../../upload/components'
import { AccountUpload } from '../../upload'

interface Props {
  task: Task
  onAddTaskUploads?: (files: AccountUpload[]) => void
  onDeleteTaskUploads?: (files: AccountUpload[]) => void
  onPressUpload?: (upload: AccountUpload) => void
  editable: boolean
}

export const TaskDetailUploads = ({
  task,
  onAddTaskUploads,
  onDeleteTaskUploads,
  onPressUpload,
  editable
}: Props) => {
  const [taskUploads, setTaskUploads] = React.useState([])

  React.useEffect(() => {
    setTaskUploads(task.uploads)
  }, [])

  const addUpload = async (files) => {
    const newUploads = files.filter((file) => {
      const result = taskUploads.every((upload) => {
        return upload.id !== file.id
      })

      return result
    })

    const newTaskUploads =
      newUploads.length &&
      newUploads.map((file) => {
        return {
          type: 'upload_id',
          value: file.id
        }
      })

    if (newTaskUploads.length) {
      try {
        await createTaskUpload({
          taskId: task.id,
          payload: {
            ...(newTaskUploads.length && { files: newTaskUploads })
          }
        })

        const uploads = [...taskUploads, ...newUploads]

        setTaskUploads(uploads)
        onAddTaskUploads(uploads)
      } catch {
        //
      }
    }
  }

  const deleteUpload = async (uploadId: string) => {
    const taskUploadsCopy = [...taskUploads]

    const newFiles = taskUploads.filter((currentFile: AccountUpload) => {
      return currentFile.id !== uploadId
    })

    setTaskUploads(newFiles)

    try {
      await deleteTaskUpload({
        taskId: task.id,
        uploadId
      })

      onDeleteTaskUploads(newFiles)
    } catch (e) {
      setTaskUploads(taskUploadsCopy)
    }
  }

  const onChangeFiles = (files: AccountUpload[]) => {
    addUpload(files)
  }

  return (
    <View>
      <UploadThumbnails
        accountId={task.account.id}
        uploads={taskUploads}
        onChangeFiles={onChangeFiles}
        itemsPerRow={4}
        onDeleteUpload={deleteUpload}
        onPressUpload={onPressUpload}
        editable={editable}
      />
    </View>
  )
}
