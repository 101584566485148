import { PapertrailSession } from '@papertrail/web3-session'

export function Controller($window, Upload, accountsData, context) {
  'ngInject'

  let vm = this

  vm.control = {
    selected: selected,
    save: save,
    cancel: cancel,
    remove: remove
  }

  vm.image = null
  vm.previewUrl = null
  vm.uploading = false

  //////////

  vm.$onInit = function () {}

  function selected(files) {
    if (files.length) {
      vm.image = files[0]

      Upload.dataUrl(vm.image, true).then(function (url) {
        vm.previewUrl = url
      })
    }
  }

  function save() {
    vm.uploading = true

    accountsData
      .uploadLogo(vm.image, vm.account.id)
      .then(function (data) {
        vm.image = null
        vm.previewUrl = null
        vm.uploading = false
        vm.account.logo = data.data.data.logo

        let accData = context.get('account')
        accData.logo = vm.account.logo
        context.set('account', accData)
        PapertrailSession.reloadAccount()
      })
      .catch(function () {})
  }

  function cancel() {
    vm.uploading = false
    vm.image = null
    vm.previewUrl = null
  }

  function remove() {
    accountsData.removeLogo(vm.account.id).then(function () {
      $window.location.reload()
    })
  }
}
