import { Controller } from './controller'
import view from './view.html'

export function ptRecordsTableFieldState() {
  'ngInject'

  let directive = {
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      record: '<'
    }
  }

  return directive
}
