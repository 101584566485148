import _extend from 'lodash/extend'

export function ApiModelActions(restmod) {
  'ngInject'

  let model = restmod.mixin({
    $extend: {
      Record: {
        // issues DELETE request
        $deleteAndReplace: function (replace) {
          return this.$action(function () {
            let url = this.$url('destroy')
            if (url) {
              let request = { method: 'DELETE', url: url }

              this.$dispatch('before-destroy', [request]).$send(
                request,
                function (_response) {
                  _extend(this, replace)
                  this.$pk = null
                  if (this.id) {
                    this.id = null
                  }

                  this.$dispatch('after-destroy', [_response])
                },
                function (_response) {
                  this.$dispatch('after-destroy-error', [_response])
                }
              )
            } else if (this.$scope.$remove) {
              this.$scope.$remove(this)
            }
          })
        }
      }
    }
  })

  return model
}
