import view from './view.html'

export function helpBlock(formlyConfigProvider) {
  'ngInject'

  formlyConfigProvider.setWrapper([
    {
      name: 'helpBlock',
      template: view
    }
  ])
}
