import { Controller } from './controller'
import view from './view.html'

export function ptDate() {
  'ngInject'

  let directive = {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'E',
    scope: {
      value: '='
    }
  }

  return directive
}
