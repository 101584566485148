export function Controller($uibModal) {
  'ngInject'

  this.openVideo = () => {
    $uibModal
      .open({
        component: 'ptTutorialsVideo',
        resolve: {
          video: () => 'https://cdn.papertrail.io/images/tutorials/new-inspection.mp4'
        },
        windowClass: 'tutorials-video'
      })
      .result.catch(function () {})
  }
}
