import * as React from 'react'
import { useEffect } from 'react'
import * as moment from 'moment-timezone'
import { Text, View } from 'reactxp'
import { Task } from '../models/index'
import { Button, PTDate } from '../../components'
import { createStyledView } from '../../components/styled'
import { colors, spacing } from '../../styles'
import { User } from '../../user/index'
import { TaskDetailRecord } from './task-detail-record'
import { PersonDisplay } from '../../user/components'
import { TaskAssignees } from './task-assignees'
import { TaskSectionTitle } from './task-section-title'
import { TaskDetailUploads } from './task-detail-uploads'
import TaskStateBadge from './task-state-badge'
import { AccountUpload } from '../../upload'
import Hyperlink from '../../components/hyperlink'
import { TaskState } from 'AppCore/activity'

interface TaskDetailsProps {
  task: Task
  isAdmin: boolean
  editable: boolean
  sessionUser: User
  taskStates: TaskState[]
  onPressAccountName?: () => void
  onPressRecordName?: () => void
  onPressInspection?: () => void
  onPressLogAssign?: () => void
  onPressDeadline?: () => void
  onPressAssignees?: () => void
  onPressStateChange?: () => void
  onPressTitleBody?: () => void
  onPressUpload?: () => void
  onUserUpdated?: () => void
  onAddTaskUploads?: (uploads: AccountUpload[]) => void
  onDeleteTaskUploads?: (uploads: AccountUpload[]) => void
}

const TaskInfoSlot = createStyledView({
  padding: 20,
  borderBottomWidth: 1,
  borderColor: colors.neutralTintSix
})

const TaskDetails = ({
  task,
  isAdmin,
  editable,
  sessionUser,
  onPressAccountName,
  onPressRecordName,
  onPressInspection,
  onPressLogAssign,
  onPressDeadline,
  onPressAssignees,
  onPressTitleBody,
  onPressUpload,
  onUserUpdated,
  onAddTaskUploads,
  onDeleteTaskUploads,
  taskStates
}: TaskDetailsProps) => {
  const taskIsOverdue = moment(task.deadlineAt).isBefore(new Date())

  // If there are assignees on the task already, set them to selected.
  const taskUsers = task.assignees.reduce((assigneeUsers, assignee) => {
    assigneeUsers.push(assignee.user)

    return assigneeUsers
  }, [])

  return (
    <>
      <TaskInfoSlot>
        <TaskSectionTitle>TASK DETAILS:</TaskSectionTitle>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 1, marginRight: spacing.base }}>
            <Text style={{ marginBottom: spacing.half }}>{task.title}</Text>
            {task.body && <Hyperlink text={task.body} textComponent={<Text />} />}
          </View>
          {!!isAdmin && (
            <Button
              label={'Edit'}
              theme={{
                labelColor: 'neutral',
                backgroundColor: 'white',
                borderColor: 'neutralTintFive'
              }}
              onPress={onPressTitleBody}
            />
          )}
        </View>
      </TaskInfoSlot>

      <TaskInfoSlot>
        <TaskSectionTitle>ACCOUNT:</TaskSectionTitle>
        <Text style={{ color: colors.secondary, alignSelf: 'flex-start' }} onPress={() => onPressAccountName()}>
          {task.account.name}
        </Text>
      </TaskInfoSlot>

      <TaskInfoSlot>
        <TaskSectionTitle>RECORD:</TaskSectionTitle>
        <TaskDetailRecord
          task={task}
          onPressRecordName={onPressRecordName}
          onPressLogAssign={onPressLogAssign}
          onPressInspection={onPressInspection}
          editable={editable}
        />
      </TaskInfoSlot>

      <TaskInfoSlot>
        <TaskSectionTitle>STATE:</TaskSectionTitle>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flexGrow: 1 }}>
            <TaskStateBadge state={task.state} name={task.taskStateName} />
          </View>
        </View>
      </TaskInfoSlot>

      <TaskInfoSlot>
        <TaskSectionTitle>UPLOADS:</TaskSectionTitle>
        <TaskDetailUploads
          task={task}
          onPressUpload={onPressUpload}
          onAddTaskUploads={onAddTaskUploads}
          onDeleteTaskUploads={onDeleteTaskUploads}
          editable={editable}
        />
      </TaskInfoSlot>

      <TaskInfoSlot>
        <TaskSectionTitle>DEADLINE:</TaskSectionTitle>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <PTDate
            style={{
              flexGrow: 1,
              ...(taskIsOverdue && { color: colors.negative })
            }}
            user={sessionUser}
            dateTime={task.deadlineAt}
            relative={false}
            showTime={true}
          />
          {editable && (
            <Button
              label={'Change'}
              theme={{
                labelColor: 'neutral',
                backgroundColor: 'white',
                borderColor: 'neutralTintFive'
              }}
              onPress={onPressDeadline}
            />
          )}
        </View>
      </TaskInfoSlot>

      <TaskInfoSlot>
        <TaskSectionTitle>ASSIGNEES:</TaskSectionTitle>
        <TaskAssignees
          users={taskUsers}
          onPressAssignees={onPressAssignees}
          onUserUpdated={onUserUpdated}
          editable={editable}
        />
      </TaskInfoSlot>

      <TaskInfoSlot>
        <TaskSectionTitle>CREATOR:</TaskSectionTitle>
        <PersonDisplay user={task.user}>
          <PTDate user={sessionUser} dateTime={task.createdAt} relative={false} showTime={true} />
        </PersonDisplay>
      </TaskInfoSlot>
    </>
  )
}

export { TaskDetails, TaskDetailsProps }
