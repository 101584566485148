export function Controller(ProcessesData, gettextCatalog) {
  'ngInject'

  let vm = this

  vm.crumbs = [
    {
      /// Bread crumb
      name: gettextCatalog.getString('Accounts'),
      link: 'accounts'
    },
    {
      /// Bread crumb. A 'Process' is a long running task
      name: gettextCatalog.getString('Profile'),
      link: 'profile.basic'
    }
  ]
  vm.self = gettextCatalog.getString('Processes')

  vm.$onInit = function () {}
}
