export function RecordsBatchCloneRoute($stateProvider) {
  'ngInject'

  let batchCloneRecordModal

  $stateProvider.state('bucket.clone-records', cloneRecordsState())
  $stateProvider.state('folders.clone-records', cloneRecordsState())
  $stateProvider.state('folder.clone-records', cloneRecordsState())
  $stateProvider.state('folder.scan.clone-records', cloneRecordsState())
  $stateProvider.state('folders.scan.clone-records', cloneRecordsState())
  $stateProvider.state('account.scan.clone-records', cloneRecordsState())

  function cloneRecordsState() {
    return {
      url: '/records/clone?{recordIds}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: addRecordPermission,
        resolvedRecords: resolveCloneRecords
      },
      onEnter: onBatchCloneRecordEnter,
      onExit: onBatchCloneRecordExit
    }
  }

  function addRecordPermission(account, member, permissionsCheck, ErrorsResolve) {
    'ngInject'

    return permissionsCheck.canPromise('add', 'records', account).catch(ErrorsResolve.caught)
  }

  function resolveCloneRecords(account, $stateParams, recordsResolves, ErrorsDisplay) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    let selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves.records(selection, 'folder').catch(function () {
      return ErrorsDisplay.catch('select_records')
    })
  }

  function onBatchCloneRecordEnter($uibModal, $state, resolvedRecords) {
    'ngInject'

    batchCloneRecordModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptRecordsBatchClone',
      resolve: {
        records: () => resolvedRecords
      }
    })

    batchCloneRecordModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onBatchCloneRecordExit() {
    'ngInject'

    batchCloneRecordModal.close()
  }
}
