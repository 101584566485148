export function Controller($scope, $timeout, lookupsData) {
  'ngInject'

  $scope.control = {
    lookup: lookup
  }

  function lookup() {
    return $timeout(function () {
      return lookupsData
        .fetch($scope.options.data.field.source.key, $scope.model[$scope.options.key])
        .then(function (results) {
          return results.map(function (result) {
            return result.value
          })
        })
    })
  }
}
