export function fileSize() {
  'ngInject'

  let service = {
    format: format
  }

  return service

  ///////////////

  function format(bytes, decimals) {
    let k = 1024,
      dm = decimals || 0,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k))

    if (!bytes) {
      return '0 Byte'
    }

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }
}
