export function accountsPoller($q, AccountModel, $timeout) {
  'ngInject'

  let pollerRate
  let pollerCancelled

  let service = {
    pollForCompletion: pollForCompletion,
    cancel: cancel
  }

  return service
  ///////////////

  function pollForCompletion(account) {
    pollerRate = 2000
    pollerCancelled = false

    return $timeout(function () {
      return check(account.id).catch(function () {
        if (!pollerCancelled) {
          pollerRate = pollerRate * 1.25
          return pollForCompletion(account)
        }
      })
    }, pollerRate)
  }

  function cancel() {
    pollerCancelled = true
  }

  function check(id) {
    let defer = $q.defer()

    AccountModel.$find(id)
      .$asPromise()
      .then(function (account) {
        if (account.available) {
          defer.resolve(account)
        } else {
          defer.reject(account)
        }
      })

    return defer.promise
  }
}
