export function Controller($scope, recordsData, gettextCatalog) {
  'ngInject'

  let vm = this

  vm.control = {}

  vm.field = {
    required: false,
    label: 'Shared'
  }

  /////////////

  vm.$onInit = function () {}
}
