import _find from 'lodash/find'
import _forIn from 'lodash/forIn'
import _sortBy from 'lodash/sortBy'
import _remove from 'lodash/remove'

export function Controller($scope, gettextCatalog, iAuditorData) {
  'ngInject'

  const params: any = {
    filter: '',
    limit: 20
  }

  const auditsEnabledCheck = () => {
    iAuditorData
      .checkIntegration()
      .then((hasIntergration) => {
        this.enabled = hasIntergration

        if (this.enabled) {
          this.setupAudits()
        }
      })
      .catch(() => {})
  }

  const checkCustomFields = () => {
    iAuditorData
      .getFields()
      .then(filterCustomFields)
      .catch(() => {})
  }

  const filterCustomFields = (customFields) => {
    let whitelist = iAuditorData.getCustomFilterWhitelist()
    let viewData

    let isWhitelisted = (field) => {
      return whitelist.indexOf(field.key) !== -1
    }

    if (customFields.length) {
      viewData = customFields.filter(isWhitelisted)
      viewData = _sortBy(viewData, 'label')
    }

    if (viewData && viewData.length) {
      this.customFields = viewData
    }
  }

  const flagHighlighted = () => {
    this.audits.forEach((audit) => {
      if (this.pendingItems.indexOf(audit.id) >= 0) {
        audit.$highlight = true
      }
    })
  }

  this.$onInit = () => {
    this.params = this.$transition$.params()
    this.panels = {
      filter: false,
      sort: false
    }

    this.audits = []
    this.auditCount = 0
    this.canLoadMore = null
    this.enabled = null
    this.customFields = []
    this.loading = false
    this.self = gettextCatalog.get
    this.pendingItems = []

    auditsEnabledCheck()
    checkCustomFields()

    $scope.$on('iauditor_audit_was_added', (event, data) => {
      this.pendingItems.push(data.data.id)
    })

    $scope.$on('iauditor_audit_was_used', (event, data) => {
      if (this.params.used !== 'true' && _find(this.audits, { id: data.data.id })) {
        _remove(this.audits, { id: data.data.id })
      }
    })
  }

  this.setupAudits = () => {
    let dateFilter = 'modified_at='
    let authorFilter = 'author_name='
    let destinationFilter = 'destination='
    let scoreFilter = 'score_percentage'
    let teamSourcedFilter = 'team_sourced='

    // Check value is not === 'all' as this is the API default
    if (this.params.used && this.params.used !== 'all') {
      params.used = this.params.used
    }

    if (this.params.from || this.params.to) {
      if (params.filter && params.filter.length) {
        dateFilter = ',' + dateFilter
      }

      params.filter =
        params.filter + dateFilter + (this.params.from || this.params.to) + '/' + (this.params.to || this.params.from)
    }

    if (this.params.score && this.params.score !== 'all') {
      if (params.filter && params.filter.length) {
        scoreFilter = ',' + scoreFilter
      }

      params.filter = params.filter + scoreFilter + this.params.score
    }

    if (this.params.team_sourced && this.params.team_sourced !== 'all') {
      if (params.filter && params.filter.length) {
        teamSourcedFilter = ',' + teamSourcedFilter
      }

      params.filter = params.filter + teamSourcedFilter + this.params.team_sourced
    }

    if (this.params.template_id) {
      params.template_id = this.params.template_id
    }

    if (this.params.author) {
      if (params.filter && params.filter.length) {
        authorFilter = ',' + authorFilter
      }

      params.filter = params.filter + authorFilter + this.params.author
    }

    if (this.params.destination) {
      if (params.filter && params.filter.length) {
        destinationFilter = ',' + destinationFilter
      }

      params.filter = params.filter + destinationFilter + '"' + this.params.destination + '"'
    }

    if (!params.filter) {
      params.filter = null
    }

    this.updateAudits()
  }

  this.loadMoreAudits = () => {
    this.loading = true

    iAuditorData.loadMore(this.audits, {}).then((data) => {
      this.loading = false
      this.auditCount = data.$metadata.pagination.total
      this.canLoadMore = data.$metadata.pagination.current_page < data.$metadata.pagination.total_pages

      flagHighlighted()

      this.pendingItems = []
    })
  }

  this.togglePanel = (panelName) => {
    const forInPanels = (value, key) => {
      // Invert the selection of clicked panel
      if (key === panelName) {
        this.panels[key] = !this.panels[key]
      } else {
        // Make sure all others are closed
        this.panels[key] = false
      }
    }

    _forIn(this.panels, forInPanels)
  }

  this.updateAudits = () => {
    this.audits = iAuditorData.init(params)

    this.loadMoreAudits()
  }
}
