import _merge from 'lodash/merge'
import _keys from 'lodash/keys'
import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _difference from 'lodash/difference'
import _intersection from 'lodash/intersection'
import _cloneDeep from 'lodash/cloneDeep'

export function ActivitiesRecord() {
  'ngInject'

  let service = {
    changes: changes,
    tagChanges: tagChanges,
    propsToSentance: propsToSentance
  }

  return service

  function changes(data) {
    let keys = _merge(_keys(data.from), _keys(data.to))

    return _keyBy(
      _map(keys, function (key) {
        return {
          property: key,
          from: (data.from && data.from[key]) || null,
          to: (data.to && data.to[key]) || null
        }
      }),
      'property'
    )
  }

  function tagChanges(activityChanges) {
    let tags: any = _find(activityChanges, { property: 'tags' })
    let results = {
      added: [],
      removed: [],
      unchanged: []
    }

    if (tags) {
      results.added = _difference(tags.to, tags.from)
      results.removed = _difference(tags.from, tags.to)
      results.unchanged = _intersection(tags.from, tags.to)
    }

    return results
  }

  /**
   * Takes an array of strings and returns them in sentance form.
   *
   * ['one'] -> 'one'
   * ['one', 'two'] -> 'one and two'
   * ['one, two, three'] -> 'one, two and three'
   */
  function propsToSentance(props) {
    let string = ''
    // make a copy of props so we aren't modifying the original object
    let tmpProps = _cloneDeep(props)

    if (tmpProps.length === 1) {
      return props[0]
    }

    for (let i = 0; i < tmpProps.length; i = i + 1) {
      // change all underscores to spaces
      tmpProps[i] = tmpProps[i].replace(/_/g, ' ')

      if (i + 1 === tmpProps.length) {
        string = string + ('and ' + tmpProps[i])
      } else if (i + 2 === tmpProps.length) {
        string = string + (tmpProps[i] + ' ')
      } else {
        string = string + (tmpProps[i] + ', ')
      }
    }

    return string
  }
}
