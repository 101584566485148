export function ptLayoutSplashRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('layoutSplash', {
    parent: 'layoutBase',
    abstract: true,
    views: {
      'page@layoutBase': {
        component: 'ptLayoutSplash'
      }
    }
  })
}
