import _includes from 'lodash/includes'

//import './popover.html'

export function Controller($scope, DefinitionsData, tracking) {
  'ngInject'

  let vm = this

  vm.control = {
    change: change,
    columnDefault
  }

  vm.$onInit = function () {
    vm.popover = {
      template: 'directives/pt-folder-columns/popover.html'
    }

    initColumns()

    $scope.$on('definitions:changed', () => {
      initColumns()
    })
  }

  function initColumns() {
    vm.availableColumns = DefinitionsData.getAllColumns()

    vm.selectedColumnKeys = DefinitionsData.getVisibleColumnsAsArray().reduce((keys, column) => {
      if (column.visibility) {
        keys.push(column.key)
      }

      return keys
    }, [])
  }

  function columnDefault() {
    tracking.trackEvent({ action: 'columnsReset', category: 'definitions' })
    DefinitionsData.removeVisibleColumnsDefault()
  }

  function change() {
    tracking.trackEvent({ action: 'columnsChanged', category: 'definitions' })

    let selectedColumns = []
    let sortHasChanged = false

    DefinitionsData.getAllColumns().forEach((column) => {
      let definition
      const columnIsSelected = _includes(vm.selectedColumnKeys, column.key)
      const visibleColumn = DefinitionsData.getVisibleColumnsAsArray().find((col) => col.key === column.key)
      const columnIsUsedForSorting = visibleColumn && visibleColumn.order && visibleColumn.visibility

      if (columnIsSelected) {
        definition = {
          ...column,
          name: column.label,
          visibility: true,
          order: (visibleColumn && visibleColumn.order) || 0,
          direction: (visibleColumn && visibleColumn.direction) || 'ASC'
        }

        selectedColumns.push(definition)
      }

      // Refresh record list, as a column was just removed that was
      // being used to sort. Reset this column to default sort values.
      if (columnIsUsedForSorting && !definition) {
        sortHasChanged = true
      }
    })

    DefinitionsData.setVisibleColumns({
      definitions: selectedColumns,
      setDefault: true,
      recalculateOrders: sortHasChanged,
      setSort: false
    })

    if (sortHasChanged) {
      DefinitionsData.setVisibleColumnsSort(selectedColumns)
    }
  }
}
