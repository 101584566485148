export function Controller(NotesData, recordsSelection, FieldsIdentifiers, gettextCatalog, $stateParams, context) {
  'ngInject'

  let s = {
    /// Field label
    comments: gettextCatalog.getString('Comments')
  }

  const checkUploadsComplete = () => {
    if (!this.uploading) {
      return true
    }

    this.error = 'wait_for_uploads'

    return false
  }

  const copyDown = (fc, options) => {
    let fieldKey = options.key
    let value = options.value()

    this.onCopyDown({ recordId: this.record.id, fieldKey, value })
  }

  this.accountId = context.get('account').id
  this.note = {}
  this.files = []
  this.uploading = false
  this.saving = false
  this.error = null
  this.identifiers = []
  this.noteFields = [
    {
      key: 'comments',
      type: 'textareaExpanding',
      ngModelAttrs: {
        ptFocus: {
          attribute: 'pt-focus'
        }
      },
      templateOptions: {
        label: s.comments,
        required: true,
        ptFocus: this.first
      },
      data: {
        copyDown: copyDown,
        canCopyDown: this.multiple
      }
    }
  ]

  this.$onInit = () => {
    let audit

    if ($stateParams.auditId) {
      audit = {
        type: 'iauditor',
        value: $stateParams.auditId
      }

      this.files = this.files.concat([audit])

      this.note.files = [audit]
    }

    this.onRegister({ scope: this })

    this.identifiers = FieldsIdentifiers.get(this.record.fields)
  }

  this.save = () => {
    if (this.form.$valid && checkUploadsComplete()) {
      this.saving = true
      this.error = null

      NotesData.create(this.record, this.note)
        .then(() => {
          this.remove('success')
          recordsSelection.unselect(this.record)
        })
        .catch((rejection) => {
          this.saving = false
          this.error = rejection.$response.data.error
        })
    }
  }

  this.remove = (reason) => {
    this.onRemove({ reason, recordId: this.record.id })
    this.onDeregister({ scope: this })
  }

  this.isValid = () => {
    return this.form.$valid && !this.uploading
  }

  this.setField = (fieldKey, value) => {
    this.note[fieldKey] = value
  }

  this.setFiles = (event) => {
    this.note.files = event.model
  }

  this.setUploading = (event) => {
    this.uploading = event.uploading
  }
}
