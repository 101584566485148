import { createParamsFromCursor } from '../../api/pagination'
import { Activity } from '../index'
import { createResource } from '../../api/resource'
import { createResponsePaginationTransformer } from '../../api/transformer'
import { Cursor } from '../../api'

interface FetchActivitiesQuery {
  filter?: string
  include?: string
  limit?: number
}

interface Params {
  accountId: string
  recordId?: string
  taskId?: string
  cursor?: Cursor
  query?: FetchActivitiesQuery
}

export const fetchActivities = createResource({
  request: (params: Params) => {
    const { accountId, recordId, taskId, cursor, query = {} } = params

    const queryParams: any = {
      ...query,
      ...(cursor ? createParamsFromCursor(cursor) : {})
    }

    if (recordId) {
      queryParams.record_id = recordId
    } else if (taskId) {
      queryParams.task_id = taskId
    }

    return {
      url: `accounts/${accountId}/activities`,
      method: 'GET',
      params: queryParams
    }
  },
  transform: createResponsePaginationTransformer<Activity>()
})
