import * as React from 'react'

export interface ptFieldLabelProps {
  field: {
    required: boolean
    label: string
  }
}

class ptFieldLabel extends React.Component<ptFieldLabelProps> {
  render() {
    return (
      <div className="field-label">
        <span className={this.props.field.required ? 'required' : ''}>{this.props.field.label}</span>
      </div>
    )
  }
}

export default ptFieldLabel
