import { Controller } from './controller'
import view from './view.html'

export const ptUsersLoginForm = {
  bindings: {
    onLogin: '&',
    ssoProviders: '<',
    ssoProvidersAuthenticate: '<'
  },
  controller: Controller,
  template: view
}
