export const chartOptions = {
  title: {
    display: false
  },
  legend: {
    display: true,
    position: 'right',
    labels: {
      fontFamily: `Avenir, sans-serif`,
      fontSize: 14
    }
  },
  elements: {
    arc: {
      borderWidth: 2
    }
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return data.labels[tooltipItem.index]
      }
    }
  }
}

export const chartOptionsPrint = {
  title: {
    display: false
  },
  legend: {
    display: true,
    position: 'right',
    labels: {
      fontFamily: `Avenir, sans-serif`,
      fontSize: 20
    }
  },
  elements: {
    arc: {
      borderWidth: 2
    }
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return data.labels[tooltipItem.index]
      }
    }
  }
}
