import { Controller } from './controller'
import view from './view.html'

export function ptActivitiesOptions() {
  var directive = {
    restrict: 'A',
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      toggleFiltersPanel: '&',
      openExportModal: '&'
    }
  }

  return directive
}
