export function Controller(LogsData) {
  'ngInject'

  this.$onInit = () => {
    this.task = this.resolve.task
    this.loading = true

    LogsData.getLogs(this.task.account.id, this.task.record.id, ['state'])
      .then((logs) => {
        this.logs = logs.items
      })
      .catch(() => {})
      .finally(() => {
        this.loading = false
      })
  }

  this.logSelected = (log) => {
    this.dismiss({ $value: log })
  }
}
