import _extend from 'lodash/extend'
import _omit from 'lodash/omit'

export function Controller(filteringContext) {
  'ngInject'

  let vm = this

  vm.control = {
    toggleStarredFilter: toggleStarredFilter
  }

  vm.isFiltered = false
  vm.params = vm.params || []

  vm.$onInit = function () {
    vm.params.forEach(function (param) {
      if (param.name.toLowerCase() === 'starred') {
        vm.isFiltered = param.value
        return
      }
    })
  }

  function toggleStarredFilter() {
    let currentParams = filteringContext.getFilteringParams()

    vm.isFiltered = !vm.isFiltered

    if (vm.isFiltered) {
      filteringContext.applyFilter(_extend(currentParams, { starred: true }))
    } else {
      filteringContext.applyFilter(_omit(currentParams, 'starred'))
    }
  }
}
