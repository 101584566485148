import { createResource } from '../../api/resource'
import { createResponseTransformer } from '../../api/transformer'

interface Params {
  taskId: UUID
  uploadId: UUID
}

export const deleteTaskUpload = createResource({
  request: ({ taskId, uploadId }: Params) => ({
    url: `tasks/${taskId}/uploads/${uploadId}`,
    method: 'DELETE'
  }),
  transform: createResponseTransformer<undefined>()
})
