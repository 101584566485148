import { Controller } from './controller'
import view from './view.html'

export const ptProcessesTable = {
  bindings: {
    processes: '<',
    type: '@'
  },
  controller: Controller,
  template: view
}
