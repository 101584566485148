import * as Sentry from '@sentry/browser'
import _find from 'lodash/find'
import _sortBy from 'lodash/sortBy'

export function TutorialsFlags(
  $q,
  context,
  session,
  EventsData,
  TUTORIALS,
  TutorialsModel,
  BASE_URL,
  API_VERSION,
  $http,
  collections
) {
  'ngInject'

  const cache = {
    userId: null,
    accountId: null,
    flags: []
  }

  const service = {
    getUserFlag,
    getUserFlags,
    setUserFlag
  }

  return service

  function cacheDataExists() {
    const userCheck = session.user.id === cache.userId
    const accountCheck = context.get('account').id === cache.accountId

    return userCheck && accountCheck && cache.flags.length
  }

  function getUserFlag(flagKey) {
    const defer = $q.defer()
    const cachedFlag = _find(cache.flags, { key: flagKey })

    if (cacheDataExists() && cachedFlag) {
      defer.resolve(cachedFlag)
    } else {
      getUserFlags(true).then((response) => {
        const flag = _find(response, { key: flagKey })

        if (flag) {
          defer.resolve(flag)
        }
      })
    }

    return defer.promise
  }

  /**
   * @param {boolean} fromApi Force fetching data from the API rather than attempting from cache
   */
  function getUserFlags(fromApi = false) {
    if (!fromApi && cacheDataExists()) {
      return $q.resolve(cache.flags)
    }

    return collections
      .getAll(TutorialsModel.$collection())
      .then((flags) => {
        // Add the additional data (stored locally in an angular constant) to the flags
        flags.forEach((flag) => {
          let additionalData = TUTORIALS[flag.key] || {}

          for (let prop in additionalData) {
            if ({}.hasOwnProperty.call(additionalData, prop)) {
              flag[prop] = additionalData[prop]
            }
          }
        })

        updateCache({
          userId: session.user.id,
          accountId: context.get('account').id,
          flags: _sortBy(flags, 'position')
        })

        return cache.flags
      })
      .catch((response) => {
        return response
      })
  }

  function setUserFlag(flag) {
    const locatedFlag = _find(cache.flags, { key: flag })

    if (locatedFlag && locatedFlag.completed_at === null) {
      EventsData.broadcast('tutorial_completed', { tutorial: locatedFlag })

      $http({
        method: 'POST',
        url: `${BASE_URL}/tutorials/${locatedFlag.id}/actions/complete`,
        headers: {
          Accept: API_VERSION
        }
      }).catch(() => {
        locatedFlag.completed_at = null
      })
    }

    return locatedFlag && locatedFlag.completed_at
  }

  function updateCache(data) {
    cache.userId = data.userId
    cache.accountId = data.accountId
    cache.flags = data.flags
  }
}
