import * as React from 'react'
import * as moment from 'moment-timezone'

import { Activity } from '..'
import { Text } from '../../components'
import { View, createStyledComponent } from '../../components/styled'
import { ActivityTitle } from './activity-title'
import { ActivityContent } from './activity-content'

interface Props {
  activity: Activity
  viewType: string
  onPressActivity: (activity: Activity) => void
}

const Wrapper = createStyledComponent(View, {
  overflow: 'visible'
})

export const ActivityTaskWasAssignedToLog = ({ activity, viewType, onPressActivity }: Props) => {
  const inspectionText = `${activity.to.state.name} ${moment(activity.to.createdAt).format('Do MMM YYYY H:mm a')}`

  switch (viewType) {
    case 'simple':
      return (
        <Wrapper>
          <ActivityTitle activity={activity} onPressActivity={onPressActivity}>
            Task was assigned to log
          </ActivityTitle>
          <ActivityContent>
            <Text style={{ color: activity.to.state.color }}>{inspectionText}</Text>
          </ActivityContent>
        </Wrapper>
      )
  }
}
