import angular from 'angular'
import angularChartJs from 'angular-chart.js'

import { ReportsData } from './services/data'
import { ReportsModel, ReportsProjectedChecksModel, ReportsComplianceModel } from './services/model'

import { ptComplianceWidget } from './components/pt-compliance-widget/component'
import { ptComplianceWidgetExport } from './components/pt-compliance-widget-export/component'
import { ptComplianceWidgetFilter } from './components/pt-compliance-widget-filter/component'
import { ptFutureInspectionsWidget } from './components/pt-future-inspections-widget/component'
import { ptFutureInspectionsWidgetFilter } from './components/pt-future-inspections-widget-filter/component'
import { ptRecordStateWidget } from './components/pt-record-state-widget/component'

export const ReportsModule = angular
  .module('reports', [angularChartJs])
  .factory('ReportsModel', ReportsModel)
  .factory('ReportsProjectedChecksModel', ReportsProjectedChecksModel)
  .factory('ReportsComplianceModel', ReportsComplianceModel)
  .factory('ReportsData', ReportsData)
  .component('ptComplianceWidget', ptComplianceWidget)
  .component('ptComplianceWidgetExport', ptComplianceWidgetExport)
  .component('ptComplianceWidgetFilter', ptComplianceWidgetFilter)
  .component('ptFutureInspectionsWidget', ptFutureInspectionsWidget)
  .component('ptFutureInspectionsWidgetFilter', ptFutureInspectionsWidgetFilter)
  .component('ptRecordStateWidget', ptRecordStateWidget)
  .run([
    '$templateCache',
    ($templateCache) => {
      $templateCache.put(
        'components/pt-compliance-widget/help-popover.html',
        require('./components/pt-compliance-widget/help-popover.html')
      )
      $templateCache.put(
        'components/pt-future-inspections-widget/help-popover.html',
        require('./components/pt-future-inspections-widget/help-popover.html')
      )
      $templateCache.put(
        'components/pt-record-state-widget/help-popover.html',
        require('./components/pt-record-state-widget/help-popover.html')
      )
    }
  ]).name
