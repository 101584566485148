export function ptImportsNewRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('imports.new', {
    url: '/new?accountId&folderId&productIds&newUser',
    params: {
      newUser: {
        type: 'bool'
      }
    },
    views: {
      'crumbs@imports': {
        component: 'ptImportsCrumbs'
      },
      'main@imports': {
        component: 'ptImportsNew'
      }
    },
    resolve: {
      products: resolveProducts,
      crumbs: resolveCrumbs,
      self: resolveSelf,
      accounts: resolveAccounts,
      subscriptions: resolveSubscriptions
    },
    onEnter: onEnter
  })

  function resolveAccounts(accountsData) {
    'ngInject'

    return accountsData
      .getAll(accountsData.init())
      .then((accounts) => {
        return accounts
      })
      .then((accounts) => {
        if (accounts.length === 1) {
          return accountsData.fetchAccount(accounts[0].id).then((account) => {
            return [account]
          })
        }

        return accounts
      })
  }

  function resolveSubscriptions($stateParams, SubscriptionsData) {
    'ngInject'

    return SubscriptionsData.getAll(SubscriptionsData.init()).then(function (subscriptions) {
      return subscriptions
    })
  }

  function resolveProducts(ProductsData, $transition$) {
    'ngInject'

    return ProductsData.get($transition$.params().productIds).catch(() => {})
  }

  function resolveCrumbs(gettextCatalog) {
    'ngInject'

    return [
      {
        name: gettextCatalog.getString('Imports'),
        link: 'imports'
      }
    ]
  }

  function resolveSelf(gettextCatalog) {
    'ngInject'

    return gettextCatalog.getString('New')
  }

  function onEnter(products, $state) {
    'ngInject'

    let result

    if (!products) {
      result = $state.target('imports')
    }

    return result
  }
}
