import view from './view.html'
import { Controller } from './controller'

/**
 * Readonly view of a signature field
 */
export const ptChecklistItemSignature = {
  bindings: {
    record: '<',
    onValueChange: '&',
    field: '<',
    readOnly: '<'
  },
  controller: Controller,
  template: view
}
