export function Controller() {
  'ngInject'

  let vm = this

  vm.control = {
    canNotScan: canNotScan
  }

  function canNotScan() {
    return false
  }
}
