import { Controller } from './controller'
import view from './view.html'

export const ptSearch = {
  template: view,
  controller: Controller,
  bindings: {
    value: '<',
    onValueChange: '&'
  }
}
