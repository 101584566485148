export function Controller($window, $state, $stateParams, usersData, TERMS_URL, PRIVACY_URL) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    register: register,
    handleSSSOProviderClick: handleSSSOProviderClick
  }

  $ctrl.selectedCredentialType = null

  $ctrl.formModel = {
    first_name: $stateParams.first_name,
    last_name: $stateParams.last_name,
    email: $stateParams.email,
    password: '',
    password_confirmation: '',
    terms: false,
    confirmation_code: $stateParams.code
  }

  $ctrl.error = null
  $ctrl.submitting = false
  $ctrl.hideEmailField = false
  $ctrl.termsLink = TERMS_URL
  $ctrl.privacyLink = PRIVACY_URL

  $ctrl.$onInit = () => {
    $ctrl.selectedCredentialType = $ctrl.credentialType

    if ($state.current.name === 'invitations' && $stateParams.email) {
      $ctrl.hideEmailField = true
    }

    $ctrl.ssoProviders = $ctrl.ssoProviders.map((provider) => {
      return {
        name: provider.name,
        image: provider.image,
        url: provider.url
      }
    })
  }

  function handleSSSOProviderClick(provider) {
    if (!$ctrl.registerForm.$valid) {
      $ctrl.error = $ctrl.registerForm.$error
      return
    }

    // If form is valid, create state ID
    const stateId = Math.random().toString(36).substring(7)

    // Cache first name and last name
    localStorage.setItem(
      `invitation-${stateId}`,
      JSON.stringify({
        first_name: $ctrl.formModel.first_name,
        last_name: $ctrl.formModel.last_name,
        email: $ctrl.formModel.email,
        confirmation_code: $stateParams.code
      })
    )

    // OAuth flow
    window.location.href = provider.url + `&state=${stateId}`
  }

  function register() {
    if ($ctrl.registerForm.$valid) {
      $ctrl.submitting = true
      $ctrl.error = null

      usersData.create($ctrl.formModel).then(registerSuccess, registerError)
    }
  }

  function registerSuccess(user) {
    $ctrl.formModel.username = user.email

    $ctrl.onRegister({ user: $ctrl.formModel })
  }

  function registerError(rejection) {
    $ctrl.submitting = false
    $ctrl.error = rejection.$response.data
  }
}
