import { Controller } from './controller'
import view from './view.html'

export const ptActivitiesTaskItems = {
  bindings: {
    task: '<',
    type: '<'
  },
  controller: Controller,
  template: view
}
