import _cloneDeep from 'lodash/cloneDeep'
import _sortBy from 'lodash/sortBy'
import _difference from 'lodash/difference'
import { TaskAssigneeUser } from 'AppCore/task/models'
import { User } from 'AppCore/user/models'
import { Log } from 'AppCore/logs'
import { AccountUpload, Upload } from 'AppCore/upload'

export function Controller(
  $scope,
  $state,
  $uibModal,
  TasksData,
  session,
  EventsData,
  moment,
  uploadFileActions,
  DateFormat,
  $timeout,
  context,
  permissionsCheck
) {
  'ngInject'
  let initialUserIds
  let updatedUserIds

  const assigneesModalClosed = ($value) => {
    if ($value.assignees) {
      const events = []
      this.taskUsers = $value.assignees

      updatedUserIds = _sortBy($value.assignees.map((user: User) => user.id))

      // Check if anyone was added
      if (_difference(updatedUserIds, initialUserIds).length) {
        events.push('task_assignee_was_added')
      }

      // Check if anyone was removed
      if (_difference(initialUserIds, updatedUserIds).length) {
        events.push('task_assignee_was_removed')
      }

      if (events.length) {
        TasksData.amendAssignees(this.task.id, updatedUserIds)
          .then((response) => {
            this.task.assignees = response

            events.forEach((event) => {
              EventsData.pushEvent(event, {
                data: {
                  id: this.task.id,
                  assignees: response
                }
              })
            })
          })
          .catch(() => {
            // noop
          })
          .finally(() => {
            initialUserIds = updatedUserIds
            updatedUserIds = null
            this.canSave = false
          })
      }
    }
  }

  const getSubStatus = () => {
    TasksData.getTaskSubscribers(this.task.id).then((subscribers) => {
      this.subStatus = subscribers.some((subscriber) => {
        return session.user.id === subscriber.userId
      })
    })
  }

  const updateDeadlineDate = (date) => {
    TasksData.amendTask(this.task.id, {
      deadlineAt: moment(date).format('YYYY-MM-DD HH:mm:ss')
    })
      .then((response) => {
        this.task.deadlineAt = response.deadlineAt

        EventsData.pushEvent('task_was_updated', {
          data: {
            body: response.body,
            id: response.id,
            deadlineAt: response.deadlineAt,
            title: response.title
          }
        })
      })
      .catch(() => {
        // noop
      })
  }

  this.$onInit = () => {
    const account = context.get('account')
    const isViewOnly = permissionsCheck.is('viewOnly', account)
    const canEditTasks = permissionsCheck.can('edit', 'tasks', account)
    this.editable = !isViewOnly && canEditTasks
    this.user = session.user
    this.isProfileTasks = $state.current.name.indexOf('tasks') === 0
    this.subStatus = false

    this.buttonTheme = {
      labelColor: 'neutral',
      backgroundColor: 'white',
      borderColor: 'neutralTintFive'
    }

    this.dateFormat = DateFormat.toUib(session.user.dateFormat)
    this.taskStates = account.task_states.data

    $scope.$on('task_note_was_created', (event, data) => {
      if (data.data.id && data.data.id === this.task.id) {
        getSubStatus()
      }
    })
  }

  this.$onChanges = (changes) => {
    if (changes.task) {
      // If there are assignees on the task already, set them to selected.
      this.taskUsers = this.task.assignees.reduce((assigneeUsers, assignee) => {
        assignee.user.isSelected = true

        assigneeUsers.push(assignee.user)

        return assigneeUsers
      }, [])

      this.taskIsOverdue = moment(this.task.deadlineAt).isBefore(new Date())

      getSubStatus()
    }
  }

  this.goToAccount = () => {
    $state.go('account', {
      accountId: this.task.account.id
    })
  }

  this.goToInspection = () => {
    TasksData.fetchActivity(this.task.log.id, this.task.account.id)
      .then((resp) => {
        $state.go($state.current.name + '.activity', {
          activityId: resp.data.data[0].id
        })
      })
      .catch((err) => {
      })
  }

  this.goToRecord = () => {
    $state.go('account.records', {
      accountId: this.task.account.id,
      recordId: this.task.record.id
    })
  }

  this.openDatepickerModal = () => {
    if (this.isAdmin) {
      $uibModal
        .open({
          component: 'ptTaskDatepicker',
          windowClass: 'task-datepicker',
          resolve: {
            task: () => this.task,
            dateFormat: () => this.dateFormat,
            taskIsOverdue: () => this.taskIsOverdue
          }
        })
        .result.catch(($value) => {
          if ($value) {
            updateDeadlineDate($value)
          }
        })
    }
  }

  this.openLogAssignModal = () => {
    $uibModal
      .open({
        backdrop: 'static',
        component: 'ptTaskLogAssign',
        windowClass: 'task-log-assign',
        size: 'lg',
        resolve: {
          task: () => this.task
        }
      })
      .result.catch((log: Log) => {
        if (log) {
          EventsData.pushEvent('task_was_assigned_to_log', {
            data: {
              id: this.task.id,
              log
            }
          })
        } else {
          EventsData.pushEvent('task_was_unassigned_from_log', {
            data: {
              id: this.task.id
            }
          })
        }

        // Create a new object so updates occur in React components
        this.task = { ...this.task }
      })
  }

  this.openAssigneeModal = () => {
    initialUserIds = _sortBy(
      this.taskUsers.map((user: User) => {
        return user.id
      })
    )

    $uibModal
      .open({
        backdrop: 'static',
        component: 'ptTaskAmendAssignees',
        windowClass: 'task-amend-assignee',
        size: 'lg',
        resolve: {
          recordId: () => this.task.record.id,
          selectedUsers: () => this.taskUsers
        }
      })
      .result.catch(assigneesModalClosed)
  }

  this.openTitleBodyModal = () => {
    if (this.isAdmin) {
      $uibModal
        .open({
          backdrop: 'static',
          component: 'ptTaskAmendTitleBody',
          windowClass: 'task-amend-title-body',
          resolve: {
            task: () => {
              return this.task
            }
          }
        })
        .result.catch(() => {
          // user dismissed
        })
    }
  }

  this.removeAssignee = (taskUser: TaskAssigneeUser, isSelected: boolean) => {
    // Wrap in timeout as this function is called within React
    $timeout(() => {
      this.taskUsers = this.taskUsers.filter((user: User) => user.id !== taskUser.id)

      TasksData.amendAssignees(
        this.task.id,
        this.taskUsers.map((user) => user.id)
      )
        .then((response) => {
          EventsData.pushEvent('task_assignee_was_removed', {
            data: {
              id: this.task.id,
              assignees: response
            }
          })
        })
        .catch(() => {})
    })
  }

  this.toggleSubStatus = () => {
    const action = this.subStatus ? 'unsubscribe' : 'subscribe'
    const statusInverse = !this.subStatus

    TasksData.action(this.task.id, action)
      .catch(() => {})
      .finally(() => {
        this.subStatus = statusInverse
      })
  }

  this.pressUpload = (upload: Upload) => {
    uploadFileActions.viewFile(upload)
  }

  this.taskUploadsAdded = (uploads: AccountUpload[]) => {
    EventsData.pushEvent('task_upload_was_created', {
      data: {
        id: this.task.id,
        uploads
      }
    })
  }

  this.taskUploadsDeleted = (uploads: AccountUpload[]) => {
    EventsData.pushEvent('task_upload_was_deleted', {
      data: {
        id: this.task.id,
        uploads
      }
    })
  }
}
