import axios from 'axios'

const axiosInstance = axios.create()

/**
 * This service calls an endpoint defined in proxy which checks for a cookie set by partner hub
 */
export function HubAuthService($q) {
  'ngInject'

  const request = () => {
    // We need to use $q.when wrapper to bring axios into the angularjs eco-system
    // https://stackoverflow.com/questions/46521516/how-to-use-the-axios-library-with-angularjs
    return $q.when(
      axiosInstance
        .request({
          method: 'POST',
          url: '/auth/hub'
        })
        .then((response) => {
          return response.data
        })
    )
  }

  return {
    request
  }
}
