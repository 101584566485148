import view from './view.html'
import { Controller } from './controller'

export function ptMembersPicker() {
  'ngInject'

  let directive = {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'E',
    scope: {
      picked: '=',
      ready: '=',
      isNewFolder: '<'
    }
  }

  return directive
}
