export function ptProfileEmailsAccountRoute($stateProvider) {
  'ngInject'

  let emailsAccountModal

  $stateProvider.state('profile.emails.account', {
    url: '/account/{accountId}',
    views: {
      'utility@layoutBase': {
        template: ' '
      }
    },
    resolve: {
      resolvedAccount: resolveAccount,
      resolvedSummaries: resolveSummaries
    },
    onEnter: onProfileEmailsAccountEnter
  })

  function resolveAccount(accountsData, $stateParams) {
    'ngInject'

    return accountsData.fetchAccount($stateParams.accountId)
  }

  function resolveSummaries(summariesData, $stateParams) {
    'ngInject'

    // inject user to make sure its been resolved
    return summariesData.getForAccount($stateParams.accountId)
  }

  function onProfileEmailsAccountEnter($uibModal, $state, resolvedAccount, resolvedSummaries) {
    'ngInject'

    emailsAccountModal = $uibModal.open({
      component: 'ptProfileEmailsAccount',
      resolve: {
        account: function () {
          return resolvedAccount
        },
        summaries: function () {
          return resolvedSummaries
        }
      },
      size: 'lg'
    })

    emailsAccountModal.result.catch(function () {
      $state.go('^')
    })
  }
}
