import _isArray from 'lodash/isArray'

export function ActivitiesField(dateTimeFormat, dateformatFilter) {
  'ngInject'

  let service = {
    info: info
  }

  return service

  function info(data) {
    let obj = {
      from: null,
      to: null
    }

    if (data.from) {
      obj.from = getValue(data.field.type, data.from)
    }

    if (data.to) {
      obj.to = getValue(data.field.type, data.to)
    }

    return obj
  }

  function getValue(type, data) {
    let value = null

    switch (type) {
      case 'money':
        value = data.value.formatted
        break

      case 'array':
        // TODO: remove check for string once the API returns as an actual array
        if (_isArray(data.value)) {
          value = data.value.join(', ')
        } else {
          value = data.value
        }
        break

      case 'date':
        value = dateformatFilter(data.value, dateTimeFormat.date())
        break

      default:
        value = data.value
    }

    return value
  }
}
