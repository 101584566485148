import { Controller } from './controller'
import view from './view.html'

export const ptImportsNew = {
  bindings: {
    products: '<',
    $transition$: '<',
    accounts: '<',
    subscriptions: '<'
  },
  controller: Controller,
  template: view
}
