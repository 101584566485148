export function Controller(session) {
  'ngInject'

  let vm = this

  vm.control = {
    acceptAll: acceptAll
  }

  vm.currentUser = session.user
  vm.validated = null

  /////////

  vm.$onInit = function () {
    checkValidationStatus()
  }

  function checkValidationStatus() {
    if (vm.currentUser) {
      vm.validated = !!vm.currentUser.confirmedAt
    } else {
      vm.validated = true
    }
  }

  function acceptAll() {
    vm.outerControl.acceptAll()
  }
}
