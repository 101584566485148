export function SummaryModel(restmod) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      account: { hasOne: 'AccountModel', map: 'account.data' }
    })

  return model
}
