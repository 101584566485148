export function Controller(foldersNavigation, foldersExpanded, context, foldersData, ErrorsModal, foldersTree) {
  'ngInject'

  const vm = this

  vm.sortOptions = {}

  vm.control = {
    openFolder: openFolder,
    shouldShowChildren: shouldShowChildren
  }

  vm.$onInit = function () {
    vm.account = context.get('account')
    vm.sortOptions = {
      items: '.folder-item',
      handle: '.folder-handle',
      update: moveFolder
    }
    vm.firstFolderPosition = foldersTree.getTree(vm.account).then((folders) => {
      return folders[0].position
    })
  }

  function moveFolder(e, ui) {
    foldersData
      .move(
        vm.account,
        ui.item.sortable.model,
        { id: ui.item.sortable.model.parent_id },
        ui.item.sortable.dropindex - 1,
        vm.firstFolderPosition.$$state.value
      )
      .catch((response) => {
        ErrorsModal.openModal(response.error)
      })
  }

  function openFolder(folderId) {
    foldersNavigation.go(folderId)
  }

  function shouldShowChildren(folderId) {
    return foldersExpanded.state(folderId)
  }
}
