import _keys from 'lodash/keys'
import _pickBy from 'lodash/pickBy'

export function ptTextcomplete(emojiService) {
  'ngInject'

  let directive = {
    link: Link,
    restrict: 'A'
  }

  return directive

  function Link(scope, element) {
    let options = {
      zIndex: '1100',
      className: 'pt-textcomplete'
    }

    const emojione = emojiService.get()

    let strategies = []
    if (emojione)
      strategies.push({
        match: /(\B):([\-+\w]{1,})$/,
        context: function (text) {
          return text.toLowerCase()
        },
        search: function (term, callback) {
          callback(
            _keys(
              _pickBy(emojione.emojioneList, function (value, key) {
                return key.startsWith(':' + term)
              })
            ).sort()
          )
        },
        replace: function (emoji) {
          return emoji + ' '
        },
        template: function (value) {
          return emojione.shortnameToUnicode(value) + value
        }
      })

    element.textcomplete(strategies, options)
  }
}
