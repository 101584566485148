export function Controller($stateParams, $state) {
  'ngInject'

  var vm = this

  vm.control = {
    resetAllFilters: resetAllFilters,
    resetUserFilter: resetUserFilter,
    resetTypeFilter: resetTypeFilter,
    resetFolderFilter: resetFolderFilter
  }

  vm.stateParams = $stateParams

  vm.$onInit = function () {}

  function resetAllFilters() {
    $state.go($state.current.name, { user: null, type: null, from: null, to: null, folder_id: null })
  }

  function resetUserFilter() {
    $state.go($state.current.name, { user: null })
  }

  function resetTypeFilter() {
    $state.go($state.current.name, { type: null })
  }

  function resetFolderFilter() {
    $state.go($state.current.name, { folder_id: null })
  }
}
