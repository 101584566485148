export function ptIauditorAuditRoute($stateProvider) {
  'ngInject'

  let auditModal

  newAuditState('iauditor')

  function newAuditState(parent) {
    $stateProvider.state(parent + '.audit', {
      url: '/audit/{auditId}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        audit: resolveAudit
      },
      onEnter: onAuditEnter,
      onExit: onAuditExit
    })
  }

  function onAuditEnter($uibModal, $state, audit) {
    'ngInject'

    auditModal = $uibModal.open({
      component: 'ptIauditorAudit',
      resolve: {
        audit: audit
      },
      size: 'xl'
    })

    auditModal.result.catch(() => {
      $state.go('^')
    })
  }

  function onAuditExit() {
    'ngInject'

    auditModal.close()
  }

  function resolveAudit(account, iAuditorData, $transition$) {
    'ngInject'

    return iAuditorData.getAuditById($transition$.params().auditId, { include: 'records' })
  }
}
