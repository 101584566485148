import _find from 'lodash/find'

export function Controller(context, filteringContext, filtersData, $stateParams, $state) {
  'ngInject'

  let vm = this

  vm.control = {
    applyDefaultFilter: applyDefaultFilter
  }
  vm.defaultFilter = null

  //////////
  vm.$onInit = function () {
    vm.filter = _find(context.get('filters'), 'default')
  }

  function applyDefaultFilter() {
    let rules = filtersData.extractRules(vm.filter.rules.data)

    if ($stateParams.folderId || $state.current.name === 'folders') {
      filteringContext.applyFilter(rules)
    } else {
      filteringContext.applyFilter(rules, 'folders')
    }
  }
}
