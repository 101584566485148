export function Controller($state, $stateParams) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    resetAllFilters,
    toggleFilterPanel,
    toggleSortPanel
  }

  $ctrl.isProfileTasks = $state.current.name.indexOf('tasks') === 0
  $ctrl.isAccountTasks = $state.current.name.indexOf('account.tasks') === 0

  $ctrl.stateFilter = null
  $ctrl.associationFilter = null
  $ctrl.assigneesFilter = false
  $ctrl.userFilter = false
  $ctrl.folderFilter = false
  $ctrl.recordFilter = false

  $ctrl.$onInit = function () {
    if ($stateParams.state) {
      $ctrl.stateFilter = $stateParams.state.replace(/,/g, ', ')
    }

    if ($stateParams.association) {
      $ctrl.associationFilter = $stateParams.association
    }

    if ($ctrl.isAccountTasks && $stateParams.assignees) {
      $ctrl.assigneesFilter = $stateParams.assignees.split(',').length
    }

    if ($ctrl.isAccountTasks && $stateParams.user) {
      $ctrl.userFilter = $stateParams.user.split(',').length
    }

    if ($ctrl.isAccountTasks && $stateParams.folders) {
      $ctrl.folderFilter = $stateParams.folders.split(',').length
    }

    if ($ctrl.isAccountTasks && $stateParams.record) {
      $ctrl.recordFilter = $stateParams.record.split(',').length
    }

    if ($ctrl.isProfileTasks && $stateParams.account) {
      $ctrl.accountFilter = $stateParams.account.split(',').length
    }
  }

  function resetAllFilters() {
    $state.go($state.current.name, {
      account: null,
      assignees: null,
      association: null,
      folders: null,
      state: 'open,pending',
      user: null,
      record: null
    })
  }

  function toggleFilterPanel() {
    $ctrl.onToggleFilterPanel()
  }

  function toggleSortPanel() {
    $ctrl.onToggleSortPanel()
  }
}
