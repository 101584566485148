export function SSOModel(restmod) {
  'ngInject'

  return restmod.model('oauth/url?platform=portal-invitation').mix('ApiBaseModel')
}

export function SSOModelAuthenticate(restmod) {
  'ngInject'

  return restmod.model('oauth/url').mix('ApiBaseModel')
}
