import _find from 'lodash/find'

export function flagsCheck() {
  'ngInject'

  let service = {
    has: has
  }

  return service

  function has(account, flag) {
    let flags = account.flags
    let matchingFlag = _find(flags, function (n) {
      return n.name.toUpperCase() === flag.toUpperCase()
    })

    if (matchingFlag) {
      return matchingFlag.active
    }

    return false
  }
}
