import _map from 'lodash/map'

export function Controller(ActivitiesRecord, ActivitiesField) {
  'ngInject'

  let vm = this

  vm.control = {
    openRecord: openRecord
  }

  vm.changes = []
  vm.stateColor = {
    'background-color': 'rgb(' + vm.data.to.state.colour + ')'
  }

  vm.$onInit = function () {
    vm.changes = ActivitiesRecord.changes(vm.data)

    vm.fields = fields()
  }

  function fields() {
    return _map(vm.changes.fields.to, function (field) {
      return {
        label: field.label,
        value: ActivitiesField.info({ to: field, field: field }).to
      }
    })
  }

  function openRecord() {
    vm.openRecord()
  }
}
