import lazy from 'lazy.js'
import _forIn from 'lodash/forIn'

export const context = () => {
  'ngInject'

  const store = {}
  const observerCallbacks = {}

  const service = {
    set,
    get,
    clear,
    registerObserverCallback,
    reset
  }

  return service

  function set(scope, value) {
    store[scope] = value
    notifyObservers(scope)
    return store[scope]
  }

  function clear(scope) {
    store[scope] = null
    notifyObservers(scope)
  }

  function get(scope) {
    return store[scope]
  }

  function registerObserverCallback(scope, callback) {
    if (!observerCallbacks[scope]) {
      observerCallbacks[scope] = []
    }

    observerCallbacks[scope].push(callback)
  }

  function reset() {
    _forIn(store, (val, key) => {
      store[key] = null
    })
  }

  function notifyObservers(scope) {
    lazy(observerCallbacks[scope]).each(function (callback) {
      callback()
    })
  }
}
