export function shareCertificate($http, session, BASE_URL, API_VERSION, certId, certName, emails, accountId) {
  let req = {
    method: 'POST',
    url: `${BASE_URL}/accounts/${accountId}/certificates/${certId}/actions/share/${encodeURIComponent(certName)}`,
    headers: {
      Accept: API_VERSION
    },
    data: {
      recipients: emails
    }
  }

  return $http(req)
}

export async function getContacts($http, session, BASE_URL, API_VERSION, accountId) {
  let req = {
    method: 'GET',
    url: `${BASE_URL}/accounts/${accountId}/contacts`,
    headers: {
      Accept: API_VERSION
    }
  }

  const response = await $http(req)
  return response
}
