export function folderContext($stateParams, context) {
  'ngInject'

  let service = {
    currentId: currentId
  }

  return service

  function currentId() {
    return $stateParams.folderId || context.get('account').folder.id
  }
}
