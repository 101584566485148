import { fetchMe } from 'AppCore/user/api/fetch-me-resource'

export function profileData($http, ProfileModel, session, Upload, BASE_URL, API_VERSION, EventsPusher) {
  'ngInject'

  let service = {
    getUser: getUser,
    uploadAvatar: uploadAvatar,
    removeAvatar: removeAvatar,
    uploadSignature: uploadSignature,
    removeSignature: removeSignature
  }

  return service

  ////////////////

  function getUser() {
    return fetchMe().then(function (user) {
      session.user = user
    })
  }

  function uploadAvatar(image) {
    return Upload.upload({
      url: BASE_URL + '/me/avatars',
      file: image,
      headers: {
        Accept: API_VERSION
      }
    })
  }

  function removeAvatar() {
    let req = {
      method: 'DELETE',
      url: BASE_URL + '/me/avatars',
      headers: {
        Accept: API_VERSION
      }
    }

    return $http(req)
  }

  function uploadSignature(image) {
    return Upload.upload({
      url: BASE_URL + '/me/signatures',
      file: image,
      headers: {
        Accept: API_VERSION
      }
    })
  }

  function removeSignature() {
    let req = {
      method: 'DELETE',
      url: BASE_URL + '/me/signatures',
      headers: {
        Accept: API_VERSION
      }
    }

    return $http(req)
  }
}
