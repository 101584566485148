import angular from 'angular'

import _isArray from 'lodash/isArray'
import _forEach from 'lodash/forEach'
import _size from 'lodash/size'
import _every from 'lodash/every'
import _bind from 'lodash/bind'
import _identity from 'lodash/identity'
import _forIn from 'lodash/forIn'

export function recordsSelection() {
  'ngInject'

  let selection = {}
  let previouslySelected = {}

  let service = {
    init: init,
    add: add,
    remove: remove,
    toggle: toggle,
    select: select,
    unselect: unselect,
    current: current,
    allSelected: allSelected,
    count: count,
    setAll: setAll,
    selectedIds: selectedIds
  }

  return service

  function init() {
    selection = {}
    previouslySelected = {}
   
    return selection
  }

  function add(record) {
    selection[record.id] = selection[record.id] || previouslySelected[record.id] || false
  }

  function remove(record) {
    if (_isArray(record)) {
      _forEach(record, function (singleRecord) {
        remove(singleRecord)
      })
    } else if (angular.isDefined(selection[record.id])) { 
      previouslySelected[record.id] = selection[record.id]
      delete selection[record.id]
    }
  }

  function toggle(record) {
    selection[record.id] = !selection[record.id]
  }

  function select(record) {
    if (angular.isDefined(selection[record.id])) {
      selection[record.id] = true
    }
  }

  function unselect(record) {
    if (angular.isDefined(selection[record.id])) {
      selection[record.id] = false
    }
  }

  function current() {
    return selection || {}
  }

  function allSelected() {
    return _size(selection) && _every(selection, _bind(_identity, true))
  }

  function count() {
    return selectedIds().length
  }

  function setAll(state) {
    _forEach(selection, function (recordState, recordId) {
      selection[recordId] = state
    })
  }

  function selectedIds() {
    let collection = current()
    let result = []

    _forIn(collection, function (isSelected, recordId) {
      if (isSelected) {
        result.push(recordId)
      }
    })

    return result
  }
}
