import angular from 'angular'

export function Controller($scope, ProcessesData) {
  'ngInject'

  var $ctrl = this

  $ctrl.$onInit = function () {
    if (!$ctrl.processes) {
      getProcesses($ctrl.type)
    }

    listen()
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.processes) {
      $ctrl.processes = angular.copy($ctrl.processes)
    }
  }

  function getProcesses(type) {
    ProcessesData.getAll(type).then((processes) => {
      $ctrl.processes = processes
    })
  }

  function listen() {
    $scope.$on('process_created', updateProcessEvent)
    $scope.$on('process_completed', updateProcessEvent)
  }

  function updateProcessEvent() {
    getProcesses($ctrl.type)
  }
}
