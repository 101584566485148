export function Authorise($q, $timeout, $state, session, permissionsCheck, ErrorsData) {
  'ngInject'

  const service = {
    admin
  }

  return service

  ///////////

  function admin(account) {
    const defer = $q.defer()
    const isAdmin = permissionsCheck.is('admin', account)

    if (isAdmin) {
      defer.resolve(isAdmin)
    } else {
      defer.reject(isAdmin)

      if (session.user) {
        $timeout(() => {
          // This code runs after the authentication promise has been rejected.
          // Go to the log-in page
          ErrorsData.currentError('authorise_admin')
          $state.go('error')
        })
      }
    }

    return defer.promise
  }
}
