import angular from 'angular'
import _forEach from 'lodash/forEach'
import _some from 'lodash/some'
import _remove from 'lodash/remove'

export function Controller($uibModal, uploadsData) {
  'ngInject'

  let $ctrl = this
  let uploadModal

  $ctrl.shouldShowUploadModal = false
  $ctrl.model = []

  $ctrl.control = {
    checkFileType: checkFileType,
    upload: upload,
    remove: remove,
    openUploadModal: openUploadModal,
    getImagePath: getImagePath
  }

  $ctrl.$onInit = function () {
    uploadTypeCheck()
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.model) {
      $ctrl.model = angular.copy($ctrl.model)
    }

    if (changes.uploading) {
      $ctrl.uploading = angular.copy($ctrl.uploading)
    }
  }

  function getImagePath(file) {
    if (file.view) return `/api/${file.view}`
    return file
  }

  function checkFileType(file) {
    let type

    if (file.type) {
      if (file.type.indexOf('image') >= 0) {
        type = 'image'
      } else if (file.type.indexOf('iauditor') >= 0) {
        type = 'iauditor'
      }
    }

    if (file.isImage) type = 'image'

    return type
  }

  function openUploadModal() {
    uploadModal = $uibModal.open({
      component: 'ptUploadsAddFile',
      windowClass: 'record-upload',
      size: 'lg'
    })

    uploadModal.result
      .then(function (files) {
        if ($ctrl.files && $ctrl.files.length && files.length) {
          $ctrl.files = $ctrl.files.concat(files)
        } else if (files.length) {
          $ctrl.files = files
        }

        $ctrl.control.upload()
      })
      .catch(function () {})
  }

  function uploadTypeCheck() {
    uploadsData
      .uploadTypeCheck()
      .then(function (response) {
        $ctrl.shouldShowUploadModal = response
      })
      .catch(function () {})
  }

  function upload() {
    if ($ctrl.files.length) {
      _forEach($ctrl.files, function (file) {
        // If the file type is iAuditor or signature, then the file is already uploaded,
        // we just need to attach it to the record/log/note.
        if (file.type === 'iauditor' || $ctrl.signatureUpload) {
          $ctrl.model.push(file)
          setModel()
        } else if (!file.uploading && !file.progress) {
          setUploading(true)

          uploadsData
            .upload(file, $ctrl.accountId)
            .then(function (data) {
              const fileId = data.fileId
              const response = data.response
              if (fileId) {
                file.value = fileId

                $ctrl.model.push({
                  value: fileId,
                  type: 'upload_id'
                })

                setModel()
              }
            })

            .catch(function () {})
            .finally(function () {
              if (!_some($ctrl.files, { uploading: true })) {
                setUploading(false)
              }
            })
        }
      })
    }
  }

  function remove(uploadId) {
    _remove($ctrl.files, (item: any) => {
      return item.value === uploadId
    })

    _remove($ctrl.model, (item: any) => {
      return item.value === uploadId
    })

    setModel()
  }

  function setModel() {
    $ctrl.onModelChange({
      $event: {
        model: $ctrl.model,
        files: $ctrl.files
      }
    })
  }

  function setUploading(value) {
    $ctrl.onUploadingChange({
      $event: {
        uploading: value
      }
    })
  }
}
