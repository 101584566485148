import * as React from 'react'
import { cloneDeep } from 'lodash'
import { Component } from 'react'
import { View } from 'reactxp'
import { Icon, IconName } from '../../components'
import { createStyledLabel, createStyledText, createStyledView } from '../../components/styled'
import { Color, spacing, iconSizes, fontSizes } from '../../styles'
import { getPlatformSize, getPlatformStyle } from '../../services/platform'
import { ChecklistField, ChecklistSection } from '../index'
import { ChecklistFieldComponent, MetaMode } from './checklist-field'

const Container = createStyledView({
  flex: 1
})

const Title = createStyledView({
  flex: 1,
  flexDirection: 'row',
  cursor: 'pointer'
})

const LabelHolder = createStyledView({
  ...getPlatformStyle({
    mobile: { flex: 1 }
  }),
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: spacing.quarter,
  marginRight: spacing.base
})

const IconHolder = createStyledView({
  marginRight: spacing.half
})

const CountContainer = createStyledView({
  flexDirection: 'row',
  marginLeft: spacing.half,
  alignItems: 'center'
})

const DropDownContainer = createStyledView({
  marginLeft: spacing.half
})

const SectionDescription = createStyledLabel({
  ...getPlatformStyle({
    mobile: { fontSize: fontSizes.base },
    web: { fontSize: 15 }
  }),
  marginHorizontal: spacing.base,
  marginVertical: spacing.half
})

const FieldContainer = createStyledView({
  marginLeft: getPlatformSize(spacing.base, {
    mobile: spacing.half
  })
})

const FieldSpacer = createStyledView({
  height: spacing.half
})

const HeaderSpacer = createStyledView({
  height: spacing.third
})

const ChecklistFieldLabel = createStyledLabel({
  ...getPlatformStyle({
    mobile: { fontSize: fontSizes.base },
    web: { fontSize: 15 }
  }),
  fontWeight: '500'
})

const BaseFontSize = createStyledText({
  ...getPlatformStyle({
    mobile: { fontSize: fontSizes.base },
    web: { fontSize: 15 }
  })
})

interface Props {
  section: ChecklistSection
  collapsible?: boolean
  open?: boolean
  onChange?: (section: ChecklistSection, field: ChecklistField) => void
  onPressMeta?: (fieldIndex: number) => void
  checkedAmount?: number
  totalAmount?: number
  metaMode?: MetaMode
  showSectionIcon?: boolean
}

interface State {
  isOpen: boolean
}

export const fieldCount = (fields: ChecklistField[]) => (fields && fields.length) || 0
export const checkedFieldCount = (fields: ChecklistField[]) => fields.filter((field) => field.value).length

export class ChecklistSectionComponent extends Component<Props, State> {
  public componentWillMount() {
    this.onInitialProps(this.props)
  }

  public render() {
    const {
      section,
      collapsible = true,
      onChange = () => undefined,
      onPressMeta = () => undefined,
      metaMode = 'none',
      showSectionIcon = true
    } = this.props

    const { isOpen } = this.state
    const { fields } = section
    const { checkedAmount = checkedFieldCount(fields), totalAmount = fieldCount(fields) } = this.props

    const [iconName, iconColor] = (() => {
      if (checkedAmount === 0) {
        return ['errorCircle', 'negative'] as [IconName, Color]
      } else if (checkedAmount > 0 && checkedAmount < totalAmount) {
        return ['searchCircle', 'warning'] as [IconName, Color]
      } else if (checkedAmount === totalAmount) {
        return ['checkCircle', 'positive'] as [IconName, Color]
      }

      return ['checkCircle', 'positive'] as [IconName, Color]
    })()

    return (
      <Container>
        <Title onPress={collapsible ? () => this.setState({ isOpen: !isOpen }) : undefined}>
          <LabelHolder>
            {showSectionIcon && (
              <IconHolder>
                <Icon name={iconName} color={iconColor} />
              </IconHolder>
            )}
            <ChecklistFieldLabel>{section.label}</ChecklistFieldLabel>
          </LabelHolder>
          <CountContainer>
            <BaseFontSize>{`${checkedAmount} / ${totalAmount}`}</BaseFontSize>
            {collapsible && (
              <DropDownContainer>
                <Icon name={isOpen ? 'up' : 'down'} size={iconSizes.large} color="black" />
              </DropDownContainer>
            )}
          </CountContainer>
        </Title>

        {isOpen && fields.length > 0 && (
          <View>
            {!!section.description && <SectionDescription>{section.description}</SectionDescription>}
            <HeaderSpacer />
            <FieldContainer>
              {fields.map((field, index) => (
                <View key={field.id}>
                  <ChecklistFieldComponent
                    key={field.id}
                    field={field}
                    onChange={(newField) => {
                      const newSection = cloneDeep(section)
                      newSection.fields[index] = newField
                      return onChange(newSection, newField)
                    }}
                    onPressMeta={() => onPressMeta(index)}
                    metaMode={metaMode}
                  />
                  {index < fields.length - 1 && <FieldSpacer />}
                </View>
              ))}
            </FieldContainer>
          </View>
        )}
      </Container>
    )
  }

  private onInitialProps(props: Props) {
    const { collapsible = true } = props
    const open = collapsible ? this.props.open : true

    this.setState({
      isOpen: open
    })
  }
}
