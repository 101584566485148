import _flatten from 'lodash/flatten'

export function recordsScan($q, recordsData, BlueprintsData) {
  'ngInject'

  let service = {
    codeSearch: codeSearch
  }

  return service

  function codeSearch(code) {
    let defer = $q.defer()
    let promises = []

    // Add Identifiers
    BlueprintsData.getIdentifiers().forEach((field) => {
      if(field.type === 'rfid' || field.type === 'barcode') {
        promises.push(search(field.key, code))
      }
    });

    $q.all(promises).then(function (promiseResult) {
      let found = []

      promiseResult.forEach(function (results) {
        if (results.length) {
          found = found.concat(_flatten(results))
        }
      })

      if (found.length) {
        defer.resolve(found)
      } else {
        defer.reject('non_found')
      }
    })

    return defer.promise
  }

  function search(field, value) {
    let params = {
      filter: field + '=' + value,
      include: 'archived'
    }

    return recordsData.search(params)
  }
}
