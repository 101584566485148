export function invitationsForPublic(InvitationRootModel, collections) {
  'ngInject'

  let service = {
    getAll: getAll
  }
  return service

  ////////////////

  function getAll(email, code) {
    let params = {
        include: 'user,account,subscription',
        email: email,
        code: code
      },
      collection = InvitationRootModel.$collection(params)

    return collections.getAll(collection)
  }
}
