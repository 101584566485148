import * as moment from 'moment-timezone'
import { Activity } from '../models'
import { values, groupBy } from 'lodash'

interface ActivityDiff extends Activity {
  $diff?: number
}

export const groupDates = (activities: Activity[]) => {
  let previousDateMoment = null
  let currentGroupCounter = 1

  activities.forEach((activity: ActivityDiff) => {
    if (!previousDateMoment) {
      activity.$diff = null
    } else if (!activity.$diff) {
      activity.$diff = previousDateMoment.diff(moment(activity.createdAt))
    }

    previousDateMoment = moment(activity.createdAt)
  })

  return values(
    groupBy(activities, (activity: ActivityDiff) => {
      if (!activity.$diff) {
        return currentGroupCounter
      } else if (activity.$diff > 1800000) {
        // 30 mins
        currentGroupCounter = currentGroupCounter + 1
      }

      return currentGroupCounter
    })
  )
}

export const groupUsers = (activitesGroupedByTime) => {
  const activitesGroupedByUsers = activitesGroupedByTime.map((activitiesTimeGroup) => {
    const innerResult = activitiesTimeGroup.reduce((result, activity) => {
      if (result.length && activity.user.id === result[result.length - 1][0].user.id) {
        result[result.length - 1].push(activity)
      } else {
        result.push([activity])
      }

      return result
    }, [])

    return innerResult
  })

  return activitesGroupedByUsers
}
