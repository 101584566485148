export function Controller(gettextCatalog) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {}

  $ctrl.modalTitle = gettextCatalog.getString('Tasks Manager Disabled')

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function () {}
}
