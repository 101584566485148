export function Controller() {
  'ngInject'

  let vm = this

  vm.control = {
    accept: accept
  }

  /////////

  function accept(invitationId) {
    vm.onAccept({
      invitationId: invitationId
    })
  }
}
