export function Controller(permissionsCheck, foldersData, context, ErrorsModal, $uibModal) {
  'ngInject'

  const vm = this

  vm.control = {
    move: move
  }

  vm.account = context.get('account')
  vm.permissionsCheck = permissionsCheck

  vm.$onInit = function () {}

  function move() {
    $uibModal
      .open({
        backdrop: 'static',
        component: 'ptFolderMoveNew',
        resolve: {
          movedFolder: () => vm.folder
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .result.catch(function () {})
  }
}
