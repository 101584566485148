export function MembersNewRoute($stateProvider) {
  'ngInject'

  let newMemberModal

  $stateProvider.state('new-member', {
    parent: 'settings.team',
    url: '/new',
    views: {
      'utility@layoutBase': {
        template: ' '
      }
    },
    onEnter: onAddMemberEnter,
    onExit: onAddMemberExit
  })

  function onAddMemberEnter($uibModal, $state) {
    'ngInject'

    newMemberModal = $uibModal.open({
      component: 'ptMembersNew',
      backdrop: 'static'
    })

    newMemberModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onAddMemberExit() {
    'ngInject'

    newMemberModal.close()
  }
}
