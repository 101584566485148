export function Controller(uploadsData, BASE_URL) {
  'ngInject'

  this.$onInit = () => {
    this.uploadType = uploadsData.getUploadType(this.uploadItem)
  }

  this.fileUrl = () => {
    if (this.uploadItem && this.uploadItem.view) {
      return BASE_URL + this.uploadItem.view + '&width=40&height=40'
    }

    return ''
  }
}
