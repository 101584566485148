export function fullname() {
  'ngInject'

  const fullnameFilter = (user) => {
    if (user && user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`
    } else if (user && user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    }

    return ''
  }

  return fullnameFilter
}
