import lazy from 'lazy.js'
import angular from 'angular'

export const validator = (gettextCatalog) => {
  'ngInject'

  const service = {
    validate,
    rejection
  }

  const rules = {
    required
  }

  const validators = {
    field: {
      money: fieldMoney
    }
  }

  return service

  function validate(type, key, value) {
    if (validators[type] && validators[type][key]) {
      var validationError = validators[type][key](value)

      if (validationError) {
        return invalid(validationError)
      }
    }

    return valid()
  }

  function rejection(response) {
    var reason = response.data && response.data.error

    if (reason === 'validation_error') {
      return 'This field is not valid'
    }

    return 'Please check the value and try again'
  }

  function valid() {
    return {
      valid: true,
      message: null
    }
  }

  function invalid(message) {
    return {
      valid: false,
      message: message
    }
  }

  function results(validations) {
    var result = null

    lazy(validations).each(function (validation) {
      if (!result) {
        result = rules[validation.type](validation.value, validation.message)
      }
    })

    return result
  }

  function required(value, message) {
    if ((angular.isString(value) && value.length === 0) || value === null || angular.isUndefined(value)) {
      return message
    }
  }

  function fieldMoney(value) {
    return results([
      {
        type: 'required',
        value: value.currency,
        message: gettextCatalog.getString('Please select a currency')
      },
      {
        type: 'required',
        value: value.value,
        message: gettextCatalog.getString('Please enter an amount')
      }
    ])
  }
}
