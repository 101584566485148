import angular from 'angular'

export function Controller(TasksData) {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {}

  $ctrl.hasTasks = null

  $ctrl.$onInit = function () {
    $ctrl.hasTasks = TasksData.flag($ctrl.account) === 'active'
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.resolve) {
      $ctrl.account = angular.copy($ctrl.resolve.account)
    }
  }
}
