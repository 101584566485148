import angular from 'angular'
import { react2angular } from 'react2angular'

import ProductsSearch from 'AppComponents/products/ProductsSearch'

export const ptProductsSearchModule = angular
  .module('records.ptProductsSearch', [])
  .component(
    'ptProductsSearch',
    react2angular(ProductsSearch, ['accountId', 'importing', 'viewProduct', 'productImagesUrl'], ['pagination', 'ProductsData'])
  ).name
