export function Controller($element) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    markAsRead
  }

  $ctrl.view = {
    compact: false,
    full: false
  }

  $ctrl.$onInit = function () {}

  $ctrl.$postLink = function () {
    if ($element[0].attributes.hasOwnProperty('compact-view')) {
      $ctrl.view = {
        compact: true,
        full: false
      }
    }

    if ($element[0].attributes.hasOwnProperty('full-view')) {
      $ctrl.view = {
        compact: false,
        full: true
      }
    }
  }

  function markAsRead() {
    $ctrl.onRead({
      $event: {
        id: $ctrl.notification.id,
        elem: $element[0]
      }
    })
  }
}
