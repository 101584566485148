import { createResource } from '../../api/resource'
import { createResponsePaginationTransformer } from '../../api/transformer'
import { Folder } from '../index'

interface Query {
  include?: string
  filter?: string
  cursor?: number
  limit?: number
}

interface Params {
  accountId: string
  query?: Query
}

export const fetchFolders = createResource({
  request: ({ accountId, query }: Params) => ({
    url: `accounts/${accountId}/folders`,
    method: 'GET',
    params: query
  }),
  transform: createResponsePaginationTransformer<Folder>(
    'members',
    'children',
    'records',
    'ancestors',
    'descendants',
    'definitions',
    'user'
  )
})
