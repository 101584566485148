import * as Sentry from '@sentry/browser'

export function $exceptionHandler() {
  'ngInject'

  return function errorCatcherHandler(exception, cause) {
    if (!appConfig.IS_PRODUCTION) {
      console.error(exception, { extra: { cause: cause } })
    }

    Sentry.captureException(exception)
  }
}
