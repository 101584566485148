import view from './view.html'

export function hasError(formlyConfigProvider) {
  'ngInject'

  formlyConfigProvider.setWrapper([
    {
      name: 'bootstrapHasError',
      template: view,
      overwriteOk: true
    }
  ])
}
