import _extend from 'lodash/extend'
import _assign from 'lodash/assign'

export function invitationsForAccount(context, EventsData, collections, permissionsConverter, TutorialsFlags) {
  'ngInject'

  let service = {
    getAll: getAll,
    create: create,
    remind: remind,
    deleteInvitation: deleteInvitation,
    remove: remove
  }
  return service

  ////////////////

  function getAll(extraParams) {
    let params = {
      include: 'user,permissions'
    }
    let collection

    _extend(params, extraParams || {})

    collection = context.get('account').invitations.$collection(params)

    return collections.getAll(collection)
  }

  function create(invite, optionOverrides) {
    let options = {
      notify: true
    }
    let newInvite

    options = _assign(options, optionOverrides)

    newInvite = context.get('account').invitations.$build(invite)

    return newInvite
      .$save()
      .$asPromise()
      .then(function (invitation) {
        TutorialsFlags.setUserFlag('created_account_invite')

        if (options.notify) {
          EventsData.pushEvent('invitation_was_created', {
            invitationId: invitation.$response.data.data.id
          })
        }

        return invitation
      })
  }

  function remind(invite) {
    invite.permissions = permissionsConverter.reverse(invite.permissions)

    return create(invite, { notify: false })
  }

  function deleteInvitation(invitation) {
    return invitation
      .$destroy()
      .$asPromise()
      .then(function () {
        EventsData.pushEvent('invitation_was_deleted', {
          invitationId: invitation.id
        })

        return invitation
      })
  }

  function remove(invitation) {
    invitation.$scope.$remove(invitation)
  }
}
