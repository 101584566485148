export function ApiConfig(restmodProvider) {
  'ngInject'

  restmodProvider.rebase(
    {
      $config: {
        style: 'AMS',
        jsonRoot: 'data'
      }
    },
    'ApiBaseUrl',
    'ApiHeaders'
  )

  restmodProvider.rebase('DefaultPacker')

  restmodProvider.rebase({
    $extend: {
      Model: {
        encodeUrlName: function (_name) {
          return _name.toLowerCase()
        }
      }
    }
  })
}
