import angular from 'angular'

export function foldersExpanded() {
  'ngInject'

  let states = {}

  let service = {
    reset: reset,
    toggle: toggle,
    state: state
  }

  return service

  function reset() {
    states = {}
  }

  function toggle(folderId) {
    if (angular.isDefined(states[folderId])) {
      states[folderId] = !states[folderId]
    } else {
      states[folderId] = true
    }

    return states[folderId]
  }

  function state(folderId) {
    if (angular.isDefined(states[folderId])) {
      return states[folderId]
    }

    return false
  }
}
