import _assign from 'lodash/assign'
import _cloneDeep from 'lodash/cloneDeep'
import _find from 'lodash/find'
import './edit-title.html'
import { Activity } from 'AppCore/activity'
import { Upload } from 'AppCore/upload'
import { User } from 'AppCore/user'

export function Controller(
  $scope,
  $state,
  $uibModal,
  richText,
  session,
  EventsData,
  uploadFileActions,
  TasksData,
  context,
  permissionsCheck
) {
  'ngInject'

  let noteModal
  const account = context.get('account')
  const taskStates = account.task_states.data

  this.$onInit = function () {
    const isViewOnly = permissionsCheck.is('viewOnly', account)
    const canEditTasks = permissionsCheck.can('edit', 'tasks', account)
    this.editable = !isViewOnly && canEditTasks
    this.richText = richText
    this.changedState = null
    this.editTitlePopover = 'components/pt-task/edit-title.html'
    this.isAccountTasks = $state.current.name.indexOf('account.tasks') === 0
    this.isProfileTasks = $state.current.name.indexOf('tasks') === 0
    this.noteFileUploading = false
    this.richTextBody = null
    this.type = 'activity'
    this.richTextBody = `${richText(this.task.body)} <span class="edit-body-icon icon-pencil"></span>`
    this.taskStates = account.task_states.data

    TasksData.getTaskSubscribers(this.task.id).then((subscribers) => {
      this.userIsSubscribed = !!_find(subscribers, { userId: session.user.id })
    })

    $scope.$on('task_assignee_was_added', (event, data) => {
      if (data.data.id && data.data.id === this.task.id) {
        this.task = _assign({}, this.task, { assignees: data.data.assignees })
      }
    })

    $scope.$on('task_assignee_was_removed', (event, data) => {
      if (data.data.id && data.data.id === this.task.id) {
        this.task = _assign({}, this.task, { assignees: data.data.assignees })
      }
    })

    $scope.$on('task_state_was_updated', (event, data) => {      
      if (data.data.id && data.data.id === this.task.id) {
        const newTask = {
          ...this.task, 
          state: data.data.state,
          taskStateKey: data.data.task_state_key,
          taskStateName: data.data.task_state_name
        }        

        this.task = newTask
      }
    })

    $scope.$on('task_was_updated', (event, data) => {                  
      if (data.data.id && data.data.id === this.task.id) {
        this.task = _assign({}, this.task, {
          title: data.data.title,
          body: data.data.body,
          deadlineAt: data.data.deadlineAt
        })

      }
    })

    $scope.$on('task_was_unassigned_from_log', (event, data) => {
      if (data.data.id && data.data.id === this.task.id) {
        delete this.task.log

        this.task = { ...this.task }
      }
    })

    $scope.$on('task_was_assigned_to_log', (event, data) => {
      if (data.data.id && data.data.id === this.task.id) {
        this.task = { ...this.task, log: data.data.log }
      }
    })

    $scope.$on('task_upload_was_created', (event, data) => {
      if (data.data.id && data.data.id === this.task.id) {
        this.task = { ...this.task, uploads: data.data.uploads }
      }
    })

    $scope.$on('task_upload_was_deleted', (event, data) => {
      if (data.data.id && data.data.id === this.task.id) {
        this.task = { ...this.task, uploads: data.data.uploads }
      }
    })
  }

  this.$onChanges = function (changes) {
    if (changes.resolve) {
      this.task = _cloneDeep(this.resolve.task)

      // Mark the user as admin if they are an admin within the account, or if they created the task.
      this.isAdmin = _cloneDeep(this.resolve.isAdmin) || this.task.user.id === session.user.id
    }
  }

  this.pressActivity = (activity: Activity) => {
    $state.go('.activity', { activityId: activity.id })
  }

  this.pressUpload = (upload: Upload, user: User) => {
    uploadFileActions.viewFile(upload, user)
  }

  this.closeModal = (reason) => {
    this.dismiss({ $value: reason || 'cancel' })
  }

  this.openStateChangeModal = () => {
    $uibModal
      .open({
        backdrop: 'static',
        component: 'ptTaskStateChange',
        windowClass: 'task-state-change',
        resolve: {
          task: () => this.task,
          isAdmin: () => this.isAdmin
        }
      })
      .result.catch((state) => {
        if (this.task.state !== state) {          
          const actualState = taskStates.find(t => t.id === state)          
          TasksData.updateTaskState(this.task.id, actualState.id)
        }
      })
  }

  this.openNoteModal = () => {
    noteModal = $uibModal.open({
      component: 'ptTaskNoteModal',
      windowClass: 'task-note-modal',
      resolve: {
        task: () => this.task
      }
    })

    noteModal.result.catch((newNote) => {
      EventsData.pushEvent('task_note_was_created', {
        data: {
          comment: newNote.comment,
          id: this.task.taskId
        }
      })

      // New object triggers child components
      this.task = { ...this.task }
    })
  }

  this.toggleSubscription = () => {
    TasksData.action(this.task.id, this.userIsSubscribed ? 'unsubscribe' : 'subscribe')

    this.userIsSubscribed = !this.userIsSubscribed
  }
}
