import angular from 'angular'

export function Controller($state, richText, TasksResolver) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    openActivity
  }

  $ctrl.isProfileTasks = $state.current.name.indexOf('tasks') === 0
  $ctrl.richText = richText

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.data) {
      $ctrl.data = angular.copy($ctrl.data)
    }
  }

  function openActivity() {
    $state.go($state.current.name + '.activity', { activityId: $ctrl.data.id })
  }

  this.goToTask = () => {
    TasksResolver.resolve({ taskId: this.data.task.id })
  }
}
