import { newLogRoutes } from '../../../logs/components/pt-logs-new/route'

export const createTaskRoutes = [
  'bucket.records.create-task',
  'folder.records.create-task',
  'folders.records.create-task',
  'account.records.create-task',
  'activities.records.create-task'
]

export function TaskCreateRoute($stateProvider) {
  'ngInject'

  let taskCreateModal

  const routes = [...createTaskRoutes, ...newLogRoutes.map((route) => `${route}.create-task`)]

  routes.forEach((route) => {
    $stateProvider.state(route, taskCreateModalDefinition())
  })

  function taskCreateModalDefinition() {
    return {
      url: '/new-task',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      backdrop: 'static',
      onEnter: onTaskCreateEnter,
      onExit: onTaskCreateExit
    }
  }

  function onTaskCreateEnter($uibModal, $state, $stateParams, page) {
    'ngInject'
    page.setTitle('New Task')
    taskCreateModal = $uibModal.open({
      component: 'ptTaskCreate',
      size: 'l',
      backdrop: 'static',
      resolve: {
        recordId: () => $stateParams.recordId
      }
    })

    // If the user dismisses the modal, return to parent state.
    // result.then() = closed a modal (via change of state)
    // result.catch() = dissmiss a modal (via close button)
    taskCreateModal.result.catch(() => {
      $state.go('^')
    })
  }

  function onTaskCreateExit(page) {
    'ngInject'
    page.resetTitle()
    taskCreateModal.close()
  }
}
