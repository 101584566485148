import React from 'react'
import styled from 'styled-components'

import { typography } from 'AppStyles/theme'

export interface RecordsBatchCloneFormProps {
  records: any
  formUpdated: Function
}

const Label = styled.label`
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
`

class RecordsBatchCloneForm extends React.Component<RecordsBatchCloneFormProps> {
  state = {
    quantity: '1',
    errorMessage: ''
  }

  handleChange = (e) => {
    this.setState(
      {
        quantity: e.target.value
      },
      () => {
        this.props.formUpdated(this.state)
      }
    )
  }

  render() {
    return (
      <React.Fragment>
        <ul className="m-b list">
          {this.props.records.map((record) => (
            <li key={record.id}>{record.name}</li>
          ))}
        </ul>
        <div>
          <Label>Quantity (Max. 100) *</Label>
          <input
            className="form-control"
            type="number"
            min="1"
            max="100"
            onChange={this.handleChange}
            value={this.state.quantity}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default RecordsBatchCloneForm
