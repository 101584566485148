import { Controller } from './controller'
import view from './view.html'

export const ptFolderActions = {
  bindings: {
    folder: '<',
    folderMeta: '<'
  },
  controller: Controller,
  template: view
}
