import _filter from 'lodash/filter'

export function Controller($timeout, SubscriptionsData, accountsData, gettextCatalog, $state) {
  'ngInject'

  let vm = this
  let s = {
    /// Field label
    subscriptionName: gettextCatalog.getString('Subscription name'),
    companyName: gettextCatalog.getString('Company name'),
    /// Field label
    country: gettextCatalog.getString('Country'),
    /// Select box default
    selectCountry: gettextCatalog.getString('select country'),
    /// Field label
    industry: gettextCatalog.getString('Industry'),
    /// Select box default
    selectIndustry: gettextCatalog.getString('select industry'),
    /// Field label
    resellerAccount: gettextCatalog.getString('Reseller account'),
    /// Selext box default
    selectReseller: gettextCatalog.getString('select reseller'),
    /// Field label
    accountName: gettextCatalog.getString('Account name'),
    /// Field label
    options: gettextCatalog.getString('Options'),
    /// Radio option
    createStandard: gettextCatalog.getString('Create a standard account'),
    /// Field label
    createDemo: gettextCatalog.getString('Create a demo account'),
    /// Field label
    cloneAccount: gettextCatalog.getString('Clone an existing account'),
    /// Field label - Select the account template to use
    selectTemplate: gettextCatalog.getString('Select module'),
    /// Field label
    selectDemo: gettextCatalog.getString('Select demo module'),
    /// Field help
    selectDemoHelp: gettextCatalog.getString('Select the example data to add to the demo account'),
    /// Field label
    accountToClone: gettextCatalog.getString('Account to clone'),
    /// Selext box default
    selectAccount: gettextCatalog.getString('select account'),
    /// Checkbox label
    cloneRecords: gettextCatalog.getString('Clone records'),
    /// Checkbox label
    makeDemoAccount: gettextCatalog.getString('Make this a demo account')
  }

  vm.control = {
    save: save
  }

  vm.saving = false
  vm.error = null
  vm.showWaitMessage = false
  vm.model = {
    name: '',
    options: 'default',
    accountName: '',
    template: 'default',
    with_data: false,
    demo: false
  }
  vm.formFields = [
    {
      template: '<legend translate>Subscription</legend>'
    },
    {
      className: 'fieldset',
      fieldGroup: [
        {
          key: 'name',
          type: 'input',
          ngModelAttrs: {
            ptFocus: {
              attribute: 'pt-focus'
            }
          },
          templateOptions: {
            required: true,
            label: s.subscriptionName,
            ptFocus: true
          }
        },
        {
          key: 'company_name',
          type: 'input',
          templateOptions: {
            required: true,
            label: s.companyName,
            ptFocus: true
          }
        }
      ]
    },
    {
      key: 'country_code',
      type: 'select',
      templateOptions: {
        label: s.country,
        required: true,
        options: ([{ name: '-- ' + s.selectCountry + ' --' }] as any).concat(vm.resolve.countries),
        ngOptions: 'country.alpha_2 as country.name for country in to.options'
      }
    },
    {
      key: 'industry_id',
      type: 'select',
      templateOptions: {
        label: s.industry,
        required: true,
        options: ([{ name: '-- ' + s.selectIndustry + ' --' }] as any).concat(
          (function () {
            return _filter(vm.resolve.industries, { default: false }).concat(
              _filter(vm.resolve.industries, { default: true })
            )
          })()
        ),
        ngOptions: 'industry.id as industry.name for industry in to.options'
      }
    },
    {
      key: 'reseller_id',
      type: 'select',
      templateOptions: {
        label: s.resellerAccount,
        required: true,
        options: [{ name: '-- ' + s.selectReseller + ' --' }].concat(vm.resolve.resellers),
        ngOptions: 'reseller.id as reseller.name for reseller in to.options'
      },
      hideExpression: function () {
        return vm.resolve.resellers.length < 2
      },
      expressionProperties: {
        required: function () {
          return !vm.resolve.resellers.length
        }
      }
    },
    {
      template: '<legend translate>The first account</legend>'
    },
    {
      className: 'fieldset',
      fieldGroup: [
        {
          key: 'accountName',
          type: 'input',
          templateOptions: {
            required: true,
            label: s.accountName
          }
        },
        {
          key: 'options',
          type: 'radio',
          templateOptions: {
            label: s.options,
            options: [
              {
                name: s.createStandard,
                value: 'default'
              },
              {
                name: s.createDemo,
                value: 'with_data'
              },
              {
                name: s.cloneAccount,
                value: 'clone'
              }
            ]
          }
        },
        {
          key: 'template',
          type: 'select',
          templateOptions: {
            label: s.selectTemplate,
            options: _filter(vm.resolve.templates, { template: true }),
            ngOptions: 'type.key as type.name for type in to.options'
          },
          hideExpression: function () {
            return vm.model.options !== 'default'
          }
        },
        {
          key: 'template',
          type: 'select',
          templateOptions: {
            label: s.selectDemo,
            help: s.selectDemoHelp,
            options: _filter(vm.resolve.templates, { demo: true }),
            ngOptions: 'type.key as type.name for type in to.options'
          },
          hideExpression: function () {
            return vm.model.options !== 'with_data'
          }
        },
        {
          template:
            '<p><a pt-contact="' +
            gettextCatalog.getString('What module or account setup would you like?') +
            '">' +
            gettextCatalog.getString('Want a different module?') +
            '</a></p>'
        },
        {
          key: 'account_id',
          type: 'select',
          templateOptions: {
            label: s.accountToClone,
            required: true,
            options: [{ name: 'Loading accounts....' }],
            ngOptions: 'account.id as account.name for account in to.options'
          },
          hideExpression: function () {
            return vm.model.options !== 'clone'
          }
        },
        {
          key: 'with_records',
          type: 'checkbox',
          templateOptions: {
            label: s.cloneRecords
          },
          hideExpression: function () {
            return vm.model.options !== 'clone'
          }
        },
        {
          key: 'demo',
          type: 'checkbox',
          templateOptions: {
            label: s.makeDemoAccount
          },
          hideExpression: function () {
            return vm.model.options !== 'clone'
          }
        }
      ]
    }
  ]
  vm.error = null
  vm.submitting = false

  ////////////

  vm.$onInit = function () {
    if (vm.resolve.resellers.length === 1) {
      // Select the only reseller, as the select option will be hidden
      vm.model.reseller_id = vm.resolve.resellers[0].id
    }
    loadAccounts()
  }

  function loadAccounts() {
    accountsData.getAll(accountsData.init()).then((accounts) => {
      vm.accsLoaded = true
      vm.accounts = accounts
      vm.formFields[6].fieldGroup[5].templateOptions.options = [{ name: '-- ' + s.selectAccount + ' --' }].concat(
        accounts
      )
    })
  }

  function save() {
    //Affiliate token is supplied as a queryparam in the urls from the demo request form.
    if ($state.params.affiliate_token) {
      vm.model.affiliate_id = $state.params.affiliate_token
    }

    if (vm.form.$valid) {
      vm.saving = true
      vm.error = null

      SubscriptionsData.create(vm.model).then(subscribeSuccess, saveError)

      $timeout(function () {
        vm.showWaitMessage = true
      }, 4000)
    }
  }

  function subscribeSuccess(subscription) {
    let data: any = {}

    data.subscription_id = subscription.id
    data.name = vm.model.accountName

    if (vm.model.options === 'default') {
      data.template = vm.model.template
    }

    // Set demo flag
    if (vm.model.options === 'with_data') {
      data.demo = true
      data.with_data = true
      data.template = vm.model.template
    }

    if (vm.model.options === 'clone') {
      data.demo = vm.model.demo
      data.account_id = vm.model.account_id
      data.with_records = vm.model.with_records
    }

    accountsData.create(data).then(saveSuccess, saveError)
  }

  function saveSuccess() {
    vm.dismiss({ $value: 'saved' })
  }

  function saveError(rejection) {
    vm.saving = false
    vm.error = rejection.$response.data.error
  }
}
