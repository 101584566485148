import _assign from 'lodash/assign'
import _cloneDeep from 'lodash/cloneDeep'

export function Controller($scope, $state) {
  'ngInject'

  const $ctrl = this

  $ctrl.isProfileTasks = $state.current.name.indexOf('tasks') === 0
  $ctrl.deadlinePassed = null

  $ctrl.$onInit = function () {
    $scope.$on('task_state_was_updated', (event, data) => {
      if (data.data.id && data.data.id === $ctrl.task.id) {
        $ctrl.task = _assign({}, $ctrl.task, { state: data.data.state })
      }
    })

    $scope.$on('task_assignee_was_added', (event, data) => {
      if (data.data.id && data.data.id === $ctrl.task.id) {
        $ctrl.task = _assign({}, $ctrl.task, { assignees: data.data.assignees })
      }
    })

    $scope.$on('task_assignee_was_removed', (event, data) => {
      if (data.data.id && data.data.id === $ctrl.task.id) {
        $ctrl.task = _assign({}, $ctrl.task, { assignees: data.data.assignees })
      }
    })

    $scope.$on('task_was_updated', (event, data) => {
      if (data.data.id && data.data.id === $ctrl.task.id) {
        $ctrl.task = _assign({}, $ctrl.task, {
          title: data.data.title,
          body: data.data.body,
          deadlineAt: data.data.deadlineAt
        })

        $ctrl.deadlinePassed = hasDeadlinePassed()
      }
    })

    $ctrl.deadlinePassed = hasDeadlinePassed()
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.task) {
      $ctrl.task = _cloneDeep($ctrl.task)
    }
  }

  $ctrl.parsedState = function () {
    return $ctrl.task.taskState.data.name
  }

  function hasDeadlinePassed() {
    let deadline = new Date($ctrl.task.deadlineAt)
    let now = new Date()

    return deadline < now
  }
}
