export function AccountTeamRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('settings.team', {
    url: '/team',
    views: {
      'content@layoutFull': 'ptAccountTeam'
    },
    onEnter: onAccountTeam
  })

  function onAccountTeam(gettextCatalog, page) {
    'ngInject'

    /// Page title
    page.setTitle(gettextCatalog.getString('Team settings'))
  }
}
