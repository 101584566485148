import _cloneDeep from 'lodash/cloneDeep'
import _find from 'lodash/find'

export function Controller() {
  'ngInject'

  this.selectAllField = false;

  this.getFields = () => this.section.fields?.data || this.section.fields || []

  this.$onInit = () => {
    this.section.$show = this.checklistOptions.isExpanded

    this.getFields().forEach((field) => {
      if (field.type === 'string') {
        if (!field.value && field.options && field.options.length > 0) {
          field.value = field.options[field.options.length - 1].value
        }
        field.defaultValue = field.value
      }

      if (field.type === 'date' && field.value) {
        field.value = new Date(field.value);
      }
    })

    this.onUpdateChecklistSection({ section: this.section })
  }

  this.$onChanges = (changes) => {
    if (changes.section) {
      this.section = _cloneDeep(this.section)
    }
  }

  this.toggleSection = () => {
    if (!this.checklistOptions.canExpand) {
      return
    }
    this.section.$show = !this.section.$show
  }

  this.valueChange = () => {
    this.onUpdateChecklistSection({ section: this.section })
    
    this.selectAllField = !this.section.fields.data.some((field) => {
      if (field.type === 'boolean') {
        return !field.value
      } else {
        return field.options && field.options[0] && field.value !== field.options[0].value;
      }
    })
      

  }

  this.selectAll = () => {
    this.onUpdateChecklistSection({ section: this.section })

    this.getFields().forEach((field) => {
      if (field.type === 'boolean') {
        field.value = this.selectAllField
      } else {
        field.value = this.selectAllField ? field.options?.[0]?.value : field.defaultValue;
      }
    })
  }
}
