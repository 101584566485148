export function ReportsModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}

export function ReportsProjectedChecksModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}

export function ReportsComplianceModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}
