export function foldersResolves(foldersData, foldersTree, recordsViewMode, ErrorsModal) {
  'ngInject'

  let service = {
    folder: folder,
    parentFolder: parentFolder,
    treeSegment: treeSegment
  }

  return service

  function folder(account, id) {
    return foldersData.getFolder(account, id, { cache: true, include: 'ancestors,user' }).catch((response) => {
      ErrorsModal.openModal(response.error)
    })
  }

  function parentFolder(account, id) {
    return foldersData.getFolder(account, id).catch((response) => {
      ErrorsModal.openModal(response.error)
    })
  }

  function treeSegment(account, id, params) {
    if (recordsViewMode.get() === 'record') {
      return []
    }

    return foldersTree.fetchTree({
      account,
      folderId: id,
      filter: true,
      ...(params && { params })
    })
  }
}
