import angular from 'angular'

import { settings } from './settings'

import { checkbox } from './types/checkbox'
import { fieldMatch } from './types/field-match'
import { frequencyId } from './types/frequency-id'
import { input } from './types/input'
import { inputArray } from './types/input-array'
import { inputDatepicker } from './types/input-datepicker'
import { inputSuggest } from './types/input-suggest'
import { money } from './types/money'
import { selectSuggest } from './types/select-suggest'
import { textareaExpanding } from './types/textarea-expanding'

import { container } from './wrappers/container'
import { copyDown } from './wrappers/copy-down'
import { hasError } from './wrappers/has-error'
import { helpBlock } from './wrappers/help-block'
import { label } from './wrappers/label'

import { validation } from './validation/messages'

export const formlyModule = angular
  .module('common.formly', [])
  .run(settings)
  .run(checkbox)
  .run(fieldMatch)
  .run(frequencyId)
  .run(input)
  .run(inputArray)
  .run(inputDatepicker)
  .run(inputSuggest)
  .run(money)
  .run(selectSuggest)
  .run(textareaExpanding)
  .run(validation)
  .config(container)
  .config(copyDown)
  .config(hasError)
  .config(helpBlock)
  .config(label).name
