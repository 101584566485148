import { Controller } from './controller'
import view from './view.html'

export const ptActivityCertificateWasCreated = {
  bindings: {
    data: '<',
    onOpenRecord: '&',
    activityOptions: '<',
    close: '&',
    dismiss: '&',
    resolve: '<'
  },
  template: view,
  controller: Controller
}
