import { Controller } from './controller'
import view from './view.html'

export const ptFoldersSelect = {
  template: view,
  controller: Controller,
  bindings: {
    memberFolders: '<',
    onReady: '&',
    onChanges: '&'
  }
}
