import angular from 'angular'

export function Controller($state) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    markAsRead,
    openPartnerhub
  }

  $ctrl.$onInit = function () {
    $ctrl.subscription = $ctrl.notification.meta.consumer_subscription_uuid
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.notification) {
      $ctrl.notification = angular.copy($ctrl.notification)
    }

    if (changes.view) {
      $ctrl.view = angular.copy($ctrl.view)
    }
  }

  function markAsRead(event) {
    // Send our own onRead event back up to our components
    $ctrl.onRead()
    // Prevent clicks from firing on parent components. e.g.:
    // The notification page has a ui-sref which would open a modal.
    event.stopPropagation()
  }

  function openPartnerhub() {
    $state.go('partner-hub', { subscriptionId: $ctrl.subscription })
  }
}
