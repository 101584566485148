import { transformSnakeToCamel } from 'AppCore/api/transformer'

export const EventsTranslator = () => {
  'ngInject'

  const createFolderDto = (eventName, data) => ({
    folderId: data.data.id,
    parentId: data.data.parent_id
  })

  const createAccessDto = (eventName, data) => ({
    folderId: data.data.folder.data.id,
    starred: data.data.starred,
    accessId: data.data.id
  })

  const createHistoryDto = (eventName, data) => {
    let type = 'log'

    if (eventName.substr(0, 4) === 'note') {
      type = 'note'
    }

    return {
      historyId: data.data.id,
      recordId: data.data.record.data.id,
      active: data.data.active,
      type: type,
      folderId: data.data.record.data.folder.data.id
    }
  }

  const createFieldDto = (eventName, data) => ({
    fieldId: data.data.id,
    recordId: data.data.record.data.id,
    folderId: data.data.record.data.folder.data.id,
    value: data.data.value
  })

  const createRecordDto = (eventName, data) => ({
    recordId: data.data.id,
    folderId: data.data.folder.data.id
  })

  const createInvitationDto = (eventName, data) => ({
    invitationId: data.data.id
  })

  const createOwnerDto = (eventName, data) => ({
    ownerId: data.data.id
  })

  const createMemberDto = (eventName, data) => ({
    memberId: data.data.id
  })

  const createAccountDto = (eventName, data) => ({
    accountId: data.data.id
  })

  const createSubscriptionDto = (eventName, data) => ({
    subscriptionId: data.data.id
  })

  const createProcessDto = (eventName, data) => ({
    processId: data.data.id
  })

  const createActivityDto = (eventName, data) => ({
    data: data
  })

  const createNotificationDto = (eventName, data) => ({
    data: data.data
  })

  const createIauditorDto = (eventName, data) => ({
    data: data.data
  })

  const createTaskStateDto = (eventName, data) => ({
    data: {
      id: data.data.id,
      state: data.data.state,
      record: {
        id: data.data.record.data.id
      },
      ...data.data
    }
  })

  const createTaskCreatedDto = (eventName, data) => ({
    data: {
      id: data.data.id,
      record: {
        id: data.data.record.data.id
      }
    }
  })

  const createTaskUpdatedDto = (eventName, data) => ({
    data: {
      id: data.data.id,
      title: data.data.title,
      body: data.data.body,
      deadlineAt: data.data.deadline_at,
      record: {
        id: data.data.record.data.id
      }
    }
  })

  const createTaskAssigneeDto = (eventName, data) => {
    data.data.assignees.data.forEach((assignee) => {
      assignee.user = assignee.user.data
    })

    data.data.assignees = data.data.assignees.data

    return {
      data: {
        id: data.data.id,
        assignees: transformSnakeToCamel(data.data.assignees)
      }
    }
  }

  const createTaskNoteDto = (eventName, data) => ({
    data: {
      comment: data.data.comment,
      id: data.data.task_id
    }
  })

  const createTaskOverdueDto = (eventName, data) => ({
    data: {
      id: data.data.task_id
    }
  })

  const createTaskLogAssignedDto = (eventName, data) => {
    data.data.log = data.data.log.data

    return {
      data: {
        id: data.data.id,
        log: transformSnakeToCamel(data.data.log)
      }
    }
  }

  const createTaskUploadWasChangedDto = (eventName, data) => {
    data.data.uploads = data.data.uploads.data

    return {
      data: {
        id: data.data.id,
        uploads: transformSnakeToCamel(data.data.uploads)
      }
    }
  }

  const createTaskLogUnassignedDto = (eventName, data) => ({
    data: {
      id: data.data.id
    }
  })

  const createChecklistsChangedDto = (eventName, data) => {
    return {
      data: {
        id: data[0]
      }
    }
  }

  const translators = {
    folder_was_created: createFolderDto,
    folder_was_updated: createFolderDto,
    folder_was_deleted: createFolderDto,
    access_was_created: createAccessDto,
    access_was_updated: createAccessDto,
    access_was_deleted: createAccessDto,
    log_was_updated: createHistoryDto,
    log_was_created: createHistoryDto,
    log_was_deactivated: createHistoryDto,
    log_was_activated: createHistoryDto,
    note_was_created: createHistoryDto,
    note_was_deactivated: createHistoryDto,
    note_was_activated: createHistoryDto,
    record_was_created: createRecordDto,
    record_was_imported: createRecordDto,
    record_was_cloned: createRecordDto,
    record_was_updated: createRecordDto,
    record_was_moved: createRecordDto,
    record_upload_was_added: createRecordDto,
    record_upload_was_updated: createRecordDto,
    record_was_deleted: createRecordDto,
    record_was_undeleted: createRecordDto,
    field_was_created: createFieldDto,
    field_was_updated: createFieldDto,
    field_was_deleted: createFieldDto,
    certificate_was_created: createRecordDto,
    invitation_was_created: createInvitationDto,
    invitation_was_updated: createInvitationDto,
    invitation_was_deleted: createInvitationDto,
    invitation_was_accepted: createInvitationDto,
    owner_was_created: createOwnerDto,
    owner_was_updated: createOwnerDto,
    member_was_created: createMemberDto,
    member_was_updated: createMemberDto,
    account_was_created: createAccountDto,
    subscription_was_created: createSubscriptionDto,
    process_created: createProcessDto,
    process_completed: createProcessDto,
    process_read: createProcessDto,
    activity_was_created: createActivityDto,
    notification_was_created: createNotificationDto,
    iauditor_audit_was_added: createIauditorDto,
    iauditor_audit_was_used: createIauditorDto,
    iauditor_audit_was_removed_from_record: createIauditorDto,
    task_state_was_updated: createTaskStateDto,
    task_assignee_was_added: createTaskAssigneeDto,
    task_assignee_was_removed: createTaskAssigneeDto,
    task_was_created: createTaskCreatedDto,
    task_was_updated: createTaskUpdatedDto,
    task_note_was_created: createTaskNoteDto,
    task_was_overdue: createTaskOverdueDto,
    task_upload_was_created: createTaskUploadWasChangedDto,
    task_upload_was_deleted: createTaskUploadWasChangedDto,
    task_was_assigned_to_log: createTaskLogAssignedDto,
    task_was_unassigned_from_log: createTaskLogUnassignedDto,
    checklists_were_added_to_account: createChecklistsChangedDto,
    checklists_were_removed_from_account: createChecklistsChangedDto
  }

  const translate = (eventName, data) => {
    if (translators[eventName]) {
      return translators[eventName](eventName, data)
    }

    return null
  }

  const eventNames = () => {
    return Object.keys(translators)
  }

  return {
    translate,
    eventNames
  }
}
