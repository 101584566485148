import _find from 'lodash/find'
import { ProductSearchField } from 'AppCore/product'

export function Controller(gettextCatalog, ImportsData, $state, pagination, $scope) {
  'ngInject'

  const $ctrl = this

  $ctrl.self = gettextCatalog.getString('Imports')

  $ctrl.control = {
    getProductIdentifiers,
    getProductName,
    loadMoreImports,
    startImport
  }

  $ctrl.canLoadMore = null
  $ctrl.imports = ImportsData.getLimitedImports()
  $ctrl.loading = false

  $ctrl.$onInit = function () {
    $ctrl.control.loadMoreImports()

    $scope.$on('product_import_created', refreshImportList)
    $scope.$on('product_import_complete', refreshImportList)
  }

  $ctrl.$onChanges = function () {}

  function getProductIdentifiers(product) {
    let productIdentifiers = []

    product.fields.forEach((field) => {
      // Create a string of identifiers for the view, to help the user see what product this is
      if (field.type === 'identifier') {
        productIdentifiers.push(field.value)
      }
    })

    return `(${productIdentifiers.join(', ')})`
  }

  function getProductName(product) {
    return _find(product.fields, <ProductSearchField>{ key: 'name' }).value
  }

  function loadMoreImports() {
    $ctrl.loading = true

    ImportsData.fetchImports($ctrl.imports)
      .then(() => {
        $ctrl.canLoadMore = pagination.hasMorePages($ctrl.imports)
      })
      .finally(() => {
        $ctrl.loading = false
      })
  }

  function refreshImportList() {
    $ctrl.imports.$refresh()
  }

  function startImport(event, importData) {
    event.stopPropagation()

    $state.go('.import.accounts', { importId: importData.id })
  }
}
