import _each from 'lodash/each'

export function Controller($scope, $timeout, lookupsData, gettextCatalog) {
  'ngInject'

  $scope.control = {
    getAvailableItems: getAvailableItems,
    popoverClose: popoverClose,
    popoverToggle: popoverToggle,
    updateTags: updateTags
  }

  $scope.popover = {
    open: false
  }

  $scope.pickerOptions = {
    searchPlaceholder: gettextCatalog.getString('Find/Create ') + $scope.to.label + '...',
    contextLabel: $scope.to.label
  }

  function getAvailableItems() {
    return lookupsData
      .fetch($scope.options.data.field.source.key, '', {
        limit: 100,
        all: true
      })
      .then(function (labels) {
        _each(labels, function (item) {
          item.text = item.value
        })
        return labels
      })
  }

  function popoverClose() {
    $scope.popover = {
      open: false
    }
  }

  function popoverToggle() {
    let newValue = !$scope.popover.open

    $scope.popover = {
      open: newValue
    }
  }

  function updateTags(tags) {
    $scope.model[$scope.options.key] = tags
  }
}
