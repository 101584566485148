export function ptBtnLoading() {
  'ngInject'

  let directive = {
    link: link
  }

  return directive

  function link(scope, element, attrs) {
    scope.$watch(
      function () {
        return scope.$eval(attrs.ptBtnLoading)
      },
      function (value) {
        if (value) {
          if (!attrs.hasOwnProperty('ngDisabled')) {
            element.addClass('disabled').attr('disabled', 'disabled')
          }

          element.data('resetText', element.html())
          element.html(element.data('loading-text'))
        } else {
          if (!attrs.hasOwnProperty('ngDisabled')) {
            element.removeClass('disabled').removeAttr('disabled')
          }

          element.html(element.data('resetText'))
        }
      }
    )
  }
}
