export function tracking($analytics) {
  'ngInject'

  let service = {
    setUser: setUser,
    trackEvent: trackEvent
  }
  return service

  function setUser(username) {
    $analytics.setUsername(username)
  }

  function trackEvent({ action, category, label }) {
    const data = {
      ...(category && { category }),
      ...(label && { label })
    }

    $analytics.eventTrack(action, data)
  }
}
