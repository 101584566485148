import _filter from 'lodash/filter'

export function invitationsTypes() {
  'ngInject'

  let service = {
    accepted: accepted,
    pending: pending
  }
  return service

  ////////////////

  function accepted(invitations, type) {
    let acceptedInvites = _filter(invitations, 'acceptedAt')

    if (type) {
      return _filter(acceptedInvites, { type: type })
    }

    return acceptedInvites
  }

  function pending(invitations, type) {
    let pendingInvites = _filter(invitations, { acceptedAt: undefined })

    if (type) {
      return _filter(pendingInvites, { type: type })
    }

    return pendingInvites
  }
}
