import lazy from 'lazy.js'
import _remove from 'lodash/remove'
import _omit from 'lodash/omit'
import _forIn from 'lodash/forIn'
import _uniqBy from 'lodash/uniqBy'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment-timezone'
import { Record } from 'AppCore/record'

export function Controller(TutorialsFlags, $uibModal, $scope, $state, $stateParams, context, $q) {
  'ngInject'

  const forms: any = {}

  const getDiscardCriteriasAdvices = () => {
    const questions = []

    _forIn(this.discardCriterias, (criteria) => {
      criteria.questions.forEach((question) => {
        if (question.value) {
          questions.push(question)
        }
      })
    })

    const advices = _uniqBy(questions, 'id').reduce((uniqAdvices, question) => {
      uniqAdvices.push(question.advice)

      return uniqAdvices
    }, [])

    return advices
  }

  this.$onInit = () => {
    this.records = this.resolve.records
    this.successIds = []
    this.saving = false
    this.multiple = this.records.length > 1
    this.discardCriterias = this.records.reduce((criterias, record) => {
      if (record.discard_criteria) {
        criterias[record.id] = _cloneDeep(record.discard_criteria.data)
        criterias[record.id].questions = criterias[record.id].questions.data
      }

      return criterias
    }, {})

    this.discardCriteriasAdvices = []

    TutorialsFlags.getUserFlag('created_log')
      .then((flag) => {
        this.tutorialLog = flag
      })
      .catch(() => {})

    TutorialsFlags.getUserFlag('read_discard_criteria_warning')
      .then((flag) => {
        this.tutorialDiscardCriteria = flag
      })
      .catch(() => {})

    $scope.$on('tutorial_completed', (event, data) => {
      if (
        this.tutorialDiscardCriteria &&
        data.tutorial.id === this.tutorialDiscardCriteria.id &&
        this.tutorialDiscardCriteria.completed_at === null
      ) {
        this.tutorialDiscardCriteria.completed_at = moment().format('YYYY-MM-DD HH:mm:ss')
      }
    })
  }

  this.allValid = () => {
    let valid = true

    _forIn(forms, (form) => {
      if (!form.isValid()) {
        valid = false
      }
    })

    return valid
  }

  this.copyDown = (recordId, fieldKey, value) => {
    let idMatched = false

    lazy(this.records).each((record) => {
      if (idMatched) {
        forms[record.id].setField(fieldKey, value)
      } else if (record.id === recordId) {
        idMatched = true
      }
    })
  }

  this.deregister = (scope) => {
    delete forms[scope.record.id]
  }

  this.register = (scope) => {
    forms[scope.record.id] = scope
  }

  this.remove = (reason, recordId) => {
    _remove(this.records, (record: Record) => {
      return record.id === recordId
    })

    this.discardCriterias = _omit(this.discardCriterias, recordId)

    this.discardCriteriasAdvices = getDiscardCriteriasAdvices()

    if (reason === 'success') {
      this.successIds.push(recordId)
    }

    if (this.records.length === 0) {
      this.dismiss({ $value: 'inspections_completed' })

      if (this.resolve.isBatch) {
        $state.go('^.action-records', { recordIds: this.successIds.join(','), action: 'inspection' }).catch((e) => {
          $state.go('^')
        });
      } else {
        $state.go('^')
      }
    }
  }

  this.save = () => {
    const formSaves = []

    if (this.allValid()) {
      this.saving = true

      _forIn(forms, (form) => {
        formSaves.push(form.save())
      })
    }

    $q.all(formSaves)
      .catch((response) => {})
      .finally(() => {
        this.saving = false
      })
  }

  this.scrollIntoView = () => {
    const element = window.document.getElementById(this.jumpToRecord)

    if (element) {
      element.scrollIntoView()
    }
  }

  this.discardCriteriaUpdated = (recordId, criteria) => {
    this.discardCriterias[recordId] = criteria
    this.discardCriteriasAdvices = getDiscardCriteriasAdvices()
  }

  this.discardCriteriaResults = () => {
    $uibModal
      .open({
        component: 'ptLogsDiscardCriteriaResults',
        resolve: {
          advices: () => this.discardCriteriasAdvices,
          record: () => this.record
        },
        size: 'lg'
      })
      .result.catch(() => {})
  }
}
