export function MembersSuspendRoute($stateProvider) {
  'ngInject'

  let suspendMemberModal

  $stateProvider.state('suspend-member', {
    parent: 'settings.team',
    url: '/{memberId:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}/suspend',
    views: {
      'utility@layoutBase': {
        template: ' '
      }
    },
    resolve: {
      resolvedMember: resolveMember
    },
    onEnter: onSuspendMemberEnter,
    onExit: onSuspendMemberExit
  })

  function resolveMember($stateParams, Authorise, membersData, context) {
    'ngInject'

    return membersData.get(context.get('account').id, $stateParams.memberId)
  }

  function onSuspendMemberEnter($uibModal, $state, resolvedMember) {
    'ngInject'

    suspendMemberModal = $uibModal.open({
      component: 'ptMembersSuspend',
      resolve: {
        member: function () {
          return resolvedMember
        }
      }
    })

    suspendMemberModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onSuspendMemberExit() {
    'ngInject'

    suspendMemberModal.close()
  }
}
