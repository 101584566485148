export function Controller(
  gettextCatalog,
  TasksData,
  $state,
  EventsData,
  moment,
  $uibModal,
  context,
  permissionsCheck
) {
  'ngInject'

  this.$onInit = () => {
    this.errors = {}
    this.modalTitle = gettextCatalog.getString('Create New Task')
    this.selectedUsers = []
    this.isLogTask = this.resolve.isLogTask

    const account = context.get('account')
    const isViewOnly = permissionsCheck.is('viewOnly', account)
    const canAddTasks = permissionsCheck.can('add', 'tasks', account)
    this.editable = !isViewOnly && canAddTasks

    this.deadlineDatepicker = {
      options: {
        showWeeks: false,
        startingDay: 1,
        formatYear: 'yyyy',
        formatMonth: 'MM',
        formatDay: 'dd',
        minDate: new Date()
      }
    }

    this.task = {
      title: '',
      body: '',
      deadlineAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      recordId: this.resolve.recordId,
      assignees: []
    }
  }

  this.$onChanges = () => {
    // do nothing
  }

  this.createTask = () => {
    this.errors = {}

    TasksData.createTask(this.task)
      .then((response) => {
        EventsData.pushEvent('task_was_created', {
          data: {
            id: response.id,
            record: {
              id: this.task.recordId
            }
          }
        })

        if (this.isLogTask) {
          this.dismiss({ $value: response })
        } else {
          $state.go('^.task', { taskId: response.id })
        }
      })
      .catch((response) => {
        this.errors = response.data
      })
  }

  this.openAssigneeModal = () => {
    $uibModal
      .open({
        component: 'ptTaskAmendAssignees',
        windowClass: 'task-amend-assignee',
        resolve: {
          recordId: () => this.resolve.recordId,
          selectedUsers: () => this.selectedUsers
        },
        size: 'lg'
      })
      .result.catch(($value) => {
        if ($value.assignees) {
          this.selectedUsers = $value.assignees
          this.task.assignees = $value.assignees.map((assignee) => assignee.id)
        }
      })
  }

  this.userUpdated = (user, isSelected) => {
    this.selectedUsers = this.selectedUsers.filter((selectedUser) => selectedUser.isSelected)
  }
}
