import { PapertrailSession } from '@papertrail/web3-session'

export function ptContact($window) {
  'ngInject'

  let directive = {
    link: link,
    restrict: 'A'
  }

  return directive

  function link($scope, element, attrs) {
    element.on('click', function () {
      if (PapertrailSession.intercom.isBooted()) {
        PapertrailSession.intercom.message(attrs.ptContact)
      } else {
        $window.open(appConfig.contactUrl, '_blank')
      }
    })
  }
}
