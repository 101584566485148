import { Controller } from './controller'
import view from './view.html'

export const ptChecklistsSection = {
  bindings: {
    record: '<',
    onUpdateChecklistSection: '&',
    checklistName: '@',
    section: '<checklistSection',
    checklistOptions: '<'
  },
  controller: Controller,
  template: view
}
