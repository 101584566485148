export function Controller(
  $scope,
  $stateParams,
  $timeout,
  ExportsData,
  folderContext,
  filteringContext,
  recordsViewMode,
  BASE_URL,
  gettextCatalog,
  DefinitionsData,
  fileHandler,
  isIe,
  context,
  $state
) {
  'ngInject'

  let viewMode = recordsViewMode.get()

  // Check for the initial value of archived by checking the state params
  const checkArchived = () => {
    let result = 'exclude'
    let include = $stateParams.include
    let filter = $stateParams.filter

    if (include && include.indexOf('archived') >= 0) {
      result = 'include'
    }

    if (include && include.indexOf('archived') >= 0 && filter && filter.indexOf('archived_at') >= 0) {
      result = 'only'
    }

    return result
  }

  const s = {
    // Field label
    exportFormat: gettextCatalog.getString('Export format'),
    // Checkbox label
    openOrDownload: gettextCatalog.getString('Open or download?'),
    // Checkbox option
    open: gettextCatalog.getString('Open'),
    // Checkbox option
    download: gettextCatalog.getString('Download'),
    // Checkbox label
    subFolders: gettextCatalog.getString('Include sub folders'),
    // Field label
    archivedRecords: {
      label: gettextCatalog.getString('Included Records'),
      exclude: gettextCatalog.getString('Non-Archived Only'),
      include: gettextCatalog.getString('Non-Archived and Archived'),
      only: gettextCatalog.getString('Archived Records Only')
    },
    // Checkbox label
    showAdvanced: gettextCatalog.getString('Advanced Options')
  }

  // Set the required values to match the currently selected archived mode
  const setArchivedParams = () => {
    ExportsData.setArchivedParams(this.model)
  }

  const setChecklistInclude = () => {
    ExportsData.setChecklistInclude(this.model)
  }

  const watch = () => {
    $scope.$watchGroup(
      ['$ctrl.model.advanced.$includeDescendants', '$ctrl.model.format', '$ctrl.model.advanced.archived'],
      () => {
        if (this.model.type === 'folder') {
          if (this.model.advanced.$includeDescendants) {
            this.model.include = 'descendants,records'
          } else {
            this.model.include = 'records'
          }
        }

        if (this.model.format === 'csv') {
          this.model
        }

        if (this.model.type === 'record') {
          if (this.model.advanced.$includeDescendants) {
            this.model.include = 'descendants'
          }
        }
      }
    )
  }

  this.$onInit = () => {
    this.isIe = isIe
    this.process = false
    this.preparing = false
    this.error = null
    this.rootFolder = !$stateParams.folderId
    this.model = {
      type: null,
      format: 'pdf',
      $download: false,
      include: 'records',
      showContainer: false,
      orientation: 'portrait',
      advanced: {
        $includeDescendants: this.rootFolder || viewMode === 'record' ? true : false,
        archived: checkArchived(),
        checklists: false
      }
    }

    this.formFields = [
      {
        key: 'format',
        type: 'radio',
        templateOptions: {
          label: s.exportFormat,
          options: [
            {
              name: 'PDF',
              value: 'pdf'
            },
            {
              name: 'CSV',
              value: 'csv'
            }
          ]
        }
      },
      {
        key: '$download',
        type: 'radio',
        templateOptions: {
          label: s.openOrDownload,
          options: [
            {
              name: s.open,
              value: false
            },
            {
              name: s.download,
              value: true
            }
          ]
        },
        hideExpression: () => {
          return this.model.format !== 'pdf' || isIe
        }
      },
      {
        key: 'orientation',
        type: 'radio',
        hideExpression: () => {
          return this.model.format !== 'pdf'
        },
        templateOptions: {
          label: 'Orientation',
          options: [
            {
              name: 'Portrait',
              value: 'portrait'
            },
            {
              name: 'Landscape',
              value: 'landscape'
            }
          ]
        }
      },
      {
        key: 'advanced',
        wrapper: 'container',
        templateOptions: {
          label: s.showAdvanced
        },
        fieldGroup: [
          {
            key: 'checklists',
            type: 'checkbox',
            templateOptions: {
              label: 'Include Checklists '
            },
            hideExpression: () => {
              return this.model.format !== 'pdf'
            }
          },

          {
            key: '$includeDescendants',
            type: 'checkbox',
            templateOptions: {
              label: s.subFolders
            },
            hideExpression: () => {
              return !this.model.showContainer || this.model.type !== 'folder'
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return !$stateParams.folderId || viewMode === 'record'
              }
            }
          },
          {
            key: 'archived',
            type: 'radio',
            templateOptions: {
              label: s.archivedRecords.label,
              options: [
                {
                  name: s.archivedRecords.exclude,
                  value: 'exclude'
                },
                {
                  name: s.archivedRecords.include,
                  value: 'include'
                },
                {
                  name: s.archivedRecords.only,
                  value: 'only'
                }
              ]
            },
            hideExpression: () => {
              return !this.model.showContainer
            }
          }
        ]
      }
    ]

    if (this.type === 'record') {
      this.model.id = $stateParams.recordId
      this.model.type = 'record'
    }

    if (this.type === 'folder') {
      if (this.batch && this.recordIds.length) {
        this.model.type = 'record'
        this.model.record_id = this.recordIds.join(',')
      } else {
        //only merge filter params and add folder id if we didn't select specific records
        this.model = filteringContext.mergeParams(filteringContext.filters(), this.model)

        if (viewMode === 'record') {
          this.model.type = 'record'
          this.model.folder_id = folderContext.currentId()
        } else {
          this.model.type = 'folder'
          this.model.id = folderContext.currentId()
        }
      }
    }

    this.model.display = DefinitionsData.getVisibleColumnsAsKeys()
    this.model.sort = DefinitionsData.getVisibleColumnsAsSortKeys()

    watch()
  }

  this.getUrls = () => {
    let downloading = this.model.$download || this.model.format === 'csv' || isIe

    this.process = false
    this.error = null

    setArchivedParams()
    setChecklistInclude()

    if (downloading) {
      this.preparing = true
    }

    const recordIds = this.model.record_id

    ExportsData.getExportGeneral(this.model)
      .then((exportData) => {
        if (exportData.view && exportData.download) {
          // This is a normal export

          if (downloading) {
            fileHandler.download(exportData.download)
          } else {
            dispatchEvent(new CustomEvent('showDocument', { detail: { docUrl: BASE_URL + exportData.view + exportData.view, mode: 'report' } }))
          }

          this.closeModal()

          $timeout(() => {
            $state.go('^.action-records', { recordIds, action: 'export' })
          })
        } else {
          // This is a process
          this.process = true
        }
      })
      .catch((error) => {    
        this.error = error
      })
  }

  this.closeModal = (doTimeout) => {
    this.onCloseModal({ timeout: doTimeout })
  }
}

