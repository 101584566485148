import angular from 'angular'
import { ptNotesNewModule } from './components/pt-notes-new'
import { ptNotesNewFormModule } from './components/pt-notes-new-form'
import { NotesData } from './services/data'
import { NotesModel } from './services/models'

export const NotesModule = angular
  .module('notes', [ptNotesNewModule, ptNotesNewFormModule])
  .factory('NotesData', NotesData)
  .factory('NotesModel', NotesModel).name
