import view from './view.html'
import { Controller } from './controller'

/**
 * Readonly view of a signature field
 */
export const ptChecklistItemSignatureModal = {
  bindings: {
    record: '<',
    close: '&',
    dismiss: '&',
    resolve: '<',
    editable: '<',
    onValueChange: '&',
    field: '<',
    readOnly: '<'
  },
  controller: Controller,
  template: view,
  controllerAs: '$ctrl',
}
