import _forEach from 'lodash/forEach'
import _find from 'lodash/find'
import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import angular from 'angular'

export function Controller(iAuditorData) {
  'ngInject'

  let columns

  const populateSortLists = () => {
    let sortedList = []
    let unsortedList = []

    // populate sort and unsort arrays
    _forEach(columns, (column) => {
      if (column.order) {
        sortedList.push(column)
      } else {
        unsortedList.push(column)
      }
    })

    this.sortedList = recalculateOrders(_sortBy(sortedList, 'order'))
    this.unsortedList = _sortBy(unsortedList, 'key')

    // If we still have unsorted items, make sure we give the select box a value, so that it does not appear blank.
    if (unsortedList.length) {
      this.unsortedSelected = this.unsortedList[0].key
      this.unsortedSelectedOrder = this.sortedList.length + 1
    }
  }

  // Deals with re-ordering, should an item be removed from sort, or the order value exceeds the sortedItems length
  // by 2 or more, this will keep things in uniform.
  const recalculateOrders = (list) => {
    return _map(list, (item, index) => {
      let newItem = angular.copy(item)

      newItem.order = index + 1

      return newItem
    })
  }

  // Check the sorted items for order values greater than or equal to the 'order' param, then bump them up by 1.
  const bumpOrders = (order) => {
    let newSortList = _map(this.sortedList, (item) => {
      let newItem

      // Return new object for changed item to trigger $onChanges in child componenets
      if (item.order >= order) {
        newItem = angular.copy(item)
        newItem.order = newItem.order + 1

        return newItem
      }

      // No changes to item
      return item
    })

    return newSortList
  }

  const amendSort = (item, order) => {
    if (typeof item !== 'undefined') {
      item.order = order

      // If we are inserting an item into the range of existing values
      if (order > 0 && order <= this.sortedList.length) {
        bumpOrders(order)
      }

      updateHeaders()
      populateSortLists()
    }
  }

  const updateHeaders = () => {
    columns = iAuditorData.setColumns(_sortBy(this.sortedList.concat(this.unsortedList), 'order'))

    refreshAuditList()
  }

  const refreshAuditList = () => {
    this.onRefreshAudits()
  }

  this.$onInit = () => {
    this.sortOptions = {
      axis: 'y',
      handle: '.order-cell-icon',
      stop: () => {
        this.sortedList = recalculateOrders(this.sortedList)
        updateHeaders()
      }
    }

    this.sortedList = []
    this.unsortedList = []
    this.unsortedSelected = null
    this.unsortedSelectedOrder = null

    columns = iAuditorData.getColumns()

    populateSortLists()
  }

  this.toggleSortPanel = () => {
    this.onTogglePanel({ panel: 'filter' })
  }

  this.addToSort = () => {
    let item = _find(this.unsortedList, { key: this.unsortedSelected })

    amendSort(item, this.unsortedSelectedOrder)
  }

  this.removeFromSort = ($event) => {
    let item = _find(this.sortedList, { key: $event.item.key })

    amendSort(item, 0)
  }

  this.directionChange = ($event) => {
    let item: any = _find(this.sortedList, { key: $event.item.key })

    if (typeof item === 'object') {
      item.direction = $event.item.direction

      updateHeaders()
    }
  }
}
