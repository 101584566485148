import { createResource } from '../../api/resource'
import { createRequestTransformer, createResponseTransformer } from '../../api/transformer'
import { CreateTaskNotePayload, TaskNote } from '..'

const transformRequest = createRequestTransformer<CreateTaskNotePayload>()

interface Params {
  taskId: UUID
  payload: CreateTaskNotePayload
}

export const createTaskNote = createResource({
  request: ({ taskId, payload }: Params) => ({
    url: `tasks/${taskId}/notes`,
    method: 'POST',
    data: transformRequest(payload)
  }),
  transform: createResponseTransformer<TaskNote>('task', 'user', 'source')
})
