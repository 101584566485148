import { clone as _clone, cloneDeep as _cloneDeep } from 'lodash'

export function Controller($timeout, lookupsData) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    saveField,
    click,
    selectCallback,
    selectedLabel
  }

  $ctrl.lookupData = []

  $ctrl.$onInit = function () {
    var fetchOptions = {
      all: true,
      limit: 100
    }

    lookupsData.fetch($ctrl.field.source.key, '', fetchOptions).then(function (results) {
      $ctrl.lookupData = results.map(function (result) {
        return {
          value: result.value,
          label: result.value
        }
      })

      $ctrl.lookupData.push({
        value: '',
        label: '(No Selection)'
      })
    })
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.field) {
      $ctrl.field = _cloneDeep($ctrl.field)
    }

    if (changes.editable) {
      $ctrl.editable = _clone($ctrl.editable)
    }
  }

  function selectedLabel(selected) {
    if (typeof selected === 'object') {
      return selected.value
    }

    return selected
  }

  function selectCallback(form) {
    form.$submit()
  }

  function saveField(value) {
    let result

    // If the value is undefined, then nothing was selected in the drop-down
    // and we don't want anything to change.
    if (typeof value !== 'undefined') {
      result = $ctrl.onSave({ value: value })
    }

    return result
  }

  function click(event, form) {
    if (!form.$visible) {
      form.$show()
    }
  }
}
