import { Controller } from './controller'

import './view.html'

export function ptOffline($interval, $uibModal, $cacheFactory, $templateRequest, EventsData) {
  'ngInject'

  let directive = {
    link: link,
    restrict: 'E',
    scope: {}
  }

  return directive

  function link(scope) {
    scope.timer = null
    let templateUrl = 'directives/pt-offline/view.html'
    let modal = null

    // When not compiled, the template will not be requested until connection is lost, which will fail,
    // so if the template is not available when linking, request it.
    if (!$cacheFactory.get(templateUrl)) {
      $templateRequest(templateUrl)
    }

    scope.$on('event:connection-failed', function () {
      if (!modal) {
        modal = $uibModal.open({
          templateUrl: templateUrl,
          controller: Controller,
          controllerAs: 'vm',
          backdrop: 'static',
          keyboard: false
        })

        modal.result.catch(function () {})
      }

      EventsData.broadcast('routeResolver:resolved')
    })

    scope.$on('event:connection-succeeded', function () {
      if (modal) {
        modal.dismiss('connected')
      }

      modal = null

      $interval.cancel(scope.timer)
    })
  }
}
