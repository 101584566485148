import { toArray as _toArray } from 'lodash'

export function Controller($timeout) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    init
  }

  $ctrl.element = null
  $ctrl.itemSelected = 0
  $ctrl.items = []

  $ctrl.$onChanges = (changes) => {
    // We just use the changed items as an event trigger to update our roving list
    if (changes.ptRovingItems) {
      init()
    }
  }

  function init() {
    // timeout so that the element has time to render
    $timeout(() => {
      $ctrl.itemSelected = 0
      $ctrl.items = _toArray($ctrl.element.querySelectorAll('[pt-roving-item]'))

      if ($ctrl.items.length) {
        $ctrl.items.forEach((item) => {
          item.setAttribute('tabindex', -1)
        })

        $ctrl.items[0].setAttribute('tabindex', 0)
      }
    })
  }
}
