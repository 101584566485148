import angular from 'angular'

export function Controller($state, $uibModal, context) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    openTask
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.tasks) {
      $ctrl.tasks = angular.copy($ctrl.tasks)
    }
  }

  function openTask(task) {
    if ($ctrl.useModal) {
      $uibModal
        .open({
          component: 'ptTask',
          resolve: {
            task: () => task,
            isAdmin: () => context.get('account').me.admin
          },
          size: 'task'
        })
        .result.catch(() => {})
    } else {
      $state.go($state.current.name + '.task', { taskId: task.id })
    }
  }
}
