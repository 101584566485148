export function Controller(ChecklistsSentiment) {
  'ngInject'

  this.getFields = () => this.section.fields.data || this.section.fields

  this.$onInit = () => {}

  this.getPositiveFieldCount = () => {
    let count = 0

    this.getFields().forEach((field) => {
      count = count + ChecklistsSentiment.getSentiment(field)
    })
    return count
  }
}
