import angular from 'angular'

export function fileHandler($timeout, BASE_URL) {
  'ngInject'

  let service = {
    download: download
  }

  return service

  ///////////////

  function download(filePath) {
    return createIframe(filePath)
  }

  function createIframe(src) {
    let id = new Date().getTime()
    let iframe = angular.element('<iframe/>')

    iframe.attr('id', id)
    iframe.attr('name', id)
    iframe.attr('src', BASE_URL + src)
    iframe.css('display', 'none')

    angular.element(window.document.body).append(iframe)

    $timeout(function () {
      iframe.remove()
    }, 30000)
  }
}
