import angular from 'angular'
import { FieldsData } from './services/data'
import { FieldsIdentifiers } from './services/identifiers'
import { FieldsModel } from './services/models'
import { ptFieldLabelModule } from './components/pt-field-label'
import { ptFieldSectionModule } from './components/pt-field-section'
import { ptRecordFieldModule } from './components/pt-record-field'
import { ptRecordFieldArrayModule } from './components/pt-record-field-array'
import { ptRecordFieldDateModule } from './components/pt-record-field-date'
import { ptRecordFieldDefaultModule } from './components/pt-record-field-default'
import { ptRecordFieldDefaultSuggestModule } from './components/pt-record-field-default-suggest'
import { ptRecordFieldMoneyModule } from './components/pt-record-field-money'
import { ptRecordFieldMoneypickerModule } from './components/pt-record-field-moneypicker'
import { ptRecordFieldSelectSuggestModule } from './components/pt-record-field-select-suggest'
import { ptRecordFieldTextAreaModule } from 'AppModules/fields/components/pt-record-field-textarea'

export const FieldsModule = angular
  .module('fields', [
    ptFieldLabelModule,
    ptFieldSectionModule,
    ptRecordFieldModule,
    ptRecordFieldArrayModule,
    ptRecordFieldDateModule,
    ptRecordFieldDefaultModule,
    ptRecordFieldDefaultSuggestModule,
    ptRecordFieldMoneyModule,
    ptRecordFieldMoneypickerModule,
    ptRecordFieldSelectSuggestModule,
    ptRecordFieldTextAreaModule
  ])
  .factory('FieldsData', FieldsData)
  .factory('FieldsIdentifiers', FieldsIdentifiers)
  .factory('FieldsModel', FieldsModel).name
