export function Controller($state, accountsData, gettextCatalog) {
  'ngInject'

  let vm = this

  vm.control = {
    openAccount: openAccount
  }

  vm.self = gettextCatalog.getString('Emails')
  vm.accounts = null

  vm.crumbs = [
    {
      /// Bread crumb
      name: gettextCatalog.getString('Accounts'),
      link: 'accounts'
    },
    {
      /// Bread crumb
      name: gettextCatalog.getString('Profile'),
      link: 'profile.basic'
    }
  ]

  vm.$onInit = function () {
    accountsData
      .getAll(accountsData.init())
      .then(function (response) {
        vm.accounts = response
      })
      .catch(() => {})
  }

  function openAccount(accountId) {
    $state.go('profile.emails.account', {
      accountId: accountId
    })
  }
}
