import _isFunction from 'lodash/isFunction'
import _cloneDeep from 'lodash/cloneDeep'
import _assign from 'lodash/assign'

export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    showAll: showAll,
    clicked: clicked
  }

  $ctrl.displayLabels = []
  $ctrl.additionalLabels = 0
  $ctrl.hasOnClick = _isFunction($ctrl.onClick)

  $ctrl.$onInit = () => {
    $ctrl.options = _assign(
      {
        labelProperty: 'text'
      },
      $ctrl.pickerOptions
    )
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.labels) {
      $ctrl.labels = _cloneDeep($ctrl.labels)

      if ($ctrl.labels) {
        prepare($ctrl.limit || ($ctrl.labels && $ctrl.labels.length) || 0)
      }
    }
  }

  function prepare(limit) {
    $ctrl.displayLabels = $ctrl.labels.slice(0, limit)
    $ctrl.additionalLabels = Math.max(0, $ctrl.labels.length - limit)

    if ($ctrl.additionalLabels === 1) {
      prepare(parseInt(limit, 10) + 1)
    }
  }

  function showAll($event) {
    $event.stopPropagation()
    if ($ctrl.labels && $ctrl.labels.length) {
      prepare($ctrl.labels.length)
    }
  }

  function clicked($event, label) {
    $ctrl.onClick({ $event: $event, value: label[$ctrl.options.labelProperty] })
  }
}
