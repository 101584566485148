export function AccessModel(restmod) {
  'ngInject'

  const model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      folder: { hasOne: 'FolderModel', map: 'folder.data' }
    })

  return model
}
