export function ErrorsModal($uibModal) {
  'ngInject'

  let modal

  const service = {
    openModal
  }

  return service

  ////////////////

  function openModal(error, closeCallback) {
    if (modal) {
      modal.dismiss('superseded')
    }

    modal = $uibModal.open({
      backdrop: 'static',
      component: error === 'trial_expired' ? 'ptErrorsExpiry' : 'ptErrorsModal',
      resolve: {
        error: () => error,
        closeCallback: () => closeCallback
      }
    })

    modal.result.catch(function () {})
  }
}
