import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'

export function Controller($scope, $state, $stateParams, iAuditorData, $filter, moment) {
  'ngInject'

  const boolFilterCheck = (filterName) => {
    let value

    switch (this.filters[filterName].option) {
      case 'true':
        value = true
        break
      case 'false':
        value = false
        break
      case 'unknown':
        value = 'unknown'
        break
      default:
        value = null
    }

    return value
  }

  const listen = () => {
    const dateCallback = (option) => {
      return (newValue) => {
        if (newValue && this.filters.date[option]) {
          this.includeDate = true
        } else {
          this.includeDate = false
        }
      }
    }

    $scope.$watch('$ctrl.filters.date.from', dateCallback('to'))
    $scope.$watch('$ctrl.filters.date.to', dateCallback('from'))
  }

  const setupCustomFilters = () => {
    this.customFields.forEach((field) => {
      switch (field.type) {
        case 'bool':
          this.filters[field.key] = {}

          break
        case 'string':
          this.filters[field.key] = {}

          break
        default:
        //
      }
    })
  }

  const templateFilterCheck = () => {
    let templates: any = _filter(this.filters.template.options, { $selected: true })

    if (templates.length === this.filters.template.options.length) {
      templates = null
    } else {
      templates = _map(templates, 'template_id').join(',')
    }

    return templates
  }

  const setupDatePickers = () => {
    this.filters.date.from = $stateParams.from
    this.filters.date.to = $stateParams.to

    if (this.filters.date.from || this.filters.date.from) {
      this.includeDate = true
    }

    this.filters.date.$today = moment().toDate()
  }

  const setupTemplateOptions = () => {
    this.filters.template.isLoading = true

    iAuditorData
      .getTemplates()
      .then((templatesData) => {
        this.filters.template.options = _sortBy(templatesData, 'template_name')
        this.filters.template.filtered = this.filters.template.options
        setSelectedTemplates()
      })
      .catch(() => {})
      .finally(() => {
        this.filters.template.isLoading = false
      })
  }

  const setupAuthorOptions = () => {
    this.filters.author.isLoading = true

    iAuditorData
      .getAuthors()
      .then((authorsData) => {
        this.filters.author.options = _sortBy(authorsData, 'name')
        this.filters.author.filtered = this.filters.author.options
        setSelectedAuthors()
      })
      .catch(() => {})
      .finally(() => {
        this.filters.author.isLoading = false
      })
  }

  const setSelectedTemplates = () => {
    let templateIds

    if ($stateParams.template_id) {
      templateIds = $stateParams.template_id.split(',')

      templateIds.forEach((id) => {
        let template: any = _find(this.filters.template.options, { template_id: id })

        if (template) {
          template.$selected = true
        }
      })
    } else {
      this.setAllTemplates(false)
    }
  }

  const setSelectedAuthors = () => {
    if ($stateParams.author) {
      this.filters.author.option = $stateParams.author

      // Below is for multiple select (coming soon!)
      // var authorNames
      // authorNames = $stateParams.author.split(',')
      //
      // _forEach(authorNames, function(name) {
      //   var author = _find(this.filters.author.options, { name: name })
      //
      //   if (author) {
      //     author.$selected = true
      //   }
      // })
    }
  }

  const setupUsedOption = () => {
    switch ($stateParams.used) {
      case 'all':
      case 'true':
        this.filters.used.option = $stateParams.used
        break
      default:
        this.filters.used.option = 'false'
    }
  }

  const setupScoreOption = () => {
    switch ($stateParams.score) {
      case '=100':
        this.filters.score.option = '=100'
        break
      case '100':
        this.filters.score.option = '100'
        break
      default:
        this.filters.score.option = 'all'
    }
  }

  this.$onInit = () => {
    this.includeDate = false

    this.filters = {
      used: {
        option: null,
        $open: false
      },
      date: {
        from: null,
        to: null,
        $fromDateActive: true,
        $open: false,
        $today: null
      },
      template: {
        isLoading: false,
        filterString: '',
        options: [],
        filtered: [],
        $open: false
      },
      author: {
        isLoading: false,
        option: null,
        filterString: '',
        options: [],
        filtered: [],
        $open: false
      },
      score: {
        option: null,
        $open: false
      }
    }

    this.filters.date.fromOptions = {
      showWeeks: false,
      startingDay: 1,
      maxDate: this.filters.date.to || this.filters.date.$today
    }

    this.filters.date.toOptions = {
      showWeeks: false,
      startingDay: 1,
      minDate: this.filters.date.from,
      maxDate: this.filters.date.$today
    }

    if (this.customFields.length) {
      setupCustomFilters()
    }

    setupDatePickers()
    setupTemplateOptions()
    setupAuthorOptions()
    setupUsedOption()
    setupScoreOption()
    listen()
  }

  this.applyFilters = () => {
    let params = {
      from: moment(this.filters.date.from).format('YYYY-MM-DD'),
      to: moment(this.filters.date.to).format('YYYY-MM-DD'),
      used: this.filters.used.option,
      template_id: templateFilterCheck(),
      author: this.filters.author.option,
      score: this.filters.score.option
    }

    if (this.customFields.length) {
      this.customFields.forEach((field) => {
        switch (field.type) {
          case 'bool':
            params[field.key] = boolFilterCheck(field.key)

            break
          case 'string':
            params[field.key] = this.filters[field.key].option

            break
          default:
          //
        }
      })
    }

    if (params.score === 'all') {
      params.score = null
    }

    if (!this.includeDate) {
      params.to = null
      params.from = null
    }

    $state.go($state.current.name, params)
  }

  this.applySearchFilter = (filterName, filterValue) => {
    let search

    if (typeof filterValue === 'string') {
      search = {}
      search[filterValue] = this.filters[filterName].filterString
    }

    this.filters[filterName].filtered = $filter('filter')(
      this.filters[filterName].options,
      search || this.filters[filterName].filterString
    )
  }

  this.clearRadioSelection = (filterName) => {
    this.filters[filterName].option = null
  }

  this.removeDateFilter = () => {
    this.filters.date.from = null
    this.filters.date.to = null
    this.includeDate = false
  }

  // Certain filters require a source
  this.showCustomFieldFilter = (field) => {
    let showFilter = false

    if (field.type === 'bool') {
      showFilter = true
    } else if (typeof field.source === 'object') {
      showFilter = true
    }

    return showFilter
  }

  this.setAllTemplates = (bool) => {
    this.filters.template.filtered.forEach((option) => {
      option.$selected = bool
    })
  }

  this.toggleDatePickers = (mode) => {
    this.filters.date.$fromDateActive = mode === 'from'
  }

  this.toggleSection = (section) => {
    this.filters[section].$open = !this.filters[section].$open
  }

  this.toggleFilterPanel = () => {
    this.onTogglePanel({ panel: 'filter' })
  }

  this.updateFilterValue = (filterName, value) => {
    this.filters[filterName].option = value
  }

  // For multiple author select (coming soon)
  // const authorFilterCheck = () => {
  //   var authors = _filter(this.filters.author.options, { $selected: true })
  //
  //   if (authors.length === this.filters.author.options.length) {
  //     authors = null
  //   }
  //   else {
  //     authors = _map(authors, 'name').join(',')
  //   }
  //
  //   return authors
  // }
}
