import { Controller } from './controller'
import view from './view.html'

export const ptImportsCycleNav = {
  bindings: {
    onNext: '&',
    onPrev: '&',
    onTo: '&',
    records: '<',
    selectedRecord: '<'
  },
  controller: Controller,
  template: view
}
