import _isUndefined from 'lodash/isUndefined'
import _isNull from 'lodash/isNull'

export const LogsModel = (restmod, timezoneUtil) => {
  'ngInject'

  const model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      next_check_at: {
        encode: (value) => {
          let date

          // We need to check that 'value' is defined, otherwise timezoneUtil will return the time now.
          if (!_isUndefined(value) && !_isNull(value)) {
            date = timezoneUtil.localToAccountTz(value).format('YYYY-MM-DD')
          }

          return date
        }
      }
    })
    .mix({
      user: { hasOne: 'UserModel', map: 'user.data' },
      state: { hasOne: 'StateModel', map: 'state.data' },
      uploads: { hasMany: 'UploadModel', map: 'uploads.data' },
      checklist_completions: { map: 'checklist_completions.data' }
    })

  return model
}
