export function Controller(session, dateTimeFormat, $attrs) {
  'ngInject'

  let vm = this

  if ('dateonly' in $attrs) {
    vm.format = dateTimeFormat.date()
  } else {
    vm.format = dateTimeFormat.dateTime()
  }

  vm.opposite = 'opposite' in $attrs
  vm.tooltip = 'tooltip' in $attrs
  vm.absolute = 'absolute' in $attrs
  vm.relative = 'relative' in $attrs

  vm.displayRelative = true

  if (session.user) {
    vm.displayRelative = session.user.displayDateRelative
  }

  if (vm.opposite) {
    vm.displayRelative = !vm.displayRelative
  }

  if (vm.absolute) {
    vm.displayRelative = false
  } else if (vm.relative) {
    vm.displayRelative = true
  }

  vm.$onInit = function () {}
}
