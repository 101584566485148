import { Controller } from './controller'
import view from './view.html'

export function ptRecordsNewButton() {
  'ngInject'

  let directive = {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'A',
    scope: {},
    transclude: true
  }

  return directive
}
