import angular from 'angular'

import { dateformat } from './dateformat'
import { emoji } from './emoji'
import { fullname } from './fullname'
import { ptCurrency } from './pt-currency'
import { reverse } from './reverse'
import { shorten } from './shorten'
import { trimDecimal } from './trim-decimal'
import { unsafe } from './unsafe'

export const CommonFiltersModule = angular
  .module('common.filters', [])
  .filter('dateformat', dateformat)
  .filter('emoji', emoji)
  .filter('fullname', fullname)
  .filter('ptCurrency', ptCurrency)
  .filter('reverse', reverse)
  .filter('shorten', shorten)
  .filter('trimDecimal', trimDecimal)
  .filter('unsafe', unsafe).name
