import _extend from 'lodash/extend'

export function ownersData($q, context, EventsData, collections) {
  'ngInject'

  let service = {
    getAll: getAll,
    suspend: suspend,
    reactivate: reactivate
  }
  return service

  ////////////////

  function getAll(subscription, type, extraParams) {
    let params = {
      sort: 'user.first_name',
      include: 'user'
    }
    let collection

    if (type === 'suspended') {
      _extend(params, extraParams || {}, {
        include: 'user,deactivated',
        filter: 'deactivated_at<P1D'
      })
    }

    collection = subscription.owners.$collection(params)

    return collections.getAll(collection)
  }

  function suspend(owner) {
    return changeOwnerState(false, owner)
  }

  function reactivate(owner) {
    return changeOwnerState(true, owner)
  }

  function changeOwnerState(active, owner) {
    owner.active = active

    return owner
      .$save(['active'])
      .$asPromise()
      .then(function (owner) {
        EventsData.pushEvent('owner_was_updated', {
          ownerId: owner.$response.data.data.id
        })

        return owner
      })
  }
}
