export function summariesData($http, $q, BASE_URL, API_VERSION, ProfileModel) {
  'ngInject'

  let service = {
    getForAccount: getForAccount,
    toggle: toggle,
    unsubscribe: unsubscribe
  }

  return service

  function getForAccount(accountId) {
    let params = {
      account_id: accountId,
      limit: 100
    }

    return ProfileModel.single('/me').summaries.$fetch(params).$asPromise()
  }

  function toggle(summary) {
    summary.active = !summary.active

    return summary.$save(['active']).$asPromise()
  }

  function unsubscribe(id) {
    let req = {
        method: 'GET',
        url: BASE_URL + '/summaries/actions/unsubscribe/' + id,
        headers: {
          Accept: API_VERSION
        },
        ignoreError: true
      },
      defer = $q.defer()

    $http(req)
      .then(function (response) {
        defer.resolve(response.data)
      })
      .catch(function (response) {
        defer.reject(response.data)
      })

    return defer.promise
  }
}
