import angular from 'angular'

import _difference from 'lodash/difference'
import _assign from 'lodash/assign'
import _values from 'lodash/values'
import _groupBy from 'lodash/groupBy'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _includes from 'lodash/includes'
import _upperFirst from 'lodash/upperFirst'
import _camelCase from 'lodash/camelCase'

export function ActivitiesData(
  $q,
  $http,
  $log,
  $injector,
  context,
  session,
  pagination,
  BASE_URL,
  API_VERSION,
  AccountModel,
  moment,
  EventsData
) {
  'ngInject'

  const service = {
    init: init,
    debugActivities: debugActivities,
    getNext: getNext,
    get: get,
    getActivityGroup: getActivityGroup,
    revert: revert,
    groupDates: groupDates,
    groupUsers: groupUsers
  }

  const eventGroups = {
    folder: ['folder_was_moved', 'folder_was_updated', 'folder_was_created', 'folder_was_deleted'],
    record: [
      'record_was_created',
      'record_was_imported',
      'record_was_cloned',
      'record_was_updated',
      'record_moved_to_folder',
      'record_moved_from_folder',
      'record_was_deleted',
      'record_was_undeleted',
      'record_moved_to_account',
      'record_moved_from_account',
      'field_was_created',
      'field_was_updated',
      'field_was_deleted',
      'record_upload_was_added',
      'record_upload_was_updated',
      'certificate_was_created',
      'service_certificate_was_created'
    ],
    record_log: [
      'log_was_created',
      'log_was_updated',
      'service_log_was_created',
      'log_was_activated',
      'log_was_deactivated',
      'partial_log_was_created',
      'partial_log_was_updated'
    ],
    record_note: ['note_was_created', 'note_was_activated', 'note_was_deactivated'],
    task: [
      'task_assignee_was_added',
      'task_assignee_was_removed',
      'task_state_was_updated',
      'task_upload_was_created',
      'task_upload_was_deleted',
      'task_was_created',
      'task_was_updated',
      'task_was_overdue',
      'task_was_assigned_to_log',
      'task_was_unassigned_from_log'
    ],
    task_note: ['task_note_was_created'],
    service: ['service_log_was_created', 'service_certificate_was_created']
  }

  return service

  function init(accountId, includes, excludes, additionalParams) {
    const defaultParams = {
      type: _difference(getIncludes(includes), excludes).join(',')
    }

    const params = _assign(defaultParams, additionalParams)

    const account = AccountModel.$new(accountId)

    return account.activities.$collection(params)
  }

  function getIncludes(includes) {
    let events = []

    if (angular.isArray(includes)) {
      includes.forEach(addToEvents)
    } else {
      throw new TypeError('includes is not of type: Array')
    }

    function addToEvents(include) {
      if (angular.isArray(eventGroups[include])) {
        events = events.concat(eventGroups[include])
      }
    }

    return events
  }

  function getActivityGroup(groupName) {
    let group

    if (angular.isArray(eventGroups[groupName])) {
      group = eventGroups[groupName]
    }

    return group
  }

  function getNext(activities) {
    const params = {
      limit: 20,
      page: pagination.nextPage(activities)
    }

    return activities.$fetch(params).$asPromise()
  }

  function get(accountId, activityId, params) {
    const account = AccountModel.$new(accountId)

    return account.activities.$find(activityId, params).$asPromise()
  }

  function revert(accountId, activityId) {
    const req = {
      method: 'POST',
      url: BASE_URL + '/accounts/' + accountId + '/activities/' + activityId + '/actions/revert',
      headers: {
        Accept: API_VERSION
      }
    }
    const defer = $q.defer()

    $http(req)
      .then(function (response) {
        EventsData.pushEvent('record_was_updated', {
          recordId: response.data.data.record.id,
          folderId: response.data.data.folder.id
        })

        defer.resolve(response.data)
      })
      .catch(function (rejection) {
        defer.reject(rejection.data)
      })

    return defer.promise
  }

  function groupDates(activities) {
    let previousDateMoment = null
    let currentGroupCounter = 1

    return _values(
      _groupBy(
        _forEach(activities, function (activity, key) {
          if (!activity.$createdAtMoment) {
            // if we dont have a moment for the created at date yet, the create one
            activities[key].$createdAtMoment = moment(activity.created_at)
          }

          if (!previousDateMoment) {
            activities[key].$diff = null
          } else if (!activity.$diff) {
            activities[key].$diff = previousDateMoment.diff(activity.$createdAtMoment)
          }

          previousDateMoment = activity.$createdAtMoment
        }),
        function (activity) {
          if (!activity.$diff) {
            return currentGroupCounter
          } else if (activity.$diff > 1800000) {
            // 30 mins
            currentGroupCounter = currentGroupCounter + 1
          }

          return currentGroupCounter
        }
      )
    )
  }

  function groupUsers(activitesGroupedByTime) {
    const activitesGroupedByUsers = _map(activitesGroupedByTime, function (activitiesTimeGroup) {
      const innerResult = _reduce(
        activitiesTimeGroup,
        function (result, activity) {
          if (result.length && activity.user.id === result[result.length - 1][0].user.id) {
            result[result.length - 1].push(activity)
          } else {
            result.push([activity])
          }

          return result
        },
        []
      )

      return innerResult
    })

    return activitesGroupedByUsers
  }

  function debugActivities(activities) {
    const seen = []
    const ignore = ['ptActivityRecordMovedFromFolder']
    let directiveName

    _forEach(activities, function (activity) {
      if (!_includes(seen, activity.type)) {
        seen.push(activity.type)

        directiveName = 'ptActivity' + _upperFirst(_camelCase(activity.type))

        if (!_includes(ignore, directiveName) && !$injector.has(directiveName + 'Directive')) {
          $log.info('Missing directive for activity - ', directiveName, activity)
          throw new MissingActivityDirective(directiveName)
        }
      }
    })
  }

  function MissingActivityDirective(name) {
    this.message = 'Missing directive for ' + name
    this.name = 'MissingActivityDirective'
  }
}
