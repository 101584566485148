export function widgetsData(context, collections) {
  'ngInject'

  let service = {
    fetch: fetch
  }

  function fetch() {
    let account = context.get('account')

    let collection = account.me.widgets.$collection()

    return collections.getAll(collection)
  }

  return service
}
