import angular from 'angular'

import _find from 'lodash/find'
import _result from 'lodash/result'
import _filter from 'lodash/filter'
import _map from 'lodash/map'

export function Controller($scope, ActivitiesData, membersData, context) {
  'ngInject'

  let $ctrl = this
  let activitiesParams = {
    limit: null,
    user_id: null
  }

  const flagHighlighted = () => {
    $ctrl.activities.forEach((activity) => {
      if ($ctrl.pendingItems.indexOf(activity.id) >= 0) {
        activity.highlight = true
      }
    })
  }

  $ctrl.activities = []
  $ctrl.members = null
  $ctrl.isFiltered = false
  $ctrl.loading = false

  $ctrl.filterPanel = {
    isOpen: false
  }

  $ctrl.control = {
    toggleFilterPanel,
    updateMembersSelected,
    clearMembersFilter,
    setupActivities
  }

  $ctrl.$onInit = () => {
    $ctrl.pendingItems = []

    getMembers()

    $scope.$on('activity_was_created', (event, data) => {
      $ctrl.pendingItems.push(data.data.id)
    })
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.widget) {
      $ctrl.widget = angular.copy($ctrl.widget)

      activitiesParams.limit = _result($ctrl.widget, 'meta.limit')
      activitiesParams.user_id = _result($ctrl.widget, 'meta.user_id') && $ctrl.widget.meta.user_id.join(',')

      setupActivities()
    }
  }

  function setSelectedMembers() {
    if (activitiesParams.user_id && activitiesParams.user_id.length) {
      $ctrl.widget.meta.user_id.forEach((userId) => {
        let member: any = _find($ctrl.members, { user: { id: userId } })

        if (member) {
          member.selected = true
        }
      })
    }
  }

  function setupActivities() {
    let includeEventGroups = ['record', 'record_note', 'record_log', 'folder', 'task', 'task_note', 'service']

    let ignoreEvents = ['record_moved_from_folder', 'record_moved_to_account']

    $ctrl.loading = true

    ActivitiesData.init(context.get('account').id, includeEventGroups, ignoreEvents, activitiesParams)
      .$fetch()
      .$asPromise()
      .then(function (activities) {
        $ctrl.activities = activities
        $ctrl.loading = false

        flagHighlighted()

        $ctrl.pendingItems = []
      })
  }

  function getMembers() {
    membersData.getAll(context.get('account').id, null, { include: 'user,deactivated' }).then((members) => {
      $ctrl.members = members
      setSelectedMembers()
    })
  }

  function toggleFilterPanel() {
    $ctrl.filterPanel.isOpen = !$ctrl.filterPanel.isOpen
  }

  /**
   * Called by descendant components to update the members colleciton with
   * the selection updates and refresh the current activities.
   */
  function updateMembersSelected($event) {
    let membersSelected = _filter($event.members, { selected: true })

    if (membersSelected.length) {
      $ctrl.isFiltered = true
      activitiesParams.user_id = _map(membersSelected, 'user.id').join(',')
    } else if (activitiesParams && activitiesParams.hasOwnProperty('user_id')) {
      // Remove the user_id property so that it is not included in the filtering
      $ctrl.isFiltered = false
      delete activitiesParams.user_id
    }

    // Update the members with the updated collection that includes selection
    $ctrl.members = $event.members

    $ctrl.control.toggleFilterPanel()
    setupActivities()
  }

  function clearMembersFilter() {
    $ctrl.members.forEach((member) => {
      member.selected = false
    })

    if (activitiesParams && activitiesParams.hasOwnProperty('user_id')) {
      delete activitiesParams.user_id
    }

    $ctrl.isFiltered = false

    setupActivities()
  }
}
