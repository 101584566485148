import { Controller } from './controller'
import view from './view.html'

export function ptRecordBreadcrumbs() {
  'ngInject'

  return {
    template: view,
    controller: Controller,
    bindToController: true,
    controllerAs: 'vm',
    scope: {
      folder: '<',
      closeRecord: '&'
    }
  }
}
