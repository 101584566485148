import angular from 'angular'

import { ptFolderModule } from './components/pt-folder'
import { ptFolderActionsModule } from './components/pt-folder-actions'
import { ptFolderPickerModule } from './components/pt-folder-picker'
import { ptFoldersEditModule } from './components/pt-folders-edit'
import { ptFolderMoveNewModule } from './components/pt-folder-move-new'
import { ptFoldersMoveModule } from './components/pt-folders-move'
import { ptFoldersNewModule } from './components/pt-folders-new'
import { ptFoldersPickerModule } from './components/pt-folders-picker'
import { ptFoldersSelectModule } from './components/pt-folders-select'
import { ptFolderFilterModalModule } from './components/pt-folder-filter-modal'

import { ptFolderColumnsModule } from './directives/pt-folder-columns'
import { ptFolderDescriptionModule } from './directives/pt-folder-description'
import { ptFolderHeaderModule } from './directives/pt-folder-header'
import { ptFolderOptionsModule } from './directives/pt-folder-options'
import { ptFolderMenuModule } from './directives/pt-folder-menu'
import { ptFolderSortModule } from './directives/pt-folder-sort'
import { ptFolderSortPanelModule } from './directives/pt-folder-sort-panel'
import { ptFolderStarFilterModule } from './directives/pt-folder-star-filter'
import { ptFoldersDropdownCrumbModule } from './directives/pt-folders-dropdown-crumb'
import { ptFoldersNewButtonModule } from './directives/pt-folders-new-button'
import { ptFoldersStarredModule } from './directives/pt-folders-starred'
import { ptFoldersTableModule } from './directives/pt-folders-table'
import { ptFoldersTableHeadModule } from './directives/pt-folders-table-head'
import { ptFoldersTableRowModule } from './directives/pt-folders-table-row'
import { ptRootfolderDropdownCrumbModule } from './directives/pt-rootfolder-dropdown-crumb'

import { foldersCache } from './services/cache'
import { foldersData } from './services/data'
import { foldersExpanded } from './services/expanded'
import { folderContext } from './services/context'
import { FolderModel, RootFolderModel } from './services/models'
import { foldersMoveConfirmModal } from './services/move-confirm-modal'
import { foldersNavigation } from './services/navigation'
import { foldersResolves } from './services/resolves'
import { foldersTree } from './services/tree'

export const FoldersModule = angular
  .module('folders', [
    // components
    ptFolderModule,
    ptFolderActionsModule,
    ptFolderMoveNewModule,
    ptFolderPickerModule,
    ptFoldersEditModule,
    ptFoldersMoveModule,
    ptFoldersNewModule,
    ptFoldersPickerModule,
    ptFoldersSelectModule,
    ptFolderFilterModalModule,

    // directives
    ptFolderColumnsModule,
    ptFolderDescriptionModule,
    ptFolderHeaderModule,
    ptFolderMenuModule,
    ptFolderOptionsModule,

    ptFolderSortModule,
    ptFolderSortPanelModule,
    ptFolderStarFilterModule,
    ptFoldersDropdownCrumbModule,
    ptFoldersNewButtonModule,
    ptFoldersStarredModule,
    ptFoldersTableModule,
    ptFoldersTableHeadModule,
    ptFoldersTableRowModule,
    ptRootfolderDropdownCrumbModule
  ])
  .factory('foldersCache', foldersCache)
  .factory('foldersData', foldersData)
  .factory('foldersExpanded', foldersExpanded)
  .factory('FolderModel', FolderModel)
  .factory('RootFolderModel', RootFolderModel)
  .factory('foldersMoveConfirmModal', foldersMoveConfirmModal)
  .factory('folderContext', folderContext)
  .factory('foldersNavigation', foldersNavigation)
  .factory('foldersResolves', foldersResolves)
  .factory('foldersTree', foldersTree)
  .run([
    '$templateCache',
    ($templateCache) => {
      $templateCache.put('components/pt-folders-select/tree.html', require('./components/pt-folders-select/tree.html'))
    }
  ]).name
