export function Controller($state, recordsBucket, context, tracking, $scope) {
  'ngInject'

  let vm = this

  vm.control = {
    recordsBucket: recordsBucket,
    navigateToBucket: navigateToBucket
  }

  //////////

  vm.$onInit = function () {
    
  }

  function navigateToBucket() {
    $state.go('bucket')
  }

}

