import { Controller } from './controller'
import view from './view.html'

export const ptChecklistsSelect = {
  bindings: {
    checklists: '<',
    formIndex: '<',
    onSelectChecklist: '&'
  },
  controller: Controller,
  template: view
}
