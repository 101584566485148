export function ptLayoutFullRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('layoutFull', {
    abstract: true,
    component: 'ptLayoutFull',
    onEnter: layoutFullEnter
  })

  function layoutFullEnter(EventsPusher) {
    'ngInject'
    EventsPusher.connect()
  }
}
