import angular from 'angular'

import { ptSubscriptionsNewModule } from './components/pt-subscriptions-new'

import { SubscriptionsData } from './services/data'
import { SubscriptionModel } from './services/models'

import { SubscriptionRoute } from './routes/subscription'

export const SubscriptionsModule = angular
  .module('subscriptions', [ptSubscriptionsNewModule])
  .config(SubscriptionRoute)
  .factory('SubscriptionsData', SubscriptionsData)
  .factory('SubscriptionModel', SubscriptionModel).name
