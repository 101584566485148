export function RecordsBatchMoveRoute($stateProvider) {
  'ngInject'

  let batchMoveRecordModal

  $stateProvider.state('bucket.move-records', moveRecordsStates())
  $stateProvider.state('folders.move-records', moveRecordsStates())
  $stateProvider.state('folder.move-records', moveRecordsStates())
  $stateProvider.state('folder.scan.move-records', moveRecordsStates())
  $stateProvider.state('folders.scan.move-records', moveRecordsStates())
  $stateProvider.state('account.scan.move-records', moveRecordsStates())

  function moveRecordsStates() {
    return {
      url: '/records/move?{recordIds}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: editRecordPermission,
        resolvedRecords: resolveMoveRecords
      },
      onEnter: onBatchMoveRecordEnter,
      onExit: onBatchMoveRecordExit
    }
  }

  function editRecordPermission(account, member, permissionsCheck, ErrorsResolve) {
    'ngInject'

    return permissionsCheck.canPromise('edit', 'records', account).catch(ErrorsResolve.caught)
  }

  function resolveMoveRecords(account, $stateParams, recordsResolves, ErrorsDisplay) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    let selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves.records(selection, 'folder,archived').catch(function () {
      return ErrorsDisplay.catch('select_records_including_archived')
    })
  }

  function onBatchMoveRecordEnter($uibModal, $state, resolvedRecords) {
    'ngInject'

    batchMoveRecordModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptRecordsBatchMove',
      resolve: {
        records: () => resolvedRecords
      }
    })

    batchMoveRecordModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onBatchMoveRecordExit() {
    'ngInject'

    batchMoveRecordModal.close()
  }
}
