export function ptImportsFoldersRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('imports.import.accounts.folders', {
    url: `/{accountId}/folders`,
    views: {
      'crumbs@imports': {
        component: 'ptImportsCrumbs'
      },
      'main@imports': {
        component: 'ptImportsFolders'
      }
    },
    resolve: {
      account: resolveAccount,
      member: resolveMember,
      crumbs: resolveCrumbs,
      self: resolveSelf
    }
  })

  function resolveAccount(context, accountsData, $transition$) {
    'ngInject'

    return accountsData.fetchAccount($transition$.params().accountId).then(function (account) {
      context.set('account', account)
      return account
    })
  }

  function resolveMember(context, account, membersData) {
    'ngInject'

    return membersData.fetch(account).then((member) => {
      context.set('member', member)
      return member
    })
  }

  function resolveCrumbs(gettextCatalog) {
    'ngInject'

    return [
      {
        name: gettextCatalog.getString('Imports'),
        link: 'imports'
      },
      {
        name: gettextCatalog.getString('Import Details'),
        link: '^.^'
      },
      {
        name: gettextCatalog.getString('Accounts'),
        link: '^'
      }
    ]
  }

  function resolveSelf(gettextCatalog) {
    'ngInject'

    return gettextCatalog.getString('Folders')
  }
}
