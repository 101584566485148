import view from './view.html'
import { Controller } from 'AppModules/checklists/components/pt-checklist-field-comments/controller'

/**
 * Readonly view of a textarea field
 */
export const ptChecklistItemTextArea = {
  bindings: {
    record: '<',
    onValueChange: '&',
    field: '<',
    readOnly: '<'
  },
  controller: Controller,
  template: view
}
