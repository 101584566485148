import moment from 'moment-timezone'

export function Controller() {
  'ngInject'

  this.$onInit = () => {
    this.task = this.resolve.task
    this.dateFormat = this.resolve.dateFormat
    this.taskIsOverdue = this.resolve.taskIsOverdue

    this.deadlineDatepicker = {
      date: moment(this.task.deadlineAt).format('YYYY-MM-DD HH:mm:ss'),
      options: {
        showWeeks: false,
        startingDay: 1
      }
    }
  }

  this.confirm = () => {
    this.dismiss({ $value: this.deadlineDatepicker.date })
  }
}
