import { Controller } from './controller'
import view from './view.html'

export const ptProcessesTableRow = {
  bindings: {
    process: '<'
  },
  controller: Controller,
  template: view
}
