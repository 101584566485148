import _extend from 'lodash/extend'

export function recordsModal($state) {
  'ngInject'

  let service = {
    goToModalRoute: goToModalRoute
  }

  return service

  function goToModalRoute(id, additionalParams) {
    let defaultParams = { recordId: id }
    let params = _extend(additionalParams || {}, defaultParams)

    if ($state.get($state.current.name + '.records')) {
      return $state.go($state.current.name + '.records', params)
    } else if ($state.get('^.records')) {
      return $state.go('^.records', params)
    } else if ($state.get('^.^.records')) {
      return $state.go('^.^.records', params)
    }
    return $state.go('account.records', params)
  }
}
