import _forEach from 'lodash/forEach'
import PasswordValidator from '../../../common/services/password-validator'

export function Controller(context, usersData, gettextCatalog, session, Authenticate, $state) {
  'ngInject'

  let vm = this,
    s = {
      /// Field label
      currentPassword: gettextCatalog.getString('Current password'),
      /// Field label
      newPassword: gettextCatalog.getString('New password'),
      /// Field placeholder
      passwordPlaceholder: gettextCatalog.getString(
        'Must be at least 8 characters and a combination of uppercase and lowercase letters, numbers, and symbols'
      ),
      /// Field label
      newPasswordAgain: gettextCatalog.getString('New password again'),
      /// Field placeholder
      passwordAgainPlaceholder: gettextCatalog.getString('Please re-enter your password'),
      /// Field validation error
      passwordMatchError: gettextCatalog.getString('Passwords must match')
    }

  vm.control = {
    save: save
  }

  vm.crumbs = [
    {
      /// Bread crumb
      name: gettextCatalog.getString('Accounts'),
      link: 'accounts'
    },
    {
      /// Bread crumb - User profile
      name: gettextCatalog.getString('Profile'),
      link: 'profile.basic'
    }
  ]
  vm.self = gettextCatalog.getString('Change Password')

  vm.saving = false
  vm.password = {
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
  }
  vm.passwordFields = [
    {
      key: 'current_password',
      type: 'input',
      templateOptions: {
        label: s.currentPassword,
        type: 'password',
        required: true,
        minlength: 6
      }
    },
    {
      key: 'new_password',
      type: 'input',
      templateOptions: {
        label: s.newPassword,
        placeholder: s.passwordPlaceholder,
        type: 'password',
        required: true,
        minlength: 8
      },
      validators: {
        passwordComplexity: {
          expression: function (viewValue, modelValue) {
            const value = modelValue || viewValue
            return PasswordValidator(value)
          },
          message: '"Must contain uppercase and lowercase letters, numbers, and symbols"'
        }
      }
    },
    {
      key: 'new_password_confirmation',
      type: 'input',
      optionsTypes: ['matchField'],
      templateOptions: {
        label: s.newPasswordAgain,
        placeholder: s.passwordAgainPlaceholder,
        type: 'password',
        required: true
      },
      data: {
        fieldToMatch: 'new_password',
        modelToMatch: vm.password,
        matchFieldMessage: '"' + s.passwordMatchError + '"'
      }
    }
  ]
  vm.error = null
  vm.success = null

  //////////

  vm.$onInit = function () {}

  function save() {
    if (vm.form.$valid) {
      vm.saving = true
      vm.success = null
      vm.error = null

      usersData
        .changePassword(vm.password)
        .then(saveSuccess)
        .catch(saveError)
        .finally(function () {
          vm.saving = false
        })
    }
  }

  function saveSuccess() {
    Authenticate.attemptLogin({
      username: session.user.email,
      password: vm.password.new_password,
      rememberme: false
    })
      .catch(loginError)
      .finally(() => {
        vm.success = true
        _forEach(vm.password, function (field, key) {
          vm.password[key] = ''
        })
        vm.form.$setUntouched()
        vm.form.$setPristine()
      })
  }

  function loginError() {
    $state.go('login')
  }

  function saveError(rejection) {
    vm.error = rejection.data.error || true
  }
}
