import * as React from 'react'
import { Icon, IconName } from '../../components'
import { createStyledLocalise, createStyledView } from '../../components/styled'

import { colors, fontSizes, spacing } from '../../styles'
import { getPlatformStyle } from '../../services/platform'
import { State } from '..'

const Container = createStyledView({
  flexDirection: 'row'
})

const Content = createStyledView({
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: spacing.base,
  paddingVertical: spacing.quarter,
  paddingHorizontal: spacing.half
})

const Label = createStyledLocalise({
  color: colors.white,
  marginLeft: spacing.quarter,
  // Fix alignment issues
  paddingRight: spacing.quarter,
  ...getPlatformStyle({
    web: { fontSize: fontSizes.small },
    mobile: { fontSize: fontSizes.base }
  })
})

const getIconFromState = ({ profile }: Partial<State> = {}): IconName => {
  switch (profile) {
    case 'good': {
      return 'ok'
    }

    case 'monitor': {
      return 'monitor'
    }

    case 'retired': {
      return 'trashEmpty'
    }

    case 'quarantine': {
      return 'lock'
    }

    case 'missing': {
      return 'help'
    }

    default: {
      return 'rhombus'
    }
  }
}

interface Props {
  state?: State
}

export const StateIndicator = ({ state }: Props) => {
  const icon = getIconFromState(state)
  const { name = 'Unknown', colour = '33, 56, 84' } = state || {}

  return (
    <Container>
      <Content style={{ backgroundColor: `rgb(${colour})` }}>
        <Icon name={icon} color="white" />
        <Label>{name}</Label>
      </Content>
    </Container>
  )
}
