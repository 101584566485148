export function ptFoldersNewRoute($stateProvider) {
  'ngInject'

  let newFolderModal

  newFolderState('account')
  newFolderState('folders')
  newFolderState('folder')
  newFolderState('imports.import.accounts.folders')

  function newFolderState(parent) {
    $stateProvider.state(parent + '.new-folder', {
      url: '/new?selectedFolder',
      params: {
        selectedFolder: null
      },
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: newFolderPermission
      },
      onEnter: onNewFolderEnter,
      onExit: onNewFolderExit
    })
  }

  function newFolderPermission(account, member, permissionsCheck, ErrorsResolve) {
    'ngInject'

    return permissionsCheck.canPromise('add', 'folders', account).catch(ErrorsResolve.caught)
  }

  function onNewFolderEnter($uibModal, $state, $transition$, page) {
    'ngInject'
    page.setTitle('New Folder')
    newFolderModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptFoldersNew',
      resolve: {
        $transitions: () => $transition$
      }
    })

    newFolderModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onNewFolderExit(page) {
    'ngInject'
    page.resetTitle()
    newFolderModal.close()
  }
}
