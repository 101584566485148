export function Controller(moment, ErrorsModal) {
  'ngInject'

  let vm = this

  vm.control = {
    learnMore: learnMore
  }

  vm.days = null

  vm.$onInit = function () {
    vm.days = moment(vm.account.trial_expires_at || undefined).diff(moment(), 'days')
  }

  function learnMore() {
    ErrorsModal.openModal('demo_expiry_info')
  }
}
