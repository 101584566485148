import { Controller } from './controller'
import view from './view.html'

export const ptAccountGeneral = {
  bindings: {
    countries: '<',
    timezones: '<'
  },
  controller: Controller,
  template: view
}
