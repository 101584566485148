import * as React from 'react'

import { Activity } from '..'
import { PTDate } from '../../components'
import { Text, View, createStyledComponent } from '../../components/styled'
import { ActivityTitle } from './activity-title'
import { ActivityContent } from './activity-content'
import Hyperlink from '../../components/hyperlink'

interface Props {
  activity: Activity
  viewType: string
  onPressActivity: (activity: Activity) => void
}

const Wrapper = createStyledComponent(View, {
  overflow: 'visible'
})

export const ActivityTaskWasUpdated = ({ activity, viewType, onPressActivity }: Props) => {
  switch (viewType) {
    case 'simple':
      return (
        <Wrapper>
          <ActivityTitle activity={activity} onPressActivity={onPressActivity}>
            Task was updated
          </ActivityTitle>
          <ActivityContent>
            <View>
              {/* TODO: Better render below view */}
              {activity.to.deadlineAt && (
                <Text>
                  Deadline at: <PTDate dateTime={activity.createdAt} />
                </Text>
              )}
              {activity.to.body && <Hyperlink text={`Description: ${activity.to.body}`} textComponent={<Text />} />}
              {activity.to.title && <Text>Title: {activity.to.title}</Text>}
            </View>
          </ActivityContent>
        </Wrapper>
      )
  }
}
