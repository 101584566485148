import view from './view.html'

export function ptUserIcon() {
  'ngInject'

  let directive = {
    restrict: 'A',
    template: view,
    bindToController: true,
    controller: function () {},
    controllerAs: 'vm',
    scope: {
      user: '='
    },
    link: link
  }

  return directive

  function link(scope, element, attrs) {
    let initials = ''

    init()

    function init() {
      if (typeof attrs.override !== 'undefined') {
        scope.vm.override = true
        attrs.$observe('override', function () {
          if (attrs.override !== '') {
            scope.vm.overrideUrl = attrs.override
          } else {
            scope.vm.overrideUrl = null
          }

          setupAvatar()
        })
      }

      if (attrs.watch) {
        watch()
      }

      setupAvatar()
    }

    function watch() {
      scope.$watch('vm.user', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          setupAvatar()
        }
      })
    }

    function setupAvatar() {
      let user = scope.vm.user

      if (scope.vm.overrideUrl || (user && user.avatar)) {
        // css
        element.css('background-image', 'url(' + (scope.vm.overrideUrl || user.avatar) + ')')

        // class
        element.addClass('photo-avatar')

        // contents
        element.children('span').text('')
      } else if (user && user.first_name) {
        initials = (user.first_name.charAt(0) + user.last_name.charAt(0)).toUpperCase()

        // css
        element.css('background-color', `#${user.avatar_colour}`)
        element.css('background-image', '')

        // class
        element.removeClass('photo-avatar')

        // contents
        element.children('span').text(initials)
      } else if (user && user.firstName) {
        initials = (user.firstName.charAt(0) + user.lastName.charAt(0)).toUpperCase()

        // css
        element.css('background-color', `#${user.avatarColour}`)
        element.css('background-image', '')

        // class
        element.removeClass('photo-avatar')

        // contents
        element.children('span').text(initials)
      }
    }
  }
}
