export function richText($filter) {
  'ngInject'

  function service(text) {
    if (text) {
      text = $filter('linky')(text, '_blank', { rel: 'nofollow noopener noreferrer' })
      text = $filter('shorten')(text)
      text = $filter('emoji')(text)
    }

    return text
  }

  return service
}
