import angular from 'angular'

export function ApiHeaders(API_VERSION, session) {
  'ngInject'

  function addHeader(headers, name, value) {
    let header = {}
    header[name] = value

    angular.extend(headers, header)
  }

  return {
    $hooks: {
      'before-request': function (_req) {
        _req.headers = _req.headers || {}
        addHeader(_req.headers, 'Accept', API_VERSION)
      }
    }
  }
}
