import angular from 'angular'
import { TasksData } from './services/data'
import { TasksResolver } from './services/resolver'
import { TasksModel } from './services/model'
import { TasksSubscribersModel } from './services/model'
import { TasksNotesModel } from './services/model'
import { Task } from './components/pt-task'
import { TaskAmendAssignees } from './components/pt-task-amend-assignees'
import { TaskAmendTitleBody } from './components/pt-task-amend-title-body'
import { TaskAssigneesPreview } from './components/pt-task-assignees-preview'
import { TaskCreate } from './components/pt-task-create'
import { TaskDatepicker } from './components/pt-task-datepicker'
import { TaskLogAssign } from './components/pt-task-log-assign'
import { ptTaskNoteModalModule } from './components/pt-task-note-modal'
import { ptTaskStateChangeModule } from './components/pt-task-state-change'
import { TaskSidebar } from './components/pt-task-sidebar'
import { Tasks } from './components/pt-tasks'
import { TasksAccountEmails } from './components/pt-tasks-account-emails'
import { TasksDisabled } from './components/pt-tasks-disabled'
import { TasksFilterHeader } from './components/pt-tasks-filter-header'
import { TasksFilterPanel } from './components/pt-tasks-filter-panel'
import { TasksItem } from './components/pt-tasks-item'
import { TasksSortPanel } from './components/pt-tasks-sort-panel'
import { TasksTable } from './components/pt-tasks-table'
import { TasksUnsubscribe } from './components/pt-tasks-unsubscribe'

export const TasksModule = angular
  .module('tasks', [
    Task,
    TaskAmendAssignees,
    TaskAmendTitleBody,
    TaskAssigneesPreview,
    TaskCreate,
    TaskDatepicker,
    TaskLogAssign,
    ptTaskNoteModalModule,
    ptTaskStateChangeModule,
    TaskSidebar,
    Tasks,
    TasksAccountEmails,
    TasksDisabled,
    TasksFilterHeader,
    TasksFilterPanel,
    TasksItem,
    TasksSortPanel,
    TasksTable,
    TasksUnsubscribe
  ])
  .factory('TasksData', TasksData)
  .factory('TasksResolver', TasksResolver)
  .factory('TasksModel', TasksModel)
  .factory('TasksSubscribersModel', TasksSubscribersModel)
  .factory('TasksNotesModel', TasksNotesModel).name
