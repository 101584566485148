import _forEach from 'lodash/forEach'
import _chunk from 'lodash/chunk'
import _assign from 'lodash/assign'
import _extend from 'lodash/extend'
import _difference from 'lodash/difference'
import _map from 'lodash/map'
import _trim from 'lodash/trim'
import _uniq from 'lodash/uniq'

export function recordsData(
  $q,
  context,
  EventsData,
  validator,
  session,
  filteringContext,
  recordsViewMode,
  collections,
  TutorialsFlags,
  ptApi,
  TagsCache
) {
  'ngInject'

  let sort

  let commonIncludes = {
    preview: [
      'uploads',
      'folder',
      'user',
      'frequency',
      'fields',
      'state',
      'log',
      'log.user',
      'log.checklist_completions',
      'log.uploads'
    ].join(','),
    expanded: [
      'checklists',
      'discard_criteria',
      'uploads',
      'folder',
      'folder.ancestors',
      'frequency',
      'fields',
      'user',
      'tags',
      'state',
      'initial_state',
      'log',
      'log.user',
      'log.state',
      'log.uploads',
      'note',
      'note.user',
      'note.uploads'
    ].join(',')
  }

  let getRecordsChunk = function (chunk, include, sortBy) {
    let params = {
      record_id: chunk.join(','),
      include: include,
      limit: chunk.length,
      sort: sortBy
    }

    return context.get('account').records.$collection(params).$fetch().$asPromise()
  }

  function batchDelete(records) {
    let promises = []

    _forEach(records, function (record) {
      promises.push(deleteRecord(record))
    })

    return $q.all(promises)
  }

  function batchSaveProperty(records, property, value) {
    let promises = []

    _forEach(records, function (record) {
      promises.push(saveProperty(record, property, value))
    })

    return $q.all(promises)
  }

  function create(recordData) {
    let account = context.get('account')

    return account.records
      .$build({
        folder_id: recordData.folder_id,
        state_id: recordData.state_id,
        name: recordData.name,
        frequency_id: recordData.frequency_id,
        quantity: recordData.quantity
      })
      .$save()
      .$asPromise()
      .then(function (record) {
        TutorialsFlags.setUserFlag('created_record')

        EventsData.pushEvent('record_was_created', {
          recordId: record.id,
          folderId: recordData.folder_id
        })

        return record
      })
  }

  function cloneRecord(sourceRecord, quantity = 1) {
    let account = context.get('account')

    return account.records
      .$build({
        record_id: sourceRecord.id,
        quantity
      })
      .$save()
      .$asPromise()
      .then(function (record) {
        EventsData.pushEvent('record_was_cloned', {
          recordId: record.id,
          folderId: sourceRecord.folder.id
        })

        return record
      })
  }

  function cloneRecords(records, quantity) {
    let promises = []

    _forEach(records, function (record) {
      promises.push(cloneRecord(record, quantity))
    })

    return $q.all(promises)
  }

  function deleteRecord(record) {
    return record
      .$destroy()
      .$asPromise()
      .then(function () {
        EventsData.pushEvent('record_was_deleted', {
          recordId: record.id,
          folderId: record.folder.id
        })

        return record
      })
  }

  function getAllRecords(collection) {
    return collections.getAll(collection)
  }

  function getRecord(id, type, includes) {
    let params = {
      include: commonIncludes[type] ? commonIncludes[type] : includes
    }

    return context.get('account').records.$find(id, params).$asPromise()
  }

  function getPage(results, page) {
    let params = {
      page: page,
      sort: sort
    }

    return results.$refresh(params).$asPromise()
  }

  function getRecords(recordIds, type, includes, sortBy) {
    let include = commonIncludes[type] ? commonIncludes[type] : includes
    let chunks = _chunk(recordIds, 50)
    let promises = []

    _forEach(chunks, function (chunk) {
      promises.push(getRecordsChunk(chunk, include, sortBy))
    })

    return $q
      .all(promises)
      .then(function (resolves) {
        let records = []

        _forEach(resolves, function (recordBatch) {
          records = records.concat(recordBatch)
        })

        return records
      })
      .catch(function () {
        return []
      })
  }

  function getUpdated(record) {
    return record.$fetch({ include: commonIncludes.preview }).$asPromise()
  }

  function init(account, folderId, limit, extraParams) {
    let coreParams = _assign(
      {
        folder_id: folderId,
        include: commonIncludes.preview,
        limit: limit || 10
      },
      extraParams
    )

    let params = filteringContext.mergeParams(filteringContext.filters(), coreParams)

    if (recordsViewMode.get() === 'record') {
      params.include = params.include + ',descendants,folder.ancestors'
    }

    return account.records.$collection(params)
  }

  function recordsVisibleInFolder(recordIds, folderId) {
    let params = {
      record_id: recordIds.join(','),
      folder_id: folderId,
      limit: recordIds.length
    }

    _extend(params, filteringContext.filters())

    return context
      .get('account')
      .records.$collection(params)
      .$fetch()
      .$asPromise()
      .then(function (results) {
        let defer = $q.defer()

        if (results.length) {
          defer.resolve(results)
        } else {
          defer.reject()
        }

        return defer.promise
      })
  }

  function remove(record) {
    record.$scope.$remove(record)
  }

  function saveChecklists(account, records, checklists, saveOptions) {
    let data: any = {}
    let promises = []
    let recordChecklistValues
    let checklistValues
    let options = _assign({ replace: false, remove: false }, saveOptions)

    records.forEach(function (record) {
      recordChecklistValues = record.checklists.map(function (checklist) {
        return checklist.id
      })
      checklistValues = checklists.map(function (checklist) {
        return checklist.id
      })

      if (checklists.length || options.replace) {
        if (options.remove) {
          data.checklist_ids = _difference(recordChecklistValues, checklistValues)
        } else if (options.replace) {
          data.checklist_ids = checklistValues
        } else {
          data.checklist_ids = checklistValues.concat(recordChecklistValues)
        }

        promises.push(
          ptApi
            .request({
              method: 'PUT',
              url: '/accounts/' + account.id + '/records/' + record.id + '/actions/checklists',
              data: data
            })
            .then(function (response) {
              EventsData.pushEvent('record_was_updated', {
                recordId: record.id,
                folderId: record.folder.id
              })

              return response.data
            })
            .catch(() => {})
        )
      }
    })

    return $q.all(promises)
  }

  function saveProperty(record, property, value, customOptions?) {
    let defer = $q.defer()
    let options = {
      force: false
    }
    let validation

    options = _assign(options, customOptions)

    if (options.force || value !== record[property]) {
      validation = validator.validate('record', property, value)
      if (!validation.valid) {
        defer.reject(validation.message)
        return defer.promise
      }

      record[property] = value

      return record
        .$save([property])
        .$asPromise()
        .then(
          function (updatedRecord) {
            EventsData.pushEvent('record_was_updated', {
              recordId: updatedRecord.id,
              folderId: record.folder.id
            })

            // Clear the TagsCache data so that the newly added tag is available
            TagsCache.clear(context.get('account'))

            return updatedRecord
          },
          function (rejection) {
            return validator.rejection(rejection.$response)
          }
        )
    }

    defer.resolve()
    return defer.promise
  }

  function saveTags(records, tags, replace, removeTags) {
    let tagsToSave = determineTagsToSave(records[0], tags, replace, removeTags)

    return saveProperty(records[0], 'tags', _uniq(tagsToSave)).then(() => {
      let promises = []

      _forEach(records.slice(1), function (record) {
        if (tags.length || replace) {
          let tagsToSave = determineTagsToSave(record, tags, replace, removeTags)
          promises.push(saveProperty(record, 'tags', _uniq(tagsToSave)))
        }
      })

      return $q.all(promises)
    })
  }

  function determineTagsToSave(record, tags, replace, removeTags) {
    let tagsToSave = []
    let tagValues
    let recordTagValues

    tagValues = _map(tags, function (tag) {
      return _trim(tag.text)
    })
    recordTagValues = _map(record.tags, 'text')

    if (removeTags) {
      tagsToSave = _difference(recordTagValues, tagValues)
    } else if (replace) {
      tagsToSave = tagValues
    } else {
      tagsToSave = tagValues.concat(recordTagValues)
    }

    return tagsToSave
  }

  function search(params) {
    let coreParams = {
      limit: 1
    }

    _extend(coreParams, params || {})

    return context.get('account').records.$search(params).$asPromise()
  }

  function setSort(sortString) {
    sort = sortString
  }

  return {
    batchDelete: batchDelete,
    batchSaveProperty: batchSaveProperty,
    create: create,
    cloneRecord: cloneRecord,
    cloneRecords: cloneRecords,
    delete: deleteRecord,
    getAllRecords: getAllRecords,
    getPage: getPage,
    getRecord: getRecord,
    getRecords: getRecords,
    getUpdated: getUpdated,
    init: init,
    recordsVisibleInFolder: recordsVisibleInFolder,
    remove: remove,
    saveChecklists: saveChecklists,
    saveProperty: saveProperty,
    saveTags: saveTags,
    search: search,
    setSort: setSort
  }
}
