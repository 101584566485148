import * as React from 'react'
import * as moment from 'moment-timezone'
import { User } from '../user/index'
import { Text } from 'reactxp'

const DEFAULT_DATE_FORMAT = 'Do MMM YYYY'
const DEFAULT_TIME_FORMAT = 'H:mm a'

interface PTDateProps {
  dateTime?: string
  user?: User
  relative?: boolean
  children?: string
  showTime?: boolean
  style?: any
  defaultText?: string
  beforeNowStyle?: any
}

export const PTDate = ({
  dateTime,
  user,
  relative,
  children,
  showTime,
  style,
  defaultText,
  beforeNowStyle
}: PTDateProps) => {
  const now = moment.tz((user && user.timezone) || 'Europe/London')
  const usableDateTime = dateTime || children
  const localDateTime = moment(usableDateTime).tz((user && user.timezone) || 'Europe/London')
  const { dateFormat = DEFAULT_DATE_FORMAT, timeFormat = DEFAULT_TIME_FORMAT } = user || {}

  let displayDateRelative = user && user.displayDateRelative
  let displayValue = null
  let closeDate = true

  if (typeof relative === 'boolean') {
    displayDateRelative = relative
  }

  if (usableDateTime) {
    if (displayDateRelative) {
      if (now.isSame(localDateTime, 'd')) {
        displayValue = 'Today'
      } else if (moment(now).subtract(1, 'd').isSame(localDateTime, 'd')) {
        displayValue = 'Yesterday'
      } else if (moment(now).add(1, 'd').isSame(localDateTime, 'd')) {
        displayValue = 'Tomorrow'
      } else {
        closeDate = false
        displayValue = localDateTime.from(now, now.isBefore(localDateTime))
      }

      if (showTime && closeDate) {
        const time = localDateTime.format(timeFormat)
        displayValue = `${displayValue} at ${time}`
      }
    } else if (showTime) {
      displayValue = localDateTime.format(`${dateFormat} ${timeFormat}`)
    } else {
      displayValue = localDateTime.format(dateFormat)
    }
  } else if (defaultText) {
    displayValue = defaultText
  }

  return <Text style={[style, localDateTime.isBefore(now) && beforeNowStyle]}>{displayValue}</Text>
}
