export function ErrorsResolve($q, $state, EventsData, ErrorsModal) {
  'ngInject'

  const service = {
    caught
  }

  return service

  //////////////

  function caught(reason) {
    EventsData.broadcast('routeResolver:resolved')

    ErrorsModal.openModal('invalid_permission', () => {
      // If we are not on a state already(i.e. this is the first page load), the go to root accounts page.
      if (!$state.current.name) {
        $state.go('accounts')
      }
    })

    return $q.reject(reason)
  }
}
