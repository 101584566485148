export function Controller($uibModalStack, $timeout, foldersNavigation) {
  'ngInject'

  var vm = this

  vm.control = {
    go: go,
    openRecord: openRecord
  }

  vm.$onInit = () => {}

  function go(id) {
    $uibModalStack.dismissAll('navigate')
    $timeout(function () {
      foldersNavigation.go(id)
    })
  }

  function openRecord() {
    vm.openRecord()
  }
}
