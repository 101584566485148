import view from './view.html'

export function container(formlyConfigProvider) {
  'ngInject'

  formlyConfigProvider.setWrapper({
    name: 'container',
    template: view
  })
}
