export function Controller(
  filteringContext,
  foldersNavigation,
  recordsModal,
  $state,
  context,
  accountsData,
  $element,
  $scope
) {
  'ngInject'

  const applyFilter = (rules) => {
    filteringContext.applyFilter(rules, 'folders')
  }

  const clickListener = (event) => {
    if ($element[0].contains(event.target)) {
      return
    }

    this.showResults = false
  }

  this.query = ''
  this.spinner = false

  this.$onInit = () => {
    this.account = context.get('account')
    this.records = []
    this.folders = []
    this.tags = []
    this.tasks = []
    this.popoverTemplate = 'components/pt-tt-search/popover.html'
    this.showResults = false

    document.addEventListener('click', clickListener)

    $scope.$on('$destroy', () => {
      document.removeEventListener('click', clickListener)
    })
  }

  this.goToFolder = (folder) => {
    foldersNavigation.go(folder.id)
  }

  this.goToRecord = (record) => {
    recordsModal.goToModalRoute(record.id)
  }

  this.goToTag = (tag) => {
    applyFilter({ tag: tag.name })
  }

  this.goToTask = (task) => {
    $state.go('account.tasks.task', { taskId: task.id })
  }

  this.hasResults = () => {
    return this.records.length || this.folders.length || this.tags.length || this.tasks.length
  }

  this.hasMoreResults = () => {
    return this.records.length > 5 || this.folders.length > 5 || this.tags.length > 5 || this.tasks.length > 5
  }

  this.resetSearch = () => {
    this.showResults = false
    this.records = []
    this.folders = []
    this.tags = []
    this.tasks = []
  }

  this.search = () => {
    if (!this.query || (this.query && this.query.length < 3)) {
      this.resetSearch()

      return
    }

    this.spinner = true

    accountsData
      .search(this.account, this.query, { limit: 6 })
      .then((response) => {
        this.records = response.records
        this.folders = response.folders
        this.tags = response.tags
        this.tasks = response.tasks
        this.showResults = this.hasResults()
      })
      .catch(() => {})
      .finally(() => {
        this.spinner = false
      })
  }

  this.searchAll = (query) => {
    let queryString = query ?? this.query;
    applyFilter({ search: queryString })
  }

  this.focusSearch = () => {
    if (this.query && this.hasResults()) {
      this.showResults = true
    }
  }
}
