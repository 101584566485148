import * as Sentry from '@sentry/browser'

export function Controller($state, permissionsCheck, context) {
  'ngInject'

  let vm = this

  vm.control = {
    newRecord: newRecord
  }

  vm.account = context.get('account')

  vm.permissionsCheck = permissionsCheck

  function newRecord() {
    if ($state.get('.new-record')) {
      $state.go('.new-record')
    } else {
      $state.go('folder.new-record')
    }
  }
}
