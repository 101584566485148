import angular from 'angular'
import { TagsCache } from './services/cache'
import { TagsData } from './services/data'
import { TagsModel } from './services/models'
import { ptTags } from './components/pt-tags/component'

export const TagsModule = angular
  .module('Tags', [])
  .factory('TagsModel', TagsModel)
  .factory('TagsCache', TagsCache)
  .factory('TagsData', TagsData)
  .component('ptTags', ptTags).name
