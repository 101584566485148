export function ptScrollRight($timeout) {
  'ngInject'

  let directive = {
    link: link,
    restrict: 'A'
  }

  return directive

  function link(scope, element, attrs) {
    attrs.$observe('ptScrollRight', function () {
      $timeout(function () {
        element.scrollLeft(10000)
      })
    })
  }
}
