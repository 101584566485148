import { Controller } from './controller'
import view from './view.html'

export const ptActivityTaskStateWasUpdated = {
  bindings: {
    data: '<',
    options: '<ptActivityOptions',
    isRecord: '@?'
  },
  controller: Controller,
  template: view
}
