import { Controller } from './controller'

export function ptRovingGroup() {
  'ngInject'

  return {
    restrict: 'A',
    scope: {
      ptRovingItems: '<'
    },
    bindToController: true,
    controllerAs: '$ctrl',
    controller: Controller,
    link: (scope, element, attr, ctrl) => {
      const KEY_LEFT = 37
      const KEY_UP = 38
      const KEY_RIGHT = 39
      const KEY_DOWN = 40
      ctrl.itemSelected = 0
      ctrl.element = element[0]

      element[0].addEventListener('keydown', handleKeyDown)

      scope.$on('$destroy', () => {
        element[0].removeEventListener('keydown', handleKeyDown)
      })

      ctrl.control.init()

      function handleKeyDown(e) {
        switch (e.which) {
          case KEY_LEFT:
          case KEY_UP:
            e.preventDefault()

            if (ctrl.itemSelected === 0) {
              selectItem(ctrl.items.length - 1)
            } else {
              selectItem(ctrl.itemSelected - 1)
            }

            break
          case KEY_RIGHT:
          case KEY_DOWN:
            e.preventDefault()

            if (ctrl.itemSelected === ctrl.items.length - 1) {
              selectItem(0)
            } else {
              selectItem(ctrl.itemSelected + 1)
            }

            break
          default:
          //
        }
      }

      function selectItem(index) {
        // unselect currently selected item
        ctrl.items[ctrl.itemSelected].setAttribute('tabindex', -1)

        // select new item
        ctrl.items[index].setAttribute('tabindex', 0)
        ctrl.items[index].focus()

        ctrl.itemSelected = index
      }
    }
  }
}
