import * as Sentry from '@sentry/browser'
import _forIn from 'lodash/forIn'
import _get from 'lodash/get'

export function Controller(gettextCatalog) {
  'ngInject'

  // Define user friendly errors to overwrite the default API error message
  const errors = {
    folders: {
      required: gettextCatalog.getString('There are no folders selected.')
    }
  }

  this.errors = []

  this.$onChanges = () => {
    this.parseErrors()
  }

  this.parseErrors = () => {
    _forIn(this.errorData, (invalidPropertyErrors, invalidPropertyName) => {
      invalidPropertyErrors.forEach((error) => {
        const errorMessage = _get(errors, [invalidPropertyName, error.code])

        if (errorMessage) {
          this.errors.push(errorMessage)
        } else {
          Sentry.captureMessage(
            `ptValidationError showed default error message for: ${invalidPropertyName} > ${error.code}`
          )

          this.errors.push(error.message)
        }
      })
    })
  }
}
