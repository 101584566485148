export function Controller(dateTimeFormat, fileSize, uploadFileActions) {
  'ngInject'

  const vm = this

  vm.control = {
    downloadFile: downloadFile,
    fileUrl: fileUrl
  }

  vm.$onInit = function () {
    vm.upload = vm.resolve.upload
    vm.user = vm.resolve.user
    vm.dateTimeFormat = dateTimeFormat.dateTime()
    vm.formattedSize = fileSize.format(vm.upload.size)
    vm.displayable = false

    vm.downloadLink =
      '<a ng-click="vm.control.downloadFile(vm.upload)" translate>click here to download the PDF file</a>'

    if (vm.upload.is_image || vm.upload.mime === 'application/pdf') {
      vm.displayable = true
    }
  }

  function downloadFile(upload) {
    vm.resolve.download(upload)
  }

  function fileUrl(url) {
    return uploadFileActions.getFileUrl(url)
  }
}
