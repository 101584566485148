export function Controller(ActivitiesField) {
  'ngInject'

  var vm = this

  vm.control = {
    openRecord: openRecord
  }

  vm.from = null

  vm.$onInit = function () {
    var info = ActivitiesField.info(vm.data)

    vm.from = info.from
  }

  function openRecord() {
    vm.openRecord()
  }
}
