export function Controller($scope, recordsData, timezoneUtil, gettextCatalog, DateFormat, session) {
  'ngInject'

  let vm = this

  vm.control = {
    saveDate: saveDate,
    datepickerChange: datepickerChange,
    click: click
  }

  vm.field = {
    label: gettextCatalog.getString('Next check date')
  }

  vm.initialValue = null

  vm.datepickerOptions = {
    showWeeks: false,
    startingDay: 1,
    showButtons: true
  }

  vm.$onInit = function () {
    vm.valueToLocal = timezoneUtil.dateToLocal(vm.record.next_check_at).toDate()
    vm.initialValue = vm.valueToLocal
    vm.dateFormat = DateFormat.toUib(session.user.dateFormat)

    watch()
  }

  function watch() {
    $scope.$watch('vm.record.next_check_at', function () {
      if (vm.record.next_check_at) {
        vm.valueToLocal = timezoneUtil.dateToLocal(vm.record.next_check_at).toDate()
        vm.initialValue = vm.valueToLocal
      }
    })
  }

  function saveDate(value) {
    if (vm.initialValue !== value && typeof value !== 'undefined') {
      return recordsData.saveProperty(vm.record, 'next_check_at', value).catch(() => {})
    }
  }

  function click(event, form) {
    if (event.target.nodeName !== 'INPUT') {
      form.$show()
    }
  }

  function datepickerChange(datePicker, data) {
    if (typeof data !== 'undefined') {
      datePicker.$form.$submit()
    }
  }
}
