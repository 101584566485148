import angular from 'angular'

import { ActivitiesData } from './services/data'
import { ActivitiesDeactivated } from './services/deactivated'
import { ActivitiesField } from './services/field'
import { ActivitiesModel } from './services/models'
import { ActivitiesRecord } from './services/record'
import { ActivitiesRevert } from './services/revert'
import { ActivitiesChecklist } from './services/checklist'

import { ptActivitiesFilterPanel } from './directives/pt-activities-filter-panel/directive'
import { ptActivitiesOptions } from './directives/pt-activities-options/directive'
import { ptActivitiesRecordItems } from './directives/pt-activities-record-items/directive'
import { ptActivitiesTableRow } from './directives/pt-activities-table-row/directive'
import { ptActivityFieldWasCreated } from './directives/pt-activity-field-was-created/directive'
import { ptActivityFieldWasDeleted } from './directives/pt-activity-field-was-deleted/directive'
import { ptActivityFieldWasUpdated } from './directives/pt-activity-field-was-updated/directive'
import { ptActivityFolderWasCreated } from './directives/pt-activity-folder-was-created/directive'
import { ptActivityFolderWasDeleted } from './directives/pt-activity-folder-was-deleted/directive'
import { ptActivityFolderWasMoved } from './directives/pt-activity-folder-was-moved/directive'
import { ptActivityFolderWasUpdated } from './directives/pt-activity-folder-was-updated/directive'
import { ptActivityLogWasActivated } from './directives/pt-activity-log-was-activated/directive'
import { ptActivityLogWasCreated } from './directives/pt-activity-log-was-created/directive'
import { ptActivityLogWasUpdated } from './directives/pt-activity-log-was-updated/directive'
import { ptActivityPartialLogWasCreated } from './directives/pt-activity-partial-log-was-created/directive'
import { ptActivityPartialLogWasUpdated } from './directives/pt-activity-partial-log-was-updated/directive'
import { ptActivityLogWasDeactivated } from './directives/pt-activity-log-was-deactivated/directive'
import { ptActivityNoteWasActivated } from './directives/pt-activity-note-was-activated/directive'
import { ptActivityNoteWasCreated } from './directives/pt-activity-note-was-created/directive'
import { ptActivityNoteWasDeactivated } from './directives/pt-activity-note-was-deactivated/directive'
import { ptActivityRecordMovedFromAccount } from './directives/pt-activity-record-moved-from-account/directive'
import { ptActivityRecordMovedFromFolder } from './directives/pt-activity-record-moved-from-folder/directive'
import { ptActivityRecordMovedToAccount } from './directives/pt-activity-record-moved-to-account/directive'
import { ptActivityRecordMovedToFolder } from './directives/pt-activity-record-moved-to-folder/directive'
import { ptActivityRecordUploadWasAdded } from './directives/pt-activity-record-upload-was-added/directive'
import { ptActivityRecordUploadWasUpdated } from './directives/pt-activity-record-upload-was-updated/directive'
import { ptActivityRecordWasCloned } from './directives/pt-activity-record-was-cloned/directive'
import { ptActivityRecordWasCreated } from './directives/pt-activity-record-was-created/directive'
import { ptActivityRecordWasDeleted } from './directives/pt-activity-record-was-deleted/directive'
import { ptActivityRecordWasImported } from './directives/pt-activity-record-was-imported/directive'
import { ptActivityRecordWasUndeleted } from './directives/pt-activity-record-was-undeleted/directive'
import { ptActivityRecordWasUpdated } from './directives/pt-activity-record-was-updated/directive'
import { ptActivityServiceLogWasCreated } from './directives/pt-activity-service-log-was-created/directive'
import { ptActivitySource } from './directives/pt-activity-source/directive'

import { ptActivitiesModule } from './components/pt-activities'
import { ptActivitiesExportModule } from './components/pt-activities-export'
import { ptActivitiesTableModule } from './components/pt-activities-table'
import { ptActivitiesTaskItemsModule } from './components/pt-activities-task-items'
import { ptActivitiesWidgetModule } from './components/pt-activities-widget'
import { ptActivitiesWidgetFilterModule } from './components/pt-activities-widget-filter'
import { ptActivitiesWidgetItemModule } from './components/pt-activities-widget-item'
import { ptActivityCertificateWasCreatedModule } from './components/pt-activity-certificate-was-created'
import { ptActivityCertificateShareModule } from './components/pt-activity-certificate-share'

import { ptActivityModule } from './components/pt-activity'
import { ptActivityTaskAssigneeWasAddedModule } from './components/pt-activity-task-assignee-was-added'
import { ptActivityTaskAssigneeWasRemovedModule } from './components/pt-activity-task-assignee-was-removed'
import { ptActivityTaskNoteWasCreatedModule } from './components/pt-activity-task-note-was-created'
import { ptActivityTaskStateWasUpdatedModule } from './components/pt-activity-task-state-was-updated'
import { ptActivityTaskUploadWasCreatedModule } from './components/pt-activity-task-upload-was-created'
import { ptActivityTaskUploadWasDeletedModule } from './components/pt-activity-task-upload-was-deleted'
import { ptActivityTaskWasAssignedToLogModule } from './components/pt-activity-task-was-assigned-to-log'
import { ptActivityTaskWasCreatedModule } from './components/pt-activity-task-was-created'
import { ptActivityTaskWasOverdueModule } from './components/pt-activity-task-was-overdue'
import { ptActivityTaskWasUnassignedFromLogModule } from './components/pt-activity-task-was-unassigned-from-log'
import { ptActivityTaskWasUpdatedModule } from './components/pt-activity-task-was-updated'
import { ptActivityServiceCertificateWasCreatedModule } from './components/pt-activity-service-certificate-was-created'

export const ActivitiesModule = angular
  .module('activities', [
    ptActivitiesModule,
    ptActivitiesExportModule,
    ptActivitiesTableModule,
    ptActivitiesTaskItemsModule,
    ptActivitiesWidgetModule,
    ptActivitiesWidgetFilterModule,
    ptActivitiesWidgetItemModule,
    ptActivityCertificateWasCreatedModule,
    ptActivityServiceCertificateWasCreatedModule,
    ptActivityModule,
    ptActivityCertificateShareModule,
    ptActivityTaskAssigneeWasAddedModule,
    ptActivityTaskAssigneeWasRemovedModule,
    ptActivityTaskNoteWasCreatedModule,
    ptActivityTaskStateWasUpdatedModule,
    ptActivityTaskUploadWasCreatedModule,
    ptActivityTaskUploadWasDeletedModule,
    ptActivityTaskWasAssignedToLogModule,
    ptActivityTaskWasCreatedModule,
    ptActivityTaskWasOverdueModule,
    ptActivityTaskWasUnassignedFromLogModule,
    ptActivityTaskWasUpdatedModule
  ])
  .factory('ActivitiesData', ActivitiesData)
  .factory('ActivitiesDeactivated', ActivitiesDeactivated)
  .factory('ActivitiesField', ActivitiesField)
  .factory('ActivitiesModel', ActivitiesModel)
  .factory('ActivitiesRecord', ActivitiesRecord)
  .factory('ActivitiesRevert', ActivitiesRevert)
  .factory('ActivitiesChecklist', ActivitiesChecklist)
  .directive('ptActivitiesFilterPanel', ptActivitiesFilterPanel)
  .directive('ptActivitiesOptions', ptActivitiesOptions)
  .directive('ptActivitiesRecordItems', ptActivitiesRecordItems)
  .directive('ptActivitiesTableRow', ptActivitiesTableRow)
  .directive('ptActivityFieldWasCreated', ptActivityFieldWasCreated)
  .directive('ptActivityFieldWasDeleted', ptActivityFieldWasDeleted)
  .directive('ptActivityFieldWasUpdated', ptActivityFieldWasUpdated)
  .directive('ptActivityFolderWasCreated', ptActivityFolderWasCreated)
  .directive('ptActivityFolderWasDeleted', ptActivityFolderWasDeleted)
  .directive('ptActivityFolderWasMoved', ptActivityFolderWasMoved)
  .directive('ptActivityFolderWasUpdated', ptActivityFolderWasUpdated)
  .directive('ptActivityLogWasActivated', ptActivityLogWasActivated)
  .directive('ptActivityLogWasCreated', ptActivityLogWasCreated)
  .directive('ptActivityLogWasUpdated', ptActivityLogWasUpdated)
  .directive('ptActivityServiceLogWasCreated', ptActivityServiceLogWasCreated)
  .directive('ptActivityPartialLogWasCreated', ptActivityPartialLogWasCreated)
  .directive('ptActivityPartialLogWasUpdated', ptActivityPartialLogWasUpdated)
  .directive('ptActivityLogWasDeactivated', ptActivityLogWasDeactivated)
  .directive('ptActivityNoteWasActivated', ptActivityNoteWasActivated)
  .directive('ptActivityNoteWasCreated', ptActivityNoteWasCreated)
  .directive('ptActivityNoteWasDeactivated', ptActivityNoteWasDeactivated)
  .directive('ptActivityRecordMovedFromAccount', ptActivityRecordMovedFromAccount)
  .directive('ptActivityRecordMovedFromFolder', ptActivityRecordMovedFromFolder)
  .directive('ptActivityRecordMovedToAccount', ptActivityRecordMovedToAccount)
  .directive('ptActivityRecordMovedToFolder', ptActivityRecordMovedToFolder)
  .directive('ptActivityRecordUploadWasAdded', ptActivityRecordUploadWasAdded)
  .directive('ptActivityRecordUploadWasUpdated', ptActivityRecordUploadWasUpdated)
  .directive('ptActivityRecordWasCloned', ptActivityRecordWasCloned)
  .directive('ptActivityRecordWasCreated', ptActivityRecordWasCreated)
  .directive('ptActivityRecordWasDeleted', ptActivityRecordWasDeleted)
  .directive('ptActivityRecordWasImported', ptActivityRecordWasImported)
  .directive('ptActivityRecordWasUndeleted', ptActivityRecordWasUndeleted)
  .directive('ptActivityRecordWasUpdated', ptActivityRecordWasUpdated)
  .directive('ptActivitySource', ptActivitySource).name
