import { Controller } from './controller'
import view from './view.html'

export const ptActivitiesTable = {
  template: view,
  controller: Controller,
  bindings: {
    activities: '<'
  }
}
