import { Controller } from './controller'
import view from './view.html'

export const ptRecordsTasks = {
  bindings: {
    tasks: '<',
    useModal: '<'
  },
  controller: Controller,
  template: view
}
