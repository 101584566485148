export function Controller(ErrorsData, tracking) {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {
    closeModal
  }

  $ctrl.errorDetails = null

  $ctrl.$onInit = function () {
    tracking.trackEvent({ action: 'error', category: $ctrl.resolve.error, label: 'modal' })
  }

  function closeModal() {
    $ctrl.dismiss({ $value: 'close' })

    if ($ctrl.resolve.closeCallback) {
      $ctrl.resolve.closeCallback()
    }
  }
}
