import _extend from 'lodash/extend'
import _pick from 'lodash/pick'

export function Controller(recordsData, FrequenciesData, gettextCatalog, context) {
  'ngInject'

  let vm = this

  vm.control = {
    saveFrequency: saveFrequency
  }

  vm.field = {
    required: true,
    label: gettextCatalog.getString('Inspection frequency')
  }

  /////////////

  vm.$onInit = function () {
    vm.frequencies = FrequenciesData.get(context.get('account'))
  }

  function saveFrequency(value) {
    let frequency = FrequenciesData.get(context.get('account'), value)

    return recordsData
      .saveProperty(vm.record, 'frequency_id', value)
      .then(function () {
        _extend(vm.record.frequency, _pick(frequency, ['id', 'name', 'notify', 'rule', '$pk']))
      })
      .catch(() => {})
  }
}
