import _upperFirst from 'lodash/upperFirst'
import _lowerCase from 'lodash/lowerCase'

export function Controller(summariesData) {
  'ngInject'

  let vm = this

  vm.isLoading = false

  vm.$onInit = function () {}

  vm.convertCase = function (string) {
    return _upperFirst(_lowerCase(string))
  }

  vm.toggle = function () {
    if (!vm.isLoading) {
      vm.isLoading = true

      summariesData.toggle(vm.summary).finally(function () {
        vm.isLoading = false
      })
    }
  }
}
