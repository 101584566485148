import { Controller } from './controller'
import view from './view.html'

export function ptAccountsBasicTable() {
  let directive = {
    restrict: 'A',
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      subscriptionId: '@',
      limit: '@'
    }
  }

  return directive
}
