import { Controller } from './controller'
import view from './view.html'

export const ptIauditorAuditTable = {
  bindings: {
    audits: '<',
    customFields: '<'
  },
  controller: Controller,
  template: view
}
