export function AddressesData() {
  'ngInject'

  var service = {
    create,
    update,
    delete: deleteAddress
  }

  return service

  ///////////////

  function create(account, address) {
    return account.addresses.$build(address).$save().$asPromise()
  }

  function update(address, newAddress) {
    return address.$save(newAddress).$asPromise()
  }

  function deleteAddress(address) {
    return address.$destroy().$asPromise()
  }
}
