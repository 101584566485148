export function AuthenticateRedirect($state, stateMonitor) {
  'ngInject'

  const service = {
    afterLogin,
    afterLogout
  }

  return service

  ///////////

  function afterLogin(params) {
    let afterLoginState

    if (params && params.then && params.then !== 'error') {
      $state.go(params.then)
    } else {
      afterLoginState = findState()
      $state.go(afterLoginState.state.name, afterLoginState.params)
    }
  }

  function afterLogout() {
    $state.go('login')
  }

  function findState() {
    let target = stateMonitor.redirectState()

    if (target && target.state && target.state.name && target.state.name !== 'login' && target.state.name !== 'error') {
      return target
    }

    return {
      state: {
        name: 'accounts'
      },
      params: {}
    }
  }
}
