import moment from 'moment-timezone'

export function Moment(gettextCatalog) {
  'ngInject'

  moment.updateLocale('en', {
    monthsShort: [
      /// Short month name
      gettextCatalog.getString('Jan'),
      /// Short month name
      gettextCatalog.getString('Feb'),
      /// Short month name
      gettextCatalog.getString('Mar'),
      /// Short month name
      gettextCatalog.getString('Apr'),
      /// Short month name
      gettextCatalog.getString('May'),
      /// Short month name
      gettextCatalog.getString('Jun'),
      /// Short month name
      gettextCatalog.getString('Jul'),
      /// Short month name
      gettextCatalog.getString('Aug'),
      /// Short month name
      gettextCatalog.getString('Sep'),
      /// Short month name
      gettextCatalog.getString('Oct'),
      /// Short month name
      gettextCatalog.getString('Nov'),
      /// Short month name
      gettextCatalog.getString('Dec')
    ],
    relativeTime: {
      /// Relative date - Used like 'In 2 weeks'
      future: gettextCatalog.getString('In %s'),
      /// Relative date - Used like '2 weeks ago'
      past: gettextCatalog.getString('%s ago'),
      /// Relative date
      s: gettextCatalog.getString('Seconds'),
      /// Relative date
      m: gettextCatalog.getString('1 Minute'),
      /// Relative date - Used like '20 Minutes'
      mm: gettextCatalog.getString('%d Minutes'),
      /// Relative date
      h: gettextCatalog.getString('1 Hour'),
      /// Relative date - Used like '10 Hours'
      hh: gettextCatalog.getString('%d Hours'),
      /// Relative date
      d: gettextCatalog.getString('1 Day'),
      /// Relative date - Used like '10 Days'
      dd: gettextCatalog.getString('%d Days'),
      /// Relative date
      M: gettextCatalog.getString('1 Month'),
      /// Relative date - Used like '5 Months'
      MM: gettextCatalog.getString('%d Months'),
      /// Relative date
      y: gettextCatalog.getString('1 Year'),
      /// Relative date - Used like '2 Years'
      yy: gettextCatalog.getString('%d Years')
    }
  })

  moment.relativeTimeThreshold('s', 59)
  moment.relativeTimeThreshold('m', 59)
  moment.relativeTimeThreshold('h', 23)
  moment.relativeTimeThreshold('d', 29)
  moment.relativeTimeThreshold('M', 11)
}
