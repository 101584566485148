export function Controller($uibModal, gettextCatalog) {
  'ngInject'

  let $ctrl = this

  $ctrl.crumbs = [
    {
      name: gettextCatalog.getString('Settings'),
      link: 'settings.general'
    }
  ]

  $ctrl.self = gettextCatalog.getString('Deactivate')

  $ctrl.$onInit = function () {}

  $ctrl.deactivate = () => {
    $uibModal
      .open({
        component: 'ptAccountDeactivateConfirm'
      })
      .result.catch(function () {})
  }
}
