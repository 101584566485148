import { Controller } from './controller'
import view from './view.html'

export const ptFutureInspectionsWidgetFilter = {
  bindings: {
    onUpdateFrequency: '&'
  },
  controller: Controller,
  template: view
}
