import angular from 'angular'
import { react2angular } from 'react2angular'

import { ptLogsDiscardCriteriaResults } from './component'
import DiscardCriteriaContent from 'AppComponents/logs/DiscardCriteriaContent'

export const ptLogsDiscardCriteriaResultsModule = angular
  .module('logs.ptLogsDiscardCriteriaResults', [])
  .component('ptLogsDiscardCriteriaContent', react2angular(DiscardCriteriaContent, ['source']))
  .component('ptLogsDiscardCriteriaResults', ptLogsDiscardCriteriaResults).name
