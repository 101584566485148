import angular from 'angular'
import { ptSearch } from './components/pt-search/component'
import { ptTtSearch } from './components/pt-tt-search/component'
import { ptUniversalSearch } from './components/pt-universal-search/component'

export const SearchModule = angular
  .module('search', [])
  .component('ptSearch', ptSearch)
  .component('ptUniversalSearch', ptUniversalSearch)
  .component('ptTtSearch', ptTtSearch).name
