export function Controller(filteringContext, recordsPath) {
  'ngInject'

  let vm = this

  vm.control = {
    tagClicked: tagClicked
  }

  vm.$onInit = function () {
    vm.crumbs = recordsPath.getRelativePath(vm.record)
  }

  function tagClicked(value, $event) {
    $event.stopPropagation()
    filteringContext.applyFilter({ tag: value }, 'folders')
  }
}
