import { createTaskNote } from 'AppCore/task/api'
import { AccountUpload } from 'AppCore/upload'

export function Controller(context, permissionsCheck) {
  'ngInject'

  this.$onInit = () => {
    const account = context.get('account')
    const isViewOnly = permissionsCheck.is('viewOnly', account)
    const canEditTasks = permissionsCheck.can('edit', 'tasks', account)
    this.editable = !isViewOnly && canEditTasks
    this.task = this.resolve.task
    this.note = ''
    this.files = []
    this.modalTitle = 'Task Note'
  }

  this.addNote = () => {
    const noteFiles =
      this.files.length &&
      this.files.map((file) => ({
        type: 'upload_id',
        value: file.id
      }))

    if (this.note.length || noteFiles.length) {
      createTaskNote({
        taskId: this.task.id,
        payload: {
          ...(this.note.length && { comment: this.note }),
          ...(noteFiles.length && { files: noteFiles })
        }
      }).then((newNote) => {
        this.dismiss({ $value: newNote })
      })
    }
  }

  this.noteUpdated = (note: string) => {
    this.note = note
  }

  this.filesUpdated = (files: AccountUpload[]) => {
    this.files = files
  }
}
