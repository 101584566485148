export function RecordsBatchDeleteRoute($stateProvider) {
  'ngInject'

  let batchDeleteRecordModal

  $stateProvider.state('bucket.delete-records', deleteRecordsStates())
  $stateProvider.state('folders.delete-records', deleteRecordsStates())
  $stateProvider.state('folder.delete-records', deleteRecordsStates())
  $stateProvider.state('folder.scan.delete-records', deleteRecordsStates())
  $stateProvider.state('folders.scan.delete-records', deleteRecordsStates())
  $stateProvider.state('account.scan.delete-records', deleteRecordsStates())

  function deleteRecordsStates() {
    return {
      url: '/records/delete?{recordIds}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: editRecordPermission,
        resolvedRecords: resolveDeleteRecords
      },
      onEnter: onBatchDeleteRecordEnter,
      onExit: onBatchDeleteRecordExit
    }
  }

  function editRecordPermission(account, member, permissionsCheck, ErrorsResolve) {
    'ngInject'

    return permissionsCheck.canPromise('edit', 'records', account).catch(ErrorsResolve.caught)
  }

  function resolveDeleteRecords(account, $stateParams, recordsResolves, ErrorsDisplay) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    let selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves.records(selection, 'log,note,folder').catch(() => {
      return ErrorsDisplay.catch('select_records')
    })
  }

  function onBatchDeleteRecordEnter($uibModal, $state, resolvedRecords) {
    'ngInject'

    batchDeleteRecordModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptRecordsBatchDelete',
      resolve: {
        records: () => resolvedRecords
      }
    })

    batchDeleteRecordModal.result.catch(() => {
      $state.go('^')
    })
  }

  function onBatchDeleteRecordExit() {
    'ngInject'

    batchDeleteRecordModal.close()
  }
}
