import { Controller } from './controller'
import view from './view.html'

export const ptAccounts = {
  bindings: {
    user: '<',
    $transition$: '<'
  },
  controller: Controller,
  template: view
}
