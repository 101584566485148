import angular from 'angular'
import { react2angular } from 'react2angular'
import { ptTaskLogAssign } from './component'

import { TaskLogsList } from 'AppCore/task/components'

export const TaskLogAssign = angular
  .module('tasks.ptTaskLogAssign', [])
  .component('ptTaskLogAssign', ptTaskLogAssign)
  .component('ptTaskLogsList', react2angular(TaskLogsList, ['task', 'logs', 'record', 'onAssigned', 'dateFormat'])).name
