export function Controller($scope, foldersTree, ivhTreeviewMgr, ivhTreeviewBfs, context) {
  'ngInject'

  const $ctrl = this
  let picked = null

  $ctrl.folders = null

  $ctrl.options = {
    idAttribute: 'id',
    labelAttribute: 'name',
    twistieCollapsedTpl: `<pt-icon name="'rightDir'" />`,
    twistieExpandedTpl: `<pt-icon name="'downDir'" />`,
    twistieLeafTpl: '<span class="icon-fw icon-none"></span>',
    validate: false,
    defaultSelectedState: false,
    nodeTpl: [
      '<div>',
      '<span ivh-treeview-toggle>',
      '<span ivh-treeview-twistie></span>',
      '</span>',
      '<span ng-class="{selected: node.$highlight}" class="ivh-treeview-node-label" ng-click="trvw.select(node, !trvw.isSelected(node))">',
      '{{::trvw.label(node)}}',
      '</span>',
      '<div ivh-treeview-children ng-if="trvw.isExpanded(node)"></div>',
      '</div>'
    ].join('\n')
  }

  $ctrl.control = {
    changeHandler: changeHandler
  }

  $ctrl.$onInit = function () {

    if($ctrl.selectedAccount) {
      $ctrl.account = $ctrl.selectedAccount  
    } else {
      $ctrl.account = context.get('account')
    }

    getFolders()

    $scope.$watch('$ctrl.selectedAccount', function(newValue, oldValue) {
      if(newValue === oldValue) {
        return;
      }

      picked = null
      $ctrl.account = newValue

      getFolders(true)
    })

    $scope.$on('folder_was_created', function (event, data) {
      getFolders(true, data.folderId)
    })

    $scope.$on('folder_picker_clear_selection', function () {
      picked = null

      ivhTreeviewBfs($ctrl.folders, function (node) {
        node.$highlight = false
      })

      emitFolderChange()
    })
  }

  $ctrl.$onChanges = function () {}

  function emitFolderChange() {
    $ctrl.onFolderChange({
      $event: {
        picked: picked
      }
    })
  }

  //////////////////////////////

  function getFolders(refresh = false, newFolderId?) {
    foldersTree
      .getTree($ctrl.account, refresh)
      .then(function (folders) {
        if ($ctrl.includeRoot) {
          $ctrl.folders = [
            { name: 'ROOT FOLDER', id: $ctrl.account.folder.id, children: folders, __ivhTreeviewExpanded: true }
          ]
        } else {
          $ctrl.folders = folders
        }

        // If a new folder was created by the user, highlight that folder in the new folder tree
        // we have just retrieved.
        if (newFolderId) {
          ivhTreeviewBfs($ctrl.folders, function (node) {
            if (node.id === newFolderId) {
              node.$highlight = true
              picked = node

              emitFolderChange()
            }
          })

          ivhTreeviewMgr.expandTo($ctrl.folders, picked.id)
        }
      })
      .catch(() => {})
  }

  function changeHandler(folder) {
    ivhTreeviewMgr.deselectAll($ctrl.folders)

    ivhTreeviewBfs($ctrl.folders, function (node) {
      node.$highlight = false
    })

    if (folder.id !== (picked && picked.id)) {
      ivhTreeviewMgr.expand($ctrl.folders, folder.id)

      folder.$highlight = true
      picked = folder
    } else {
      ivhTreeviewMgr.collapse($ctrl.folders, folder.id)

      picked = null
    }

    emitFolderChange()
  }
}
