import angular from 'angular'
import { ptPermissionsDisplayModule } from './directives/pt-permissions-display'

import { permissionsConverter } from './services/converter'
import { PermissionModel } from './services/models'
import { permissionsCheck } from './services/permissions-check'

export const PermissionsModule = angular
  .module('permissions', [ptPermissionsDisplayModule])
  .factory('permissionsConverter', permissionsConverter)
  .factory('permissionsCheck', permissionsCheck)
  .factory('PermissionModel', PermissionModel).name
