export function foldersCache(EventsData, EventsPusher) {
  'ngInject'

  let service = {
    get: get,
    set: set,
    clear: clear
  }

  // 60 mins
  let TTL = 1000 * 60 * 60
  let cache = {}

  init()

  return service

  function init() {
    EventsData.on('folder_was_updated', clear)
    /*
    TODO I have no idea what the purpose of this was
    EventsPusher.registerStateChangeObserverCallback(function(data) {
      if (data.current && data.current === 'connected') {
        clear()
      }
    })
     */
  }

  function get(id, includes) {
    if (cache[id] && cache[id][includes]) {
      if (expired(cache[id][includes].$expires)) {
        delete cache[id][includes]
      } else {
        return cache[id][includes]
      }
    }
  }

  function set(id, includes, folder) {
    if (!cache[id]) {
      cache[id] = {}
    }

    cache[id][includes] = folder
    cache[id][includes].$expires = Date.now() + TTL
  }

  function clear() {
    cache = {}
  }

  function expired(expireTime) {
    return Date.now() > expireTime
  }
}
