export function Controller() {
  'ngInject'

  var vm = this

  vm.control = {
    openRecord: openRecord
  }

  vm.$onInit = function () {}

  function openRecord() {
    vm.openRecord()
  }
}
