import { createResource } from '../../api/resource'
import { createResponsePaginationTransformer, createResponseTransformer, createRequestTransformer } from '../../api/transformer'
import { Log, UpdateLogRequest } from '..'

interface Query {
  include?: string
}

interface Params {
  accountId: string
  recordId: string,
  logId?: string,
  query?: Query,
  log?: Partial<UpdateLogRequest>
}

const transformRequest = createRequestTransformer<Query>()
const transformUpdateRequest = createRequestTransformer<any>()

export const fetchLogs = createResource({
  request: ({ accountId, recordId, query }: Params) => ({
    url: `accounts/${accountId}/records/${recordId}/logs`,
    method: 'GET',
    params: transformRequest(query)
  }),
  transform: createResponsePaginationTransformer<Log>(
    'record',
    'user',
    'state',
    'uploads',
    'source',
    'checklistCompletions'
  )
})

export const fetchLog = createResource({
  request: ({ accountId, recordId, logId, query }: Params) => ({
    url: `accounts/${accountId}/records/${recordId}/logs/${logId}`,
    method: 'GET',
    params: transformRequest(query)
  }),
  transform: createResponseTransformer<Log>(
    'record',
    'user',
    'state',
    'uploads',
    'source',
    'checklistCompletions'
  )
})

export const updateLog = createResource({
  request: ({ accountId, recordId, logId, log }: Params) => ({
    url: `accounts/${accountId}/records/${recordId}/logs/${logId}`,
    method: 'PATCH',
    data: transformUpdateRequest(log, ['checklists'])
  }),
  transform: createResponseTransformer<Log>(
    'record',
    'user',
    'state',
    'uploads',
    'source',
    'checklistCompletions'
  )
})

