export function TasksRoute($stateProvider) {
  'ngInject'

  // Profile Tasks Page
  $stateProvider.state('tasks', {
    parent: 'layoutFull',
    url: '/tasks?account&state&association',
    params: {
      state: 'open,pending'
    },
    views: {
      'content@layoutFull': {
        component: 'ptTasks'
      }
    },
    onEnter: onTasksEnter
  })

  // Account Tasks Page
  $stateProvider.state('account.tasks', {
    url: '/tasks?folders&assignees&user&state&record',
    params: {
      state: 'open,pending'
    },
    views: {
      'content@layoutFull': {
        component: 'ptTasks'
      }
    },
    onEnter: onTasksEnter
  })

  function onTasksEnter(gettextCatalog, page) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Tasks'))
  }
}
