import { Controller } from './controller'
import view from './view.html'

export const ptImportsCrumbs = {
  bindings: {
    crumbs: '<',
    self: '<'
  },
  controller: Controller,
  template: view
}
