import _omit from 'lodash/omit'
import { fetchLogs, fetchLog, updateLog } from 'AppCore/logs/api/fetch-logs-resource'
import { UpdateLogRequest } from 'AppCore/logs'

export const LogsData = (EventsData, context, TutorialsFlags, $q, timezoneUtil) => {
  'ngInject'

  const create = (record, log) => {
    return record.logs
      .$build(log)
      .$save()
      .$asPromise()
      .then((savedLog) => {
        TutorialsFlags.setUserFlag('created_log')
        logCreatedSuccess(record, savedLog)

        return savedLog
      })
  }

  const update = (accountId, record, logId, log) => {
    let defer = $q.defer();
    const { id: recordId } = record;

    const cleanedLog = _omit<UpdateLogRequest>(log, [
      'active',
      'createdAt',
      'updatedAt',
      'loggedAt',
      'logged_at',
      'record',
      'user',
      'source',
      'checklistCompletions',
      'state',
      'lat',
      'lng'
    ]);


    if(cleanedLog.next_check_at){
      cleanedLog.next_check_at = timezoneUtil.localToAccountTz(cleanedLog.next_check_at).format('YYYY-MM-DD')
    }


    updateLog({
      accountId,
      recordId,
      logId,
      log: cleanedLog
    }).then((savedLog) => {
      logUpdatedSuccess(record, savedLog)
      defer.resolve(savedLog)
    })
    .catch((error) => {
      defer.resolve(error.response.data)
    })

    return defer.promise;
  }

  const getLogs = (accountId, recordId, includes) => {
    const defer = $q.defer()

    fetchLogs({
      accountId,
      recordId,
      ...(includes && { query: { include: includes.join() } })
    })
      .then((response) => {
        defer.resolve(response)
      })
      .catch((error) => {
        defer.resolve(error.response.data)
      })

    return defer.promise
  }

  const getLog = (accountId, recordId, logId, includes) => {
    let promises = []
    let defer = $q.defer()

    const handleGetRecord = context.get('account').records
      .$find(recordId)
      .$asPromise();

    const handleGetLog = fetchLog({
      accountId,
      recordId,
      logId,
      ...(includes && { query: { include: includes.join() } })
    });

    promises.push(handleGetRecord);
    promises.push(handleGetLog);

    $q.all(promises).then(([record, log]) => {
      log.next_check_at = record.next_check_at
    
      const checklistCompletions = [];

      for (const completion of log.checklistCompletions) {
        const checklist = completion.checklist;

        const completionSections = completion.sections.data;
        const checklistSections = checklist.data.sections.data;

        // Sections of the checklist template
        for(const section of checklistSections) {
          // Fields of the checklist template section
          for(const field of section.fields.data) {
            // Find the completion section that matches the template section
            const completionSection = completionSections.find((completionSection) => {
              return completionSection.id === section.id;
            });

            if (completionSection) {
              const completionField = completionSection.fields.data.find((completionField) => {
                return completionField.id === field.id;
              });

              if (completionField) {
                if (completionField.type === 'genfile' || completionField.type === 'imgfile' || completionField.type === 'sigfile') {
                  field.responseUploads = completionField.responseUploads;
                } else {
                  field.value = completionField.value;
                }
  
                field.comments = completionField.comments
              }
            }
          }
        }

        completion.sections = checklistSections;
  
        checklistCompletions.push(completion);
      }
      const finalObject = [{...record, checklists: checklistCompletions, log}];
      defer.resolve(finalObject)
    }).catch((error) => {
      defer.resolve(error.response.data)
    })

    return defer.promise
  }


  const logCreatedSuccess = (record, log) => {
    EventsData.pushEvent('log_was_created', {
      historyId: log.id,
      type: 'log',
      recordId: record.id,
      folderId: record.folder.id,
      active: log.active
    })
  }

  const logUpdatedSuccess = (record, log) => {
    EventsData.pushEvent('log_was_updated', {
      historyId: log.id,
      type: 'log',
      recordId: record.id,
      folderId: record.folder.id,
      active: log.active
    })
  }

  const service = {
    create,
    update,
    getLogs,
    getLog
  }

  return service
}
