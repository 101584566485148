export function Controller($stateParams, usersData, Authenticate, AuthenticateRedirect) {
  'ngInject'

  let vm = this
  const $ctrl = this

  vm.control = {
    registerSuccess: registerSuccess
  }

  function registerSuccess(user) {
    Authenticate.attemptLogin(user)
      .then(function () {
        AuthenticateRedirect.afterLogin($stateParams)
      })
      .catch(() => {})
  }
}
