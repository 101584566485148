import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _some from 'lodash/some'

export function Controller($uibModal, uploadsData, uploadFileActions, EventsData, BASE_URL, $scope, context) {
  'ngInject'

  const vm = this
  let recordUploadModal

  vm.shouldShowUploadModal = false

  vm.control = {
    getFileType: getFileType,
    remove: remove,
    fileUrl: fileUrl,
    view: view,
    toggleCoverImage: toggleCoverImage,
    upload: upload,
    openUploadModal: openUploadModal
  }

  //////////

  vm.$onInit = function () {
    watch()
    uploadTypeCheck()
  }

  function openUploadModal() {
    recordUploadModal = $uibModal.open({
      component: 'ptUploadsAddFile',
      windowClass: 'record-upload',
      size: 'lg'
    })

    recordUploadModal.result
      .then(function (files) {
        if (vm.files.length) {
          vm.files = vm.files.concat(files)
        } else {
          vm.files = files
        }

        vm.control.upload()
      })
      .catch(function () {})
  }

  function uploadTypeCheck() {
    uploadsData
      .uploadTypeCheck()
      .then(function (shouldShowUploadModal) {
        vm.shouldShowUploadModal = shouldShowUploadModal
      })
      .catch(function () {})
  }

  function watch() {
    $scope.$watchCollection('vm.record.uploads', function () {
      vm.files = vm.record.uploads
    })
  }

  function remove(uploadedFile) {
    // Persist
    uploadsData.remove(uploadedFile).then(function () {
      pushEvent('record_upload_was_updated')
    })

    // Remove from local array
    vm.files = _filter(vm.files, function (file) {
      return file.id !== uploadedFile.id
    })
  }

  /**
   * File URL for the thumbnails
   * @param url
   */
  function fileUrl(url) {
    return BASE_URL + url + '&width=100&height=100'
  }

  function view(file) {
    uploadFileActions.viewFile(file, null)
  }

  function toggleCoverImage(file) {
    const changeTo = !file.default

    _forEach(vm.files, function (item) {
      item.default = false
    })

    file.default = changeTo

    uploadsData
      .saveCoverImageState(file)
      .then(function () {
        pushEvent('record_upload_was_updated')
      })
      .catch(function () {})
  }

  function upload() {
    if (vm.files.length) {
      uploadsData.removeFailedUploads(vm.files)

      _forEach(vm.files, function (file, key) {
        // File already uploaded, go straight to attaching to record
        if (file.type === 'iauditor') {
          updateRecord(file.value, file.type)
        } else if (!file.id && !file.value && !file.uploading) {
          vm.uploading = true

          uploadsData.upload(file, context.get('account').id).then(uploadSuccess, uploadError).finally(complete)
        }

        function uploadSuccess(fileId, type) {
          file.value = fileId

          updateRecord(fileId, type)
        }

        function updateRecord(fileId, type) {
          uploadsData.saveSingle(vm.record, fileId, type).then(saveSuccess, uploadError)
        }

        function saveSuccess(result) {
          vm.files[key] = result

          pushEvent('record_upload_was_added')
        }

        function uploadError() {
          file.error = true
        }

        function complete() {
          file.uploading = false
          vm.uploading = _some(vm.files, { uploading: true })
        }
      })
    }
  }

  function pushEvent(event) {
    EventsData.pushEvent(event, {
      recordId: vm.record.id,
      folderId: vm.record.folder.id
    })
  }

  function getFileType(file) {
    return uploadsData.getUploadType(file)
  }
}
