//import './popover.html'
//import './start.html'

import _sortBy from 'lodash/sortBy'

export function Controller($scope, TutorialsFlags, $uibModal, moment, session, EventsData) {
  'ngInject'

  const $ctrl = this

  const ignoreFlags = ['read_discard_criteria_warning']

  $ctrl.control = {
    viewInformation
  }

  $ctrl.popover = {
    // These files need importing at the top
    template: 'components/pt-tutorials-menu/popover.html',
    start: 'components/pt-tutorials-menu/start.html'
  }

  $ctrl.$onInit = () => {
    $ctrl.showTutorialsIcon = false
    $ctrl.tutorials = []
    $ctrl.popoverStartOpen = false

    TutorialsFlags.getUserFlags()
      .then((response) => {
        const tutorials = response.filter((tutorial) => !ignoreFlags.includes(tutorial.key))
        const completedTutorials = tutorials.filter((tutorial) => tutorial.completed_at)
        const hasSomeTutorials = tutorials.length && completedTutorials.length < tutorials.length
        const hasAllTutorials = tutorials.length && !completedTutorials.length

        $ctrl.tutorials = _sortBy(tutorials, 'position')

        // Only show the tutorials icon if there are tutorials to complete
        if (hasSomeTutorials) {
          $ctrl.showTutorialsIcon = true
        }

        if (hasAllTutorials && !localStorage.getItem(`startDismissed-${session.user.id}`)) {
          $ctrl.popoverStartOpen = true

          // Broadcast a message to the app so other components can apply styling where needed
          EventsData.broadcast('start:enabled')
        }
      })
      .catch(() => {})

    $scope.$on('tutorial_completed', (event, data) => {
      $ctrl.tutorials.forEach((tutorial) => {
        if (data.tutorial.id === tutorial.id && tutorial.completed_at === null) {
          tutorial.completed_at = moment().format('YYYY-MM-DD HH:mm:ss')
        }
      })
    })
  }

  $ctrl.$onChanges = () => {}

  this.dismissStart = () => {
    localStorage.setItem(`startDismissed-${session.user.id}`, 'true')
    $ctrl.popoverStartOpen = false

    EventsData.broadcast('start:disabled')
  }

  function viewInformation(tutorial) {
    $uibModal
      .open({
        component: 'ptTutorialsInfoView',
        resolve: {
          tutorial: () => tutorial
        }
      })
      .result.catch(() => {})
  }
}
