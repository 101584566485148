export function Controller($stateParams, summariesData) {
  'ngInject'

  let vm = this

  vm.error = null
  vm.submitting = true
  vm.success = null

  vm.$onInit = function () {
    unsubscribe()
  }

  function unsubscribe() {
    summariesData
      .unsubscribe($stateParams.summaryId)
      .then(unsubscribeSuccess, unsubscribeError)
      .finally(function () {
        vm.submitting = false
      })
  }

  function unsubscribeSuccess() {
    vm.success = true
  }

  function unsubscribeError(rejection) {
    if (rejection.error && rejection.error === 'summary_is_already_deactive') {
      vm.success = true
    } else {
      vm.error = rejection.error || true
    }
  }
}
