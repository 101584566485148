import lazy from 'lazy.js'
import * as Sentry from '@sentry/browser'

export const session = (amMoment, dateTimeFormat, gettextCatalog) => {
  'ngInject'

  let user = null
  let accessToken = null
  let observerCallbacks = []

  const service = {
    get token() {
      return accessToken
    },
    set token(theToken) {
      accessToken = theToken
    },
    get user() {
      return user
    },
    set user(userResponse) {
      user = userResponse

      amMoment.changeTimezone(user.timezone)
      gettextCatalog.setCurrentLanguage(user.locale)

      dateTimeFormat.date(user.date_format)
      dateTimeFormat.time(user.time_format)

      notifyObservers()
    },
    destroy: destroy,
    registerObserverCallback: registerObserverCallback
  }

  return service

  function destroy() {
    Sentry.addBreadcrumb({
      message: 'Destroying Session'
    })
    accessToken = null
    user = null
    notifyObservers()
  }

  function registerObserverCallback(callback) {
    observerCallbacks.push(callback)
  }

  function notifyObservers() {
    lazy(observerCallbacks).each(function (callback) {
      callback()
    })
  }
}
