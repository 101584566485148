export const ptRecordFieldEsc = () => {
  const directive = {
    restrict: 'A',
    link: (scope, element) => {
      const stopProp = (event) => {
        let isEscape

        if ('key' in event) {
          isEscape = event.key === 'Escape' || event.key === 'Esc'
        } else {
          isEscape = event.keyCode === 27
        }

        if (isEscape) {
          event.stopPropagation()
        }
      }

      element[0].addEventListener('keydown', stopProp)

      scope.$on('$destroy', () => {
        element[0].removeEventListener('keyDown', stopProp)
      })
    }
  }

  return directive
}
