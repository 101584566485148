import _forEach from 'lodash/forEach'

export function shorten() {
  'ngInject'

  const shortenFilter = (html) => {
    let htmlString = html
    let urls = htmlString.match(/<a[^>]*>(.*?)<\/a>/g)

    if (urls) {
      urls = urls.map(function (val) {
        return val.replace(/<a?[^>]*a?>/g, '')
      })
    }

    _forEach(urls, function (url) {
      if (url.length > 33) {
        htmlString = htmlString.replace('>' + url + '<', '>' + url.substr(0, 30) + '...<')
      }
    })

    return htmlString
  }

  return shortenFilter
}
