export function ErrorsData(ERRORS, HELPCENTRE_URL) {
  'ngInject'

  let lastError = null

  const service = {
    get,
    currentError
  }

  return service

  //////////////

  function get(error) {
    let result

    if (error && ERRORS[error]) {
      result = ERRORS[error]

      if (result.articleId) {
        result.url = HELPCENTRE_URL + result.articleId
      }
    }

    return result
  }

  function currentError(error) {
    let thisError

    if (typeof error === 'undefined') {
      thisError = lastError
    } else {
      lastError = error
    }

    return thisError
  }
}
