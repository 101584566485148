import view from './view.html'

export function money(formlyConfig) {
  'ngInject'

  formlyConfig.setType({
    name: 'money',
    extends: 'input',
    template: view
  })
}
