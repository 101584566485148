import { Controller } from './controller'
import view from './view.html'

export const ptAccount = {
  bindings: {
    account: '<',
    member: '<',
    filters: '<',
    user: '<'
  },
  controller: Controller,
  template: view
}
