import { Controller } from './controller'
import view from './view.html'

export const ptFolderPicker = {
  bindings: {
    onFolderChange: '&',
    includeRoot: '=',
    selectedAccount: '='
  },
  controller: Controller,
  template: view
}
