import { createResource } from '../../api/resource'
import { createRequestTransformer, createResponseArrayTransformer } from '../../api/transformer'
import { Member } from '../../member'

interface MembersFolderRequest {
  members: UUID[]
}

interface Params {
  accountId: UUID
  folderId: UUID
  payload: MembersFolderRequest
}

const requestTransformer = createRequestTransformer<MembersFolderRequest>()

export const updateMembersFolder = createResource({
  request: ({ accountId, folderId, payload }: Params) => ({
    url: `accounts/${accountId}/folders/${folderId}/members`,
    method: 'PUT',
    data: requestTransformer(payload)
  }),
  transform: createResponseArrayTransformer<Member>('user', 'permissions')
})
