export function pagination() {
  'ngInject'

  let service = {
    totalResults: totalResults,
    hasMorePages: hasMorePages,
    nextPage: nextPage
  }

  return service

  ////////////////

  function hasMorePages(collection) {
    let meta = collection.$metadata || collection.meta

    return meta && meta.pagination && meta.pagination.current_page < meta.pagination.total_pages
  }

  function totalResults(collection) {
    let meta = collection.$metadata || collection.meta

    return meta && meta.pagination && meta.pagination.total
  }

  function nextPage(collection) {
    let meta = collection.$metadata || collection.meta

    return (meta && meta.pagination && meta.pagination.current_page + 1) || 1
  }
}
