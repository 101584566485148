import _sortBy from 'lodash/sortBy'

export function Controller($scope, $filter, iAuditorData, $stateParams) {
  'ngInject'

  const setSelectedRadioFilter = () => {
    if ($stateParams[this.filterKey]) {
      this.filter.option = $stateParams[this.filterKey]
    }
  }

  const watch = () => {
    $scope.$watch('$ctrl.filter.option', (newValue) => {
      this.onUpdateFilterValue({
        filterName: this.filterKey,
        value: newValue
      })
    })
  }

  this.$onInit = () => {
    this.filter = {
      isLoading: false,
      option: null,
      filterString: '',
      options: [],
      filtered: [],
      $open: false
    }

    this.filter.isLoading = true

    watch()

    iAuditorData
      .getDataFromSource(this.filterKey)
      .then((data) => {
        if (data && data.length) {
          this.filter.options = _sortBy(data, 'value')
          this.filter.filtered = this.filter.options

          setSelectedRadioFilter()
        }
      })
      .catch(() => {})
      .finally(() => {
        this.filter.isLoading = false
      })
  }

  this.applySearchFilter = () => {
    this.filter.filtered = $filter('filter')(this.filter.options, { value: this.filter.filterString })
  }

  this.clearRadioSelection = () => {
    this.filter.option = null
  }
}
