import * as React from 'react'
import * as materialIcons from '../services/icon-svgs'
import SVG, { SvgPath as Path } from 'reactxp-imagesvg'
import { Color, colors, IconSize, iconSizes } from '../styles'

export type IconName = keyof typeof materialIcons

interface Props {
  name: keyof typeof materialIcons
  color?: Color | string
  size?: number | IconSize
}

export const Icon = ({ name, color, size = iconSizes.base }: Props) => {
  const path = materialIcons[name]
  const iconColor = colors[color] || color
  const iconSize = typeof size === 'number' ? size : iconSizes[size]

  if (!path) {
    return (
      <SVG width={iconSize} height={iconSize} viewBox="0 0 24 24">
        <Path d={materialIcons.rhombus} fillColor={iconColor} />
      </SVG>
    )
  }

  return (
    <SVG width={iconSize} height={iconSize} viewBox="0 0 24 24">
      <Path d={path} fillColor={iconColor} />
    </SVG>
  )
}
