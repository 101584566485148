import _cloneDeep from 'lodash/cloneDeep'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'

export function Controller($scope, CurrenciesData, gettextCatalog, context, $timeout) {
  'ngInject'

  const $ctrl = this
  var moreMarker = 'MORE'

  $ctrl.checkSelection = checkSelection
  $ctrl.currenciesLoaded = false
  $ctrl.currencies = []

  $ctrl.$onInit = () => {
    $timeout(() => {
      loadCurrencies(true).finally(() => {
        watch()
      })
    })
  }

  function watch() {
    $scope.$watch(
      '$ctrl.model',
      function (newValue) {
        if (newValue) {
          $ctrl.ngModelCtrl.$setViewValue(_cloneDeep($ctrl.model))
          $ctrl.checkSelection()
        } else {
          $ctrl.model = $ctrl.ngModelCtrl.$viewValue
        }
      },
      true
    )
  }

  function loadCurrencies(popular?) {
    var request

    $ctrl.currenciesLoaded = false

    if (popular) {
      request = CurrenciesData.popular(context.get('account'), _get($ctrl.ngModelCtrl.$viewValue, 'currency'))
    } else {
      request = CurrenciesData.all(context.get('account'))
    }

    return request
      .then(function (currencies) {
        const copyCurrencies = _cloneDeep(currencies)

        if (popular) {
          copyCurrencies.push({ name: '-- ' + gettextCatalog.getString('get more') + ' --', iso_code: moreMarker })
        }

        $ctrl.currencies = copyCurrencies
      })
      .finally(() => {
        $ctrl.currenciesLoaded = true
      })
  }

  function checkSelection() {
    if ($ctrl.ngModelCtrl.$viewValue.currency === moreMarker) {
      $ctrl.ngModelCtrl.$viewValue.currency = null
      loadCurrencies()
    }
  }
}
