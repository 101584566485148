export function Controller(BASE_URL, uploadsData) {
  'ngInject'

  this.$onChanges = () => {}

  this.$onInit = () => {
    this.type = uploadsData.getUploadType(this.upload)
  }

  this.fileUrl = (url) => {
    return BASE_URL + url
  }
}
