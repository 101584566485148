import * as React from 'react'
import { View } from 'reactxp'
import { createStyledTextInput } from '../../components/styled'
import { spacing, colors } from '../../styles'
import { AccountUpload } from '../../upload'
import { UploadThumbnails } from '../../upload/components'
import { Task } from '../models'
import { TaskSectionTitle } from './task-section-title'

interface Props {
  task: Task
  onChangeNote: (note: string) => void
  onChangeFiles: (files: AccountUpload[]) => void
  isAdmin: boolean
  editable: boolean
}

const TaskNoteInput = createStyledTextInput({
  height: 90,
  marginBottom: spacing.base,
  padding: spacing.half,
  borderColor: colors.neutralTintSix,
  borderWidth: 1,
  borderRadius: 3
})

export const TaskNoteComponent = ({ task, onChangeNote, onChangeFiles, editable }: Props) => {
  const [note, setNote] = React.useState('')
  const [files, setFiles] = React.useState([])
  React.useEffect(() => {
    return () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.view)
      })
    }
  }, [])

  const changeFiles = (newFiles: AccountUpload[]) => {
    setFiles(newFiles)
    onChangeFiles(newFiles)
  }

  const deleteUpload = (id: string) => {
    const newFiles = files.filter((currentFile: AccountUpload) => {
      return currentFile.id !== id
    })

    setFiles(newFiles)
    onChangeFiles(newFiles)
  }

  return (
    <View>
      {editable && (
        <>
          <TaskSectionTitle>ADD NOTE:</TaskSectionTitle>
          <TaskNoteInput
            multiline={true}
            value={note}
            onChangeText={(newValue) => {
              setNote(newValue)
              onChangeNote(newValue)
            }}
          />
        </>
      )}
      <UploadThumbnails
        accountId={task.account.id}
        onChangeFiles={changeFiles}
        onDeleteUpload={deleteUpload}
        uploads={files}
        editable={editable}
      />
    </View>
  )
}
