import _assign from 'lodash/assign'
import _some from 'lodash/some'
import _sortBy from 'lodash/sortBy'
import _filter from 'lodash/filter'
import angular from 'angular'

export function iAuditorData(
  IntegrationsData,
  context,
  collections,
  pagination,
  $http,
  $q,
  session,
  BASE_URL,
  API_VERSION
) {
  'ngInject'

  let authorsCache: any = {}
  let templatesCache: any = {}
  let fieldsCache: any = {}
  let sourcesCache: any = {}

  let columns = [
    {
      key: 'name',
      label: 'Name',
      direction: 'ASC',
      order: 0
    },
    {
      key: 'template_id',
      label: 'Template',
      direction: 'ASC',
      order: 0
    },
    {
      key: 'author_name',
      label: 'Author',
      direction: 'ASC',
      order: 0
    },
    {
      key: 'date_completed',
      label: 'Date Completed',
      direction: 'DESC',
      order: 1
    },
    {
      key: 'score_percentage',
      label: 'Score',
      direction: 'ASC',
      order: 0
    },
    {
      key: 'used_at',
      label: 'Actioned',
      direction: 'ASC',
      order: 0
    }
  ]

  let service = {
    downloadAudit: downloadAudit,
    getAuditById: getAuditById,
    getAuthors: getAuthors,
    getColumns: getColumns,
    getCustomFieldValue: getCustomFieldValue,
    getCustomFilterWhitelist: getCustomFilterWhitelist,
    getDataFromSource: getDataFromSource,
    getFields: getFields,
    getTemplates: getTemplates,
    checkIntegration: checkIntegration,
    init: init,
    loadAll: loadAll,
    loadMore: loadMore,
    setColumns: setColumns
  }

  return service

  function init(additionalParams) {
    let params = {
      limit: 20
    }

    // if additionalParams were provided, assign them to params (will overwrite)
    if (typeof additionalParams === 'object') {
      params = _assign(params, additionalParams)
    }

    return context.get('account').iAuditor.$collection(params)
  }

  function loadAll(additionalParams) {
    let auditData = service.init(additionalParams)

    return collections.getAll(auditData)
  }

  function loadMore(audits, additionalParams) {
    let params = {
      page: pagination.nextPage(audits),
      sort: getSortString()
    }

    // if additionalParams were provided, assign them to params (will overwrite)
    if (typeof additionalParams === 'object') {
      params = _assign(params, additionalParams)
    }

    return audits.$fetch(params).$asPromise()
  }

  function getAuthors() {
    let authors
    let defer = $q.defer()

    // If we already have the integrations data, return it from cache.
    if (authorsDataExists()) {
      defer.resolve(authorsCache.data)

      return defer.promise
    }

    authors = context.get('account').iAuditorAuthors.$collection()

    return collections.getAll(authors).then((authorsData) => {
      storeAuthorsCache(_sortBy(authorsData, 'author_name'))
      return authorsData
    })
  }

  function getCustomFieldValue(customFields, fieldKey) {
    let value
    let customField

    if (customFields.length) {
      customField = customFields.filter((field) => {
        return field.key === fieldKey
      })

      if (customField.length && typeof customField[0].value === 'boolean') {
        value = customField[0].value ? 'Yes' : 'No'
      } else if (customField.length) {
        value = customField[0].value
      }
    }

    return value
  }

  function getCustomFilterWhitelist() {
    return ['team_sourced', 'destination', 'turbine_ref', 'tools']
  }

  function getDataFromSource(sourceName) {
    let sourceData
    let defer = $q.defer()

    // If we already have the integrations data, return it from cache.
    if (sourcesDataExists(sourceName)) {
      defer.resolve(sourcesCache[sourceName].data)

      return defer.promise
    }

    sourceData = context.get('account').iAuditorSources.$collection({ type: sourceName })

    return collections.getAll(sourceData).then((response) => {
      storeSourcesCache(sourceName, _sortBy(response, 'value'))
      return _sortBy(response, 'value')
    })
  }

  function getFields() {
    let fields
    let defer = $q.defer()

    // If we already have the fields data, return it from cache.
    if (FieldsDataExists()) {
      defer.resolve(fieldsCache.data)

      return defer.promise
    }

    fields = context.get('account').iAuditorFields.$collection()

    return collections.getAll(fields).then((FieldsData) => {
      storeFieldsCache(_sortBy(FieldsData, 'label'))

      return FieldsData
    })
  }

  function getTemplates() {
    let templates
    let defer = $q.defer()

    // If we already have the integrations data, return it from cache.
    if (templatesDataExists()) {
      defer.resolve(templatesCache.data)

      return defer.promise
    }

    templates = context.get('account').iAuditorTemplates.$collection()

    return collections.getAll(templates).then((templatesData) => {
      storeTemplatesCache(_sortBy(templatesData, 'template_name'))
      return templatesData
    })
  }

  function storeAuthorsCache(authorsData) {
    authorsCache.data = authorsData
    authorsCache.accountId = context.get('account').id
  }

  function storeFieldsCache(FieldsData) {
    fieldsCache.data = FieldsData
    fieldsCache.accountId = context.get('account').id
  }

  function storeTemplatesCache(templatesData) {
    templatesCache.data = templatesData
    templatesCache.accountId = context.get('account').id
  }

  function storeSourcesCache(sourceName, sourceData) {
    sourcesCache[sourceName] = {
      data: sourceData,
      accountId: context.get('account').id
    }
  }

  /**
   * Check if the authors data is cached and that the account the user is on matches that data
   */
  function authorsDataExists() {
    return !!authorsCache.data && context.get('account').id === authorsCache.accountId
  }

  /**
   * Check if the fields data is cached and that the account the user is on matches that data
   */
  function FieldsDataExists() {
    return !!fieldsCache.data && context.get('account').id === fieldsCache.accountId
  }

  /**
   * Check if the templates data is cached and that the account the user is on matches that data
   */
  function templatesDataExists() {
    return !!templatesCache.data && context.get('account').id === templatesCache.accountId
  }

  /**
   * Check if the templates data is cached and that the account the user is on matches that data
   */
  function sourcesDataExists(sourceName) {
    let data = sourcesCache[sourceName]

    return !!(data && data.data) && context.get('account').id === data.accountId
  }

  function getSortArray() {
    let currentSort = []
    let sortedColumns = _filter(columns, (column) => {
      return column.order > 0
    })

    sortedColumns.forEach((column) => {
      if (column.direction === 'ASC') {
        currentSort.push(column.key)
      } else if (column.direction === 'DESC') {
        currentSort.push('-' + column.key)
      }
    })

    return currentSort
  }

  function getSortString() {
    return getSortArray().join(',')
  }

  function getColumns() {
    return columns
  }

  function setColumns(updatedColumns) {
    columns = updatedColumns

    return columns
  }

  function getAuditById(id, params) {
    return context.get('account').iAuditor.$find(id, params).$asPromise()
  }

  function checkIntegration() {
    return IntegrationsData.init()
      .then((data) => {
        return _some(data, { provider: 'iauditor' })
      })
      .catch(() => {})
  }

  function downloadAudit(audit) {
    let defer = $q.defer()

    let req = {
      method: 'GET',
      url: BASE_URL + audit.download,
      headers: {
        Accept: API_VERSION
      }
    }

    $http(req)
      .then((response) => {
        let anchor = angular.element('<a/>')

        defer.resolve()

        anchor.css({ display: 'none' })
        angular.element(document.body).append(anchor)

        anchor
          .attr({
            href: 'data:attachment/pdf;charset=utf-8,' + encodeURI(response.data),
            target: '_blank',
            download: audit.export_name
          })[0]
          .click()

        anchor.remove()
      })
      .catch((response) => {
        defer.reject(response.data)
      })

    return defer.promise
  }
}
