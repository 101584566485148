import _clone from 'lodash/clone'
import _xor from 'lodash/xor'
import _map from 'lodash/map'

export function Controller(
  $scope,
  $timeout,
  $element,
  recordsData,
  TagsData,
  filteringContext,
  $uibModalStack,
  gettextCatalog,
  context
) {
  'ngInject'

  let vm = this

  vm.control = {
    saveTags: saveTags,
    toggleFocus: toggleFocus,
    tagClicked: tagClicked,
    getAvailableItems: getAvailableItems,
    popoverClose: popoverClose,
    popoverToggle: popoverToggle
  }

  vm.pickerOptions = {
    searchPlaceholder: gettextCatalog.getString('Find/Create Tags...'),
    contextLabel: gettextCatalog.getString('Tags')
  }

  vm.field = {
    required: false,
    label: gettextCatalog.getString('Tags')
  }
  vm.initialTags = []
  vm.focusInput = false
  vm.popover = {
    open: false
  }

  /////////////

  vm.$onInit = function () {
    // Set the initial tag values to compare against later on when saving.
    vm.initialTags = _clone(vm.record.tags)
  }

  function getAvailableItems() {
    return TagsData.findTags(context.get('account'))
  }

  function saveTags(tags) {
    if (_xor(mapText(tags), mapText(vm.initialTags)).length) {
      vm.initialTags = _clone(tags)

      recordsData.saveProperty(vm.record, 'tags', tags, { force: true }).catch(() => {})
    }
  }

  function mapText(tags) {
    return _map(tags, function (tag) {
      return tag.text
    })
  }

  function popoverClose() {
    vm.popover = {
      open: false
    }
  }

  function popoverToggle() {
    let newValue = !vm.popover.open

    vm.popover = {
      open: newValue
    }
  }

  function tagClicked(value) {
    // $uibModalStack.dismissAll('navigate')
    // $timeout(function() {
    filteringContext.applyFilter({ tag: value }, 'folders')
    // })
  }

  function toggleFocus($event) {
    if ($event.originalEvent && $event.originalEvent.type === 'click') {
      vm.focusInput = !vm.focusInput

      if (vm.focusInput) {
        $timeout(function () {
          $element.find('input').trigger('click')
        })
      }
    }
  }
}
