import * as Sentry from '@sentry/browser'
import _assign from 'lodash/assign'

import * as auth from 'AppCore/api/auth'
import { getConfig } from 'AppCore/api'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'AppCore/keys'

export function Authenticate(
  $http,
  BASE_URL,
  API_VERSION,
  session,
  profileData,
  authService,
  hubAuthService,
  tracking,
  stateMonitor,
  $q
) {
  'ngInject'

  let attemptingRefresh = null

  const service = {
    attemptLogin,
    attemptRefresh,
    attemptHubLogin,
    requestReset,
    requestResetRespond,
    logout,
    isAuthenticated,
    _loginSuccess
  }

  return service

  function _loginSuccess() {
    attemptingRefresh = false

    //session.token = accessToken

    return profileData.getUser().then(() => _confirmLogin())
  }

  function _confirmLogin() {
    authService.loginConfirmed(session.user, _requestUpdater)
    _trackUser()

    return { authenticated: true }
  }

  function _trackUser() {
    tracking.setUser(session.user.id)
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: session.user.id,
        username: `${session.user.firstName} ${session.user.lastName}`,
        email: session.user.email
      })
    })
  }

  function _refreshError() {
    attemptingRefresh = false

    stateMonitor.redirectState(stateMonitor.targetState())
    // Will trigger event:auth-loginCancelled
    authService.loginCancelled()
    session.destroy()

    return _refreshErrorRedirect()
  }

  function _refreshErrorRedirect() {
    const redirect = {
      name: 'login',
      params: null,
      options: {}
    }

    switch (stateMonitor.targetState().state.name) {
      // If the user is trying to do a new import, show the signup page first, instead of login
      case 'imports.new':
        redirect.name = 'signup'

        break

      // If no one is logged in coming from accepting an invite email, take them back to invitations
      case 'invitations':
        redirect.name = 'invitations'
        redirect.params = _assign({}, stateMonitor.targetState().params, { skipAuth: true })

        break

      case 'invitations-oauth-redirect':
        redirect.name = 'invitations-oauth-redirect'
        redirect.params = _assign({}, stateMonitor.targetState().params, { skipAuth: true })
        break

      default:
      //
    }

    return redirect
  }

  function _requestUpdater(config) {
    return config
  }

  function attemptLogin(credentials) {
    const defer = $q.defer()

    auth
      .login(credentials.username, credentials.password, credentials.rememberme)
      .then(_loginSuccess)
      .then((response) => defer.resolve(response))
      .catch((error) => {
        defer.reject(error.response.data)
      })

    return defer.promise
  }

  /**
   *
   */
  function attemptHubLogin() {
    if (attemptingRefresh) {
      return attemptingRefresh
    }

    attemptingRefresh = hubAuthService
      .request()
      .then(async (data) => {
        const { storage } = getConfig()
        const { refresh_token: refreshToken, access_token: accessToken } = data
        return _loginSuccess()
      })
      .catch(() => {
        attemptingRefresh = false
      })

    return attemptingRefresh
  }

  function attemptRefresh() {
    if (attemptingRefresh) {
      return attemptingRefresh
    }

    attemptingRefresh = auth.requestToken().then(_loginSuccess).catch(_refreshError)

    return attemptingRefresh
  }

  function requestReset(credentials) {
    const req = {
      method: 'POST',
      url: BASE_URL + '/reminders',
      headers: {
        Accept: API_VERSION
      },
      data: {
        email: credentials.email
      },
      ignoreAuthModule: true
    }

    return $http(req)
  }

  function requestResetRespond(credentials) {
    const req = {
      method: 'POST',
      url: BASE_URL + '/reminders/actions/reset',
      headers: {
        Accept: API_VERSION
      },
      data: {
        email: credentials.email,
        code: credentials.code,
        password: credentials.password,
        password_confirmation: credentials.password_confirmation
      },
      ignoreAuthModule: true
    }

    return $http(req)
  }

  function logout() {
    const req = {
      method: 'POST',
      url: BASE_URL + '/auth/logout'
    }

    return $http(req).then(() => {
      session.destroy()
      return true
    })
  }

  function isAuthenticated() {
    return !!session.user
  }
}
