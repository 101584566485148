export function Controller($scope, $timeout, recordsPagination, foldersNavigation) {
  'ngInject'

  let vm = this

  vm.control = {
    setPerPage: setPerPage,
    setPage: setPage,
    pagePrevious: pagePrevious,
    pageNext: pageNext
  }

  vm.recordsPerPage = recordsPagination.getPerPage()
  vm.from = null
  vm.to = null
  vm.of = null
  vm.numbers = []

  //////////
  vm.$onInit = function () {
    watch()

  }

  function watch() {
    $scope.$watch('vm.meta',  setup() )
  }

  function setup() {

    vm.page = vm.meta.current_page
    vm.pages = vm.meta.total_pages
    vm.from = (vm.page - 1) * vm.meta.per_page + 1
    vm.to = vm.page * vm.meta.per_page
    vm.of = vm.meta.total

    if (vm.to > vm.of) {
      vm.to = vm.of
    }

    vm.noPrevious = vm.page === 1
    vm.noNext = vm.page === vm.pages

    vm.numbers = calculateNumbers()
  }

  function calculateNumbers() {
    let numbers = [],
      i

    if (vm.pages <= 5) {
      for (i = 1; i <= vm.pages; i++) {
        numbers.push({ label: i, page: i })
      }

      return numbers
    }

    if (vm.page <= 3) {
      for (i = 1; i <= 5; i++) {
        numbers.push({ label: i, page: i })
      }
      numbers.push({ label: '&hellip;', page: null })

      return numbers
    }

    if (vm.page >= vm.pages - 2) {
      numbers.push({ label: '&hellip;', page: null })
      for (i = vm.pages - 4; i <= vm.pages; i++) {
        numbers.push({ label: i, page: i })
      }

      return numbers
    }

    numbers.push({ label: '&hellip;', page: null })
    for (i = vm.page - 2; i <= vm.page + 2; i++) {
      numbers.push({ label: i, page: i })
    }
    numbers.push({ label: '&hellip;', page: null })

    return numbers
  }

  function setPerPage() {
    recordsPagination.setPerPage(vm.recordsPerPage)
    go()
  }

  function setPage(page) {
    if (page >= 1 && page <= vm.pages && page !== vm.page) {
      recordsPagination.setPage(page)
      vm.meta.current_page = page
   
        go(true)
    } 
  }

  function pagePrevious() {
    if (vm.meta.current_page > 1) {
      recordsPagination.setPage(vm.meta.current_page - 1)
      vm.meta.current_page -= 1
        go(true)
    } 
  }

  function pageNext() {
    if (vm.meta.current_page < vm.meta.total_pages) {
      recordsPagination.setPage(vm.meta.current_page + 1)
      vm.meta.current_page += 1
        go(true)
    } 
  }

  function go(paginate = false) {
    foldersNavigation.go(vm.folderId, { paginate: paginate })
  }
}