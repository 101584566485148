export function Controller(ActivitiesRecord, recordsData, BASE_URL, fileHandler, $uibModal, context, $timeout, $location) {
  'ngInject'

  const $ctrl = this

  let shareCertModal

  this.$onInit = () => {
    this.changes = ActivitiesRecord.changes(this.data)
    this.downloadLink = `<a ng-click="$ctrl.downloadFile($ctrl.data.to.download)" translate>
      click here to download the PDF file</a>`
    $ctrl.showShare = true
    this.canPreview = this.formatMegabytes(this.data.to.size) < 30
    this.record = null;
    this.account = context.get('account')

    recordsData.getRecord(this.data.record.id).then(function (record) {
      $ctrl.record = record;
    });
  }

  this.canDownload = () => {
    if(this.record && this.account) {
      return this.record.partner ? this.data.account.id === this.account.id : true;
    } else {
      return true;
    }
  }

  this.downloadFile = () => {
    fileHandler.download(this.data.to.download)
  }

  this.openRecord = () => {
    this.onOpenRecord()
  }

  this.share = () => {
    dispatchEvent(new CustomEvent('shareDocument', { detail: { docUrl: BASE_URL + $ctrl.data.to.view, mode: 'cert' } }))
  }

  this.formatMegabytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0.00'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals

    const megabytes = bytes / Math.pow(k, 2)

    return megabytes.toFixed(dm)
  }

  this.previewUrl = () => {
    let url = BASE_URL + this.data.to.view;

    return url;
  }
}
