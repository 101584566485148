// jquery required for angular
//import 'jquery'

import '../styles/main.scss'

import angular from 'angular'
import apiCheck from 'api-check'
import moment from 'moment-timezone'
import configureAPI from 'AppCore/api'

import singleSpaCss from 'single-spa-css'
import singleSpaAngularJS from 'single-spa-angularjs'

import { setPublicPath } from 'systemjs-webpack-interop'

setPublicPath('@papertrail/web3-legacy')

configureAPI({
  baseUrl: appConfig.BASE_URL,
  storage: {
    hasItem: (key: string) => {
      return new Promise((res) => res(!!window.localStorage.getItem(key)))
    },
    setItem: (key: string, value: string) => new Promise((res) => res(window.localStorage.setItem(key, value))),
    getItem: (key: string) => {
      return new Promise((res) => res(window.localStorage.getItem(key)))
    },
    removeItem: (key: string) => new Promise((res) => res(window.localStorage.removeItem(key)))
  }
})

apiCheck.globalConfig.disabled = appConfig.IS_PRODUCTION

/**
 * Sentry is configured in the root application so we don't need this.
 * TODO Use Sentry hub to have a project per MFE
Sentry.init({
  dsn: appConfig.sentryDSN,
  release: appConfig.appVersion,
  environment: appConfig.ENV,
  whitelistUrls: [/papertrail\.io/, /.*\.papertrail\.io/],
})*/

// bootstrap: required for angular-ui-bootstrap
import 'bootstrap'
// bootstrap-sass: used in app's styles (app/styles/bootstrap.scss)
//import 'bootstrap-sass'
// jquery-textcomplete: used in pt-textcomplete directive
import 'jquery-textcomplete'
// jquery-ui: not sure where this is used TODO: check
import 'jquery-ui'
// used as 'rzSlider' (e.g. in pt-subscription-plan-change component)
//import 'angularjs-slider'
// angular-animate: used in various modules throughout app
import 'angular-animate'

// Following 'imports' defined in 'app' module dependencies, below.
import 'angulartics'
import 'angulartics-google-analytics'
import 'angular-moment'
import 'angular-spinner'
import 'checklist-model'
import 'angular-formly'
import 'angular-formly-templates-bootstrap'
import 'angular-gettext'
import 'angular-ivh-treeview'
import 'angular-local-storage'
import 'angular-elastic'
import 'oclazyload'
import 'ng-file-upload'
import 'ngprogress'
import 'angular-restmod'
import 'angular-ui-bootstrap'
import 'angular-ui-indeterminate'
import '@uirouter/angularjs'
import 'angular-xeditable'
import 'angular-ui-sortable'
import 'ui-select'
import 'angular-aria'
import 'angular-messages'
import 'angular-sanitize'
import 'angular-touch'
import 'angular-http-auth'
import 'angular-localforage'
import 'angular-cookies'
import 'angular-signature'

import { App } from './app'

import { AccessesModule } from './accesses'
import { AccountsModule } from './accounts'
import { ActivitiesModule } from './activities'
import { AddressesModule } from './addresses'
import { AuthenticationModule } from './authentication'
import { AuthorisationModule } from './authorisation'
import { BlueprintsModule } from './blueprints'
import { BillingModule } from './billing'
import { ChecklistsModule } from './checklists'
import { CommonModule } from './common'
import { CountriesModule } from './countries'
import { CurrenciesModule } from './currencies'
import { DefinitionsModule } from './definitions'
import { ErrorCatcherModule } from './error-catcher'
import { ErrorsModule } from './errors'
import { EventsModule } from './events'
import { ExportsModule } from './exports'
import { FieldsModule } from './fields'
import { FiltersModule } from './filters'
import { FlagsModule } from './flags'
import { FoldersModule } from './folders'
import { FormatsModule } from './formats'
import { FrequenciesModule } from './frequencies'
import { HistoriesModule } from './histories'
import { iAuditorModule } from './iauditor'
import { ImportsModule } from './imports'
import { IndustriesModule } from './industries'
import { IntegrationsModule } from './integrations'
import { InvitationsModule } from './invitations'
import { InvoicesModule } from './invoices'
import { LabelsModule } from './labels'
import { LogsModule } from './logs'
import { LookupsModule } from './lookups'
import { MembersModule } from './members'
import { NotesModule } from './notes'
import { NotificationsModule } from './notifications'
import { OwnersModule } from './owners'
import { PackagesModule } from './packages'
import { PermissionsModule } from './permissions'
import { ProcessesModule } from './processes'
import { ProductsModule } from './products'
import { ProfileModule } from './profile'
import { RecordsModule, RecordsProvidersModule } from './records'
import { ReportsModule } from './reports'
import { ResellersModule } from './resellers'
import { ScansModule } from './scans'
import { SearchModule } from './search'
import { StatesModule } from './states'
import { StatsModule } from './stats'
import { SubscriptionsModule } from './subscriptions'
import { SummariesModule } from './summaries'
import { TagsModule } from './tags'
import { TasksModule } from './tasks'
import { TimezonesModule } from './timezones'
import { TrackingModule } from './tracking'
import { TutorialsModule } from './tutorials'
import { UploadsModule } from './uploads'
import { UsersModule } from './users'
import { WidgetsModule } from './widgets'
import { ptWeb3Routes } from 'AppModules/web3/routes'
import { SSOModule } from './sso'


const websiteUrl = 'http://www.papertrail.io/'

//Load extracted CSS in production mode only - when running locally we use 'style-loader'
const cssLifecycles = singleSpaCss({
  cssUrls: ['https://cdn.papertrail.io/fonts/fontello/css/papertrail-embedded.css'],
  webpackExtractedCss: true,
  shouldUnmount: true,
  timeout: 60000
})

const ngLifecycles = singleSpaAngularJS({
  angular: angular,
  mainAngularModule: 'app',
  uiRouter: true,
  preserveGlobal: false,
  template:
    '<div ng-controller="App as app" ui-view class="full-height" ng-class="{\'left-menu-open\': leftMenuOpen}"></div>'
})

export const bootstrap = [
  //@ts-ignore
  cssLifecycles.bootstrap,
  ngLifecycles.bootstrap
]

export const mount = [
  // The CSS lifecycles should be before your framework's mount lifecycle,
  // to avoid a Flash of Unstyled Content (FOUC)
  //@ts-ignore
  cssLifecycles.mount,
  ngLifecycles.mount
]

export const unmount = [
  // The CSS lifecycles should be after your framework's unmount lifecycle,
  // to avoid a Flash of Unstyled Content (FOUC)
  ngLifecycles.unmount,
  //@ts-ignore
  cssLifecycles.unmount
]

angular
  .module('app', [
    'angulartics',
    'angulartics.google.analytics',
    'angularMoment',
    'angularSpinner',
    'checklist-model',
    'formly',
    'formlyBootstrap',
    'gettext',
    'ivh.treeview',
    'LocalStorageModule',
    'oc.lazyLoad',
    'monospaced.elastic',
    'ngFileUpload',
    'ngProgress',
    'restmod',
    'ui.bootstrap',
    'ui.indeterminate',
    'ui.router',
    'LocalForageModule',
    'xeditable',
    'ui.sortable',
    'ui.select',
    'ngAria',
    'ngMessages',
    'ngSanitize',
    'ngTouch',
    'ngCookies',

    RecordsProvidersModule,
    AccessesModule,
    AccountsModule,
    ActivitiesModule,
    AddressesModule,
    AuthenticationModule,
    AuthorisationModule,
    BlueprintsModule,
    BillingModule,
    ChecklistsModule,
    CommonModule,
    CountriesModule,
    CurrenciesModule,
    DefinitionsModule,
    ErrorCatcherModule,
    ErrorsModule,
    EventsModule,
    ExportsModule,
    FieldsModule,
    FiltersModule,
    FlagsModule,
    FoldersModule,
    FormatsModule,
    FrequenciesModule,
    HistoriesModule,
    iAuditorModule,
    ImportsModule,
    IndustriesModule,
    IntegrationsModule,
    InvitationsModule,
    InvoicesModule,
    LabelsModule,
    LogsModule,
    LookupsModule,
    MembersModule,
    NotesModule,
    NotificationsModule,
    OwnersModule,
    PackagesModule,
    PermissionsModule,
    ProcessesModule,
    ProductsModule,
    ProfileModule,
    RecordsModule,
    ReportsModule,
    ResellersModule,
    ScansModule,
    SearchModule,
    SSOModule,
    StatesModule,
    StatsModule,
    SubscriptionsModule,
    SummariesModule,
    TagsModule,
    TasksModule,
    TimezonesModule,
    TrackingModule,
    TutorialsModule,
    UploadsModule,
    UsersModule,
    WidgetsModule,

    'http-auth-interceptor'
  ])
  .config(ptWeb3Routes)
  .controller('App', App)
  .constant('moment', moment)
  .constant('BASE_URL', '/api')
  .constant('API_VERSION', 'application/vnd.papertrail.api+json; version=1.0')
  .constant('CONNECTION_TEST_URL', '/connection-test')
  .constant('HELPCENTRE_URL', 'http://help.papertrail.io/')
  .constant('WEBSITE_URL', websiteUrl)
  .constant('TERMS_URL', websiteUrl + 'legal')
  .constant('PRIVACY_URL', websiteUrl + 'privacy')
  .constant('angularMomentConfig', {
    timezone: 'Europe/London'
  })
