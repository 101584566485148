import angular from 'angular'
import { TutorialsConstants } from './constants'
import { TutorialsFlags } from './flags'
import { TutorialsModel } from './models'
import { ptTutorialsInfoViewModule } from './components/pt-tutorials-info-view'
import { ptTutorialsMenuModule } from './components/pt-tutorials-menu'
import { ptTutorialsMenuItemModule } from './components/pt-tutorials-menu-item'
import { ptTutorialsNewAccountInviteModule } from './components/pt-tutorials-new-account-invite'
import { ptTutorialsNewFolderModule } from './components/pt-tutorials-new-folder'
import { ptTutorialsNewInspectionModule } from './components/pt-tutorials-new-inspection'
import { ptTutorialsNewRecordModule } from './components/pt-tutorials-new-record'
import { ptTutorialsVideoModule } from './components/pt-tutorials-video'

export const TutorialsModule = angular
  .module('tutorials', [
    ptTutorialsInfoViewModule,
    ptTutorialsMenuModule,
    ptTutorialsMenuItemModule,
    ptTutorialsNewAccountInviteModule,
    ptTutorialsNewFolderModule,
    ptTutorialsNewInspectionModule,
    ptTutorialsNewRecordModule,
    ptTutorialsVideoModule
  ])
  .constant('TUTORIALS', TutorialsConstants)
  .factory('TutorialsFlags', TutorialsFlags)
  .factory('TutorialsModel', TutorialsModel).name
