export function connection($injector, EventsData, connectionBuffer, CONNECTION_TEST_URL) {
  'ngInject'

  let $http

  let service = {
    failed: failed,
    succeeded: succeeded,
    test: test
  }

  return service

  ////////////////

  function failed(rejection, deferred) {
    EventsData.broadcast('event:connection-failed', rejection)

    return connectionBuffer.append(rejection.config, deferred)
  }

  function succeeded() {
    EventsData.broadcast('event:connection-succeeded')

    connectionBuffer.retryAll()
  }

  function test() {
    $http = $http || $injector.get('$http')
    return $http({ url: CONNECTION_TEST_URL }).then(succeeded)
  }
}
