export function UploadModel(restmod) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    // .mix({
    //   $expires: Math.floor(Date.now() / 1000) + (300 * 0.9), // time + ( 90% of validity time )
    // });
    .mix({
      $hooks: {
        'after-feed': function () {
          this.$expires = Math.floor(Date.now() / 1000) + this.validity * 0.9 // time + ( 90% of validity time )
        }
      }
    })

  return model
}
