import { cloneDeep as _cloneDeep, clone as _clone, debounce as _debounce } from 'lodash'

export function Controller(lookupsData) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    lookup: null,
    typeaheadSelect,
    saveField,
    click
  }

  $ctrl.$onInit = () => {
    $ctrl.control.lookup = _debounce(lookup, 500)
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.field) {
      $ctrl.field = _cloneDeep($ctrl.field)
    }

    if (changes.editable) {
      $ctrl.editable = _clone($ctrl.editable)
    }
  }

  function saveField(value) {
    return $ctrl.onSave({ value: value })
  }

  function typeaheadSelect(typeahead) {
    typeahead.$form.$submit()
  }

  function lookup(source, value) {
    return lookupsData.fetch(source, value).then(function (results) {
      return results.map(function (result) {
        return result.value
      })
    })
  }

  function click(event, form) {
    if (event.target.nodeName !== 'INPUT') {
      form.$show()
    }
  }
}
