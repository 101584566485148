import * as React from 'react'

import { ActivityTitle } from './activity-title'
import { ActivityContent } from './activity-content'
import { Activity } from '../models/index'
import { PersonDisplay } from '../../user/components'
import { User } from '../../user'
import { createStyledComponent, View } from '../../components/styled'

interface Props {
  activity: Activity
  viewType: string
  onPressActivity: (activity: Activity) => void
}

const Wrapper = createStyledComponent(View, {
  overflow: 'visible'
})

export const ActivityTaskAssigneeWasRemoved = ({ activity, viewType, onPressActivity }: Props) => {
  switch (viewType) {
    case 'simple':
      return (
        <Wrapper>
          <ActivityTitle activity={activity} onPressActivity={onPressActivity}>
            Assignee was removed
          </ActivityTitle>
          <ActivityContent>
            <PersonDisplay user={activity.from as User} fontSize={'small'} imageSize={30} />
          </ActivityContent>
        </Wrapper>
      )
  }
}
