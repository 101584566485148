import _filter from 'lodash/filter'

export function AccountNewRoute($stateProvider) {
  'ngInject'

  let newAccountModal

  $stateProvider.state('accounts.new-account', newAccount())
  $stateProvider.state('subscription.overview.new-account', newAccount())
  $stateProvider.state('subscription.accounts.new-account', newAccount())
  $stateProvider.state('imports.import.accounts.new-account', newAccount())

  function newAccount() {
    return {
      url: '/new-account?subscriptionId',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      onEnter: onNewAccountEnter,
      onExit: onNewAccountExit
    }
  }

  function onNewAccountEnter($uibModal, $timeout, $state, page) {
    'ngInject'

    page.setTitle('New Account')

    newAccountModal = $uibModal.open({
      component: 'ptAccountNew',
      resolve: {
        templates: resolveTemplates
      }
    })

    newAccountModal.result.catch(function () {
      //timeout is required otherwise MFE is unmounted before modal elements removed from dom
      //which causes issues with multiple modals if you click the button again
      $timeout(() => {
        newAccountModal.close()
        $timeout(() => {
          $state.go('^')
        }, 500)
      }, 50)
    })
  }

  function onNewAccountExit(page) {
    'ngInject'
    page.resetTitle()
    newAccountModal.close()
  }

  function resolveTemplates(TemplatesData) {
    'ngInject'

    return TemplatesData.get().then(function (templates) {
      const demoTemplates = _filter(templates, { demo: true })
      const standardTemplates = _filter(templates, { template: true })

      return {
        demoTemplates,
        standardTemplates
      }
    })
  }
}
