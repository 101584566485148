export function NotesModel(restmod) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      user: { hasOne: 'UserModel', map: 'user.data' },
      uploads: { hasMany: 'UploadModel', map: 'uploads.data' }
    })

  return model
}
