export function ptCheckboxIndeterminate() {
  'ngInject'

  let directive = {
    link: link
  }

  return directive

  function link($scope, element, attrs) {
    $scope.$watch(attrs.ptCheckboxIndeterminate, function (newVal) {
      element[0].indeterminate = !!newVal
    })
  }
}
