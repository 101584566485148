import { Controller } from './controller'
import view from './view.html'

export const ptIauditorSortPanel = {
  bindings: {
    onRefreshAudits: '&',
    onTogglePanel: '&'
  },
  controller: Controller,
  template: view
}
