import { updateMe } from 'AppCore/user/api'

export function usersData($q, $http, session, BASE_URL, API_VERSION, UserModel) {
  'ngInject'

  let service = {
    create: create,
    resendValidation: resendValidation,
    validate: validate,
    changePassword: changePassword,
    update: update,
    changeEmail: changeEmail
  }
  return service

  ////////////////

  function create(user) {
    return UserModel.$build(user).$save().$asPromise()
  }

  function resendValidation() {
    let req = {
      method: 'POST',
      url: BASE_URL + '/me/actions/send-confirmation',
      headers: {
        Accept: API_VERSION
      },
      data: {}
    }

    return $http(req)
  }

  function validate(code) {
    let req = {
      method: 'POST',
      url: BASE_URL + '/me/actions/confirm',
      headers: {
        Accept: API_VERSION
      },
      data: {
        confirmation_code: code
      }
    }

    return $http(req)
      .then(function (response) {
        return response.data
      })
      .catch(function (rejection) {
        return rejection.data
      })
  }

  function changePassword(password) {
    let req = {
      method: 'POST',
      url: BASE_URL + '/me/actions/reset-password',
      headers: {
        Accept: API_VERSION
      },
      data: password
    }

    return $http(req).then(function (response) {
      return response.data
    })
  }

  function update(user, fields) {
    const defer = $q.defer()

    updateMe({ payload: fields })
      .then((response) => defer.resolve(response))
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function changeEmail(user) {
    let req = {
      method: 'POST',
      url: BASE_URL + '/me/actions/reset-email',
      headers: {
        Accept: API_VERSION
      },
      data: {
        email: user.email,
        password: user.password
      }
    }

    return $http(req)
  }
}
