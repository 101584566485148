import { Controller } from './controller'
import view from './view.html'

export function inputArray(formlyConfig) {
  'ngInject'

  formlyConfig.setType({
    name: 'inputArray',
    extends: 'input',
    template: view,
    controller: Controller
  })
}
