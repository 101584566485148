import _cloneDeep from 'lodash/cloneDeep'

export function Controller($state, TasksResolver) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    openActivity
  }

  $ctrl.isProfileTasks = $state.current.name.indexOf('tasks') === 0

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.data) {
      $ctrl.data = _cloneDeep($ctrl.data)
    }
  }

  this.deletedUploads = () => {
    return this.data.from.uploads.filter(this.isDeletedUpload)
  }

  this.isDeletedUpload = (upload) => {
    return !this.data.to.uploads || !this.data.to.uploads.find((u) => u.id === upload.id)
  }

  function openActivity() {
    $state.go($state.current.name + '.activity', { activityId: $ctrl.data.id })
  }

  this.goToTask = () => {
    TasksResolver.resolve({ taskId: this.data.task.id })
  }
}
