import angular from 'angular'
import { react2angular } from 'react2angular'

import { DiscardCriteriaComponent } from 'AppCore/discard-criteria/components'

export const ptLogsDiscardCriteriaModule = angular
  .module('logs.ptLogsDiscardCriteria', [])
  .component(
    'ptLogsDiscardCriteria',
    react2angular(DiscardCriteriaComponent, ['criteria', 'onChange', 'onSubmit', 'open'])
  ).name
