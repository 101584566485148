import { Controller } from './controller'
import view from './view.html'

export const ptChecklistItemBoolean = {
  bindings: {
    record: '<',
    onValueChange: '&',
    field: '<',
    readOnly: '<'
  },
  controller: Controller,
  template: view
}
