import * as React from 'react'
import { Component } from 'react'
import { Button as ReactButton, ActivityIndicator } from 'reactxp'
import { Color, colors, fontSizes, iconSizes, spacing } from '../styles'
import { Icon, IconName } from '.'
import { createStyledText, createStyledView } from './styled'
import { darkenColor, lightenColor } from '../services/colors'
import { getPlatformStyle } from '../services/platform'
import { SyntheticEvent } from 'reactxp/src/common/Types'

const DEFAULT_THEME: ButtonTheme = {
  backgroundColor: 'positive',
  labelColor: 'white',
  iconColor: 'white',
  borderColor: undefined,
  paddingHorizontal: 10,
  paddingVertical: 5,
  border: true,
  round: spacing.quarter,
  size: 'quarter'
}

const Container = createStyledView({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
})

const Label = createStyledText({
  ...getPlatformStyle({
    web: { fontWeight: '400', fontSize: fontSizes.small },
    mobile: { fontWeight: '500' }
  })
})

const Spacer = createStyledView({
  width: spacing.quarter
})

const createTheme = (partialTheme: PartialButtonTheme): ButtonTheme => {
  return { ...DEFAULT_THEME, ...partialTheme }
}

const getLabelSize = (themeSize: string) => {
  if (themeSize === 'quarter') {
    return {
      ...getPlatformStyle({
        web: { fontSize: fontSizes.small, lineHeight: fontSizes.small * 1.2 },
        mobile: { fontSize: fontSizes.base }
      })
    }
  }

  return {
    fontSize: fontSizes.small
  }
}

interface ButtonTheme {
  backgroundColor: Color
  labelColor: Color
  iconColor: Color
  borderColor: Color
  paddingHorizontal: number
  paddingVertical: number
  border: boolean
  round: number
  size: 'quarter' | 'base'
}

type PartialButtonTheme = Partial<ButtonTheme>
export { PartialButtonTheme as ButtonTheme }

interface Props {
  label?: string
  icon?: IconName
  onPress?: (event?: SyntheticEvent) => any
  theme?: PartialButtonTheme
  locked?: boolean
}

interface State {
  isLoading: boolean
  hovering: boolean
}

export class Button extends Component<Props, State> {
  public state: State = { isLoading: false, hovering: false }

  public handleHoverStart = () => {
    this.setState({
      hovering: true
    })
  }

  public handleHoverEnd = () => {
    this.setState({
      hovering: false
    })
  }

  public render() {
    const { label, icon, onPress = () => undefined, theme: inputTheme = DEFAULT_THEME, locked = false } = this.props

    const { isLoading } = this.state
    const theme = createTheme(inputTheme)
    const iconSize = theme.size === 'quarter' ? iconSizes.base : iconSizes.small
    const labelStyle = {
      color: colors[theme.labelColor]
    }

    const pressHandler = () => {
      const result = onPress()
      if (result instanceof Promise) {
        this.setState({ isLoading: true })
        result.then(() => this.setState({ isLoading: false })).catch(() => this.setState({ isLoading: false }))
      }
    }

    const buttonStyles = {
      padding: 0,
      opacity: locked ? 0.5 : 1,
      ...getPlatformStyle({
        web: { outline: 0, cursor: locked ? 'not-allowed' : 'pointer' }
      })
    }

    return (
      <ReactButton
        onPress={!locked ? pressHandler : undefined}
        onHoverStart={!locked ? this.handleHoverStart : undefined}
        onHoverEnd={!locked ? this.handleHoverEnd : undefined}
        style={buttonStyles}
      >
        <Container
          style={{
            backgroundColor: this.state.hovering
              ? darkenColor(theme.backgroundColor, 0.1)
              : colors[theme.backgroundColor],
            borderRadius: theme.round,
            borderWidth: theme.border ? 1 : 0,
            borderColor: theme.borderColor ? colors[theme.borderColor] : darkenColor(theme.backgroundColor, 0.2),
            paddingHorizontal: theme.paddingHorizontal,
            paddingVertical: theme.paddingVertical
          }}
        >
          {isLoading && (
            <ActivityIndicator color={colors[theme.labelColor]} size={theme.size === 'quarter' ? 'tiny' : 'small'} />
          )}
          {!isLoading && (
            <>
              {icon && <Icon name={icon} color={colors[theme.iconColor]} size={iconSize} />}
              {icon && label && <Spacer />}
              {label && <Label style={{ ...labelStyle, ...getLabelSize(theme.size) }}>{label}</Label>}
            </>
          )}
        </Container>
      </ReactButton>
    )
  }
}
