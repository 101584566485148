import angular from 'angular'

import { ptUploadsAddFileModule } from './components/pt-uploads-add-file'
import { ptUploadsDropZoneModule } from './components/pt-uploads-drop-zone'
import { ptUploadsFileViewModule } from './components/pt-uploads-file-view'
import { ptUploadsTypeDeviceModule } from './components/pt-uploads-type-device'
import { ptUploadsTypeIauditorModule } from './components/pt-uploads-type-iauditor'
import { ptUploadsUploaderModule } from './components/pt-uploads-uploader'
import { ptUploadsDisclaimerMessageModule } from './components/pt-uploads-disclaimer-message'

import { ptUploadAccountLogoModule } from './directives/pt-upload-account-logo'
import { ptUploadAvatarModule } from './directives/pt-upload-avatar'
import { ptUploadSignatureModule } from './directives/pt-upload-signature'
import { ptUploadLiModule } from './directives/pt-upload-li'
import { ptUploadMimeIconModule } from './directives/pt-upload-mime-icon'
import { ptUploadSingleModule } from './directives/pt-upload-single'
import { ptUploadPreviewModule } from './directives/pt-upload-preview'

import { uploadsData } from './services/data'
import { uploadFileActions } from './services/file-actions'
import { UploadModel } from './services/models'
import { viewFileModal } from './services/view-file-modal'

export const UploadsModule = angular
  .module('uploads', [
    // components
    ptUploadsAddFileModule,
    ptUploadsDropZoneModule,
    ptUploadsFileViewModule,
    ptUploadsTypeDeviceModule,
    ptUploadsTypeIauditorModule,
    ptUploadsUploaderModule,
    ptUploadsDisclaimerMessageModule,

    // directives
    ptUploadAccountLogoModule,
    ptUploadAvatarModule,
    ptUploadSignatureModule,
    ptUploadLiModule,
    ptUploadMimeIconModule,
    ptUploadSingleModule,
    ptUploadPreviewModule
  ])
  .factory('uploadsData', uploadsData)
  .factory('uploadFileActions', uploadFileActions)
  .factory('UploadModel', UploadModel)
  .factory('viewFileModal', viewFileModal).name
