import * as React from 'react'
import { Text } from 'reactxp'
import { TextStyle } from 'reactxp/src/common/Types'

interface PluralTextProps {
  children: React.ReactNode
  count: number
  plural: string
  style?: TextStyle
}

export const PluralText = ({ children, count, plural, style }: PluralTextProps) => {
  return count > 1 ? <Text style={style}>{plural}</Text> : <Text style={style}>{children}</Text>
}
