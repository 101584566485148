export function ptProfileEmailsRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('profile.emails', {
    url: '/emails',
    views: {
      'content@layoutFull': {
        component: 'ptProfileEmails'
      }
    },
    onEnter: onProfileEmailsEnter
  })

  // Old route, redirected to new .emails route
  $stateProvider.state('profile.notifications', {
    url: '/notifications',
    onEnter: function ($state) {
      'ngInject'

      $state.go('profile.emails')
    }
  })

  function onProfileEmailsEnter(page, gettextCatalog) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Your email settings'))
  }
}
