export function Controller($scope, context, recordsSelection) {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {}

  $ctrl.$onInit = function () {
    $ctrl.selectionCount = 0
    $ctrl.addRecordButton = $ctrl.folder.id !== context.get('account').folder.id

    $scope.$watch(
      function () {
        return recordsSelection.count()
      },
      function (newValue) {
        $ctrl.selectionCount = newValue
      }
    )
  }

  $ctrl.$onChanges = function () {
  }
}
