import _includes from 'lodash/includes'
import _last from 'lodash/last'
import _uniqBy from 'lodash/uniqBy'
import _cloneDeep from 'lodash/cloneDeep'

export function Controller($scope, ActivitiesDeactivated, ActivitiesData, pagination) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    addActivities
  }

  $ctrl.activityOptions = {
    view: 'simple'
  }

  $ctrl.activities = []
  $ctrl.organisedActivities = []
  $ctrl.canFetchMore = {}
  $ctrl.fetching = false

  $ctrl.$onInit = function () {
    $scope.$on('activity_was_created', activityWasCreated)
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.task) {
      $ctrl.task = _cloneDeep($ctrl.task)
    }

    if (changes.type) {
      $ctrl.type = _cloneDeep($ctrl.type)

      show(getTypes($ctrl.type))
    }
  }

  function activityWasCreated(event, data) {
    // Check this activity is referring to the current task
    if (data.data.task && data.data.task.id === $ctrl.task.id) {
      // Dont add task_was_created as this will already be in the list when the record first opens.
      if (!_includes(['task_was_created'], data.data.type)) {
        $ctrl.activities.unshift(data.data)
        organise(ActivitiesDeactivated.flag($ctrl.activities))
      }
    }
  }

  function addActivities() {
    $ctrl.fetching = true

    ActivitiesData.getNext($ctrl.activities).then(addActivitiesSuccess)
  }

  function addActivitiesSuccess(results) {
    ActivitiesData.debugActivities($ctrl.activities)

    $ctrl.canFetchMore = pagination.hasMorePages(results)
    $ctrl.fetching = false

    organise(ActivitiesDeactivated.flag($ctrl.activities))
  }

  function getTypes(type) {
    if (type === 'notes') {
      return ActivitiesData.getActivityGroup('task_note')
    }

    return null
  }

  function initActivitiesData(types) {
    let includeEventGroups = ['task', 'task_note']

    let params: any = {
      limit: 10,
      task_id: $ctrl.task.id
    }

    if (types) {
      params.type = types.join(',')
    }

    return ActivitiesData.init($ctrl.task.account.id, includeEventGroups, null, params)
  }

  function organise(activities) {
    $ctrl.organisedActivities = ActivitiesData.groupUsers(ActivitiesData.groupDates(_uniqBy(activities, 'id')))

    if (!$ctrl.canFetchMore && $ctrl.organisedActivities.length) {
      // Mark the last group of activities as such so we can display it differently
      ;(_last(_last($ctrl.organisedActivities)) as any).$last = true
    }
  }

  function reset() {
    $ctrl.activities = []
    $ctrl.organisedActivities = []
    $ctrl.canFetchMore = {}
    $ctrl.fetching = false
  }

  function show(types) {
    reset()

    $ctrl.activities = initActivitiesData(types)

    addActivities()
  }
}
