import { Cursor } from './index'

export function createParamsFromCursor(cursor: Cursor) {
  if (!cursor.hasMore) {
    throw new Error('Cursor has no more items.')
  }

  const params: any = {}

  if (cursor.page) {
    const { currentPage, perPage } = cursor.page
    params.page = currentPage + 1
    params.limit = perPage
  } else if (cursor.basic) {
    const { next } = cursor.basic
    params.cursor = next
  }

  return params
}
