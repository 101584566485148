export function Link(scope, element, attrs) {
  if ('simple' in attrs) {
    scope.vm.view = 'simple'
  } else if ('td' in attrs) {
    scope.vm.view = 'td'
  } else {
    scope.vm.view = 'modal'
  }

  scope.vm.compact = 'compact' in attrs
}
