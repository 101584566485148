import { createResource } from '../../api/resource'
import { createRequestTransformer, createResponseTransformer } from '../../api/transformer'
import { Task } from '../index'

interface UpdateTaskPayload {
  title?: string
  body?: string
  deadlineAt?: string
}

interface Params {
  taskId: UUID
  payload: UpdateTaskPayload
}

const transformRequest = createRequestTransformer<UpdateTaskPayload>()

export const updateTask = createResource({
  request: ({ taskId, payload }: Params) => ({
    url: `tasks/${taskId}`,
    method: 'PATCH',
    data: transformRequest(payload)
  }),
  transform: createResponseTransformer<Task>('record', 'user', 'source', 'assignees', 'uploads', 'account', 'log')
})
