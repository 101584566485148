export function ptProfileBasicRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('profile', {
    abstract: true,
    url: '/profile',
    parent: 'layoutFull',
    views: {},
    onEnter: onProfileEnter
  })

  $stateProvider.state('profile.basic', {
    url: '/basic',
    views: {
      'content@layoutFull': {
        component: 'ptProfileBasic'
      }
    },
    resolve: {
      user: resolveUser,
      timezones: resolveTimezones,
      countries: resolveCountries,
      dateFormats: resolveDateFormats,
      timeFormats: resolveTimeFormats
    }
  })

  function onProfileEnter(page, gettextCatalog) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Profile'))
  }

  function resolveUser(profileData) {
    'ngInject'

    return profileData.getUser()
  }

  function resolveTimezones(timezonesData) {
    'ngInject'

    return timezonesData.get()
  }

  function resolveCountries(CountriesData) {
    'ngInject'

    return CountriesData.get()
  }

  function resolveDateFormats(formatsData) {
    'ngInject'

    return formatsData.getFormats('date')
  }

  function resolveTimeFormats(formatsData) {
    'ngInject'

    return formatsData.getFormats('time')
  }
}
