import { Controller } from './controller'
import view from './view.html'

export const ptChecklistFieldUploads = {
  bindings: {
    fieldUploads: '<',
    viewSize: '@'
  },
  controller: Controller,
  template: view
}
