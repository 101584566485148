import view from './view.html'

export function checkbox(formlyConfig) {
  'ngInject'

  formlyConfig.setType({
    name: 'checkbox',
    template: view,
    overwriteOk: true
  })
}
