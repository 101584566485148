export function Controller($scope, $stateParams) {
  'ngInject'

  const watch = () => {
    $scope.$watch('$ctrl.filter.option', (newValue) => {
      this.onUpdateFilterValue({
        filterName: this.filterKey,
        value: newValue
      })
    })
  }

  const setupSelectedOption = () => {
    switch ($stateParams[this.filterKey]) {
      case 'false':
        this.filter.option = 'false'
        break
      case 'true':
        this.filter.option = 'true'
        break
      case 'unknown':
        this.filter.option = 'unknown'
        break
      default:
        this.filter.option = 'all'
    }
  }

  this.$onInit = () => {
    this.filter = {
      option: null,
      $open: false
    }

    watch()
    setupSelectedOption()
  }

  this.$onChanges = () => {}
}
