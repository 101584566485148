import { PapertrailSession } from '@papertrail/web3-session'

export function Controller($window, $scope, Upload, profileData, session) {
  'ngInject'

  let vm = this

  vm.control = {
    selected: selected,
    save: save,
    cancel: cancel,
    remove: remove
  }

  vm.image = null
  vm.previewUrl = null
  vm.uploading = false

  vm.$onInit = function () {}

  function selected(files) {
    if (files.length) {
      vm.image = files[0]

      Upload.dataUrl(vm.image, true).then(function (url) {
        vm.previewUrl = url
      })
    }
  }

  function save() {
    vm.uploading = true

    profileData
      .uploadAvatar(vm.image)
      .then(function (data) {
        vm.user.avatar = data.data.data.avatar
        vm.uploading = false
        vm.previewUrl = null
        vm.image = null
        session.user = data.data.data
        PapertrailSession.loadUser()
      })
      .catch(function () {})
  }

  function cancel() {
    vm.uploading = false
    vm.image = null
    vm.previewUrl = null
  }

  function remove() {
    profileData
      .removeAvatar()
      .then(function () {
        $window.location.reload()
      })
      .catch(function () {})
  }
}
