import { createResource } from '../../api/resource'
import { createRequestTransformer, createResponseTransformer } from '../../api/transformer'
import { Task } from '../index'

interface CreateTaskPayload {
  title: string
  body?: string
  deadlineAt: string
  recordId: string
  assignees?: string[]
}

interface Params {
  payload: CreateTaskPayload
}

const transformRequest = createRequestTransformer<CreateTaskPayload>()

export const createTask = createResource({
  request: ({ payload }: Params) => ({
    url: `tasks`,
    method: 'POST',
    data: transformRequest(payload)
  }),
  transform: createResponseTransformer<Task>('record', 'user', 'source', 'assignees', 'uploads', 'account', 'log')
})
