import * as React from 'react'
import styled from 'styled-components'

const TooltipTrigger = styled.span`
  position: relative;
  cursor: pointer;

  &:before {
    content: attr(data-tip);
    position: absolute;
    top: -25px;
    left: 10%;
    transform: translateX(-50%);
    padding: 5px 8px;
    min-width: 300px;
    text-transform: none;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    margin-left: 180px;
    z-index: 999;
  }

  &:hover:before {
    opacity: 1;
  }
`

const spanStyle: React.CSSProperties = {
  textTransform: 'none'
}

export class FileUploadDisclaimer extends React.Component<any> {
  render() {
    return (
      <TooltipTrigger
        className="tooltip-trigger"
        data-tip="For security reasons, please note that uploaded files may be removed or modified if they contain malicious content.">
        <span style={spanStyle}>&#9432;</span>
      </TooltipTrigger>
    )
  }
}
