export function Controller(context, membersData, permissionsConverter, gettextCatalog, ErrorsModal, TasksData) {
  'ngInject'
  const account = context.get('account')
  const showTasks = TasksData.flag(account) === 'active'

  let vm = this
  const s = {
    /// Field label
    adminMember: gettextCatalog.getString('Admin member'),
    /// Field help message
    adminHelp: gettextCatalog.getString('Admin members can access the account settings and manage other members.'),
    viewOnlyMember: gettextCatalog.getString('View only member'),
    viewOnlyHelp: gettextCatalog.getString(
      'View only members can view folders, records and tasks but cannot make any changes.'
    ),
    /// Field label
    permissions: gettextCatalog.getString('Permissions'),
    /// Message
    adminPermissions: gettextCatalog.getString('Admin members automatically receive all permissions'),
    viewOnlyPermissions: gettextCatalog.getString('View only members automatically receive view permissions'),
    /// Field label
    folderPermissions: gettextCatalog.getString('Folder permissions'),
    /// Field help message
    folderPermissionsHelp: gettextCatalog.getString('Restrict what this member can do with folders.'),
    /// Field label
    recordPermissions: gettextCatalog.getString('Record permissions'),
    /// Field help message
    recordPermissionsHelp: gettextCatalog.getString('Restrict what this member can do with records.'),
    /// Field label
    inspectionPermissions: gettextCatalog.getString('Inspection permissions'),
    /// Field help message
    inspectionPermissionsHelp: gettextCatalog.getString('Restrict what this member can do with inspections.'),
    /// Select box option
    folderView: gettextCatalog.getString('Can only view folders'),
    /// Select box option
    folderAdd: gettextCatalog.getString('Can add new folders'),
    /// Select box option
    folderEdit: gettextCatalog.getString('Can edit folders'),
    /// Select box option
    recordView: gettextCatalog.getString('Can only view records'),
    /// Select box option
    recordAdd: gettextCatalog.getString('Can add new records'),
    /// Select box option
    recordEdit: gettextCatalog.getString('Can edit records'),
    /// Select box option
    inspectionView: gettextCatalog.getString('Can only view inspections'),
    /// Select box option
    inspectionAdd: gettextCatalog.getString('Can add new inspections'),
    /// Select box option
    inspectionEdit: gettextCatalog.getString('Can edit inspections'),
    /// Field label
    taskPermissions: gettextCatalog.getString('Task permissions'),
    /// Field help message
    taskPermissionsHelp: gettextCatalog.getString('Restrict what this member can do with tasks.'),
    /// Select box option
    taskAdd: gettextCatalog.getString('Can add tasks'),
    /// Select box option
    taskEdit: gettextCatalog.getString('Can edit tasks'),
    /// Select box option
    taskView: gettextCatalog.getString('Can only view tasks')
  }

  const convertPermissions = () => {
    this.memberModel.permissions = permissionsConverter.convert(this.memberModel.permissions, 'key')
  }

  const getFolders = () => {
    membersData.folderIds(this.memberModel).then((folderIds) => {
      this.memberModel.folderIds = folderIds
    })
  }

  const saveError = (rejection) => {
    if (rejection.error === 'member_limit_exceeded') {
      ErrorsModal.openModal(rejection.error)
    } else {
      this.error = rejection.error
    }

    this.saving = false
  }

  const saveSuccess = () => {
    this.dismiss({ $value: 'saved' })
  }

  this.$onInit = () => {
    this.memberModel = this.resolve.member
    this.memberModel.folderIds = null
    this.reactivating = this.resolve.$transitions.params().reactivate
    this.folderPickerReady = false
    this.saving = false
    this.error = null

    this.formFields = [
      {
        key: 'admin',
        type: 'checkbox',
        templateOptions: {
          label: gettextCatalog.getString('Admin member'),
          help: s.adminHelp
        },
        hideExpression: function () {
          return vm.memberModel.view_only
        }
      },
      {
        key: 'view_only',
        type: 'checkbox',
        templateOptions: {
          label: s.viewOnlyMember,
          help: s.viewOnlyHelp
        },
        hideExpression: function () {
          return vm.memberModel.admin
        }
      },
      {
        template: '<label class="control-label">' + s.permissions + '</label><p>' + s.adminPermissions + '</p>',
        hideExpression: () => {
          return !vm.memberModel.admin
        }
      },
      {
        template: '<label class="control-label">' + s.permissions + '</label><p>' + s.viewOnlyPermissions + '</p>',
        hideExpression: function () {
          return !vm.memberModel.view_only
        }
      },
      {
        key: 'permissions',
        hideExpression: () => {
          return vm.memberModel.admin || vm.memberModel.view_only
        },
        fieldGroup: [
          {
            key: 'folders',
            type: 'select',
            templateOptions: {
              label: s.folderPermissions,
              help: s.folderPermissionsHelp,
              options: [
                { name: s.folderView, id: 'view' },
                { name: s.folderAdd, id: 'add' },
                { name: s.folderEdit, id: 'edit' }
              ],
              ngOptions: 'level.id as level.name for level in to.options'
            }
          },
          {
            key: 'records',
            type: 'select',
            templateOptions: {
              label: s.recordPermissions,
              help: s.recordPermissionsHelp,
              options: [
                { name: s.recordView, id: 'view' },
                { name: s.recordAdd, id: 'add' },
                { name: s.recordEdit, id: 'edit' }
              ],
              ngOptions: 'level.id as level.name for level in to.options'
            }
          },
          {
            key: 'logs',
            type: 'select',
            templateOptions: {
              label: s.inspectionPermissions,
              help: s.inspectionPermissionsHelp,
              options: [
                { name: s.inspectionView, id: 'view' },
                { name: s.inspectionAdd, id: 'add' },
                { name: s.inspectionEdit, id: 'edit' }
              ],
              ngOptions: 'level.id as level.name for level in to.options'
            }
          },
          {
            key: 'tasks',
            type: 'select',
            hideExpression: () => !showTasks,
            templateOptions: {
              label: s.taskPermissions,
              help: s.taskPermissionsHelp,
              options: [
                { name: s.taskView, id: 'view' },
                { name: s.taskAdd, id: 'add' },
                { name: s.taskEdit, id: 'edit' }
              ],
              ngOptions: 'level.id as level.name for level in to.options'
            }
          }
        ]
      }
    ]

    convertPermissions()

    if (showTasks && !this.memberModel.permissions.tasks) {
      this.memberModel.permissions.tasks = 'view'
    }

    getFolders()

    if (this.reactivating) {
      this.memberModel.active = true
    }
  }

  this.changeHandler = (memberFolders) => {
    this.memberModel.folderIds = memberFolders
  }

  this.toggleFolderPickerReady = (state) => {
    this.folderPickerReady = state
  }

  this.save = () => {
    if (this.form.$valid) {
      this.saving = true
      this.error = null

      // If the member is admin, dont send any folders as the API assumes all folders for an admin
      if (this.memberModel.admin) {
        this.memberModel.folderIds = []
      }

      membersData.update(this.memberModel, context.get('account')).then(saveSuccess, saveError)
    }
  }
}
