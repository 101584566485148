export function Controller(context, $state, $location, $scope, $stateParams, $interval, $timeout, permissionsCheck, recordsBucket) {
  'ngInject'

  const vm = this
  const member = context.get('member')
  const userId = member.user.$scope.$scope.id
  this.dontShowAgain = false

  this.$onInit = () => {
    this.permissionsCheck = permissionsCheck
    this.accountId = context.get('account').id
    this.records = this.resolve.records
    this.numberOfRecords = this.records.length
    this.createOrCloneTriggerd = false
    
    addEventListener('identifiersSaved', () => {
      vm.title = 'Identifiers'
      vm.action = 'identifiers'
    })

    $scope.$on('record_was_cloned', (_, data) => {
      if (vm.createOrCloneTriggerd) {
        vm.title = 'Clone'
        vm.action = 'clone'
        vm.records = []
        vm.createOrCloneTriggerd = false
      }

      vm.records = [...vm.records, { id: data.recordId }]
    })

    $scope.$on('$destroy', () => {
      $interval.cancel(vm.interval)
    })

    $timeout(() => {
      this.action = $stateParams.action

      if (this.action === 'inspection') {
        this.title = 'Inspection'
      } else if (this.action === 'export') {
        this.title = 'Print/Export'
      } else if (this.action === 'edit') {
        this.title = 'Edit'
      } else if (this.action === 'clone') {
        this.title = 'Clone'
      } else if (this.action === 'create') {
        this.title = 'Create'
      } else if (this.action === 'identifiers') {
        this.title = 'Identifiers'
      }
    })
  }

  this.control = {
    batchExport,
    identifiers,
    clone,
    edit,
    addLogs,
    addNotes,
    tag,
    checklist,
    move,
    addToBucket,
    removeFromBucket

  }

  this.onClose = () => {
    if (this.dontShowAgain) {
      localStorage.setItem(`no-followup-inspections-for-${userId}`, 'true')
    }

    this.dismiss()
  }

  function recordIds() {
    return vm.records.map((record) => record.id);
  }

  function getRecordIds() {
    return recordIds().join(',')
  }

  function addToBucket() {
    vm.dismiss()
    $timeout(() => {
      recordsBucket.addMany(recordIds());
    });
  }

  function removeFromBucket() {
    vm.dismiss()
    $timeout(() => {
      recordsBucket.removeMany(recordIds());
    });
  }

  function addLogs() {
    vm.dismiss()
    $timeout(() => {
      $state.go('.new-log', { recordIds: getRecordIds() })
    });
  }

  function addNotes() {
    vm.dismiss()
    $timeout(() => {
      $state.go('.new-note', { recordIds: getRecordIds() })
    });
  }

  function tag() {
    vm.dismiss()
    $timeout(() => {
      $state.go('.tag-records', { recordIds: getRecordIds() })
    });
  }

  function checklist() {
    vm.dismiss()
    $timeout(() => {
      $state.go('.checklist-records', { recordIds: getRecordIds() })
    });
  }

  function move() {
    vm.dismiss()
    $timeout(() => {
      $state.go('.move-records', { recordIds: getRecordIds() })
    });
  }

  function batchExport() {
    vm.dismiss()
    $timeout(() => {
      $state.go('.batch-export', { recordIds: getRecordIds() })
    })
  }

  function edit() {
    vm.dismiss()
    $timeout(() => {
      $state.go('.edit-records', { recordIds: getRecordIds() })
    })
  }

  function identifiers() {
    $location.url($location.url() + `&records=${getRecordIds()}`)
  }

  function clone() {
    vm.createOrCloneTriggerd = true

    if ($state.get('.clone-records')) {
      $state.go('.clone-records', { recordIds: getRecordIds() })
    } else {
      $state.go('folder.clone-records', { recordIds: getRecordIds() })
    }
  }
}
