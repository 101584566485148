import { cloneDeep as _cloneDeep, clone as _clone } from 'lodash'

export function Controller(richText) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    saveField,
    click
  }

  let memorizeCache = {
    richText: {
      raw: null,
      parsed: null
    }
  }

  function memorizeRichText(text) {
    if (memorizeCache.richText.raw !== text) {
      memorizeCache.richText.raw = text
      memorizeCache.richText.parsed = richText(text)
    }

    return memorizeCache.richText.parsed
  }

  $ctrl.richText = memorizeRichText

  $ctrl.$onChanges = (changes) => {
    if (changes.field) {
      $ctrl.field = _cloneDeep($ctrl.field)
    }

    if (changes.editable) {
      $ctrl.editable = _clone($ctrl.editable)
    }
  }

  function saveField(value) {
    return $ctrl.onSave({ value: value })
  }

  function click(event, form) {
    if (event.target.nodeName !== 'INPUT') {
      form.$show()
    }
  }
}
