import { ComponentProps, ComponentType, default as React } from 'react'
import {
  ImageProps,
  ImageStyle,
  ScrollViewProps,
  TextInputProps,
  TextInputStyle,
  TextProps,
  TextStyle,
  ViewProps,
  ViewStyle
} from 'reactxp/src/common/Types'

import {
  Image as ImageXP,
  ScrollView as ScrollViewXP,
  Text as TextXP,
  TextInput as TextInputXP,
  View as ViewXP
} from 'reactxp'
import { Styles } from 'reactxp/dist/web/ReactXP'
import { Label as LabelXP } from './label'
import { Localise as LocaliseXP } from './localise'
import { isPlainObject } from 'lodash'

const createComponent = <P extends StyleHolder>(Comp: any, styleBuilder: any): ComponentType<P> => {
  return ({ style = {}, ...props }: P) => <Comp style={styleBuilder(style, false)} {...props} />
}
/*
export const createInjectedComponent = <
  Props extends any,
  InjectedProps extends any,
  DefinedProps extends Partial <Props>
  > (Comp: ComponentType <Props>, mapProps: (p: InjectedProps) => DefinedProps) => {
  type InputProps = InjectedProps & Omit <Props, keyof DefinedProps> & Partial <Pick <Props, keyof DefinedProps>>

  const InjectedComponent = (props: InputProps) => {
    const suppliedProps = mapProps(props)
    const combinedProps = {}

    // For each prop, if it is an object and is injected & passed in, then merge the objects together
    Object
      .keys(props)
      .forEach((key) => {
        const inputProp = props[key]
        const suppliedProp = suppliedProps[key]

        if (inputProp && suppliedProp && isPlainObject(inputProp) && isPlainObject(suppliedProp)) {
          combinedProps[key] = { ...suppliedProp, ...inputProp }
        }
      })

    return <Comp {...suppliedProps as any} {...combinedProps as any} {...props} />
  }

  return InjectedComponent as ComponentType <InputProps>
}
*/
interface StyleHolder {
  style?: any
}

export const createStyledComponent = <P extends StyleHolder>(Comp: ComponentType<P>, staticStyle: P['style'] = {}) => {
  return ({ style = {}, ...props }) => <Comp style={{ ...staticStyle, ...style }} {...(props as any)} />
}

// TODO migrate to createStyledComponent(view, style)
export const createStyledView = (style: ViewStyle) => createStyledComponent(View, style)
export const createStyledText = (style: TextStyle) => createStyledComponent(Text, style)
export const createStyledTextInput = (style: TextInputStyle) => createStyledComponent(TextInput, style)
export const createStyledImage = (style: ImageStyle) => createStyledComponent(Image, style)
export const createStyledLocalise = (style: TextStyle) => createStyledComponent(Localise, style)
export const createStyledLabel = (style: TextStyle) => createStyledComponent(Label, style)
// TODO migrate to createStyledComponent(view, style)

export const View = createComponent<ViewProps>(ViewXP, Styles.createViewStyle.bind(Styles))
export const ScrollView = createComponent<ScrollViewProps>(ScrollViewXP, Styles.createScrollViewStyle.bind(Styles))
export const Text = createComponent<TextProps>(TextXP, Styles.createTextStyle.bind(Styles))
export const TextInput = createComponent<TextInputProps>(TextInputXP, Styles.createTextInputStyle.bind(Styles))
export const Image = createComponent<ImageProps>(ImageXP, Styles.createImageStyle.bind(Styles))
export const Localise = createComponent<ComponentProps<typeof LocaliseXP>>(
  LocaliseXP,
  Styles.createTextStyle.bind(Styles)
)
export const Label = createComponent<ComponentProps<typeof LabelXP>>(LabelXP, Styles.createTextStyle.bind(Styles))

export const Flex = createStyledComponent(View, { flex: 1 })
export const FlexRow = createStyledComponent(View, { flexDirection: 'row' })
export const FlexColumn = createStyledComponent(View, { flexDirection: 'column' })
export const Absolute = createStyledComponent(View, { position: 'absolute' })
export const Bold = createStyledComponent(Text, { fontWeight: 'bold' })
