import _assign from 'lodash/assign'

export function Controller($scope, uploadsData, EventsData, iAuditorData, pagination) {
  'ngInject'

  let $ctrl = this

  $ctrl.iAuditorUpload = false
  $ctrl.audits = null
  $ctrl.auditSearchTerm = null
  $ctrl.auditsLoading = false
  $ctrl.auditsLoadingMore = false
  $ctrl.canLoadMoreAudits = false
  $ctrl.auditSearchTotal = null

  $ctrl.auditParams = {
    sort: '-date_completed',
    used: false,
    limit: 10
  }

  $ctrl.control = {
    addAudit: addAudit,
    auditsUsed: auditsUsed,
    clearAuditSearch: clearAuditSearch,
    initAudits: initAudits,
    initUploads: initUploads,
    loadMoreAudits: loadMoreAudits,
    searchAudit: searchAudit,
    showAuditPanel: showAuditPanel,
    toggleUploadView: toggleUploadView,
    upload: upload
  }

  $ctrl.$onInit = function () {
    listen()
  }

  function listen() {
    $scope.$watch('$ctrl.auditSearchTerm', function (newValue) {
      if (newValue !== null && newValue.length === 0) {
        $ctrl.auditParams.filter = null
        $ctrl.control.initAudits()
      }
    })
  }

  function upload() {
    $ctrl.close({ $value: $ctrl.files })
  }

  function initUploads() {
    if ($ctrl.uploadView === 'device') {
      return
    }

    $ctrl.control.toggleUploadView('device')
  }

  function initAudits(additionalParams) {
    if (typeof additionalParams === 'object') {
      $ctrl.auditParams = _assign($ctrl.auditParams, additionalParams)
    }

    $ctrl.control.toggleUploadView('iauditor')

    $ctrl.audits = iAuditorData.init()
    $ctrl.iAuditorUpload = true

    $ctrl.control.loadMoreAudits()
  }

  function loadMoreAudits() {
    $ctrl.auditsLoadingMore = true

    iAuditorData.loadMore($ctrl.audits, $ctrl.auditParams).then(loadAuditsSuccess).finally(loadAuditsFinally)
  }

  function searchAudit() {
    if ($ctrl.auditSearchTerm.length > 2) {
      $ctrl.auditsLoading = true
      $ctrl.auditParams.filter = 'name~=' + $ctrl.auditSearchTerm

      $ctrl.control.initAudits()
    }
  }

  function loadAuditsSuccess(response) {
    $ctrl.audits = response
    $ctrl.canLoadMoreAudits = pagination.hasMorePages(response)
    $ctrl.auditSearchTotal = response.$metadata.pagination.total
  }

  function loadAuditsFinally() {
    $ctrl.auditsLoading = false
    $ctrl.auditsLoadingMore = false
  }

  function clearAuditSearch() {
    $ctrl.auditSearchTerm = ''
  }

  function toggleUploadView(view) {
    $ctrl.uploadView = view
  }

  function auditsUsed(used) {
    if (typeof used === 'boolean' && used !== $ctrl.auditParams.used) {
      $ctrl.control.initAudits({ used: used })
    }
  }

  function addAudit(audit) {
    // Create an audit 'file' object with the information needed to upload.
    let auditFile = [
      {
        type: 'iauditor',
        value: audit.id,
        name: audit.name
      }
    ]

    // Tell the controller that opened the modal about the file.
    $ctrl.close({ $value: auditFile })
  }

  function showAuditPanel() {
    if ($ctrl.uploadView === 'iauditor') {
      return
    }

    $ctrl.control.initAudits()
  }
}
