import view from './view.html'

export function ptSummariesTable() {
  'ngInject'

  let directive = {
    restrict: 'A',
    template: view,
    controller: function () {},
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      summaries: '='
    }
  }

  return directive
}
