import { AxiosRequestConfig } from 'axios'
import { Transformer } from './transformer'
import { getAxiosInstance, ServerPayload } from './index'

export interface ResourceConfig<R> {
  request: (...params) => AxiosRequestConfig
  transform: Transformer<ServerPayload, R>
}

export const createResource = <C extends ResourceConfig<any>>(config: C) => {
  type RequestCall = C['request']
  type Args = Parameters<RequestCall>
  type TransformCall = C['transform']
  type Result = ReturnType<TransformCall>

  return async (...args: Args) => {
    const { request } = getAxiosInstance()
    const { data } = await request(config.request(...args))
    return config.transform(data) as Result
  }
}
