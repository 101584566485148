export function dateTimeFormat() {
  'ngInject'

  // Default values incase they are used before being set for the first time
  let currentDateFormat = 'Do MMM YYYY',
    currentTimeFormat = 'H:mm a'

  let service = {
    date: date,
    time: time,
    dateTime: dateTime
  }
  return service

  ////////////////

  function date(dateFormat) {
    if (typeof dateFormat !== 'undefined') {
      currentDateFormat = dateFormat
    }

    return currentDateFormat
  }

  function time(timeFormat) {
    if (typeof timeFormat !== 'undefined') {
      currentTimeFormat = timeFormat
    }

    return currentTimeFormat
  }

  function dateTime() {
    return currentDateFormat + ' ' + currentTimeFormat
  }
}
