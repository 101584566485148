import { Controller } from './controller'
import view from './view.html'

export function ptInvitationsPendingSectionHeader() {
  'ngInject'

  let directive = {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'A',
    scope: {
      folder: '=',
      pendingInvitations: '=',
      outerControl: '=control'
    }
  }

  return directive
}
