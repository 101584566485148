export function Controller(gettextCatalog) {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {
    valueChange: valueChange
  }

  $ctrl.crumbs = [
    {
      name: gettextCatalog.getString('Settings'),
      link: 'settings.general'
    }
  ]
  $ctrl.self = gettextCatalog.getString('Team Settings')

  $ctrl.filterValue = ''

  $ctrl.$onInit = function () {}

  function valueChange(value) {
    if (value !== $ctrl.filterValue) {
      $ctrl.filterValue = value
    }
  }
}
