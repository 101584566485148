//import { jsPDF } from 'jspdf'
import moment from 'moment-timezone'

/**
 * This will only work if the img url is on same domain, or if the resource
 * has CORS set up to allow the origin
 * @param url
 */
async function getBase64FromUrl(url) {
  const data = await fetch(url, { mode: 'cors' })
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onload = function () {
      const base64data = this.result
      resolve(base64data)
    }
    reader.readAsDataURL(blob)
  })
}

export async function getPieChartPDF(method, accountName, userName, logoUrl) {
  const { default: jsPDF } = await import('jspdf')
  const pdf = new jsPDF('l')

  var logo = (await getBase64FromUrl(logoUrl + '?pdf=true')) as string
  pdf.addImage(logo, 'png', 15, 12, 20, 20)

  pdf.setFont('Helvetica', '')

  pdf.setFontSize(24)
  pdf.text(accountName, 40, 25)

  pdf.text('COMPLIANCE REPORT', 282, 25, { align: 'right' })

  pdf.setFont('Helvetica', 'Bold')
  pdf.setFontSize(10)
  pdf.text('Generated by:', 282, 175, { align: 'right' })
  pdf.setFont('Helvetica', '')
  pdf.text(userName, 282, 180, { align: 'right' })
  pdf.setFont('Helvetica', 'Bold')
  pdf.text('Generated at:', 282, 185, { align: 'right' })
  pdf.setFont('Helvetica', '')
  pdf.text(moment().format('Do MMM YYYY hh:mm a'), 282, 190, { align: 'right' })

  pdf.setFontSize(9)
  pdf.text('Powered by', 15, 184, { align: 'left' })
  var papertrailLogo = (await getBase64FromUrl(appConfig.images + 'logo-print.png')) as string
  pdf.addImage(papertrailLogo, 'png', 15, 185, 26.6, 4.8)

  var canvas = document.getElementById('pie-compliance-print') as HTMLCanvasElement
  pdf.addImage(canvas.toDataURL('image/png'), 'png', 40, 60, 220, 110)

  pdf.setProperties({
    title: 'Compliance Pie Chart'
  })
  if (method === 'open') {
    // @ts-ignore
    window.open(pdf.output('bloburl'), 'CompliancePieChart.pdf')
  } else {
    //TODO better name
    pdf.save('CompliancePieChart.pdf')
  }
  return true
}
