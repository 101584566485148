import angular from 'angular'
import _forEach from 'lodash/forEach'

export function Controller($filter, $state, $stateParams, TasksData, accountsData) {
  'ngInject'

  const $ctrl = this

  $ctrl.filters = {
    folders: {
      picked: [],
      $open: false
    },
    association: {
      selection: null
    },
    account: {
      isLoading: false,
      option: [],
      selection: null,
      $open: false,
      searchText: null
    },
    assignees: {
      option: [],
      selection: null,
      $open: false,
      searchText: null
    },
    user: {
      option: [],
      selection: null,
      $open: false,
      searchText: null
    },
    state: {
      option: null,
      $open: false
    }
  }

  $ctrl.control = {
    applyFilters,
    clearFilter,
    searchAccounts,
    searchMembers,
    selectAll,
    toggleFilterPanel,
    toggleSection
  }

  $ctrl.isProfileTasks = $state.current.name.indexOf('tasks') === 0
  $ctrl.isAccountTasks = $state.current.name.indexOf('account.tasks') === 0

  $ctrl.$onInit = function () {
    if ($ctrl.isProfileTasks) {
      setupAccountsFilter()
      setupAssociationFilter()
    }

    if ($ctrl.isAccountTasks) {
      setupFolderFilter()
    }

    setupStatesFilter()
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.members) {
      $ctrl.members = angular.copy($ctrl.members)

      setupMembers()
    }
  }

  function applyFilters() {
    const params = {
      account: $ctrl.filters.account.selection,
      association: checkAssociationFilter(),
      folders: $ctrl.filters.folders.picked.join(','),
      assignees: $ctrl.filters.assignees.selection,
      user: $ctrl.filters.user.selection,
      state: getSelectedStates()
    }

    $state.go($state.current.name, params)
  }

  function checkAssociationFilter() {
    let filterValue = null

    // If 'created' is selected, then return that value
    // otherwise the default will be 'assinged'
    if ($ctrl.filters.association.selection === 'created') {
      filterValue = 'created'
    }

    return filterValue
  }

  function clearFilter(filterName) {
    $ctrl.filters[filterName].selection = null
  }

  // *********** For multi-select when it is available on the API
  // function getSelectedMember(filterName, filterProperty = 'id') {
  //   const hasData = $ctrl.filters[filterName].option.length
  //   let validSelection = false
  //   let selectedData
  //   let selectedDataProps
  //
  //   if (hasData) {
  //     selectedData = $ctrl.filters[filterName].option.filter(filterItem => filterItem.$selected)
  //     validSelection = selectedData.length && selectedData.length < $ctrl.filters[filterName].option.length
  //   }
  //
  //   if (validSelection) {
  //     selectedDataProps = selectedData.map(filterItem => filterItem.user[filterProperty]).join(',')
  //   }
  //
  //   return selectedDataProps
  // }

  function getSelectedStates() {
    const selectedStates = $ctrl.filters.state.option.filter((stateItem) => stateItem.$selected)
    const selectedStateKeys = selectedStates.map((stateItem) => stateItem.key).join(',')
    let validStateKeys

    if (selectedStateKeys.length && selectedStateKeys !== 'open,pending') {
      validStateKeys = selectedStateKeys
    }

    return validStateKeys
  }

  function searchMembers(filterName) {
    $ctrl.filters[filterName].option = $filter('filter')($ctrl.members, (member) => {
      const name = $filter('fullname')(member.user).toLowerCase()
      const search = $ctrl.filters[filterName].searchText.toLowerCase()
      let result

      if (name.indexOf(search) >= 0) {
        result = member
      }

      return result
    })
  }

  function searchAccounts() {
    $ctrl.filters.account.option = $filter('filter')($ctrl.accounts, { name: $ctrl.filters.account.searchText })
  }

  function selectAll(filterName, select) {
    $ctrl.filters[filterName].option.forEach((filterItem) => {
      filterItem.$selected = select
    })
  }

  function setSelectedMulti(filterName, selectedData, matchKey = 'id') {
    selectedData.forEach((selectedItem) => {
      $ctrl.filters[filterName].option.some((filterItem) => {
        let isDone = false

        if (filterItem[matchKey] === selectedItem) {
          filterItem.$selected = true

          // break out of the 'some' method lookup
          isDone = true
        }

        return isDone
      })
    })
  }

  function setSelectedMember(filterName, selectedData, matchKey = 'id') {
    selectedData.forEach((selectedItem) => {
      $ctrl.filters[filterName].option.some((filterItem) => {
        let isDone = false

        if (filterItem.user[matchKey] === selectedItem) {
          $ctrl.filters[filterName].selection = selectedItem

          // break out of the 'some' method lookup
          isDone = true
        }

        return isDone
      })
    })
  }

  function setSingleSelection(filterName, selectedData, matchKey = 'id') {
    selectedData.forEach((selectedItem) => {
      $ctrl.filters[filterName].option.some((filterItem) => {
        let isDone = false

        if (filterItem[matchKey] === selectedItem) {
          $ctrl.filters[filterName].selection = selectedItem

          // break out of the 'some' method lookup
          isDone = true
        }

        return isDone
      })
    })
  }

  function setupAccountsFilter() {
    let selectedAccountIds

    $ctrl.filters.account.isLoading = true

    accountsData
      .getAll(accountsData.init())
      .then(setup)
      .catch(() => {})
      .finally(() => {
        $ctrl.filters.account.isLoading = false
      })

    function setup(accounts) {
      $ctrl.accounts = accounts
      $ctrl.filters.account.option = angular.copy($ctrl.accounts)

      if ($stateParams.account) {
        selectedAccountIds = $stateParams.account.split(',')

        setSingleSelection('account', selectedAccountIds)
      }
    }
  }

  function setupAssociationFilter() {
    if ($stateParams.association) {
      $ctrl.filters.association.selection = $stateParams.association
    } else {
      $ctrl.filters.association.selection = 'assigned'
    }
  }

  function setupFolderFilter() {
    if ($stateParams.folders) {
      _forEach($stateParams.folders.split(','), function (folderId) {
        $ctrl.filters.folders.picked.push(folderId)
      })
    }
  }

  function setupMembers() {
    let selectedAssigneesIds = []
    let selectedUsersIds = []

    if ($stateParams.assignees) {
      selectedAssigneesIds = $stateParams.assignees.split(',')
    }

    if ($stateParams.user) {
      selectedUsersIds = $stateParams.user.split(',')
    }

    if ($ctrl.members) {
      $ctrl.filters.assignees.option = angular.copy($ctrl.members)
      $ctrl.filters.user.option = angular.copy($ctrl.members)
    }

    if (selectedAssigneesIds.length) {
      setSelectedMember('assignees', selectedAssigneesIds)
    }

    if (selectedUsersIds.length) {
      setSelectedMember('user', selectedUsersIds)
    }
  }

  function setupStatesFilter() {
    let selectedStateKeys

    $ctrl.filters.state.option = TasksData.getAllAvailableStates()

    if ($stateParams.state) {
      selectedStateKeys = $stateParams.state.split(',')

      setSelectedMulti('state', selectedStateKeys, 'key')
    }
  }

  function toggleFilterPanel() {
    $ctrl.onToggleFilterPanel()
  }

  function toggleSection(section) {
    $ctrl.filters[section].$open = !$ctrl.filters[section].$open
  }
}

