import { cloneDeep } from 'lodash'

export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    isLastItem,
    nextProduct,
    previousProduct,
    onTo
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.records) {
      $ctrl.records = cloneDeep($ctrl.records)
    }

    if (changes.selectedRecord) {
      $ctrl.selectedRecord = cloneDeep($ctrl.selectedRecord)
    }
  }

  function isLastItem() {
    return $ctrl.selectedRecord.index + 1 === $ctrl.records.length
  }

  function nextProduct() {
    $ctrl.onNext()
  }

  function previousProduct() {
    $ctrl.onPrev()
  }

  function onTo() {
    $ctrl.onTo({ value: $ctrl.selectedRecord.index })
  }
}
