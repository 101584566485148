import angular from 'angular'

import { ptFiltersSectionHeaderModule } from './directives/pt-filters-section-header'
import { ptFiltersTableModule } from './directives/pt-filters-table'
import { ptFiltersTableRowModule } from './directives/pt-filters-table-row'

import { filterParams } from './providers/filter-params'

import { filtersData } from './services/data'
import { filteringContext } from './services/filtering-context'

export const FiltersModule = angular
  .module('filters', [
    // directives
    ptFiltersSectionHeaderModule,
    ptFiltersTableModule,
    ptFiltersTableRowModule
  ])
  .provider('filterParams', filterParams)
  .factory('filtersData', filtersData)
  .factory('filteringContext', filteringContext).name
