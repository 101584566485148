import { Controller } from './controller'
import view from './view.html'

export const ptTaskAssigneesPreview = {
  bindings: {
    assignees: '<',
    assigneeLimit: '@'
  },
  controller: Controller,
  template: view
}
