export function NotesData(EventsData) {
  'ngInject'

  const create = (record, noteDate) => {
    return record.notes
      .$build(noteDate)
      .$save()
      .$asPromise()
      .then(function (note) {
        EventsData.pushEvent('note_was_created', {
          historyId: note.id,
          type: 'note',
          recordId: record.id,
          folderId: record.folder.id,
          active: note.active
        })
      })
  }

  return {
    create
  }
}
