export function Controller() {
  'ngInject'

  let vm = this

  if (vm.account) {
    vm.isAdmin = vm.account.member && vm.account.member.admin
  } else {
    vm.isAdmin = true
  }
}
