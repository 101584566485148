export function Controller($scope, recordsData, gettextCatalog, richText) {
  'ngInject'

  let vm = this
  let memorizeCache = {
    richText: {
      raw: null,
      parsed: null
    }
  }

  vm.control = {
    saveComments: saveComments,
    click: click
  }

  vm.richText = memorizeRichText

  vm.field = {
    required: false,
    label: gettextCatalog.getString('Comments')
  }

  /////////////

  vm.$onInit = function () {}

  function memorizeRichText(text) {
    if (memorizeCache.richText.raw !== text) {
      memorizeCache.richText.raw = text
      memorizeCache.richText.parsed = richText(text)
    }

    return memorizeCache.richText.parsed
  }

  function saveComments(value) {
    return recordsData.saveProperty(vm.record, 'comments', value).catch(() => {})
  }

  function click(event, form) {
    if (event.target.nodeName !== 'A') {
      form.$show()
    }
  }
}
