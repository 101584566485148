import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'

export const ChecklistsCache = () => {
  'ngInject'

  const cache = {}

  const store = (account, data) => {
    cache[account.id] = _cloneDeep(data)
  }

  const get = (account) => {
    return _cloneDeep(_get(cache, account.id))
  }

  return {
    get,
    store
  }
}
