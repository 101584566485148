import view from './view.html'

export function ptFoldersTableHead() {
  let directive = {
    template: view,
    controller: function () {
      'ngInject'
    },
    controllerAs: 'vm',
    bindToController: true,
    scope: {}
  }

  return directive
}
