import angular from 'angular'

export function Controller(gettextCatalog) {
  'ngInject'

  const $ctrl = this

  $ctrl.billingType = null
  $ctrl.s = {
    notSupplied: gettextCatalog.getString('Not supplied'),
    card: gettextCatalog.getString('Card'),
    invoice: gettextCatalog.getString('Invoice')
  }

  $ctrl.$onInit = function () {
    $ctrl.billingType = getBillingType()
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.subscription) {
      $ctrl.subscription = angular.copy($ctrl.subscription)
    }

    if (changes.billing) {
      $ctrl.billing = angular.copy($ctrl.billing)
    }

    if (changes.billingDetailsAvailable) {
      $ctrl.billingDetailsAvailable = angular.copy($ctrl.billingDetailsAvailable)
    }
  }

  function getBillingType() {
    let result = 'invoice'

    if ($ctrl.billing.carddetails.year) {
      result = 'card'
    }

    return result
  }
}
