import { Controller } from './controller'
import view from './view.html'

export const ptNotesNewForm = {
  bindings: {
    record: '<',
    onCopyDown: '&',
    onRegister: '&',
    onRemove: '&',
    onDeregister: '&',
    multiple: '<',
    first: '<'
  },
  controller: Controller,
  template: view
}
