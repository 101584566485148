import _omit from 'lodash/omit'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _startCase from 'lodash/startCase'
import _get from 'lodash/get'
import _find from 'lodash/find'

export function Controller($scope, context, recordsBucket, recordsViewMode, foldersNavigation, filteringContext, tracking, $uibModal) {
  'ngInject'

  let vm = this
  let addParamModal

  vm.control = {
    viewModeChange: viewModeChange,
    addToFilter: addToFilter,
    removeFilter: removeFilter,
    removeFilterTag: removeFilterTag,
    removeAllFilters: removeAllFilters,
    formatValue: formatValue,
    recordsBucket: recordsBucket
  }

  vm.viewMode = null

  vm.$onInit = function () {
    vm.viewMode = recordsViewMode.get()

    vm.filterInfo = getFilterInfo()
    vm.filterInfoTags = getFilterInfoTags()

    vm.toggleSort = function () {
      vm.showSort = !vm.showSort
    }

    watch()
  }

  function formatValue(key, value) {
    if (key === 'Inspection') {
      return value.split('_').join(' ')
    } else {
      return value
    }
  }

  function watch() {
    $scope.$watch('vm.viewMode', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        viewModeChange()
      }
    })
  }

  function viewModeChange() {
    tracking.trackEvent({ action: 'switchView', category: 'recordViewMode', label: vm.viewMode })
    recordsViewMode.set(vm.viewMode)
    foldersNavigation.go(vm.folder.id)
  }

  function removeFilter(paramName) {
    filteringContext.applyFilter(_omit(filteringContext.getFilteringParams(), paramName))
  }

  function removeFilterTag(tag) {
    let tags = filteringContext.getFilteringParams().tag ? filteringContext.getFilteringParams().tag : ''
    tags = _filter(tags.split(','), function (value) {
      return value !== tag
    }).join(',')

    if (tags) {
      filteringContext.applyFilter({ ...filteringContext.getFilteringParams(), tag: tags })
    } else {
      filteringContext.applyFilter(_omit(filteringContext.getFilteringParams(), 'tag'))
    }
  }

  function removeAllFilters() {
    filteringContext.setDefaultFilter().reloadWithFilter()
  }

  function addToFilter() {
    tracking.trackEvent({
      action: 'addTagsToFilter',
      category: 'records',
      label: 'open'
    })

    let existingTags = filteringContext.getFilteringParams().tag

    addParamModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptFolderFilterModal',
      resolve: {
        existingTags: () => (existingTags ? existingTags.split(',') : [])
      }
    })

    addParamModal.result.then(function (result) {
      if (result) {
        let tags = (existingTags ? existingTags + ',' : '') + result.map((it) => it.name).join(',')
        filteringContext.applyFilter({ ...filteringContext.getFilteringParams(), tag: tags })
      }
    })

    addParamModal.result.catch(() => {
      // user dismissed
    })
  }

  function getFilterInfo() {
    let filterInfo = {
      params: null
    }

    filterInfo.params = _map(
      _omit(filteringContext.getFilteringParams(), 'tag', 'action'),
      function (value, key) {
        if (key === 'filter') {
          return {
            name: _startCase(value.split(/=|<|>/)[0]),
            value: value.split(/=|<|>/)[1],
            key: key
          }
        }

        if (key === 'state') {
          //only look up the state name if ythe state filter isn't using the new state attribute syntax
          if (value.split(/=|<|>/).length === 1) {
            value = _get(_find(context.get('account').states, { id: value }), 'name', '')
          }
        }

        return {
          name: _startCase(key),
          value: value,
          key: key
        }
      }
    )

    return filterInfo
  }

  function getFilterInfoTags() {
    let filterInfoTags = {
      params: null
    }

    let tags = filteringContext.getFilteringParams().tag ? filteringContext.getFilteringParams().tag : ''

    if (tags) {
      filterInfoTags.params = _map(tags.split(','), function (value) {
        return {
          name: 'Tag',
          value: value,
          key: value
        }
      })
    }

    return filterInfoTags
  }
}
