import * as Sentry from '@sentry/browser'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _forEach from 'lodash/forEach'
import _result from 'lodash/result'
import axios from 'axios'

const CancelToken = axios.CancelToken

export function filtersData(context, session, ptApi) {
  'ngInject'

  let fetchingCounts = {}

  const fetch = (account) => {
    let params = {
      limit: 100
    }

    return ptApi
      .request({
        method: 'GET',
        url: `/accounts/${account.id}/filters`,
        params
      })
      .then(function (filters) {
        setShowCount(filters.data)
        context.set('filters', filters.data)
        return filters
      })
  }

  const fetchCounts = (folderId, account) => {
    let params = {
      limit: 100,
      folder_id: folderId,
      include: 'count,descendants'
    }

    if (fetchingCounts[account.id] && fetchingCounts[account.id].folderId === folderId) {
      return fetchingCounts[account.id].promise
    } else if (fetchingCounts[account.id] && fetchingCounts[account.id].folderId) {
      fetchingCounts[account.id].source.cancel()
    }

    fetchingCounts[account.id] = {}
    fetchingCounts[account.id].source = CancelToken.source()
    fetchingCounts[account.id].folderId = folderId
    fetchingCounts[account.id].promise = ptApi
      .request({
        method: 'GET',
        url: `/accounts/${account.id}/filters`,
        params,
        cancelToken: fetchingCounts[account.id].source.token
      })
      .then(function (filters) {
        const filterData = filters.data
        fetchingCounts[account.id].folderId = null

        setShowCount(filterData)
        context.set('filters', filterData)

        return filterData
      })
      .catch(() => {})

    return fetchingCounts[account.id].promise
  }

  const defaultFilter = () => {
    let filters = context.get('filters')

    return _find(filters, { default: true })
  }

  const extractRules = (rules) => {
    let extractedRules = {}

    if (rules.length) {
      _forEach(rules, function (rule) {
        extractedRules[rule.key] = rule.value
      })
    }

    return extractedRules
  }

  const setShowCount = (filters) => {
    filters.forEach((filter) => {
      filter.$showCount = _result(_find(filter.meta.data, { key: 'show_count' }), 'value')
    })
  }

  return {
    fetch,
    fetchCounts,
    defaultFilter,
    extractRules
  }
}
