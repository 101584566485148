import sortBy from 'lodash/sortBy'

export function CurrenciesData(CurrenciesModel) {
  'ngInject'

  const service = {
    get,
    popular,
    all
  }

  const cached: { [key: string]: any } = {}

  return service

  ////////////////

  function get(isoCode = 'GBP') {
    const params = {
      limit: 10,
      sort: 'name',
      filter: `iso_code=${isoCode}`
    }

    return CurrenciesModel.$collection(params).$fetch().$asPromise()
  }

  function popular(account, additionalIsoCode) {
    const params = {
      limit: 10,
      sort: 'name',
      filter: 'priority<4'
    }

    if (cached[account.id] && cached[account.id].popular) {
      return cached[account.id].popular
    }

    cached[account.id] = {}

    cached[account.id].popular = CurrenciesModel.$collection(params)
      .$fetch()
      .$asPromise()
      .then((result) => {
        const hasAccountCurrency = result.filter((currency) => account.currency === currency.iso_code)

        if (!hasAccountCurrency.length) {
          return get(account.currency).then((accountCurrency) => {
            return result.concat(accountCurrency)
          })
        }

        return result
      })
      .then((result) => {
        const hasAdditionalCurrency = result.filter((currency) => additionalIsoCode === currency.iso_code)

        if (additionalIsoCode && !hasAdditionalCurrency.length) {
          return get(additionalIsoCode).then((additionalCurrency) => {
            return result.concat(additionalCurrency)
          })
        }

        return result
      })
      .then((result) => {
        return sortBy(result, 'name')
      })

    return cached[account.id].popular
  }

  function all(account) {
    if (cached[account.id] && cached[account.id].all) {
      return cached[account.id].all
    }

    cached[account.id] = {}

    cached[account.id].all = CurrenciesModel.$collection()
      .$fetch({ sort: 'name' })
      .$asPromise()
      .then((result) => {
        return sortBy(result, 'name')
      })

    return cached[account.id].all
  }
}
