/**
 * Password Rules, to be shared by validation directive and formly validation
 * @param password
 * @constructor
 */
export default function PasswordValidator(password: string): boolean {
  if (!/[A-Z]/.test(password)) {
    return false
  }

  if (!/[a-z]/.test(password)) {
    return false
  }

  if (!/\d/.test(password)) {
    return false
  }

  if (!/[\W_]/.test(password)) {
    return false
  }

  return true
}
