import angular from 'angular'
import { AuthenticateRedirect } from './services/authenticate-redirect'
import { Authenticate } from './services/authenticate'
import { HubAuthService } from 'AppModules/authentication/services/hub-auth-service'

export const AuthenticationModule = angular
  .module('authentication', [])
  .factory('AuthenticateRedirect', AuthenticateRedirect)
  .factory('Authenticate', Authenticate)
  .factory('hubAuthService', HubAuthService).name
