export function InvitationModel(restmod) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      account: { hasOne: 'AccountModel', map: 'account.data' },
      subscription: { hasOne: 'SubscriptionModel', map: 'subscription.data' },
      user: { hasOne: 'UserModel', map: 'user.data' }
    })

  return model
}
