import { Controller } from './controller'
import view from './view.html'

export const ptSortPanelListItem = {
  bindings: {
    sortItem: '<',
    onSortRemove: '&',
    onDirectionChange: '&'
  },
  controller: Controller,
  template: view
}
