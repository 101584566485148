import * as productImportHelpers from 'AppCore/product/services'

export function Controller($state, ImportsData, foldersData) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    createImport
  }

  $ctrl.error = false
  $ctrl.loading = false
  $ctrl.autoImportComplete = false

  $ctrl.$onInit = function () {
    if ($ctrl.$transition$.params().newUser) {
      completeImport()
    } else {
      createImport()
    }
  }

  $ctrl.$onChanges = function () {}

  function completeImport() {
    // TODO add redirect check here. Add condition that they need to come from signup route.
    if ($ctrl.accounts.length === 1 && $ctrl.subscriptions.length === 1) {
      $ctrl.loading = true
      $ctrl.error = false
      $ctrl.account = $ctrl.accounts[0]

      ImportsData.createImport($ctrl.$transition$.params().productIds)
        .then((importData) => {
          $ctrl.importData = importData

          return importData
        })
        .then(() => {
          $ctrl.mappings = productImportHelpers.createMappings(
            $ctrl.importData,
            $ctrl.account.frequencies,
            $ctrl.account.blueprint
          )

          return foldersData
            .create($ctrl.account, {
              name: 'Imports',
              description: 'Products imported into Papertrail',
              parentId: $ctrl.account.folder.id
            })
            .then((folder) => {
              $ctrl.folder = folder
              return folder
            })
        })
        .then((folder) => {
          return ImportsData.completeImport(
            $ctrl.importData.id,
            folder.id,
            $ctrl.account.blueprint.id,
            productImportHelpers.prepRecordsForAPI($ctrl.mappings)
          )
        })
        .then(() => {
          // $state.go('subscription.overview.plan-change', {
          //   subscriptionId: $ctrl.subscriptions[0].id, newUser: true
          // })
          $state.go('imports.import.accounts.folders.map.complete', {
            importId: $ctrl.importData.id,
            accountId: $ctrl.account.id,
            folderId: $ctrl.folder.id,
            newUser: true
          })
        })
        .catch(() => {
          $ctrl.error = true
          $ctrl.loading = false
        })
    } else {
      createImport()
    }
  }

  function createImport() {
    $ctrl.error = false
    $ctrl.loading = true

    ImportsData.createImport($ctrl.$transition$.params().productIds)
      .then((response) => {
        if ($ctrl.$transition$.params().accountId && $ctrl.$transition$.params().folderId) {
          $state.go('imports.import.accounts.folders.map', {
            importId: response.id,
            accountId: $ctrl.$transition$.params().accountId,
            folderId: $ctrl.$transition$.params().folderId
          })
        } else if ($ctrl.$transition$.params().accountId) {
          $state.go('imports.import.accounts.folders', {
            importId: response.id,
            accountId: $ctrl.$transition$.params().accountId
          })
        } else {
          $state.go('imports.import.accounts', { importId: response.id })
        }
      })
      .catch(() => {
        $ctrl.error = true
      })
      .finally(() => {
        $ctrl.loading = false
      })
  }
}
