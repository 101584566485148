import angular from 'angular'
import { ptChecklistModule } from './components/pt-checklist'
import { ptChecklistFieldCommentsModule } from './components/pt-checklist-field-comments'
import { ptChecklistFieldEditModule } from './components/pt-checklist-field-edit'
import { ptChecklistFieldUploadsModule } from './components/pt-checklist-field-uploads'
import { ptChecklistRemoveModalModule } from './components/pt-checklist-remove-modal'
import { ptChecklistsSectionModule } from './components/pt-checklists-section'
import { ptChecklistsSectionContentModule } from './components/pt-checklists-section-content'
import { ptChecklistsSectionHeadingModule } from './components/pt-checklists-section-heading'
import { ptChecklistsSelectModule } from './components/pt-checklists-select'
import { ptChecklistItemBooleanModule } from './components/pt-checklist-item-boolean'
import { ptChecklistItemDateModule } from './components/pt-checklist-item-date'
import { ptChecklistItemRadioModule } from './components/pt-checklist-item-radio'
import { ptChecklistItemSelectModule } from './components/pt-checklist-item-select'
import { ptChecklistItemTextAreaModule } from './components/pt-checklist-item-textarea'
import { ptChecklistItemSignatureModule } from './components/pt-checklist-item-signature'
import { ptChecklistItemSignatureModalModule } from './components/pt-checklist-item-signature-modal'

import { ptChecklistItemFilesModule } from './components/pt-checklist-item-files'
import { ChecklistsCache } from './services/cache'
import { ChecklistsData } from './services/data'
import { ChecklistsSentiment } from './services/sentiment'

export const ChecklistsModule = angular
  .module('checklists', [
    ptChecklistModule,
    ptChecklistFieldCommentsModule,
    ptChecklistFieldEditModule,
    ptChecklistFieldUploadsModule,
    ptChecklistRemoveModalModule,
    ptChecklistsSectionModule,
    ptChecklistsSectionContentModule,
    ptChecklistsSectionHeadingModule,
    ptChecklistsSelectModule,
    ptChecklistItemBooleanModule,
    ptChecklistItemDateModule,
    ptChecklistItemRadioModule,
    ptChecklistItemSelectModule,
    ptChecklistItemTextAreaModule,
    ptChecklistItemFilesModule,
    ptChecklistItemSignatureModule,
    ptChecklistItemSignatureModalModule
  ])
  .factory('ChecklistsCache', ChecklistsCache)
  .factory('ChecklistsData', ChecklistsData)
  .factory('ChecklistsSentiment', ChecklistsSentiment).name
