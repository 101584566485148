import _find from 'lodash/find'
import _cloneDeep from 'lodash/cloneDeep'

export function statesData(context) {
  'ngInject'

  let service = {
    get: get,
    defaultState: defaultState
  }
  return service

  ////////////////

  function get(id?) {
    let states = _cloneDeep(context.get('account').states)

    if (id) {
      return _find(states, { id: id })
    }

    return states
  }

  function defaultState() {
    const states = get()

    return _find(states, { default: true }) || states[0]
  }
}
