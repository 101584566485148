export function stateMonitor() {
  'ngInject'

  let states = {
    previousState: {},
    currentState: {},
    targetState: {},
    redirectState: {}
  }

  let service = {
    previousState: previousState,
    currentState: currentState,
    targetState: targetState,
    redirectState: redirectState
  }

  return service

  ////////////////

  function currentState(state) {
    if (state) {
      states.currentState = state
    }
    return states.currentState
  }

  function previousState(state) {
    if (state) {
      states.previousState = state
    }
    return states.previousState
  }

  function targetState(state) {
    if (state) {
      states.targetState = state
    }
    return states.targetState
  }

  function redirectState(state) {
    if (state) {
      states.redirectState = state
    }
    return states.redirectState
  }
}
