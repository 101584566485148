import view from './view.html'

export function ptSummariesTableHead() {
  'ngInject'

  let directive = {
    template: view,
    scope: {}
  }

  return directive
}
