import angular from 'angular'

const datepickerDayModule = angular.module('uib/template/datepicker/day.html', []).run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'uib/template/datepicker/day.html',
      `
        <table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">
          <thead>
            <tr>
              <th>
                <button
                  type="button"
                  class="btn btn-default btn-sm pull-left"
                  ng-click="move(-1)"
                  tabindex="-1">
                  <i class="icon-left-open"></i>
                </button>
              </th>
              <th colspan="{{::5 + showWeeks}}">
                <button
                  id="{{::uniqueId}}-title"
                  role="heading"
                  aria-live="assertive"
                  aria-atomic="true"
                  type="button"
                  class="btn btn-default btn-sm"
                  ng-click="toggleMode()"
                  ng-disabled="datepickerMode === maxMode"
                  tabindex="-1"
                  style="width:100%;">
                  <strong>{{title}}</strong>
                </button>
              </th>
              <th>
                <button type="button" class="btn btn-default btn-sm pull-right" ng-click="move(1)" tabindex="-1">
                  <i class="icon-right-open"></i>
                </button>
              </th>
            </tr>
            <tr>
              <th ng-if="showWeeks" class="text-center"></th>
              <th ng-repeat="label in ::labels track by $index" class="text-center">
                <small aria-label="{{::label.full}}">{{::label.abbr}}</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr ng-repeat="row in rows track by $index">
              <td ng-if="showWeeks" class="text-center h6"><em>{{ weekNumbers[$index] }}</em></td>
              <td
                ng-repeat="dt in row track by dt.date" class="text-center"
                role="gridcell"
                id="{{::dt.uid}}"
                ng-class="::dt.customClass">
                <button
                  type="button"
                  class="btn btn-default btn-sm"
                  ng-class="{'btn-info': dt.selected, active: isActive(dt)}"
                  ng-click="select(dt.date)"
                  ng-disabled="dt.disabled"
                  tabindex="-1">
                  <span ng-class="::{'text-muted': dt.secondary, 'text-info': dt.current}">{{::dt.label}}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        `
    )
  }
])

const datepickerMonthModule = angular.module('uib/template/datepicker/month.html', []).run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'uib/template/datepicker/month.html',
      `
        <table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">
          <thead>
            <tr>
              <th>
                <button type="button" class="btn btn-default btn-sm pull-left" ng-click="move(-1)" tabindex="-1">
                  <i class="icon-left-open"></i>
                </button>
              </th>
              <th>
                <button
                  id="{{::uniqueId}}-title"
                  role="heading"
                  aria-live="assertive"
                  aria-atomic="true"
                  type="button"
                  class="btn btn-default btn-sm"
                  ng-click="toggleMode()"
                  ng-disabled="datepickerMode === maxMode"
                  tabindex="-1"
                  style="width:100%;">
                  <strong>{{title}}</strong>
                </button>
              </th>
              <th>
                <button type="button" class="btn btn-default btn-sm pull-right" ng-click="move(1)" tabindex="-1">
                  <i class="icon-right-open"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr ng-repeat="row in rows track by $index">
              <td
                ng-repeat="dt in row track by dt.date"
                class="text-center"
                role="gridcell"
                id="{{::dt.uid}}"
                ng-class="::dt.customClass">
                <button
                  type="button"
                  class="btn btn-default"
                  ng-class="{'btn-info': dt.selected, active: isActive(dt)}"
                  ng-click="select(dt.date)"
                  ng-disabled="dt.disabled"
                  tabindex="-1">
                  <span ng-class="::{'text-info': dt.current}">{{::dt.label}}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        `
    )
  }
])

const datepickerYearModule = angular.module('uib/template/datepicker/year.html', []).run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'uib/template/datepicker/year.html',
      `
        <table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">
          <thead>
            <tr>
              <th>
                <button type="button" class="btn btn-default btn-sm pull-left" ng-click="move(-1)" tabindex="-1">
                  <i class="icon-left-open"></i>
                </button>
              </th>
              <th colspan="3">
                <button
                  id="{{::uniqueId}}-title"
                  role="heading"
                  aria-live="assertive"
                  aria-atomic="true"
                  type="button"
                  class="btn btn-default btn-sm"
                  ng-click="toggleMode()"
                  ng-disabled="datepickerMode === maxMode"
                  tabindex="-1"
                  style="width:100%;">
                  <strong>{{title}}</strong>
                </button>
              </th>
              <th>
                <button type="button" class="btn btn-default btn-sm pull-right" ng-click="move(1)" tabindex="-1">
                  <i class="icon-right-open"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr ng-repeat="row in rows track by $index">
              <td
                ng-repeat="dt in row track by dt.date"
                class="text-center"
                role="gridcell"
                id="{{::dt.uid}}">
                <button
                  type="button"
                  class="btn btn-default"
                  ng-class="{'btn-info': dt.selected, active: isActive(dt)}"
                  ng-click="select(dt.date)"
                  ng-disabled="dt.disabled"
                  tabindex="-1">
                  <span ng-class="::{'text-info': dt.current}">{{::dt.label}}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        `
    )
  }
])

const datepickerPopupModule = angular.module('uib/template/datepickerPopup/popup.html', []).run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'uib/template/datepickerPopup/popup.html',
      `
        <ul
          class="uib-datepicker-popup uib-position-measure dropdown-menu datepicker"
          ng-if="isOpen"
          style="display: block"
          ng-keydown="keydown($event)"
          ng-click="$event.stopPropagation()">
           <li ng-transclude></li>
           <li class="button-row" ng-if="showButtonBar" style="padding:10px 9px 2px">
              <span class="btn-group pull-left">
                <button
                  type="button"
                  class="btn btn-sm btn-info uib-datepicker-current"
                  ng-click="select('today', $event)"
                  ng-disabled="isDisabled('today')">
                  {{ getText('current') }}
                </button>
              </span>
           </li>
        </ul>
        `
    )
  }
])

export const BootstrapDatepickerModule = angular.module('BootstrapDatepickerTemplates', [
  datepickerDayModule.name,
  datepickerMonthModule.name,
  datepickerYearModule.name,
  datepickerPopupModule.name
]).name
