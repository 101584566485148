export function Controller(DateFormat, session, dateformatFilter, dateTimeFormat) {
  'ngInject'

  const $ctrl = this

  $ctrl.dateformatFilter = dateformatFilter
  $ctrl.dateTimeFormat = dateTimeFormat

  $ctrl.datepickerOptions = {
    showWeeks: false,
    startingDay: 1,
    showButtons: true,
    customClass: getCustomClass
  }

  $ctrl.$onInit = () => {
    $ctrl.dateFormat = DateFormat.toUib(session.user.dateFormat)
    

  }

  this.valueChange = () => {
    this.onValueChange()
  }

  function getCustomClass(dateObject) {
    const today = new Date();
    const date = dateObject.date;
  
    return date.getFullYear() === today.getFullYear() &&
           date.getMonth() === today.getMonth() &&
           date.getDate() === today.getDate() ? 'highlight-today' : '';
  }

}
