import _map from 'lodash/map'

export function Controller(ErrorsModal, context, foldersData, gettextCatalog, page) {
  'ngInject'

  let vm = this
  let originalValues
  let s = {
    /// Input label
    folderName: gettextCatalog.getString('Folder name'),
    /// Input label
    description: gettextCatalog.getString('Description')
  }

  vm.control = {
    closeModal: closeModal,
    save: save
  }

  vm.$onInit = function () {
    vm.folder = vm.resolve.folder
    vm.memberPickerReady = false
    vm.saving = false
    vm.error = null
    vm.model = {
      name: vm.folder.name,
      description: vm.folder.description
    }
    vm.model.member_ids = null
    vm.formFields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          label: s.folderName,
          required: true
        }
      },
      {
        key: 'description',
        type: 'textareaExpanding',
        templateOptions: {
          label: s.description
        }
      }
    ]

    originalValues = {
      name: vm.folder.name,
      description: vm.folder.description,
      memberIds: null
    }

    getMemberIds()
  }

  function getMemberIds() {
    foldersData
      .getFolder(context.get('account'), vm.folder.id, { filter: false, include: 'members' })
      .then(function (folder) {
        originalValues.memberIds = _map(folder.members, 'id')
        vm.model.member_ids = _map(folder.members, 'id')
      })
      .catch((response) => {
        ErrorsModal.openModal(response.error)
      })
  }

  function save() {
    if (vm.form.$valid) {
      vm.saving = true
      vm.error = null

      foldersData
        .update(context.get('account'), vm.folder, vm.model, originalValues)
        .then(saveSuccess, saveError)
        .catch((response) => {
          ErrorsModal.openModal(response.error)
        })
    }
  }

  function saveSuccess(response) {
    let folderName = response && response.folder && response.folder.name

    vm.dismiss({ value: 'saved' })

    if (folderName) {
      page.setTitle('Folder')
    }
  }

  function saveError(rejection) {
    vm.saving = false
    vm.error = rejection.error

    if (vm.error === 'invalid_permission') {
      closeModal()
      ErrorsModal.openModal(vm.error)
    } else {
      ErrorsModal.openModal(vm.error)
    }
  }

  function closeModal() {
    vm.dismiss({ value: 'cancel' })
  }
}
