import angular from 'angular'

export function recursionHelper($compile) {
  'ngInject'

  let service = {
    compile: compile
  }
  return service

  ////////////////

  function compile(element, link) {
    // Normalize the link parameter
    if (angular.isFunction(link)) {
      link = { post: link }
    }

    // Break the recursion loop by removing the contents
    let contents = element.contents().remove()
    let compiledContents

    return {
      pre: link && link.pre ? link.pre : null,
      /**
       * Compiles and re-adds the contents
       */
      post: function (scope, element) {
        // Compile the contents
        if (!compiledContents) {
          compiledContents = $compile(contents)
        }
        // Re-add the compiled contents to the element
        compiledContents(scope, function (clone) {
          element.append(clone)
        })

        // Call the post-linking function, if any
        if (link && link.post) {
          link.post.apply(null, arguments)
        }
      }
    }
  }
}
