export function Controller($stateParams, usersData, Authenticate, $state) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    login: login,
    handleSSSOProviderClick: handleSSSOProviderClick
  }

  $ctrl.formModel = {
    username: $stateParams.email,
    password: null,
    rememberme: false
  }

  $ctrl.error = null
  $ctrl.submitting = false

  $ctrl.$onInit = () => {
    if ($state.current.name === 'invitations' && $stateParams.email) {
      $ctrl.hideEmailField = true
    }
  }

  function login() {
    if ($ctrl.loginForm.$valid) {
      $ctrl.submitting = true
      $ctrl.error = null

      Authenticate.attemptLogin($ctrl.formModel).then(loginSuccess).catch(loginError)
    }
  }

  function handleSSSOProviderClick(provider) {
    const stateId = Math.random().toString(36).substring(7)

    // Cache first name and last name
    localStorage.setItem(
      `invitation-${stateId}`,
      JSON.stringify({
        email: $stateParams.email,
        invitation_id: $stateParams.invitation_id
      })
    )

    window.location.href = provider.url + `&state=${stateId}`
  }

  function loginSuccess() {
    $ctrl.onLogin()
  }

  function loginError(error) {
    $ctrl.submitting = false
    $ctrl.loginForm.$setUntouched()
    $ctrl.loginForm.$setPristine()

    $ctrl.error = error || true
    $ctrl.formModel.password = null
  }
}
