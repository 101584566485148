/* tslint:disable:max-line-length */

import {
  mdiAccountBoxMultiple as accounts,
  mdiAndroid as android,
  mdiApple as apple,
  mdiArrowRight as arrowRight,
  mdiDragVertical as dragVertical,
  mdiPaperclip as attach,
  mdiBell as bell,
  mdiBookOpen as book,
  mdiCamera as camera,
  mdiCalendar as calendar,
  mdiCloseCircle as cancelCircle,
  mdiChat as chat,
  mdiChevronDownCircleOutline as circleDown,
  mdiClockOutline as clock,
  mdiClose as close,
  mdiCog as cog,
  mdiFormatColumns as columns,
  mdiCommentOutline as comment,
  mdiContentCopy as copy,
  mdiDelete as delete_,
  mdiDraw as draw,
  mdiMenuDown as downDir,
  mdiChevronDown as down,
  mdiTune as settings,
  mdiDownload as download,
  mdiDotsHorizontal as ellipsis,
  mdiSwapHorizontal as exchange,
  mdiExport as export_,
  mdiEyeOff as eyeOff,
  mdiEye as eye,
  mdiZipBox as fArchive,
  mdiFileMusic as fAudio,
  mdiFileDocument as fDoc,
  mdiFileExcel as fExcel,
  mdiFileImage as fImage,
  mdiFile as file,
  mdiFileOutline as fileOutline,
  mdiFilePdf as fPdf,
  mdiFilePowerpoint as fPowerpoint,
  mdiFileVideo as fVideo,
  mdiFileWord as fWord,
  mdiFilter as filter,
  mdiFolderOutline as folderEmpty,
  mdiFolderOpen as folderOpen,
  mdiFolder as folder,
  mdiSpeedometer as gauge,
  mdiShieldCheck as checkedGood,
  mdiHelpCircle as helpCircle,
  mdiHelp as help,
  mdiHistory as history,
  mdiHome as home,
  mdiImport as import_,
  mdiInformation as infoCircle,
  mdiInformationVariant as info,
  mdiKey as key,
  mdiChevronLeft as left,
  mdiArrowDownDropCircleOutline as levelDown,
  mdiArrowUpDropCircleOutline as levelUp,
  mdiLightbulbOnOutline as lightOn,
  mdiFormatListBulleted as list,
  mdiLock as lock,
  mdiLogout as logout,
  mdiEmail as mail,
  mdiCheckboxBlankCircle as markCom,
  mdiCheckboxBlankCircleOutline as markIncom,
  mdiHelpCircleOutline as missing,
  mdiMagnify as monitor,
  mdiCheck as ok,
  mdiAccountMultiple as owners,
  mdiPencil as edit,
  mdiPlus as plus,
  mdiCurrencyGbp as pound,
  mdiShieldLock as quarantine,
  mdiRefresh as refresh,
  mdiCloseOctagon as retired,
  mdiMenuRight as rightDir,
  mdiChevronRight as right,
  mdiRocket as rocket,
  mdiBarcodeScan as scan,
  mdiSortAscending as sortNameDown,
  mdiSortDescending as sortNameUp,
  mdiLoading as spin,
  mdiStarOutline as starEmpty,
  mdiStar as star,
  mdiTag as tag,
  mdiTagMultiple as tags,
  mdiAccountGroup as team,
  mdiDeleteOutline as trashEmpty,
  mdiUndoVariant as undo,
  mdiMenuUp as upDir,
  mdiChevronUp as up,
  mdiAccountPlus as userPlus,
  mdiAccount as user,
  mdiArrowExpand as expand,
  mdiFormatListChecks as checklist,
  mdiMapOutline as map,
  mdiMapMarker as mapMarker,
  mdiClipboardOutline as bucket,
  mdiClipboardPlusOutline as bucketAdd,
  mdiClipboardMinusOutline as bucketRemove,
  mdiClipboardRemoveOutline as bucketClear,
  mdiNfcSearchVariant as manageSearch,

  // Extra icons not in the design system
  mdiHelpRhombus as rhombus,
  mdiDotsVertical as ellipsisVertical,
  mdiText as text,
  mdiCheckCircle as checkCircle,
  mdiAlertCircle as errorCircle,
  mdiChevronUpCircleOutline as circleUp,
  mdiArrowDownDropCircle as downDropCircle,
  mdiArrowUpDropCircle as upDropCircle,
  mdiChevronDown as chevronDown,
  mdiChevronUp as chevronUp
} from '@mdi/js'

export {
  accounts,
  android,
  apple,
  arrowRight,
  attach,
  bell,
  book,
  camera,
  calendar,
  cancelCircle,
  chat,
  circleDown,
  clock,
  close,
  cog,
  columns,
  comment,
  copy,
  delete_,
  draw,
  downDir,
  down,
  dragVertical,
  settings,
  download,
  ellipsis,
  exchange,
  export_,
  eyeOff,
  eye,
  fArchive,
  fAudio,
  fDoc,
  fExcel,
  fImage,
  file,
  fileOutline,
  fPdf,
  fPowerpoint,
  fVideo,
  fWord,
  filter,
  folderEmpty,
  folderOpen,
  folder,
  gauge,
  checkedGood,
  helpCircle,
  help,
  history,
  home,
  import_,
  infoCircle,
  info,
  key,
  left,
  levelDown,
  levelUp,
  lightOn,
  list,
  lock,
  logout,
  mail,
  markCom,
  markIncom,
  missing,
  monitor,
  ok,
  owners,
  edit,
  plus,
  pound,
  quarantine,
  refresh,
  retired,
  rightDir,
  right,
  rocket,
  scan,
  sortNameDown,
  sortNameUp,
  spin,
  starEmpty,
  star,
  tag,
  tags,
  team,
  trashEmpty,
  undo,
  upDir,
  up,
  userPlus,
  user,
  expand,
  checklist,
  map,
  mapMarker,
  // Extra icons not in the design system
  rhombus,
  ellipsisVertical,
  text,
  checkCircle,
  errorCircle,
  circleUp,
  downDropCircle,
  upDropCircle,
  chevronDown,
  chevronUp,
  bucket,
  bucketAdd,
  bucketRemove,
  bucketClear,
  manageSearch
}

// Custom icons

export const searchCircle =
  'm12 2a10 10 0 0 0 -10 10 10 10 0 0 0 10 10 10 10 0 0 0 10 -10 10 10 0 0 0 -10 -10zm-1.2852 5a3.7143 3.7143 0 0 1 3.7129 3.7148c0 0.92-0.33633 1.7646-0.89062 2.416l0.1543 0.1543h0.45117l2.8574  2.8574-0.85742 0.85742-2.8574-2.8574v-0.45117l-0.1543-0.1543c-0.65144 0.55428-1.496 0.89062-2.416 0.89062a3.7143 3.7143 0 0 1 -3.7148 -3.7129 3.7143 3.7143 0 0 1 3.7148 -3.7148zm0 1.1426c-1.4286 0-2.5723 1.1437-2.5723 2.5723 0 1.4286 1.1437 2.5703 2.5723 2.5703 1.4286 0 2.5703-1.1417 2.5703-2.5703 0-1.4286-1.1417-2.5723-2.5703-2.5723z'
export const papertrail =
  'M10.39 3.764a8.24 8.24 0 0 0-7.305 12.312 8.24 8.24 0 0 0 7.157 4.162h6.515a5.25 5.25 0 0 0 1.457-10.294 8.24 8.24 0 0 0-7.824-6.18zM9.958 5.25c3.538-.148 6.6 2.46 6.993 5.98h-.005c2.046.093 3.643 1.805 3.592 3.853s-1.73 3.68-3.78 3.67h-6.515c-3.676.002-6.678-2.936-6.756-6.612s2.798-6.737 6.47-6.9zm.13 1.543a5.21 5.21 0 0 0 .151 10.415h6.514c1.212-.017 2.186-1.004 2.186-2.217s-.974-2.2-2.186-2.217h.003-1.307V12a5.21 5.21 0 0 0-5.36-5.208z'
export const callBack =
  'M12 2a10 10 0 1 0 6.161 17.88v1h.88v-2.64H16.4v.88h1.36c-1.617 1.315-3.636 2.036-5.72 2.04-5.048.01-9.148-4.072-9.16-9.12a9.14 9.14 0 1 1 18.282-.042 9.04 9.04 0 0 1-1.84 5.48l.68.52A10 10 0 0 0 11.999 2zm-1.32 5.28A1.52 1.52 0 0 0 9.16 8.8v6.44a1.52 1.52 0 0 0 1.52 1.52h2.678a1.52 1.52 0 0 0 1.52-1.52V8.8a1.52 1.52 0 0 0-1.52-1.519zM14 8.8v6.44a.63.63 0 0 1-.64.64h-2.68a.63.63 0 0 1-.64-.64V8.8a.63.63 0 0 1 .64-.64h2.68a.66.66 0 0 1 .64.64zm-1.44 5.96a.56.56 0 0 1-.846.501.56.56 0 0 1-.274-.501.56.56 0 0 1 .846-.501.56.56 0 0 1 .274.501'
export const mobile =
  'M6.727 2h10.545a.24.24 0 0 0 .064.038 1.22 1.22 0 0 1 .912 1.255v17.454a1.17 1.17 0 0 1-.45.97 3.37 3.37 0 0 1-.526.282H6.727c-.02-.015-.04-.028-.064-.038a1.22 1.22 0 0 1-.912-1.254V3.232a1.15 1.15 0 0 1 .448-.95A3.36 3.36 0 0 1 6.727 2zM17 5.138H7.016v13.726H17zm-5-1.262h2.103c.24 0 .376-.118.382-.304s-.136-.32-.384-.32H9.9c-.238 0-.376.118-.38.304s.136.3.384.322h2.103zm0 15.633a.93.93 0 0 0-.373 1.788.93.93 0 0 0 1.028-1.51.93.93 0 0 0-.655-.278z'
export const safety =
  'M15.125 13.25c.795-.001 1.578.198 2.277.576.126.068.24.15.36.227a15.18 15.18 0 0 0 1.738-6.88l-.004-.396a.42.42 0 0 0-.329-.392 12.28 12.28 0 0 1-6.587-3.938l-.266-.304a.41.41 0 0 0-.313-.141c-.12-.012-.236.04-.308.134l-.4.448A12.32 12.32 0 0 1 5.092 6.32l-.27.067a.41.41 0 0 0-.317.392c-.15 5.858 3.3 10.07 4.8 11.612.403.415.83.805 1.282 1.167a4.77 4.77 0 0 1 .646-4.322c.9-1.252 2.35-1.992 3.893-1.986zm1.88 1.3a3.96 3.96 0 1 0 2.079 3.483c.003-1.456-.797-2.795-2.08-3.483zm.15 2.956l-3.096 2.408L13 18.27c-.115-.193-.055-.443.134-.564s.44-.07.568.116l.564.883 2.375-1.848c.18-.14.443-.1.584.073s.1.443-.073.584z'
export const voucher =
  'M19.597 2.056a.45.45 0 0 0-.463.027l-2.167 1.474-2.247-1.48a.45.45 0 0 0-.494 0l-2.223 1.48-2.223-1.48a.45.45 0 0 0-.494 0l-2.248 1.48-2.17-1.48a.45.45 0 0 0-.463-.027c-.147.077-.24.23-.238.396V21.55a.45.45 0 0 0 .45.45h14.77a.45.45 0 0 0 .45-.45V2.447a.44.44 0 0 0-.238-.391zm-.66 19.05H5.065V3.3l1.717 1.17a.45.45 0 0 0 .499.004L9.528 3l2.225 1.478c.152.1.348.1.5 0L14.473 3l2.248 1.483a.45.45 0 0 0 .499-.004l1.717-1.17zM11.545 9.62c0-1.068-.865-1.933-1.933-1.933S7.68 8.553 7.68 9.62s.865 1.933 1.933 1.933 1.93-.866 1.933-1.933zm-2.962 0c0-.57.463-1.034 1.034-1.034s1.034.463 1.034 1.034-.463 1.034-1.034 1.034-1.033-.463-1.033-1.034zm5.8 2.837c-1.068 0-1.933.865-1.933 1.933s.865 1.933 1.933 1.933 1.933-.865 1.933-1.933-.866-1.93-1.933-1.933zm0 2.962c-.57 0-1.034-.463-1.034-1.034s.463-1.034 1.034-1.034 1.034.463 1.034 1.034-.463 1.034-1.034 1.034zm1.2-7.32a.45.45 0 0 0-.634.027L8.56 15.08a.45.45 0 0 0 .027.634c.083.077.192.12.306.117a.46.46 0 0 0 .333-.144L15.6 8.723c.082-.087.125-.203.12-.323a.44.44 0 0 0-.147-.31z'
export const iAuditor =
  'M30.694 37.499l-9.034 8.927-6.798-7.296c-.099.161-1.66-10.304 8.968-8.463l6.864 6.832zM46.683 22.058s10.848-3.365 8.968 8.313L39.438 46.426l-8.744-8.927 15.989-15.441 M21.66 46.426l5.209 5.26s3.937 3.064 7.537-.227l5.032-5.033-8.744-8.927-9.034 8.927z M30.694 37.499l2.297-2.128 7.629 9.884-1.182 1.171-8.744-8.927z'
  