import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'

export function formatsData(FormatModel, moment) {
  'ngInject'

  let cache: any = {}

  let service = {
    getFormats: getFormats
  }

  return service

  ////////////////

  function getFormats(type) {
    if (cache[type]) {
      return cache[type]
    }

    if (!cache.raw) {
      return FormatModel.$collection()
        .$fetch()
        .$asPromise()
        .then(function (formats) {
          cache[type] = _filter(formats, { type: type })

          _forEach(cache[type], function (format) {
            format.$display = format.rule + ' (' + moment().format(format.rule) + ')'
          })

          return cache[type]
        })
    }

    return cache[type]
  }
}
