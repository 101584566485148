export function Controller() {
  'ngInject'

  this.selectChecklist = () => {
    if (this.selectedChecklist) {
      this.onSelectChecklist({
        checklistId: this.selectedChecklist.id
      })

      this.selectedChecklist = null
    }
  }
}
