export function Controller(FieldsIdentifiers) {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {
    folderChange: folderChange,
    getRecordIdentifiers: getRecordIdentifiers,
    selectRecord: selectRecord
  }

  $ctrl.showFolders = true

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function () {}

  //////////////////////////////

  function getRecordIdentifiers(record) {
    let identifiers = FieldsIdentifiers.get(record.fields)

    return identifiers
  }

  function folderChange(event) {
    if (event.picked && event.picked.id) {
      $ctrl.showFolders = false
    } else {
      $ctrl.showFolders = true
    }

    $ctrl.onFolderChange({
      picked: event.picked
    })
  }

  function selectRecord(record) {
    $ctrl.onRecordSelected({
      $event: {
        record: record
      }
    })
  }
}
