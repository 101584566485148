import angular from 'angular'

export function Controller($scope, $uibModalStack, $timeout, filteringContext) {
  'ngInject'

  var $ctrl = this

  $ctrl.control = {
    showAll,
    tagClicked
  }

  $ctrl.displayTags = []
  $ctrl.additionalTags = 0

  $ctrl.$onInit = function () {
    $scope.$watch('$ctrl.tags', () => {
      prepareTags($ctrl.limit || $ctrl.tags.length)
    })
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.tags) {
      $ctrl.tags = angular.copy($ctrl.tags)
    }
  }

  function prepareTags(limit = $ctrl.tags.length) {
    $ctrl.displayTags = $ctrl.tags.slice(0, limit)
    $ctrl.additionalTags = Math.max(0, $ctrl.tags.length - limit)

    if ($ctrl.additionalTags === 1) {
      prepareTags(parseInt(limit, 10) + 1)
    }
  }

  function showAll($event) {
    $event.stopPropagation()
    prepareTags()
  }

  function tagClicked($event, tag) {
    $event.stopPropagation()
    $uibModalStack.dismissAll('navigate')
    $timeout(() => {
      filteringContext.applyFilter({ tag: tag.text }, 'folders')
    })
  }
}
