import _kebabCase from 'lodash/kebabCase'

export function Controller(localStorageService, ProductsList) {
  'ngInject'

  this.$onInit = () => {
    this.product = this.resolve.product
    this.getImageResizeUrl = this.resolve.onGetImageResizeUrl
    this.productManufacturer = _kebabCase(this.product.manufacturer)

    if(this.product.uniqueUuid) {
      ProductsList.getUnique(this.product.uniqueUuid).then((product) => {
        this.inputValue = (product && product.quantity) || 1
        this.existsInList = !!product
      })
    } else {
      this.inputValue = 1;
    }
  }

  this.add = () => {
    ProductsList.set(this.product, this.inputValue)
    this.dismiss()
  }

  this.getFilename = (url) => url.split('/').pop()

  this.handleQtyBlur = () => {
    this.inputValue = Number(this.inputValue) || 1
  }
}
