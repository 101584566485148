export function ProductsData($http, $q, ErrorsDisplay, ptApi, BASE_URL, API_VERSION) {
  'ngInject'

  const cache = {}

  const get = (ids) => {
    let req = {
      method: 'GET',
      url: BASE_URL + '/products?productIds=' + ids,
      headers: {
        Accept: API_VERSION
      }
    }
    let defer = $q.defer()

    if (cache[ids]) {
      defer.resolve(cache[ids])
    } else {
      $http(req)
        .then(function (response) {
          cache[ids] = response.data
          defer.resolve(response.data)
        })
        .catch(function (rejection) {
          defer.reject(rejection.data)
          ErrorsDisplay.catch('product_not_found').catch(function () {})
        })
    }

    return defer.promise
  }

  const search = (accountId, params, cancelToken) => {
    return ptApi
      .request({
        method: 'GET',
        url: `/accounts/${accountId}/products/search`,
        params,
        ...(cancelToken && { cancelToken })
      })
      .then((response) => {
        response.queryString = params.q

        return response
      })
      .catch(() => {
        //
      })
  }

  return {
    get,
    search
  }
}
