import { Controller } from './controller'
import view from './view.html'

export const ptUploadIcon = {
  bindings: {
    upload: '<'
  },
  controller: Controller,
  template: view
}
