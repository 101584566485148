export function ProfileModel(restmod) {
  'ngInject'

  let model = restmod
    .model(null)
    .mix('ApiBaseModel')
    .mix({
      invitations: { hasMany: 'InvitationModel' },
      summaries: { hasMany: 'SummaryModel' },
      processes: { hasMany: 'ProcessesModel' },
      notifications: { hasMany: 'NotificationsModel' }
    })

  return model
}
