export function Controller(permissionsCheck, foldersData, context, ErrorsModal, $uibModal) {
  'ngInject'

  let vm = this

  vm.control = {
    remove: remove,
    move: move
  }

  vm.account = context.get('account')
  vm.permissionsCheck = permissionsCheck

  vm.$onInit = function () {}

  function remove(folder) {
    foldersData.remove(vm.account, folder).catch((response) => {
      ErrorsModal.openModal(response.error)
    })
  }

  function move() {
    const editFolderModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptFolderMoveNew',
      resolve: {
        movedFolder: () => vm.folder
      }
    })
  }
}
