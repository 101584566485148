import _sortBy from 'lodash/sortBy'
import _some from 'lodash/some'

export function Controller($scope, $state, SubscriptionsData, accountsData, ErrorsModal) {
  'ngInject'

  let vm = this

  vm.control = {
    click: click
  }

  vm.accounts = null
  vm.loading = true
  vm.showPlaceholder = false

  /////////

  vm.$onInit = function () {
    getAccounts()

    listen()
  }

  function listen() {
    $scope.$on('account_was_created', function () {
      getAccounts()
    })
  }

  function getAccounts() {
    return SubscriptionsData.fetchSubscription(vm.subscriptionId, { include: 'accounts' }).then(function (
      subscription
    ) {
      vm.accounts = _sortBy(subscription.accounts, function (account) {
        return account.name.toLowerCase()
      })
      vm.loading = false

      if (_some(vm.accounts, { demo: false })) {
        vm.showPlaceholder = false
      } else {
        // Show a non-demo account placeholder
        vm.showPlaceholder = true
      }
    })
  }

  function click(account) {
    if (account.deactivated_at) {
      if (account.demo) {
        ErrorsModal.openModal('account_deactivated_demo')
      } else {
        ErrorsModal.openModal('account_deactivated')
      }
    } else {
      accountsData
        .hasAccount(account.id)
        .then(
          function () {
            $state.go('account', { accountId: account.id })
          },
          function () {
            ErrorsModal.openModal('user_does_not_belong_to_account')
          }
        )
        .catch(() => {})
    }
  }
}
