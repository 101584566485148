import view from './view.html'
import { Controller } from './controller'

export const ptMembersEdit = {
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  },
  template: view,
  controller: Controller
}
