import * as React from 'react'
import axios from 'axios'
import _assign from 'lodash/assign'
import _isArray from 'lodash/isArray'
import _debounce from 'lodash/debounce'

const CancelToken = axios.CancelToken
let searchRequest = {
  source: null
}

import * as productImportHelpers from 'AppCore/product/services'

export interface ptProductsSearchProps {
  accountId: string
  importing: boolean
  viewProduct: Function
  productImagesUrl: string
  pagination: {
    hasMorePages: Function
    nextPage: Function
  }
  ProductsData: {
    search: Function
  }
}

class ptProductsSearch extends React.Component<ptProductsSearchProps> {
  state = {
    canLoadMore: null,
    nextPage: null,
    searchResults: null,
    searchString: '',
    searchStringRequested: null,
    searchStringResponse: '',
    totalResults: null
  }

  searchInput = React.createRef<HTMLInputElement>()

  componentDidMount = () => {
    setTimeout(() => {
      this.searchInput.current.focus()
    })
  }

  clearSearch = (clearSearchString: boolean = false) => {
    if (this.props.importing) {
      return
    }

    // Cancel any pending search requests
    searchRequest.source && searchRequest.source.cancel()

    this.setState({
      searchResults: null,
      searchStringRequested: null,
      searchStringResponse: '',
      nextPage: null,
      totalResults: null
    })

    if (clearSearchString) {
      this.setState({
        searchString: ''
      })
    }
  }

  getImageResizeUrl = (url: string) =>
    url && url.replace(new RegExp(appConfig.productImages.replace), `${this.props.productImagesUrl}/50x50`)

  handleSearch = (e) => {
    const searchString = e.target.value

    if (this.props.importing) {
      return
    }

    this.setState({ searchString })

    // Because there is a new search value, cancel any current searches
    this.clearSearch()

    if (searchString.length > 2) {
      // Create a new source to reference later, if needed.
      searchRequest.source = CancelToken.source()

      this.searchDebounced({ q: searchString }, searchRequest.source.token)
    }
  }

  loadMoreProducts = () => {
    this.search({ q: this.state.searchString }, null)
  }

  search = (params, cancelToken) => {
    const queryParams = _assign(
      {
        limit: 25,
        page: this.state.nextPage || 1
      },
      params
    )

    this.setState({
      searchStringRequested: params.q
    })

    this.props.ProductsData.search(this.props.accountId, queryParams, cancelToken)
      .then((response) => {
        this.setState({
          searchStringRequested: null,
          searchStringResponse: response.queryString,
          nextPage: this.props.pagination.nextPage(response),
          totalResults: response.meta.pagination.total
        })

        // Load more results
        if (_isArray(this.state.searchResults)) {
          this.setState({
            searchResults: this.state.searchResults.concat(response.data)
          })
        }
        // New search
        else {
          this.setState({
            searchResults: response.data
          })
        }

        response.data.forEach(productImportHelpers.generateHighlightedValues)

        if (this.props.pagination.hasMorePages(response)) {
          this.setState({
            canLoadMore: true
          })
        } else {
          this.setState({
            canLoadMore: false
          })
        }
      })
      .catch(() => {})
  }

  searchDebounced = _debounce(this.search, 1000)

  render() {
    return (
      <>
        <label className="control-label">Search products</label>
        <div className="searchForm">
          <div className="searchInputWrapper">
            <div className="searchInputIcon">
              <svg className="pt-icon" viewBox="0 0 26 28">
                {/* icon-search */}
                <title>Search</title>
                <path d="M18 13c0-3.859-3.141-7-7-7s-7 3.141-7 7 3.141 7 7 7 7-3.141 7-7zM26 26c0 1.094-0.906 2-2 2-0.531 0-1.047-0.219-1.406-0.594l-5.359-5.344c-1.828 1.266-4.016 1.937-6.234 1.937-6.078 0-11-4.922-11-11s4.922-11 11-11 11 4.922 11 11c0 2.219-0.672 4.406-1.937 6.234l5.359 5.359c0.359 0.359 0.578 0.875 0.578 1.406z" />
              </svg>
            </div>
            <div>
              <input
                type="text"
                ref={this.searchInput}
                className={`form-control searchInput ${this.props.importing ? 'disabled' : ''}`}
                onChange={this.handleSearch}
                value={this.state.searchString}
                disabled={this.props.importing}
              />
            </div>
            {this.state.searchString.length > 0 && (
              <div className="searchInputClear" onClick={() => this.clearSearch(true)}>
                <svg className="pt-icon" viewBox="0 0 10.629 10.628">
                  {/* search-cancel */}
                  <path
                    fill="#213854"
                    d="M1.181 0L0 1.181l4.134 4.134L0 9.448l1.181 1.181 4.134-4.133 4.134 4.133 1.181-1.181-4.134-4.133 4.134-4.134L9.448 0 5.314 4.134z"
                  />
                </svg>
              </div>
            )}
          </div>
          {(this.state.searchStringRequested || this.state.searchResults) && (
            <div className="searchResultsWrapper">
              {this.props.importing && (
                <div className="p-a text-center">
                  <span us-spinner className="m-b-" />
                  <p className="m-b-0">Importing. Please wait.</p>
                </div>
              )}
              {this.state.searchResults && this.state.searchResults.length === 0 && (
                <div className="p-a">
                  <p className="m-b-0">
                    We couldn't find anything matching <strong>{this.state.searchStringResponse}</strong>
                  </p>
                </div>
              )}
              {!this.props.importing && this.state.searchResults && this.state.searchResults.length > 0 && (
                <div className="searchResultsInfo">
                  <p className="m-b-0">
                    Showing {this.state.searchResults.length} of {this.state.totalResults} results
                  </p>
                </div>
              )}
              {!this.props.importing && (
                <div className="searchResults">
                  {this.state.searchResults &&
                    this.state.searchResults.length > 0 &&
                    this.state.searchResults.map((product) => (
                      <div
                        key={product.id}
                        onClick={() => this.props.viewProduct(product, this.clearSearch)}
                        className="searchResult"
                      >
                        <div className="searchResultIcon">
                          {product.images[0] && (
                            <img
                              src={this.getImageResizeUrl(product.images[0])}
                              alt={product.name}
                              className="searchResultImg"
                            />
                          )}
                        </div>
                        <div className="searchResultName">
                          {product.manufacturer && (
                            <p className="m-b-0">
                              <small
                                dangerouslySetInnerHTML={{ __html: product.manufacturerHtml || product.manufacturer }}
                              />
                            </p>
                          )}
                          <p
                            className="productNameText"
                            dangerouslySetInnerHTML={{ __html: product.nameHtml || product.name }}
                          />
                          {product.internal_identifiers.length && (
                            <div>
                              <small>{product.internal_identifiers.join(', ')}</small>
                            </div>
                          )}
                          <div>
                            {product.matches.data.map(
                              (match, index) =>
                                match.html && (
                                  <small key={index} className={product.matches.data - 1 === index ? `` : `m-r-`}>
                                    {match.label}: <span dangerouslySetInnerHTML={{ __html: match.html }} />
                                  </small>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="p-x">
                    {this.state.searchStringRequested && (
                      <div>
                        <p className="p-y m-b-0">Please wait...</p>
                      </div>
                    )}
                    {this.state.searchResults && !this.state.searchStringRequested && this.state.canLoadMore && (
                      <div>
                        <a className="btn btn-secondary m-y" onClick={this.loadMoreProducts}>
                          Load more
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    )
  }
}

export default ptProductsSearch
