export function ptProfileValidateRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('profile.validate', {
    url: '/validate?code',
    views: {
      'content@layoutFull': {
        component: 'ptProfileValidate'
      }
    }
  })
}
