import moment from 'moment-timezone'

export const ChecklistsSentiment = () => {
  'ngInject'

  const getSentiment = (field) => {
    if (field.sentiment) {
      return typeof field.sentiment === 'boolean' ? 1 : parseInt(field.sentiment)
    } else if (field.type === 'date') {
      return getDateSentiment(field)
    } else if (field.type === 'boolean') {
      return getBooleanSentiment(field)
    } else if (field.type === 'string') {
      return getOptionsSentiment(field)
    } else if (field.type === 'textarea') {
      return getTextareaSentiment(field)
    } else if (field.type === 'sigfile') {
      return getSignatureSentiment(field)
    } else if (field.type === 'genfile' || field.type === 'imgfile') {
      return getFileSentiment(field)
    }
    else {
      return 0
    }
  }

  const getBooleanSentiment = (field) => {
    return field.value && (field.value === 'true' || field.value === true) ? 1 : 0
  }

  const getOptionsSentiment = (field) => {
    
    if (field.value && field.options) {
      const option = field.options.find((option) => option && option.value === field.value)
      if (option) {
        return parseInt(option.sentiment)
      }
    }
    return 0
  }

  const getDateSentiment = (field) => {
    if (field.value) {
      return moment(field.value).isBefore(moment(), 'day') ? 0 : 1
    }
    return 0
  }

  const getTextareaSentiment = (field) => {
      return field.value ? 1 : 0
  }

  const getSignatureSentiment = (field) =>  {
    
    if ( (field?.files || field?.responseUploads) ?? 0)
     {
      return true;
    } else {
      return false;
    }
  }
  

  const getFileSentiment = (field) => {
    if ((field?.files || field?.responseUploads) ?? 0)
    {
      return true;
    } else {
      return false;
    }
  }

  return {
    getSentiment
  }
}
