import { Controller } from './controller'
import view from './view.html'

export const ptInvitationsPublicOAuthRedirect = {
  template: view,
  controller: Controller,
  bindings: {
    $transition$: '<',
    invitations: '<',
    loggedInUser: '<',
    ssoProviders: '<'
  }
}
