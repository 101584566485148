import * as React from 'react'
import { Icon } from '../../components'
import { createStyledView } from '../../components/styled'
import { spacing, colors } from '../../styles'
import { Text, View } from 'reactxp'
import { getPlatformStyle } from '../../services/platform'
import { User } from '../../user'
import { MemberSelector } from '../../member/components'

export interface TaskAssigneesProps {
  users: User[]
  onPressAssignees: () => void
  onUserUpdated: (user: User, isSelected: boolean) => void
  iconSize?: number
  editable: boolean
}

const ChangeAssignees = createStyledView({
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'flex-start',
  marginBottom: spacing.half,

  ...getPlatformStyle({
    web: {
      cursor: 'pointer'
    }
  })
})

const AddAssigneeIcon = createStyledView({
  minWidth: spacing.double,
  marginRight: spacing.half
})

const AddAssigneeText = createStyledView({
  flex: 1
})

const AddAssigneeAvatar = createStyledView({
  flex: 1,
  flexDirection: 'row',
  backgroundColor: colors.neutralTintSix,
  borderRadius: 20,
  alignItems: 'center',
  justifyContent: 'center'
})

export class TaskAssignees extends React.Component<TaskAssigneesProps> {
  public userUpdated = (user: User, isSelected: boolean) => {
    this.props.onUserUpdated(user, isSelected)
  }

  public render() {
    const iconSize = this.props.iconSize || 40
    const { editable } = this.props

    return (
      <>
        {editable && (
          <ChangeAssignees onPress={this.props.onPressAssignees} editable={editable}>
            <AddAssigneeIcon>
              <AddAssigneeAvatar style={{ width: iconSize, height: iconSize }}>
                <Icon name="userPlus" size={iconSize / 2} />
              </AddAssigneeAvatar>
            </AddAssigneeIcon>
            <AddAssigneeText>
              <Text
                style={{
                  color: colors.secondary,
                  textDecorationColor: colors.secondary,
                  textDecorationLine: 'underline'
                }}>
                Add/Remove assignees
              </Text>
            </AddAssigneeText>
          </ChangeAssignees>
        )}
        {this.props.users &&
          !!this.props.users.length &&
          this.props.users.map((user) => (
            <View key={user.id} style={{ marginBottom: spacing.half }}>
              <MemberSelector user={user} isSelected={true} onUpdateUser={this.userUpdated} editable={editable} />
            </View>
          ))}
      </>
    )
  }
}
