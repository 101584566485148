export function ptResolvingIndicator($timeout, ngProgressFactory) {
  'ngInject'

  let directive = {
    link: link,
    restrict: 'A'
  }

  return directive

  function link(scope) {
    let progressbar = ngProgressFactory.createInstance()
    let promise = null
    let running = false

    progressbar.setColor('#C61A28')

    scope.$on('routeResolver:resolving', function () {
      if (promise) {
        $timeout.cancel(promise)
        promise = null
      }

      running = false
      progressbar.reset()

      promise = $timeout(function () {
        progressbar.start()
        running = true
      }, 750)
    })

    scope.$on('routeResolver:resolved', function () {
      if (promise) {
        $timeout.cancel(promise)
        promise = null

        if (running) {
          progressbar.complete()
          running = false
        }
      }
    })
  }
}
