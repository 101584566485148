import _cloneDeep from 'lodash/cloneDeep'
import { PapertrailSession } from '@papertrail/web3-session'

export function Controller($state, session, usersData, gettextCatalog) {
  'ngInject'

  let vm = this
  let initialUserFields = _cloneDeep(session.user)
  let s = {
    /// Field label
    firstName: gettextCatalog.getString('First name'),
    /// Field label
    lastName: gettextCatalog.getString('Last name'),
    /// Field label
    username: gettextCatalog.getString('Username'),
    /// Field label
    emailAddress: gettextCatalog.getString('New email address'),
    /// Field label
    phoneNumber: gettextCatalog.getString('Telephone number'),
    /// Field label
    country: gettextCatalog.getString('Country'),
    /// Field label
    language: gettextCatalog.getString('Language'),
    /// Field label
    timezone: gettextCatalog.getString('Timezone'),
    /// Field label
    dateFormat: gettextCatalog.getString('Date format'),
    /// Field label
    timeFormat: gettextCatalog.getString('Time format'),
    /// Checkbox label
    displayRelative: gettextCatalog.getString('Display relative dates (i.e. 3 weeks ago)'),
    /// Field label
    password: gettextCatalog.getString('Password')
  }

  vm.control = {
    save: save,
    saveEmail: saveEmail
  }

  vm.crumbs = [
    {
      /// Bread crumb
      name: gettextCatalog.getString('Accounts'),
      link: 'accounts'
    }
  ]
  vm.self = gettextCatalog.getString('Profile')

  vm.saving = false
  vm.user = session.user
  vm.userFields = [
    {
      key: 'firstName',
      type: 'input',
      templateOptions: {
        label: s.firstName,
        required: true
      }
    },
    {
      key: 'lastName',
      type: 'input',
      templateOptions: {
        label: s.lastName,
        required: true
      }
    },
    {
      key: 'username',
      type: 'input',
      templateOptions: {
        label: s.username
      }
    },
    {
      key: 'phoneNumber',
      type: 'input',
      templateOptions: {
        label: s.phoneNumber
      }
    },
    {
      key: 'countryCode',
      type: 'select',
      templateOptions: {
        label: s.country,
        required: true,
        options: vm.countries,
        ngOptions: 'country.alpha_2 as country.name for country in to.options'
      }
    },
    {
      key: 'timezone',
      type: 'select',
      templateOptions: {
        label: s.timezone,
        required: true,
        options: vm.timezones,
        ngOptions: 'timezone.name as timezone.formattedName for timezone in to.options'
      }
    },
    // formats = filter = type = date || time
    {
      key: 'dateFormat',
      type: 'select',
      templateOptions: {
        label: s.dateFormat,
        required: true,
        options: vm.dateFormats,
        ngOptions: 'format.rule as format.$display for format in to.options'
      }
    },
    {
      key: 'timeFormat',
      type: 'select',
      templateOptions: {
        label: s.timeFormat,
        required: true,
        options: vm.timeFormats,
        ngOptions: 'format.rule as format.$display for format in to.options'
      }
    },
    {
      key: 'displayDateRelative',
      type: 'checkbox',
      templateOptions: {
        label: s.displayRelative
      }
    }
  ]

  vm.userEmailFields = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: s.emailAddress,
        required: true
      }
    },
    {
      key: 'password',
      type: 'input',
      templateOptions: {
        label: s.password,
        type: 'password',
        required: true,
        minlength: 6
      }
    }
  ]

  vm.error = null
  vm.errorMessages = []
  vm.emailError = null
  vm.success = false

  //////////

  function save() {
    let changedFields = {}
    let userBasicFields = [
      'firstName',
      'lastName',
      'username',
      'phoneNumber',
      'timezone',
      'countryCode',
      'dateFormat',
      'timeFormat',
      'displayDateRelative'
    ]

    if (vm.form.$valid) {
      vm.saving = true
      vm.error = null
      vm.errorMessages = []
      vm.success = false

      userBasicFields.forEach(function (property) {
        if (initialUserFields[property] !== vm.user[property]) {
          changedFields[property] = vm.user[property]
        }
      })

      if (Object.keys(changedFields).length) {
        usersData
          .update(vm.user, changedFields)
          .then(saveSuccess, saveError)
          .finally(function () {
            vm.saving = false
          })
      } else {
        vm.saving = false
        vm.error = true
        vm.errorMessages = ['No changes detected.']
      }
    }
  }

  function saveEmail() {
    if (vm.emailForm.$valid) {
      vm.saving = true
      vm.error = null
      vm.errorMessages = []

      vm.success = false
      usersData
        .changeEmail(vm.user)
        .then(saveSuccess, saveEmailError)
        .finally(function () {
          vm.saving = false
          vm.user.password = ''
        })
    }
  }

  function saveSuccess() {
    vm.success = true
    initialUserFields = _cloneDeep(session.user)
    PapertrailSession.loadUser()
    //$state.forceReload()
  }

  function saveError(errorData) {
    let error

    vm.saving = false
    vm.error = errorData.message

    // Get all individual error messages.
    for (error in errorData.data) {
      if (errorData.data[error].length) {
        errorData.data[error].forEach(function (errorItem) {
          vm.errorMessages.push(errorItem.message)
        })
      }
    }
  }

  function saveEmailError(rejection) {
    vm.saving = false
    vm.emailError = rejection.data.error
  }
}
