import { Controller } from './controller'
import view from './view.html'

export const ptRecordsDetail = {
  bindings: {
    record: '<',
    subRows: '<',
    isAdmin: '<'
  },
  controller: Controller,
  template: view
}
