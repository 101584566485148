/**
 * Service created for dynamic code splitting of emojione
 * Because it is a big dependency
 */
export function emojiService() {
  'ngInject'

  let emojione

  let service = {
    init: init,
    get: get
  }

  return service

  function get() {
    return emojione
  }

  function init() {
    if (!emojione) {
      import('emojione')
        .then(({ default: result }) => {
          emojione = result
        })
        .catch((err) => {
          console.log('error loading emojione')
        })
    }
  }
}
