import _includes from 'lodash/includes'
import _last from 'lodash/last'
import _uniqBy from 'lodash/uniqBy'
import _isArray from 'lodash/isArray'
import _flattenDeep from 'lodash/flattenDeep'

export function Controller($scope, ActivitiesData, ActivitiesDeactivated, pagination, context, session) {
  'ngInject'

  let vm = this
  let limit = 30
  const getDefaultParams = () => ({
    limit: limit,
    record_id: vm.record.id,
    include:
      'checklist_completions,checklist_completions.sections.fields.uploads,checklist_completions.sections.fields.response_uploads,partial_completions,partial_completions.sections.fields.uploads,partial_completions.sections.fields.response_uploads'
  })
  let params

  vm.control = {
    addActivities: addActivities
  }

  vm.show = show

  vm.activityOptions = {
    view: 'simple',
    isRecord: true,
    compact: false
  }

  function reset() {
    vm.activities = []
    vm.organisedActivities = []
    vm.canFetchMore = {}
    vm.fetching = false

    params = getDefaultParams()
  }

  vm.$onInit = function () {
    reset()

    $scope.$on('activity_was_created', activityWasCreated)
    $scope.$on('note_was_created', noteLogWasCreated)
    $scope.$on('log_was_created', noteLogWasCreated)
    $scope.$on('log_was_updated', noteLogWasCreated)

    $scope.$watch('vm.type', function () {
      show(getTypes(vm.type))
    })

    $scope.$watch('vm.organisedActivities', function (organisedActivities) {
      if (organisedActivities) {
        const createdAndUpdatedActivities: any = _flattenDeep(organisedActivities).filter((a: any) => ['log_was_created', 'log_was_updated'].includes(a.type));
        if (createdAndUpdatedActivities.length) {
          vm.activityIdEditEnabled = createdAndUpdatedActivities[0].id;
        }
      }
    })
  }

  function getTypes(type) {
    if (type === 'logs') {
      return ActivitiesData.getActivityGroup('record_log')
    }

    if (type === 'notes') {
      return ActivitiesData.getActivityGroup('record_note')
    }

    if (type === 'tasks') {
      return ['task_was_created', 'task_state_was_updated']
    }

    if (type === 'certificates') {
      return ['certificate_was_created', 'service_certificate_was_created']
    }

    return null
  }

  function initActivitiesData(types?) {
    let includeEventGroups = ['record', 'record_note', 'record_log', 'task', 'service']

    let ignoreEvents = [
      'record_moved_from_folder',
      'record_moved_to_account',
      'task_assignee_was_added',
      'task_assignee_was_removed',
      'task_upload_was_created',
      'task_upload_was_deleted',
      'task_was_updated',
      'task_was_assigned_to_log',
      'task_was_unassigned_from_log'
    ]

    if (_isArray(types) && types.length) {
      params.type = types.join(',')
    }

    return ActivitiesData.init(context.get('account').id, includeEventGroups, ignoreEvents, params)
  }

  function show(types) {
    reset()

    vm.activities = initActivitiesData(types)

    addActivities()
  }

  function activityWasCreated(event, data) {
    let ignore = [
      'record_was_created',
      'record_was_imported',
      'record_was_cloned',
      'task_assignee_was_added',
      'task_assignee_was_removed',
      'task_upload_was_created',
      'task_upload_was_deleted',
      'task_was_updated',
      'task_was_assigned_to_log',
      'task_was_unassigned_from_log'
    ]

    ignore = ignore.concat(ActivitiesData.getActivityGroup('task_note'))

    // Check this activity is referring to the current record
    if (data.data.record && data.data.record.id === vm.record.id) {
      if (!_includes(ignore, data.data.type)) {
        ActivitiesData.get(data.data.account.id, data.data.id, {
          include: params.include
        }).then((response) => {
          vm.activities.unshift(response)
          organise(ActivitiesDeactivated.flag(vm.activities))
        })
      }
    }
  }

  function noteLogWasCreated(event, data) {
    let recentActivities
    // Deal with local events only
    if (!data.isRemote) {
      // Check this activity is referring to the current record
      if (data.recordId && data.recordId === vm.record.id) {
        recentActivities = initActivitiesData()
        ActivitiesData.getNext(recentActivities).then(function () {
          vm.activities = recentActivities

          organise(ActivitiesDeactivated.flag(vm.activities))
        })
      }
    }
  }

  function addActivities() {
    vm.fetching = true

    ActivitiesData.getNext(vm.activities).then(success)
  }

  function success(results) {
    ActivitiesData.debugActivities(vm.activities)

    vm.canFetchMore = pagination.hasMorePages(results)
    vm.fetching = false

    organise(ActivitiesDeactivated.flag(vm.activities))
  }

  function organise(activities) {
    vm.organisedActivities = ActivitiesData.groupUsers(ActivitiesData.groupDates(_uniqBy(activities, 'id')))

    if (!vm.canFetchMore && vm.organisedActivities.length) {
      // mark the last group of activities as such so we can display it differently
      ;(_last(_last(vm.organisedActivities)) as any).$last = true
    }
  }
}
