import { Controller } from './controller'
import view from './view.html'

export const ptUsersRegisterForm = {
  bindings: {
    onRegister: '&',
    ssoProviders: '<',
    credentialType: '<'
  },
  controller: Controller,
  template: view
}
