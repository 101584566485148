export function Controller($scope, lookupsData) {
  'ngInject'

  let fetchOptions = {
    all: true,
    limit: 100
  }

  lookupsData.fetch($scope.options.data.field.source.key, '', fetchOptions).then(function (results) {
    $scope.to.options = results.map(function (result) {
      return {
        value: result.value,
        label: result.value
      }
    })

    $scope.to.options.push({
      value: '',
      label: '(No Selection)'
    })
  })
}
