import angular from 'angular'

export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {}

  $ctrl.extraAssignees = 0
  $ctrl.displayAssignees = []

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.assignees) {
      $ctrl.assignees = angular.copy($ctrl.assignees)

      checkTaskAssignees()
    }
  }

  function checkTaskAssignees() {
    let limit
    let assignees = []

    if ($ctrl.assignees.length > Number($ctrl.assigneeLimit) + 1) {
      limit = Number($ctrl.assigneeLimit)
      $ctrl.extraAssignees = $ctrl.assignees.length - Number($ctrl.assigneeLimit)
    } else {
      limit = $ctrl.assignees.length
      $ctrl.extraAssignees = 0
    }

    for (let i = 0; i < limit; i = i + 1) {
      assignees.push($ctrl.assignees[i])
    }

    $ctrl.displayedAssignees = assignees
  }
}
