export function Controller(
  $state,
  $stateParams,
  Authenticate,
  AuthenticateRedirect,
  invitationsForUser,
  gettextCatalog,
  usersData
) {
  'ngInject'

  const $ctrl = this

  /**
   * null = Accepting
   * accepted = Accepted
   * error = Error
   */
  $ctrl.acceptedState = null
  $ctrl.acceptedStateMessage = 'Accepting invite. Please wait...'

  $ctrl.$onInit = () => {
    const params = $ctrl.$transition$.params()
    const authorisationCode = params.code
    const state = params.state
    let invitationFormData = localStorage.getItem(`invitation-${state}`)

    $ctrl.ssoProviders = $ctrl.ssoProviders.map((provider) => {
      return {
        name: provider.name,
        image: provider.image,
        url: provider.url
      }
    })

    if (!invitationFormData) {
      $ctrl.acceptedState = 'error'
      $ctrl.acceptedStateMessage = 'Invalid invitation'
    }

    invitationFormData = JSON.parse(invitationFormData)

    const formModel = {
      confirmation_code: invitationFormData['confirmation_code'],
      terms: invitationFormData['terms'],
      first_name: invitationFormData['first_name'],
      last_name: invitationFormData['last_name'],
      email: invitationFormData['email'],
      authorisation_code: authorisationCode,
      platform: 'portal-invitation'
    }

    usersData.create(formModel).then(
      (success) => {
        formModel['username'] = formModel.email

        $ctrl.acceptedState = 'accepted'
        $ctrl.acceptedStateMessage = 'Invite successfully accepted. Please login in below to continue.'

        localStorage.removeItem(`invitation-${state}`)
      },
      (error) => {
        $ctrl.acceptedState = 'error'
        $ctrl.acceptedStateMessage = 'Error accepting invite.'

        // This is duplicated in case we want to do something different
        localStorage.removeItem(`invitation-${state}`)
      }
    )
  }

  $ctrl.accept = () => {}
}
