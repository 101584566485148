const mimeTypeIcon = (mime) => {
  let substr = mime && mime.substr(0, 5)
  let icons = {
    archive: 'https://cdn.papertrail.io/images/icons/file-archive.svg',
    audio: 'https://cdn.papertrail.io/images/icons/file-audio.svg',
    defaultFile: 'https://cdn.papertrail.io/images/icons/file-default.svg',
    excel: 'https://cdn.papertrail.io/images/icons/file-excel.svg',
    image: 'https://cdn.papertrail.io/images/icons/file-image.svg',
    pdf: 'https://cdn.papertrail.io/images/icons/file-pdf.svg',
    powerpoint: 'https://cdn.papertrail.io/images/icons/file-powerpoint.svg',
    video: 'https://cdn.papertrail.io/images/icons/file-video.svg',
    word: 'https://cdn.papertrail.io/images/icons/file-word.svg'
  }
  let icon = 'defaultFile'

  if (substr === 'image' || substr === 'video' || substr === 'audio') {
    icon = substr
  } else {
    if (mime === 'application/zip') {
      icon = 'archive'
    }

    if (mime === 'application/pdf') {
      icon = 'pdf'
    }

    if (
      mime === 'application/msword' ||
      mime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      icon = 'word'
    }

    if (
      mime === 'application/vnd.ms-excel' ||
      mime === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      icon = 'excel'
    }

    if (
      mime === 'application/vnd.ms-powerpoint' ||
      mime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      icon = 'powerpoint'
    }
  }

  return icons[icon]
}

export function link(scope, element) {
  let icon = mimeTypeIcon(scope.mime)

  element.append(`<img src="${icon}" class="upload-thumbnails__mime">`)
}
