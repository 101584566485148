import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _pick from 'lodash/pick'
import _sortBy from 'lodash/sortBy'
import _find from 'lodash/find'
import _cloneDeep from 'lodash/cloneDeep'

const _addCoreColumn = (accountId, coreField, columns) => {
  const definition: any = _find(_currentVisibleColumns[accountId], { key: coreField.key })

  if (definition && definition.visibility) {
    definition.$coreColumn = true
    columns.push(definition)
  }
}

const _addFieldColumn = (definition, coreProperties, columns) => {
  if (definition.visibility && !_find(coreProperties, { key: definition.key })) {
    definition.$coreColumn = false
    columns.push(definition)
  }
}

const _corePropertiesStart = [
  { label: 'Image', key: 'cover_image' },
  { label: 'Name', key: 'name' }
]

const _corePropertiesEnd = [
  { label: 'Frequency', key: 'frequency' },
  { label: 'State', key: 'state' },
  { label: 'Notify', key: 'notify_at' },
  { label: 'Next check', key: 'next_check_at' },
  { label: 'Created', key: 'created_at' },
  { label: 'Shared', key: 'partner' }
]

const _coreSubordinate = [
  { label: 'Last Inspection Comments', key: 'log.comments', type: 'string' },
  { label: 'Last Inspection Uploads', key: 'log.uploads', type: 'array' },
  { label: 'Last Inspection Checklists', key: 'log.checklist_completions', type: 'array' }
]

const _currentVisibleColumns = {}
const _currentSort = {}
let _allColumns = []

export function DefinitionsData(EventsData, context, localStorageService, session) {
  'ngInject'

  const clearAllColumns = () => {
    _allColumns = []
  }

  const getAllColumns = () => {
    return _allColumns
  }

  const getCoreProperties = () => {
    return _corePropertiesStart.concat(_corePropertiesEnd).concat(_coreSubordinate)
  }

  const getSubordinate = () => {
    return _filter(_currentVisibleColumns[context.get('account').id], (definition: any): any =>
      _find(_coreSubordinate, { key: definition.key })
    )
  }

  const getSortExcludes = () => ['cover_image', 'log.comments', 'log.uploads', 'log.checklist_completions', 'state']

  const getVisibleColumnsAsArray = () => {
    return _currentVisibleColumns[context.get('account').id]
  }

  const getVisibleColumnsAsKeys = () => {
    const columnKeys = getVisibleColumnsOnly().map((column) => column.key)

    getSubordinate().forEach((subordinate: any) => {
      if (_get(subordinate, 'key')) {
        columnKeys.push(subordinate.key)
      }
    })

    return columnKeys.join(',')
  }

  const getVisibleColumnsAsSortKeys = () => {
    const columns = _sortBy(_currentSort[context.get('account').id], 'order')
    const sortFields = []

    columns.forEach((column) => {
      if (column.direction === 'ASC') {
        sortFields.push(column.key)
      } else {
        sortFields.push('-' + column.key)
      }
    })

    return sortFields.join(',')
  }

  // Return just the columns without subordinates for displaying the records table.
  const getVisibleColumnsOnly = () => {
    const accountId = context.get('account').id
    const columns = []

    _corePropertiesStart.forEach((coreField) => {
      _addCoreColumn(accountId, coreField, columns)
    })

    _currentVisibleColumns[accountId].forEach((definition) => {
      _addFieldColumn(definition, getCoreProperties(), columns)
    })

    _corePropertiesEnd.forEach((coreField) => {
      _addCoreColumn(accountId, coreField, columns)
    })

    return columns
  }

  const recalculateSortOrders = () => {
    // First, we need to only get the columns that are being used to sort
    const columnsUsedForSorting = _allColumns.filter((col) => {
      if (!getSortExcludes().includes(col.key)) {
        return col.order
      }
    })

    // Then we need to sort them by their order...
    const orderedColumnsUsedForSorting = _sortBy(columnsUsedForSorting, 'order')

    // ...so we can apply the index correctly from scratch
    orderedColumnsUsedForSorting.forEach((column, index) => {
      column.order = index + 1
    })
  }

  const removeVisibleColumnsDefault = () => {
    localStorageService.remove(`definitions-${context.get('account').id}-${session.user.id}`)

    setVisibleColumns({
      definitions: context.get('account').folder.definitions,
      recalculateOrders: true
    })

    EventsData.broadcast('definitions:changed')
  }

  const setAllColumns = (columns) => {
    _allColumns = columns
  }

  const setVisibleColumns = ({ definitions, setDefault = false, recalculateOrders = false, setSort = true }) => {
    const sortedDefinitions = _sortBy(definitions, 'position')

    _currentVisibleColumns[context.get('account').id] = sortedDefinitions.map((definition) => {
      return _pick(definition, ['key', 'name', 'subordinate', 'visibility', 'order', 'direction', 'type', 'position'])
    })

    setVisibleColumnsSort(sortedDefinitions)

    if (setDefault) {
      setVisibleColumnsDefault()
    }

    if (recalculateOrders) {
      recalculateSortOrders()
    }

    EventsData.broadcast('definitions:changed')
  }

  const setVisibleColumnsSort = (definitions) => {
    // Get all definitions that have a truthy 'order' property (and aren't subordinates)
    const definitionsFiltered = _filter(definitions, (def) => def.order && !def.subordinate)

    // Set all columns with an 'order' as the current sort
    _currentSort[context.get('account').id] = definitionsFiltered.map((definition) =>
      _pick(definition, ['key', 'order', 'direction'])
    )

    EventsData.broadcast('sort:changed')
  }

  const setVisibleColumnsDefault = () => {
    localStorageService.set(`definitions-${context.get('account').id}-${session.user.id}`, {
      columns: _currentVisibleColumns[context.get('account').id]
    })
  }

  const service = {
    clearAllColumns,
    getAllColumns,
    getCoreProperties,
    getSubordinate,
    getSortExcludes,
    getVisibleColumnsAsArray,
    getVisibleColumnsAsKeys,
    getVisibleColumnsAsSortKeys,
    getVisibleColumnsOnly,
    recalculateSortOrders,
    removeVisibleColumnsDefault,
    setAllColumns,
    setVisibleColumns,
    setVisibleColumnsSort,
    setVisibleColumnsDefault
  }

  return service
}
