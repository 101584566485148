export function ptProfileInvitationsRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('profile.invitations', {
    url: '/invitations',
    views: {
      'content@layoutFull': {
        component: 'ptProfileInvitations'
      }
    },
    resolve: {
      invitations: resolveInvitations
    },
    onEnter: onProfileInvitationsEnter
  })

  function resolveInvitations(invitationsForUser, session) {
    'ngInject'
    if (session.user.confirmedAt) {
      //this endpoint throws an error if the user has not confirmed
      return invitationsForUser.getAll()
    } else {
      return []
    }
  }

  function onProfileInvitationsEnter(page, gettextCatalog) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Your invitations'))
  }
}
