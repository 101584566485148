import { cloneDeep as _cloneDeep, isEqual as _isEqual, remove as _remove } from 'lodash'

export function EventsCache() {
  'ngInject'

  const storedEvents = []

  const service = {
    store,
    stored
  }

  return service

  ////////////////

  function store(eventName, eventData) {
    storedEvents.push({
      eventName: eventName,
      eventData: _cloneDeep(eventData)
    })
  }

  function stored(eventName, eventData) {
    const matches = _remove(storedEvents, function (storedEvent) {
      return _isEqual(eventName, storedEvent.eventName) && _isEqual(eventData, storedEvent.eventData)
    })

    return !!matches.length
  }
}
