export function Controller($window, Upload, profileData, BASE_URL) {
  'ngInject'

  let vm = this

  vm.control = {
    selected: selected,
    signatureUrl: signatureUrl,
    save: save,
    cancel: cancel,
    remove: remove
  }

  vm.image = null
  vm.previewUrl = null
  vm.uploading = false

  vm.$onInit = function () {}

  function selected(files) {
    if (files.length) {
      vm.image = files[0]

      Upload.dataUrl(vm.image, true).then(function (url) {
        vm.previewUrl = url
      })
    }
  }

  function save() {
    vm.uploading = true

    profileData
      .uploadSignature(vm.image)
      .then(function (data) {
        vm.image = null
        vm.previewUrl = null
        vm.uploading = false
        vm.user.signature = data.data.data.signature
      })
      .catch(function () {})
  }

  function cancel() {
    vm.uploading = false
    vm.image = null
    vm.previewUrl = null
  }

  function signatureUrl(url) {
    return BASE_URL + url
  }

  function remove() {
    profileData
      .removeSignature()
      .then(function () {
        $window.location.reload()
      })
      .catch(function () {})
  }
}
