import { Color, colors } from '../styles'
import * as parseColor from 'color'

export const darkenColor = (color: Color, ratio: number) => {
  return parseColor(colors[color]).darken(ratio).string()
}

export const lightenColor = (color: Color, ratio: number) => {
  return parseColor(colors[color]).lighten(ratio).string()
}
