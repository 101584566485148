import angular from 'angular'
import { ptTutorialsMenu } from './component'

export const ptTutorialsMenuModule = angular
  .module('tutorials.pt-tutorials-menu', [])
  .component('ptTutorialsMenu', ptTutorialsMenu)
  .run([
    '$templateCache',
    ($templateCache) => {
      $templateCache.put('components/pt-tutorials-menu/popover.html', require('./popover.html'))
      $templateCache.put('components/pt-tutorials-menu/start.html', require('./start.html'))
    }
  ]).name
