export function fieldMatch(formlyConfig) {
  'ngInject'

  formlyConfig.setType({
    name: 'matchField',
    defaultOptions: function matchFieldDefaultOptions(options) {
      return {
        expressionProperties: {
          // this expressionProperty is here simply to be run, the property `data.validate` isn't actually used anywhere
          'data.validate': function (viewValue, modelValue, scope) {
            if (scope.fc) {
              scope.fc.$validate()
            }
          }
        },
        validators: {
          fieldMatch: {
            expression: function (viewValue, modelValue, fieldScope) {
              let value = modelValue || viewValue
              let model = options.data.modelToMatch || fieldScope.model
              return model[options.data.fieldToMatch] !== undefined && value === model[options.data.fieldToMatch]
            },
            message: options.data.matchFieldMessage || '"Must match"'
          }
        }
      }
    }
  })
}
