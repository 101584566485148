import * as React from 'react'
import { Text } from 'reactxp'
import { createStyledView } from '../../components/styled'
import { spacing, FontSize, fontSizes } from '../../styles'
import { User } from '../index'
import { UserAvatar } from './user-avatar'

interface PersonDisplayProps {
  user: User
  children?: React.ReactNode
  imageSize?: number
  fontSize?: FontSize
}

const PersonDisplayWrapper = createStyledView({
  flexDirection: 'row',
  alignItems: 'center'
})

const PersonDisplayIcon = createStyledView({
  flex: 0
})

const PersonDisplayContent = createStyledView({
  flex: 1,
  marginLeft: spacing.half
})

export const PersonDisplay = ({ user, children, imageSize = 40, fontSize = 'base' }: PersonDisplayProps) => {
  return (
    <PersonDisplayWrapper>
      <PersonDisplayIcon>
        <UserAvatar user={user} imageSize={imageSize} fontSize={fontSize} />
      </PersonDisplayIcon>
      <PersonDisplayContent>
        <Text style={{ fontSize: fontSizes[fontSize] }}>
          {user.firstName} {user.lastName}
        </Text>
        {children}
      </PersonDisplayContent>
    </PersonDisplayWrapper>
  )
}
