import _takeRightWhile from 'lodash/takeRightWhile'
import _map from 'lodash/map'

export function recordsPath(folderContext) {
  'ngInject'

  let service = {
    getRelativePath: getRelativePath
  }

  return service

  function getRelativePath(record) {
    let fullPath = getFullPath(record)
    let folderId = folderContext.currentId()

    return _takeRightWhile(fullPath, function (folder) {
      return folder.id !== folderId
    })
  }

  function getFullPath(record) {
    let fullPath = _map(record.folder.ancestors, function (folder) {
      return {
        id: folder.id,
        name: folder.name
      }
    })

    fullPath.push({
      id: record.folder.id,
      name: record.folder.name
    })

    return fullPath
  }
}
