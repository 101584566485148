export function AccountDeactivateRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('settings.deactivate', {
    url: '/deactivate',
    views: {
      'content@layoutFull': 'ptAccountDeactivate'
    },
    onEnter: onAccountDeactivateEnter
  })

  function onAccountDeactivateEnter(gettextCatalog, page) {
    'ngInject'

    /// Page title
    page.setTitle(gettextCatalog.getString('Deactivate account'))
  }
}
