import { Controller } from './controller'
import view from './view.html'

export const ptListUpdates = {
  bindings: {
    pendingItems: '<'
  },
  controller: Controller,
  template: view
}
