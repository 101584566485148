import { Controller } from './controller'
import view from './view.html'

export function ptRecordsPropertyShared() {
  'ngInject'

  let directive = {
    restrict: 'E',
    replace: true,
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    scope: {
      record: '<'
    },
    bindToController: true
  }

  return directive
}
