import { Controller } from './controller'
import view from './view.html'

export function ptRecordRelativeBreadcrumbs() {
  'ngInject'

  return {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'E',
    scope: {
      crumbs: '<'
    }
  }
}
