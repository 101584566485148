import angular from 'angular'
import { ptIauditorAuditModule } from './components/pt-iauditor-audit'
import { ptIauditorAuditItemModule } from './components/pt-iauditor-audit-item'
import { ptIauditorAuditTableModule } from './components/pt-iauditor-audit-table'
import { ptIauditorAuditsModule } from './components/pt-iauditor-audits'
import { ptIauditorFilterBoolModule } from './components/pt-iauditor-filter-bool'
import { ptIauditorFilterHeaderModule } from './components/pt-iauditor-filter-header'
import { ptIauditorFilterPanelModule } from './components/pt-iauditor-filter-panel'
import { ptIauditorFilterStringModule } from './components/pt-iauditor-filter-string'
import { ptIauditorSortPanelModule } from './components/pt-iauditor-sort-panel'
import { ptIauditorUploadItemModule } from './components/pt-iauditor-upload-item'

import { iAuditorData } from './services/data'
import {
  iAuditorAuthorsModel,
  iAuditorTemplatesModel,
  iAuditorFieldsModel,
  iAuditorSourcesModel,
  iAuditorModel
} from './services/models'

export const iAuditorModule = angular
  .module('iauditor', [
    ptIauditorAuditModule,
    ptIauditorAuditItemModule,
    ptIauditorAuditTableModule,
    ptIauditorAuditsModule,
    ptIauditorFilterBoolModule,
    ptIauditorFilterHeaderModule,
    ptIauditorFilterPanelModule,
    ptIauditorFilterStringModule,
    ptIauditorSortPanelModule,
    ptIauditorUploadItemModule
  ])
  .factory('iAuditorData', iAuditorData)
  .factory('iAuditorAuthorsModel', iAuditorAuthorsModel)
  .factory('iAuditorTemplatesModel', iAuditorTemplatesModel)
  .factory('iAuditorFieldsModel', iAuditorFieldsModel)
  .factory('iAuditorSourcesModel', iAuditorSourcesModel)
  .factory('iAuditorModel', iAuditorModel).name
