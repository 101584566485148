import { Controller } from './controller'
import view from './view.html'

export const ptTasksFilterPanel = {
  bindings: {
    onToggleFilterPanel: '&',
    members: '<'
  },
  controller: Controller,
  template: view
}
