import angular from 'angular'
import { ptItemPicker } from './component'

export const ptItemPickerModule = angular
  .module('common.pt-item-picker', [])
  .component('ptItemPicker', ptItemPicker)
  .run([
    '$templateCache',
    ($templateCache) => {
      $templateCache.put('components/pt-item-picker/popover.html', require('./popover.html'))
    }
  ]).name
