import _cloneDeep from 'lodash/cloneDeep'

export function Controller(TasksData, gettextCatalog, EventsData) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    closeModal,
    updateTitleBody
  }

  $ctrl.modalTitle = gettextCatalog.getString('Amend Task Details')
  $ctrl.taskBody = null
  $ctrl.taskTitle = null
  $ctrl.titleBodySaving = false

  $ctrl.$onInit = () => {
    $ctrl.taskBody = $ctrl.task.body
    $ctrl.taskTitle = $ctrl.task.title
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.resolve) {
      $ctrl.task = _cloneDeep($ctrl.resolve.task)
    }
  }

  function closeModal(reason) {
    $ctrl.dismiss({ $value: reason || 'cancel' })
  }

  function updateTitleBody() {
    const updatedData: any = {}

    if ($ctrl.taskTitle && $ctrl.taskTitle.length && $ctrl.taskTitle !== $ctrl.task.title) {
      updatedData.title = $ctrl.taskTitle
    }

    if ($ctrl.taskBody && $ctrl.taskBody.length && $ctrl.taskBody !== $ctrl.task.body) {
      updatedData.body = $ctrl.taskBody
    }

    if (updatedData.title || updatedData.body) {
      $ctrl.titleBodySaving = true

      TasksData.amendTask($ctrl.task.id, updatedData)
        .then((response) => {
          EventsData.pushEvent('task_was_updated', {
            data: {
              body: response.body,
              id: response.id,
              deadlineAt: response.deadlineAt,
              title: response.title
            }
          })
        })
        .catch(() => {})
        .finally(() => {
          $ctrl.titleBodySaving = false

          $ctrl.control.closeModal()
        })
    }
  }
}
