export function MembersEditRoute($stateProvider) {
  'ngInject'

  let editMemberModal

  $stateProvider.state('edit-member', {
    parent: 'settings.team',
    url: '/{memberId:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}/edit?reactivate',
    params: {
      reactivate: {
        type: 'bool',
        value: false
      }
    },
    views: {
      'utility@layoutBase': {
        template: ' '
      }
    },
    resolve: {
      resolvedMember: resolveMember
    },
    onEnter: onEditMemberEnter,
    onExit: onEditMemberExit
  })

  function resolveMember($stateParams, Authorise, membersData, context) {
    'ngInject'

    return membersData.get(context.get('account').id, $stateParams.memberId)
  }

  function onEditMemberEnter($uibModal, $state, resolvedMember, $transition$) {
    'ngInject'

    editMemberModal = $uibModal.open({
      component: 'ptMembersEdit',
      backdrop: 'static',
      resolve: {
        member: function () {
          return resolvedMember
        },
        $transitions: function () {
          return $transition$
        }
      }
    })

    editMemberModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onEditMemberExit() {
    'ngInject'

    editMemberModal.close()
  }
}
