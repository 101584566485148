export function Controller() {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {
    remove: remove,
    directionChange: directionChange
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function () {}

  function directionChange() {
    $ctrl.onDirectionChange({
      $event: {
        item: $ctrl.sortItem
      }
    })
  }

  function remove() {
    $ctrl.onSortRemove({
      $event: {
        item: $ctrl.sortItem
      }
    })
  }
}
