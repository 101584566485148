export function TasksModel(restmod) {
  'ngInject'

  const model = restmod
    .model('/tasks')
    .mix('ApiBaseModel')
    .mix({ subscribers: { hasMany: 'TasksSubscribersModel' } })

  return model
}

export function TasksSubscribersModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}

export function TasksNotesModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}
