import angular from 'angular'

import _find from 'lodash/find'
import _flatten from 'lodash/flatten'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'
import _sortBy from 'lodash/sortBy'
import _cloneDeep from 'lodash/cloneDeep'
import _isPlainObject from 'lodash/isPlainObject'

export function Controller($scope, recordsData, FieldsData, uploadsData, context, gettextCatalog, $state, $timeout) {
  'ngInject'
  const vm = this
  const s = {
    /// Field label
    selectField: gettextCatalog.getString('Select field to edit'),
    /// Checkbox label
    replaceExisting: gettextCatalog.getString('Replace existing?'),
    /// Field label - The name of the record
    name: gettextCatalog.getString('Name'),
    /// Field label
    inspectionFrequency: gettextCatalog.getString('Inspection frequency'),
    /// Field label
    comments: gettextCatalog.getString('Comments'),
    /// Field label - A date field
    nextCheck: gettextCatalog.getString('Next check date'),
    /// Field label - A date field
    notifyAt: gettextCatalog.getString('Notify date'),
    /// Select box default
    selectFieldDefault: gettextCatalog.getString('select a field'),
    /// Field label
    uploads: gettextCatalog.getString('Uploads')
  }

  let activeFormlyField = null
  let cachedFields = []

  function getRecordIds() {
    return vm.records.map((record) => record.id).join(',')
  }

  const saveSuccess = () => {
    console.log('success')
    const recordIds = getRecordIds()
    this.dismiss({ $value: 'saved' })

    $timeout(() => {
      $state.go('folder.action-records', { recordIds, action: 'edit' })
    }, 0)
  }

  const saveError = (rejection) => {
    this.saving = false

    this.error =
      (rejection && rejection.$response && rejection.$response.data && rejection.$response.data.error) || true
  }

  this.$onInit = () => {
    this.accountId = context.get('account').id
    this.saving = false
    this.uploading = false
    this.records = this.resolve.records
    this.error = null

    this.model = {
      fieldKey: null,
      type: null,
      frequency_id: null,
      string: null,
      stringSuggest: null,
      selectSuggest: null,
      textarea: null,
      date: null,
      array: null,
      uploads: null
    }

    this.formFields = [
      {
        key: 'fieldKey',
        type: 'select',
        templateOptions: {
          label: s.selectField,
          required: true,
          options: buildFieldsList(),
          ngOptions: 'fields.key as fields.label for fields in to.options'
        }
      },
      {
        key: 'frequency_id',
        type: 'frequencyId',
        templateOptions: {
          label: '',
          required: false,
          options: [],
          ngOptions: 'frequency.id as frequency.name for frequency in to.options | orderBy: "order"'
        },
        hideExpression: () => {
          return this.model.type !== 'frequency_id'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'string',
        type: 'input',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'string'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'identifier',
        type: 'input',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'identifier'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'rfid',
        type: 'input',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'rfid'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'barcode',
        type: 'input',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'barcode'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'integer',
        type: 'input',
        templateOptions: {
          label: '',
          required: false,
          type: 'number'
        },
        hideExpression: () => {
          return this.model.type !== 'integer'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'money',
        type: 'money',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'money'
        },
        data: {
          field: {
            currency: null,
            value: null
          }
        },
        validators: {
          money: {
            expression: ($viewValue, $modelValue) => {
              let field = $modelValue || $viewValue
              return (
                !field ||
                (field.currency && field.value) ||
                (!field.currency &&
                  typeof field.currency !== 'undefined' &&
                  !field.value &&
                  typeof field.value !== 'undefined')
              )
            },
            message: 'Please select currency and enter amount'
          }
        }
      },
      {
        key: 'stringSuggest',
        type: 'inputSuggest',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'stringSuggest'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'selectSuggest',
        type: 'selectSuggest',
        templateOptions: {
          label: '',
          required: false,
          optionsAttr: 'bs-options'
        },
        hideExpression: () => {
          return this.model.type !== 'selectSuggest'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'array',
        type: 'inputArray',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'array'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'replace',
        type: 'checkbox',
        templateOptions: {
          label: s.replaceExisting
        },
        hideExpression: () => {
          return this.model.type !== 'array'
        }
      },
      {
        key: 'textarea',
        type: 'textareaExpanding',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'textarea'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'date',
        type: 'inputDatepicker',
        templateOptions: {
          label: '',
          required: false
        },
        hideExpression: () => {
          return this.model.type !== 'date'
        },
        data: {
          field: {}
        }
      },
      {
        key: 'uploads',
        type: 'input',
        templateOptions: {
          label: '',
          required: true,
          type: 'hidden'
        },
        hideExpression: () => {
          return this.model.type !== 'uploads'
        },
        data: {
          field: {}
        }
      }
    ]

    $scope.$watch('$ctrl.model.fieldKey', () => {
      let field: any = _find(availableFields(), { key: this.model.fieldKey })

      this.model.type = null

      if (this.model.fieldKey) {
        if (field.type === 'string' && field.source && field.source.type === 'suggest') {
          activeFormlyField = _find(this.formFields, { key: 'stringSuggest' })
        } else if (field.type === 'string' && field.source && field.source.type === 'select') {
          activeFormlyField = _find(this.formFields, { key: 'selectSuggest' })
        } else {
          activeFormlyField = _find(this.formFields, { key: field.type })
        }
      } else {
        activeFormlyField = null
      }

      if (activeFormlyField) {
        $timeout(() => {
          this.model.type = activeFormlyField.key
          this.model[this.model.type] = null

          activeFormlyField.templateOptions.label = field.label
          activeFormlyField.templateOptions.required = field.required ? true : false
          activeFormlyField.data.field = field
        })
      }
    })
  }

  const buildFieldsList = () => {
    // Notify_at cant be changed as it depends on the current check_due_at for each record
    // and cant be after the check_due_at date
    // Barcode and RFID types are also not batch editable, as they should be used for individual id's
    let blueprint = context.get('account').blueprint

    let fields: any = [
      {
        key: 'name',
        type: 'string',
        label: s.name,
        required: true
      },
      {
        key: 'frequency_id',
        type: 'frequency_id',
        label: s.inspectionFrequency,
        required: true
      },
      {
        key: 'comments',
        type: 'textarea',
        label: s.comments
      },
      {
        key: 'next_check_at',
        type: 'date',
        label: s.nextCheck
      },
      {
        key: 'notify_at',
        type: 'date',
        label: s.notifyAt
      },
      {
        key: 'uploads',
        type: 'uploads',
        label: s.uploads
      }
    ]

    let blueprintFields = _flatten(
      _map(blueprint.sections.data, (section) => {
        return section.fields.data
      })
    )

    // Add each field from the blueprint
    _forEach(blueprintFields, (field) => {
      // Only particular types are editable as a batch
      if (
        _includes(
          ['string', 'date', 'identifier', 'array', 'barcode', 'rfid', 'money', 'textarea', 'integer'],
          field.type
        )
      ) {
        fields.push(field)
      }
    })

    // Sort all the fields by their label
    fields = _sortBy(fields, 'label')

    // Add the initial selected option
    fields.unshift({ key: null, label: '-- ' + s.selectFieldDefault + ' --', type: null, order: -1 })

    availableFields(fields)

    return fields
  }

  const availableFields = (fields?) => {
    if (angular.isDefined(fields)) {
      cachedFields = _cloneDeep(fields)
    }

    return cachedFields
  }

  this.save = () => {
    let key = this.model.fieldKey
    let modelData = this.model[this.model.type]
    let replace = this.model.replace
    let properties = ['name', 'frequency_id', 'comments', 'next_check_at', 'notify_at']

    if (this.form.$valid) {
      this.saving = true

      if (key === 'uploads') {
        return uploadsData.batchSaveUploads(this.records, modelData).then(saveSuccess, saveError)
      }
      if (_includes(properties, key)) {
        return recordsData.batchSaveProperty(this.records, key, modelData).then(saveSuccess, saveError)
      }

      if (_isPlainObject(modelData) && typeof modelData.value !== 'undefined') {
        if (!modelData.currency) {
          modelData = modelData.value
        }
      }

      return FieldsData.batchSaveField(this.records, activeFormlyField.data.field, modelData, replace).then(
        saveSuccess,
        saveError
      )
    }
  }

  this.setFiles = (event) => {
    this.model.uploads = event.model
  }

  this.setUploading = (event) => {
    this.uploading = event.uploading
  }
}
