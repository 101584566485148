import { PapertrailSession } from '@papertrail/web3-session'

export function ptUsersLogoutRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('logout', {
    url: '/logout',
    template: '',
    onEnter: onLogoutEnter
  })

  function onLogoutEnter(EventsPusher, Authenticate, EventsData, session) {
    'ngInject'
    session.destroy()
    PapertrailSession.intercom.logout()
    //this is handled by the single-spa logout MFE now
    //Authenticate.logout()
    EventsData.broadcast('event:auth-logout')
    //this is handled by the single-spa logout MFE now
    //AuthenticateRedirect.afterLogout()
  }
}
