import * as React from 'react'
import { Component } from 'react'
import { View } from 'reactxp'
import { Button } from '../../components'
import { createStyledText, createStyledView } from '../../components/styled'

import { colors, spacing, fontSizes } from '../../styles'
import { getPlatformStyle } from '../../services/platform'
import { ChecklistField } from '../index'

const Content = createStyledView({
  flexDirection: 'row',
  alignItems: 'flex-start'
})

const CheckableContainer = createStyledView({
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer'
})

const Checkbox = createStyledView({
  marginRight: spacing.half,
  width: 12,
  height: 12,
  borderWidth: 1
})

const LabelContainer = createStyledView({
  flex: 1,
  marginRight: spacing.half
})

const MetaContainer = createStyledView({
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: spacing.third
})

const DescriptionContainer = createStyledView({
  marginRight: spacing.base,
  marginBottom: spacing.half
})

const DescriptionSeparator = createStyledView({
  height: 1,
  flex: 1,
  backgroundColor: colors.neutralTintFour,
  marginVertical: spacing.half
})

const DescriptionTextContainer = createStyledView({
  flex: 1,
  marginHorizontal: spacing.base,
  ...getPlatformStyle({
    mobile: { fontSize: fontSizes.base },
    web: { fontSize: 15 }
  })
})

const DescriptionText = createStyledText({
  color: colors.neutralTintFour
})

const BaseFontSize = createStyledText({
  ...getPlatformStyle({
    mobile: { fontSize: fontSizes.base },
    web: { fontSize: 15 }
  })
})

export type MetaMode = 'none' | 'add' | 'edit'

interface Props {
  field: ChecklistField
  metaMode?: MetaMode
  onChange?: (field: ChecklistField) => void
  onPressMeta?: () => void
}

interface State {
  isInfoOpen: boolean
}

export class ChecklistFieldComponent extends Component<Props, State> {
  public state: State = { isInfoOpen: false }

  public render() {
    const { field, onChange = () => undefined, onPressMeta = () => undefined, metaMode = 'none' } = this.props

    const { isInfoOpen } = this.state

    const checkboxStyles = {
      backgroundColor: field.value ? colors.positive : colors.neutralTintSix,
      borderColor: colors.neutralTintTwo
    }

    return (
      <View>
        <Content>
          <CheckableContainer onPress={() => onChange({ ...field, value: !field.value })}>
            <Checkbox style={checkboxStyles} />
            <LabelContainer>
              <BaseFontSize>{field.label}</BaseFontSize>
            </LabelContainer>
          </CheckableContainer>

          {metaMode !== 'none' && (
            <MetaContainer>
              <Button
                icon={metaMode === 'edit' ? 'edit' : 'text'}
                onPress={onPressMeta}
                theme={{
                  backgroundColor: 'clear',
                  iconColor: 'neutralTintFour',
                  paddingVertical: 1,
                  paddingHorizontal: 1
                }}
              />
            </MetaContainer>
          )}

          <View>
            {field.description && (
              <Button
                icon="infoCircle"
                onPress={() => this.setState({ isInfoOpen: !isInfoOpen })}
                theme={{
                  backgroundColor: 'clear',
                  iconColor: 'secondary',
                  paddingVertical: 1,
                  paddingHorizontal: 1
                }}
              />
            )}
          </View>
        </Content>
        {isInfoOpen && (
          <DescriptionContainer>
            <DescriptionSeparator />
            <DescriptionTextContainer>
              <DescriptionText>{field.description}</DescriptionText>
            </DescriptionTextContainer>
          </DescriptionContainer>
        )}
      </View>
    )
  }
}
