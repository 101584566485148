export function Controller(permissionsCheck, context) {
  'ngInject'

  let vm = this

  vm.account = context.get('account')

  vm.permissionsCheck = permissionsCheck

  vm.$onInit = function () {}
}
