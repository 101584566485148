import _find from 'lodash/find'
import { ProductSearchField } from 'AppCore/product'

export function Controller($state) {
  'ngInject'

  const $ctrl = this

  $ctrl.selectedFolder = null

  $ctrl.control = {
    getProductName,
    folderChange,
    nextStep
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function () {}

  function getProductName(product) {
    return _find(product, <ProductSearchField>{ key: 'name' }).value
  }

  function folderChange(event) {
    $ctrl.selectedFolder = event.picked
  }

  function nextStep() {
    if ($ctrl.selectedFolder) {
      $state.go('.map', { folderId: $ctrl.selectedFolder.id })
    }
  }
}
