import { cloneDeep as _cloneDeep } from 'lodash'

export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    saveField
  }

  $ctrl.type = ''
  $ctrl.show = false

  $ctrl.fieldOptions = {
    label: true
  }

  $ctrl.$onInit = () => {}

  $ctrl.$onChanges = (changes) => {
    if (changes.field) {
      $ctrl.type = getType()
    }

    if (changes.record) {
      $ctrl.record = _cloneDeep($ctrl.record)
    }
  }

  function getType() {
    if ($ctrl.field.type === 'date') {
      return 'date'
    }

    if ($ctrl.field.type === 'array') {
      return 'array'
    }

    if ($ctrl.field.type === 'money') {
      return 'money'
    }

    if ($ctrl.field.type === 'textarea') {
      return 'textarea'
    }

    if ($ctrl.field.source && $ctrl.field.source.type === 'select') {
      return 'select'
    }

    if ($ctrl.field.source && $ctrl.field.source.type === 'suggest') {
      return 'suggest'
    }

    return 'default'
  }

  function saveField(value) {
    return $ctrl.onSaveField({
      field: $ctrl.field,
      value: value
    })
  }
}
