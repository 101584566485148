import _get from 'lodash/get'
import * as Sentry from '@sentry/browser'

export function App(
  $scope,
  $state,
  $uibModalStack,
  context,
  httpError,
  stateMonitor,
  Authenticate,
  EventsData,
  EventsPusher,
  $trace,
  $transitions,
  $q
) {
  'ngInject'

  init()

  function init() {
    $transitions.onBefore({}, stateChangeBefore)
    $transitions.onStart({}, stateChangeStart)
    $transitions.onSuccess({}, stateChangeSuccess)
    $transitions.onError({}, stateChangeError)

    $scope.$on('event:auth-loginRequired', authLoginRequired)
    $scope.$on('event:auth-loginConfirmed', authLoginConfirmed)
    $scope.$on('event:auth-loginCancelled', authLoginCancelled)
    $scope.$on('event:auth-forbidden', httpError.handle)
    $scope.$on('event:http-error', httpError.handle)
    $scope.$on('$destroy', function () {
      EventsPusher.disconnect()
    })
  }

  function stateChangeBefore($transition$) {
    const run = () => {
      const requiresAuth = _get($transition$.to(), 'data.secure') !== false
      const skipAuth = $transition$.params().skipAuth

      stateMonitor.targetState({ state: $transition$.to(), params: $transition$.params() })

      // If a users session has ended whilst the user still has the web page open
      // This condition will still be met, but it will caught in authLoginRequired() when
      // the request to the API throws a 401
      if (skipAuth || !requiresAuth || Authenticate.isAuthenticated()) {
        return $q.resolve()
      }

      //try to reload user (will work if cookies set)
      return Authenticate._loginSuccess()
        .then(function (response) {
          return $q.resolve()
        })
        .catch(function (response) {
          // Could not load user so cookie wasn't set/valid
          // Attempt to log in using the hub cookie
          return Authenticate.attemptHubLogin().then(function (response) {
            if (response && response.authenticated) {
              return $q.resolve()
            }

            // If hub login fails attempt a refresh
            // If this fails, they will be redirected (usually to the login screen)
            return Authenticate.attemptRefresh().then(function (response) {
              if (response.authenticated) {
                return $q.resolve()
              }
              return $state.target(response.name, response.params, response.options)
            })
          })
        })
    }

    return run()
  }

  function stateChangeStart($transition$) {
    if ($transition$.to().resolve) {
      EventsData.broadcast('routeResolver:resolving')
    }
  }

  function stateChangeSuccess($transition$) {
    stateMonitor.previousState({ state: $transition$.from(), params: $transition$.params('from') })
    stateMonitor.currentState({ state: $transition$.to(), params: $transition$.params('to') })

    EventsData.broadcast('routeResolver:resolved')
  }

  function stateChangeError($transition$) {

    if ($transition$.error().detail === 'subscription_does_not_have_tasks_access') {
      $state.go('tasks.disabled')
    }

    EventsData.broadcast('routeResolver:resolved')
  }

  function authLoginRequired() {
    Authenticate.attemptRefresh().then(function (response) {
      $state.go(response.name, response.params, response.options)
    })
  }

  function authLoginConfirmed() {}

  function authLoginCancelled() {
    EventsData.broadcast('routeResolver:resolved')
  }
}
