import { Controller } from './controller'
import view from './view.html'

export const ptImportsFolders = {
  bindings: {
    account: '<',
    importData: '<',
    $transition$: '<'
  },
  controller: Controller,
  template: view
}
