export function Controller(uploadFileActions, uploadsData) {
  'ngInject'

  let vm = this

  vm.type = uploadsData.getUploadType(vm.upload)

  vm.control = {
    view: view,
    fileUrl: fileUrl
  }

  function view() {
    if (!vm.nolink) {
      uploadFileActions.viewFile(vm.upload, vm.user)
    }
  }

  function fileUrl(url) {
    return uploadFileActions.getFileUrl(url)
  }
}
