import angular from 'angular'
import { ptAccountDemoBanner } from './directives/pt-account-demo-banner/directive'
import { ptAccountHeader } from './directives/pt-account-header/directive'
import { ptAccountLogo } from './directives/pt-account-logo/directive'
import { ptAccountsBasicTable } from './directives/pt-accounts-basic-table/directive'
import { ptAccountsBasicTableRow } from './directives/pt-accounts-basic-table-row/directive'

import { ptAccountModule } from './components/pt-account'
import { ptAccountDeactivateModule } from './components/pt-account-deactivate'
import { ptAccountDeactivateConfirmModule } from './components/pt-account-deactivate-confirm'
import { ptAccountExportModule } from './components/pt-account-export'
import { ptAccountExportOptionsModule } from './components/pt-account-export-options'
import { ptAccountGeneralModule } from './components/pt-account-general'
import { ptAccountNewModule } from './components/pt-account-new'
import { ptAccountTeamModule } from './components/pt-account-team'
import { ptAccountsModule } from './components/pt-accounts'

import { accountsData } from './services/data'
import { AccountModel, AccountEmailsModel } from './services/models'
import { accountsPoller } from './services/poller'

export const AccountsModule = angular
  .module('accounts', [
    ptAccountModule,
    ptAccountDeactivateModule,
    ptAccountDeactivateConfirmModule,
    ptAccountExportModule,
    ptAccountExportOptionsModule,
    ptAccountGeneralModule,
    ptAccountNewModule,
    ptAccountTeamModule,
    ptAccountsModule
  ])
  .factory('AccountModel', AccountModel)
  .factory('AccountEmailsModel', AccountEmailsModel)
  .factory('accountsData', accountsData)
  .factory('accountsPoller', accountsPoller)
  .directive('ptAccountDemoBanner', ptAccountDemoBanner)
  .directive('ptAccountHeader', ptAccountHeader)
  .directive('ptAccountLogo', ptAccountLogo)
  .directive('ptAccountsBasicTable', ptAccountsBasicTable)
  .directive('ptAccountsBasicTableRow', ptAccountsBasicTableRow).name
