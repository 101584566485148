import { createResource } from '../../api/resource'
import { createResponseArrayTransformer } from '../../api/transformer'
import { Member } from '../../member'

interface Query {
  include?: string
  filter?: string
}

interface Params {
  accountId: string
  folderId: string
  query?: Query
}

export const fetchFolderMembers = createResource({
  request: ({ accountId, folderId, query }: Params) => ({
    url: `accounts/${accountId}/folders/${folderId}/members`,
    method: 'GET',
    params: query
  }),
  transform: createResponseArrayTransformer<Member>('user', 'permissions')
})
