export function Controller(ProcessesData, fileHandler, moment, EventsData) {
  'ngInject'

  var $ctrl = this

  $ctrl.control = {
    download: download
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function () {}

  function download() {
    ProcessesData.getDownloadLink($ctrl.process)
      .then((source) => {
        fileHandler.download(source.data.download)
        $ctrl.process.read_at = moment().format()

        ProcessesData.markAsRead($ctrl.process && $ctrl.process.id).catch(() => {})

        EventsData.pushEvent('process_read', {
          processId: $ctrl.process.id
        })
      })
      .catch(() => {})
  }
}
