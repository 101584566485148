export function Controller(EventsData) {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {
    itemPickerInit,
    toggle,
    close
  }

  $ctrl.$onChanges = function () {}

  function itemPickerInit() {
    EventsData.broadcast('itemPickerInit', {})
  }

  function close() {
    $ctrl.onClose()
  }

  function toggle() {
    $ctrl.onToggle()
  }
}
