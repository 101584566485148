export function Controller($state, stateMonitor, ErrorsData) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    goToPreviousState: goToPreviousState,
    goToAccountsList: goToAccountsList
  }

  $ctrl.errorDetails = null

  $ctrl.$onInit = () => {
    $ctrl.errorDetails = getCurrentError()
    $ctrl.hasPreviousState = hasPreviousState()
  }

  function getCurrentError() {
    var currentError = ErrorsData.currentError()
    var errorDetails
    var errorName

    if (currentError) {
      errorName = currentError
    } else {
      errorName = 'default'
    }

    errorDetails = ErrorsData.get(errorName)

    if (!errorDetails) {
      errorDetails = ErrorsData.get('default')
    }

    return errorDetails
  }

  function hasPreviousState() {
    var previousState = stateMonitor.previousState()

    if (previousState.state && previousState.state.name && previousState.state.name !== 'login') {
      return true
    }

    return false
  }

  function goToPreviousState() {
    var previousState = stateMonitor.previousState()

    $state.go(previousState.state.name, previousState.params, { location: 'replace' })
  }

  function goToAccountsList() {
    $state.go('accounts', {}, { location: 'replace' })
  }
}
