import _assign from 'lodash/assign'
import _some from 'lodash/some'
import _reject from 'lodash/reject'

export function Controller($scope, $timeout, $state, recordsScan, recordsModal) {
  'ngInject'

  let vm = this
  let listener

  vm.control = {
    remove: remove,
    scanned: scanned,
    goToRecord: goToRecord
  }

  vm.singleScanMode = true
  vm.multiRecords = []
  vm.singleRecords = []
  vm.multiArchived = []
  vm.multiDuplicates = []
  vm.scanFail = false
  vm.lastScan = ''
  vm.searching = false
  vm.waiting = true

  //////////

  vm.$onInit = function () {
    listen()
  }

  function listen() {
    if (!listener) {
      listener = $scope.$on('scan:read', scanned)
    }
  }

  function scanned(scanString) {
    if (vm.singleScanMode) {
      singleScan(scanString)
    } else {
      multiScan(scanString)
    }
  }

  function singleScan(scanString) {
    let code = scanString

    vm.singleRecords = []
    vm.lastScan = code
    vm.waiting = false
    vm.scanFail = false
    vm.searching = true

    recordsScan
      .codeSearch(code)
      .then(
        function (results) {
          singleScanSearchResult(results)
        },
        function () {
          vm.scanFail = true
        }
      )
      .finally(function () {
        vm.searching = false
        vm.waiting = true
      })
  }

  function multiScan(scanString) {
    let code = scanString

    vm.multiArchived = []
    vm.multiDuplicates = []
    vm.scanFail = false
    vm.lastScan = code
    vm.searching = true

    recordsScan
      .codeSearch(code)
      .then(
        function (results) {
          multiScanSearchResult(code, results)
        },
        function () {
          vm.scanFail = true
        }
      )
      .finally(function () {
        vm.searching = false
      })
  }

  function singleScanSearchResult(results) {
    if (results.length === 1) {
      recordsModal.goToModalRoute(results[0].id)
    } else if (results.length > 1) {
      vm.singleRecords = results
    }
  }

  function multiScanSearchResult(code, results) {
    let validRecords = []
    let duplicateRecords = []
    let archivedRecords = []

    results.forEach(function (result) {
      let record = _assign(result, { _code: code })

      if (scanDuplicate(record)) {
        duplicateRecords.push(record)
      } else if (record.archived_at) {
        archivedRecords.push(record)
      } else {
        validRecords.push(record)
      }
    })

    // Add the valid records to the multi-scan list
    if (validRecords.length) {
      vm.multiRecords = vm.multiRecords.concat(validRecords)
    }

    if (duplicateRecords.length) {
      vm.multiDuplicates = duplicateRecords
    }

    if (archivedRecords.length) {
      vm.multiArchived = archivedRecords
    }
  }

  function scanDuplicate(record) {
    return _some(vm.multiRecords, function (n) {
      return n.id === record.id
    })
  }

  function remove(record) {
    vm.multiRecords = _reject(vm.multiRecords, function (n) {
      return n.id === record.id
    })
  }

  function goToRecord(record) {
    recordsModal.goToModalRoute(record.id)
  }
}
