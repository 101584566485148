export function RootFolderModel(restmod) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      definitions: { hasMany: 'DefinitionsModel', map: 'definitions.data' }
    })

  return model
}

export function FolderModel(restmod) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      folder: { hasMany: 'FolderModel', map: 'children.data' },
      children: { hasMany: 'FolderModel', map: 'children.data' },
      members: { hasMany: 'MemberModel', map: 'members.data' },
      ancestors: { hasMany: 'FolderModel', map: 'ancestors.data' },
      definitions: { hasMany: 'DefinitionsModel', map: 'definitions.data' },
      records: { hasMany: 'RecordModel', map: 'records.data' },
      user: { hasOne: 'UserModel', map: 'user.data' }
    })

  return model
}
