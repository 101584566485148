import angular from 'angular'

import { ptRecordModule } from './components/pt-record'
import { ptRecordFieldEscModule } from './components/pt-record-field-esc'
import { ptRecordManufacturerDetailModule } from './components/pt-record-manufacturer-detail'
import { ptRecordPreviewHeaderModule } from './components/pt-record-preview-header'
import { ptRecordPreviewUploadItemModule } from './components/pt-record-preview-upload-item'
import { ptRecordsBatchButtonModule } from './components/pt-records-batch-button'
import { ptRecordsBatchChecklistModule } from './components/pt-records-batch-checklist'
import { ptRecordsBatchCloneModule } from './components/pt-records-batch-clone'
import { ptRecordsBatchDeleteModule } from './components/pt-records-batch-delete'
import { ptRecordsBatchEditModule } from './components/pt-records-batch-edit'
import { ptRecordsBatchActionModule } from './components/pt-records-batch-action'
import { ptRecordsBatchMoveModule } from './components/pt-records-batch-move'
import { ptRecordsBatchTagModule } from './components/pt-records-batch-tag'
import { ptRecordsBrowserModule } from './components/pt-records-browser'
import { ptRecordsBrowserToolbarModule } from './components/pt-records-browser-toolbar'
import { ptRecordsDetailModule } from './components/pt-records-detail'
import { ptRecordsPropertyChecklistsModule } from './components/pt-records-property-checklists'
import { ptRecordsTaskItemModule } from './components/pt-records-task-item'
import { ptRecordsTasksModule } from './components/pt-records-tasks'

import { ptRecordBreadcrumbsModule } from './directives/pt-record-breadcrumbs'
import { ptRecordHistoryModule } from './directives/pt-record-history'
import { ptRecordRelativeBreadcrumbsModule } from './directives/pt-record-relative-breadcrumbs'
import { ptRecordUploadsModule } from './directives/pt-record-uploads'
import { ptRecordsNewButtonModule } from './directives/pt-records-new-button'
import { ptRecordsPaginationModule } from './directives/pt-records-pagination'
import { ptRecordsPropertyCommentsModule } from './directives/pt-records-property-comments'
import { ptRecordsPropertyFrequencyModule } from './directives/pt-records-property-frequency'
import { ptRecordsPropertyNameModule } from './directives/pt-records-property-name'
import { ptRecordsPropertySharedModule } from './directives/pt-records-property-shared'
import { ptRecordsPropertyNextCheckModule } from './directives/pt-records-property-next-check'
import { ptRecordsPropertyStateModule } from './directives/pt-records-property-state'
import { ptRecordsPropertyNotifyModule } from './directives/pt-records-property-notify'
import { ptRecordsPropertyTagsModule } from './directives/pt-records-property-tags'
import { ptRecordsTableModule } from './directives/pt-records-table'
import { ptRecordsTableFieldArrayModule } from './directives/pt-records-table-field-array'
import { ptRecordsTableFieldCoverImageModule } from './directives/pt-records-table-field-cover-image'
import { ptRecordsTableFieldDateModule } from './directives/pt-records-table-field-date'
import { ptRecordsTableFieldFrequencyModule } from './directives/pt-records-table-field-frequency'
import { ptRecordsTableFieldMoneyModule } from './directives/pt-records-table-field-money'
import { ptRecordsTableFieldNameModule } from './directives/pt-records-table-field-name'
import { ptRecordsTableFieldNextCheckAtModule } from './directives/pt-records-table-field-next-check-at'
import { ptRecordsTableFieldCreatedAtModule } from './directives/pt-records-table-field-created-at'
import { ptRecordsTableFieldNotifyAtModule } from './directives/pt-records-table-field-notify-at'
import { ptRecordsTableFieldStateModule } from './directives/pt-records-table-field-state'
import { ptRecordsTableFieldSharedModule } from './directives/pt-records-table-field-shared'
import { ptRecordsTableFieldStringModule } from './directives/pt-records-table-field-string'
import { ptRecordsTableHeadModule } from './directives/pt-records-table-head'
import { ptRecordsTableRowModule } from './directives/pt-records-table-row'

import { recordPaginationOptions } from './providers/pagination-options'
import { recordViewOptions } from './providers/view-options'

import { recordsData } from './services/data'
import { recordsEvents } from './services/events'
import { recordsModal } from './services/modal'
import { RecordModel } from './services/models'
import { recordsMove } from './services/move'
import { recordsPagination } from './services/pagination'
import { recordsPath } from './services/path'
import { recordsResolves } from './services/resolves'
import { recordsScan } from './services/scan'
import { recordsBucket } from './services/bucket'
import { recordsSelection } from './services/selection'
import { recordsViewMode } from './services/view-mode'

import { NewRecordRoute } from './routes/new'

export const RecordsProvidersModule = angular
  .module('records.providers', [])
  .provider('recordPaginationOptions', recordPaginationOptions)
  .provider('recordViewOptions', recordViewOptions).name

export const RecordsModule = angular
  .module('records', [
    // components
    ptRecordModule,
    ptRecordsTaskItemModule,
    ptRecordManufacturerDetailModule,
    ptRecordFieldEscModule,
    ptRecordPreviewHeaderModule,
    ptRecordPreviewUploadItemModule,
    ptRecordsBatchButtonModule,
    ptRecordsBatchChecklistModule,
    ptRecordsBatchCloneModule,
    ptRecordsBatchDeleteModule,
    ptRecordsBatchEditModule,
    ptRecordsBatchActionModule,
    ptRecordsBatchMoveModule,
    ptRecordsBatchTagModule,
    ptRecordsBrowserModule,
    ptRecordsBrowserToolbarModule,
    ptRecordsDetailModule,
    ptRecordsPropertyChecklistsModule,
    ptRecordsTaskItemModule,
    ptRecordsTasksModule,

    // directives
    ptRecordBreadcrumbsModule,
    ptRecordHistoryModule,
    ptRecordRelativeBreadcrumbsModule,
    ptRecordUploadsModule,
    ptRecordsNewButtonModule,
    ptRecordsPaginationModule,
    ptRecordsPropertyCommentsModule,
    ptRecordsPropertyFrequencyModule,
    ptRecordsPropertyNameModule,
    ptRecordsPropertySharedModule,
    ptRecordsPropertyNextCheckModule,
    ptRecordsPropertyStateModule,
    ptRecordsPropertyNotifyModule,
    ptRecordsPropertyTagsModule,
    ptRecordsTableModule,
    ptRecordsTableFieldArrayModule,
    ptRecordsTableFieldCoverImageModule,
    ptRecordsTableFieldDateModule,
    ptRecordsTableFieldFrequencyModule,
    ptRecordsTableFieldMoneyModule,
    ptRecordsTableFieldNameModule,
    ptRecordsTableFieldNextCheckAtModule,
    ptRecordsTableFieldCreatedAtModule,
    ptRecordsTableFieldNotifyAtModule,
    ptRecordsTableFieldStateModule,
    ptRecordsTableFieldSharedModule,
    ptRecordsTableFieldStringModule,
    ptRecordsTableHeadModule,
    ptRecordsTableRowModule
  ])
  .config(NewRecordRoute)
  .factory('recordsData', recordsData)
  .factory('recordsEvents', recordsEvents)
  .factory('recordsModal', recordsModal)
  .factory('RecordModel', RecordModel)
  .factory('recordsMove', recordsMove)
  .factory('recordsPagination', recordsPagination)
  .factory('recordsPath', recordsPath)
  .factory('recordsResolves', recordsResolves)
  .factory('recordsScan', recordsScan)
  .factory('recordsBucket', recordsBucket)
  .factory('recordsSelection', recordsSelection)
  .factory('recordsViewMode', recordsViewMode).name
