import * as React from 'react'
import { FileUploadDisclaimer } from '../common/FileUploadDisclaimer'

export interface ptFieldSectionProps {
  name: string
}

class ptFieldSection extends React.Component<ptFieldSectionProps> {
  state = {
    hasSub: false,
    name: ''
  }

  componentDidMount = () => {
    this.setState({
      name: this.parseName(this.props.name)
    })
  }

  parseName = (name) => {
    const regex = /\*\*([^\*\*]+)\*\*/
    const check = name.match(regex)
    let replaced = ''
    let formatted = ''

    if (check) {
      this.setState({
        hasSub: true
      })

      replaced = name.replace(regex, '')
      formatted = name.match(regex)[1]
    } else {
      replaced = name
    }

    return [replaced, formatted]
  }

  renderExtraInformation(sectionTitle: string) {
    switch (sectionTitle) {
      case 'Uploads':
        return <FileUploadDisclaimer></FileUploadDisclaimer>
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={this.state.hasSub ? 'field-section-title has-sub' : 'field-section-title'}>
          <h4 data-tip="This is the tooltip content">
            {this.state.name[0]} {this.renderExtraInformation(this.state.name[0])}
          </h4>
        </div>
        {this.state.hasSub && (
          <div className="field-section-sub">
            <span className="sub-title">{this.state.name[1]}</span>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default ptFieldSection
