export function AccountGeneralRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('settings', {
    abstract: true,
    url: '/settings',
    parent: 'account',
    resolve: {
      authorise: resolveAuthorise
    }
  })

  $stateProvider.state('settings.general', {
    url: '',
    views: {
      'content@layoutFull': 'ptAccountGeneral'
    },
    resolve: {
      countries: resolveCountries,
      timezones: resolveTimezones
    },
    onEnter: onSettingsGeneralEnter
  })

  function resolveAuthorise(account, member, Authorise) {
    'ngInject'

    return Authorise.admin(account)
  }

  function resolveCountries(Authorise, CountriesData) {
    'ngInject'

    return CountriesData.get()
  }

  function resolveTimezones(Authorise, timezonesData) {
    'ngInject'

    return timezonesData.get()
  }

  function onSettingsGeneralEnter(gettextCatalog, page) {
    'ngInject'

    /// Page title
    page.setTitle(gettextCatalog.getString('Account settings'))
  }
}
