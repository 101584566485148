import { camelCase, snakeCase, isObject, isArray } from 'lodash'
import { DataWrapper, Pagination, ServerPagination, ServerPayload } from './index'

export type Transformer<A, B> = (model: A) => B
export type RequestTransformer<M> = (payload: M, skipProps?: [string]) => ServerPayload
export type ResponseTransformer<M> = (payload: ServerPayload) => M

export const stripData = (payload: ServerPayload) => (!!payload.data ? payload.data : payload)

export const stripDataFromProperties = (obj: any, properties: string[]): any => {
  const result = { ...obj }

  properties.forEach((propertyLevels) => {
    const propertiesArray = propertyLevels.split('.')
    let propertyData = result

    propertiesArray.forEach((property) => {
      if (propertyData[property]) {
        // Fallback to self, in case `data` property doesn't exist
        propertyData[property] = propertyData[property].data || propertyData[property]

        propertyData = propertyData[property]
      } else if (propertyData && isArray(propertyData)) {
        propertyData = propertyData.reduce((newPropertyData, child) => {
          if (child[property]) {
            child[property] = child[property].data || child[property]

            newPropertyData.push(child[property])
          }

          return newPropertyData
        }, [])
      }
    })
  })

  return result
}

export const stripNull = (obj: any): any => {
  const result: any = isArray(obj) ? [] : {}

  Object.entries(obj).forEach(([key, value]) => {
    result[key] = isObject(value) ? stripNull(value as any) : value === null ? undefined : value
  })

  return result
}

export const transformSnakeToCamel = (obj: any): any => {
  const result: any = isArray(obj) ? [] : {}

  Object.entries(obj).forEach(([key, value]) => {
    const newKey = camelCase(key)
    result[newKey] = isObject(value) ? transformSnakeToCamel(value as any) : value
  })

  return result
}

export const transformCamelToSnake = (obj: any, skipProps?: [string]): any => {
  const result: any = isArray(obj) ? [] : {}

  Object.entries(obj).forEach(([key, value]) => {
    if (skipProps && skipProps.includes(key)) {
      result[key] = value;
      return;
    }

    const newKey = snakeCase(key)
    result[newKey] = isObject(value) ? transformCamelToSnake(value as any) : value
  })

  return result
}

export const transformStripDataFromPropertiesWith = <A, B>(
  transform: Transformer<A, B>,
  properties: string[]
): Transformer<A, B> => {
  return (model: A) => transform(stripDataFromProperties(model, properties))
}

export const transformStripNullWith = <A, B>(transform: Transformer<A, B>): Transformer<A, B> => {
  return (model: A) => transform(stripNull(model))
}

export const transformDataWrapperWith = <A, B>(transform: Transformer<A, B>): Transformer<DataWrapper<A>, B> => {
  return (model: DataWrapper<A>) => transform(model.data)
}

export const transformArrayWith = <A, B>(transform: Transformer<A, B>): Transformer<A[], B[]> => {
  return (model: A[]) => model.map((item) => transform(item))
}

export const transformPaginationWith = <A, B>(
  transform: Transformer<A, B>
): Transformer<ServerPagination<A>, Pagination<B>> => {
  return (model: ServerPagination<A>) => {
    const arrayTransformer = transformArrayWith(transform)
    const items = arrayTransformer(model.data)

    if (model.meta.pagination) {
      const {
        total,
        count,
        per_page: perPage,
        current_page: currentPage,
        total_pages: totalPages
      } = model.meta.pagination
      return {
        items,
        cursor: {
          hasMore: currentPage < totalPages,
          page: {
            total,
            count,
            perPage,
            currentPage,
            totalPages
          }
        }
      }
    }

    if (model.meta.cursor) {
      const { current, prev: previous, next, count } = model.meta.cursor
      return {
        items,
        cursor: {
          hasMore: count !== 0,
          basic: {
            current,
            previous,
            next,
            count
          }
        }
      }
    }

    throw new Error('No pagination found.')
  }
}

export const voidTransformer: Transformer<any, void> = () => undefined
export const createRequestTransformer = <M>(): RequestTransformer<M> => transformCamelToSnake

export const createResponseTransformer =
  <M>(...properties: string[]): ResponseTransformer<M> =>
  (model) => {
    let result = { ...model }
    result = transformSnakeToCamel(result)
    result = stripData(result)
    result = stripNull(result)
    result = stripDataFromProperties(result, properties)
    return result as M
  }

export const createResponseArrayTransformer =
  <M>(...properties: string[]): ResponseTransformer<M[]> =>
  (model) => {
    let result = { ...model }
    result = transformSnakeToCamel(result)
    result = stripData(result)

    return result.map((item) => {
      let itemResult = { ...item }

      itemResult = stripNull(itemResult)
      itemResult = stripDataFromProperties(itemResult, properties)

      return itemResult
    })
  }

export const createResponsePaginationTransformer =
  <M>(...properties: string[]): ResponseTransformer<Pagination<M>> =>
  (model) => {
    const result: Pagination<M> = {} as any

    if (model.meta.pagination) {
      const {
        total,
        count,
        per_page: perPage,
        current_page: currentPage,
        total_pages: totalPages
      } = model.meta.pagination

      result.cursor = {
        hasMore: currentPage < totalPages,
        page: {
          total,
          count,
          perPage,
          currentPage,
          totalPages
        }
      }
    }

    if (model.meta.cursor) {
      const { current, prev: previous, next, count } = model.meta.cursor

      result.cursor = {
        hasMore: count !== 0,
        basic: {
          current,
          previous,
          next,
          count
        }
      }
    }

    result.items = stripData(model)
    result.items = transformSnakeToCamel(result.items)

    result.items = result.items.map((item) => {
      let itemResult = { ...item }

      itemResult = stripNull(itemResult)
      itemResult = stripDataFromProperties(itemResult, properties)

      return itemResult
    })

    return result
  }
