export function Controller(invitationsForAccount, tracking, $uibModal, ErrorsModal) {
  'ngInject'

  let vm = this

  vm.control = {
    deleteInvitation: deleteInvitation,
    remind: remind,
    copyToClipboard: copyToClipboard
  }

  vm.deleting = false
  vm.reminderSent = false

  ////////////////

  vm.$onInit = function () {}

  function copyToClipboard() {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      const link = `https://${window.location.hostname}/invitations?email=${encodeURIComponent(
        vm.invitation.email
      )}&code=${vm.invitation.code}`
      tracking.trackEvent({ action: 'copyLink', category: 'invitations', label: 'member' })
      navigator.clipboard.writeText(link).then(() => {
        vm.popover = true
      })
    }
  }

  function deleteInvitation() {
    if (!vm.deleting) {
      vm.deleting = true

      invitationsForAccount.deleteInvitation(vm.invitation).finally(function () {
        vm.deleting = false
      })
    }
  }

  function remind() {
    invitationsForAccount
      .remind(vm.invitation)
      .then(function () {
        vm.reminderSent = true
      })
      .catch((err) => {
        ErrorsModal.openModal(err.$response.data.error)
      })
  }
}
