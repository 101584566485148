import view from './view.html'
import { Controller } from './controller'

export const ptRecordPreviewUploadItem = {
  bindings: {
    uploadItem: '<'
  },
  template: view,
  controller: Controller
}
