import { Controller } from './controller'
import view from './view.html'

export function ptFolderOptions() {
  let directive = {
    restrict: 'A',
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      folder: '=',
      folderMeta: '=',
      showSort: '=',
      bucket: '='
    }
  }

  return directive
}
