import { Controller } from './controller'

export function ptItemPickerClick($timeout) {
  'ngInject'

  const directive = {
    restrict: 'A',
    link: function (scope, elem) {
      document.addEventListener('click', docClick)

      scope.$on('$destroy', function () {
        document.removeEventListener('click', docClick)
      })

      function docClick(e) {
        const isSelf = isSelfOrDescendant(elem[0], e.target)

        if (e.which && e.which !== 1) {
          return
        }

        if (isSelf) {
          $timeout(() => {
            scope.$ctrl.control.toggle()
          })
        } else {
          $timeout(() => {
            scope.$ctrl.control.close()
          })
        }
      }

      function isSelfOrDescendant(parent, child) {
        let node
        if (child === parent) {
          return true
        }

        node = child.parentNode

        while (node !== null) {
          if (node === parent) {
            return true
          }
          node = node.parentNode
        }
        return false
      }
    },
    controller: Controller,
    controllerAs: '$ctrl',
    bindToController: {
      onToggle: '&',
      onClose: '&'
    },
    scope: {}
  }

  return directive
}
