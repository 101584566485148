export function timezoneUtil(moment, session, context) {
  'ngInject'

  let service = {
    localToAccountTz: localToAccountTz,
    dateToLocal: dateToLocal,
    convertUtcToAccountTz: convertUtcToAccountTz
  }
  return service

  ////////////////

  function localToAccountTz(date) {
    return moment.tz(moment(date), session.user.timezone).tz(context.get('account').timezone)
  }

  function dateToLocal(date) {
    return moment(date).tz(session.user.timezone)
  }

  /**
   * Used for rendering UTC dates in the account's timezone
   *
   * Used on:
   * - Fields
   * - Tasks
   *
   * @param date
   */
  function convertUtcToAccountTz(date) {
    const accountTimezone = context.get('account').timezone
    const momentTimeInTimeZone = moment.utc(date).tz(accountTimezone)

    // Extract the year, month, day, hours, minutes, and seconds
    const year = momentTimeInTimeZone.year()
    const month = momentTimeInTimeZone.month() // Note: January is 0, February is 1, and so on.
    const day = momentTimeInTimeZone.date()
    const hours = momentTimeInTimeZone.hours()
    const minutes = momentTimeInTimeZone.minutes()
    const seconds = momentTimeInTimeZone.seconds()

    // Create a new JavaScript Date object with these values
    return new Date(year, month, day, hours, minutes, seconds)
  }
}
