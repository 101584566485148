import { createResource } from '../../api/resource'
import { createRequestTransformer, createResponseTransformer } from '../../api/transformer'
import { UpdateMePayload } from '../models/index'

const transformRequest = createRequestTransformer<UpdateMePayload>()

interface Params {
  payload?: UpdateMePayload
}

export const updateMe = createResource({
  request: ({ payload }: Params) => ({
    url: `me`,
    method: 'PATCH',
    data: transformRequest(payload)
  }),
  transform: createResponseTransformer<any>()
})
