export function RecordsBatchTagRoute($stateProvider) {
  'ngInject'

  let batchTagRecordModal

  $stateProvider.state('bucket.tag-records', tagRecordsStates())
  $stateProvider.state('folders.tag-records', tagRecordsStates())
  $stateProvider.state('folder.tag-records', tagRecordsStates())
  $stateProvider.state('folder.scan.tag-records', tagRecordsStates())
  $stateProvider.state('folders.scan.tag-records', tagRecordsStates())
  $stateProvider.state('account.scan.tag-records', tagRecordsStates())

  function tagRecordsStates() {
    return {
      url: '/records/tag?{recordIds}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: editRecordPermission,
        resolvedRecords: resolveTagRecords
      },
      onEnter: onBatchTagRecordEnter,
      onExit: onBatchTagRecordExit
    }
  }

  function editRecordPermission(account, member, permissionsCheck, ErrorsResolve) {
    'ngInject'

    return permissionsCheck.canPromise('edit', 'records', account).catch(ErrorsResolve.caught)
  }

  function onBatchTagRecordEnter($uibModal, $state, resolvedRecords) {
    'ngInject'

    batchTagRecordModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptRecordsBatchTag',
      resolve: {
        records: () => resolvedRecords
      }
    })

    batchTagRecordModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onBatchTagRecordExit() {
    'ngInject'

    batchTagRecordModal.close()
  }

  function resolveTagRecords(account, $stateParams, recordsResolves, ErrorsDisplay) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    let selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves.records(selection, 'checklists,fields,folder').catch(() => {
      return ErrorsDisplay.catch('select_records')
    })
  }
}
