import * as React from 'react'
import * as locale from '../locale/en.json'
import { Text } from 'reactxp'
import { TextProps } from 'reactxp/src/common/Types'

interface Props extends TextProps {
  children: string
  values?: { [key: string]: any }
}

const valuePattern = /\$([A-Za-z0-9]+)/

export const localise = (text: string, values: { [key: string]: any } = {}) => {
  const localised: string = locale[text] || text
  return localised.replace(valuePattern, (match, group) => values[group])
}

export const Localise = ({ children: text, values, ...props }: Props) => (
  <Text {...(props as any)}>{localise(text, values)}</Text>
)

export { Props as LocaliseProps }
