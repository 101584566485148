import _extend from 'lodash/extend'
import _cloneDeep from 'lodash/cloneDeep'
import _map from 'lodash/map'

export function membersData(EventsData, collections, AccountModel, $http, $q, session, BASE_URL, API_VERSION) {
  'ngInject'

  let service = {
    getAll: getAll,
    fetch: fetch,
    update: update,
    suspend: suspend,
    folderIds: folderIds,
    get: get
  }
  return service

  ////////////////

  function getAll(accountId, type, extraParams) {
    let params = {
      sort: 'user.first_name',
      include: 'user,permissions'
    }
    let collection
    let account = AccountModel.$new(accountId)

    if (type === 'suspended') {
      _extend(params, {
        include: 'user,permissions,deactivated',
        filter: 'deactivated_at<P1D'
      })
    }

    collection = account.members.$collection(_extend(params, extraParams || {}))

    return collections.getAll(collection)
  }

  function fetch(account) {
    return account.me.$fetch({ include: 'permissions' }).$asPromise()
  }

  function update(member, account) {
    let originalFolders = _cloneDeep(member.folders)
    let defer = $q.defer()

    member.folders = member.folderIds

    $http({
      method: 'PATCH',
      url: BASE_URL + '/accounts/' + account.id + '/members/' + member.id,
      headers: {
        Accept: API_VERSION
      },
      data: {
        active: member.active,
        admin: member.admin,
        view_only: member.view_only,
        folders: member.folders,
        permissions: member.permissions
      }
    })
      .then(function (response) {
        let memberUpdated = response.data.data

        EventsData.pushEvent('member_was_updated', {
          memberId: memberUpdated.id
        })

        defer.resolve(memberUpdated)
      })
      .catch(function (response) {
        defer.reject(response.data)
      })

    member.folders = originalFolders

    return defer.promise
  }

  function suspend(member) {
    return changeMemberState(false, member)
  }

  function changeMemberState(active, member) {
    member.active = active

    return member
      .$save(['active'])
      .$asPromise()
      .then(function (memberUpdated) {
        EventsData.pushEvent('member_was_updated', {
          memberId: memberUpdated.$response.data.data.id
        })

        return memberUpdated
      })
  }

  function folderIds(member) {
    return member.folders
      .$refresh()
      .$asPromise()
      .then(function (folders) {
        return _map(folders, 'id')
      })
  }

  function get(accountId, memberId) {
    let account = AccountModel.$new(accountId)

    return account.members.$find(memberId, { include: 'user,permissions' }).$asPromise()
  }
}
