import { Controller } from './controller'
import view from './view.html'

export function ptFoldersTable(recursionHelper) {
  'ngInject'

  let directive = {
    restrict: 'A',
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      folder: '=',
      folderMeta: '=',
      nested: '='
    },
    compile: function (element) {
      // Use the compile function from the RecursionHelper,
      // And return the linking function(s) which it returns
      return recursionHelper.compile(element)
    }
  }

  return directive
}
