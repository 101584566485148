export function ProcessesModel(restmod) {
  'ngInject'

  const model = restmod
    .model(null)
    .mix('ApiBaseModel')
    .mix({
      user: { hasOne: 'UserModel', map: 'user.data' },
      account: { hasOne: 'AccountModel', map: 'account.data' }
    })

  return model
}
