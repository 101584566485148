export function editableAutoTextarea(editableDirectiveFactory) {
  'ngInject'

  return editableDirectiveFactory({
    directiveName: 'editableAutoTextarea',
    inputTpl: '<textarea pt-textcomplete></textarea>',
    addListeners: function () {
      let self = this
      self.parent.addListeners.call(self)
      // submit textarea by ctrl+enter even with buttons
      if (self.single && self.buttons !== 'no') {
        self.autosubmit()
      }
    },
    autosubmit: function () {
      let self = this
      self.scope.$on('autoSubmit', function () {
        self.scope.$form.$submit()
      })

      self.inputEl.bind('keydown', function (e) {
        if ((e.ctrlKey || e.metaKey) && e.keyCode === 13) {
          self.scope.$apply(function () {
            self.scope.$form.$submit()
          })
        }
      })
    }
  })
}
