import { Controller } from './controller'
import view from './view.html'

export const ptNotificationsFilterPanel = {
  bindings: {
    onToggleFilterPanel: '&',
    accounts: '<'
  },
  controller: Controller,
  template: view
}
