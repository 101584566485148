import { link } from './link'

export function ptUploadMimeIcon() {
  'ngInject'

  let directive = {
    link: link,
    restrict: 'A',
    scope: {
      mime: '<'
    }
  }

  return directive
}
