import angular from 'angular'
import { ErrorsConstants } from './constants'
import { ErrorsRoutes } from './routes'
import { ErrorsData } from './services/data'
import { ErrorsDisplay } from './services/display'
import { ErrorsModal } from './services/modal'
import { ErrorsResolve } from './services/resolve'
import { ptErrorsErrorModule } from './components/pt-errors-error'
import { ptErrorsMaintenanceModule } from './components/pt-errors-maintenance'
import { ptErrorsModalModule } from './components/pt-errors-modal'
import { ptErrorsExpiryModule } from './components/pt-errors-expiry'

export const ErrorsModule = angular
  .module('errors', [ptErrorsErrorModule, ptErrorsMaintenanceModule, ptErrorsModalModule, ptErrorsExpiryModule])
  .config(ErrorsRoutes)
  .constant('ERRORS', ErrorsConstants)
  .factory('ErrorsData', ErrorsData)
  .factory('ErrorsDisplay', ErrorsDisplay)
  .factory('ErrorsModal', ErrorsModal)
  .factory('ErrorsResolve', ErrorsResolve).name
