import _cloneDeep from 'lodash/cloneDeep'

export function Controller() {
  'ngInject'

  this.control = {}

  this.$onInit = () => {}

  this.$onChanges = (changes) => {
    if (changes.customFields) {
      this.customFields = _cloneDeep(this.customFields)
    }
  }
}
