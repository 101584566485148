import view from './view.html'

export function ptUploadPreview() {
  'ngInject'

  let directive = {
    restrict: 'A',
    template: view,
    bindToController: true,
    controller: function () {},
    controllerAs: 'vm',
    scope: {
      file: '='
    },
    link: link
  }

  return directive

  function link(scope, element, attrs) {
    init()

    function init() {
      if (typeof attrs.override !== 'undefined') {
        scope.vm.override = true
        attrs.$observe('override', function () {
          if (attrs.override !== '') {
            scope.vm.overrideUrl = attrs.override
          } else {
            scope.vm.overrideUrl = null
          }

          setupPreview()
        })
      }
    }

    function setupPreview() {
      if (scope.vm.overrideUrl || scope.vm.file) {
        // css
        element.css('background-image', 'url(' + (scope.vm.overrideUrl || scope.vm.file) + ')')
        element.css('background-size', 'contain')
        element.css('background-repeat', 'no-repeat')
        element.css('background-position', 'center')
        // contents
        element.children('span').text('')
      }
    }
  }
}
