import _extend from 'lodash/extend'

export function invitationsForSubscription(context, EventsData, collections) {
  'ngInject'

  let service = {
    getAll: getAll,
    create: create,
    getUpdated: getUpdated,
    deleteInvitation: deleteInvitation,
    remove: remove
  }
  return service

  ////////////////

  function getAll(subscription, extraParams) {
    let params = {
      include: 'user,permissions'
    }
    let collection

    _extend(params, extraParams || {})

    collection = subscription.invitations.$collection(params)

    return collections.getAll(collection)
  }

  function getUpdated(invitation) {
    return invitation.$fetch({ include: 'user' }).$asPromise()
  }

  function create(subscription, invite) {
    let newInvite

    newInvite = subscription.invitations.$build(invite)

    return newInvite
      .$save()
      .$asPromise()
      .then(function (invitation) {
        EventsData.pushEvent('invitation_was_created', {
          invitationId: invitation.$response.data.data.id
        })

        return invitation
      })
  }

  function deleteInvitation(invitation) {
    return invitation
      .$destroy()
      .$asPromise()
      .then(function () {
        EventsData.pushEvent('invitation_was_deleted', {
          invitationId: invitation.id
        })
      })
  }

  function remove(invitation) {
    invitation.$scope.$remove(invitation)
  }
}
