export function Controller($scope, recordsData, gettextCatalog) {
  'ngInject'

  let vm = this

  vm.control = {
    saveName: saveName,
    click: click
  }

  vm.field = {
    required: true,
    label: gettextCatalog.getString('Record name')
  }

  /////////////

  vm.$onInit = function () {}

  function saveName(value) {
    return recordsData.saveProperty(vm.record, 'name', value).catch(() => {})
  }

  function click(event, form) {
    if (event.target.nodeName !== 'INPUT') {
      form.$show()
    }
  }
}
