import { Controller } from './controller'
import view from './view.html'

export const ptActivitiesWidgetItem = {
  template: view,
  controller: Controller,
  bindings: {
    activity: '<'
  }
}
