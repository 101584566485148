import angular from 'angular'
import { ptWidgetDashboardModule } from './components/pt-widget-dashboard'
import { ptWidgetHeaderModule } from './components/pt-widget-header'

import { widgetsData } from './services/data'
import { WidgetModel } from './services/models'

export const WidgetsModule = angular
  .module('widgets', [
    // components
    ptWidgetDashboardModule,
    ptWidgetHeaderModule
  ])
  .factory('widgetsData', widgetsData)
  .factory('WidgetModel', WidgetModel).name
