import _assign from 'lodash/assign'
import _map from 'lodash/map'

export function NotificationsData($http, ProfileModel, accountsData, collections, $q, API_VERSION, session) {
  'ngInject'

  let service = {
    init: init,
    loadMore: loadMore,
    readOne: readOne,
    readCollection: readCollection,
    readEverything: readEverything,
    getAccounts: getAccounts,
    getAllUnread: getAllUnread,
    getNotificationById: getNotificationById,
    getWhitelist: getWhitelist
  }

  return service

  ////////////////

  function init(additionalParams) {
    let params = {
      limit: 10,
      include: 'account',
      type: getWhitelist().join(',')
    }

    // if additionalParams were provided, assign them to params (will overwrite)
    if (typeof additionalParams === 'object') {
      params = _assign(params, additionalParams)
    }

    return ProfileModel.single('/me').notifications.$collection(params)
  }

  /**
   * Allowed notification types.
   */
  function getWhitelist() {
    return [
      'state_was_changed',
      'task_assignee_was_added',
      'task_deadline_was_updated',
      'task_note_was_created',
      'task_upload_was_created',
      'task_upload_was_deleted',
      'task_state_was_updated',
      'task_was_overdue',
      'account_records_retirement_overdue',
      'record_retirement_overdue',
      'partnerhub_partnership_was_created',
      'partnerhub_partnership_was_updated_by_client',
      'partnerhub_partnership_was_accepted_by_client'
    ]
  }

  function getNotificationById(id) {
    return ProfileModel.single('/me')
      .notifications.$find(id, {
        include: 'account',
        type: getWhitelist().join(',')
      })
      .$asPromise()
  }

  function loadMore(notifications, additionalParams) {
    let params = {
      cursor: 0
    }

    // if additionalParams were provided, assign them to params (will overwrite)
    if (typeof additionalParams === 'object') {
      params = _assign(params, additionalParams)
    }

    return notifications.$fetch(params).$asPromise()
  }

  function readOne(notification) {
    notification.read = true

    return notification.$save(['read']).$asPromise()
  }

  function readCollection(notifications) {
    let data = {
      notifications: _map(notifications, 'id')
    }

    return actionRead(data)
  }

  function readEverything(data) {
    return actionRead(data)
  }

  function actionRead(data) {
    let defer = $q.defer()

    $http({
      method: 'POST',
      url: '/api/me/notifications/actions/read',
      headers: {
        Accept: API_VERSION
      },
      data: data
    })
      .then(function (response) {
        defer.resolve(response.data)
      })
      .catch(function (rejection) {
        defer.reject(rejection.data)
      })

    return defer.promise
  }

  function getAccounts() {
    let accounts = accountsData.init(false, {
      role: 'member'
    })

    return accountsData.getAll(accounts).catch(() => {})
  }

  function getAllUnread() {
    let params = {
      read: false,
      type: getWhitelist().join(',')
    }

    let collection = ProfileModel.single('/me').notifications.$collection(params)

    return collections.getAll(collection)
  }
}
