import angular from 'angular'
import { ptInvitationsPublicModule } from './components/pt-invitations-public'
import { ptInvitationsPublicOAuthRedirectModule } from './components/pt-invitations-public-oauth-redirect'

import { ptInvitationsAcceptedSectionHeaderModule } from './directives/pt-invitations-accepted-section-header'
import { ptInvitationsPendingSectionHeaderModule } from './directives/pt-invitations-pending-section-header'
import { ptInvitationsTableModule } from './directives/pt-invitations-table'
import { ptInvitationsTableHeadModule } from './directives/pt-invitations-table-head'
import { ptInvitationsTableRowModule } from './directives/pt-invitations-table-row'

import { invitationsForAccount } from './services/for-account'
import { invitationsForPublic } from './services/for-public'
import { invitationsForSubscription } from './services/for-subscription'
import { invitationsForUser } from './services/for-user'
import { InvitationModel } from './services/models'
import { InvitationRootModel } from './services/root-models'
import { invitationsTypes } from './services/types'

export const InvitationsModule = angular
  .module('invitations', [
    ptInvitationsPublicModule,
    ptInvitationsPublicOAuthRedirectModule,
    ptInvitationsAcceptedSectionHeaderModule,
    ptInvitationsPendingSectionHeaderModule,
    ptInvitationsTableModule,
    ptInvitationsTableHeadModule,
    ptInvitationsTableRowModule
  ])
  .factory('invitationsForAccount', invitationsForAccount)
  .factory('invitationsForPublic', invitationsForPublic)
  .factory('invitationsForSubscription', invitationsForSubscription)
  .factory('invitationsForUser', invitationsForUser)
  .factory('InvitationModel', InvitationModel)
  .factory('InvitationRootModel', InvitationRootModel)
  .factory('invitationsTypes', invitationsTypes).name
