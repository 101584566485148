export function OwnerModel(restmod) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      user: { hasOne: 'UserModel', map: 'user.data' }
    })

  return model
}
