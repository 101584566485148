import _assign from 'lodash/assign'
import _cloneDeep from 'lodash/cloneDeep'
import _includes from 'lodash/includes'

export function Controller( $rootScope, $timeout, foldersTree, ivhTreeviewBfs, ivhTreeviewMgr, context) {
  'ngInject'

  const getFolders = () => {
    return foldersTree
      .getTree(this.account)
      .then((folders) => {
        this.folders = setSelectedIds(folders, this.picked)
      })
      .catch(() => {})
  }

  const getSelectedIds = (tree) => {
    let ids = []

    ivhTreeviewBfs(tree, (node) => {
      if (node.selected || node.__ivhTreeviewIndeterminate) {
        ids.push(node.id)
      }
    })

    return ids
  }

  const setSelectedIds = (tree, selected) => {
    ivhTreeviewBfs(tree, (node) => {
      if (this.options.disableCheckboxSelectionPropagation) {
        node.selected = _includes(selected, node.id)
      } else {
        node.selected = _includes(selected, node.id) && !node.children.length
      }
    })

    ivhTreeviewMgr.validate(tree)

    return tree
  }

  this.$onInit = () => {
    this.options = {
      labelAttribute: 'name',
      twistieCollapsedTpl: `<pt-icon name="'rightDir'" />`,
      twistieExpandedTpl: `<pt-icon name="'downDir'" />`,
      twistieLeafTpl: '<span class="icon-fw icon-none"></span>',
      nodeTpl: `
        <div>
          <span ivh-treeview-toggle>
            <span ivh-treeview-twistie></span>
          </span>
          <span ivh-treeview-checkbox></span>
          <span class="ivh-treeview-node-label" ivh-treeview-toggle>
            {{::trvw.label(node)}}
          </span>
          <div ivh-treeview-children ng-if="trvw.isExpanded(node)"></div>
        </div>
      `
    }
    if (this.defaults) {
      this.options = _assign(this.options, this.defaults)
    }

    this.folders = null
    this.account = context.get('account')

    getFolders().then(() => {
      $timeout(() => {
        // We need to wait for a digest cycle to have happened to set the flags on each folder
        this.picked = getSelectedIds(this.folders)

        this.onReady({ state: true })
      })
    })
  }

  this.$onChanges = (changes) => {
    if (changes.picked) {
      this.picked = _cloneDeep(this.picked)
    }
  }

  this.changeHandler = (node, isSelected, tree) => {
    this.picked = getSelectedIds(tree)

    this.onChange({ picked: this.picked })
  }

  this.selectAll = () => {
    ivhTreeviewMgr.selectAll(this.folders, this.options)
    this.picked = getSelectedIds(this.folders)
    
    $rootScope.$broadcast('foldersSelected', this.picked);
  }

  this.deselectAll = () => {
    ivhTreeviewMgr.deselectAll(this.folders, this.options)
    this.picked = getSelectedIds(this.folders)

    $rootScope.$broadcast('foldersNotSelected', this.picked);
  }
}
