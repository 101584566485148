import { PapertrailSession } from '@papertrail/web3-session'

export function ptUsersRegisterRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('register', {
    parent: 'layoutSplash',
    url: '/register?then&email&code',
    data: { secure: false },
    views: {
      'content@layoutSplash': {
        component: 'ptUsersRegister'
      }
    },
    onEnter: onRegisterEnter
  })

  function onRegisterEnter(Authenticate, page, gettextCatalog) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Register'))
    Authenticate.logout()
    PapertrailSession.intercom.logout()
  }
}
