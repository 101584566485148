export function TasksDisabledRoute($stateProvider) {
  'ngInject'

  let tasksDisabledModal

  $stateProvider.state('tasks.disabled', {
    url: '/disabled',
    views: {
      'utility@layoutBase': {
        template: ' '
      }
    },
    onEnter: onTasksDisabledEnter
  })

  function onTasksDisabledEnter($uibModal, $state) {
    'ngInject'

    tasksDisabledModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptTasksDisabled'
    })

    // If the user dismisses the modal, return to parent state.
    // result.then() = closed a modal (via change of state)
    // result.catch() = dissmiss a modal (via close button)
    tasksDisabledModal.result.catch(() => {
      $state.go('^')
    })
  }
}
