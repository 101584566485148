import { Controller } from './controller'
import view from './view.html'

export const ptActivitiesWidgetFilter = {
  template: view,
  controller: Controller,
  bindings: {
    members: '<',
    onUpdateMembers: '&'
  }
}
