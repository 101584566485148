export function requestRetry($injector, $timeout) {
  'ngInject'

  let service = {
    retry: retry
  }

  return service

  ////////////////

  function retry(rejection, deferred) {
    let $http = $injector.get('$http')

    rejection.config.retries = rejection.config.retries + 1 || 1

    function successCallback(response) {
      deferred.resolve(response)
    }

    function errorCallback(response) {
      deferred.reject(response)
    }

    // Retry the request with incrementing one second delays
    return $timeout(function () {
      return $http(rejection.config).then(successCallback, errorCallback)
    }, rejection.config.retries * 1000)
  }
}
