import { PapertrailSession } from '@papertrail/web3-session'

export function ptUsersLoginRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('login', {
    parent: 'layoutLogin',
    url: '/login?then&email',
    data: { secure: false },
    views: {
      'content@layoutLogin': {
        component: 'ptUsersLogin'
      }
    },
    onEnter: onLoginEnter
  })

  function onLoginEnter(Authenticate, page, gettextCatalog) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Login'))
    PapertrailSession.intercom.logout()
    Authenticate.logout()
  }
}
