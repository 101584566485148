import _forIn from 'lodash/forIn'

export function Controller($scope, membersData, gettextCatalog, TasksData, $state, $stateParams, session, context) {
  'ngInject'

  const $ctrl = this

  const params: any = {
    include: 'user,assignees,account,record',
    limit: 20,
    cursor: 0
  }

  const flagHighlighted = () => {
    $ctrl.tasks.forEach((task) => {
      if ($ctrl.pendingItems.indexOf(task.id) >= 0) {
        task.$highlight = true
      }
    })
  }

  $ctrl.control = {
    loadTasks,
    reloadTasks,
    togglePanel,
    getNewTasks
  }

  $ctrl.$onInit = function () {
    $ctrl.isProfileTasks = $state.current.name.indexOf('tasks') === 0
    $ctrl.isAccountTasks = $state.current.name.indexOf('account.tasks') === 0

    $ctrl.tasks = []
    $ctrl.taskCount = 0
    $ctrl.self = gettextCatalog.getString('Tasks')
    $ctrl.tasksLoading = true
    $ctrl.tasksMeta = null
    $ctrl.pendingItems = []

    $ctrl.panels = {
      filter: false,
      sort: false
    }

    if ($ctrl.isAccountTasks) {
      params.accountId = context.get('account').id
    }

    setupTasksParams()
    loadTasks()

    if ($ctrl.isAccountTasks) {
      setupMembers()
    }

    $scope.$on('task_was_created', (event, data) => {
      $ctrl.pendingItems.push(data.data.id)
    })
  }

  function setupTasksParams() {
    const filter = []
    let association = 'assignee_id'

    if ($stateParams.association) {
      association = 'user_id'
    }

    if ($stateParams.account) {
      params.accountId = $stateParams.account.split(',')
    }

    if ($stateParams.folders) {
      params.folderId = $stateParams.folders.split(',')
    }

    if ($ctrl.isProfileTasks) {
      filter.push(`${association}=${session.user.id}`)
    }

    if ($stateParams.assignees) {
      filter.push(`assignee_id=${$stateParams.assignees.split(',')}`)
    }

    if ($stateParams.user) {
      filter.push(`user_id=${$stateParams.user.split(',')}`)
    }

    if ($stateParams.record) {
      params.recordId = $stateParams.record
    }

    if ($stateParams.state) {
      params.state = $stateParams.state
    }

    if (filter.length) {
      params.filter = filter.join(',')
    }
  }

  function reloadTasks() {
    params.cursor = 0
    loadTasks()
  }

  function loadTasks() {
    $ctrl.tasksLoading = true

    if (params.cursor == 0) {
      TasksData.fetchTasksCount(params).then((response) => {
        $ctrl.taskCount = response
      })
    }

    TasksData.fetchTasks(params)
      .then((response) => {
        if (params.cursor == 0) {
          // replace tasks if we're reloading from the start following a sort
          $ctrl.tasks = response.items
        } else {
          $ctrl.tasks = $ctrl.tasks.concat(response.items)
        }
        $ctrl.tasksMeta = response.cursor
        params.cursor = $ctrl.tasksMeta.basic.next

        flagHighlighted()

        $ctrl.pendingItems = []
      })
      .catch(() => {
        // noop
      })
      .finally(() => {
        $ctrl.tasksLoading = false
      })
  }

  function setupMembers() {
    membersData.getAll(context.get('account').id).then((response) => {
      $ctrl.members = response
    })
  }

  function togglePanel(panelName) {
    _forIn($ctrl.panels, forInPanels)

    function forInPanels(value, key) {
      // Invert the selection of clicked panel
      if (key === panelName) {
        $ctrl.panels[key] = !$ctrl.panels[key]
      } else {
        // Make sure all others are closed
        $ctrl.panels[key] = false
      }
    }
  }

  function getNewTasks() {
    const updateTasksParams = {
      taskId: $ctrl.pendingItems.join(),
      include: params.include
    }

    $ctrl.tasksLoading = true

    TasksData.fetchTasks(updateTasksParams)
      .then((response) => {
        $ctrl.taskCount = $ctrl.taskCount + response.items.length

        $ctrl.tasks = response.items.concat($ctrl.tasks)

        flagHighlighted()

        params.cursor = params.cursor + $ctrl.pendingItems.length
        $ctrl.pendingItems = []
      })
      .catch(() => {
        // noop
      })
      .finally(() => {
        $ctrl.tasksLoading = false
      })
  }
}
