import _find from 'lodash/find'
import _sortBy from 'lodash/sortBy'
import _forEach from 'lodash/forEach'
import _merge from 'lodash/merge'
import _mapValues from 'lodash/mapValues'
import _omit from 'lodash/omit'


interface FieldType {
  key?: string
  template?: string
  wrapper?: string
  hideExpression?: () => void
  type?: string
  data?: any
  templateOptions?: any
  expressionProperties?: any
  fieldGroup?: FieldType[]
}

export function Controller(
  $scope,
  $stateParams,
  ExportsData,
  context,
  folderContext,
  filteringContext,
  localStorageService,
  filterParams,
  recordsViewMode,
  moment,
  BASE_URL,
  gettextCatalog,
  fileHandler,
  isIe,
  session,
  ErrorsModal,
  $state,
  $timeout
) {
  'ngInject'

  const linkText = 'General account settings'

  const s = {
    /// Field label
    certificateType: gettextCatalog.getString('Certificate type'),
    /// Select box default
    selectType: gettextCatalog.getString('Select type'),
    /// Radio label
    openOrDownload: gettextCatalog.getString('Open or download?'),
    /// Radio option
    open: gettextCatalog.getString('Open'),
    /// Radio option
    download: gettextCatalog.getString('Download'),
    /// Checkbox label
    includeSubfolders: gettextCatalog.getString('Include sub folders'),
    companyAddress: gettextCatalog.getString('Note: Company address is not set yet. Go to {{link}} to set it.', {
      link: '<a ui-sref="settings.general">' + linkText + '</a>'
    }),
    /// Field label
    archivedRecords: {
      label: gettextCatalog.getString('Included Records'),
      exclude: gettextCatalog.getString('Non-Archived Only'),
      include: gettextCatalog.getString('Non-Archived and Archived'),
      only: gettextCatalog.getString('Archived Records Only')
    },
    /// Checkbox label
    showAdvanced: gettextCatalog.getString('Advanced Options')
  }

  const certificateFields = (certificate, userSignatures) => {
    let fields: FieldType[] = [
      {
        template: '<legend translate>' + certificate.label + ' certificate details</legend>'
      },
      {
        template: '<p>' + s.companyAddress + '</p>',
        hideExpression: () => {
          return _find(context.get('account').addresses, 'default')
        }
      }
    ]
    let blueprintFields = _sortBy(certificate.blueprint.data.fields.data, 'order')
  
    fieldTypesMap = {}

    _forEach(blueprintFields, (field) => {
      fieldTypesMap[field.key] = field.type

      fields.push({
        key: field.key,
        type: getFieldType(field.type),
        templateOptions: {
          label: field.label,
          valueProp: field.type === 'select' ? 'name' : 'value',
          ...(field.type === 'select' && {
            options: (field.options as string[]).map((label) => ({ name: label }))
          }),
          ...(field.type === 'user' && {
            options: userSignatures.map((user) => ({
              value: user.user.id,
              name: user.user.first_name + ' ' + user.user.last_name
            }))
          })
        },
        data: {
          datepickerOptions: {
            showWeeks: false,
            startingDay: 1
          }
        }
      })
    })
    return [
      {
        className: 'fieldset',
        hideExpression: () => {
          return !this.model.template
        },
        fieldGroup: fields
      }
    ]
  }

  // Check for the initial value of archived by checking the state params
  const checkArchived = () => {
    let result = 'exclude'
    let include = $stateParams.include
    let filter = $stateParams.filter

    if (include && include.indexOf('archived') >= 0) {
      result = 'include'
    }

    if (include && include.indexOf('archived') >= 0 && filter && filter.indexOf('archived_at') >= 0) {
      result = 'only'
    }

    return result
  }

  const closeModal = () => {
    this.onCloseModal({ timeout: true })
  }

  let fieldTypesMap = {}

  const formatDate = (value) => {
    return moment(new Date(value)).format('YYYY-MM-DD')
  }

  const getFieldType = (type) => {
    switch (type) {
      case 'textarea':
        return 'textareaExpanding'
      case 'date':
        return 'inputDatepicker'
      case 'select':
        return 'select'
      case 'user':
        return 'select'
      default:
        return 'input'
    }
  }

  this.certificates = this.certificates.sort((a, b) => a.label.localeCompare(b.label));
  const initialFormFields: FieldType[] = [
    {
      key: 'template',
      type: 'select',
      templateOptions: {
        required: true,
        label: s.certificateType,
        options: [{ label: '-- ' + s.selectType + ' --' }].concat(this.certificates),
        ngOptions: 'certificate.key as certificate.label for certificate in to.options'
      }
    },
    {
      template:
        '<p>' +
        gettextCatalog.getString('Want a different certificate type?') +
        ' <a pt-contact="' +
        gettextCatalog.getString('What certificate would you like to be able to generate?') +
        '">' +
        gettextCatalog.getString('Let us know') +
        '</a></p>'
    },
    {
      key: '$download',
      type: 'radio',
      templateOptions: {
        label: s.openOrDownload,
        options: [
          {
            name: s.open,
            value: false
          },
          {
            name: s.download,
            value: true
          }
        ]
      },
      hideExpression: () => isIe
    },
    {
      key: 'advanced',
      wrapper: 'container',
      templateOptions: {
        label: s.showAdvanced
      },
      fieldGroup: [
        {
          key: '$includeDescendants',
          type: 'checkbox',
          templateOptions: {
            label: s.includeSubfolders
          },
          hideExpression: () => {
            return !this.model.showContainer || this.model.type !== 'folder'
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !$stateParams.folderId || viewMode === 'record'
            }
          }
        },
        {
          key: 'archived',
          type: 'radio',
          templateOptions: {
            label: s.archivedRecords.label,
            options: [
              {
                name: s.archivedRecords.exclude,
                value: 'exclude'
              },
              {
                name: s.archivedRecords.include,
                value: 'include'
              },
              {
                name: s.archivedRecords.only,
                value: 'only'
              }
            ]
          },
          hideExpression: () => {
            return !this.model.showContainer
          }
        }
      ]
    }
  ]

  const isDateValue = (value, key) => {
    return value && fieldTypesMap[key] && fieldTypesMap[key] === 'date'
  }

  // Set the required values to match the currently selected archived mode
  const setArchivedParams = () => {
    ExportsData.setArchivedParams(this.model)
  }

  const viewMode = recordsViewMode.get()

  const watch = () => {
    $scope.$watch('$ctrl.model.advanced.$includeDescendants', () => {
      if (this.model.type === 'folder') {
        if (this.model.advanced.$includeDescendants) {
          this.model.include = 'descendants,records'
        } else {
          this.model.include = 'records'
        }
      }
    })

    $scope.$watch('$ctrl.model.advanced.archived', () => {
      setArchivedParams()
    })

    $scope.$watch('$ctrl.model.template', () => {
      let certificate = _find(this.certificates, { key: this.model.template })

      if (certificate) {
        this.formFields = initialFormFields.concat(certificateFields(certificate, this.userSignatures))

        if (localStorageService.get(`pt-certificate-${this.model.template}-${session.user.id}`)) {
          _merge(
            this.model,
            _mapValues(
              localStorageService.get(`pt-certificate-${this.model.template}-${session.user.id}`),
              (valueObject) => {
                // Convert dates back to a JS date
                if (valueObject.date) {
                  return moment(valueObject.value).toDate()
                }
                return valueObject.value
              }
            )
          )
        }
      }
    })
  }

  this.$onInit = () => {
    this.isIe = isIe
    this.preparing = false
    this.error = null
    this.rootFolder = !$stateParams.folderId

    this.model = {
      type: this.type,
      $download: false,
      include: 'records',
      showContainer: false,
      advanced: {
        $includeDescendants: this.rootFolder || viewMode === 'record' ? true : false,
        archived: checkArchived()
      }
    }
    
    this.formFields = initialFormFields

    if (this.model.type === 'record') {
      this.model.record_id = $stateParams.recordId
    } else if (this.model.type === 'folder') {
      if (this.batch && this.recordIds.length) {
        this.model.type = 'record'
        this.model.record_id = this.recordIds.join(',')
      } else {
        this.model.id = folderContext.currentId()
        this.model = filteringContext.mergeParams(filteringContext.filters(), this.model)
      }
    }

    watch()
  }

  this.getUrls = () => {
     this.errorMessages = {}
     
    // let newWindow
    let localStorageData = {}
    let downloading = this.model.$download || isIe

    if (this.form.$valid) {
      if (downloading) {
        this.preparing = true
      }

      ExportsData.getExportCertificate(
        _mapValues(this.model, (value, key) => {
          if (isDateValue(value, key)) {
            localStorageData[key] = { value: value, date: true }
            return formatDate(value)
          }
          localStorageData[key] = { value: value, date: false }
          return value
        })
      )
        .then((exportData) => {
          if (downloading) {
            fileHandler.download(exportData.download)
          } else {
            dispatchEvent(new CustomEvent('showDocument', { detail: { docUrl: BASE_URL + exportData.view + exportData.view, mode: 'cert' } }))
          }

          // Store model in local storage
          localStorageService.set(
            `pt-certificate-${this.model.template}-${session.user.id}`,
            _omit(
              localStorageData,
              ['record_id', 'id', 'type', '$download', 'advanced', 'showContainer'].concat(filterParams.params())
            )
          )
          const recordIds = this.model.record_id

          closeModal()
          $timeout(() => {
            $state.go('^.action-records', { recordIds, action: 'export' })
          })
        })
        .catch((error) => {
          if (error && error.$response && error.$response.data.data) {
            const errorData = error.$response.data.data;
          
            // Iterate over error fields and populate error messages
            Object.keys(errorData).forEach((fieldName) => {
              const fieldErrors = errorData[fieldName]
              const errorMessage = fieldErrors.map(error => error.message).join(', ')
              this.errorMessages[fieldName] = errorMessage
              
            })
          } else {
            ErrorsModal.openModal(error.$response.data.error)
          }
          
      });
    } else {
      this.form.$setSubmitted()
    }
  }
}

