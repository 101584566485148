export const ptNotesNewRoute = ($stateProvider) => {
  'ngInject'

  let newNoteModal

  newNoteState('account.records')
  newNoteState('iauditor.audit')
  newNoteState('activities.records')
  newNoteState('bucket.records')
  newNoteState('folder.records')
  newNoteState('folders.records')
  newNoteState('bucket')
  newNoteState('folder')
  newNoteState('folders')
  newNoteState('folder.scan')
  newNoteState('folders.scan')
  newNoteState('account.scan')

  function newNoteState(parent) {
    $stateProvider.state(parent + '.new-note', {
      url: '/notes/new?{recordIds}&{sort}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        records: resolveRecords
      },
      onEnter: onNewNoteEnter,
      onExit: onNewNoteExit
    })
  }

  function resolveRecords(account, $stateParams, recordsResolves, ErrorsDisplay) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    let selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves.records(selection, 'fields,folder,uploads', $stateParams.sort).catch(function () {
      return ErrorsDisplay.catch('select_records_for_note')
    })
  }

  function onNewNoteEnter($uibModal, $state, records, page) {
    'ngInject'
    page.setTitle('Add Note')
    newNoteModal = $uibModal.open({
      component: 'ptNotesNew',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        recordsData: () => records
      }
    })

    newNoteModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onNewNoteExit(page) {
    'ngInject'
    page.resetTitle()
    newNoteModal.close()
  }
}
