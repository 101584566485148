export function Controller(gettextCatalog) {
  'ngInject'

  this.$onInit = () => {
    this.checklistName = this.resolve.checklistName
    this.modalTitle = gettextCatalog.getString('Confirm Checklist Removal')
  }

  this.$onChanges = () => {}

  this.confirm = () => {
    this.dismiss({ $value: true })
  }

  this.cancel = () => {
    this.dismiss({ $value: false })
  }
}
