export const chartOptions = {
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontFamily: `Avenir, sans-serif`
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          max: 100,
          stepSize: 20,
          beginAtZero: true,
          fontFamily: `Avenir, sans-serif`,
          /**
           *  A return of undefined = no line drawn on graph
           *  params (value, index, values)
           */
          callback: function (value) {
            let int

            // Make sure floating numbers do not get rendered on the chart
            if (value % 1 === 0) {
              int = value
            }

            return int + '%'
          }
        }
      }
    ]
  },
  tooltips: {
    titleMarginBottom: 10,
    bodySpacing: 10,
    titleFontFamily: `Avenir, sans-serif`,
    bodyFontFamily: `Avenir, sans-serif`,
    footerFontFamily: `Avenir, sans-serif`,
    callbacks: {
      label: function (tooltipItem, data) {
        const label = `${data.datasets[tooltipItem.datasetIndex].label} ${tooltipItem.yLabel}%`

        return label
      },
      afterLabel: function (tooltipItem, data) {
        return `
          ${data.datasets[1].label}: ${data.datasets[1].data[tooltipItem.index]}   ${data.datasets[2].label}: ${
          data.datasets[2].data[tooltipItem.index]
        }
        `.trim()
      }
    }
  },
  title: {
    display: false
  },
  legend: {
    display: false,
    labels: {
      fontFamily: `Avenir, sans-serif`,
      fontSize: 14
    }
  }
}
