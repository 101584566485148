export function Controller() {
  'ngInject'

  var vm = this

  vm.control = {
    openRecord: openRecord
  }

  vm.change = ''

  vm.$onInit = function () {
    if (vm.data.from.active !== vm.data.to.active) {
      // Deleted or undeleted
      vm.change = vm.data.to.active ? 'undeleted' : 'deleted'
    } else if (vm.data.from.default !== vm.data.to.default) {
      // Starred or unstarred
      vm.change = vm.data.to.default ? 'starred' : 'unstarred'
    }
  }

  function openRecord() {
    vm.openRecord()
  }
}
