export function resellersData(collections, ResellerModel) {
  'ngInject'

  let service = {
    getAll: getAll
  }

  return service
  ///////////////

  function getAll() {
    let collection = ResellerModel.$collection()

    return collections.getAll(collection)
  }
}
