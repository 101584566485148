import view from './view.html'

export function ptFolderHeader() {
  let directive = {
    restrict: 'A',
    template: view,
    controller: function () {
      'ngInject'
    },
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      folder: '=',
      folderMeta: '=',
      showSort: '=',
      bucket: '='
    }
  }

  return directive
}
