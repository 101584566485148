import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'

export const TagsCache = (EventsData) => {
  'ngInject'

  EventsData.on('activity_was_created', (event, data) => {
    if (data.data.type === 'record_was_updated' && cache[data.data.account.id]) {
      clear(data.data.account)
    }
  })

  const cache = {}

  const clear = (account) => {
    delete cache[account.id]
  }

  const get = (account) => _cloneDeep(_get(cache, account.id))

  const store = (account, data) => {
    cache[account.id] = _cloneDeep(data)
  }

  return {
    clear,
    get,
    store
  }
}
