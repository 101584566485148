import { createResource } from '../../api/resource'
import { createResponseTransformer } from '../../api/transformer'
import { AccountSearch } from '..'

interface Params {
  accountId: string
  query?: {
    limit: number
    q: string
  }
}

export const accountSearch = createResource({
  request: ({ accountId, query }: Params) => ({
    url: `accounts/${accountId}/search`,
    method: 'GET',
    params: query
  }),
  transform: createResponseTransformer<AccountSearch>('folders', 'records', 'tags', 'tasks')
})
