import _get from 'lodash/get'

export function foldersNavigation($state, filteringContext, recordsViewMode, recordsPagination) {
  'ngInject'

  let service = {
    go: go
  }

  return service

  //////////////

  function go(id, options) {
    let folderId = id || null
    let route = folderId ? 'folder' : 'folders'
    let filters = {}

    if (!_get(options, 'paginate')) {
      recordsPagination.resetPage()
    } 

    filters = filteringContext.mergeParams(
      filteringContext.filters(),
      recordsViewMode.filters(),
      recordsPagination.filters(),
      { folderId: folderId }
    )
    return $state.go(route, filters)
  }
}
