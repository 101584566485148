export function trimDecimal() {
  'ngInject'

  return function (value, length) {
    if (typeof value === 'undefined') {
      return value
    }

    if (typeof value !== 'number') {
      throw TypeError("The value provided needs to be of type 'number'")
    }

    if (typeof length !== 'number') {
      throw TypeError("The length provided needs to be of type 'number'")
    }

    return Number(value.toString().match(new RegExp('^-?\\d+(?:\\.\\d{0,' + length + '})?'))[0])
  }
}
