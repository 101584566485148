import _get from 'lodash/get'

export const ChecklistsData = (collections, $q, ptApi, ChecklistsCache, ChecklistsSentiment) => {
  'ngInject'

  const getChecklistCounts = (checklistCompletions) => {
    const fields = {
      complete: 0,
      total: 0
    }

    checklistCompletions.forEach((checklist) => {
      checklist.sections.data.forEach((section) => {
        section.fields.data.forEach((field) => {
          fields.total = fields.total + 1

          fields.complete = fields.complete + ChecklistsSentiment.getSentiment(field)
        })
      })
    })

    return fields
  }

  const getAccountChecklists = (account, fromApi = false) => {
    if (!fromApi && ChecklistsCache.get(account)) {
      return $q.when(ChecklistsCache.get(account))
    }

    return ptApi
      .request({
        method: 'GET',
        url: `/accounts/${_get(account, 'id')}/checklists?limit=1000`
      })
      .then((response) => {
        ChecklistsCache.store(account, response.data)
        return response.data
      })
      .catch(() => {})
  }

  const uploadSignature = (account, signatureFile) => {
    const formData = new FormData();
    formData.append('file', signatureFile);

    return ptApi
      .request({
        method: 'POST',
        url: `/accounts/${_get(account, 'id')}/uploads`,
        data: formData,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  return {
    getChecklistCounts,
    getAccountChecklists, 
    uploadSignature
  }
}
