import { createResource } from '../../api/resource'
import { createRequestTransformer, createResponseTransformer } from '../../api/transformer'
import { Folder } from '..'

interface CreateFolderRequest {
  name: string
  description?: string
  parentId?: UUID
}

interface Params {
  accountId: UUID
  payload: CreateFolderRequest
}

const transformRequest = createRequestTransformer<CreateFolderRequest>()

export const createFolder = createResource({
  request: ({ accountId, payload }: Params) => ({
    url: `accounts/${accountId}/folders`,
    method: 'POST',
    data: transformRequest(payload)
  }),
  transform: createResponseTransformer<Folder>(
    'members',
    'children',
    'records',
    'ancestors',
    'descendants',
    'definitions',
    'user'
  )
})
