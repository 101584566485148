export function connectionBuffer($injector, CONNECTION_TEST_URL) {
  'ngInject'

  let buffer = [],
    $http

  let service = {
    append: append,
    retryAll: retryAll
  }

  return service

  ////////////////

  /**
   * Appends HTTP request configuration object with deferred response attached to buffer.
   */
  function append(config, deferred) {
    if (config.url === CONNECTION_TEST_URL) {
      // Dont append connection tests
      deferred.reject()
    } else {
      buffer.push({
        config: config,
        deferred: deferred
      })
    }

    return deferred
  }

  /**
   * Retries all the buffered requests clears the buffer.
   */
  function retryAll() {
    for (let i = 0; i < buffer.length; ++i) {
      retryHttpRequest(buffer[i].config, buffer[i].deferred)
    }
    buffer = []
  }

  function retryHttpRequest(config, deferred) {
    function successCallback(response) {
      deferred.resolve(response)
    }
    function errorCallback(response) {
      deferred.reject(response)
    }
    $http = $http || $injector.get('$http')
    $http(config).then(successCallback, errorCallback)
  }
}
