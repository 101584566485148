import { Controller } from './controller'
import view from './view.html'

export const ptFoldersPicker = {
  template: view,
  controller: Controller,
  bindings: {
    onChange: '&',
    onReady: '&',
    picked: '<',
    defaults: '<'
  }
}
