export function EventsPusher(EventsData, EventsTranslator, $rootScope) {
  'ngInject'

  const listeners = new Map()

  const service = {
    connect,
    disconnect
  }

  return service

  ////////////////

  function connect() {
    listen()
  }

  function disconnect() {
    EventsTranslator.eventNames().forEach((eventName) => {
      window.removeEventListener(eventName, listeners.get(eventName))
      listeners.delete(eventName)
    })
  }

  function listen() {
    EventsTranslator.eventNames().forEach((eventName) => {
      const callback = (evt: CustomEvent) => {
        EventsData.translate(eventName, evt.detail)
        //digest is required so that components update themselves after receiving message - previously this was called by the angular-pusher library
        $rootScope.$digest()
      }
      listeners.set(eventName, callback)
      window.addEventListener(eventName, callback)
    })
  }
}
