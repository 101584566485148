import { cloneDeep } from 'lodash'

export function Controller() {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {}

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.selectedRecord) {
      $ctrl.selectedRecord = cloneDeep($ctrl.selectedRecord)
    }
  }
}
