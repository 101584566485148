export function Controller($scope, ReportsData, context, BASE_URL, $timeout, ptApi, fileHandler, isIe) {
  'ngInject'

  const $ctrl = this

  $ctrl.generating = false
  $ctrl.error = false
  $ctrl.isIe = isIe

  $ctrl.formModel = {
    exportType: 'pdf'
  }

  $ctrl.$onInit = () => {}

  $ctrl.$onChanges = (changes) => {
    if (changes.resolve) {
      $ctrl.params = $ctrl.resolve.params
    }
  }

  $ctrl.export = (method) => {
    let newWindow

    if (method === 'open') {
      newWindow = window.open('/exporting.html')
    }

    $ctrl.generating = true
    $ctrl.error = false

    const accountId = context.get('account').id

    const filePath = `/accounts/${accountId}/activities/export`

    ptApi
      .request({
        method: 'POST',
        url: filePath,
        params: { ...$ctrl.params, limit: 1000 },
        data: { export_type: 'activity', export_format: $ctrl.formModel.exportType, export_orientation: 'portrait' }
      })
      .then((response) => {
        if (method === 'open') {
          newWindow.location.href = BASE_URL + response.data.view
        } else {
          fileHandler.download(response.data.download)
        }

        $ctrl.dismiss()
      })
      .catch(() => {
        $ctrl.error = true

        if (method === 'open') {
          newWindow.close()
        }
      })
      .finally(() => {
        $ctrl.generating = false
      })
  }
}
