export function MemberModel(restmod) {
  'ngInject'

  let model = restmod
    .model()
    .mix('ApiBaseModel')
    .mix({
      accesses: { hasMany: 'AccessModel' },
      user: { hasOne: 'UserModel', map: 'user.data' },
      folders: { hasMany: 'FolderModel' },
      widgets: { hasMany: 'WidgetModel', map: 'widgets.data' },
      permissions: {
        hasMany: 'PermissionModel',
        map: 'permissions.data'
      }
    })

  return model
}
