import { createResource } from '../../api/resource'
import { voidTransformer } from '../../api/transformer'

interface Params {
  accountId: UUID
  folderId: UUID
}

export const deleteFolder = createResource({
  request: ({ accountId, folderId }: Params) => ({
    url: `accounts/${accountId}/folders/${folderId}`,
    method: 'DELETE'
  }),
  transform: voidTransformer
})
