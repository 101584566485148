export function xEditable(editableThemes, editableOptions) {
  'ngInject'

  editableThemes.bs3.submitTpl =
    '<button type="submit" class="btn btn-secondary"><i class="icon-fw icon-ok"></i></button>'
  editableThemes.bs3.cancelTpl = `<button
      type="button"
      class="btn btn-default"
      ng-click="$form.$cancel()"
     >
      <i class="icon-fw icon-cancel"></i>
     </button>`

  editableThemes.bs3.postrender = function () {
    // apply `form-control` class to std inputs
    switch (this.directiveName) {
      case 'editableText':
      case 'editableSelect':
      case 'editableTextarea':
      case 'editableEmail':
      case 'editableTel':
      case 'editableNumber':
      case 'editableUrl':
      case 'editableSearch':
      case 'editableDate':
      case 'editableDatetime':
      case 'editableTime':
      case 'editableMonth':
      case 'editableWeek':
      case 'editableBsdate':
        this.inputEl.addClass('form-control')
        if (this.theme.inputClass) {
          // don`t apply `input-sm` and `input-lg` to select multiple
          // should be fixed in bs itself!
          if (
            this.inputEl.attr('multiple') &&
            (this.theme.inputClass === 'input-sm' || this.theme.inputClass === 'input-lg')
          ) {
            break
          }
          this.inputEl.addClass(this.theme.inputClass)
        }
        break
      default:
      //
    }

    // apply buttonsClass (bs3 specific!)
    if (this.buttonsEl && this.theme.buttonsClass) {
      this.buttonsEl.find('button').addClass(this.theme.buttonsClass)
    }
  }

  editableOptions.theme = 'bs3'
}
