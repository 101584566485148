import { createResource } from '../../api/resource'
import { createResponseTransformer } from '../../api/transformer'
import { User } from '../index'

export const fetchMe = createResource({
  request: () => ({
    url: `me`,
    method: 'GET'
  }),
  transform: createResponseTransformer<User>()
})
