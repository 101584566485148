export function Controller(uploadFileActions, uploadsData) {
  'ngInject'

  let vm = this

  vm.type = uploadsData.getUploadType(vm.upload)
  vm.tooltipOpen = false
  vm.tooltipEnable = false

  vm.control = {
    view: view,
    fileUrl: fileUrl,
    toggleTooltip: toggleTooltip
  }

  function view() {
    uploadFileActions.viewFile(vm.upload, vm.user)
  }

  function fileUrl(url) {
    return uploadFileActions.getFileUrl(url)
  }

  function toggleTooltip() {
    vm.tooltipOpen = !vm.tooltipOpen
  }
}
