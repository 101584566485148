export function Controller(filteringContext) {
  'ngInject'

  let vm = this

  vm.control = {
    clicked: clicked
  }

  vm.$onInit = function () {}

  function clicked(value, $event) {
    $event.stopPropagation()
    filteringContext.applyFilter({ filter: vm.key + '=' + value.replace('/', '\\/') }, 'folders')
  }
}
