export function Controller() {
  'ngInject'

  let vm = this

  vm.control = {
    accept: accept
  }

  /////////

  function accept() {
    vm.onAccept({
      invitationId: vm.invitation.id
    })
  }
}
