export function recordsResolves($q, recordsData) {
  'ngInject'

  let service = {
    expandedRecord: expandedRecord,
    records: records,
    recordIdsSelection: recordIdsSelection
  }

  return service

  function expandedRecord(id) {
    return recordsData.getRecord(id, 'expanded')
  }

  function records(ids, includes, sort) {
    let defer = $q.defer()

    if (!ids) {
      defer.reject('no records')
      return defer.promise
    }

    return recordsData.getRecords(ids, null, includes, sort).then(function (response) {
      if (!response.length) {
        defer.reject('no records')
      } else {
        // Let's keep sort
        if(!sort) {
          response.sort((a, b) => {
            return ids.indexOf(a.id) - ids.indexOf(b.id);
          });
        }
        defer.resolve(response)
      }

      return defer.promise
    })
  }

  function recordIdsSelection($stateParams) {
    let ids

    if ($stateParams.recordId) {
      ids = [$stateParams.recordId]
    }

    if ($stateParams.recordIds) {
      ids = $stateParams.recordIds.split(',')
    }

    return ids
  }
}
