import {
  isArray as _isArray,
  isString as _isString,
  clone as _clone,
  cloneDeep as _cloneDeep,
  xor as _xor
} from 'lodash'

export function Controller($scope, $element, $timeout, lookupsData, $uibModalStack, filteringContext, gettextCatalog) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    saveTags,
    toggleFocus,
    getAvailableItems,
    tagClicked,
    popoverClose,
    popoverToggle
  }

  $ctrl.initialTags = []
  $ctrl.focusInput = false
  $ctrl.popover = {
    open: false
  }

  $ctrl.$onInit = function () {
    $ctrl.pickerOptions = {
      searchPlaceholder: `${gettextCatalog.getString('Find/Create')} ${$ctrl.field.label}...`,
      contextLabel: $ctrl.field.label
    }
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.field) {
      $ctrl.field = _cloneDeep($ctrl.field)

      if ($ctrl.field.value) {
        if (_isString($ctrl.field.value)) {
          $ctrl.field.value = $ctrl.field.value.split(', ')
        }
        $ctrl.initialTags = _clone($ctrl.field.value)
      }
    }

    if (changes.editable) {
      $ctrl.editable = _clone($ctrl.editable)
    }
  }

  function saveTags(tags) {
    const tagsString = mapText(tags)
    const initialTagsString = mapText($ctrl.initialTags)

    if (_xor(tagsString, initialTagsString).length) {
      $ctrl.initialTags = _clone(tags)

      $ctrl.onSave({ value: tags })
    }
  }

  function mapText(tags) {
    let result = []

    if (_isArray(tags)) {
      result = tags.map(function (n) {
        return n.text
      })
    }

    return result
  }

  function toggleFocus($event) {
    if ($event.originalEvent && $event.originalEvent.type === 'click') {
      $ctrl.focusInput = !$ctrl.focusInput

      if ($ctrl.focusInput) {
        $timeout(function () {
          $element.find('input').trigger('click')
        })
      }
    }
  }

  function getAvailableItems() {
    return lookupsData
      .fetch($ctrl.field.source.key, '', {
        limit: 100,
        all: true
      })
      .then(function (labels) {
        labels.forEach(function (item) {
          item.text = item.value
        })
        return labels
      })
  }

  function popoverClose() {
    $ctrl.popover = {
      open: false
    }
  }

  function popoverToggle() {
    var newValue = !$ctrl.popover.open

    $ctrl.popover = {
      open: newValue
    }
  }

  function tagClicked(value) {
    $uibModalStack.dismissAll('navigate')
    $timeout(function () {
      filteringContext.applyFilter({ filter: $ctrl.field.key + '=' + value.replace('/', '\\/') }, 'folders')
    })
  }
}
