export function ExportsModel(restmod) {
  'ngInject'

  let model = restmod.model().mix('ApiBaseModel')

  return model
}

export function CertificatesModel(restmod) {
  'ngInject'

  return restmod.model().mix('ApiBaseModel')
}
