import _filter from 'lodash/filter'

export function ProcessesData($q, $http, ProfileModel, collections, BASE_URL, session, EventsData, API_VERSION) {
  'ngInject'

  const service = {
    initAccountExport,
    get,
    getAll,
    getAllUnread,
    getDownloadLink,
    markAsRead
  }

  return service

  ////////////////

  function initAccountExport(id, includeFiles) {
    const req = {
      method: 'POST',
      url: BASE_URL + '/accounts/' + id + '/exports',
      headers: {
        Accept: API_VERSION
      },
      data: {
        type: 'account',
        include_files: includeFiles
      }
    }

    return $http(req).then((response) => {
      EventsData.pushEvent('process_created', {
        processId: response.data.data.id
      })

      return response.data.data
    })
  }

  function get(id) {
    return ProfileModel.single('/me').processes.$find(id).$asPromise()
  }

  function getAll(type) {
    const params = {
      sort: '-created_at',
      include: 'account',
      type: type
    }

    let collection = ProfileModel.single('/me').processes.$collection(params)

    return collections.getAll(collection)
  }

  function getAllUnread(type) {
    return getAll(type).then((processes) => {
      return _filter(processes, (process) => {
        return process.available && !process.read_at
      })
    })
  }

  function getDownloadLink(processData) {
    let defer = $q.defer()

    $http({
      method: 'GET',
      url: BASE_URL + processData.source,
      headers: {
        Accept: API_VERSION
      }
    })
      .then(function (response) {
        defer.resolve(response.data)
      })
      .catch(function (error) {
        defer.reject(error)
      })

    return defer.promise
  }

  function markAsRead(processId) {
    let defer = $q.defer()

    $http({
      method: 'POST',
      url: `${BASE_URL}/me/processes/${processId}/actions/read`,
      headers: {
        Accept: API_VERSION
      }
    })
      .then((response) => {
        defer.resolve(response.data)
      })
      .catch((error) => {
        defer.reject(error)
      })

    return defer.promise
  }
}
