import * as React from 'react'
import { Image, Text } from 'reactxp'
import { createStyledView } from '../../components/styled'
import { FontSize, fontSizes } from '../../styles'
import { getPlatformStyle } from '../../services/platform'
import { User } from '../index'

interface UserAvatarProps {
  user: User
  imageSize?: number
  fontSize?: FontSize
}

const AvatarWrapper = createStyledView({
  justifyContent: 'center',
  alignItems: 'center'
})

export const UserAvatar = ({ user, imageSize = 40, fontSize = 'base' }: UserAvatarProps) => {
  const userInitials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`.toUpperCase()

  return (
    <AvatarWrapper
      style={{
        width: imageSize,
        height: imageSize,
        backgroundColor: `#${user.avatarColour}`,
        borderRadius: imageSize / 2
      }}
    >
      {user.avatar && (
        <Image
          source={user.avatar}
          accessibilityLabel={`${user.firstName} ${user.lastName}`}
          resizeMode="contain"
          style={{ width: imageSize, height: imageSize }}
        />
      )}
      {!user.avatar && (
        <Text
          style={{
            color: '#fff',
            fontSize: fontSizes[fontSize],
            lineHeight: 1.2
          }}
        >
          {userInitials}
        </Text>
      )}
    </AvatarWrapper>
  )
}
