export function Controller(ActivitiesField) {
  'ngInject'

  var vm = this

  vm.control = {
    openRecord: openRecord
  }

  vm.from = null
  vm.to = null

  ////////////

  vm.$onInit = function () {
    var info = ActivitiesField.info(vm.data)

    vm.from = info.from
    vm.to = info.to
  }

  function openRecord() {
    vm.openRecord()
  }
}
