import * as React from 'react'
import { View, Text, Image, Platform } from 'reactxp'
import { spacing, colors, fontSizes } from '../../styles'
import { AccountUpload } from '../models/'
import { ViewProps } from 'reactxp/src/common/Types'
import { Button, Icon } from '../../components'
import { createStyledView } from '../../components/styled'
import { isNumber, isNaN } from 'lodash'
import { getPlatformStyle } from '../../services/platform'

interface Props extends ViewProps {
  file: AccountUpload
  containerWidth: number
  height?: number
  itemsPerRow?: number
  onDeleteUpload?: (id: string) => void
  onPressUpload?: (upload: AccountUpload) => void
  editable: boolean
}

interface ContainerValues {
  width: number
  height: number
  x: number
  yield: number
}

const ProgressBarContainer = createStyledView({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 20,
  marginTop: 5,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.neutralTintFive
})

const UploadThumbnailItem = createStyledView({
  padding: spacing.half,
  justifyContent: 'center',
  alignItems: 'stretch',
  borderWidth: 1,
  borderColor: colors.neutralTintFive,
  overflow: 'visible',
  margin: 5
})

const DeleteContainer = createStyledView({
  position: 'absolute',
  height: 25,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: colors.negative,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
})
const UploadWrapper = createStyledView({
  flexDirection: 'row',
  height: 80,
  alignItems: 'stretch',

  ...getPlatformStyle({
    web: {
      cursor: 'pointer'
    }
  })
})

export const UploadThumbnail = ({
  file,
  containerWidth,
  height = 100,
  onDeleteUpload,
  itemsPerRow = 3,
  onPressUpload,
  editable
}: Props) => {
  const [progressBarContainerValues, setProgressBarContainerValues] = React.useState({} as ContainerValues)
  // Set as 9999 so it does not display the progress bar initially
  const [progressBarValue, setProgressBarValue] = React.useState(9999)
  const [showDelete, setShowDelete] = React.useState(false)
  const [fileSource, setFileSource] = React.useState(null)
  const uploadItem = React.useRef(null)

  const thumbnailWidth = (containerWidth - itemsPerRow * spacing.half) / itemsPerRow

  const handlePress = (event) => {
    if (uploadItem && uploadItem.current && uploadItem.current.contains(event.target)) {
      return
    }

    setShowDelete(false)
  }

  React.useEffect(() => {
    const platform = Platform.getType()
    const pressEvent = platform === 'web' ? 'click' : 'touchstart'

    if (showDelete) {
      document.addEventListener(pressEvent, handlePress)
    } else {
      document.removeEventListener(pressEvent, handlePress)
    }

    return () => {
      document.removeEventListener(pressEvent, handlePress)
    }
  }, [showDelete])

  React.useEffect(() => {
    const { width } = progressBarContainerValues

    if (width) {
      const newWidth = width - (file.progress * width) / 100

      if (newWidth !== progressBarValue) {
        setProgressBarValue(width - (file.progress * width) / 100)
      }
    }
    setFileSource(file.uploaded ? `/api${file.view}&width=150&height=150` : file.view)
  })

  return (
    <UploadThumbnailItem style={{ width: thumbnailWidth, height }}>
      {file && file.error && <Text>Unable to upload file</Text>}
      {file && !file.mime && (
        <View style={{ alignSelf: 'center', justifyContent: 'center' }}>
          <Icon size={80} name="file"></Icon>
        </View>
      )}
      {file && file.mime && file.mime.includes('pdf') && (
        <View style={{ alignSelf: 'center', justifyContent: 'center' }} onPress={() => onPressUpload(file)}>
          <Icon size={80} name="fPdf"></Icon>
        </View>
      )}
      {file && fileSource && file.mime && file.mime.includes('image') && (
        <UploadWrapper onPress={() => onPressUpload(file)}>
          <Image
            style={{
              opacity: file.uploading ? 0.5 : 1,
              flexGrow: 1,
              borderRadius: 3
            }}
            source={fileSource}
          />
        </UploadWrapper>
      )}
      {editable && (
        <View style={{ position: 'absolute', top: -5, right: -5, overflow: 'visible' }}>
          <Button
            icon={'close'}
            theme={{
              iconColor: 'neutralTintThree',
              backgroundColor: 'neutralTintSix',
              round: 50,
              paddingHorizontal: 5,
              paddingVertical: 5,
              size: 'base'
            }}
            onPress={() => setShowDelete(true)}
          />
        </View>
      )}
      {!file.uploading && showDelete && (
        <div ref={uploadItem}>
          <DeleteContainer onPress={() => onDeleteUpload(file.id)}>
            <Text style={{ color: 'white', fontSize: fontSizes.small }}>DELETE</Text>
          </DeleteContainer>
        </div>
      )}
      {file.uploading && (
        <ProgressBarContainer onLayout={setProgressBarContainerValues}>
          <View
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: isNumber(progressBarValue) && !isNaN(progressBarValue) ? progressBarValue : 9999,
              bottom: 0,
              backgroundColor: colors.positive,
              alignItems: 'center'
            }}
          />
          {file.progress >= 0 && file.progress < 100 && (
            <Text
              style={{
                color: 'white'
              }}>
              {file.progress}%
            </Text>
          )}
          {file.progress === 100 && <Text style={{ color: 'white' }}>Finalising...</Text>}
        </ProgressBarContainer>
      )}
    </UploadThumbnailItem>
  )
}
