export function Controller($state) {
  'ngInject'

  var vm = this

  vm.control = {
    goToFolder: goToFolder
  }

  function goToFolder() {
    $state.go('folder', { folderId: vm.data.folder.id })
  }
}
