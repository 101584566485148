export function TasksResolver($state) {
  'ngInject'

  const resolve = ({ taskId, additionalParams = {} }) => {
    const params = { taskId, ...additionalParams }

    if ($state.get(`${$state.current.name}.task`)) {
      $state.go(`${$state.current.name}.task`, params)
    } else if ($state.get(`^.task`)) {
      $state.go(`^.task`, params)
    } else if ($state.get(`^.^.task`)) {
      $state.go(`^.^.task`, params)
    } else {
      $state.go(`account.tasks.task`, params)
    }
  }

  return {
    resolve
  }
}
