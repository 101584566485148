export function foldersMoveConfirmModal($uibModal) {
  'ngInject'

  let modal

  let service = {
    openModal: openModal
  }

  return service

  function openModal(movedFolder, initialFolder, droppedFolder) {
    modal = $uibModal.open({
      backdrop: 'static',
      component: 'ptFoldersMove',
      resolve: {
        movedFolder: () => movedFolder,
        initialFolder: () => initialFolder,
        droppedFolder: () => droppedFolder
      }
    })

    modal.result.catch(function () {})

    return modal
  }
}
