export function ptTimeAgo($window, moment, amMoment, amTimeAgoConfig, angularMomentConfig, session, gettextCatalog) {
  'ngInject'

  let directive = {
    link: link,
    scope: {
      ptTimeAgo: '='
    }
  }

  return directive

  function link(scope, element, attr) {
    let activeTimeout = null
    let currentValue
    let currentFormat = angularMomentConfig.format
    let withoutSuffix = amTimeAgoConfig.withoutSuffix
    let titleFormat = amTimeAgoConfig.titleFormat
    let fullDateThreshold = amTimeAgoConfig.fullDateThreshold
    let fullDateFormat = amTimeAgoConfig.fullDateFormat
    let localDate = new Date().getTime()
    let preprocess = angularMomentConfig.preprocess
    let isTimeElement = element[0].nodeName.toUpperCase() === 'TIME'

    init()

    function init() {
      currentValue = scope.ptTimeAgo
      updateMoment()
    }

    function getNow() {
      let now
      let localNow
      let nowMillis
      let timezone = angularMomentConfig.timezone

      if (session && session.user) {
        timezone = session.user.timezone
      }

      if (amTimeAgoConfig.serverTime) {
        localNow = new Date().getTime()
        nowMillis = localNow - localDate + amTimeAgoConfig.serverTime
        now = moment.tz(nowMillis, timezone)
      } else {
        now = moment.tz(timezone)
      }

      return now
    }

    function cancelTimer() {
      if (activeTimeout) {
        $window.clearTimeout(activeTimeout)
        activeTimeout = null
      }
    }

    function updateTime(momentInstance) {
      let now = getNow()
      let showFullDate = fullDateThreshold && now.diff(momentInstance, 'day') >= fullDateThreshold
      let howOld
      let secondsUntilUpdate

      if (showFullDate) {
        element.text(momentInstance.format(fullDateFormat))
      } else if (now.isSame(momentInstance, 'd')) {
        /// Relative date
        element.text(momentInstance.format('[' + gettextCatalog.getString('Today') + ']'))
      } else if (moment(now).subtract(1, 'd').isSame(momentInstance, 'd')) {
        /// Relative date
        element.text(momentInstance.format('[' + gettextCatalog.getString('Yesterday') + ']'))
      } else if (moment(now).add(1, 'd').isSame(momentInstance, 'd')) {
        /// Relative date
        element.text(momentInstance.format('[' + gettextCatalog.getString('Tomorrow') + ']'))
      } else {
        element.text(momentInstance.from(now, withoutSuffix))
      }

      if (titleFormat && !element.attr('title')) {
        element.attr('title', momentInstance.local().format(titleFormat))
      }

      if (!showFullDate) {
        howOld = Math.abs(now.diff(momentInstance, 'minute'))
        secondsUntilUpdate = null

        if (howOld < 1) {
          secondsUntilUpdate = 1
        } else if (howOld < 60) {
          secondsUntilUpdate = 30
        } else if (howOld < 180) {
          secondsUntilUpdate = 300
        }

        if (secondsUntilUpdate) {
          activeTimeout = $window.setTimeout(function () {
            updateTime(momentInstance)
          }, secondsUntilUpdate * 1000)
        }
      }
    }

    function updateDateTimeAttr(value) {
      if (isTimeElement) {
        element.attr('datetime', value)
      }
    }

    function updateMoment() {
      let momentValue
      let momentLocalValue

      cancelTimer()

      if (currentValue) {
        momentValue = amMoment.preprocessDate(currentValue, preprocess, currentFormat)
        momentLocalValue = amMoment.applyTimezone(momentValue)
        calculateSuffix(momentLocalValue)

        updateTime(momentLocalValue)
        updateDateTimeAttr(momentLocalValue.toISOString())
      }
    }

    function calculateSuffix(momentValue) {
      if (getNow().isAfter(momentValue)) {
        withoutSuffix = false
      } else {
        withoutSuffix = true
      }
    }

    scope.$watch('ptTimeAgo', function (value) {
      if (typeof value === 'undefined' || value === null || value === '') {
        cancelTimer()

        if (currentValue) {
          element.text('')
          updateDateTimeAttr('')
          currentValue = null
        }

        return
      }

      currentValue = value
      updateMoment()
    })

    attr.$observe('format', function (format) {
      if (typeof format !== 'undefined') {
        currentFormat = format
        updateMoment()
      }
    })

    attr.$observe('preprocess', function (newValue) {
      preprocess = newValue
      updateMoment()
    })

    attr.$observe('fullDateThreshold', function (newValue) {
      fullDateThreshold = newValue
      updateMoment()
    })

    attr.$observe('fullDateFormat', function (newValue) {
      fullDateFormat = newValue
      updateMoment()
    })

    scope.$on('$destroy', function () {
      cancelTimer()
    })

    scope.$on('amMoment:localeChanged', function () {
      updateMoment()
    })
  }
}
