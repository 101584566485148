import view from './view.html'
import { Controller } from './controller'

export const ptRecordField = {
  bindings: {
    field: '<',
    record: '<',
    onSaveField: '&'
  },
  template: view,
  controller: Controller
}
