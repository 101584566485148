export function guidGenerator() {
  'ngInject'

  let service = {
    create: create
  }
  return service

  ////////////////

  function create() {
    return guid()
  }

  function s4() {
    /* jslint bitwise: true */
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    /* jslint bitwise: false */
  }

  function guid() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  }
}
