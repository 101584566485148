import _sortBy from 'lodash/sortBy'

export function Controller(
  $timeout,
  $stateParams,
  accountsData,
  SubscriptionsData,
  ErrorsModal,
  gettextCatalog,
  TemplatesData,
  $scope
) {
  'ngInject'

  const $ctrl = this
  const s = {
    /// Field label
    accountName: gettextCatalog.getString('Account name'),
    /// Field placeholder
    accountNamePlaceholder: gettextCatalog.getString('Enter account name...'),
    /// Field label - The subscription that owns this account
    parentSubscription: gettextCatalog.getString('Parent subscription'),
    /// Select box default
    selectSubscription: gettextCatalog.getString('select subscription'),
    /// Radio input options
    createDefaultAccount: gettextCatalog.getString('Create a standard account'),
    /// Field label
    options: gettextCatalog.getString('Options'),
    /// Radio input options
    createDemoAccount: gettextCatalog.getString('Create a demo account'),
    /// Radio input options
    cloneAccount: gettextCatalog.getString('Clone an existing account'),
    /// Field label - Select the account template to use
    selectTemplate: gettextCatalog.getString('Select module'),
    /// Field label - Select the demo account to clone
    selectDemo: gettextCatalog.getString('Select demo module'),
    /// Field label
    accountToClone: gettextCatalog.getString('Account to clone'),
    /// Select box default
    selectAccount: gettextCatalog.getString('select account'),
    /// Checkbox label
    cloneFolders: gettextCatalog.getString('Clone folders'),
    /// Checkbox label
    cloneRecords: gettextCatalog.getString('Clone records'),
    /// Checkbox label
    makeDemoAccount: gettextCatalog.getString('Make this a demo account')
  }

  $ctrl.control = {
    save: save
  }

  $ctrl.saving = false
  $ctrl.error = null
  $ctrl.showWaitMessage = false

  $ctrl.model = {
    name: '',
    options: 'default',
    subscription_id: null,
    template: 'default',
    with_data: false,
    with_folders: true,
    with_records: false,
    demo: false
  }
  $ctrl.formFields = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: s.accountName,
        placeholder: s.accountNamePlaceholder,
        required: true,
        ptFocus: true
      },
      ngModelAttrs: {
        ptFocus: {
          attribute: 'pt-focus'
        }
      }
    },
    {
      key: 'subscription_id',
      type: 'select',
      templateOptions: {
        label: s.parentSubscription,
        required: true,
        options: [],
        ngOptions: 'subscription.id as subscription.name for subscription in to.options'
      },
      hideExpression: function () {
        return !$ctrl.subsLoaded || $ctrl.subscriptions.length === 1 || $stateParams.subscriptionId
      }
    },
    {
      key: 'options',
      type: 'radio',
      templateOptions: {
        label: s.options,
        options: [
          {
            name: s.createDefaultAccount,
            value: 'default'
          },
          {
            name: s.createDemoAccount,
            value: 'with_data'
          },
          {
            name: s.cloneAccount,
            value: 'clone'
          }
        ]
      }
    },
    {
      key: 'template',
      type: 'select',
      templateOptions: {
        label: s.selectTemplate,
        options: [],
        ngOptions: 'type.key as type.name for type in to.options'
      },
      hideExpression: function () {
        return $ctrl.model.options !== 'default'
      }
    },
    {
      key: 'template',
      type: 'select',
      templateOptions: {
        label: s.selectDemo,
        options: $ctrl.resolve.templates.demoTemplates,
        ngOptions: 'type.key as type.name for type in to.options'
      },
      hideExpression: function () {
        return $ctrl.model.options !== 'with_data'
      }
    },
    {
      template:
        '<p><a pt-contact="' +
        gettextCatalog.getString('What module or account setup would you like?') +
        '">' +
        gettextCatalog.getString('Want a different module?') +
        '</a></p>'
    },
    {
      key: 'account_id',
      type: 'select',
      templateOptions: {
        label: s.accountToClone,
        required: true,
        options: [{ name: 'Loading accounts....' }],
        ngOptions: 'account.id as account.name for account in to.options'
      },
      hideExpression: function () {
        return $ctrl.model.options !== 'clone'
      }
    },
    {
      key: 'with_folders',
      type: 'checkbox',
      templateOptions: {
        label: s.cloneFolders
      },
      hideExpression: function () {
        return $ctrl.model.options !== 'clone'
      }
    },
    {
      key: 'with_records',
      type: 'checkbox',
      templateOptions: {
        label: s.cloneRecords
      },
      hideExpression: function () {
        return $ctrl.model.options !== 'clone' || !$ctrl.model.with_folders
      }
    },
    {
      key: 'demo',
      type: 'checkbox',
      templateOptions: {
        label: s.makeDemoAccount
      },
      hideExpression: function () {
        return $ctrl.model.options !== 'clone'
      }
    }
  ]

  //////////

  $ctrl.$onInit = function () {
    if ($stateParams.subscriptionId) {
      $ctrl.model.subscription_id = $stateParams.subscriptionId
    } //else if ($ctrl.resolve.subscriptions.length === 1) {
    // Select the only subscription, as the select option will be hidden
    //$ctrl.model.subscription_id = $ctrl.resolve.subscriptions[0].id
    //}
    loadTemplates()
    loadAccounts()
    loadSubscriptions()

    $scope.$watch(
      '$ctrl.model.subscription_id',
      function (newValue) {
        loadTemplates()
      },
      true
    )
  }

  function loadAccounts() {
    accountsData.getAll(accountsData.init()).then((accounts) => {
      $ctrl.formFields[6].templateOptions.options = [{ name: '-- ' + s.selectAccount + ' --' }].concat(accounts)
    })
  }

  function loadSubscriptions() {
    if ($stateParams.subscriptionId) {
      $ctrl.subscriptions = [{ id: $stateParams.subscriptionId }]
      $ctrl.subsLoaded = true
    } else {
      SubscriptionsData.getAll(SubscriptionsData.init()).then(function (subscriptions) {
        if (subscriptions.length) {
          $ctrl.subscriptions = subscriptions
          $ctrl.formFields[1].templateOptions.options = subscriptions
          $ctrl.subsLoaded = true

          if (subscriptions.length === 1) {
            // Select the only subscription, as the select option will be hidden
            $ctrl.model.subscription_id = subscriptions[0].id
          }
        } else {
          ErrorsModal.openModal('no_subscriptions_for_account')
          throw 'no_subscriptions_for_account'
        }
      })
    }
  }

  function loadTemplates() {
    if ($ctrl.model.subscription_id) {
      TemplatesData.getForSubscription($ctrl.model.subscription_id).then((templates) => {
        const standardTemplates = _sortBy([...$ctrl.resolve.templates.standardTemplates, ...templates], 'name')
        $ctrl.formFields[3].templateOptions.options = standardTemplates
      })
    }
  }

  function save() {
    let data: any = {}

    if ($ctrl.form.$valid) {
      $ctrl.saving = true
      $ctrl.error = null

      data.name = $ctrl.model.name
      data.subscription_id = $ctrl.model.subscription_id

      if ($ctrl.model.options === 'default') {
        data.template = $ctrl.model.template
      }

      // Set demo flag
      if ($ctrl.model.options === 'with_data') {
        data.demo = true
        data.with_data = true
        data.template = $ctrl.model.template
      }

      if ($ctrl.model.options === 'clone') {
        data.demo = $ctrl.model.demo
        data.account_id = $ctrl.model.account_id
        data.with_folders = $ctrl.model.with_folders
        data.with_records = $ctrl.model.with_folders && $ctrl.model.with_records
      }

      accountsData.create(data).then(saveSuccess, saveError)

      $timeout(function () {
        $ctrl.showWaitMessage = true
      }, 4000)
    }
  }

  function saveSuccess() {
    $ctrl.dismiss({ $value: 'saved' })
  }

  function saveError(rejection) {
    $ctrl.saving = false
    $ctrl.error = rejection.$response.data.error
  }
}
