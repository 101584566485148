import { Controller } from './controller'
import view from './view.html'

export const ptExportGeneral = {
  bindings: {
    onCloseModal: '&',
    type: '<',
    batch: '<',
    recordIds: '<'
  },
  controller: Controller,
  template: view
}
