export function RecordsBatchChecklistRoute($stateProvider) {
  'ngInject'

  let batchChecklistRecordModal

  $stateProvider.state('bucket.checklist-records', checklistRecordsStates())
  $stateProvider.state('folders.checklist-records', checklistRecordsStates())
  $stateProvider.state('folder.checklist-records', checklistRecordsStates())
  $stateProvider.state('folder.scan.checklist-records', checklistRecordsStates())
  $stateProvider.state('folders.scan.checklist-records', checklistRecordsStates())
  $stateProvider.state('account.scan.checklist-records', checklistRecordsStates())

  function checklistRecordsStates() {
    return {
      url: '/records/checklist?{recordIds}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: editRecordPermission,
        resolvedRecords: resolveTagRecords
      },
      onEnter: onBatchChecklistRecordEnter,
      onExit: onBatchChecklistRecordExit
    }
  }

  function resolveTagRecords(account, $stateParams, recordsResolves, ErrorsDisplay) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    let selection = recordsResolves.recordIdsSelection($stateParams)

    return recordsResolves.records(selection, 'checklists,fields,folder').catch(function () {
      return ErrorsDisplay.catch('select_records')
    })
  }

  function editRecordPermission(account, member, permissionsCheck, ErrorsResolve) {
    'ngInject'

    return permissionsCheck.canPromise('edit', 'records', account).catch(ErrorsResolve.caught)
  }

  function onBatchChecklistRecordEnter($uibModal, $state, resolvedRecords) {
    'ngInject'

    batchChecklistRecordModal = $uibModal.open({
      component: 'ptRecordsBatchChecklist',
      resolve: {
        records: () => resolvedRecords
      }
    })

    batchChecklistRecordModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onBatchChecklistRecordExit() {
    'ngInject'

    batchChecklistRecordModal.close()
  }
}
