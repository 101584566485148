export function link(scope, element) {
  let scanString = ''
  let timeoutHandler = null

  if (scope.vm.control.canNotScan()) {
    return
  }

  element.keypress(function (e) {
    keypress(e)
  })

  function keypress(e) {
    let character = String.fromCharCode(e.which)

    if (e.which === 13) {
      // If they want to force search, let them
      if(element[0].value.length > 0) {
        scanString = element[0].value
        timeout()
      }
      e.preventDefault()
      return false
    }

    if (character.match(/^[A-Za-z0-9.\-_*@#&+-]{1}$/i)) {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler)
      }

      scanString = scanString + character

      timeoutHandler = setTimeout(timeout, 100)
    }

    return false
  }

  function timeout() {
    let doScan = scanString.length >= 5

    if (doScan) {
      scanned()
      element[0].value = ''
    }

    scanString = ''
  }

  function scanned() {
    scope.vm.ptScanSubmit({
      scanString: scanString
    })
  }
}
