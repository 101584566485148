import { Controller } from './controller'
import view from './view.html'

export const ptIauditorFilterHeader = {
  bindings: {
    customFields: '<',
    onTogglePanel: '&'
  },
  controller: Controller,
  template: view
}
