import { getPieChartPDF } from 'AppModules/reports/services/pdf'

export function Controller($scope, session, ReportsData, context, BASE_URL, $timeout, fileHandler, isIe) {
  'ngInject'

  const $ctrl = this

  $ctrl.generating = false
  $ctrl.error = false
  $ctrl.isIe = isIe

  $ctrl.formModel = {
    exportType: 'pdf'
  }

  $ctrl.$onInit = () => {}

  $ctrl.$onChanges = (changes) => {
    if (changes.resolve) {
      $ctrl.params = $ctrl.resolve.params
      $ctrl.report = $ctrl.resolve.report
    }
  }

  $ctrl.export = (method) => {
    if ($ctrl.formModel.exportType === 'pdf' && $ctrl.report === 'record_state_compliance') {
      let name = `${session.user.firstName} ${session.user.lastName}`
      getPieChartPDF(method, context.get('account').name, name, context.get('account').logo).then(() => {
        $ctrl.dismiss()
      })
    } else {
      let newWindow

      if (method === 'open') {
        newWindow = window.open('/exporting.html')
      }

      $ctrl.params.type = $ctrl.formModel.exportType
      $ctrl.generating = true
      $ctrl.error = false

      ReportsData.generateReportExport(context.get('account').id, $ctrl.report, $ctrl.params)
        .then((data) => {
          if (method === 'open') {
            newWindow.location.href = BASE_URL + data.view
          } else {
            fileHandler.download(data.download)
          }

          $ctrl.dismiss()
        })
        .catch(() => {
          $ctrl.error = true

          if (method === 'open') {
            newWindow.close()
          }
        })
        .finally(() => {
          $ctrl.generating = false
        })
    }
  }
}
