export const colors = {
  // common
  white: '#FFFFFF',
  black: '#000000',
  clear: '#00000000',

  // primary
  primary: '#EB3745',
  primaryLightest: '#F7AFB7',
  primaryLight: '#F1737E',
  primaryDark: '#8B1F29',
  primaryDarkest: '#430D14',

  // secondary
  secondary: '#007AFF',
  secondaryLightest: '#8FC1F7',
  secondaryLight: '#479DFB',
  secondaryDark: '#004A97',
  secondaryDarkest: '#002649',

  // neutral
  neutral: '#213854',
  neutralTintOne: '#354A63',
  neutralTintTwo: '#5D6E82',
  neutralTintThree: '#718092',
  neutralTintFour: '#8592A1',
  neutralTintFive: '#C2C8D0',
  neutralTintSix: '#E1E4E8',
  neutralTable: '#F0F1F3',

  // validation
  positive: '#18A678',
  warning: '#F7A503',
  negative: '#FC2F3F'
}

export const spacing = {
  quarter: 20 / 4,
  third: 20 / 3,
  half: 20 / 2,
  base: 20,
  double: 20 * 2,
  triple: 20 * 3,
  quadruple: 20 * 4
}

export const fontSizes = {
  small: 14,
  base: 16,
  medium: 20,
  large: 24,
  huge: 30,
  massive: 36
}

export const iconSizes = {
  tiny: 12,
  small: 14,
  base: 16,
  medium: 22,
  large: 30,
  huge: 50,
  massive: 66
}

export type Color = keyof typeof colors
export type Spacing = keyof typeof spacing
export type FontSize = keyof typeof fontSizes
export type IconSize = keyof typeof iconSizes
