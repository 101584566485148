import _forEach from 'lodash/forEach'
import _debounce from 'lodash/debounce'

export function recordsEvents() {
  'ngInject'

  let $scope
  let vm
  let eventControl
  let getRecordsThrottled

  let service = {
    handle: handle
  }

  return service

  /////////////

  function handle(_$scope, _vm, _eventControl) {
    $scope = _$scope
    vm = _vm
    eventControl = _eventControl

    setup()
    listen($scope)
  }

  function setup() {
    getRecordsThrottled = function (ms) {
      return _debounce(eventControl.getRecords, ms)
    }
  }

  function listen($scope) {
    let recordEvents = [
      'record_was_created',
      'record_was_imported',
      'record_was_cloned',
      'record_was_updated',
      'record_was_moved',
      'record_was_deleted',
      'record_was_undeleted',
      'record_upload_was_added',
      'record_upload_was_updated',
      'certificate_was_created',
      'log_was_created',
      'log_was_updated',
      'log_was_deactivated',
      'log_was_activated',
      'field_was_created',
      'field_was_updated',
      'field_was_deleted',
      'service_log_was_created',
      'service_certificate_was_created'
    ]

    let sortEvents = ['sort:changed']

    _forEach(recordEvents, function (event) {
      $scope.$on(
        '$destroy',
        $scope.$on(event, function () {
          getRecordsThrottled(1000)()
        })
      )
    })

    _forEach(sortEvents, function (event) {
      $scope.$on(
        '$destroy',
        $scope.$on(event, function () {
          getRecordsThrottled(250)()
        })
      )
    })
  }
}
