import { Controller } from './controller'
import view from './view.html'

export const ptFutureInspectionsWidget = {
  bindings: {
    widget: '<'
  },
  controller: Controller,
  template: view
}
