import { Controller } from './controller'
import view from './view.html'

export const ptItemPicker = {
  bindings: {
    getAvailableItems: '&',
    selectedItems: '<',
    onSave: '&',
    onItemClick: '&',
    onItemChange: '&',
    popover: '<',
    pickerOptions: '<'
  },
  controller: Controller,
  template: view
}
