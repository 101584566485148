export function ptNotificationRoute($stateProvider) {
  'ngInject'

  let notificationModal

  notificationViewState('notifications')

  function notificationViewState(parent) {
    'ngInject'

    $stateProvider.state(parent + '.notification', {
      url: '/notification/{notificationId}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        notificationResolves: resolveNotification
      },
      onEnter: onNotificationEnter,
      onExit: onNotificationExit
    })
  }

  function onNotificationEnter($uibModal, $state, notificationResolves) {
    'ngInject'

    notificationModal = $uibModal.open({
      component: 'ptNotification',
      resolve: {
        notification: () => notificationResolves
      },
      size: 'xxl'
    })

    notificationModal.result.catch(() => {
      $state.go('^')
    })
  }

  function onNotificationExit() {
    'ngInject'

    notificationModal.close()
  }

  function resolveNotification(NotificationsData, $stateParams) {
    'ngInject'

    return NotificationsData.getNotificationById($stateParams.notificationId)
  }
}
