import { Controller } from './controller'
import { Link } from './link'
import view from './view.html'

export function ptActivityRecordMovedToAccount() {
  var directive = {
    template: view,
    link: Link,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'E',
    scope: {
      data: '=',
      openRecord: '&?openRecord'
    }
  }

  return directive
}
