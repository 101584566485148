export function Controller($stateParams) {
  'ngInject'

  const $ctrl = this

  $ctrl.$onInit = function () {
    if ($stateParams.accounts) {
      $ctrl.accounts = $stateParams.accounts.split(',').length
    }

    if ($stateParams.read === 'true') {
      $ctrl.read = true
    }
  }
}
