export function Controller(richText) {
  'ngInject'

  var vm = this

  vm.control = {
    openRecord: openRecord
  }

  vm.richText = richText

  vm.$onInit = function () {}

  function openRecord() {
    vm.openRecord()
  }
}
