export function ptImportsRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('imports', {
    parent: 'layoutFull',
    url: '/imports',
    views: {
      'content@layoutFull': {
        component: 'ptImports'
      }
    },
    resolve: {
      subscriptions: resolveSubscriptions
    },
    onEnter: onImportsEnter
  })

  function onImportsEnter(gettextCatalog, page) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Imports'))
  }

  function resolveSubscriptions($stateParams, SubscriptionsData) {
    'ngInject'

    return SubscriptionsData.getAll(SubscriptionsData.init()).then(function (subscriptions) {
      return subscriptions
    })
  }
}
