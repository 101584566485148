export function ptScansRoute($stateProvider) {
  'ngInject'

  let scanModal

  scanState('folder')
  scanState('folders')
  scanState('account')

  function scanState(parent) {
    $stateProvider.state(parent + '.scan', {
      url: '/scan',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      onEnter: onScanEnter,
      onExit: onScanExit
    })
  }

  function onScanEnter($uibModal, $state) {
    'ngInject'

    scanModal = $uibModal.open({
      component: 'ptScans'
    })

    scanModal.result.catch(function () {
      $state.go('^')
    })
  }

  function onScanExit() {
    'ngInject'

    scanModal.close()
  }
}
