import _find from 'lodash/find'

export function FrequenciesData() {
  'ngInject'

  const service = {
    get: get,
    defaultFrequency: defaultFrequency
  }

  return service

  ////////////////

  function get(account, id?) {
    const frequencies = account.frequencies.sort((a, b) => a.order - b.order)

    if (id) {
      return _find(frequencies, { id: id })
    }

    return frequencies
  }

  function defaultFrequency(account) {
    return _find(get(account), { default: true })
  }
}
