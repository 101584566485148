import { Controller } from './controller'
import view from './view.html'

export const ptRecordsBrowser = {
  bindings: {
    records: '<',
    loading: '<',
    onRecordSelected: '&',
    onFolderChange: '&'
  },
  controller: Controller,
  template: view
}
