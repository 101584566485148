import _find from 'lodash/find'

export const AccessesData = ($q, context, collections, EventsData) => {
  'ngInject'

  let currentAccessesCache = null
  let resolving = null

  const folderAccess = (folderId) => {
    const defer = $q.defer()
    const access = checkAccess(folderId)

    if (context.get('member').role === 'employee') {
      // Employees can't star a folder
      defer.resolve(null)
    } else if (access) {
      // Check if the folder is available in the cache and resolve if bool
      defer.resolve(access)
    } else if (resolving) {
      resolving.then(() => {
        resolving = null
        defer.resolve(checkAccess(folderId))
      })
    } else {
      // fetch all accesses and resolve bool or undefined
      resolving = refreshCache().then(() => {
        resolving = null
        defer.resolve(checkAccess(folderId))
      })
    }

    return defer.promise
  }

  const pushAccessUpdatedEvent = (access, folderId) =>
    EventsData.pushEvent('access_was_updated', {
      folderId,
      accessId: access.id,
      starred: access.starred
    })

  const saveAccess = (access, folderId) =>
    access
      .$save(['starred'])
      .$asPromise()
      .then((result) => pushAccessUpdatedEvent(result, folderId))
      .then(refreshCache)

  const checkAccess = (folderId) => {
    const access = _find(currentAccessesCache, function (item) {
      return item.folder.id === folderId
    })

    if (!currentAccessesCache) {
      return null
    }

    return (access && access) || null
  }

  const refreshCache = () =>
    getAll().then((accesses) => {
      currentAccessesCache = accesses
      return accesses
    })

  const getAll = () => collections.getAll(context.get('account').members.$new('me').accesses.$collection())

  return {
    folderAccess: folderAccess,
    saveAccess: saveAccess
  }
}
