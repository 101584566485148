import * as React from 'react'
import { colors, spacing } from '../styles'
import { View, ScrollView } from 'reactxp'
import { Button } from '.'
import { FlexRow } from './styled'

interface InputDropdownProps {
  children: React.ReactNode
  minHeight?: number
  maxHeight?: number
  position?: {
    top?: number
    right?: number
    left?: number
  }
  closeDropdown: () => void
}

const defaultPosition = {
  top: 0,
  right: spacing.base,
  left: spacing.base
}

export class InputDropdown extends React.Component<InputDropdownProps> {
  public render() {
    const { children, minHeight = 0, maxHeight = 300, position, closeDropdown } = this.props
    const pos = { ...defaultPosition, ...position }
    const wrapperStyles = {
      top: pos.top,
      left: pos.left,
      right: pos.right,
      boxShadow: `0px 0px 20px 0px ${colors.neutralTintSix}`,
      backgroundColor: '#fff'
    }

    return (
      <View style={[{ position: 'absolute', overflow: 'visible' }, wrapperStyles]}>
        <div
          style={{
            bottom: '100%',
            left: '50%',
            border: 'solid transparent',
            height: 0,
            width: 0,
            position: 'absolute',
            borderBottomColor: '#fff',
            borderWidth: '5px',
            marginLeft: '-5px'
          }}
        />
        <ScrollView style={{ minHeight, maxHeight }}>{children}</ScrollView>
        <FlexRow
          style={{
            padding: 20,
            borderColor: colors.neutralTintSix,
            borderWidth: 0,
            borderTopWidth: 1
          }}
        >
          <Button onPress={closeDropdown} label="Close" />
        </FlexRow>
      </View>
    )
  }
}
