export function Controller() {
  'ngInject'

  let vm = this

  vm.control = {
    closeModal: closeModal,
    confirm: confirm
  }

  vm.$onInit = () => {
    vm.movedFolderName = vm.resolve.movedFolder.name
    vm.initialFolderName = vm.resolve.initialFolder && vm.resolve.initialFolder.name
    vm.droppedFolderName = vm.resolve.droppedFolder && vm.resolve.droppedFolder.name
  }

  function confirm() {
    vm.close()
  }

  function closeModal() {
    vm.dismiss({ $value: 'cancel' })
  }
}
