import angular from 'angular'

export function Controller($state) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    markAsRead,
    openRecord
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.notification) {
      $ctrl.notification = angular.copy($ctrl.notification)
    }

    if (changes.view) {
      $ctrl.view = angular.copy($ctrl.view)
    }
  }

  function markAsRead(event) {
    // Send our own onRead event back up to our components
    $ctrl.onRead()
    // Prevent clicks from firing on parent components. e.g.:
    // The notification page has a ui-sref which would open a modal.
    event.stopPropagation()
  }

  function openRecord() {
    $state.go('account.records', {
      accountId: $ctrl.notification.account.data.id,
      recordId: $ctrl.notification.meta.record.id
    })
  }
}
