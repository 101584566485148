import { Controller } from './controller'
import view from './view.html'

export const ptFolder = {
  bindings: {
    folder: '<',
    treeSegment: '<',
    hasManyFolders: '<'
  },
  controller: Controller,
  template: view
}
