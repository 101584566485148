export function CountriesData(CountriesModel) {
  'ngInject'

  let countriesCache = null

  const service = {
    get
  }

  return service

  ////////////////

  function get() {
    if (countriesCache) {
      return countriesCache
    }

    return CountriesModel.$collection()
      .$fetch()
      .$asPromise()
      .then(function (countries) {
        countriesCache = countries
        return countries
      })
  }
}
