export function ErrorsDisplay($q, EventsData, ErrorsModal, $state, session) {
  'ngInject'

  const service = {
    catch: catchError
  }

  return service

  /////////////

  function catchError(reason) {
    EventsData.broadcast('routeResolver:resolved')

    ErrorsModal.openModal(reason, function () {
      // If there is no page to display after displaying the modal (first page load),
      // or the user is still on the login page (after being logged in), take them to the accounts page.
      if (!$state.current.name || (session.user && $state.is('login'))) {
        $state.go('accounts')
      }
    })

    return $q.reject(reason)
  }
}
