import { Controller } from './controller'
import view from './view.html'

export function ptInvitationsTable() {
  'ngInject'

  let directive = {
    restrict: 'A',
    template: view,
    controller: Controller,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      invitations: '<',
      buttonContent: '<',
      onAccept: '&'
    }
  }

  return directive
}
