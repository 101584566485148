import angular from 'angular'

import _forEach from 'lodash/forEach'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _map from 'lodash/map'

export function Controller($scope, $stateParams, gettextCatalog, NotificationsData, moment, EventsData) {
  'ngInject'

  let $ctrl = this
  let filterParams: any = {
    limit: 10
  }

  $ctrl.crumbs = [
    {
      name: gettextCatalog.getString('Accounts'),
      link: 'accounts'
    }
  ]
  $ctrl.self = gettextCatalog.getString('Notifications')
  $ctrl.showFilterPanel = false
  $ctrl.canLoadMore = null
  $ctrl.loading = false
  $ctrl.accountsList = []
  $ctrl.notifications = []
  $ctrl.notificationsDisplay = []
  $ctrl.unreadInDisplay = []
  $ctrl.showReadAll = false

  $ctrl.control = {
    toggleFilterPanel: toggleFilterPanel,
    loadMore: loadMore,
    readOne: readOne,
    readCollection: readCollection,
    readEverything: readEverything,
    setupNotifications: setupNotifications
  }

  $ctrl.$onInit = function () {
    $ctrl.pendingItems = []
    listen()
    setupNotifications()

    NotificationsData.getAccounts().then(function getAccountsSuccess(result) {
      $ctrl.accountsList = result
    })
  }

  function setupNotifications() {
    $ctrl.notificationsDisplay = []
    $ctrl.unreadInDisplay = []

    if ($stateParams.accounts) {
      filterParams.account_id = $stateParams.accounts
    }

    if (!$stateParams.read || $stateParams.read !== 'true') {
      filterParams.read = false
    }

    $ctrl.notifications = NotificationsData.init(filterParams)

    loadMore()
  }

  function listen() {
    $scope.$on('notification_was_created', function (event, data) {
      $ctrl.pendingItems.push(data.data.id)
    })

    $scope.$on('notification_was_read', function (event, data) {
      // If the event came from the modal, just show the notification as read within the display.
      // else, re-init the notifications.
      if (data.from === 'modal') {
        _forEach(data.id, function (id) {
          readOneFaux(id)
        })
        setupDisplayData()
      } else if (data.from !== 'page') {
        setupNotifications()
      }
    })
  }

  function toggleFilterPanel() {
    $ctrl.showFilterPanel = !$ctrl.showFilterPanel
  }

  /**
   * Loads in more notifications from the restmod collection.
   */
  function loadMore() {
    $ctrl.loading = true

    if (filterParams.read === false) {
      filterParams.cursor = $ctrl.unreadInDisplay.length
    } else {
      filterParams.cursor = $ctrl.notifications.length
    }

    NotificationsData.loadMore($ctrl.notifications, filterParams).then(loadMoreSuccess)
  }

  /**
   * Invoked on a successful loadMore() request.
   *
   * results (Object) - Array of objects returned from the API request. Contains restmod properties.
   */
  function loadMoreSuccess(results) {
    setupDisplayData()

    $ctrl.notificationsDisplay.forEach((notification) => {
      if ($ctrl.pendingItems.indexOf(notification.id) >= 0) {
        notification.highlight = true
      }
    })

    $ctrl.pendingItems = []
    $ctrl.loading = false
    $ctrl.canLoadMore = results.$metadata.cursor.count === filterParams.limit
    $ctrl.showReadAll = results.$metadata.total_unread > $ctrl.unreadInDisplay.length
  }

  /**
   * Marks one notification as read.
   *
   * event (Object) - Data passed from the click event or child component.
   */
  function readOne(event) {
    let notification = _find($ctrl.notifications, { id: event.id })

    if (typeof notification !== 'undefined') {
      return NotificationsData.readOne(notification).then(function readOneSuccess() {
        $ctrl.notifications.$metadata.total_unread = $ctrl.notifications.$metadata.total_unread - 1
        setupDisplayData()
        EventsData.pushEvent('notification_was_read', {
          from: 'page',
          id: [notification.id]
        })
      })
    }
  }

  /**
   * Set a temporary read_at date so that it is represented in the view showing as 'read'.
   * This saves us having to do another request for the true data.
   *
   * id (String) - A notification ID
   */
  function readOneFaux(id) {
    let notification: any = _find($ctrl.notifications, { id: id })

    if (notification) {
      notification.read_at = moment().format()
    }
  }

  /**
   * Marks all unread notifications in the collection as read.
   */
  function readCollection() {
    let notifications = _filter($ctrl.notifications, { read_at: null })

    if (notifications.length) {
      return NotificationsData.readCollection(notifications)
        .then(function readCollectionSuccess() {
          _forEach(notifications, function (notification) {
            notification.read_at = moment().format()
          })

          $ctrl.notifications.$metadata.total_unread = $ctrl.notifications.$metadata.total_unread - notifications.length
          setupDisplayData()

          EventsData.pushEvent('notification_was_read', {
            from: 'page',
            id: _map(notifications, 'id')
          })
        })
        .catch(() => {})
    }
  }

  /**
   * Read every notification. Takes into account the current filter.
   * If 2 specific accounts are selected, it will only read notifications on those accounts.
   */
  function readEverything() {
    let data = {
      accounts: filterParams.account_id && filterParams.account_id.split(',')
    }

    return NotificationsData.readEverything(data)
      .then(function readEverythingSuccess(notificationIds) {
        EventsData.pushEvent('notification_was_read', {
          from: 'page',
          id: notificationIds
        })
        setupNotifications()
      })
      .catch(() => {})
  }

  /**
   * Takes a copy of the current notifications for use in the view and child components.
   * The data is copied so that it generates a new object and triggers the $onChanges event of
   * child components.
   */
  function setupDisplayData() {
    $ctrl.notificationsDisplay = angular.copy($ctrl.notifications)
    $ctrl.unreadInDisplay = _filter($ctrl.notificationsDisplay, { read_at: null })
  }
}
