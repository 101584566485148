export function validation(formlyValidationMessages, gettextCatalog) {
  'ngInject'

  formlyValidationMessages.messages.required = getRequiredMessage
  formlyValidationMessages.messages.minlength = getMinlengthMessage

  formlyValidationMessages.addStringMessage('email', gettextCatalog.getString('Enter a valid email address'))
  formlyValidationMessages.addStringMessage('emailPattern', gettextCatalog.getString('Enter a valid email address'))

  function getRequiredMessage($viewValue, $modelValue, scope) {
    if (scope.data && scope.data.useGenericMessage) {
      return gettextCatalog.getString('This field is required')
    }
    return gettextCatalog.getString('{{label}} is required', { label: scope.to.label })
  }

  function getMinlengthMessage($viewValue, $modelValue, scope) {
    return gettextCatalog.getString('Must be at least {{minlength}} characters', { minlength: scope.to.minlength })
  }
}
