export function Controller($stateParams, $state, $timeout, ActivitiesRevert, recordsModal, ErrorsModal) {
  'ngInject'

  var $ctrl = this

  $ctrl.control = {
    closeModal: closeModal,
    openRecord: openRecord,
    revert: revert
  }

  $ctrl.revertable = null
  $ctrl.checkingRevertable = true

  $ctrl.$onInit = function () {
    $ctrl.activity = $ctrl.resolve.activity

    checkRevertable()
  }

  function checkRevertable() {
    ActivitiesRevert.revertable($ctrl.activity)
      .then(function (revertable) {
        $ctrl.revertable = revertable
      })
      .catch(function () {
        $ctrl.revertable = false
      })
      .finally(function () {
        $ctrl.checkingRevertable = false
      })
  }

  function revert() {
    if ($ctrl.revertable) {
      ActivitiesRevert.revert($ctrl.activity.id)
        .then(function () {
          closeModal()
        })
        .catch(function (rejection) {
          closeModal('rejection')
          ErrorsModal.openModal(rejection.error)
        })
    }
  }

  function closeModal(reason?) {
    $ctrl.dismiss({ $value: reason || 'cancel' })
  }

  function openRecord(recordId) {
    // If we have this record open in the background and we have access to the outer control,
    // then just close the modal to get back to the relevant record

    closeModal()
    if ($stateParams.recordId !== recordId) {
      $timeout(function () {
        if ($ctrl.activity.to && $ctrl.activity.to.account && $ctrl.activity.to.account.id) {
          // Link to the record in the destination account
          recordsModal.goToModalRoute(recordId, { accountId: $ctrl.activity.to.account.id })
        } else {
          recordsModal.goToModalRoute(recordId)
        }
      })
    }
  }
}
