import _omit from 'lodash/omit'
import _cloneDeep from 'lodash/cloneDeep'
import _pickBy from 'lodash/pickBy'
import _identity from 'lodash/identity'
import _isEmpty from 'lodash/isEmpty'
import _forEach from 'lodash/forEach'
import _extend from 'lodash/extend'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _find from 'lodash/find'

export function filteringContext($state, context, session, filtersData, recordPaginationOptions, recordViewOptions) {
  'ngInject'

  let currentFilterParams = {}

  let service = {
    applyFilter: applyFilter,
    filters: filters,
    setInitialFilter: setInitialFilter,
    getActiveFilter: getActiveFilter,
    flagActive: flagActive,
    mergeParams: mergeParams,
    isActiveFilterParams: isActiveFilterParams,
    getFilteringParams: getFilteringParams,
    defaultFilterApplied: defaultFilterApplied,
    currentFilterName: currentFilterName,
    reloadWithFilter: reloadWithFilter,
    setDefaultFilter: setDefaultFilter
  }

  return service

  /////////////

  function applyFilter(params, state?) {
    if (session.user.isEmployee) {
      params = _omit(params, 'starred')
    }
    currentFilterParams = _omit(params, recordPaginationOptions.params().concat(recordViewOptions.params()))
    setActiveFilter(currentFilterParams)

    if (state !== false) {
      reloadWithFilter(state || $state.$current)
    }
  }

  function filters() {
    return _cloneDeep(currentFilterParams)
  }

  function getFilteringParams(params?) {
    if (params === undefined) {
      params = $state.params
    }
    return _omit(
      _omit(_pickBy(params || {}, _identity), [
        'accountId',
        'folderId',
        'recordId',
        'recordIds',
        'activityId',
        'sort',
        'openfields',
        'taskId',
        '$inherit'
      ]),
      recordPaginationOptions.params().concat(recordViewOptions.params())
    )
  }

  function setInitialFilter(params) {
    let defaultFilter = filtersData.defaultFilter(),
      filteringParams = getFilteringParams(params)

    if (_isEmpty(filteringParams)) {
      filteringParams = filtersData.extractRules(defaultFilter.rules.data)
    }

    applyFilter(filteringParams, false)

    return service
  }

  function setDefaultFilter() {
    let defaultFilter = filtersData.defaultFilter()

    applyFilter(filtersData.extractRules(defaultFilter.rules.data || []))

    return service
  }

  function reloadWithFilter(state) {
    let params = {},
      activeFilters = filters(),
      filteringParams = getFilteringParams()

    // Set all the current filtering params to null
    _forEach(filteringParams, function (value, name) {
      filteringParams[name] = null
    })

    // Combine the null values with the new filter values
    params = _extend(filteringParams, activeFilters)

    if (state) {
      $state.go(state, params)
    } else {
      $state.go($state.current.name, params)
    }
  }

  function flagActive() {
    setActiveFilter(currentFilterParams)
  }

  function setActiveFilter(filterParams) {
    let filters = context.get('filters')

    _map(filters, function (filter) {
      let rules = filtersData.extractRules(filter.rules.data)

      if (_isEqual(filterParams, rules)) {
        filter.$active = true
      } else {
        filter.$active = false
      }
    })
  }

  function isActiveFilterParams() {
    // we don't care if the starred param is applied as it only filters folders not records.
    let filteringParams = _omit(getFilteringParams(), 'starred', 'postRecordAction', 'action')
    return !_isEmpty(filteringParams)
  }

  function getActiveFilter() {
    let filters = context.get('filters')
    return _find(filters, { $active: true })
  }

  function mergeParams() {
    let paramsObject = Array.prototype.slice.call(arguments)
    let filterParams = {}

    _forEach(paramsObject, function (paramObject) {
      _forEach(paramObject, function (value, name) {
        if (filterParams[name] && typeof filterParams[name] === 'string' && value && typeof value === 'string') {
          filterParams[name] = filterParams[name].split(',').concat(value.split(',')).join(',')
        } else {
          filterParams[name] = value
        }
      })
    })
    return filterParams
  }

  function defaultFilterApplied() { 
    let defaultFilter = filtersData.defaultFilter()
    let activeFilter: any = getActiveFilter()

    // if we can't find an active filter and there are no params, assume it is the "default filter" i.e. all records
    // sometimes activeFilter is undefined because the default filter definition includes starred
    return (!activeFilter && !isActiveFilterParams()) || !!(activeFilter && defaultFilter.id === activeFilter.id)
  }

  function currentFilterName() {
    let activeFilter: any = getActiveFilter()

    if (activeFilter) {
      return activeFilter.name
    }
    return 'Custom filter'
  }
}
