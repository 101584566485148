import { Controller } from './controller'
import view from './view.html'

export const ptActivitiesWidget = {
  template: view,
  controller: Controller,
  bindings: {
    widget: '<'
  }
}
