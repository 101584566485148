import * as Sentry from '@sentry/browser'
import moment from 'moment-timezone'
import * as productImportHelpers from 'AppCore/product/services'
import _includes from 'lodash/includes'
import _cloneDeep from 'lodash/cloneDeep'

export function Controller($window, $document, $state, ImportsData, dateTimeFormat, gettextCatalog) {
  'ngInject'

  const $ctrl = this
  let originalImportData

  $ctrl.control = {
    completeImport,
    frequencyChange,
    isImporting: ImportsData.isImporting,
    getProductTypeValue,
    mappingFieldChange,
    productCycleTo,
    productCycleNext,
    productCyclePrevious,
    setImportMode,
    shouldShowField
  }

  $ctrl.importIsComplete = false
  $ctrl.pageLoading = false
  $ctrl.importMode = 'standard'
  $ctrl.frequencies = $ctrl.account.frequencies
  $ctrl.mappings = []
  $ctrl.error = false
  $ctrl.fakeRecords = []

  $ctrl.selected = {
    index: 0,
    product: null,
    record: null
  }

  $ctrl.$onInit = function () {
    checkForImportCompletion()

    // Make a copy of the import data locally, so that if a user goes
    // into an advanced import but want's to reset the data, we can.
    originalImportData = _cloneDeep($ctrl.importData)

    createMappings()
  }

  $ctrl.$onChanges = function () {}

  /**
   * Do a fresh call for the import to make sure the user hasn't
   * navigated back to the location of a completed import.
   */
  function checkForImportCompletion() {
    $ctrl.pageLoading = true

    ImportsData.getImport($ctrl.importData.id)
      .then((response) => {
        if (response.completed_at) {
          $ctrl.importIsComplete = true
        }
      })
      .finally(() => {
        $ctrl.pageLoading = false
      })
  }

  function completeImport() {
    if (!ImportsData.isImporting()) {
      ImportsData.isImporting(true)
      $ctrl.error = false
      setPageUnload(true)

      ImportsData.completeImport(
        $ctrl.$transition$.params().importId,
        $ctrl.$transition$.params().folderId,
        $ctrl.account.blueprint.id,
        productImportHelpers.prepRecordsForAPI($ctrl.mappings)
      )
        .then(() => {
          $state.go('.complete', $ctrl.$transition$.params())
        })
        .catch(() => {
          $ctrl.error = 'complete'
        })
        .finally(() => {
          ImportsData.isImporting(false)
          setPageUnload(false)
        })
    }
  }

  function createFakeRecords() {
    $ctrl.fakeRecords = productImportHelpers.createFakeRecords($ctrl.mappings)
    $ctrl.selected.record = $ctrl.fakeRecords[$ctrl.selected.index]
  }

  function createMappings() {
    $ctrl.mappings = productImportHelpers.createMappings(
      originalImportData,
      $ctrl.account.frequencies,
      $ctrl.account.blueprint
    )

    $ctrl.selected.product = $ctrl.mappings[$ctrl.selected.index]

    mappingFieldChange()
  }

  function frequencyChange() {
    createFakeRecords()
  }

  function getProductTypeValue(field) {
    switch (field.type) {
      case 'array':
        return field.value.join(', ')
      case 'date':
        return moment(new Date(field.value)).format(dateTimeFormat.date())
      default:
        return field.value
    }
  }

  function mappingFieldChange() {
    productImportHelpers.mappingFieldChange($ctrl.selected.product)
    createFakeRecords()
  }

  function pageUnload(e) {
    const confirmationMessage = gettextCatalog.getString('Imports are still completing, are you sure you want to exit?')

    e.returnValue = confirmationMessage

    return confirmationMessage
  }

  function productCycleNext() {
    if ($ctrl.selected.index < $ctrl.mappings.length - 1) {
      $ctrl.selected.index = $ctrl.selected.index + 1
      $ctrl.selected.product = $ctrl.mappings[$ctrl.selected.index]
      $ctrl.selected.record = $ctrl.fakeRecords[$ctrl.selected.index]

      if ($document[0].getElementsByClassName('layoutContent').length) {
        $document[0].getElementsByClassName('layoutContent')[0].scrollTop = 0
      }
    }
  }

  function productCyclePrevious() {
    if ($ctrl.selected.index > 0) {
      $ctrl.selected.index = $ctrl.selected.index - 1
      $ctrl.selected.product = $ctrl.mappings[$ctrl.selected.index]
      $ctrl.selected.record = $ctrl.fakeRecords[$ctrl.selected.index]

      if ($document[0].getElementsByClassName('layoutContent').length) {
        $document[0].getElementsByClassName('layoutContent')[0].scrollTop = 0
      }
    }
  }

  function productCycleTo(value) {
    $ctrl.selected.index = value
    $ctrl.selected.product = $ctrl.mappings[$ctrl.selected.index]
    $ctrl.selected.record = $ctrl.fakeRecords[$ctrl.selected.index]
  }

  function reset() {
    $ctrl.importMode = 'standard'
    ImportsData.isImporting(false)
    $ctrl.mappings = []
    $ctrl.error = false

    $ctrl.selected = {
      index: 0,
      product: null,
      record: null
    }
  }

  function setImportMode(mode = 'standard') {
    if (!ImportsData.isImporting()) {
      reset()
      createMappings()

      $ctrl.importMode = mode
    }
  }

  function setPageUnload(set) {
    if (set) {
      $window.addEventListener('beforeunload', pageUnload)
    } else {
      $window.removeEventListener('beforeunload', pageUnload)
    }
  }

  function shouldShowField(productField) {
    return !_includes(['name'], productField.key)
  }
}
