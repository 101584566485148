import _filter from 'lodash/filter'

export function Controller($scope, invitationsForAccount) {
  'ngInject'

  let vm = this

  vm.invitations = null
  vm.displayableInvitations = null

  ////////////////

  vm.$onInit = function () {
    getInvitations()

    listen()
    watch()
  }

  function getInvitations() {
    return invitationsForAccount.getAll().then(function (invitations) {
      vm.invitations = _filter(invitations, { accepted_at: null })
      vm.displayableInvitations = vm.invitations
    })
  }

  function listen() {
    $scope.$on('invitation_was_created', getInvitations)
    $scope.$on('invitation_was_updated', getInvitations)
    $scope.$on('invitation_was_deleted', getInvitations)
    $scope.$on('invitation_was_accepted', getInvitations)
  }

  function watch() {
    $scope.$watch('vm.filter', function (newVaue, oldValue) {
      if (newVaue !== oldValue) {
        if (!vm.filter) {
          vm.displayableInvitations = vm.invitations
        }

        vm.displayableInvitations = _filter(vm.invitations, function (invitation) {
          if (invitation.email.search(new RegExp(vm.filter, 'i')) > -1) {
            return invitation
          }
        })
      }
    })
  }
}
