export function AccountModel(restmod) {
  'ngInject'

  let model = restmod
    .model('/accounts')
    .mix('ApiBaseModel')
    .mix({
      activities: { hasMany: 'ActivitiesModel' },
      addresses: { hasMany: 'AddressesModel', map: 'addresses.data' },
      blueprint: { hasOne: 'BlueprintsModel', map: 'blueprint.data' },
      emails: { hasMany: 'AccountEmailsModel' },
      flags: { hasMany: 'FlagModel', map: 'flags.data' },
      folder: { hasOne: 'RootFolderModel', map: 'folder.data' },
      folders: { hasMany: 'FolderModel' },
      exports: { hasMany: 'ExportsModel' },
      certificates: { hasMany: 'CertificatesModel' },
      frequencies: { hasMany: 'FrequenciesModel', map: 'frequencies.data' },
      iAuditor: { hasMany: 'iAuditorModel' },
      iAuditorAuthors: { hasMany: 'iAuditorAuthorsModel', path: 'iauditor/authors' },
      iAuditorTemplates: { hasMany: 'iAuditorTemplatesModel', path: 'iauditor/templates' },
      iAuditorSources: { hasMany: 'iAuditorSourcesModel', path: 'iauditor/sources' },
      iAuditorFields: { hasMany: 'iAuditorFieldsModel', path: 'iauditor/fields' },
      invitations: { hasMany: 'InvitationModel' },
      integrations: { hasMany: 'IntegrationsModel' },
      lookup: { hasMany: 'LookupModel' },
      me: { hasOne: 'MemberModel' },
      member: { hasOne: 'MemberModel', map: 'member.data' },
      members: { hasMany: 'MemberModel' },
      records: { hasMany: 'RecordModel' },
      reports: { hasMany: 'ReportsModel' },
      reportsProjectedChecks: { hasMany: 'ReportsProjectedChecksModel', path: 'reports/projected_checks' },
      reportsComplianceChecks: { hasMany: 'ReportsComplianceModel', path: 'reports/compliance' },
      states: { hasMany: 'StateModel', map: 'states.data' },
      subscription: { hasOne: 'SubscriptionModel', map: 'subscription.data' },
      tags: { hasMany: 'TagsModel' },
      uploads: { hasMany: 'UploadModel' }
    })

  return model
}

export function AccountEmailsModel(restmod) {
  'ngInject'

  let model = restmod.model().mix('ApiBaseModel')

  return model
}
