export function Controller($scope, AccessesData, $state) {
  'ngInject'

  let vm = this

  vm.control = {
    toggleStarred: toggleStarred
  }

  vm.access = null

  vm.$onInit = function () {
    AccessesData.folderAccess(vm.folderId)
      .then(function (access) {
        vm.access = access
      })
      .catch(() => {})

    // When the users starred folders changes,
    // reload the current state to update the folders/records that are now visible
    $scope.$on('access_was_created', reload)
    $scope.$on('access_was_updated', reload)
    $scope.$on('access_was_deleted', reload)
  }

  function reload() {
    $state.reload($state.current.name)
  }

  function toggleStarred($event) {
    $event.stopPropagation()
    vm.access.starred = !vm.access.starred
    AccessesData.saveAccess(vm.access, vm.folderId)
  }
}
