export function TasksUnsubscribeRoute($stateProvider) {
  'ngInject'

  const url = [
    `/accounts/{accountId}`,
    `/records/{recordId}`,
    `/tasks/{taskId}`,
    `/subscribers/{subscriberId}`,
    `/{subscribeAction}`
  ]

  // No-Auth Unsubscribe
  $stateProvider.state('tasks-unsubscribe', {
    parent: 'layoutBase',
    data: { secure: false },
    url: url.join(''),
    views: {
      'page@layoutBase': {
        component: 'ptTasksUnsubscribe'
      }
    },
    onEnter: onTasksUnsubscribe
  })

  function onTasksUnsubscribe(page, gettextCatalog) {
    'ngInject'

    page.setTitle(gettextCatalog.getString('Task Unsubscribe'))
  }
}
