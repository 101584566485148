import view from './view.html'
import { Controller } from './controller'

export function ptMembersAccount() {
  'ngInject'

  let directive = {
    template: view,
    bindToController: true,
    controller: Controller,
    controllerAs: 'vm',
    restrict: 'A',
    scope: {
      type: '@ptMembersAccount',
      filter: '='
    }
  }

  return directive
}
