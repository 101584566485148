export function TaskRoute($stateProvider) {
  'ngInject'

  let taskViewModal
  let uuid = '(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})'

  // Profile Task View Modal
  $stateProvider.state('tasks.task', profileTaskView(`/{taskId:${uuid}}`))

  // Account Task View Modal
  $stateProvider
    .state('account.tasks.task', accountTaskView(`/{taskId:${uuid}}`))
    .state('folder.records.task', accountTaskView())
    .state('folders.records.task', accountTaskView())
    .state('account.records.task', accountTaskView())
    .state('activities.records.task', accountTaskView())

  function profileTaskView(url = `/tasks/{taskId:${uuid}}`) {
    return {
      url,
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        task: resolveTask,
        account: resolveAccount,
        isAdmin: resolveIsAdmin
      },
      onEnter: onTaskViewEnter,
      onExit: onTaskViewExit
    }
  }

  function accountTaskView(url = `/tasks/{taskId:${uuid}}`) {
    return {
      url,
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        task: resolveTask,
        isAdmin: resolveIsAdmin
      },
      onEnter: onTaskViewEnter,
      onExit: onTaskViewExit
    }
  }

  function onTaskViewEnter($uibModal, $state, task, account, isAdmin, page) {
    'ngInject'
    page.setTitle('Task Details')

    taskViewModal = $uibModal.open({
      component: 'ptTask',
      resolve: {
        task: task,
        isAdmin: isAdmin
      },
      size: 'task'
    })

    // If the user dismisses the modal, return to parent state.
    // result.then() = closed a modal (via change of state)
    // result.catch() = dismiss a modal (via close button)
    taskViewModal.result.catch(() => {
      $state.go('^')
    })
  }

  function onTaskViewExit(page) {
    'ngInject'
    page.resetTitle()
    taskViewModal.close()
  }

  function resolveIsAdmin($q, task, context, AccountModel, $state) {
    'ngInject'

    let defer = $q.defer()
    let isAccount = $state.current.name.indexOf('account') !== -1

    if (isAccount) {
      defer.resolve(context.get('account').me.admin)
    } else {
      AccountModel.$new(task.account.id)
        .me.$fetch({ include: 'permissions' })
        .$asPromise()
        .then((response) => {
          defer.resolve(response.admin)
        })
    }

    return defer.promise
  }

  function resolveAccount(accountsData, task, context) {
    'ngInject'

    return accountsData.fetchAccount(task.account.id).then((account) => {
      context.set('account', account)

      return account
    })
  }

  function resolveTask($q, TasksData, $stateParams) {
    'ngInject'

    return TasksData.getTaskById($stateParams.taskId).catch((error) => {
      return $q.reject(error.$response.data.error)
    })
  }
}
