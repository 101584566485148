import * as React from 'react'
import { View } from 'reactxp'
import { Button } from '../../components'
import { Flex, FlexRow } from '../../components/styled'
import { User } from '../../user'
import { PersonDisplay } from '../../user/components'

interface MemberSelectorProps {
  user: User
  isSelected: boolean
  editable: boolean
  onUpdateUser: (user: User, isSelected: boolean) => void
}

export class MemberSelector extends React.Component<MemberSelectorProps> {
  public toggleUserSelection = (event) => {
    event.preventDefault()

    this.props.onUpdateUser(this.props.user, !this.props.isSelected)
  }

  public render() {
    const { editable } = this.props
    return (
      <FlexRow style={{ alignItems: 'center' }}>
        <Flex>
          <PersonDisplay user={this.props.user} />
        </Flex>
        <View>
          {editable && (
            <FlexRow>
              <Button
                label={this.props.isSelected ? 'Remove' : 'Add'}
                theme={{
                  labelColor: 'neutral',
                  backgroundColor: 'white',
                  borderColor: 'neutralTintFive'
                }}
                onPress={() => this.toggleUserSelection(event) as any}
              />
            </FlexRow>
          )}
        </View>
      </FlexRow>
    )
  }
}
