import { createResource } from '../../api/resource'
import { createResponseTransformer, createRequestTransformer } from '../../api/transformer'
import { Task, TaskAction } from '../index'

const transformRequest = createRequestTransformer<any>()

interface Params {
  taskId: UUID
  action: TaskAction
  payload?: any
}

export const createTaskAction = createResource({
  request: ({ taskId, action, payload }: Params) => ({
    url: `tasks/${taskId}/actions/${action}`,
    method: 'POST',
    ...(payload && { data: transformRequest(payload) })
  }),
  transform: createResponseTransformer<Task>('record', 'user', 'source', 'assignees', 'uploads', 'account', 'log')
})
