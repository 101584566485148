import * as React from 'react'
import { Image } from 'reactxp'
import { Activity } from '../models'
import { spacing } from '../../styles'
import { Text, Icon } from '../../components'
import { View, createStyledComponent } from '../../components/styled'
import { getPlatformStyle } from '../../services/platform'
import { getUploadType } from '../../upload/services'
import { Upload } from '../../upload'
import { User } from '../../user'

interface Props {
  activity: Activity
  action?: string
  onPressUpload: (upload: Upload, user: User) => void
}

const UploadWrapper = createStyledComponent(View, {
  flexDirection: 'row',
  alignItems: 'center',

  ...getPlatformStyle({
    web: {
      cursor: 'pointer'
    }
  })
})

const UploadImage = createStyledComponent(View, {
  marginRight: spacing.half
})

const UploadContent = createStyledComponent(View, {
  flexGrow: 1
})

export const ActivityUploads = ({ action = 'to', activity, onPressUpload }: Props) => {
  const newUploads: any = () => {
    return activity[action] && activity[action].uploads ? activity[action].uploads.filter(isNewUpload) : []
  }

  const isNewUpload = (upload) => {
    const from = action === 'to' ? 'from' : 'to'
    return !activity[from] || !activity[from].uploads || !activity[from].uploads.find((u) => u.id === upload.id)
  }

  const uploadComponent = (upload) => {
    return {
      image: (
        <Image
          style={{ width: 30, height: 30 }}
          source={`/api${upload.view}&width=30&height=30`}
          accessibilityLabel="Upload thumbnail"
        />
      ),
      pdf: <Icon size={20} name="fPdf"></Icon>,
      iauditor: <Icon size={20} name="iAuditor" color="secondary"></Icon>
    }
  }

  return (
    <>
      {newUploads().map((upload) => {
        const uploadType = getUploadType(upload)

        return (
          <View style={{ marginBottom: spacing.quarter }} key={upload.id}>
            {!!uploadComponent(upload)[uploadType] && (
              <UploadWrapper onPress={() => onPressUpload(upload, activity.user)}>
                <UploadImage>{uploadComponent(upload)[uploadType]}</UploadImage>
                <UploadContent>
                  <Text>{upload.name}</Text>
                </UploadContent>
              </UploadWrapper>
            )}
          </View>
        )
      })}
    </>
  )
}
