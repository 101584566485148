import _isFinite from 'lodash/isFinite'
import _get from 'lodash/get'

export function Controller($state, recordsData, recordsSelection, $timeout, recordsViewMode) {
  'ngInject'

  this.$onInit = () => {
    this.cloning = false
    this.records = this.resolve.records
    this.error = null
    this.state = {
      quantity: '1'
    }
  }

  this.clone = () => {
    const viewMode = recordsViewMode.get()
    const quantity = Number(this.state.quantity)
    const recordIds = this.records.map((r) => r.id).join(',')
    if (_isFinite(quantity) && quantity > 0) {
      this.cloning = true
      recordsData
        .cloneRecords(this.records, this.state.quantity)
        .then((response) => {
          if (quantity === 1) {
            if (viewMode === 'record') {
              $state.go('folders.records', { recordId: response[0].id })
            } else if (viewMode === 'folder') {
              $state.go('folder.records', { recordId: response[0].id })
            }

            recordsSelection.setAll(false)
          } else {
            this.dismiss({ $value: 'saved' })
            recordsSelection.setAll(false)
          }

          const newIds = recordIds.map((r) => r.id).join(',')

          $timeout(() => {
            $state.go('folder.action-records', { recordIds: newIds, action: 'clone' })
          })
        })
        .catch((rejection) => {
          this.deleting = false
          this.error = _get(rejection.$response.data, 'error')
        })
    }
  }

  this.formUpdated = (state) => {
    this.state = state
  }
}
