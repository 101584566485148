export function Controller(usersData) {
  'ngInject'

  let vm = this

  vm.control = {
    resendValidation: resendValidation
  }

  vm.resendingValidation = false
  vm.validationResent = false

  vm.$onInit = function () {}

  function resendValidation() {
    vm.resendingValidation = true

    usersData
      .resendValidation()
      .then(function () {
        vm.resendingValidation = false
        vm.validationResent = true
      })
      .catch(function () {})
  }
}
