import { Controller } from './controller'
import view from './view.html'

export const ptTaskSidebar = {
  bindings: {
    task: '<',
    isAdmin: '<',
    editable: '<'
  },
  controller: Controller,
  template: view
}
