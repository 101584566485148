import angular from 'angular'

export function Controller(accountsData) {
  'ngInject'

  const $ctrl = this

  $ctrl.taskEmails = null
  $ctrl.taskEmailsLoading = false

  $ctrl.control = {
    toggleAccountEmails
  }

  $ctrl.$onInit = function () {
    $ctrl.taskEmailsLoading = true

    accountsData
      .getAccountEmailSettings($ctrl.account.id)
      .then((emailSettings) => {
        // check for task email settings (undefined if none)
        emailSettings.forEach((setting) => {
          if (setting.type === 'tasks') {
            $ctrl.taskEmails = setting
          }
        })
      })
      .finally(() => {
        $ctrl.taskEmailsLoading = false
      })
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.account) {
      $ctrl.account = angular.copy($ctrl.account)
    }
  }

  function toggleAccountEmails() {
    if (!$ctrl.taskEmailsLoading) {
      $ctrl.taskEmailsLoading = true

      $ctrl.taskEmails.active = !$ctrl.taskEmails.active

      accountsData.setAccountEmailSettings($ctrl.account.id, $ctrl.taskEmails).finally(() => {
        $ctrl.taskEmailsLoading = false
      })
    }
  }
}
