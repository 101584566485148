import angular from 'angular'

import { editableAutoColor } from './auto-color'
import { editableAutoDate } from './auto-date'
import { editableAutoDatetime } from './auto-datetime'
import { editableAutoEmail } from './auto-email'
import { editableAutoMonth } from './auto-month'
import { editableAutoNumber } from './auto-number'
import { editableAutoSearch } from './auto-search'
import { editableAutoTel } from './auto-tel'
import { editableAutoText } from './auto-text'
import { editableAutoTextarea } from './auto-textarea'
import { editableAutoTime } from './auto-time'
import { editableAutoUrl } from './auto-url'
import { editableAutoWeek } from './auto-week'
import { editableDatepicker } from './datepicker'
import { editableMoney } from './money'

export const xeditableModule = angular
  .module('xeditable.directives', [])
  .directive('editableAutoColor', editableAutoColor)
  .directive('editableAutoDate', editableAutoDate)
  .directive('editableAutoDatetime', editableAutoDatetime)
  .directive('editableAutoEmail', editableAutoEmail)
  .directive('editableAutoMonth', editableAutoMonth)
  .directive('editableAutoNumber', editableAutoNumber)
  .directive('editableAutoSearch', editableAutoSearch)
  .directive('editableAutoTel', editableAutoTel)
  .directive('editableAutoText', editableAutoText)
  .directive('editableAutoTextarea', editableAutoTextarea)
  .directive('editableAutoTime', editableAutoTime)
  .directive('editableAutoUrl', editableAutoUrl)
  .directive('editableAutoWeek', editableAutoWeek)
  .directive('editableDatepicker', editableDatepicker)
  .directive('editableMoney', editableMoney).name
