const uuid = '(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})'

export function ptImportsImportRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('imports.import', {
    url: `/{importId:${uuid}}`,
    views: {
      'crumbs@imports': {
        component: 'ptImportsCrumbs'
      },
      'main@imports': {
        component: 'ptImportsImport'
      }
    },
    resolve: {
      importData: resolveImport,
      crumbs: resolveCrumbs,
      self: resolveSelf
    }
  })

  function resolveImport(ImportsData, $transition$) {
    'ngInject'

    return ImportsData.getImport($transition$.params().importId)
  }

  function resolveCrumbs(gettextCatalog) {
    'ngInject'

    return [
      {
        name: gettextCatalog.getString('Imports'),
        link: 'imports'
      }
    ]
  }

  function resolveSelf(gettextCatalog) {
    'ngInject'

    return gettextCatalog.getString('Import Details')
  }
}
