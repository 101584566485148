import * as React from 'react'
import { FormComponentProps } from './index'
import { Icon, IconName } from '../index'
import { createStyledTextInput, createStyledView } from '../styled'
import { spacing, colors, iconSizes, fontSizes } from '../../styles'

const Input = createStyledTextInput({
  paddingTop: spacing.base,
  paddingRight: spacing.base,
  paddingBottom: spacing.base,
  paddingLeft: spacing.double + spacing.half,
  flex: 1,
  backgroundColor: colors.neutralTable,
  fontSize: fontSizes.base
})

const InputWrapper = createStyledView({
  position: 'relative',
  height: 50
})

const IconWrapper = createStyledView({
  position: 'absolute',
  width: iconSizes.base,
  height: iconSizes.base,
  left: spacing.base,
  top: 25 - iconSizes.base / 2
})

export interface IconTextInputProps extends FormComponentProps<string> {
  placeholder: string
  iconName: IconName
  onInputPress?: () => void
  onBlurEvent?: (event: React.MouseEvent) => void
  onKeyPressEvent?: (event: React.KeyboardEvent) => void
}

export const IconTextInput = ({
  value,
  onChangeValue,
  onInputPress,
  onBlurEvent,
  onKeyPressEvent,
  placeholder,
  iconName
}: IconTextInputProps) => (
  <InputWrapper onPress={onInputPress}>
    <Input
      onKeyPress={onKeyPressEvent}
      value={value}
      onChangeText={onChangeValue}
      placeholder={placeholder}
      onBlur={onBlurEvent}
    />
    <IconWrapper>
      <Icon name={iconName} />
    </IconWrapper>
  </InputWrapper>
)
