export function wootricInit($window, session, moment) {
  'ngInject'

  let service = {
    boot: boot
  }

  let booted = false

  return service

  function boot() {
    let user = session.user

    if (booted || !appConfig.wootric.accountToken) {
      return
    }

    $window.wootricSettings = {
      email: user.email,
      external_id: user.id,
      created_at: moment(user.createdAt).unix(),
      account_token: appConfig.wootric.accountToken,
      twitter_account: 'papertrail_io',
      facebook_page: 'https://www.facebook.com/papertrail.io'
    }

    if (appConfig.wootric.forceDisplay) {
      // Shows survey immediately for testing purposes.
      $window.wootric_survey_immediately = true
      // Bypass cookie based throttle for testing purposes.
      $window.wootric_no_surveyed_cookie = true
    }

    initWootric()
  }

  // Because 'Wootric' might be set as null when defined in constants
  // due to the external script not yet being loaded, this will do a
  // check and retry before trying to initialise.
  function initWootric(retries?) {
    let WootricFn = (<any>window).wootric
    let currentRetries = 0
    let retryMax = Number(retries) || 5

    function attempt() {
      if (currentRetries >= retryMax) {
        return
      }

      if (!WootricFn) {
        currentRetries = currentRetries + 1

        setTimeout(function () {
          WootricFn = (<any>window).wootric || null
          attempt()
        }, 1000)
      } else {
        booted = true
        WootricFn('run')
      }
    }

    attempt()
  }
}
