import { createResource } from '../../api/resource'
import { transformCamelToSnake, createResponseArrayTransformer } from '../../api/transformer'
import { FetchTaskSubscribersQuery, TaskSubscribers } from '..'

interface Params {
  taskId: UUID
  query?: FetchTaskSubscribersQuery
}

export const fetchTaskSubscribers = createResource({
  request: ({ taskId, query }: Params) => ({
    url: `tasks/${taskId}/subscribers`,
    method: 'GET',
    params: query && transformCamelToSnake(query)
  }),
  transform: createResponseArrayTransformer<TaskSubscribers>()
})
