export function ptExportsRoute($stateProvider) {
  'ngInject'

  let exportModal
  
  exportState('account.records', 'record')
  exportState('activities.records', 'record')
  exportState('bucket.records', 'record')
  exportState('folder.records', 'record')
  exportState('folders.records', 'record')
  exportState('folder', 'folder')
  exportState('folders', 'folder')

  batchExportState('folder', 'folder')
  batchExportState('bucket', 'folder')
  batchExportState('folders', 'folder')
  batchExportState('folder.scan', 'folder')
  batchExportState('folders.scan', 'folder')
  batchExportState('account.scan', 'folder')

  function exportState(parent, type) {
    $stateProvider.state(parent + '.export', {
      url: '/export',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        resolvedCertificates: resolveCertificates,
        resolvedRecordIds: resolveRecordIds,
        resolvedSignatures: resolveSignatures,
        exportType: () => type,
        isBatch: () => false
      },
      onEnter: onExportEnter,
      onExit: onExportExit
    })
  }

  function batchExportState(parent, type) {
    $stateProvider.state(parent + '.batch-export', {
      url: '/batch-export?{recordIds}',
      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        resolvedCertificates: resolveCertificates,
        resolvedRecordIds: resolveRecordIds,
        resolvedSignatures: resolveSignatures,
        exportType: () => type,
        isBatch: () => true
      },
      onEnter: onExportEnter,
      onExit: onExportExit
    })
  }

  function resolveSignatures(account, membersData) {
    'ngInject'
    return membersData.getAll(account.id, '', { 'not-null-filter': 'user.signature_path' })
  }

  function resolveCertificates(account, ExportsData) {
    'ngInject'

    return ExportsData.getCertificates()
  }

  function resolveRecordIds(account, $stateParams, recordsResolves) {
    'ngInject'

    // We require account DI to ensure it is resolved before this route
    return recordsResolves.recordIdsSelection($stateParams)
  }

  function onExportEnter(
    $uibModal,
    $state,
    exportType,
    isBatch,
    resolvedCertificates,
    resolvedRecordIds,
    resolvedSignatures,
    $transition$
  ) {
    'ngInject'

    exportModal = $uibModal.open({
      backdrop: 'static',
      component: 'ptExports',
      resolve: {
        type: () => exportType,
        batch: () => isBatch,
        certificates: () => resolvedCertificates,
        recordIds: () => resolvedRecordIds,
        userSignatures: () => resolvedSignatures,
        $transitions: () => $transition$
      }
    })

    exportModal.result.catch(() => {
      $state.go('^')
    })
  }

  function onExportExit() {
    'ngInject'
    exportModal.close()
  }
}
