import _forEach from 'lodash/forEach'

export function Controller($timeout, foldersNavigation) {
  'ngInject'

  let vm = this

  vm.control = {
    go: go
  }

  vm.crumbs = []

  /////////

  vm.$onInit = function () {
    if (vm.folder.ancestors && vm.folder.ancestors.length) {
      let folders = vm.folder.ancestors.slice(1)

      _forEach(folders, function (folder) {
        vm.crumbs.push({
          name: folder.name,
          id: folder.id
        })
      })
    }

    vm.crumbs.push({
      name: vm.folder.name,
      id: vm.folder.id
    })
  }

  function go(crumb) {
    vm.closeRecord()

    $timeout(function () {
      foldersNavigation.go(crumb.id)
    })
  }
}
