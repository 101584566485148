import * as React from 'react'
import { View } from 'reactxp'
import { FlexRow } from '../../components/styled'
import { colors } from '../../styles'
import { User } from '../../user/index'
import { UserAvatar } from '../../user/components'

interface MembersAvatarRowProps {
  users: User[]
  iconSize?: number
}

export const MembersAvatarRow = ({ users, iconSize = 30 }: MembersAvatarRowProps) => {
  return (
    <FlexRow>
      {users.map((user, index) => (
        <View
          key={index}
          style={{
            width: iconSize,
            height: iconSize,
            borderRadius: iconSize / 2,
            borderWidth: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: colors.neutralTable,
            ...(index > 0 ? { left: -5 * index } : {})
          }}
        >
          <UserAvatar user={user} imageSize={iconSize} fontSize="small" />
        </View>
      ))}
    </FlexRow>
  )
}
