export function input(formlyConfig) {
  'ngInject'

  formlyConfig.setType({
    name: 'input',
    template: '<input class="form-control" ng-model="::model[options.key]">',
    wrapper: ['copyDown', 'helpBlock', 'bootstrapLabel', 'bootstrapHasError'],
    overwriteOk: true
  })
}
