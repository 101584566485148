import _isArray from 'lodash/isArray'
import _cloneDeep from 'lodash/cloneDeep'
import _includes from 'lodash/includes'

//import './tree.html'

export function Controller(context, foldersTree) {
  'ngInject'

  const crawl = (tree, cb) => {
    const queue = []
    let next
    let node
    let parents
    let ix
    let numChildren

    if (_isArray(tree)) {
      tree.forEach((treeItem) => {
        // node and parents
        queue.push([treeItem, []])
      })
      next = queue.shift()
    } else {
      // node and parents
      next = [tree, []]
    }

    while (next) {
      node = next[0]
      parents = next[1]
      // cb might return `undefined` so we have to actually check for equality
      // against `false`
      if (cb(node, parents) !== false && node.children && _isArray(node.children)) {
        numChildren = node.children.length

        for (ix = 0; ix < numChildren; ix = ix + 1) {
          queue.push([node.children[ix], [node].concat(parents)])
        }
      }

      next = queue.shift()
    }
  }

  const getFolders = () => {
    return foldersTree
      .getTree(this.account)
      .then((accountFoldersTree) => {
        this.accountFoldersTree = setSelectedIds(accountFoldersTree, this.memberFolders)

        // We need to validate twice here as the first pass through sets 'selected'
        // on each folder (we don't know what needs to be 'indeterminate' yet).
        validateDescendants()
        // The second pass through can then determine the indeterminate state and set it accordingly.
        validateDescendants()
      })
      .catch(() => {})
  }

  const getSelectedIds = (folders) => {
    const ids = []

    crawl(folders, (folder) => {
      if (folder.selected) {
        ids.push(folder.id)
      }
    })

    return ids
  }

  const expandAll = (tree, expandValue = true) => {
    crawl(tree, (item) => {
      item.expanded = expandValue
    })
  }

  const setSelectedIds = (accountFoldersTree, memberFolders) => {
    crawl(accountFoldersTree, (treeItem) => {
      treeItem.selected = _includes(memberFolders, treeItem.id)
    })

    return accountFoldersTree
  }

  const validate = (node) => {
    let childrenSelected = node.children.filter((child) => child.selected)
    let childrenIndeterminate = node.children.filter((child) => child.indeterminate)

    if (node.selected && !childrenSelected.length) {
      node.selected = true
      node.indeterminate = true
    } else if (childrenSelected.length === node.children.length && childrenIndeterminate.length) {
      node.selected = true
      node.indeterminate = true
    } else if (childrenSelected.length === node.children.length) {
      node.selected = true
      node.indeterminate = false
    } else if (childrenSelected.length > 0 && childrenSelected.length < node.children.length) {
      node.selected = true
      node.indeterminate = true
    }
  }

  const validateAncestors = (folderId) => {
    crawl(this.accountFoldersTree, (node) => {
      if (node.id === folderId) {
        validate(node)

        validateAncestors(node.parentId)
      }
    })
  }

  const validateDescendants = () => {
    crawl(this.accountFoldersTree, (node) => {
      if (node.children.length > 0) {
        validate(node)
      }
    })
  }

  this.$onInit = () => {
    this.account = context.get('account')

    getFolders().then(() => {
      this.memberFolders = getSelectedIds(this.accountFoldersTree)

      this.onReady({ state: true })
    })
  }

  this.$onChanges = (changes) => {
    if (changes.memberFolders) {
      this.memberFolders = _cloneDeep(this.memberFolders)
    }
  }

  this.changeHandler = (folder) => {
    // Apply the new select value to all children
    if (folder.children && folder.children.length) {
      this.selectAll(folder.children, folder.selected)
    }

    validateAncestors(folder.parentId)

    this.memberFolders = getSelectedIds(this.accountFoldersTree)

    this.onChanges({ memberFolders: this.memberFolders })
  }

  this.deselectAll = (tree) => {
    this.selectAll(tree, false)
  }

  this.selectAll = (tree, selectValue = true) => {
    crawl(tree, (item) => {
      item.selected = selectValue

      // remove indeterminate value as we are selecting or deselecting
      item.indeterminate = false
    })

    validateAncestors(tree[0].parentId)

    this.memberFolders = getSelectedIds(this.accountFoldersTree)

    this.onChanges({ memberFolders: this.memberFolders })
  }

  this.toggleExpanded = (folder) => {
    folder.expanded = !folder.expanded

    // Collapse all sub-folders
    if (!folder.expanded && folder.children && folder.children.length) {
      expandAll(folder.children, folder.expanded)
    }
  }
}
