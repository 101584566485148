import view from './view.html'
import { Controller } from './controller'

export const ptLogsForm = {
  bindings: {
    mode: '<',
    record: '<',
    formIndex: '<',
    onRegister: '&',
    onDeregister: '&',
    onRemove: '&',
    onCopyDown: '&',
    multiple: '<',
    first: '<',
    showTutorialAdvice: '<',
    onDiscardCriteriaUpdated: '&',
    onDiscardCriteriaResults: '&'
  },
  template: view,
  controller: Controller
}
