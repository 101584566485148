import { createResource } from '../../api/resource'
import { createResponseArrayTransformer, createRequestTransformer } from '../../api/transformer'
import { FetchInvitationsQuery } from '../models/index'

interface Params {
  query?: FetchInvitationsQuery
}

const transformRequest = createRequestTransformer<FetchInvitationsQuery>()

export const fetchInvitations = createResource({
  request: ({ query }: Params) => ({
    url: `me/invitations`,
    method: 'GET',
    params: transformRequest(query)
  }),
  transform: createResponseArrayTransformer<any>('user', 'account', 'subscription')
})
