import _cloneDeep from 'lodash/cloneDeep'

export function Controller($state, TasksResolver) {
  'ngInject'

  this.isProfileTasks = $state.current.name.indexOf('tasks') === 0

  this.$onInit = () => {}

  this.$onChanges = (changes) => {
    if (changes.data) {
      this.data = _cloneDeep(this.data)
    }
  }

  this.newUploads = () => {
    return this.data.to.uploads.filter(this.isNewUpload)
  }

  this.isNewUpload = (upload) => {
    return !this.data.from.uploads || !this.data.from.uploads.find((u) => u.id === upload.id)
  }

  this.openActivity = () => {
    $state.go($state.current.name + '.activity', { activityId: this.data.id })
  }

  this.goToTask = () => {
    TasksResolver.resolve({ taskId: this.data.task.id })
  }
}
