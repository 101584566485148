import { Controller } from './controller'
import view from './view.html'

export const ptProfileBasic = {
  bindings: {
    timezones: '<',
    countries: '<',
    dateFormats: '<',
    timeFormats: '<'
  },
  controller: Controller,
  template: view
}
