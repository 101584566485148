import * as React from 'react'
import { Task } from '../models'
import { TaskSectionTitle } from './task-section-title'
import { fetchActivities } from '../../activity/api'
import { groupDates, groupUsers } from '../../activity/services'
import { uniqBy } from 'lodash'
import { Button } from '../../components'
import { View } from '../../components/styled'
import { createStyledComponent } from '../../components/styled'
import { spacing, colors } from '../../styles'
import { Upload } from '../../upload'
import { User } from '../../user'

import {
  Activity,
  ActivityUser,
  ActivityTaskAssigneeWasAdded,
  ActivityTaskAssigneeWasRemoved,
  ActivityTaskNoteWasCreated,
  ActivityTaskStateWasUpdated,
  ActivityTaskUploadWasCreated,
  ActivityTaskWasCreated,
  ActivityTaskWasOverdue,
  ActivityTaskWasUpdated,
  ActivityTaskWasAssignedToLog,
  ActivityTaskWasUnassignedFromLog,
  ActivityTaskUploadWasDeleted,
  TaskState
} from '../../activity'

interface Props {
  task: Task
  onPressActivity: (activity: Activity) => void
  onPressUpload: (upload: Upload, user: User) => void
  taskStates: TaskState[]
}

const ActivitiesWrapper = createStyledComponent(View, {
  marginBottom: spacing.half
})

/**
 * This component lines itself up with ActivityUser which has:
 * 40px wide avatar + marginRight 10px
 * The margin and padding below adds a line below the avatar to group the activities
 */
const ActivityContent = createStyledComponent(View, {
  marginTop: spacing.half,
  // Add 10px for margin
  marginLeft: spacing.base,
  paddingLeft: spacing.base + spacing.half,
  borderLeftWidth: 2,
  borderColor: colors.neutralTintFive,
  overflow: 'visible'
})

const LoadMoreWrapper = createStyledComponent(View, {
  paddingHorizontal: spacing.base,
  marginTop: spacing.half
})

export const TaskActivities = (props: Props) => {
  const { task, onPressActivity, onPressUpload, taskStates } = props
  const [activities, setActivities] = React.useState([])
  const [activitiesByGroup, setActivitiesByGroup] = React.useState([])
  const [pagination, setPagination] = React.useState(null)

  // Update activities when there are changes to the task
  React.useEffect(() => {
    getActivities()
  }, [task])

  const getActivities = async ({ loadMore = false } = {}) => {
    const data = await fetchActivities({
      accountId: task.account.id,
      taskId: task.id,
      ...(loadMore && pagination && { cursor: pagination }),
      query: {
        limit: 10
      }
    })

    let newActivities: Activity[]

    if (loadMore) {
      newActivities = activities.concat(data.items)
    } else {
      newActivities = data.items
    }

    const organisedActivities = groupUsers(groupDates(uniqBy(newActivities, 'id')))

    setActivities(newActivities)
    setActivitiesByGroup(organisedActivities)
    setPagination(data.cursor)
  }

  const getActivityStateView = (activity: Activity) => ({
    task_assignee_was_added: (
      <ActivityTaskAssigneeWasAdded activity={activity} onPressActivity={onPressActivity} viewType="simple" />
    ),
    task_assignee_was_removed: (
      <ActivityTaskAssigneeWasRemoved activity={activity} onPressActivity={onPressActivity} viewType="simple" />
    ),
    task_note_was_created: (
      <ActivityTaskNoteWasCreated
        activity={activity}
        onPressActivity={onPressActivity}
        onPressUpload={onPressUpload}
        viewType="simple"
      />
    ),
    task_state_was_updated: (
      <ActivityTaskStateWasUpdated activity={activity} onPressActivity={onPressActivity} viewType="simple" taskStates={taskStates} />
    ),
    task_upload_was_created: (
      <ActivityTaskUploadWasCreated
        activity={activity}
        onPressActivity={onPressActivity}
        onPressUpload={onPressUpload}
        viewType="simple"
      />
    ),
    task_upload_was_deleted: (
      <ActivityTaskUploadWasDeleted
        activity={activity}
        onPressActivity={onPressActivity}
        onPressUpload={onPressUpload}
        viewType="simple"
      />
    ),
    task_was_created: (
      <ActivityTaskWasCreated activity={activity} onPressActivity={onPressActivity} viewType="simple" />
    ),
    task_was_overdue: (
      <ActivityTaskWasOverdue activity={activity} onPressActivity={onPressActivity} viewType="simple" />
    ),
    task_was_updated: (
      <ActivityTaskWasUpdated activity={activity} onPressActivity={onPressActivity} viewType="simple" />
    ),
    task_was_assigned_to_log: (
      <ActivityTaskWasAssignedToLog activity={activity} onPressActivity={onPressActivity} viewType="simple" />
    ),
    task_was_unassigned_from_log: (
      <ActivityTaskWasUnassignedFromLog activity={activity} onPressActivity={onPressActivity} viewType="simple" />
    )
  })


  return (
    <View>
      <TaskSectionTitle>ACTIVITY:</TaskSectionTitle>
      {!!activitiesByGroup.length &&
        activitiesByGroup.map((dateActivities: Activity[][], dateIndex: number) => (
          <View key={dateIndex}>
            {dateActivities.map((userActivities: Activity[], userIndex) => (
              <ActivitiesWrapper key={userIndex}>
                <ActivityUser activity={userActivities[0]} />
                <ActivityContent>
                  {userActivities.map((activity) => {
                    return (
                      <View key={activity.id} style={{ overflow: 'visible', marginBottom: spacing.half }}>
                        {getActivityStateView(activity)[activity.type]}
                      </View>
                    )
                  })}
                </ActivityContent>
              </ActivitiesWrapper>
            ))}
          </View>
        ))}
      <LoadMoreWrapper>
        <Button
          label="Load More"
          onPress={() => getActivities({ loadMore: true })}
          locked={pagination && !pagination.hasMore}
          theme={{
            backgroundColor: 'neutralTintSix',
            borderColor: 'neutralTintFive',
            labelColor: 'black'
          }}
        />
      </LoadMoreWrapper>
    </View>
  )
}
