import { createResource } from '../../api/resource'
import { AssigneesTaskPayload, TaskAssignee } from '..'
import { createResponseArrayTransformer, createRequestTransformer } from '../../api/transformer'

const transformRequest = createRequestTransformer<AssigneesTaskPayload>()

interface Params {
  taskId: UUID
  payload: AssigneesTaskPayload
}

export const createTaskAssignee = createResource({
  request: ({ taskId, payload }: Params) => ({
    url: `tasks/${taskId}/assignees`,
    method: 'POST',
    data: transformRequest(payload)
  }),
  transform: createResponseArrayTransformer<TaskAssignee>('user')
})
