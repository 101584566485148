import _cloneDeep from 'lodash/cloneDeep'
import _clone from 'lodash/clone'

export function Controller($scope, timezoneUtil, session, DateFormat) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    datepickerChange,
    saveField
  }

  $ctrl.valueToLocal = null
  $ctrl.initialValue = null

  $ctrl.datepickerOptions = {
    showWeeks: false,
    startingDay: 1,
    showButtons: true
  }

  $ctrl.$onInit = () => {
    $ctrl.dateFormat = DateFormat.toUib(session.user.dateFormat)
  }

  $ctrl.$onChanges = (changes) => {
    if (changes.field) {
      $ctrl.field = _cloneDeep($ctrl.field)

      if ($ctrl.field.value) {
        $ctrl.valueToLocal = timezoneUtil.dateToLocal($ctrl.field.value).toDate()
        $ctrl.initialValue = $ctrl.valueToLocal
      }
    }

    if (changes.editable) {
      $ctrl.editable = _clone($ctrl.editable)
    }
  }

  function saveField(value) {
    let result

    if ($ctrl.initialValue !== value && typeof value !== 'undefined') {
      result = $ctrl.onSave({ value: value })
    }

    return result
  }

  function datepickerChange(datePicker, data) {
    if (typeof data !== 'undefined') {
      datePicker.$form.$submit()
    }
  }
}
