import _intersection from 'lodash/intersection'
import _includes from 'lodash/includes'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _reduce from 'lodash/reduce'
import _uniq from 'lodash/uniq'
import _uniqWith from 'lodash/uniqWith'
import _forEach from 'lodash/forEach'
import _isEqual from 'lodash/isEqual'

export function Controller($scope, $state, $stateParams, membersData, statesData, gettextCatalog, moment, context) {
  'ngInject'

  let vm = this

  vm.membersLoading = false

  vm.filters = {
    type: {
      options: [
        {
          key: 'folder',
          label: gettextCatalog.getString('Folder activities'),
          $filter: ['folder_was_moved', 'folder_was_updated', 'folder_was_created', 'folder_was_deleted']
        },
        {
          key: 'record',
          /// checkbox option
          label: gettextCatalog.getString('Record activities'),
          $suboptionsExpanded: false,
          $suboptions: [
            {
              key: 'record_creation',
              label: gettextCatalog.getString('Record creation'),
              $filter: ['record_was_created']
            },
            {
              key: 'record_import',
              label: gettextCatalog.getString('Record import'),
              $filter: ['record_was_imported']
            },
            {
              key: 'record_cloning',
              label: gettextCatalog.getString('Record cloning'),
              $filter: ['record_was_cloned']
            },
            {
              key: 'record_movements',
              label: gettextCatalog.getString('Record movements'),
              $filter: ['record_moved_to_folder', 'record_moved_from_account', 'record_moved_to_account']
            },
            {
              key: 'record_updates',
              /// checkbox option
              label: gettextCatalog.getString('Record updates'),
              $filter: ['record_was_updated', 'field_was_created', 'field_was_updated', 'field_was_deleted']
            },
            {
              key: 'note',
              /// checkbox option
              label: gettextCatalog.getString('Record notes'),
              $filter: ['note_was_created', 'note_was_deactivated', 'note_was_activated']
            },
            {
              key: 'record_uploads',
              /// checkbox option
              label: gettextCatalog.getString('Record uploads'),
              $filter: ['record_upload_was_added', 'record_upload_was_updated']
            },
            {
              key: 'record_deletion',
              /// checkbox option
              label: gettextCatalog.getString('Record deletion'),
              $filter: ['record_was_deleted', 'record_was_undeleted']
            }
          ]
        },
        {
          key: 'log',
          /// checkbox option
          label: gettextCatalog.getString('Inspection activities'),
          tooltip:
            'Note that you cannot filter by specific inspection statuses at the same time as other activity types.',
          $filter: ['log_was_created', 'log_was_updated', 'log_was_updated', 'service_log_was_created', 'log_was_deactivated', 'log_was_activated'],
          $suboptionsExpanded: false,
          $suboptions: []
        },
        {
          key: 'task',
          /// checkbox option
          label: gettextCatalog.getString('Task activities'),
          $filter: [
            'task_assignee_was_added',
            'task_assignee_was_removed',
            'task_state_was_updated',
            'task_upload_was_created',
            'task_upload_was_deleted',
            'task_was_created',
            'task_was_updated',
            'task_note_was_created',
            'task_was_overdue',
            'task_was_assigned_to_log',
            'task_was_unassigned_from_log'
          ]
        },
        {
          key: 'certificates',
          /// checkbox option
          label: gettextCatalog.getString('Certificate activities'),
          $filter: ['certificate_was_created', 'service_certificate_was_created']
        },
        {
          key: 'service',
          /// checkbox option
          label: gettextCatalog.getString('Service activities'),
          $filter: ['service_log_was_created', 'service_certificate_was_created']
        }
      ],
      $open: false
    },
    state: {
      options: [],
      $open: false
    },
    user: {
      options: [],
      $open: false
    },
    folder: {
      picked: [],
      $open: false
    },
    date: {
      from: null,
      to: null,
      $fromDateActive: true,
      $open: false,
      $today: null
    }
  }

  vm.filters.date.fromOptions = {
    showWeeks: false,
    startingDay: 1,
    maxDate: vm.filters.date.to
  }

  vm.filters.date.toOptions = {
    showWeeks: false,
    startingDay: 1,
    maxDate: vm.filters.date.$today,
    minDate: vm.filters.date.from
  }

  vm.control = {
    close: vm.toggleFiltersPanel,
    changeHandler,
    toggle: toggle,
    filter: filter,
    setAll: setAll,
    toggleDatePickers: toggleDatePickers,
    toggleDetailedActivities: toggleDetailedActivities,
    updateChildren: updateChildren,
    updateParent: updateParent
  }

  /////////////

  vm.$onInit = function () {
    getMembers()
    getStates()
    setupTypeFilters()
    setupDatePickers()
    setupFolderFilter()
  }

  function getMembers() {
    membersData
      .getAll(context.get('account').id, null, { include: 'user,deactivated,papertrail-bot' })
      .then(function (members) {
        vm.filters.user.options = members

        checkActiveUserFilters()

        vm.membersLoading = false
      })
  }

  function getStates() {
    const states = statesData.get()
    states.sort(function (a, b) {
      return a.order - b.order
    })

    const logFilter = vm.filters.type.options.filter((type) => type.key === 'log')[0]
    states.forEach((state) => {
      logFilter.$suboptions.push({
        key: state.id,
        label: state.name,
        $log_state_id: state.id,
        $filter: ['log_was_created', 'log_was_updated', 'log_was_deactivated', 'log_was_activated']
      })
    })
  }

  function setupTypeFilters() {
    _forEach(vm.filters.type.options, function (option, n) {
      if (option.key == 'log') {
        _forEach(option.$suboptions, function (suboption) {
          suboption.$selected = isLogStateSelected(suboption.$log_state_id)
        })
        updateParent(option)
      } else if (option.$suboptions) {
        _forEach(option.$suboptions, function (suboption) {
          suboption.$selected = isTypeSelected(suboption)
        })

        updateParent(option)
      } else {
        vm.filters.type.options[n].$selected = isTypeSelected(option)
      }
    })
  }

  function setupFolderFilter() {
    if ($stateParams.folder_id) {
      _forEach($stateParams.folder_id.split(','), function (folderId) {
        vm.filters.folder.picked.push(folderId)
      })
    }
  }

  $scope.$on('foldersSelected', (event, picked) => {
    vm.filters.folder.picked = picked;
  });

  $scope.$on('foldersNotSelected', (event, picked) => {
    vm.filters.folder.picked = picked;

  });

  function checkActiveUserFilters() {
    _forEach(vm.filters.user.options, function (member, n) {
      vm.filters.user.options[n].$selected = isUserSelected(member.user.id)
    })
  }

  function setupDatePickers() {
    vm.filters.date.from = $stateParams.from
    vm.filters.date.to = $stateParams.to

    vm.filters.date.$today = moment().toDate()
  }

  function isTypeSelected(option) {
    let filterValueArray = option.$filter || []
    let types = $stateParams.type && $stateParams.type.split(',')

    if ($stateParams.type && _intersection(filterValueArray, types).length === filterValueArray.length) {
      return true
    }

    if ($stateParams.type) {
      return false
    }

    return true
  }

  function isUserSelected(id) {
    if ($stateParams.user && _includes($stateParams.user.split(','), id)) {
      return true
    }

    if ($stateParams.user) {
      return false
    }

    return true
  }

  function isLogStateSelected(id) {
    if ($stateParams.log_state_id && _includes($stateParams.log_state_id.split(','), id)) {
      return true
    }

    if (!$stateParams.log_state_id) {
      // no specific states selected. Select if log type, or ALL types.
      let types = $stateParams.type && $stateParams.type.split(',')
      if (types && types.includes('log_was_created')) {
        return true
      }

      if (!types) {
        //this is when all types are selected (i.e. no type filter)
        return true
      }
    }

    return false
  }

  function toggle(section) {
    vm.filters[section].$open = !vm.filters[section].$open
  }

  function filter() {
    let params: any = {
      user: _map(_filter(vm.filters.user.options, '$selected'), 'user.id'),
      type: _map(_filter(vm.filters.type.options, '$selected'), '$filter'),
      from: moment(vm.filters.date.from).format('YYYY-MM-DD'),
      to: moment(vm.filters.date.to).format('YYYY-MM-DD'),
      folder_id: vm.filters.folder.picked,
      log_state_id: []
    }
    let availableTypeCount = _reduce(
      vm.filters.type.options,
      function (result, option) {
        if (option.$suboptions && !(option.key === 'log')) {
          return result + _uniq(_map(option.$suboptions, '$filter').join(',').split(',')).length
        }
        return result + option.$filter.length
      },
      0
    )

    // Get the filters for any selected suboptions
    params.type = _uniq(
      params.type.concat(
        _reduce(
          _filter(vm.filters.type.options, '$suboptions'),
          function (result, option) {
            return result.concat(_map(_filter(option.$suboptions, '$selected'), '$filter'))
          },
          []
        )
      )
    )

    // Get the filters for selected inspection state
    params.log_state_id = getSelectedLogStates()

    // Check the number of available activities against the selected activities
    if (_filter(params.type).join(',').split(',').length === availableTypeCount) {
      params.type = []
    }

    // Check the number of available users against the selected users
    if (_filter(params.user).length === vm.filters.user.options.length) {
      params.user = []
    }

    //check the number of available states against the selected states
    if (params.log_state_id.split(',').length === statesData.get().length) {
      params.log_state_id = ''
    }

    params.user = _filter(params.user).join(',')
    params.type = _filter(_uniqWith(params.type, _isEqual)).join(',')
    params.folder_id = params.folder_id.join(',')
    $state.go($state.current.name, params)
    vm.control.close()
  }

  function getSelectedLogStates() {
    return _reduce(
      vm.filters.type.options,
      function (result, option) {
        if (option.key === 'log') {
          return result.concat(_map(_filter(option.$suboptions, '$selected'), '$log_state_id').join(','))
        }
        return result
      },
      ''
    )
  }

  function setAll(filterType, bool) {
    _forEach(vm.filters[filterType].options, function (option) {
      option.$selected = bool
      if (option.$suboptions) {
        updateChildren(option)
      }
    })
  }

  function unsetExceptLogs() {
    _forEach(vm.filters.type.options, function (option) {
      if (option.key !== 'log' && (option.$selected || option.$indeterminate)) {
        option.$selected = false
        if (option.$suboptions) {
          updateChildren(option)
        }
      }
    })
  }

  function resetLogs() {
    _forEach(vm.filters.type.options, function (option) {
      if (option.key === 'log' && option.$indeterminate) {
        option.$selected = true
        updateChildren(option)
      }
    })
  }

  function toggleDatePickers(mode) {
    vm.filters.date.$fromDateActive = mode === 'from'
  }

  function toggleDetailedActivities(type, index) {
    vm.filters[type].options[index].$suboptionsExpanded = !vm.filters[type].options[index].$suboptionsExpanded
  }

  function updateChildren(option) {
    _forEach(option.$suboptions, function (suboption) {
      suboption.$selected = option.$selected
    })

    updateParent(option)
  }

  function updateParent(option) {
    let subOptionsCount = (option.$suboptions && option.$suboptions.length) || 0
    let subOptionsSelectedCount

    // If all sub options selected, check the parent option
    subOptionsSelectedCount = _filter(option.$suboptions, { $selected: true }).length

    if (subOptionsCount) {
      if (!subOptionsSelectedCount) {
        option.$selected = false
        option.$indeterminate = false
      } else if (subOptionsSelectedCount === subOptionsCount) {
        option.$selected = true
        option.$indeterminate = false
      } else {
        option.$selected = false
        option.$indeterminate = true
      }
    }

    if (option.key === 'log') {
      if (option.$indeterminate) {
        unsetExceptLogs()
      }
    } else if (option.$selected || option.$indeterminate) {
      resetLogs()
    }
  }

  function changeHandler(picked) {
    vm.filters.folder.picked = picked
  }
}
