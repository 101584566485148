import angular from 'angular'
import _find from 'lodash/find'
import _sortBy from 'lodash/sortBy'

export function Controller(TasksData) {
  'ngInject'

  const $ctrl = this
  let sortColumns = TasksData.getSortColumns()

  $ctrl.control = {
    toggleSortPanel,
    add: addToSort,
    remove: removeFromSort,
    directionChange
  }

  $ctrl.sortOptions = {
    axis: 'y',
    handle: '.order-cell-icon',
    stop: function () {
      $ctrl.sortedList = recalculateOrders($ctrl.sortedList)
      updateHeaders()
    }
  }

  $ctrl.sortedList = []
  $ctrl.unsortedList = []
  $ctrl.unsortedSelected = null
  $ctrl.unsortedSelectedOrder = null

  $ctrl.$onInit = function () {
    populateSortLists()
  }

  function toggleSortPanel() {
    $ctrl.onToggleSortPanel()
  }

  function populateSortLists() {
    const sortedList = []
    const unsortedList = []

    // populate sort and unsort arrays
    sortColumns.forEach(function (column) {
      if (column.order) {
        sortedList.push(column)
      } else {
        unsortedList.push(column)
      }
    })

    $ctrl.sortedList = recalculateOrders(_sortBy(sortedList, 'order'))
    $ctrl.unsortedList = _sortBy(unsortedList, 'key')

    // If we still have unsorted items, make sure we give the select box a value, so that it does not appear blank.
    if (unsortedList.length) {
      $ctrl.unsortedSelected = $ctrl.unsortedList[0].key
      $ctrl.unsortedSelectedOrder = $ctrl.sortedList.length + 1
    }
  }

  // Deals with re-ordering, should an item be removed from sort, or the order value exceeds the sortedItems length
  // by 2 or more, this will keep things in uniform.
  function recalculateOrders(list) {
    return list.map(function (item, index) {
      let newItem = angular.copy(item)

      newItem.order = index + 1

      return newItem
    })
  }

  // Check the sorted items for order values greater than or equal to the 'order' param, then bump them up by 1.
  function bumpOrders(order) {
    let newSortList = $ctrl.sortedList.map(function (item) {
      let newItem

      // Return new object for changed item to trigger $onChanges in child componenets
      if (item.order >= order) {
        newItem = angular.copy(item)
        newItem.order = newItem.order + 1

        return newItem
      }

      // No changes to item
      return item
    })

    return newSortList
  }

  function amendSort(item, order) {
    if (typeof item !== 'undefined') {
      item.order = order

      // If we are inserting an item into the range of existing values
      if (order > 0 && order <= $ctrl.sortedList.length) {
        bumpOrders(order)
      }

      updateHeaders()
      populateSortLists()
    }
  }

  function addToSort() {
    let item = _find($ctrl.unsortedList, { key: $ctrl.unsortedSelected })

    amendSort(item, $ctrl.unsortedSelectedOrder)
  }

  function removeFromSort($event) {
    let item = _find($ctrl.sortedList, { key: $event.item.key })

    amendSort(item, 0)
  }

  function directionChange($event) {
    let item: any = _find($ctrl.sortedList, { key: $event.item.key })

    if (typeof item === 'object') {
      item.direction = $event.item.direction

      updateHeaders()
    }
  }

  function updateHeaders() {
    sortColumns = TasksData.setSortColumns(_sortBy($ctrl.sortedList.concat($ctrl.unsortedList), 'order'))

    $ctrl.onRefreshTasks()
  }
}
