import { createResource } from '../../api/resource'
import { createResponseTransformer } from '../../api/transformer'
import { Folder } from '../index'

interface Query {
  include?: string
  filter?: string
}

interface Params {
  accountId: string
  folderId: string
  query?: Query
}

export const fetchFolder = createResource({
  request: ({ accountId, folderId, query }: Params) => ({
    url: `accounts/${accountId}/folders/${folderId}`,
    method: 'GET',
    params: query
  }),
  transform: createResponseTransformer<Folder>(
    'members',
    'children',
    'records',
    'ancestors',
    'descendants',
    'definitions',
    'user'
  )
})
