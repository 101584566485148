export function Controller(foldersData, context, ErrorsModal) {
  'ngInject'

  const vm = this

  this.$onInit = () => {
    vm.account = context.get('account')
    vm.saving = false
    vm.error = null
    vm.movedFolder = vm.resolve.movedFolder
    vm.folder = {}
  }

  this.save = () => {
    if (this.folder && this.folder.id) {
      this.saving = true

      foldersData
        .move(vm.account, vm.resolve.movedFolder, this.folder, 0)
        .catch((response) => {
          ErrorsModal.openModal(response.error)
        })
        .finally(() => {
          vm.close()
        })
    }
  }

  this.movingToRoot = () => {
    return this.folder && this.folder.id == this.account.folder.id
  }

  this.folderChange = (event) => {
    this.folder = event.picked
  }
}
