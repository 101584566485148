import view from './view.html'
import { Controller } from './controller'

export const ptLabels = {
  bindings: {
    labels: '<',
    limit: '@',
    onClick: '&',
    pickerOptions: '<'
  },
  template: view,
  controller: Controller
}
