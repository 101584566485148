export function SubscriptionModel(restmod) {
  'ngInject'

  let model = restmod
    .model('/subscriptions')
    .mix('ApiBaseModel')
    .mix({
      accounts: { hasMany: 'AccountModel', map: 'accounts.data' },
      billing: { hasOne: 'BillingModel' },
      package: { hasOne: 'PackageModel', map: 'package.data' },
      stats: { hasOne: 'StatModel', map: 'stats.data' },
      industry: { hasOne: 'IndustryModel', map: 'industry.data' },
      invitations: { hasMany: 'InvitationModel' },
      invoices: { hasMany: 'InvoiceModel' },
      owners: { hasMany: 'OwnerModel' }
    })

  return model
}
