import angular from 'angular'

export function Controller() {
  'ngInject'

  let $ctrl = this

  $ctrl.control = {
    clearSearch: clearSearch,
    deselectRecords: deselectRecords,
    gotoState: gotoState,
    recordSearch: recordSearch,
    viewSelection: viewSelection
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.recordsSelected) {
      $ctrl.recordsSelected = angular.copy($ctrl.recordsSelected)
    }

    if (changes.folder) {
      $ctrl.folder = angular.copy($ctrl.folder)

      // We only want to clear the search if a folder has been selected, as the search clears the currently selected
      // folder, which would result in the newly typed search being cleared.
      if ($ctrl.folder.id) {
        $ctrl.recordSearchText = null
      }
    }
  }

  function clearSearch() {
    $ctrl.recordSearchText = null
    $ctrl.onClearSearch()
  }

  function deselectRecords() {
    $ctrl.onDeselectRecords()
  }

  function gotoState(event, state) {
    if (hasPermission(state) && $ctrl.recordsSelected.length) {
      $ctrl.onSelection({
        $event: {
          event: event,
          state: state
        }
      })
    }
  }

  function hasPermission(state) {
    if (state === '.new-log') {
      return $ctrl.permissions && $ctrl.permissions.logs
    }

    return true
  }

  function recordSearch() {
    $ctrl.onRecordSearch({
      query: $ctrl.recordSearchText
    })
  }

  function viewSelection() {
    $ctrl.recordSearchText = null

    $ctrl.onViewSelection()
  }
}
