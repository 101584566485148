import { createResource } from '../../api/resource'
import { createResponseTransformer, createRequestTransformer } from '../../api/transformer'
import { CreateUploadsPayload } from '../models'

const transformRequest = createRequestTransformer<CreateUploadsPayload>()

interface Params {
  accountId: string
  uploadProgressCallback: (progressEvent) => void
  payload: CreateUploadsPayload
}

export const CreateUploads = createResource({
  request: ({ accountId, uploadProgressCallback, payload }: Params) => {
    const formData = new FormData()

    formData.append('file', payload.file)

    return {
      url: `accounts/${accountId}/uploads`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': `multipart/form-data`
      },
      onUploadProgress: uploadProgressCallback
    }
  },
  transform: createResponseTransformer<any>()
})
