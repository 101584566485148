export function Controller(context, accountsData, $state, gettextCatalog, ErrorsModal, $uibModalStack, $timeout,) {
  'ngInject'

  const $ctrl = this

  $ctrl.account = context.get('account')
  $ctrl.confirmStringTarget = gettextCatalog.getString('YES')
  $ctrl.confirmString = ''
  $ctrl.saving = false

  $ctrl.$onInit = () => {}

  $ctrl.deactivate = () => {
    $ctrl.account.active = false
    $ctrl.saving = true

    accountsData
      .deactivate($ctrl.account)
      .then(function () {
        // Dismiss the current modal
        $ctrl.dismiss({ $value: 'deactivated' })

        // Dismiss all open modals (if needed)
        $uibModalStack.dismissAll()

        // Then transition to a new state after a brief delay to allow modals to close
        $timeout(() => $state.go('accounts'), 300) // Adjust delay as necessary
      })
      .catch(function () {})
      .finally(() => {
        $ctrl.saving = false
      })
  }
}
