import * as React from 'react'

export interface ModalHeaderProps extends React.ComponentProps<'div'> {
  modalTitle: string
  onCloseModal: Function
}

class ModalHeader extends React.Component<ModalHeaderProps> {
  closeModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    this.props.onCloseModal()
  }

  render() {
    return (
      <>
        <a className="close" onClick={this.closeModal}>
          <svg className="pt-icon" viewBox="0 0 10.629 10.628">
            {/* icon-cancel */}
            <title>Close</title>
            <path
              fill="#213854"
              d="M1.181 0L0 1.181l4.134 4.134L0 9.448l1.181 1.181 4.134-4.133 4.134 4.133 1.181-1.181-4.134-4.133 4.134-4.134L9.448 0 5.314 4.134z"
            />
          </svg>
        </a>
        <h2 className="modal-title">{this.props.modalTitle}</h2>
      </>
    )
  }
}

export default ModalHeader
