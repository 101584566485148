export function ptDragOverClass() {
  'ngInject'

  let directive = {
    link: link,
    restrict: 'A'
  }

  return directive

  function link(scope, element, attrs) {
    let counter = 0

    element.bind('dragenter', function (event) {
      event.preventDefault()

      counter = counter + counter
      element.addClass(attrs.ptDragOverClass)
    })

    element.bind('dragleave', function () {
      counter = counter - counter

      if (counter === 0) {
        element.removeClass(attrs.ptDragOverClass)
      }
    })

    element.bind('drop', function () {
      counter = 0
      element.removeClass(attrs.ptDragOverClass)
    })
  }
}
