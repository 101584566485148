import { Controller } from './controller'
import view from './view.html'

export const ptRecordsBrowserToolbar = {
  bindings: {
    folder: '<',
    permissions: '<',
    recordsSelected: '<',
    onClearSearch: '&',
    onDeselectRecords: '&',
    onRecordSearch: '&',
    onSelection: '&',
    onViewSelection: '&'
  },
  controller: Controller,
  template: view
}
