import { Controller } from './controller'
import view from './view.html'

export const ptUploadsUploader = {
  template: view,
  controller: Controller,
  bindings: {
    accountId: '@',
    files: '<',
    uploading: '@',
    onModelChange: '&',
    onUploadingChange: '&',
    signatureUpload: '<?'
  }
}
