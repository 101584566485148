import { getContacts, shareCertificate } from '../../services/share'
import _merge from 'lodash/merge'
import _sortBy from 'lodash/sortBy'

export function Controller($scope, $http, session, BASE_URL, API_VERSION, context, tracking) {
  'ngInject'

  const $ctrl = this

  $ctrl.$scope = $scope
  $ctrl.modalTitle = 'Share certificate'
  $ctrl.emailList = []
  $ctrl.emailToAdd = ''
  $ctrl.emailToAddValid = true
  $ctrl.addEmailDisabled = true
  $ctrl.shareDisabled = true
  $ctrl.pickerOpen = false
  $ctrl.loadingContacts = false
  $ctrl.searchTxt = ''
  $ctrl.activity = undefined
  $ctrl.generating = false
  $ctrl.error = ''

  $ctrl.avatarHue = (id) => {
    let number = 0

    for (let i = 0; i < id.length; i++) {
      number = (number + id.charCodeAt(i)) % 256
    }

    return number
  }

  $ctrl.avatarStyle = (contact) => {
    return {
      backgroundColor: 'hsl(' + contact.avatarHue + ',76%,63%)'
    }
  }

  $ctrl.$onInit = async () => {
    $ctrl.activity = $ctrl.resolve.activity
    $ctrl.loadingContacts = true
    const accountId = context.get('account').id
    const res = await getContacts($http, session, BASE_URL, API_VERSION, accountId)
    $ctrl.suggestedContacts = _sortBy(res.data.data, 'name')
      .filter((contact) => contact.email)
      .map((contact) => ({
        id: contact.id,
        name: contact.name,
        email: contact.email,
        added: false,
        avatarHue: $ctrl.avatarHue(contact.id)
      }))

    if (context.get('account').subscription && context.get('account').subscription.reseller) {
      $ctrl.showContacts = context.get('account').subscription.reseller.data.code === 'tb'
    }

    $ctrl.loadingContacts = false
    $scope.$apply()
  }

  $ctrl.getInitials = (contact) => {
    return contact.name
      .split(' ')
      .map((name) => name[0])
      .join('')
  }

  $ctrl.getSelectedContacts = () => {
    return $ctrl.suggestedContacts.filter((contact) => contact.added)
  }

  const updateShareDisabled = () => {
    if ($ctrl.emailList.length > 0 || $ctrl.getSelectedContacts().length > 0) {
      $ctrl.shareDisabled = false
    } else {
      $ctrl.shareDisabled = true
    }
  }

  $ctrl.openPicker = () => {
    $ctrl.pickerOpen = true
  }

  $ctrl.filteredContacts = () => {
    if ($ctrl.searchTxt == '') {
      return $ctrl.suggestedContacts
    } else {
      return $ctrl.suggestedContacts.filter((contact) =>
        contact.name.toLowerCase().includes($ctrl.searchTxt.toLowerCase())
      )
    }
  }

  $ctrl.share = async () => {
    if (!$ctrl.shareDisabled) {
      $ctrl.error = false
      $ctrl.generating = true

      const emails = _merge(
        [],
        $ctrl.emailList,
        $ctrl.getSelectedContacts().map((contact) => contact.email)
      )

      try {
        $ctrl.shareDisabled = true
        await shareCertificate(
          $http,
          session,
          BASE_URL,
          API_VERSION,
          $ctrl.activity.to.id,
          $ctrl.activity.to.name + '.pdf',
          emails,
          $ctrl.activity.account.id
        )
        $ctrl.generating = false
        $ctrl.$scope.$parent.$close(emails)
        tracking.trackEvent({ action: 'shareCertificate', category: 'certificates' })
      } catch (e) {
        $ctrl.generating = false
        $ctrl.shareDisabled = false
        $ctrl.error = true

        $scope.$apply()
      }
    }
  }

  $ctrl.closePopup = () => {
    $ctrl.pickerOpen = false
    $ctrl.searchTxt = ''
  }

  $ctrl.onAddClick = (id: number) => {
    $ctrl.suggestedContacts = $ctrl.suggestedContacts.map((contact) => {
      if (contact.id === id) {
        return {
          ...contact,
          added: false
        }
      } else {
        return contact
      }
    })
    updateShareDisabled()
  }

  $ctrl.onRemoveClick = (id: number) => {
    $ctrl.suggestedContacts = $ctrl.suggestedContacts.map((contact) => {
      if (contact.id === id) {
        return {
          ...contact,
          added: true
        }
      } else {
        return contact
      }
    })
    updateShareDisabled()
  }

  $ctrl.addEmail = () => {
    if (!$ctrl.addEmailDisabled) {
      $ctrl.emailList.push($ctrl.emailToAdd)
      $ctrl.emailToAdd = ''
      $ctrl.addEmailDisabled = true
      updateShareDisabled()
    }
  }

  $ctrl.removeEmail = (email: string) => {
    $ctrl.emailList = $ctrl.emailList.filter((e) => e !== email)
    updateShareDisabled()
  }

  $ctrl.addEmailChange = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = regex.test($ctrl.emailToAdd)
    $ctrl.emailToAddValid = isValid || $ctrl.emailToAdd.trim() === ''
    $ctrl.addEmailDisabled = $ctrl.emailToAdd.trim() === '' || !isValid
  }

  $ctrl.close = () => {
    $ctrl.dismiss()
  }
}
