export function NewRecordRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('folder.new-record.**', {
    url: '/records/new',
    lazyLoad: ($transition$) => {
      const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')

      return import(/* webpackChunkName: "records.new" */ '../components/pt-record-new')
        .then((mod) => {
          return $ocLazyLoad.inject(mod.default)
        })
        .catch((err) => {
          throw new Error('LazyLoad Error: ' + err)
        })
    }
  })
}
