export function Controller(context) {
  'ngInject'

  this.$onInit = () => {
    const responseUploads = this.field.responseUploads?.data || this.field.responde_uploads?.data
    this.files = this.field.files || responseUploads ||  []
    this.responseUploads = responseUploads;
    this.accountId = context.get('account').id
  }

  this.hasFiles = () => {
    if(this.field.uploads && this.field.files){
      return this.field.uploads
    }
  }

  this.setFiles = (event) => {
    this.field.uploads = event.model
    this.field.files = event.files
  }

  this.setUploading = (event) => {
    this.uploading = event.uploading
  }

  this.valueChange = () => {
    this.onValueChange()
  }
}
