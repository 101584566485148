import angular from 'angular'

export function Controller(
  $state,
  permissionsCheck,
  recordsBucket, 
  recordsSelection,
  DefinitionsData,
  context,
  ChecklistsCache,
  $location
) {
  'ngInject'

  const $ctrl = this

  $ctrl.control = {
    addLogs,
    addNotes,
    addToBucket,
    removeFromBucket,
    clearBucket,
    batchExport,
    edit,
    tag,
    checklist,
    move,
    delete: deleteRecords,
    clone,
    identifiers,
    selectionCount
  }

  $ctrl.account = context.get('account')
  $ctrl.permissionsCheck = permissionsCheck
  $ctrl.popoverOpen = false

  $ctrl.$onInit = function () {
    $ctrl.accountChecklists = ChecklistsCache.get(context.get('account'))

    addEventListener('identifiersSaved', () => {
      $state.go('folder.action-records', { action: 'identifiers', recordIds: selectedIds() })
    })
  }

  $ctrl.$onChanges = function (changes) {
    if (changes.records) {
      $ctrl.records = angular.copy($ctrl.records)
    }

    if (changes.selectionCount) {
      if (!Number($ctrl.selectionCount)) {
        $ctrl.popoverOpen = false
      }
    }
  }

  function addLogs() {
    // As this is the default option displayed, add the extra check that records are selected first
    if ($ctrl.control.selectionCount()) {
      $state.go('.new-log', { recordIds: selectedIds(), sort: getRecordSort() })
    }
  }

  function addToBucket() {
    recordsBucket.addMany(selectedIdArray());
  }

  function removeFromBucket() {
    recordsBucket.removeMany(selectedIdArray());
  }

  function clearBucket() {
    recordsBucket.clear();
  }

  function addNotes() {
    $state.go('.new-note', { recordIds: selectedIds(), sort: getRecordSort() })
  }

  function batchExport() {
    $state.go('.batch-export', { recordIds: selectedIds() })
  }

  function edit() {
    $state.go('.edit-records', { recordIds: selectedIds() })
  }

  function identifiers() {
    const path = $location.url()
    const newPath = path + `&records=${selectedIds()}`
    $location.url(newPath)
  }

  function tag() {
    $state.go('.tag-records', { recordIds: selectedIds() })
  }

  function checklist() {
    $state.go('.checklist-records', { recordIds: selectedIds() })
  }

  function move() {
    $state.go('.move-records', { recordIds: selectedIds() })
  }

  function deleteRecords() {
    // Transition to delete records state and then remove records from the bucket
    $state.go('.delete-records', {
      recordIds: selectedIds()
    }).then(() => {
      removeFromBucket();
    });
  }

  function selectedIds() {
    return selectedIdArray().join(',');
  }

  function selectedIdArray() {
    let recordIds

    // $ctrl.records is passed into the component to override the default records list data-set
    if ($ctrl.records) {
      recordIds = getRecordIds()
    } else {
      recordIds = recordsSelection.selectedIds()
    }

    return recordIds
  }

  function getRecordIds() {
    return $ctrl.records.map((record) => record.id)
  }

  function getRecordSort() {
    return DefinitionsData.getVisibleColumnsAsSortKeys()
  }

  function clone() {
    $state.go('.clone-records', { recordIds: selectedIds() })
  }

  function selectionCount() {
    return Number($ctrl.selectionCount)
  }
}
