import angular from 'angular'

export function Controller() {
  'ngInject'

  var $ctrl = this

  $ctrl.control = {}

  $ctrl.activityOptions = {
    view: 'td',
    compact: true
  }

  $ctrl.$onInit = function () {}

  $ctrl.$onChanges = function (changes) {
    if (changes.activity) {
      $ctrl.activity = angular.copy($ctrl.activity)
    }
  }
}
