import { createResource } from '../../api/resource'
import { createResponseTransformer, createRequestTransformer } from '../../api/transformer'
import { Log } from '..'

interface Query {
  include?: string
}

interface Params {
  accountId: string
  recordId: string
  logId: string
  query?: Query
}

const transformRequest = createRequestTransformer<Query>()

export const fetchLog = createResource({
  request: ({ accountId, recordId, logId, query }: Params) => ({
    url: `accounts/${accountId}/records/${recordId}/logs/${logId}`,
    method: 'GET',
    params: transformRequest(query)
  }),
  transform: createResponseTransformer<Log>('record', 'user', 'state', 'uploads', 'source', 'checklistCompletions')
})
