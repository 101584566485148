export function ptNotificationsRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('notifications', {
    parent: 'layoutFull',
    url: '/notifications?accounts&read',
    views: {
      'content@layoutFull': {
        component: 'ptNotifications'
      }
    },
    onEnter: onNotificationsEnter
  })

  function onNotificationsEnter(page, gettextCatalog) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Notifications'))
  }
}
