import view from './view.html'

export function ptAccountsBasicTableRow() {
  let directive = {
    template: view,
    restrict: 'A',
    controller: function () {
      'ngInject'
    },
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      account: '='
    }
  }

  return directive
}
