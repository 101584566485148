import { Controller } from './controller'

export function frequencyId(formlyConfig) {
  'ngInject'

  formlyConfig.setType({
    name: 'frequencyId',
    extends: 'select',
    controller: Controller
  })
}
