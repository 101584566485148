export function iAuditorAuthorsModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}

export function iAuditorTemplatesModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}

export function iAuditorSourcesModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}

export function iAuditorFieldsModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}

export function iAuditorModel(restmod) {
  'ngInject'

  const model = restmod.model().mix('ApiBaseModel')

  return model
}
