import * as React from 'react'
import { ActivityTitle } from './activity-title'
import { ActivityContent } from './activity-content'
import { Activity, TaskState } from '..'
import { Text } from '../../components'
import { View, createStyledComponent } from '../../components/styled'

interface Props {
  activity: Activity
  viewType: string
  onPressActivity: (activity: Activity) => void
  taskStates: TaskState[]
}

const Wrapper = createStyledComponent(View, {
  overflow: 'visible'
})

export const ActivityTaskStateWasUpdated = (props: Props) => {
  const { activity, viewType, onPressActivity, taskStates } = props

  switch (viewType) {
    case 'simple': {
      return (
        <Wrapper>
          <ActivityTitle activity={activity} onPressActivity={onPressActivity}>
            Task state was updated
          </ActivityTitle>
          <ActivityContent>
            <Text>Title: {activity.task.title}</Text>
            <Text>
              {activity.from.name} &rarr; {activity.to.name}
            </Text>
          </ActivityContent>
        </Wrapper>
      )
    }
  }
}
