import angular from 'angular'

import { ptMembersEditModule } from './components/pt-members-edit'
import { ptMembersNewModule } from './components/pt-members-new'
import { ptMembersSelectorModule } from './components/pt-members-selector'
import { ptMembersSuspendModule } from './components/pt-members-suspend'

import { ptMembersAccountModule } from './directives/pt-members-account'
import { ptMembersAccountPendingModule } from './directives/pt-members-account-pending'
import { ptMembersAccountPendingRowModule } from './directives/pt-members-account-pending-row'
import { ptMembersAccountRowModule } from './directives/pt-members-account-row'
import { ptMembersAccountSuspendedRowModule } from './directives/pt-members-account-suspended-row'
import { ptMembersPickerModule } from './directives/pt-members-picker'

import { membersData } from './services/data'
import { MemberModel } from './services/models'

export const MembersModule = angular
  .module('members', [
    // components
    ptMembersEditModule,
    ptMembersNewModule,
    ptMembersSelectorModule,
    ptMembersSuspendModule,

    // directives
    ptMembersAccountModule,
    ptMembersAccountPendingModule,
    ptMembersAccountPendingRowModule,
    ptMembersAccountRowModule,
    ptMembersAccountSuspendedRowModule,
    ptMembersPickerModule
  ])
  .factory('membersData', membersData)
  .factory('MemberModel', MemberModel).name
