import axios from 'axios'

const axiosInstance = axios.create()

export function ptApi(BASE_URL, API_VERSION, session, $q) {
  'ngInject'

  const request = (req) => {
    // We need to use $q.when wrapper to bring axios into the angularjs eco-system
    // https://stackoverflow.com/questions/46521516/how-to-use-the-axios-library-with-angularjs
    return $q.when(
      axiosInstance
        .request({
          method: req.method,
          url: BASE_URL + req.url,
          headers: {
            Accept: API_VERSION
          },
          ...(req.data && { data: req.data }),
          ...(req.params && { params: req.params }),
          ...(req.cancelToken && { cancelToken: req.cancelToken })
        })
        .then((response) => {
          return response.data
        })
    )
  }

  return {
    request
  }
}
