export const TutorialsConstants = {
  created_folder: {
    label: 'Create a Folder'
  },
  created_record: {
    label: 'Create a Record'
  },
  created_log: {
    label: 'Add an Inspection'
  },
  created_account_invite: {
    label: 'Invite your team'
  }
}
